<template>
  <div>
    <DowntimeList v-if="currentShift" :shift="currentShift" :flowId="currentFlowId" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DowntimeList from '@/components/pet/shift/downtimes/list/DowntimeList.vue'

export default {
  name: 'ShiftDowntimes',
  data () {
    return {
    }
  },
  components: {
    DowntimeList
  },
  computed: {
    ...mapState({
      currentShift: state => state.pet.currentShift,
      currentFlowId: state => state.pet.currentFlowId
    }),
    shiftId () {
      return this.currentShift?.id;
    }
  },
  watch: {
    currentShift: {
      immediate: true,
      handler () {
      }
    },
    currentFlowId: {
      immediate: true,
      handler () {
      }
    }
  }
}
</script>
