import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import BasicHelper from 'ebg-vue-common/src/helpers/BasicHelper';

const defaultEl = {
  id: null,
  name: {},
  order: null,
  class_id: null,
  classifier_type_id: null,
  allow_multiple: false
};
export default {
  name: 'CharacterizationMetadataEdit',
  components: { },
  data () {
    return {
      classifierTypes: null,
      editEl: defaultEl,
      loading: false
    }
  },
  validations: {
    editEl: {
      name: { required },
      order: { required },
      class_id: { required },
      allow_multiple: { required }
    }
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getTitle () {
      if (this.editEl.id) {
        return this.$t('general.actions.edit');
      }
      return this.$t('general.actions.new');
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission('edit-characterization-metadata');
    },
    modelClassOptions () {
      const items = this.$store.getters.getDataFromConfigWithFilter('model_classes', null, null);
      const options = [];
      Object.values(items).forEach(el => {
        options.push({ value: el.id, text: el.classname.split('\\').pop() })
      });
      return options;
    },
    classifierTypeOptions () {
      let classifierTypeOptions = null;
      if (this.classifierTypes) {
        classifierTypeOptions = this.classifierTypes.map((classifierType) => {
          return { value: classifierType.id, text: BasicHelper.getTranslation(classifierType.name) };
        })
      }
      return classifierTypeOptions;
    }
  },
  methods: {
    show (elData) {
      this.v$.$reset();
      if (elData) {
        this.editEl = this.BasicHelper.cloneObject(elData);
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
      }
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    loadClassifierTypes () {
      this.loading = true;
      this.currentReq = this.ApiRequest.request('classifiers/types?noPaging=true&withoutTrashed=true', this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.data) {
          this.classifierTypes = response.data;
          this.loading = false;
        }
      });
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        this.editEl.allow_multiple = (this.editEl.allow_multiple && this.editEl.classifier_type_id)
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        this.ApiRequest.request('characterizations/metadata/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$emit('saved');
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    }
  },
  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'ClassifierTypeEdit' }) };
  },
  mounted () {
    this.loadClassifierTypes();
  }
}
