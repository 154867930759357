export default {
  name: 'SalesStatusInfo',
  props: {
    configkey: {
      type: String,
      default: ''
    },
    cargoType: {
      type: String,
      default: ''
    },
    statusId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
    }
  },
  computed: {
    options () {
      let options = this.$store.getters.getConfigOptions(
        this.configkey,
        this.$i18n.locale,
        null,
        null,
        false,
        false,
        false,
        ['color']
      );
      if (this.configkey == 'cargo_statuses') {
        if (this.cargoType == 'IMP') {
          options = options.filter(el => ['TEMPLATE', 'PLANNED', 'LOADING', 'MARKING', 'LABORATORY', 'LOADED', 'FINISHED', 'QUARANTINE', 'CANCELED'].includes(el.code))
        } else if (this.cargoType == 'IMP-R') {
          options = options.filter(el => ['TEMPLATE', 'PLANNED', 'FINISHED', 'CANCELED'].includes(el.code))
        } else {
          options = options.filter(el => !(['MARKING', 'LABORATORY'].includes(el.code)))
        }
      }
      options.forEach(el => {
        el.display = true;
        if (el.code == 'CLOSED' || el.code == 'FINISHED') {
          el.spliticon = null;
        } else if (!['CANCELED', 'QUARANTINE', 'BOOKCANCEL'].includes(el.code)) {
          el.spliticon = 'fw-bold bi bi-arrow-right';
        } else {
          el.display = false;
        }
      });
      return options;
    },
    selectedOption () {
      return this.options.find(el => el.value == this.statusId);
    }
  },
  methods: {
    getOptionStyle (option) {
      const parts = [];
      if (option.value == this.statusId) {
        parts.push('background-color:' + option.color);
        parts.push('color:' + this.BasicHelper.getContrastColor(option.color));
        parts.push('opacity:1');
      }
      return parts.join(';')
    }
  }
}
