import { render, staticRenderFns } from "./RatesList.vue?vue&type=template&id=7433ee89"
import script from "./RatesList.js?vue&type=script&lang=js&external"
export * from "./RatesList.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports