import FullCalendar from '@fullcalendar/vue'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import dayGridMonthPlugin from '@fullcalendar/daygrid'
import CargoLogisticsCard from '@/components/pet/sales/logistics/card/CargoLogisticsCard.vue'
import resourceTimeGrid from '@fullcalendar/resource-timegrid';

export default {
  name: 'CargoLogisticsCalendar',
  components: {
    FullCalendar,
    CargoLogisticsCard
  },
  props: {
    currentEvents: {
      type: Array,
      default: []
    },
    loading: {
      type: Boolean,
      default: false
    },
    deliveryDate: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      resourceOptions: [],
      mounted: false,
      draggable: null,
      selectedView: 'resourceTimeGridWeek',
      calendarOptions: {
        schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
        plugins: [
          interactionPlugin,
          dayGridMonthPlugin,
          timeGridPlugin,
          resourceTimeGrid
        ],
        headerToolbar: {
          left: null,
          center: null,
          right: null
        },
        views: {
          resourceTimeGridWeek: {
            type: 'resourceTimeGrid',
            duration: { days: 5 },
            buttonText: '5-day',
            dayHeaderFormat: {
              weekday: 'long',
              month: 'long',
              day: 'numeric'
            }
          },
          resourceTimeGridThree: {
            type: 'resourceTimeGrid',
            duration: { days: 3 },
            buttonText: '3-day',
            dayHeaderFormat: {
              weekday: 'long',
              month: 'long',
              day: 'numeric'
            }
          },
          resourceTimeGridDay: {
            type: 'resourceTimeGrid',
            dayHeaderFormat: {
              weekday: 'long',
              month: 'long',
              day: 'numeric'
            }
          },
          dayGridMonth: {
            dayHeaderFormat: {
              weekday: 'long'
            }
          }
        },
        contentHeight: 'auto',
        nowIndicator: true,
        datesAboveResources: true,
        resourceOrder: 'order',
        locale: 'lv',
        firstDay: 1,
        slotDuration: '00:30:00',
        droppable: true,
        initialView: 'resourceTimeGridWeek',
        allDayText: this.$t('general.fullcalendar.withouttime'),
        editable: false,
        selectable: false,
        selectMirror: true,
        dayMaxEvents: true,
        eventClick: this.handleEventClick,
        drop: this.receivedDraggable,
        slotEventOverlap: false,
        moreLinkContent: this.handleMoreInfo,
        defaultTimedEventDuration: '02:00:00',
        events: this.currentEvents,
        dayCellClassNames: this.cellClassNames,
        dayHeaderClassNames: this.dayHeaderClassNames,
        resourceLabelClassNames: this.cellClassNames
      }
    };
  },
  computed: {
    viewOptions () {
      return [
        { code: 'resourceTimeGridDay', text: this.$t('general.fullcalendar.timeGridDay'), multi: 1 },
        { code: 'resourceTimeGridThree', text: this.$t('general.fullcalendar.timeGridThree'), multi: 3 },
        { code: 'resourceTimeGridWeek', text: this.$t('general.fullcalendar.timeGridWeek'), multi: 5 },
        { code: 'dayGridMonth', text: this.$t('general.fullcalendar.dayGridMonth'), mullti: 1 }
      ]
    },
    hasSimpleCargo () {
      return this.selectedView == 'dayGridMonth';
    },
    colcount () {
      const option = this.viewOptions.find(el => el.code == this.selectedView);
      const multi = (option ? option.multi : 1)
      const total = this.resourceOptions.length * multi;
      return total
    },
    calendarclass () {
      if (this.selectedView == 'resourceTimeGridWeek') {
        return 'small';
      }
      if (this.selectedView == 'resourceTimeGridThree') {
        return 'medium';
      }
      return 'large';
    },
    tinycards () {
      if (this.colcount > 8) {
        return true;
      }
      return false;
    },
    quarantineStatusId () {
      return this.BasicHelper.getConfigValue('cargo_statuses', 'code', 'QUARANTINE', 'id')
    }
  },
  methods: {
    cellClassNames (elem) {
      if (this.selectedView == 'resourceTimeGridThree' || this.selectedView == 'resourceTimeGridWeek') {
        const lastResourceValue = this.resourceOptions[this.resourceOptions.length - 1]?.value;
        if (elem && elem.resource && elem.resource.extendedProps) {
          const resourceValue = elem.resource.extendedProps.value;
          return resourceValue === lastResourceValue ? 'cal-bw-l' : '';
        }
        return '';
      }
      return '';
    },
    dayHeaderClassNames () {
      if (this.selectedView == 'resourceTimeGridThree' || this.selectedView == 'resourceTimeGridWeek') {
        return 'cal-bw-l';
      }
      return '';
    },
    getLastDayOfTheMonth (date) {
      const selected = new Date(date);
      return new Date(selected.getFullYear(), selected.getMonth() + 1, 0).toISOString().split('T')[0];
    },
    goNext () {
      this.$refs.calendar.getApi().next();
      this.dateChanged();
    },
    goPrev () {
      this.$refs.calendar.getApi().prev();
      this.dateChanged();
    },
    clearFilters () {
      this.changeView(this.calendarOptions.initialView);
      this.$emit('clearFilters');
    },
    toToday () {
      const date = new Date();
      if (this.selectedView != 'dayGridMonth') {
        const option = this.viewOptions.find(el => el.code == this.selectedView);
        const multi = (option ? option.multi : 1)
        date.setDate(date.getDate() - (Math.floor(multi / 2)))
      }
      this.$refs.calendar.getApi().gotoDate(date);
      this.dateChanged();
    },
    changeDate (date) {
      if (date) {
        this.$refs.calendar.getApi().gotoDate(date);
        this.dateChanged();
      }
    },
    changeView (view) {
      this.$refs.calendar.getApi().changeView(view);
      this.selectedView = view;
      this.BasicHelper.updateFilterValues(this.$route.name + '_selectedView', { selectedView: view });
      this.dateChanged();
    },
    dateChanged () {
      const currentData = this.$refs.calendar.getApi().getCurrentData();
      const endAt = new Date(currentData.dateProfile.currentRange.end);

      if (this.selectedView != 'dayGridMonth') {
        endAt.setDate(endAt.getDate() - 1);
      }

      const data = this.BasicHelper.cloneObject({
        start: currentData.dateProfile.currentRange.start,
        end: endAt
      });
      data.hasSimpleCargo = this.hasSimpleCargo;
      this.$emit('dateChanged', data);
    },
    statusColor (id) {
      return this.$store.getters.getParamFromConfig('cargo_statuses', id, 'color');
    },
    statusTextColor (id) {
      return this.BasicHelper.getContrastColor(this.statusColor(id));
    },
    receivedDraggable (received) {
      const eventData = JSON.parse(received.draggedEl.dataset.event);
      const sendData = { cargoCode: eventData.localcode, date: received.dateStr };
      if (!received.allDay) {
        sendData.scheduledStartAt = received.dateStr;
      }
      if (received.resource && received.resource._resource) {
        sendData.stage_id = received.resource._resource.id;
        if (sendData.stage_id == 'NULL') {
          sendData.stage_id = null;
        }
      }
      this.$emit('editSchedule', sendData);
    },
    handleEventClick (clickInfo) {
      if (this.selectedView == 'dayGridMonth') {
        this.editSchedule({ cargoCode: clickInfo.event.title, date: null });
      }
    },
    editSchedule (val) {
      this.$emit('editSchedule', val);
    },
    handleMoreInfo (e) {
      return this.$tc('general.fullcalendar.extracargos', null, { 0: e.num });
    },
    setResources (newOptions) {
      this.resourceOptions = newOptions;
      const api = this.$refs.calendar.getApi();
      const resources = api.getResources();
      this.resourceOptions.forEach(el => {
        if (!api.getResourceById(el.id)) {
          el.title = el.text;
          api.addResource(el);
        }
      });
      resources.forEach(el => {
        const match = this.resourceOptions.find(o => o.id == el._resource.id);
        if (!match) {
          el.remove();
        }
      });
    }
  },
  mounted () {
    this.mounted = true;
    const viewfilters = this.BasicHelper.fillFilterValues(this.$route.name + '_selectedView', {}, this.$session);
    const valueFilters = localStorage.getItem('filters:' + this.$route.name + '_incoming');
    const startDateFromSession = valueFilters ? JSON.parse(valueFilters)?.deliveryDate?.start : null;
    if (viewfilters && viewfilters.selectedView && this.viewOptions.find(el => el.code == viewfilters.selectedView)) {
      this.changeView(viewfilters.selectedView)
    }
    if (startDateFromSession) {
      this.deliveryDate.start = startDateFromSession;
      this.changeDate(startDateFromSession);
    } else {
      if (this.selectedView == 'resourceTimeGridWeek') {
        const selected = new Date();
        selected.setDate(selected.getDate() - 2);
        this.deliveryDate.start = selected.toISOString().split('T')[0];
        this.changeDate(selected.toISOString().split('T')[0]);
      } else if (this.selectedView == 'dayGridMonth') {
        const selected = new Date();
        selected.setDate(1);
        this.deliveryDate.start = selected.toISOString().split('T')[0];
        this.changeDate(selected.toISOString().split('T')[0]);
      } else {
        const selected = new Date();
        this.deliveryDate.start = selected.toISOString().split('T')[0];
        this.changeDate(selected.toISOString().split('T')[0]);
      }
    }
    this.draggable = new Draggable(this.$refs.cargobase, {
      itemSelector: '.dragcard',
      eventData: function (eventEl) {
        return {
          create: false
        }
      }
    });
  }
}
