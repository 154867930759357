import WarehouseBin from '@/components/pet/warehouse/sku/WarehouseBin.vue';

export default {
  name: 'WarehouseLevel',
  data () {
    return {
    }
  },
  validations: {
  },
  components: {
    WarehouseBin
  },
  props: {
    level: {
      type: Object,
      default: null
    },
    selectedChunk: {
      type: String,
      default: null
    },
    selectedBinCode: {
      type: String,
      default: null
    },
    totalLevels: {
      type: Number,
      default: 0
    },
    isFactoryProductSelectable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    numberRange () {
      const numberRange = [];
      if (this.selectedChunk) {
        const splitChunk = this.selectedChunk.split('-');
        for (let i = parseInt(splitChunk[0]); i <= parseInt(splitChunk[1]); i++) {
          numberRange.push(i);
        }
      }
      return numberRange;
    }
  },
  methods: {
  }
}
