import { helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import ProductQualityMeasurementEdit from '@/components/pet/quality/measurements/edit/ProductQualityMeasurementEdit.vue';
import draggable from 'vuedraggable'

const defaultLine = {
  id: null,
  product_id: null,
  quality_id: null,
  editmode: true,
  name: '',
  category_id: null,
  additionalData: []
}
const mathSymbols = [
  '+',
  '-',
  '/',
  '*',
  '(',
  ')'
];
export default {
  name: 'ProductQualityMeasurementList',
  components: {
    ProductQualityMeasurementEdit,
    draggable
  },
  props: {
    orderId: {
      type: Number,
      default: null
    },
    lines: {
      type: Array,
      default: () => null
    },
    readonly: {
      type: Boolean,
      default: true
    },
    editMeasurement: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      matchOption: mathSymbols.map((name, index) => {
        return { name, order: index + 1, fixed: false, id: index, type: 'matchOption' };
      }),
      loading: false,
      currentReq: null,
      solines: [],
      groupedMeasurements: [],
      groupedMeasurementsDraggable: [],
      formulaInput: [],
      editable: true,
      isDragging: true,
      paging: { limit: 20, page: 1, total: 0, pages: 0 }
    };
  },
  validations: {
    solines: {
      $each: helpers.forEach({
      })
    }
  },
  computed: {
    dragOptions () {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      };
    },
    basePathComp () {
      return 'config/mwc-custom/product-quality-measurements-base-config/view/' + this.orderId + '?perPage=' + this.paging.limit + '&page=' + this.paging.page
    },
    baseTransParam () {
      return 'menu.quality';
    },
    tableHeaders () {
      const headers = [
        { key: `name.${this.$i18n.locale}`, label: this.$t(this.baseTransParam + '.qualityElement.name'), filterKey: 'name', sortable: false },
        { key: 'category_id', label: this.$t(this.baseTransParam + '.qualityElement.category'), sortable: false, format: 'formatFromConfig', configParam: 'name', configKey: 'quality_measurement_categories' },
        { key: 'measurement_id', label: this.$t(this.baseTransParam + '.qualityElement.measurement'), sortable: false, format: 'formatFromConfig', configParam: 'name', configKey: 'measure_types' },
        { key: 'order', label: this.$t(this.baseTransParam + '.qualityElement.order'), sortable: false },
        { key: 'additionalData.maxRangeValue', label: this.$t(this.baseTransParam + '.qualityElement.maxRangeValue'), sortable: false, format: 'formatDecimal', precision: 0 },
        { key: 'additionalData.minRangeValue', label: this.$t(this.baseTransParam + '.qualityElement.minRangeValue'), sortable: false, format: 'formatDecimal', precision: 0 },
        { key: 'additionalData.precision', label: this.$t(this.baseTransParam + '.qualityElement.precision'), sortable: false },
        { key: 'additionalData.defaultValue', label: this.$t(this.baseTransParam + '.qualityElement.defaultValue'), sortable: false },
        { key: 'additionalData.canEditOnFactoryProductShiftMeasurement', label: this.$t(this.baseTransParam + '.qualityElement.canEditOnFactoryProductShiftMeasurement'), sortable: false, format: 'formatYesNo' }
      ];
      const actions = [];
      if (this.canEdit) {
        actions.push({ label: this.$t('general.actions.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-sm btn-warning' });
      }
      if (this.canDelete) {
        actions.push({ label: this.$t('general.actions.delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-sm btn-danger' });
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    canEditSales () {
      return this.canEdit && this.ApiRequest.userHasPermission('edit-sales');
    },
    productOptions () {
      return this.PetHelper.getFeadableBaleProducts();
    },
    productFilters () {
      return { value: this.products.map(el => el.product_id) }
    },
    qualityOptions () {
      return this.$store.getters.getConfigOptions('product_qualities', this.$i18n.locale);
    },
    qualityCategoryOptions () {
      return this.$store.getters.getConfigOptions('quality_measurement_categories', this.$i18n.locale);
    },
    tableExpandConfig () {
      return { component: 'slot' }
    },
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-product-quality-measurements');
    },
    canDelete () {
      return this.ApiRequest.userHasPermission('delete-product-quality-measurements');
    },
    tableActions () {
      const actions = { download: false };
      if (this.canEdit) actions.add = { title: this.$t('general.actions.add') };
      return actions;
    }
  },
  methods: {
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    getTableData () {
      if (this.orderId) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.loading = true;
        this.currentReq = this.ApiRequest.request(this.basePathComp, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.solines = response.data;
            this.paging = response.paging;
            this.loading = false;
          }
        });
      }
    },
    addLine () {
      const el = this.BasicHelper.cloneObject(defaultLine);
      this.solines.push(el);
      this.$refs.tableview.$forceUpdate();
    },
    deleteItem (el, approved) {
      if (approved) {
        el.deleted = 1;
        const idx = this.solines.findIndex(el => el.deleted == 1);
        this.solines.splice(idx, 1);
        this.ApiRequest.request('config/mwc-custom/product-quality-measurement/deleted', this.ApiRequest.REQUEST_GET, { id: el.id, deleted: true }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
            this.loading = false;
          } else {
            this.$refs.tableview.$forceUpdate();
          }
        });
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t('general.item.deleteTitle'), this.$t('general.item.deleteMessage', { name: this.BasicHelper.getTranslation(el.name), code: '' }))
      }
    },
    canDeleteLine (el) {
      return (this.editMeasurement || el.id == null);
    },
    productChanged (val, rowId) {
      if (val) {
        const prod = this.products.find(el => el.product_id == val);
        if (prod) {
          this.solines[rowId].quality_id = (prod.min_quality_id ? prod.min_quality_id : null);
        }
      }
    },
    onMove ({ relatedContext, draggedContext }) {
    },
    onDragchange (event) {
      if ('removed' in event && 'element' in event.removed) {
        if ('type' in event.removed.element && event.removed.element.type == 'matchOption') {
          if (this.matchOption.find(el => el.id == event.removed.element.id) == undefined) {
            this.matchOption.push(event.removed.element);
          }
        }
        if ('category_id' in event.removed.element) {
          if (this.groupedMeasurements[event.removed.element.category_id].find(el => el.id == event.removed.element.id) == undefined) {
            this.groupedMeasurements[event.removed.element.category_id].push(event.removed.element);
          }
        }
      }
    },
    editItem (el) {
      this.$refs.editElFrm.show(el);
    },
    toggleEditMode (el) {
      if (el.editmode) {
        el.editmode = false;
      } else {
        el.editmode = true;
      }
      this.$refs.tableview.$forceUpdate();
    },
    assembledChanged (data, lineId) {
      const rowId = this.solines.findIndex(el => el.id == lineId);
      if (rowId >= 0) {
        this.solines[rowId].assembledPieces = data.assembledPieces;
        this.solines[rowId].assembledQuantity = data.assembledQuantity;
      }
    }
  },
  mounted () {
    if (this.poproducts) {
      this.products = this.poproducts;
    }
    if (this.lines == null) {
      this.getTableData();
    }
  },
  watch: {
    isDragging (newValue) {
      if (newValue) {
        this.delayedDragging = false;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
    lines: {
      immediate: true,
      handler (val) {
        if (val !== null) {
          this.solines = val;
        }
      }
    },
    orderId: {
      immediate: true,
      handler (val) {
        if (this.lines == null) {
          this.getTableData()
        }
      }
    },
    poproducts: {
      immediate: true,
      handler (val) {
        if (val) {
          this.products = val;
        }
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
