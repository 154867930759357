export default {
  name: 'CargoLogisticsCard',
  components: {
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    inCalendar: {
      type: Boolean,
      default: true
    },
    isTiny: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    editSchedule (data) {
      this.$emit('editSchedule', data);
    },
    statusName (id) {
      return this.$store.getters.getParamFromConfig('cargo_statuses', id, 'name', this.$i18n.locale);
    },
    statusColor (id) {
      return this.$store.getters.getParamFromConfig('cargo_statuses', id, 'color');
    },
    statusTextColor (id) {
      return this.BasicHelper.getContrastColor(this.statusColor(id));
    },
    productTypeNames (ids) {
      const res = [];
      if (ids) {
        ids.forEach(id => {
          res.push(this.BasicHelper.getConfigValue('product_types', 'id', id, 'name', this.$i18n.locale));
        });
      }
      if (res.length === 0) {
        res.push('-');
      }
      return res.join('; ');
    }
  }
}
