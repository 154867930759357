import ModelProductDocumentsEdit from '@/components/configs/modeldocument/ModelProductDocumentsEdit.vue';

const defaultFilterValues = {
  orderCargoType: [],
  generated: [],
  attachmentName: ''
};

export default {
  name: 'ModelProductDocumentsList',
  components: {
    ModelProductDocumentsEdit
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { item: 'order', direction: 'asc' },
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false
    };
  },
  computed: {
    basePathComp () {
      return 'model-product-documents'
    },
    tableHeaders () {
      const headers = [
        { key: 'model_attachment_id', label: this.$t('modelfiles.model_attachment_id'), format: 'formatFromConfig', configKey: 'model_attachments', configParam: 'name', filterKey: 'attachmentName' },
        { key: 'model_class_id', label: this.$t('modelfiles.model_class_id'), format: 'formatFromConfig', configKey: 'model_classes', configParam: 'classname' },
        { key: 'order_cargo_type_id', label: this.$t('cargo.order_cargo_type_id'), format: 'formatFromConfig', configKey: 'order_cargo_types', configParam: 'name', filterKey: 'orderCargoType' },
        { key: 'product_id', label: this.$t('product.name'), format: 'formatFromConfig', configKey: 'products', configParam: 'name' },
        { key: 'generated', label: this.$t('modelfiles.auto_generate'), format: 'formatYesNo', filterKey: 'generated' },
        { key: 'order', label: this.$t('general.item.order'), sortable: true }
      ];
      const actions = [];
      if (this.canEdit) {
        actions.push({ label: this.$t('general.actions.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-sm btn-primary' });
      } else {
        actions.push({ label: this.$t('general.actions.view'), emit: 'editItem', bicon: 'eye-fill', class: 'btn-sm btn-secondary' });
      }
      if (this.canDelete) {
        actions.push({ label: this.$t('general.actions.delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-sm btn-danger', ifconfig: { param: 'deleted_at', value: null } });
        actions.push({ label: this.$t('general.actions.enable'), emit: 'restoreItem', bicon: 'clock-history', class: 'btn-sm btn-warning', ifconfig: { param: 'deleted_at', value: null, notCheck: true } });
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    tableFilterConfig () {
      const tableFilters = {};
      tableFilters.orderCargoType = { type: 'checkbox', label: this.$t('cargo.order_cargo_type_id'), options: this.orderTypeOptions, colOnlyFilter: true };
      tableFilters.generated = { type: 'checkbox', label: '', options: this.yesNoOptions, colOnlyFilter: true };
      tableFilters.generated = { type: 'checkbox', label: '', options: this.yesNoOptions, colOnlyFilter: true };
      tableFilters.attachmentName = { type: 'text', label: '', colOnlyFilter: true };
      return tableFilters;
    },
    canDelete () {
      return !this.loading && this.ApiRequest.userHasPermission('delete-model-product-documents');
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission('edit-model-product-documents');
    },
    orderTypeOptions () {
      return this.$store.getters.getConfigOptions('order_cargo_types', this.$i18n.locale);
    },
    yesNoOptions () {
      return this.BasicHelper.yesNoOptions();
    },
    requestPath () {
      let path = this.ApiRequest.addGetParamsToPath(this.basePathComp + '?perPage=' + this.paging.limit + '&page=' + this.paging.page, this.filterValues, true);
      if (this.tableSorting.item) {
        path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
      }
      return path;
    },
    tableActions () {
      const actions = { extra: [], download: false };
      if (this.canEdit) {
        actions.add = { title: this.$t('general.actions.add') };
      }
      return actions;
    }
  },
  methods: {
    getTableData () {
      if (this.basePathComp) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        this.loading = true;
        this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.tableItems = response.data;
            this.paging = response.paging;
            this.loading = false;
          }
        });
      }
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    deleteItem (el, approved) {
      if (approved) {
        this.setDeleted(el, 1);
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t('partners.actions.deleteTitle'), this.$t('partners.actions.deleteMessage', { name: this.BasicHelper.getTranslation(el.name), code: el.code }))
      }
    },
    restoreItem (el) {
      this.setDeleted(el, 0);
    },
    setDeleted (el, isDeleted) {
      this.loading = true;
      this.ApiRequest.request(this.basePathComp + '/deleted', this.ApiRequest.REQUEST_POST, { item: { id: el.id, deleted: isDeleted } }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        } else {
          this.getTableData();
        }
      });
    },
    editItem (el) {
      this.$refs.editElFrm.show(el);
    },
    addItem (el) {
      this.editItem(null);
    }
  },
  mounted () {
    this.getTableData();
    this.isMounted = true;
  },
  watch: {
    basePathComp: {
      immediate: true,
      handler (val) {
        if (val) {
          this.isMounted = false;
          this.getTableData();
          this.isMounted = true;
        }
      }
    }
  }
}
