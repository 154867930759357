import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  name: 'FactoryProductD365Batch',
  data () {
    return {
      countryCode: null,
      loading: false,
      factoryProductId: null,
      isBatchSupported: true
    }
  },
  validations: {
    countryCode: { required },
    factoryProductId: { required }
  },
  props: {},
  computed: {
    title () {
      return this.$t('factoryproduct.createD365Batch')
    },
    countryOptions () {
      return this.$store.getters.getCountryOptions();
    }
  },
  methods: {
    show (factoryProductId, countryCode, isBatchSupported) {
      this.isBatchSupported = isBatchSupported;
      this.factoryProductId = factoryProductId;
      this.countryCode = countryCode;
      this.$refs['modal-window'].show();
    },
    close () {
      this.$refs['modal-window'].hide();
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        this.ApiRequest.request('factory-products/d365-batch', this.ApiRequest.REQUEST_POST, { item: { id: this.factoryProductId, countrycode: this.countryCode } }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$emit('saved')
            this.close();
          }
          this.loading = false;
        });
      }
    }
  },
  mounted () {
    this.$store.dispatch('loadDynamicConfig', { type: 'Countries' });
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
