<template>
  <b-modal
    ref="modal"
    size="lg"
    :showIssueReport="false"
    :title="$t('cargo.qualityTestEnablingModal.title')"
  >
    <div class="modal-body">
      <b-form-checkbox-group :labelClass="`p-1 w-100`" controlClass="commentTypeIcons" :disabled-on-click="true" v-model="comment.availableCommentTypes" :options="getCommentTypes.filter(el => comment.availableCommentTypes.includes(el.value))" />
      <textarea
          v-model="comment.comment"
          class="form-control resize-none"
          :class="[ !comment.comment ? 'border-danger' : '' ]"
          rows="3"
      />
    </div>
    <div class="modal-footer">
      <button
          type="button"
          class="btn btn-warning"
          @click="$refs.modal.hide()"
      >
        {{ $t('cargo.qualityTestEnablingModal.cancel') }}
      </button>
      <button
          :disabled="isSubmitting || !comment.comment"
          type="button"
          class="btn btn-primary"
          @click="saveComment"
      >
        {{ $t('cargo.qualityTestEnablingModal.save') }}
      </button>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: 'QualityTestEnablingModal',
  props: {
    cargoId: Number
  },
  data () {
    return {
      comment: {
        id: null,
        comment: '',
        availableCommentTypes: []
      },
      isSubmitting: false
    }
  },
  methods: {
    show () {
      this.$refs.modal.show();
    },
    saveComment () {
      if (!this.isSubmitting) {
        this.isSubmitting = true;
        this.ApiRequest.request(this.basePathComp + '/edit', this.ApiRequest.REQUEST_POST, { item: this.comment }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.comment.comment = '';
            this.$emit('commentSaved');
            this.$refs.modal.hide();
          }
        });
        this.isSubmitting = false;
      }
    }
  },
  computed: {
    basePathComp () {
      if (this.cargoId && this.modelClassId) {
        return 'comments/' + this.modelClassId + '/' + this.cargoId;
      }
      return null;
    },
    commentTypeOptions () {
      return this.$store.getters.getConfigOptions('comment_types', this.$i18n.locale, null, null, false, false, false, ['iconClass', 'color', 'name', 'availableClasses']);
    },
    modelClassId () {
      return this.$store.getters.getParamFromConfigByFilter('model_classes', 'classname', 'EcoBaltia\\MWC\\Models\\Order\\Cargo', 'id');
    },
    getCommentTypes () {
      const validComentTypeIds = []
      Object.values(this.commentTypeOptions).forEach(el => {
        if (el.availableClasses.includes(this.modelClassId)) {
          validComentTypeIds.push(el.value)
        }
      })
      const filtered = this.commentTypeOptions.filter(el => validComentTypeIds.includes(el.value))
      if (filtered != null && filtered != undefined) {
        return filtered.map(el => {
          return { value: el.value, code: el.code, text: el.name[this.$i18n.locale], bicon: el.iconClass, color: el.color, colorCheckbox: true }
        })
      }
      return []
    }
  },
  mounted () {
    this.getCommentTypes.forEach(el => {
      if (el.code === 'COM_3') {
        this.comment.availableCommentTypes.push(el.value)
      }
    });
  }
}
</script>
