import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';

const defaultSummableParameter = {
  id: null,
  type_id: null,
  active: false,
  showInCertificate: false,
  showForTester: false
};

export default {
  name: 'SummableParameters',
  data () {
    return {
    }
  },
  components: {
  },
  props: {
    summableParameters: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  validations: {
    summableParameters: {
      $each: helpers.forEach({
        type_id: { required },
        active: { required },
        showInCertificate: { required },
        showForTester: { required }
      })
    }
  },
  computed: {
    typeOptions () {
      return this.$store.getters.getConfigOptions('summable_parameter_types', this.$i18n.locale);
    },
    yesNoOptions () {
      return this.BasicHelper.yesNoOptions();
    }
  },
  methods: {
    addItem () {
      const el = this.BasicHelper.cloneObject(defaultSummableParameter);
      this.summableParameters.push(el);
    },
    removeItem (index) {
      if (this.summableParameters[index].id) {
        this.summableParameters[index].deleted = true;
        this.$forceUpdate();
      } else {
        this.summableParameters.splice(index, 1);
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  mounted () {
  },
  watch: {
  }
}
