import FactoryProductPicker from '@/components/pet/factoryproduct/picker/FactoryProductPicker.vue';
import FactoryProductOverview from '@/components/pet/product/factoryProductOverview/FactoryProductOverview.vue'
import CargoD365LineList from '@/components/pet/sales/cargo/d365linelist/CargoD365LineList.vue';

const defaultFilterValues = {
  product: ''
};
export default {
  name: 'FactoryProductOverview',
  components: {
    CargoD365LineList,
    FactoryProductOverview,
    FactoryProductPicker
  },
  props: {
    elCode: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      isMounted: false,
      factory_product: null,
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      paging: { limit: 20, page: 1, total: 0, pages: 0 }
    };
  },
  computed: {
    baseTransParam () {
      return 'fed-products';
    },
    tableHeaders () {
      const headers = [];

      headers.push({ key: 'factoryProductCode', label: this.$t('fed-products.factoryproduct'), sortable: false, headerClass: 'w-20' });
      headers.push({ key: 'fed_at', label: this.$t('fed-products.date'), sortable: false, type: 'datetime', filterKey: 'fed_at' });
      headers.push({ key: 'flow_id', label: this.$t(this.baseTransParam + '.flow_id'), format: 'formatFromConfig', configKey: 'line_flows', configParam: 'flowcode' })
      headers.push({ key: 'usageType', label: this.$t(this.baseTransParam + '.usageType'), type: 'slot', slotcode: 'usageType' })
      headers.push({ key: 'product_id', label: this.$t('fed-products.product_id'), sortable: false, format: 'formatFromConfig', configKey: 'products', configParam: 'name', headerClass: 'w-20', filterKey: 'product' });
      headers.push({ key: 'type_id', label: this.$t('fed-products.type_id'), format: 'formatFromConfig', configKey: 'feeding_types', configParam: 'name' });
      headers.push({ key: 'quantity', label: this.$t('fed-products.quantity'), sortable: false, format: 'formatDecimal', precision: 0 });
      headers.push({ key: 'measure_type_id', label: this.$t('fed-products.measure_type_id'), format: 'formatFromConfig', configKey: 'measure_types', configParam: 'name' });
      headers.push({ key: 'factory_product.countrycode', label: this.$t('factory-product.countrycode') });
      headers.push({ key: 'cargo', label: this.$t('fed-products.cargo'), type: 'slot', slotcode: 'cargo' });
      return headers
    },
    tableFilterConfig () {
      const filters = { };
      filters.product = { type: 'text', label: '', colOnlyFilter: true };
      return filters;
    },
    requestPath () {
      let path = 'factory-products/factoryProductOverview/';
      if (this.elCode != null) {
        this.filterValues.code = this.elCode
      } else {
        this.filterValues.code = this.$route.params.code
      }
      path = this.ApiRequest.addGetParamsToPath(path + '?perPage=' + this.paging.limit + '&page=' + this.paging.page, this.filterValues, true);
      path += '&order=fed_at,desc';
      return path;
    },
    tableActions () {
      const actions = { download: false };
      return actions;
    },
    tableExpandConfig () {
      return { component: 'slot' }
    }
  },
  methods: {
    updateFilter () {
      this.setComponentData(this.factory_product.code);
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.setComponentData(this.factory_product.code);
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.setComponentData(this.factory_product.code);
    },
    setComponentData (id) {
      if (id) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.loading = true;
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else if (response.data) {
            this.factory_product = response.data
            this.paging = response.paging;
            if (this.factory_product.fedFactoryFiltered != undefined) {
              this.tableItems = this.factory_product.fedFactoryFiltered
            }
          } else {
            this.factory_product = null;
          }
          this.loading = false;
        });
      }
    }
  },
  mounted () {
    if (this.elCode != null) {
      this.filterValues.code = this.elCode
    } else {
      this.filterValues.code = this.$route.params.code
    }
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.setComponentData(this.elCode != null ? this.elCode : this.$route.params.code);
    this.isMounted = true;
  },
  watch: {
  }
}
