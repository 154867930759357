import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

const defaultEl = {
  id: null,
  base_config_id: null,
  category_id: null,
  additionalData: {
    showCertificateCalculatedValue: false,
    showCertificateInputValue: false,
    showTesterInputValue: false,
    showTesterCalculatedValue: false,
    groupCode: null,
    maxOptionSelect: null,
    defaultSelectedOptions: []
  },
  measurement_id: null,
  name: {},
  order: 0,
  isOption: false,
  availableQualityMeasurementOptions: []
};

const additionalDataDef = {
  showCertificateCalculatedValue: false,
  showCertificateInputValue: false,
  showTesterInputValue: false,
  showTesterCalculatedValue: false,
  groupCode: null
}
const checkMeasurement = (value, siblings, vm) => {
  if (value == null && vm.form.isOption == false) {
    return false;
  }
  return true;
}
export default {
  name: 'MeasurementEdit',
  data () {
    return {
      form: this.BasicHelper.cloneObject(defaultEl),
      loading: false,
      additionalDataDefaults: this.BasicHelper.cloneObject(additionalDataDef)
    }
  },
  validations: {
    form: {
      base_config_id: { required },
      name: { required },
      category_id: { required },
      measurement_id: { checkMeasurement },
      additionalData: {
        groupCode: { required }
      }
    }
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    },
    baseConfigId: {
      type: Number,
      default: null
    }
  },
  computed: {
    baseTransParam () {
      return 'menu.quality';
    },
    basePerm () {
      return this.$route.meta.configPermabase;
    },
    getTitle () {
      if (this.form.id) {
        return this.$t(this.baseTransParam + '.actions.edit');
      }
      return this.$t(this.baseTransParam + '.actions.new');
    },
    qualityMeasurementOptions () {
      return this.$store.getters.getConfigOptions('quality_measurement_options', this.$i18n.locale);
    },
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-' + this.basePerm);
    },
    measureTypeOptions () {
      return this.$store.getters.getConfigOptions('measure_types', this.$i18n.locale);
    },
    typeOptions () {
      return this.$store.getters.getConfigOptions('product_types', this.$i18n.locale);
    },
    baseConfigOptions () {
      return this.$store.getters.getConfigOptions('quality_measurement_base_config', this.$i18n.locale);
    },
    categoryOptions () {
      let dataSet = this.$store.getters.getConfigOptions('quality_measurement_categories', this.$i18n.locale)
      if (dataSet.length > 0) {
        dataSet = this.BasicHelper.sortArrayByKeyValue(dataSet, 'order')
      }
      return dataSet
    },
    categoryOptionsIsOptions () {
      return this.$store.getters.getConfigOptions('quality_measurement_categories', this.$i18n.locale, 'isOption', 1);
    },
    productOptions () {
      return this.$store.getters.getConfigOptions('products', this.$i18n.locale);
    }
  },
  methods: {
    show (elData) {
      if (elData) {
        this.form = this.BasicHelper.cloneObject(defaultEl);
        if (elData.additionalData.length == 0) {
          elData.additionalData = {}
        }
        this.BasicHelper.syncObjectValuesByKeys(this.form, this.BasicHelper.cloneObject(elData));
      } else {
        this.form = this.BasicHelper.cloneObject(defaultEl);
      }
      this.form.base_config_id = this.baseConfigId;
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = this.BasicHelper.cloneObject(this.form);
        this.ApiRequest.request('config/mwc-custom/product-quality-measurement/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$store.dispatch('reloadConfig');
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
