const defaultFilterValues = {
  vendorName: null,
  orgNumberOrPersonCode: null,
  productName: null,
  productAx2009code: null,
  productD365Code: null,
  qualityCode: null,
  rate: null,
  endAt: null
}
export default {
  name: 'VendorProductsReport',
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      paging: { limit: 20, page: 1, total: 0, pages: 0 }
    }
  },
  props: {
  },
  components: {
  },
  computed: {
    tableHeaders () {
      const headers = [
        { key: 'vendorName', label: this.$t('partners.vendors.vendor'), sortable: true, filterKey: 'vendorName', type: 'slot', slotcode: 'vendor' },
        { key: 'orgNumberOrPersonCode', label: this.$t('partners.vendors.orgNumberOrPersonCode'), sortable: true, filterKey: 'orgNumberOrPersonCode' },
        { key: 'productId', label: this.$t('product.rates.product'), format: 'formatFromConfig', configKey: 'products', configParam: 'name', sortable: true, filterKey: 'productName' },
        { key: 'productAx2009code', label: this.$t('product.ax2009code'), sortable: true, filterKey: 'productAx2009code' },
        { key: 'productD365Code', label: this.$t('product.d365_product_id'), sortable: true, filterKey: 'productD365Code' },
        { key: 'qualityId', label: this.$t('product.default_quality_id'), sortable: true, type: 'slot', slotcode: 'quality' },
        { key: 'rate', label: this.$t('product.rates.active'), format: 'formatDecimal', precision: 2, sortable: true },
        { key: 'endAt', label: this.$t('product.rates.endAt'), type: 'date', sortable: true }
      ];
      headers.push({ key: 'actions', actions: [] });
      return headers;
    },
    tableExpandConfig () {
      return null;
    },
    tableFilterConfig () {
      const filters = { };
      filters.vendorName = { type: 'text', label: '', colOnlyFilter: true };
      filters.orgNumberOrPersonCode = { type: 'text', label: '', colOnlyFilter: true };
      filters.productName = { type: 'text', label: '', colOnlyFilter: true };
      filters.productAx2009code = { type: 'text', label: '', colOnlyFilter: true };
      filters.productD365Code = { type: 'text', label: '', colOnlyFilter: true };
      filters.qualityCode = { type: 'checkbox', label: this.$t('product.default_quality_id'), options: this.qualityOptions, class: 'col-12' };
      return filters;
    },
    requestPath () {
      return this.ApiRequest.addGetParamsToPath('vendors/products-report?perPage=' + this.paging.limit + '&page=' + this.paging.page, this.filterValues, true);
    },
    qualityOptions () {
      const options = this.$store.getters.getConfigOptions('product_qualities', this.$i18n.locale);
      options.unshift({ code: 'null', text: this.$t('quality.code') });
      return options;
    }
  },
  methods: {
    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
      this.loading = true;
      this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, [], (response) => {
        if (response.data) {
          this.tableItems = response.data;
          this.paging = response.paging;
        }
        this.loading = false;
      }, true);
    },
    getQualityBadgeColors (qualityId) {
      return this.$store.getters.getQualityBadgeColors(qualityId);
    },
    getVendorUrl (el) {
      return '/salesmodule/vendors/view/' + el.vendorCode;
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.getTableData();
  }
}
