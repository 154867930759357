import UserPicker from 'ebg-vue-common/src/components/user/picker/UserPicker.vue'

const defaultEl = {
  id: null,
  user_id: null,
  role_id: null,
  roles: [],
  hours: '',
  productivity: 1
};

export default {
  name: 'UserWorklist',
  data () {
    return {
      content: (this.value ? this.value : []),
      displayErrors: false,
      loading: false
    }
  },
  props: {
    value: {
      type: Array,
      default () {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: true
    },
    isShift: {
      type: Boolean,
      default: false
    },
    brigadeId: {
      type: Number,
      default: null
    },
    viewmode: {
      type: Boolean,
      default: false
    }
  },
  components: {
    UserPicker
  },
  computed: {
    formErrors () {
      const errors = {};
      if (this.displayErrors) {
        this.content.forEach((user, idx) => {
          if (!user.deleted) {
            if (!user.user_id || !user.role_id) {
              errors[idx] = {};
              if (!user.user_id) {
                errors[idx].user_id = true
              }
              if (!user.role_id) {
                errors[idx].role_id = true
              }
            }
          }
        });
      }
      return errors;
    },
    roleOptions () {
      return this.BasicHelper.sortArrayByKeyValue(this.$store.getters.getRoleOptions(this.$i18n.locale, 'shiftRole', 1));
    }
  },
  methods: {
    updateValue () {
      this.$emit('input', this.content);
      this.$emit('changed', this.content);
    },
    addItem () {
      this.content.push(this.BasicHelper.cloneObject(defaultEl));
      this.updateValue();
    },
    removeItem (index) {
      if (this.content[index].id) {
        this.content[index].deleted = true;
        this.$forceUpdate();
      } else {
        this.content.splice(index, 1);
      }
      this.updateValue();
    },
    isValid () {
      this.displayErrors = true;
      return (Object.values(this.formErrors).length == 0);
    },
    hideValidation () {
      this.displayErrors = false;
    },
    getUserOptionText (item) {
      return (item ? item.fullname : '');
    },
    userChanged (index, val) {
      this.content[index].user_id = (val ? val.id : null)
      this.updateValue();
    },
    getUserRoleOptionFilters (index) {
      if (this.content[index] && this.content[index].user) {
        return { value: this.content[index].user.roles };
      }
      return { value: [] };
    },
    replaceWithBrigadeWorkers () {
      if (this.brigadeId) {
        this.loading = true;
        this.ApiRequest.request('brigades/view/' + this.brigadeId, this.ApiRequest.REQUEST_GET, [], (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, self);
          } else if (response.data.brigadeusers) {
            const newUsers = [];
            response.data.brigadeusers.forEach(el => {
              const newEl = this.BasicHelper.cloneObject(defaultEl);
              newEl.user_id = el.user_id;
              newEl.roles = el.roles;
              newEl.user = el.user;
              newEl.role_id = el.role_id;
              newUsers.push(newEl);
            });
            this.content = newUsers;
            this.updateValue();
          }
          this.loading = false;
        });
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        if (val != this.content) {
          this.content = (val || []);
        }
      }
    }
  }
}
