export default {
  name: 'PartnerCheck',
  props: {
    isApproved: {
      type: Boolean,
      default: false
    },
    approvalComment: {
      type: String,
      default: ''
    },
    approvalDate: {
      type: String,
      default: ''
    },
    approvalUser: {
      type: Object,
      default: {
        fullname: ''
      }
    }
  },
  computed: {
    getValueForCheckBox () {
      return !!this.approvalDate;
    }
  },
  methods: {
    getDateText (approvalDate) {
      return this.$options.filters.formatDate(approvalDate, this.$t('general.dateFormat'), true);
    }
  }
}
