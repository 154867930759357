import { render, staticRenderFns } from "./ShiftFlowProduced.vue?vue&type=template&id=0b06a99b"
import script from "./ShiftFlowProduced.js?vue&type=script&lang=js&external"
export * from "./ShiftFlowProduced.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports