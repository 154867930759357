import SalesOrderFactoryProducts from '@/components/pet/sales/salesorder/factoryproducts/SalesOrderFactoryProducts.vue'
import FactoryProductPicker from '@/components/pet/factoryproduct/picker/FactoryProductPicker.vue'
import PetHelper from '@/helpers/PetHelper';
import ReserveProduct from '@/components/pet/sales/salesorder/linelist/ReserveProduct.vue'

const defaultLine = {
  id: null,
  product_id: null,
  quality_id: null,
  quality_code_id: null,
  quantity: '',
  pieces: '',
  editmode: true,
  factoryproducts: [],
  cargo_id: 'null'
}
export default {
  name: 'SalesOrderLineList',
  components: {
    ReserveProduct,
    SalesOrderFactoryProducts,
    FactoryProductPicker
  },
  props: {
    canPrintLabel: {
      type: Boolean,
      default: false
    },
    orderId: {
      type: Number,
      default: null
    },
    lines: {
      type: Array,
      default: () => null
    },
    readonly: {
      type: Boolean,
      default: true
    },
    findBySpecificCode: {
      type: Boolean,
      default: true
    },
    specificQuantity: {
      type: Boolean,
      default: false
    },
    editProduct: {
      type: Boolean,
      default: true
    },
    poproducts: {
      type: Array,
      default: () => null
    },
    editFactoryProducts: {
      type: Boolean,
      default: false
    },
    qrReaderDeviceIds: {
      type: Array,
      default: () => []
    },
    displayRate: {
      type: Boolean,
      default: false
    },
    cargo_id: {
      type: Number,
      default: null
    },
    validator: {
      default: () => null
    },
    isTransferOrder: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      currentReq: null,
      solines: [],
      products: [],
      addingProduct: false,
      addFactoryProducts: false,
      addedProduct: null
    };
  },
  validations: {
  },
  computed: {
    basePathComp () {
      return 'cargo-orders/view/' + this.cargo_id
    },
    baseTransParam () {
      return 'sales-order.lines';
    },
    tableHeaders () {
      const headers = [
        { key: 'product_id', label: this.$t(this.baseTransParam + '.product_id'), type: 'slot', slotcode: 'product' },
        { key: 'quality_code_id', label: this.$t(this.baseTransParam + '.qualitycode'), type: 'slot', slotcode: 'quality_code' },
        { key: 'qualityinput', label: this.$t(this.baseTransParam + '.minimal_quality'), type: 'slot', slotcode: 'quality' },
        { key: 'allowBroken', label: this.$t(this.baseTransParam + '.allowBroken'), format: 'formatYesNo' },
        { key: 'pieces', label: this.$t(this.baseTransParam + '.pieces'), type: 'slot', slotcode: 'pieces' },
        { key: 'quantity', label: this.$t(this.baseTransParam + '.quantity'), type: 'slot', slotcode: 'quantity' },
        { key: '', label: '', type: 'slot', slotcode: 'reserveProduct', ifconfig: { func: this.checkReservationCondition, useFunc: true } }
      ];

      if (this.displayRate) {
        headers.push({ key: 'activeRate', label: this.$t(this.baseTransParam + '.activeRate', { currency: PetHelper.getCurrencyNameFromCode() }), type: 'slot', slotcode: 'rate' })
      }
      const actions = [];
      if (this.canEdit && this.editProduct) {
        actions.push({ label: this.$t('general.actions.edit'), emit: 'toggleEditMode', bicon: 'pencil-fill', class: 'btn-sm btn-warning', ifconfig: { useFunc: true, func: this.canDeleteLine } });
        actions.push({ label: this.$t('general.actions.delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-sm btn-danger', ifconfig: { useFunc: true, func: this.canDeleteLine } });
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    canEdit () {
      return !this.readonly;
    },
    canEditSales () {
      return this.canEdit && this.ApiRequest.userHasPermission('edit-sales');
    },
    productOptions () {
      return this.PetHelper.getFeadableBaleProducts();
    },
    productFilters () {
      return { value: this.products.map(el => el.product_id) }
    },
    qualityOptions () {
      return this.$store.getters.getConfigOptions('product_qualities', this.$i18n.locale);
    },
    tableExpandConfig () {
      return { component: 'slot' }
    },
    factoryPickerFilters () {
      const filters = {};
      filters.product_id = this.solines.map(el => el.product_id);
      filters.withRemainingQuantity = true;
      filters.withQuality = true;
      filters.status = 'WAREHOUSE';
      filters.canSell = 1;
      filters.withSaleOrderProducts = 1;
      return filters;
    },
    totalInfo () {
      const res = {
        pieces: 0,
        assembledPieces: 0,
        quantity: 0,
        assembledQuantity: 0
      }
      this.solines.forEach(el => {
        res.pieces += (el.pieces ? el.pieces : 0)
        res.assembledPieces += (el.assembledPieces ? el.assembledPieces : 0);
        res.quantity += (el.quantity ? el.quantity : 0);
        res.assembledQuantity += (el.assembledQuantity ? el.assembledQuantity : 0);
      });
      return res;
    }
  },
  methods: {
    reserveProduct (item) {
      this.$refs.reserveProduct.show(item);
    },
    reserveProductSave (item) {
      this.loading = true;
      this.currentReq = this.ApiRequest.request('cargo-orders/reserveProduct', this.ApiRequest.REQUEST_POST, { item }, (response) => {
        if (response.data) {
          this.getTableData();
        }
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        }
        this.loading = false;
      });
    },
    checkReservationCondition (item = null) {
      let saleInBags = true;
      if (this.specificQuantity && item != null && this.poproducts != null) {
        const findProduct = this.poproducts.find(el => el.product_id == item.product_id)
        if (findProduct && findProduct.id && !findProduct.saleInBags) {
          saleInBags = false
        }
      }
      return this.editFactoryProducts && !saleInBags
    },
    getTableData () {
      if (this.orderId) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        const expandedLineIds = [];
        if (this.solines) {
          this.solines.forEach(el => {
            if (el._showDetails) {
              expandedLineIds.push(el.id);
            }
          });
        }
        this.loading = true;
        this.currentReq = this.ApiRequest.request(this.basePathComp, this.ApiRequest.REQUEST_GET, { withOrder: true }, (response) => {
          if (response.data) {
            this.products = response.data.order.products;
            response.data.lines.forEach(el => {
              if (expandedLineIds.includes(el.id)) {
                el._showDetails = true;
              }
            })
            this.solines = response.data.lines;
            this.loading = false;
          }
        });
      }
    },
    addLine () {
      defaultLine.cargo_id = this.cargo_id
      const el = this.BasicHelper.cloneObject(defaultLine);
      this.solines.push(el);
      this.$refs.tableview.$forceUpdate();
    },
    deleteLine (el) {
      el.deleted = 1;
      const idx = this.solines.findIndex(el => el.deleted == 1);
      this.solines.splice(idx, 1);
      this.$refs.tableview.$forceUpdate();
    },
    canDeleteLine (el) {
      return (this.editProduct || el.id == null) && (el.factoryproducts.length == 0);
    },
    productChanged (val, rowId) {
      if (val) {
        const prod = this.products.find(el => el.product_id == val);
        if (prod) {
          this.solines[rowId].quality_id = (prod.min_quality_id || null);
          this.solines[rowId].allowBroken = !!prod.allowBroken;
        } else if (this.isTransferOrder) {
          this.solines[rowId].allowBroken = true;
        }
        this.solines[rowId].quality_code_id = null;
      }
    },
    toggleEditMode (el) {
      if (el.editmode) {
        el.editmode = false;
      } else {
        el.editmode = true;
      }
      this.$refs.tableview.$forceUpdate();
    },
    assembledChanged (data, lineId) {
      const rowId = this.solines.findIndex(el => el.id == lineId);
      if (rowId >= 0) {
        this.solines[rowId].assembledPieces = data.assembledPieces;
        this.solines[rowId].assembledQuantity = data.assembledQuantity;
      }
    },
    productAdded (prod) {
      if (!this.addingProduct && prod) {
        this.addingProduct = true;
        this.ApiRequest.request('cargo-orders/add-factory-product/' + this.cargo_id, this.ApiRequest.REQUEST_POST, { factoryproduct_id: prod.id, specificQuantityValue: prod.specificQuantityValue != undefined ? prod.specificQuantityValue : null }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.getTableData();
            this.addedProduct = null;
            this.$store.commit('toastAdd', {
              context: 'success',
              message: this.$t('cargo.factoryproduct.addsuccess', {}, { code: prod.code })
            });
          }
          this.$refs.factoryproductpicker.clearSearch();
          this.$refs.factoryproductpicker.focusInput();
          this.addingProduct = false;
        });
      }
    },
    focusSearch (e) {
      if (e) {
        setTimeout(() => {
          if (this.$refs.factoryproductpicker) {
            this.$refs.factoryproductpicker.focusInput();
          }
        }, 100);
      }
    },
    getLineQualityCodeOptions (line) {
      const product = this.products.find(product => {
        return product.product_id === line.product_id;
      })
      if (!product) {
        return [];
      }

      const filterKey = product.qualitiesCodes.length ? 'id' : null;

      return this.$store.getters.getConfigOptions('product_qualities_code', this.$i18n.locale, filterKey, product.qualitiesCodes, false, false, true);
    }
  },
  mounted () {
    if (this.poproducts) {
      this.products = this.poproducts;
    }
    if (this.lines == null) {
      this.getTableData();
    }
  },
  watch: {
    lines: {
      immediate: true,
      handler (val) {
        if (val !== null) {
          this.solines = val;
        }
      }
    },
    orderId: {
      immediate: true,
      handler (val) {
        if (this.lines == null) {
          this.getTableData()
        }
      }
    },
    poproducts: {
      immediate: true,
      handler (val) {
        if (val) {
          this.products = val;
        }
      }
    }
  }
}
