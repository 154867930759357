import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

const defaultEl = {
  id: null,
  code: '',
  name: {},
  text: {}
};

export default {
  name: 'DevicePrinterTemplateEdit',
  data () {
    return {
      editEl: defaultEl,
      loading: false
    }
  },
  validations: {
    editEl: {
      code: { required },
      name: { required },
      order: { },
      text: { }
    }
  },
  props: {
    editPerm: {
      type: String,
      default: 'edit-settings'
    },
    basePath: {
      type: String,
      default: ''
    },
    isModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getTitle () {
      if (this.editEl.id) {
        return this.$t('general.actions.edit');
      }
      return this.$t('general.actions.new');
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission(this.editPerm);
    }
  },
  methods: {
    show (elData) {
      if (elData) {
        this.editEl = this.BasicHelper.cloneObject(elData);
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
      }
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        const self = this;
        this.ApiRequest.request(this.basePath + '/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, function (response) {
          if (response.error) {
            self.ApiRequest.displayErrorDialog(response, self);
          } else {
            self.$store.dispatch('reloadConfig');
            self.$emit('saved', response.data);
            self.close();
          }
          self.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
