import { render, staticRenderFns } from "./Characterization.vue?vue&type=template&id=072172aa"
import script from "./Characterization.js?vue&type=script&lang=js&external"
export * from "./Characterization.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports