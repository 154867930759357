import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import UserPicker from 'ebg-vue-common/src/components/user/picker/UserPicker.vue'

const product = {
  sales_manager: null,
  warehouse_editor: null,
  product_type: null,
  productIds: []
};
export default {
  name: 'ManagersBulkAdd',
  data () {
    return {
      productData: product,
      loading: false
    }
  },
  validations: {
    productData: {
      product_type: { required }
    }
  },
  components: {
    UserPicker
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getTitle () {
      return this.$t('product.rates.bulkAddManagers');
    },
    getProductTypesOptions () {
      return this.$store.getters.getConfigOptions('product_types', this.$i18n.locale);
    },
    productOptions () {
      return this.$store.getters.getConfigOptions('products', this.$i18n.locale, 'type_id', this.productData.product_type);
    }
  },
  methods: {
    resetProducts () {
      this.productData.productIds = [];
    },
    show () {
      this.$refs['modal-window'].show();
    },
    checkProduct () {
      this.productData.productIds = []
    },
    getUserOptionText (item) {
      return (item ? item.fullname : '');
    },
    save () {
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.loading = true;
        this.ApiRequest.request('rates/bulk-edit-managers', this.ApiRequest.REQUEST_POST, { item: this.productData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
            this.loading = false;
          } else {
            this.close();
            this.$emit('updateGetTableList');
            this.loading = false;
          }
        });
      }
    },
    close () {
      this.$refs['modal-window'].hide();
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
