import useVuelidate from '@vuelidate/core';

export default {
  name: 'WarehousePrint',
  data () {
    return {
      selectedDevice: null,
      loading: true,
      sku: null,
      forcePrint: false
    };
  },
  computed: {},
  methods: {
    print () {
      if (this.selectedDevice) {
        const requestBody = {
          printPositionCodes: [],
          printerCode: this.selectedDevice,
          forcePrint: this.forcePrint
        };
        for (const [, rack] of Object.entries(this.sku.racks)) {
          for (const [, level] of Object.entries(rack.levels)) {
            for (const [, bin] of Object.entries(level.bins)) {
              if (bin.isSelected) {
                requestBody.printPositionCodes.push(bin.code);
              }
            }
          }
        }
        this.loading = true;
        this.ApiRequest.request('warehouses/stock-keeping-units/print', this.ApiRequest.REQUEST_POST, requestBody, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.close();
          }
          this.loading = false;
        });
      }
    },
    clickLevel (levelIndex, rackIndex) {
      const level = this.sku.racks[rackIndex].levels[levelIndex];
      for (const [binIndex, bin] of Object.entries(level.bins)) {
        if (!bin.isDisabled) {
          this.sku.racks[rackIndex].levels[levelIndex].bins[binIndex].isSelected = !bin.isSelected;
        }
      }
      this.sku.racks[rackIndex].levels[levelIndex].isSelected = !level.isSelected;
    },
    clickBin (rackIndex, levelIndex, binIndex) {
      const bin = this.sku.racks[rackIndex].levels[levelIndex].bins[binIndex];
      if (!bin.isDisabled) {
        this.sku.racks[rackIndex].levels[levelIndex].bins[binIndex].isSelected = !bin.isSelected;
      }
    },
    clickRack (rackIndex) {
      const rack = this.sku.racks[rackIndex];
      for (const [levelIndex, level] of Object.entries(rack.levels)) {
        for (const [binIndex] of Object.entries(level.bins)) {
          const bin = this.sku.racks[rackIndex].levels[levelIndex].bins[binIndex];
          if (!bin.isDisabled) {
            this.sku.racks[rackIndex].levels[levelIndex].bins[binIndex].isSelected = !bin.isSelected;
          }
        }
      }
      this.sku.racks[rackIndex].isSelected = !rack.isSelected;
    },
    show (id) {
      this.ApiRequest.request('warehouses/' + id + '/stock-keeping-units', this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else if (response.data) {
          this.sku = this.BasicHelper.cloneObject(response.data);
        }
        this.loading = false;
      })
      this.$refs['modal-window'].show();
    },
    close () {
      this.$refs['modal-window'].hide();
    },
    displayForcePrint () {
      return (this.environment !== 'production' && this.ApiRequest.userHasPermission('force-print-test'));
    }
  },
  mounted () {
    this.isMounted = true;
  },
  setup () {
    return { v$: useVuelidate({ $stopPropagation: true }) }
  }
}
