import WarehouseLevel from '@/components/pet/warehouse/sku/WarehouseLevel.vue';
import WarehouseBin from '@/components/pet/warehouse/sku/WarehouseBin.vue';

export default {
  name: 'WarehouseRack',
  data () {
    return {
    }
  },
  validations: {
  },
  components: {
    WarehouseBin,
    WarehouseLevel
  },
  props: {
    rack: {
      type: Object,
      default: null
    },
    selectedChunk: {
      type: String,
      default: null
    },
    totalLevels: {
      type: Number,
      default: 0
    },
    selectedBinCode: {
      type: String,
      default: null
    },
    isFactoryProductSelectable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
  },
  methods: {
  }
}
