import DeviceScaleInput from '@/components/pet/device/scaleinput/DeviceScaleInput.vue';

export default {
  name: 'CargoWeightings',
  components: { DeviceScaleInput },
  data () {
    return {
      showAllWeightFields: true,
      selectedWorkstationId: null
    }
  },
  props: {
    cargo: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    totalPieces () {
      let val = 0;
      if (this.cargo) {
        this.cargo.d365lines.forEach(el => {
          if (el.pieces) {
            val += parseFloat(el.pieces)
          }
        });
      }
      return val;
    },
    totalWeight () {
      let val = 0;
      if (this.cargo) {
        this.cargo.d365lines.forEach(el => {
          if (el.quantity) {
            val += parseFloat(el.quantity);
          }
        });
      }
      return val;
    },
    weightDelta () {
      const val = parseFloat(this.cargo.weightStart_1) + parseFloat(this.cargo.weightStart_2 ?? '0') - parseFloat(this.cargo.weightEnd_1 ?? '0') - parseFloat(this.cargo.weightEnd_2);
      if (isNaN(val) || val == 0) {
        return '';
      }
      return val;
    },
    weightDifferenceKg () {
      if (!this.cargo) {
        return null;
      }
      return (this.weightDelta || 0) - this.totalWeight;
    },
    workstationIds () {
      return this.BasicHelper.getConfigValue('warehouses_ramps', 'id', this.cargo?.stage_id, 'workstation_ids');
    },
    workstationOptions () {
      return this.$store.getters.getConfigOptions(
        'workstations',
        this.$i18n.locale,
        'id',
        this.workstationIds,
        false,
        false,
        true,
        ['device_ids']
      );
    },
    currentWorkstation () {
      if (this.workstationOptions.length > 0) {
        if (this.selectedWorkstationId) {
          return this.workstationOptions.find(el => el.value == this.selectedWorkstationId);
        } else {
          this.selectedWorkstationId = this.workstationOptions[0].value;
          return this.workstationOptions[0];
        }
      } else {
        return null;
      }
    },
    deviceIds () {
      return this.currentWorkstation?.device_ids ?? [];
    }
  },
  watch: {
    cargo: {
      immediate: true,
      handler (cargo) {
        if (cargo) {
          this.showAllWeightFields = cargo.weightStart_2 && cargo.weightStart_2 > 0;
        }
      }
    }
  }
}
