export default {
  name: 'FactoryProductVisualOverview',
  props: {
    data: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    lines () {
      const res = [];
      let maxCount = 0;
      this.data.forEach(el => {
        const newEl = {
          product_id: el.product_id,
          remainingBales: (el.total.remainingBales ? el.total.remainingBales : 0),
          remainingQuantity: (el.total.remainingQuantity ? el.total.remainingQuantity : 0),
          product: this.$store.getters.getDataFromConfigById('products', el.product_id),
          style: 'color:#FFF;background-color:#000',
          width: 15
        }
        if (newEl.product && newEl.product.color) {
          newEl.style = 'background-color:' + newEl.product.color + ';color:' + this.BasicHelper.getContrastColor(newEl.product.color) + ';';
        }
        if (maxCount < newEl.remainingQuantity) {
          maxCount = newEl.remainingQuantity;
        }
        res.push(newEl)
      });
      if (maxCount > 0) {
        res.forEach(el => {
          if (el.remainingQuantity > 0) {
            el.width = 15 + Math.round(65 * el.remainingQuantity / maxCount);
          }
        });
      }
      return res;
    }
  }
}
