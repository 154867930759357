import useVuelidate from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';

const defaultEl = {
  id: null,
  name: {},
  order: 0,
  availableLineIds: [],
  availableUserIds: [],
  availableBaseConfigIds: [],
  active: true,
  isForImportedCargos: true,
  validationEnabled: true
};

export default {
  name: 'QualityCategoryEdit',
  data () {
    return {
      editEl: defaultEl,
      loading: false
    }
  },
  validations: {
    editEl: {
      name: { required },
      availableLineIds: {
        required: requiredIf(function () {
          return !this.editEl.isForImportedCargos;
        })
      },
      availableUserIds: { required },
      availableBaseConfigIds: { required },
      order: { }
    }
  },
  props: {
    editPerm: {
      type: String,
      default: 'edit-product-quality-measurements-tester-categories'
    },
    basePath: {
      type: String,
      default: ''
    },
    isModal: {
      type: Boolean,
      default: true
    },
    compactLocales: {
      type: Boolean,
      default: false
    },
    users: {
      type: Array,
      default: () => []
    },
    baseConfigs: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    getTitle () {
      if (this.editEl.id) {
        return this.$t('general.actions.edit');
      }
      return this.$t('general.actions.new');
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission(this.editPerm);
    },
    lineOptions () {
      return this.$store.getters.getConfigOptions('lines', this.$i18n.locale);
    },
    yesNoOptions () {
      return this.BasicHelper.yesNoOptions();
    },
    checkboxOption () {
      return { title: 'test1', bicon: 'check' }
    }
  },
  methods: {

    isOptionChanged () {
      if (this.editEl.isOption) this.editEl.canUseAverageData = false;
    },
    show (elData) {
      if (elData) {
        this.editEl = this.BasicHelper.cloneObject(elData);
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
      }
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        this.ApiRequest.request(this.basePath + '/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$store.dispatch('reloadConfig');
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
