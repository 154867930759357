export default {
  name: 'CargoSectionMenu',
  computed: {
    menuOption () {
      return [
        { value: 1, text: this.$t('menu.warehouse.orders-in'), code: 'menu.warehouse.orders-in.list', disabled: false },
        { value: 2, text: this.$t('menu.warehouse.orders-out'), code: 'menu.warehouse.orders-out.list', disabled: false },
        { value: 2, text: this.$t('menu.warehouse.cargos'), code: 'menu.warehouse.cargos.list', disabled: false }
      ]
    }
  }
}
