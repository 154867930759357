import ShiftProductionJournalLines from '@/components/pet/shift/productionjournal/lines/ShiftProductionJournalLines.vue'
import useVuelidate from '@vuelidate/core';
import { helpers } from '@vuelidate/validators';

const checkLineRequired = (value, siblings) => {
  if (siblings.mode == 1) {
    if (value) {
      return true;
    }
    return false;
  }
  return true;
}

const percentMulti = 1000000;
const checkLinePercentage = (value, siblings, vm, el) => {
  if (siblings.mode == 1) {
    let total = 0;
    let nonFinalProductCalculatedCostPercentageSum = 0;
    let finalProductCostPercentageSum = 0;
    const finalProductLines = [];

    el.lines.forEach(line => {
      if (!line.costPercentage) {
        line.calculatedCostPercentage = '';
        return;
      }

      const costPercentage = parseFloat(line.costPercentage);

      if (!line.isFinalProduct) {
        if (line.targetProductPercentage) {
          line.calculatedCostPercentage = costPercentage * (line.targetProductPercentage / 100);
          nonFinalProductCalculatedCostPercentageSum += parseFloat(line.calculatedCostPercentage);
        } else {
          line.calculatedCostPercentage = costPercentage;
        }
        total += parseFloat(line.calculatedCostPercentage);
      } else {
        finalProductLines.push(line);
        finalProductCostPercentageSum += costPercentage;
      }
    });

    finalProductLines.forEach(line => {
      const costPercentage = parseFloat(line.costPercentage);
      line.calculatedCostPercentage = costPercentage +
        (nonFinalProductCalculatedCostPercentageSum * costPercentage / finalProductCostPercentageSum);

      total += parseFloat(line.calculatedCostPercentage);
    });

    total = Math.round(total * percentMulti) / percentMulti;
    if (total == 100) {
      return true;
    }
    return false;
  }
  return true;
};

export default {
  name: 'ShiftProductionJournalEdit',
  components: {
    ShiftProductionJournalLines
  },
  data () {
    return {
      journal: { lines: [] },
      loading: false,
      shiftId: null,
      journalId: null
    };
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  },
  validations: {
    journal: {
      lines: {
        $each: helpers.forEach({
          costPercentage: { checkLineRequired, checkLinePercentage }
        })
      }
    }
  },
  computed: {
    getTitle () {
      if (!this.isModal) {
        return ''
      }
      return this.$t('shift.production-journal.actions.editcosts');
    },
    canEdit () {
      return !this.loading;
    },
    costPercentage () {
      let total = 0;
      this.journal.lines.forEach(line => {
        if (line.mode == 1 && line.calculatedCostPercentage) {
          total += parseFloat(line.calculatedCostPercentage);
        }
      });
      total = Math.round(total * percentMulti) / percentMulti;
      return total;
    },
    productioncost () {
      if (this.journal) {
        return parseFloat(this.journal.usedTotalCost) + parseFloat(this.trashcost);
      }
      return null;
    },
    trashcost () {
      let total = 0;
      if (this.journal && this.journal.lines) {
        this.journal.lines.forEach(el => {
          if (el.mode == 2 && parseFloat(el.costValue)) {
            total += parseFloat(el.costValue);
          }
        })
      }
      return total;
    },
    journalErrors () {
      if (this.v$ && this.journal && this.journal.id && this.v$.$invalid) {
        const res = [];
        this.v$.journal.lines.$each.$response.$errors.forEach(lineError => {
          Object.values(lineError.costPercentage).forEach(validatorError => {
            if (!res.includes(validatorError.$validator)) {
              res.push(validatorError.$validator)
            }
          });
        });
        return res;
      }
      return null;
    }
  },
  methods: {
    show (shiftId, journalId) {
      this.journal = { lines: [] };
      this.shiftId = shiftId;
      this.journalId = journalId;
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    save () {
      this.v$.$touch();
      if (!this.loading && !this.v$.$invalid) {
        this.loading = true;
        const reqData = {
          lines: [],
          sendToD365: true
        }
        Object.values(this.journal.lines).forEach(line => {
          reqData.lines.push({
            id: line.id,
            costValue: line.costValue,
            costPercentage: line.costPercentage,
            calculatedCostPercentage: line.calculatedCostPercentage,
            targetProductPercentage: line.targetProductPercentage
          });
        })
        this.ApiRequest.request('shift/update-journals/' + this.journal.id, this.ApiRequest.REQUEST_POST, reqData, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$store.commit('toastAdd', {
              context: 'success',
              message: this.$t('general.actions.saved')
            });
            this.$emit('saved', true);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    },
    updateJournal (journal) {
      if (journal) {
        this.journal = journal;
        this.v$.$touch();
        this.$forceUpdate();
      } else {
        this.journal = { lines: [] };
      }
    }
  },
  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'ShiftProductionJournalEdit' }) }
  }
}
