<template>
  <div class="shift-recipe">
    <ShiftClose :shift="currentShift" @saved="shiftSaved" />
    <div v-if="currentShift" class="mt-3">
      <b-nav-tab :options="tabOptions">
        <template v-slot:tabtitle="option">
          <template v-if="option.code == 'comments'">
            {{ option.text }}
            <template v-if="commentCount" >
              ({{ commentCount }})
            </template>
          </template>
        </template>
        <template v-slot:tabcontent="option">
          <Comments v-if="option.code == 'comments'" ref="commentcomp" modelClass="EcoBaltia\MWC\Models\Shift\Shift" :modelElementId="currentShift.id"
                    @commentCount="updateCommentCount"
          />
          <ShiftSummary v-else-if="option.code == 'summary'" ref="summary" :shiftId="currentShift.id" />
        </template>
      </b-nav-tab>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ShiftClose from '@/components/pet/shift/close/ShiftClose.vue'
import Comments from 'ebg-vue-common/src/components/common/comments/Comments.vue';
import ShiftSummary from '@/components/pet/shift/summary/ShiftSummary.vue'

export default {
  name: 'ShiftSummaryView',
  data () {
    return {
      commentCount: 0
    }
  },
  components: {
    ShiftClose,
    Comments,
    ShiftSummary
  },
  computed: {
    ...mapState({
      currentShift: state => state.pet.currentShift,
      currentFlowId: state => state.pet.currentFlowId
    }),
    tabOptions () {
      return [
        { code: 'summary', text: this.$t('shift.tabs.summary') },
        { code: 'comments', text: this.$t('shift.tabs.comments') }
      ];
    }
  },
  methods: {
    updateCommentCount (val) {
      this.commentCount = val;
    },
    shiftSaved () {
      console.log('saved');
      if (this.$refs.summary) {
        this.$refs.summary.getTableData();
      }
    }
  },
  watch: {
    currentShift: {
      immediate: true,
      handler () {
      }
    },
    currentFlowId: {
      immediate: true,
      handler () {
      }
    }
  }
}
</script>
