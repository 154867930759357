import ShiftProductsMeasurementsEdit from '@/components/pet/quality/shiftProductsMeasurements/edit/ShiftProductsMeasurementsEdit.vue';

const defaultFilterValues = {
  code: '',
  shiftManager: '',
  usageTypes: [],
  name: [],
  line_id: [],
  qualityNotDefined: true,
  flow_id: [],
  stage_id: [],
  created: null,
  shiftQualityClosed: false
};

export default {
  name: 'ShiftProductsMeasurements',
  components: {
    ShiftProductsMeasurementsEdit
  },
  props: {
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      shiftManagers: [],
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { item: 'created_at', direction: 'desc' },
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false,
      baseConfigIdsWithTechGroupingType: [],
      baseConfigsProductTypeIds: []
    };
  },
  computed: {
    flowOptions () {
      return this.$store.getters.getConfigOptions(
        'line_flows',
        this.$i18n.locale,
        'line_id',
        this.filterValues.line_id,
        false,
        false,
        true,
        ['flowcode']
      );
    },
    lineOptions () {
      return this.$store.getters.getConfigOptions('lines', this.$i18n.locale).filter(el => this.availableTesterCategory != undefined ? this.availableTesterCategory.lineCodes.includes(el.code) : true);
    },
    typeOptions () {
      return this.PetHelper.getProductTypes().filter(el => this.baseConfigsProductTypeIds.includes(el.value));
    },
    productTypeOptions () {
      return this.$store.getters.getConfigOptions('product_types', this.$i18n.locale);
    },
    editPermComp () {
      return 'edit-shift-products-quality-measurements';
    },
    basePathComp () {
      return 'quality/shift-products-quality-measurements'
    },
    compactLocales () {
      return false;
    },
    tableHeaders () {
      const headers = [
        { key: 'shift.shortname', label: this.$t('shift.shortName'), sortable: false, classTd: 'w-10' },
        { key: 'flow_id', label: this.$t('produced.flow_id'), format: 'formatFromConfig', configKey: 'line_flows', configParam: 'flowcode' },
        { key: 'product_type', label: this.$t('produced.type'), sortable: false, type: 'slot', slotcode: 'product_type', filterKey: 'product_type' },
        { key: 'product_id', label: this.$t('product.name'), sortable: false, format: 'formatFromConfig', configKey: 'products', configParam: 'name', classTd: 'w-10', filterKey: (this.productOptions.length > 5 ? 'nameText' : '') },
        { key: 'shift.manager.fullname', label: this.$t('brigade.manager') },
        { key: 'technological_stage.name.' + this.$i18n.locale, label: this.$t('menu.quality.qualityElement.technologicalStage'), sortable: false },
        { key: 'registeredFactoryProductsCount', label: this.$t('menu.quality.registeredFactoryProductsCount'), sortable: false, classTd: 'text-center' },
        { key: 'registeredFactoryProductsQualityCount', label: this.$t('menu.quality.registeredFactoryProductsQualityCount'), sortable: false, classTd: 'text-center' },
        { key: 'measurementDataSetExists', label: this.$t('menu.quality.shiftQualityMeasurementsEntered'), format: 'formatYesNo', classTd: 'text-center w-5' },
        { key: 'skipMeasurement', label: this.$t('menu.quality.skipMeasurement'), format: 'formatYesNo', reverseMode: true, classTd: 'text-center' },
        { key: 'shiftProductMeasurementClosed', label: this.$t('menu.quality.shiftProductMeasurementClosed'), type: 'slot', slotcode: 'shiftProductMeasurementClosed', classTd: 'text-center' }
      ]
      const actions = [];
      if (this.canEdit) {
        actions.push({ label: this.$t('menu.quality.shift-product-measurements.closeShiftProductMeasurements'), emit: 'closeShiftProductMeasurements', bicon: 'file-earmark-lock2-fill', class: 'btn-sm btn-info', ifconfig: { func: this.allowCloseShiftProductMeasurements, useFunc: true } });
        actions.push({ label: this.$t('general.actions.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-sm btn-primary', ifconfig: { func: this.allowEditClosedShiftProductMeasurements, useFunc: true } });
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    tableExpandConfig () {
      return null;
    },
    tableFilterConfig () {
      const filters = { };
      let lineCol = 'col-4'
      if (this.stageOptions.length < 2 && this.flowOptions.length < 2) {
        lineCol = 'col-12'
      }
      filters.line_id = { type: 'slot', label: this.$t('shift.line_id'), slotcode: 'linefilter', class: lineCol };
      let flowCol = 'col-8'
      if (this.stageOptions.length > 1) {
        flowCol = 'col-4'
      }
      if (this.flowOptions.length > 1 && this.filterValues.line_id.length > 0) {
        filters.flow_id = { type: 'checkbox', label: this.$t('produced.flow_id'), options: this.flowOptions, valueField: 'value', class: flowCol };
      }
      let stageCol = 'col-8'
      if (this.flowOptions.length > 1) {
        stageCol = 'col-4'
      }
      if (this.stageOptions.length > 1) {
        filters.stage_id = { type: 'checkbox', label: this.$t('menu.quality.qualityElement.technologicalStage'), options: this.stageOptions, valueField: 'id', class: stageCol }
      }
      filters.code = { type: 'text', label: '', colOnlyFilter: true };
      filters.shiftShortname = { type: 'text', label: '', colOnlyFilter: true };
      if (this.productOptions.length > 5) {
        filters.nameText = { type: 'text', label: '', colOnlyFilter: true };
      } else {
        filters.name = { type: 'checkbox', label: this.$t('product.name'), options: this.productOptions, valueField: 'value' };
      }
      filters.shiftManager = { type: 'slot', label: this.$t('brigade.manager'), slotcode: 'shiftManagerFilter', class: 'col-3', classTd: 'col-3' };
      filters.shiftDateRange = { type: 'date-range', label: this.$t('menu.quality.shiftDateRange'), colOnlyFilter: false, manualFilter: true, class: 'col-2' }
      filters.created = { type: 'date-range', label: '', colOnlyFilter: true, manualFilter: true };
      filters.shiftQualityClosed = { type: 'checkbox-single', label: this.$t('menu.quality.shiftQualityClosed_at'), class: 'col-2 mt-3', inlineLabel: true };
      return filters;
    },
    testerProfileId () {
      return this.$route.fullPath.split('/')[(this.$route.fullPath.split('/')).length - 2]
    },
    qualityCodeOptions () {
      return this.$store.getters.getConfigOptions('product_qualities_code', this.$i18n.locale);
    },
    getShiftManagers () {
      const dataSet = []
      this.shiftManagers.forEach(manager => {
        dataSet.push({
          value: manager.id,
          text: manager.fullname
        })
      })
      return dataSet;
    },
    availableTesterBaseConfigs () {
      if (this.availableTesterCategory != undefined && this.availableTesterCategory != null) {
        return this.availableTesterCategory.baseConfigs
      }
      return []
    },
    availableTesterCategory () {
      const availableTesterCategory = JSON.parse(localStorage.getItem('availableTesterCategories'))
      return Array.isArray(availableTesterCategory) ? availableTesterCategory.find(el => el.id == this.testerProfileId) : undefined
    },
    availableTesterLineIds () {
      if (this.availableTesterCategory != undefined && this.availableTesterCategory != null) {
        return this.availableTesterCategory.lineIds
      }
      return []
    },
    productOptions () {
      let data = this.$store.getters.getConfigOptions('products', this.$i18n.locale, null, null, null, false, false, ['type_id'], true);
      if (this.filterValues.type != undefined && this.filterValues.type.length > 0) {
        const allowedTypeIds = this.$store.getters.getConfigOptions('product_types', this.$i18n.locale).filter(el => this.filterValues.type.includes(el.code)).map(el => el.value)
        data = data.filter(el => allowedTypeIds.includes(el.type_id))
      }
      const allowedProducts = []
      const vm = this
      data.forEach(product => {
        vm.availableTesterBaseConfigs.forEach(baseConfig => {
          if (product.type_id == baseConfig.product_type) {
            if (baseConfig.product_id != null) {
              if (baseConfig.product_id == product.value) {
                allowedProducts.push(product)
              }
            } else {
              allowedProducts.push(product)
            }
          }
        })
      })
      return allowedProducts;
    },
    matchingProductsWithStages () {
      return this.$store.getters.getMatchingProductsWithStages(null, { canProduce: true }, { isFinalProduct: true })
    },
    stageOptions () {
      const validFlows = this.filterValues.flow_id.map(Number)
      const foundStageIds = []
      let validStages = []
      let validStageIds = []
      const vm = this
      Object.keys(this.matchingProductsWithStages).map(Number).forEach(function callback (flowId) {
        if (validFlows.includes(flowId) || validFlows.length == 0) {
          vm.availableTesterBaseConfigs.forEach(baseConfig => {
            let validProducts = Object.values(vm.matchingProductsWithStages[flowId].products).filter(
              el => el.type_id == baseConfig.product_type && vm.typeOptions.map(typeOption => typeOption.value).includes(el.type_id))
            if (validProducts.length > 0) {
              validProducts.forEach(validProduct => {
                validStageIds = validStageIds.concat(Object.keys(validProduct.stages).map(el => parseInt(el)))
              })
              if (baseConfig.product_id != null) {
                validProducts = validProducts.filter(el => el.id == baseConfig.product_id)
                if (validProducts.length > 0 && vm.availableTesterLineIds.map(el => parseInt(el)).includes(vm.matchingProductsWithStages[flowId].line_id)) {
                  vm.matchingProductsWithStages[flowId].stages.forEach(stage => {
                    if (!foundStageIds.includes(stage.id) && validStageIds.includes(stage.id)) {
                      validStages.push(stage)
                      foundStageIds.push(stage.id)
                    }
                  })
                }
              } else {
                if (vm.availableTesterLineIds.map(el => parseInt(el)).includes(vm.matchingProductsWithStages[flowId].line_id)) {
                  vm.matchingProductsWithStages[flowId].stages.forEach(stage => {
                    if (!foundStageIds.includes(stage.id) && validStageIds.includes(stage.id)) {
                      validStages.push(stage)
                      foundStageIds.push(stage.id)
                    }
                  })
                }
              }
            }
          })
        }
      });
      if (this.filterValues.line_id.length > 0 && validStages != undefined && validStages != null) {
        validStages = validStages.filter(el => this.filterValues.line_id.map(Number).includes(el.lineId))
      }
      if (this.filterValues.flow_id.length > 0 && validStages != undefined && validStages != null) {
        validStages = validStages.filter(el => this.filterValues.flow_id.map(Number).includes(el.flowId))
      }
      return validStages
    },
    canEdit () {
      return this.ApiRequest.userHasPermission(this.editPermComp) || (this.availableTesterCategory != null && this.availableTesterCategory != undefined);
    },
    tableActions () {
      const actions = { download: false };
      return actions;
    }
  },
  methods: {
    getTableData () {
      if (this.basePathComp) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        this.loading = true;
        let path = 'quality/tester-shift-products-quality-measurements/list?perPage=' + this.paging.limit + '&page=' + this.paging.page + '&testerProfileId=' + this.testerProfileId;
        const usedFilters = this.BasicHelper.cloneObject(this.filterValues);
        if (!usedFilters.line_id.length) {
          usedFilters.line_id = this.lineOptions.map(el => el.value);
        }
        path = this.ApiRequest.addGetParamsToPath(path, usedFilters, true);
        if (this.tableSorting.item) {
          path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
        }
        this.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.shiftManagers = response.data.shiftManagers
            this.baseConfigsProductTypeIds = response.data.baseConfigsProductTypeIds
            this.tableItems = response.data.list
            this.baseConfigIdsWithTechGroupingType = response.data.baseConfigIdsWithTechGroupingType
            this.paging = response.paging;
            this.loading = false;
          }
        });
      }
    },
    lineFilterChanged () {
      this.filterValues.flow_id = this.filterValues.flow_id.filter(flowId => (this.flowOptions.findIndex(el => el.value == flowId) >= 0));
      this.getTableData();
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    editItem (el) {
      this.$refs.editElFrm.show(el);
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    },
    allowCloseShiftProductMeasurements (el) {
      return el.id != null && el.measurementDataSet != null && el.closed_at == null;
    },
    allowEditClosedShiftProductMeasurements (el) {
      return el.closed_at == null;
    },
    closeShiftProductMeasurements (el, approved) {
      if (approved) {
        this.loading = true;
        this.ApiRequest.request(this.basePathComp + '/closeShiftProductMeasurements', this.ApiRequest.REQUEST_POST, { id: el.id }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
            this.loading = false;
          } else {
            this.getTableData();
          }
        })
      } else {
        const productType = this.productTypeOptions.find(element => element.value == el.product_type)
        const product = el.product_id != null ? this.$store.getters.getConfigOptions('products', this.$i18n.locale).find(element => element.value == el.product_id) : undefined
        let stageText = ''
        if (el.quality_measurement_base_config != null && this.baseConfigIdsWithTechGroupingType.includes(el.quality_measurement_base_config.id)) {
          stageText = this.$t('menu.quality.shiftQualityMeasurementsCloseMessageApproveMessage');
        }
        this.$refs.itemCloseMsgBox.show(el, this.$t('menu.quality.actionCloseShiftQuality'),
          this.$t('menu.quality.shiftQualityMeasurementsCloseMessage', {
            shiftName: el.shift.shortname,
            productTypeName: productType.text,
            productName: product != undefined ? ', ' + product.text : '',
            stageName: stageText !== '' ? ', ' + stageText : ''
          }))
      }
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.getTableData();
    this.isMounted = true;
  },
  watch: {
    basePathComp: {
      immediate: true,
      handler (val) {
        if (val) {
          this.isMounted = false;
          this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
          this.getTableData();
          this.isMounted = true;
        }
      }
    },
    testerProfileId: {
      immediate: true,
      handler (val) {
        if (val && !isNaN(Number(val))) {
          this.isMounted = false;
          this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
          this.getTableData();
          this.isMounted = true;
        }
      }
    }
  }
}
