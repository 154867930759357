import TesterQualityTestsList
  from '@/components/pet/quality/testerProfile/testerQualityTestList/TesterQualityTestsList.vue';
import TesterShiftProductsMeasurements
  from '@/components/pet/quality/testerProfile/testerShiftProductMeasurements/TesterShiftProductsMeasurements.vue';
import { mapState } from 'vuex';
import CargoScreeningList
  from '@/components/pet/quality/testerProfile/testerCargoScreeningList/TesterCargoScreeningList.vue';
export default {
  name: 'TesterSubmenu',
  props: {
  },
  data () {
    return {
      selectedSubmenu: 'productQualityTestList'
    }
  },
  components: {
    CargoScreeningList,
    TesterQualityTestsList,
    TesterShiftProductsMeasurements
  },
  computed: {
    ...mapState({
      testerSelectedSubmenu: state => state.pet.testerSelectedSubmenu
    })
  },
  methods: {
  },
  mounted () {
    if (['shiftProductMeasurements', 'productQualityTestList', 'cargoScreening'].includes(this.testerSelectedSubmenu)) {
      this.selectedSubmenu = this.testerSelectedSubmenu
    }
  },
  watch: {
    testerSelectedSubmenu: {
      immediate: true,
      handler (val) {
        this.selectedSubmenu = val
      }
    }
  }
}
