const defaultFilterValues = {
  includeEmpty: false,
  type_ids: [],
  type: [],
  usageTypes: ['isBought'],
  local_warehouse_ids: [],
  productName: '',
  productax2009: ''
};

export default {
  name: 'WarehouseSummary',
  components: {
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      isMounted: false,
      summaryRow: null,
      splitBy: 1000,
      precision: 3
    };
  },
  computed: {
    tableHeaders () {
      const headers = [
        { key: 'productName', label: this.$t('product.name'), sortable: true, filterKey: 'productName' },
        { key: 'productax2009', label: this.$t('product.ax2009code'), sortable: true, filterKey: 'ax2009code' }
      ];
      this.warehouseOptions.forEach(warehouse => {
        if (this.filterValues.local_warehouse_ids.length === 0 || this.filterValues.local_warehouse_ids.find(warehouseId => warehouseId === warehouse.value)) {
          headers.push(
            {
              key: 'warehouses.' + warehouse.value,
              label: warehouse.text,
              type: 'slot',
              sortable: true,
              slotcode: 'quantity'
            }
          );
        }
      });
      headers.push({
        key: 'total',
        label: this.$t('warehouse.summary.total'),
        classTd: 'fw-bold border-start border-dark',
        type: 'slot',
        sortable: true,
        slotcode: 'quantity'
      });
      return headers;
    },
    tableFilterConfig () {
      const filters = { };
      filters.productName = { type: 'text', label: '', colOnlyFilter: true };
      filters.ax2009code = { type: 'text', label: '', colOnlyFilter: true };
      filters.usageTypes = { type: 'checkbox', label: this.$t('product.usageTypes'), options: this.usageTypeOptions, colOnlyFilter: false, class: 'col-3' };
      filters.local_warehouse_ids = { type: 'checkbox', label: this.$t('warehouse.name'), options: this.warehouseOptions, colOnlyFilter: false, class: 'col-8', valueField: 'value' };
      filters.includeEmpty = { type: 'checkbox-single', label: this.$t('warehouse.summary.filters.includeEmpty'), colOnlyFilter: false, class: 'col-1 my-auto pt-3', inlineLabel: true };
      filters.type = { type: 'checkbox', label: this.$t('product.type_id'), options: this.typeOptions, colOnlyFilter: false, class: 'col-12' };
      return filters;
    },
    usageTypeOptions () {
      return this.PetHelper.getProductUsageTypeOptions();
    },
    warehouseOptions () {
      return this.$store.getters.getAvailableWarehouseOptions(this.$i18n.locale, 'isAssembly', ['0', '1']);
    },
    requestPath () {
      return 'warehouses/summary';
    },
    typeOptions () {
      const productTypeIds = [];
      const checkValue = this.filterValues.usageTypes;
      checkValue.push('type_id');
      const products = this.$store.getters.getConfigOptions('products', this.$i18n.locale, null, null, false, false, false, checkValue);
      checkValue.pop();
      products.forEach(
        el => {
          this.filterValues.usageTypes.forEach(subEl => {
            if (el[subEl] === true && !productTypeIds.includes(el.type_id)) {
              productTypeIds.push(el.type_id);
            }
          })
        }
      );
      return this.PetHelper.getProductTypes().filter(el => (this.filterValues.usageTypes.length ? el.inAxapta && productTypeIds.includes(el.value) : el.inAxapta));
    }
  },
  methods: {
    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.loading = true;
      this.cacheFilterValues();
      this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, this.filterValues, (response) => {
        if (response.data) {
          const tableItems = [];
          response.data.forEach(el => {
            if (el.product_id) {
              const productEl = this.$store.getters.getDataFromConfigWithFilter('products', 'id', el.product_id);
              if (productEl) {
                el.productName = this.BasicHelper.getTranslation(productEl.name);
                el.productax2009 = productEl.ax2009code;
                el.productd365code = this.BasicHelper.getConfigValue('d365_products', 'id', productEl.d365_product_id, 'code');
              }
              tableItems.push(el);
            } else {
              this.summaryRow = this.BasicHelper.cloneObject(el);
            }
          })
          this.tableItems = tableItems;
          this.loading = false;
        }
      });
    },
    cacheFilterValues () {
      const keysToKeep = ['type', 'includeEmpty', 'local_warehouse_ids', 'usageTypes'];
      const filteredObject = Object.fromEntries(
        Object.entries(this.filterValues)
          .filter(([key, _]) => {
            return keysToKeep.includes(key)
          })
      );
      this.BasicHelper.updateFilterValues(this.$route.name, filteredObject);
    },
    download (html, exportCols) {
      this.BasicHelper.htmlToPdfFile(
        html,
        'PET_warehouse_' + (new Date()).toISOString() + '.pdf',
        'landscape'
      );
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.getTableData();
  },
  watch: {
    basePathComp: {
      immediate: true,
      handler (val) {
        if (val) {
          this.isMounted = false;
          this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
          this.getTableData();
          this.isMounted = true;
        }
      }
    }
  }
}
