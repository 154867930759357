<template>
  <div id="app" :class="appClassName">
    <transition name='fade' mode='out-in'>
      <layout-default>
        <div v-if="maintenanceOn" class="page-content">
          <h2>{{ $t('general.maintenance.title')}}</h2>
          <div>{{ $t('general.maintenance.message')}}</div>
        </div>
        <div v-else-if="backendVersionMismatch" class="page-content">
          <h2>{{ $t('general.version-mismatch.title')}}</h2>
          <div>{{ $t('general.version-mismatch.message')}}</div>
        </div>
        <div v-else>
          <div v-if="isConfigPage" class="page-content">
            <h5 class="text-danger fs-2">
              <TranslationDomElements :value="$t('menu.config.warning')" />
            </h5>
          </div>
          <router-view v-if="configLoaded" />
          <span v-else class="spinner-border spinner-border-sm" />
        </div>
      </layout-default>
    </transition>
    <MessageBox ref="modalAlert" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import LayoutDefault from '@/components/pet/_layouts/default'

export default {
  components: {
    LayoutDefault
  },
  computed: {
    ...mapState(['configLoaded', 'maintenanceOn', 'backendVersionMismatch']),
    isConfigPage () {
      if (this.$route.meta.isConfigPage) {
        return true;
      }
      return false;
    },
    appClassName () {
      let val = this.$store.getters.getDataFromConfigWithFilter('environment', null, null);
      if (val) {
        const parts = val.split('-');
        val = parts[parts.length - 1];
      }
      if (val && val != 'production') {
        if (val == 'preprod') {
          val = 'test';
        }
        if (val != 'test') {
          val = 'dev';
        }
        return `server-${val}`;
      } else if (!val && process.env) {
        return 'server-' + process.env.VUE_APP_ENV_NAME;
      }
      return 'server-' + val;
    }
  },
  mounted () {
    window.setInterval(() => {
      this.$store.dispatch('reloadConfig');
    }, 60000);

    window.setInterval(() => {
      if (this.$session) {
        this.$store.dispatch('checkSession');
      }
    }, 30000);

    window.setInterval(() => {
      this.$store.dispatch('clearOldToasts');
    }, 1000);

    window.setInterval(() => {
      if (this.$session.get('accessToken') && !this.maintenanceOn) {
        this.$store.dispatch('reloadShiftData');
      }
    }, 8000);
  },
  created () {
    this.ApiRequest.setPublicParams(this.$session, this.$router, this.$store, this);
    this.BasicHelper.setParams(this);
    this.PetHelper.setParams(this);
    this.$store.dispatch('loadConfig');
    this.$store.dispatch('loadTranslations');
    if (this.$session) {
      this.$store.dispatch('checkSession');
      const localeCode = this.$session.get('locale');
      if (localeCode) {
        this.$root.$i18n.locale = localeCode;
      }
    }
  }
}
</script>
