import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

const defaultEl = {
  id: null,
  name: {},
  order: 0,
  code: '',
  unit_weight: null
};

export default {
  name: 'D365ProductEdit',
  data () {
    return {
      editEl: defaultEl,
      loading: false,
      baseMeasureType: 'kg'
    }
  },
  validations: {
    editEl: {
      name: { required },
      code: { required },
      order: { }
    }
  },
  props: {
    basePath: {
      type: String,
      default: ''
    },
    isModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getTitle () {
      return this.$t('general.actions.edit');
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission('edit-product-constants');
    },
    canEditFull () {
      return this.canEdit && !this.$store.getters.isFeatureEnabled('d365_api', 'products.sync');
    },
    productMeasureType () {
      return this.BasicHelper.getConfigValue('measure_types', 'id', this.editEl.measure_type_id, 'name', true);
    },
    baseMeasureTypeId () {
      return this.BasicHelper.getConfigValue('measure_types', 'code', this.baseMeasureType, 'id', false);
    }
  },
  methods: {
    show (elData) {
      if (elData) {
        this.editEl = this.BasicHelper.cloneObject(elData);
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
      }
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        this.ApiRequest.request(this.basePath + '/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$store.dispatch('reloadConfig');
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
