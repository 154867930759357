export default {
  name: 'ContainerPicker',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    cargo: {
      type: Object,
      default: null
    },
    orderId: {
      type: Number,
      default: null
    },
    originContainerNumber: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    dbparam: {
      type: String,
      default: ''
    },
    orderContainerUsage: {
      type: Array,
      default: () => []
    },
    orderContainers: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading: false,
      container: this.value,
      loadedContainerOptions: [],
      loadedContainerUsage: [],
      originalContainerNumber: this.originContainerNumber
    };
  },
  computed: {
    containerOptions () {
      const containerOptions = [];
      if (this.orderContainers.length === 0) {
        this.loadedContainerOptions.forEach(el => containerOptions.push(el));
      } else {
        this.orderContainers.forEach(el => containerOptions.push({ value: el + containerOptions.length, text: el }));
      }
      let hasOriginalOption = false;
      containerOptions.map(el => {
        if (this.originalContainerNumber && (el.text === this.originalContainerNumber)) {
          hasOriginalOption = true;
          el.value = 'ORIGINAL';
        }
        return el;
      });
      if (this.originalContainerNumber && !hasOriginalOption) {
        containerOptions.push({ value: 'ORIGINAL', text: this.originalContainerNumber, ordermissing: true });
      }
      containerOptions.map(el => {
        if ((el.text !== this.originalContainerNumber) && (el.text !== this.cargo.container_number) && this.isContainerUsed(el)) {
          el.disabled = true;
        }
        return el;
      });
      return containerOptions;
    }
  },
  methods: {
    isContainerUsed (item) {
      let isUsed = false;
      if (this.orderContainerUsage && this.orderContainerUsage.length > 0) {
        if (this.orderContainerUsage.includes(item.text)) {
          isUsed = true;
        }
      } else if (this.loadedContainerUsage.length > 0) {
        if (this.loadedContainerUsage.includes(item.text)) {
          isUsed = true;
        }
      }
      return isUsed;
    },
    selectContainer (el) {
      if (el) {
        if (this.cargo.container_number === el.text) {
          this.cargo.container_number = null;
        } else {
          this.cargo.container_number = el.text;
        }
        if (this.orderContainerUsage && this.orderContainerUsage.length > 0) {
          this.$emit('updateContainerUsage', this.cargo.container_number);
        }
      }
    },
    deselectContainer (el) {
      if (el) {
        this.cargo.container_number = null;
        if (this.orderContainerUsage && this.orderContainerUsage.length > 0) {
          this.$emit('updateContainerUsage', this.cargo.container_number);
        }
      }
    },
    getContainerClass (item) {
      const itemValue = (item ? item.value : null);
      const match = this.containerOptions.find(el => el.value === itemValue);
      return (match && match.ordermissing) ? 'text-danger' : '';
    },
    getContainerBgClass (item) {
      let itemValue = null;
      if (item && item[0]) {
        itemValue = item[0];
      }
      const match = this.containerOptions.find(el => el.value === itemValue);
      return (match && !match.ordermissing) ? 'bg-secondary' : 'bg-danger';
    },
    loadContainerOptionsAndUsage () {
      if (this.orderId) {
        this.loading = true;
        this.currentReq = this.ApiRequest.request('orders/containers/options-and-usage?orderId=' + this.orderId, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.loadedContainerOptions = response.data.containerOptions;
            this.loadedContainerUsage = response.data.containerUsage;
          }
          this.loading = false;
        });

        if (this.cargo.container_number) {
          this.container = ['ORIGINAL'];
          this.originalContainerNumber = this.cargo.container_number;
        } else {
          this.container = null;
        }
      }
    }
  },
  mounted () {
    this.loadContainerOptionsAndUsage();
  }
}
