import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

export default {
  name: 'ManagersBulkAdd',
  data () {
    return {
      loading: false,
      initProductDataSet: {},
      measureType: ''
    }
  },
  validations: {
    initProductDataSet: {
      reserveQuantity: { required }
    }
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getTitle () {
      return this.$t('cargo.factoryproduct.reserveProduct');
    },
    getProductOptions () {
      return this.$store.getters.getConfigOptions('products', this.$i18n.locale, null, null, false, false, false, ['default_measure_type_id']);
    }
  },
  methods: {
    show (productDataSet = null) {
      this.initProductDataSet = productDataSet
      this.reserveQuantity = null
      if (productDataSet) {
        const product = this.getProductOptions.find(el => el.value == productDataSet.product_id)
        if (product != undefined) {
          const measureType = this.BasicHelper.getConfigValue('measure_types', 'id', product.default_measure_type_id, 'name');
          if (measureType) {
            this.measureType = measureType
          }
        }
      }
      this.$refs['modal-window'].show();
    },
    save () {
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.close();
        this.$emit('reserveProduct', this.initProductDataSet);
      }
    },
    close () {
      this.$refs['modal-window'].hide();
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
