export default {
  name: 'AssemblyMovementInfo',
  components: {
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      movement: null,
      isMounted: false
    };
  },
  props: {
    movementId: {
      default: null,
      type: Number
    }
  },
  computed: {
    tableHeaders () {
      const headers = [
        { key: 'quantity', label: this.$t('assembly.bale.quantity'), format: 'formatDecimal', precision: 0 },
        { key: 'assemblyproduct.factoryproduct.code', label: this.$t('factoryproduct.code') }
      ];
      if (this.movement && !this.movement.isReturnMovement) {
        headers.push({ key: 'feeding.fed_at', label: this.$t('assembly.bale.fed_at'), type: 'datetime' });
        headers.push({ key: 'returnedAt', label: this.$t('assembly.bale.returnedAt'), type: 'datetime' });
      }
      return headers;
    },
    tableExpandConfig () {
      return null;
    },
    tableFilterConfig () {
      const filters = { };
      return filters;
    },
    requestPath () {
      return 'assembly/movements/view/' + this.movementId;
    },
    tableActions () {
      const actions = { download: false };
      return actions;
    }
  },
  methods: {
    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.loading = true;
      this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.data) {
          this.movement = response.data;
          this.loading = false;
        }
      });
    }
  },
  mounted () {
    this.getTableData();
  }
}
