import useVuelidate from '@vuelidate/core'
import { helpers, requiredIf } from '@vuelidate/validators'

const defaultEl = {
  measured_at: null,
  measurements: {}
};

export default {
  name: 'ShiftDeviceMeasureMultiEdit',
  data () {
    return {
      editEl: null,
      loading: false,
      shiftId: null,
      stages: null,
      devices: null,
      shiftTypeId: null
    }
  },
  validations: {
    editEl: {
      measurements: {
        $each: helpers.forEach({
          requiredMeasurement: {},
          measurevalue: {
            required: requiredIf((value, el) => {
              return el.requiredMeasurement;
            })
          }
        })
      }
    }
  },
  components: {
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    baseTransParam () {
      return 'device.measurement';
    },
    getTitle () {
      if (this.editEl && this.editEl.measured_at) {
        return this.$t(this.baseTransParam + '.actions.edit');
      }
      return this.$t(this.baseTransParam + '.actions.new');
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission('edit-shift-device-measurements');
    },
    yesNoOptions () {
      return this.BasicHelper.yesNoOptions();
    },
    boolMeasureTypeId () {
      return this.$store.getters.getParamFromConfigByFilter('measure_types', 'code', 'BOOL', 'id');
    },
    shiftType () {
      return this.$store.getters.getDataFromConfigById('shift_types', this.shiftTypeId);
    },
    validTimeHours () {
      if (this.shiftType) {
        if (this.shiftType.startHour > this.shiftType.endHour) {
          const res = [];
          let i = this.shiftType.startHour;
          while (i !== this.shiftType.endHour) {
            res.push(i);
            i += 1;
            if (i === 24) {
              i = 0;
            }
          }
          return res;
        }
        return { min: this.shiftType.startHour, max: (this.shiftType.endHour - 1) };
      }
      return { min: 0, max: 0 };
    },
    stageDevices () {
      const res = [];
      if (this.stages && this.devices) {
        Object.values(this.stages).forEach(stage => {
          const items = this.devices.filter(el => el.stage_id == stage.id);
          if (items.length > 0) {
            res[stage.id] = items;
          }
        });
      }
      return res;
    },
    measureStageDevices () {
      const res = [];
      if (this.stages && this.devices) {
        const baseElDev = {
          measure_type_id: null,
          devices: [],
          stageTypes: []
        };
        let prevEl = this.BasicHelper.cloneObject(baseElDev);
        Object.values(this.devices).forEach(el => {
          if (el.measure_type_id != prevEl.measure_type_id) {
            if (prevEl.measure_type_id) {
              res.push(prevEl);
              prevEl = this.BasicHelper.cloneObject(baseElDev);
            }
            prevEl.measure_type_id = el.measure_type_id;
            prevEl.devices = [];
          }
          const stageMatch = this.stages.find(stage => stage.id == el.stage_id);
          if (stageMatch && !prevEl.stageTypes.includes(stageMatch.type_id)) {
            prevEl.stageTypes.push(stageMatch.type_id);
          }
          prevEl.devices.push(el);
        });
        if (prevEl.measure_type_id) {
          res.push(prevEl);
        }
      }
      return res;
    }
  },
  methods: {
    show (elData, stages, devices, shiftId, shiftTypeId) {
      this.editEl = null;
      let defaultData = this.BasicHelper.cloneObject(defaultEl);
      if (elData) {
        defaultData = this.BasicHelper.cloneObject(elData);
        defaultData.measured_at = new Date(elData.measured_at);
      } else {
        defaultData.measured_at = new Date();
      }

      devices.forEach(dev => {
        if (!defaultData.measurements['dev_' + dev.id]) {
          defaultData.measurements['dev_' + dev.id] = {
            id: null,
            measurevalue: ((dev.measure_type_id == this.boolMeasureTypeId) ? 0 : null),
            device_id: dev.id,
            measure_type_id: dev.measure_type_id,
            requiredMeasurement: dev.requiredMeasurement,
            comment: null
          }
        } else {
          defaultData.measurements['dev_' + dev.id].requiredMeasurement = dev.requiredMeasurement;
          if (dev.measure_type_id == this.boolMeasureTypeId) {
            defaultData.measurements['dev_' + dev.id].measurevalue = parseInt(defaultData.measurements['dev_' + dev.id].measurevalue)
          } else if (defaultData.measurements['dev_' + dev.id].measurevalue != null) {
            defaultData.measurements['dev_' + dev.id].measurevalue = parseFloat(defaultData.measurements['dev_' + dev.id].measurevalue);
          }
        }
      });

      defaultData.measurements = Object.values(defaultData.measurements);
      devices.forEach(dev => {
        dev.measurementIdx = defaultData.measurements.findIndex(el => el.device_id == dev.id);
      });

      this.shiftId = shiftId;
      this.shiftTypeId = shiftTypeId;
      this.devices = devices;
      this.stages = this.BasicHelper.sortArrayByKeyValue(Object.values(stages), 'order');
      this.editEl = defaultData;
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        const reqData = this.BasicHelper.cloneObject(this.editEl);
        this.loading = true;
        this.ApiRequest.request('shift/view/' + this.shiftId + '/measurements/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
        this.editEl = null;
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
