import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

const product = {
  productQuantity: null
};
export default {
  name: 'ManagersBulkAdd',
  data () {
    return {
      productData: product,
      loading: false,
      initProductDataSet: {},
      salesProductMaxLimit: null
    }
  },
  validations: {
    productData: {
      productQuantity: { required }
    }
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getTitle () {
      return this.$t('order.productQuantityTitle');
    },
    getProductTypesOptions () {
      return this.$store.getters.getConfigOptions('product_types', this.$i18n.locale);
    }
  },
  methods: {
    show (productDataSet = null) {
      this.initProductDataSet = productDataSet
      if (this.initProductDataSet) {
        this.salesProductMaxLimit = this.initProductDataSet.remainingBales
      }
      this.$refs['modal-window'].show();
    },
    save () {
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.close();
        this.$emit('updateSpecificQuantity', this.productData.productQuantity);
      }
    },
    close () {
      this.$refs['modal-window'].hide();
    }
  },
  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'SpecifyQuantity' }) }
  }
}
