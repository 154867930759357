import useVuelidate from '@vuelidate/core';
import ShiftProductMeasurementElement
  from '@/components/pet/quality/shiftProductsMeasurements/edit/ShiftProductMeasurementElement/ShiftProductMeasurementElement.vue';
import ShiftProductMeasurementGroup
  from '@/components/pet/quality/shiftProductsMeasurements/edit/ShiftProductMeasurementGroup/ShiftProductMeasurementGroup.vue';
import { mapState } from 'vuex';

const defaultEl = {
  id: null,
  base_config_id: null,
  factory_product_id: null,
  measurementDataSet: [],
  skipMeasurement: false
};

const defaultQualityMeasurement = {
  quality_id: null,
  value: null,
  category_id: null
}
export default {
  name: 'ShiftProductsMeasurementsEdit',
  data () {
    return {
      editEl: defaultEl,
      factoryProduct: {},
      qualityBaseConfig: {},
      loading: false,
      defaultQualityMeasurement: this.BasicHelper.cloneObject(defaultQualityMeasurement)
    }
  },
  validations: {
  },
  components: {
    ShiftProductMeasurementElement,
    ShiftProductMeasurementGroup
  },
  props: {
    editPerm: {
      type: String,
      default: 'edit-shift-products-quality-measurements'
    },
    basePath: {
      type: String,
      default: ''
    },
    isModal: {
      type: Boolean,
      default: true
    },
    compactLocales: {
      type: Boolean,
      default: false
    },
    baseConfigIdsWithTechGroupingType: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapState({
      qualityMeasurementCategoriesNewColumn: state => state.config.quality_measurement_categories,
      currentUser: state => state.user
    }),
    qualityCategoryOptions () {
      return this.$store.getters.getConfigOptions('quality_measurement_categories', this.$i18n.locale);
    },
    measureTypeOptions () {
      return this.$store.getters.getConfigOptions('measure_types', this.$i18n.locale);
    },
    getTitle () {
      if (this.editEl.id) {
        return this.$t('general.actions.edit');
      }
      return this.$t('general.actions.new');
    },
    testerProfileId () {
      const parts = this.$route.fullPath.split('/');
      let id = '';
      for (let i = parts.length - 1; i >= 0; i--) {
        if (parts[i]) {
          id = parts[i];
          return id;
        }
      }
      return id;
    },
    availableTesterCategory () {
      if (this.currentUser && this.currentUser.availableTesterCategories && Array.isArray(this.currentUser.availableTesterCategories)) {
        return this.currentUser.availableTesterCategories.find(el => el.id == this.testerProfileId)
      }
      return null;
    },
    canEdit () {
      return !this.loading && (this.ApiRequest.userHasPermission(this.editPerm) || (this.availableTesterCategory != null && this.availableTesterCategory != undefined));
    }
  },
  methods: {
    isNewColumn (id) {
      const category = this.qualityMeasurementCategoriesNewColumn[id];
      if (category != undefined && category.newColumnOrder == true) {
        return true;
      }
      return false;
    },
    qualityCategoryText (index) {
      const text = this.qualityCategoryOptions.find(el => el.value == index)
      return text == undefined ? '' : text.text
    },
    isOptionChanged () {
      if (this.editEl.isOption) this.editEl.canUseAverageData = false;
    },
    show (elData) {
      this.editEl = elData;
      if (elData.measurementDataSet == null) elData.measurementDataSet = []
      this.qualityBaseConfig = elData.quality_measurement_base_config
      if (this.qualityBaseConfig.quality_measurements != null) {
        const vm = this;
        this.qualityBaseConfig.quality_measurements.forEach(function callback (value, index) {
          if (value.isOption == 0) {
            const defaultQualityMeasurement = vm.BasicHelper.cloneObject(vm.defaultQualityMeasurement);
            defaultQualityMeasurement.category_id = value.category_id
            defaultQualityMeasurement.quality_id = value.id
            vm.editEl.measurementDataSet.push(defaultQualityMeasurement);
          }
        })
      }
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        this.ApiRequest.request(this.basePath + '/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$store.dispatch('reloadConfig');
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
