export default {
  name: 'D365List',
  components: {
  },
  props: {
    baseTransParam: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: true
    },
    canDeleteLines: {
      type: Boolean,
      default: true
    },
    tableHeaders: {
      type: Array,
      default: () => []
    },
    basePath: {
      type: String,
      default: ''
    },
    listParam: {
      type: String,
      default: 'lines'
    }
  },
  data () {
    return {
      items: [],
      currentReq: null,
      loading: false
    };
  },
  computed: {
  },
  methods: {
    getTableData () {
      if (this.basePath) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.loading = true;
        this.currentReq = this.ApiRequest.request(this.basePath + '/view', this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.items = response.data[this.listParam];
            if (response.data.costsModified) {
              this.$emit('reloadTransportCosts');
            }
            this.$emit('loaded', response.data);
            this.loading = false;
          } else {
            this.loading = false;
          }
        });
      }
    },
    deleteLine (el) {
      this.loading = true;
      this.currentReq = this.ApiRequest.request(this.basePath + '/delete', this.ApiRequest.REQUEST_POST, el, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        } else {
          this.items = response.data;
          this.loading = false;
        }
      });
    }
  },
  mounted () {
    this.getTableData();
  },
  watch: {
    basePath: {
      immediate: true,
      handler (val) {
        this.getTableData();
      }
    }
  }
}
