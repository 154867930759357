import ProduceusesEdit from '@/components/pet/produceuses/edit/ProducesesEdit.vue'

const defaultUsed = {
  id: null,
  type_id: null,
  produses: []
};

export default {
  name: 'DowntimeEditactive',
  props: {
    value: {
      type: Object | Array,
      default () {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    typeCodes: {
      default: null,
      type: Array
    },
    siteId: {
      type: Number,
      default: null
    },
    withusage: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ProduceusesEdit
  },
  data () {
    return {
      content: this.value
    }
  },
  computed: {
    downtimesTypesOptions () {
      return this.$store.getters.getConfigOptions('downtime_types', this.$i18n.locale)
    },
    tableHeaders () {
      const headers = [
        { key: 'type_id', label: this.$t('downtimeactive.type_id'), type: 'slot', slotcode: 'downtimetype' }
      ];
      const actions = [];
      if (!this.disabled) {
        if (this.withusage) {
          actions.push({ label: this.$t('general.actions.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-sm btn-warning' });
        }
        actions.push({ label: this.$t('general.actions.delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-sm btn-danger', ifconfig: { param: 'deleted_at', value: null } });
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    tableExpandConfig () {
      if (this.withusage) {
        return { component: 'slot' };
      }
      return null;
    }
  },
  methods: {
    updateValue () {
      this.$emit('input', this.content);
    },
    deleteItem (el) {
      el.deleted = true;
      el._showDetails = false;
      this.updateValue();
      this.$refs.tableview.$forceUpdate();
    },
    addItem () {
      this.content.push(this.BasicHelper.cloneObject(defaultUsed));
      this.updateValue();
    },
    editItem (el) {
      el._showDetails = !el._showDetails;
      this.$refs.tableview.$forceUpdate();
    },
    rowClassText (el) {
      if (el.deleted) {
        return 'd-none';
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        if (val != this.content) {
          this.content = (val || []);
        }
      }
    }
  }
}
