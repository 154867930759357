const defaultEl = {
  id: null,
  config_id: null,
  product_id: null,
  quantity: null,
  measure_type_id: null,
  canWriteOffAsUsed: false,
  canEditQuantity: false,
  from_warehouse_id: null,
  product_group_id: null,
  quantityRatio: null,
  quantityInKg: null
};

export default {
  name: 'AdditionalUsedProducts',
  components: {

  },
  data () {
    return {
      loading: false,
      isMounted: false
    };
  },
  props: {
    products: {
      type: Array,
      default: () => []
    },
    canEdit: {
      type: Boolean,
      default: true
    },
    produsesConfiguration: {
      type: Object,
      default: null
    }
  },
  computed: {
    tableHeaders () {
      const headers = [
        { key: 'product_group_id', label: this.$t('technologicalStage.produceuses.product_group_id'), format: 'formatFromConfig', configKey: 'product_groups', configParam: 'name' },
        { key: 'product_id', label: this.$t('product.name'), type: 'slot', slotcode: 'product' },
        { key: 'quantity', label: this.$t('technologicalStage.produceuses.quantity'), classTd: 'w-20', type: 'slot', slotcode: 'quantity' },
        { key: 'quantityInKg', label: this.$t('technologicalStage.produceuses.quantityInKg'), classTd: 'w-20', type: 'slot', slotcode: 'quantityInKg' }
      ];
      const actions = [];
      if (this.canDelete) {
        actions.push({ label: this.$t('general.actions.delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-sm btn-danger', ifconfig: { param: 'deleted_at', value: null } });
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    canDelete () {
      return this.canEdit;
    },
    tableActions () {
      const actions = { download: false };
      return actions;
    },
    usageOptions () {
      const options = [];
      if (this.produsesConfiguration) {
        if (this.produsesConfiguration.always) {
          this.produsesConfiguration.always.forEach(el => {
            options.push(el);
          });
        }
        if (this.produsesConfiguration.groups) {
          Object.values(this.produsesConfiguration.groups).forEach(groupModes => {
            Object.values(groupModes).forEach(groups => {
              Object.values(groups).forEach(el => {
                if (el.product_id) {
                  options.push(el);
                }
              });
            });
          });
        }
      }
      return options;
    }
  },
  methods: {
    deleteItem (el, approved) {
      this.setDeleted(el, 1);
    },
    setDeleted (el, isDeleted) {
      el.deleted = 1;
      const idx = this.products.findIndex(el => el.deleted == 1);
      this.products.splice(idx, 1);
    },
    addRow () {
      this.products.push(this.BasicHelper.cloneObject(defaultEl));
    },
    usageOptionChanged (val, idx) {
      let option = this.usageOptions.find(el => el.id == val);
      if (!option) {
        option = defaultEl;
      }
      option = this.BasicHelper.cloneObject(option);
      option.quantityInKg = option.quantity * option.quantityRatio;
      option.id = null;
      Object.keys(option).forEach(param => {
        this.products[idx][param] = option[param];
      });
    },
    quantityChanged (val, idx) {
      const el = this.products[idx];
      if (el && el.quantityRatio) {
        el.quantityInKg = val * el.quantityRatio;
      }
    }
  },
  mounted () {
    this.isMounted = true;
  },
  watch: {
  }
}
