import CargoRfidList from '@/components/pet/rfid/cargolist/CargoRfidList.vue'
import PetHelper from '@/helpers/PetHelper';
import DevicePrinterCallprint from '@/components/pet/device/printer/callprint/DevicePrinterCallprint.vue';

const defaultLine = {
  id: null,
  product_type_id: null,
  product_id: null,
  quality_id: null,
  quantity: '',
  pieces: '',
  measure_type_id: null,
  price: null,
  availableApusWasteClasses: [],
  autoAdded: false,
  rfids: []
}

export default {
  name: 'CargoD365LineList',
  components: {
    DevicePrinterCallprint,
    CargoRfidList
  },
  props: {
    lines: {
      type: Array,
      default: () => null
    },
    readonly: {
      type: Boolean,
      default: true
    },
    isExportOrTransferOrder: {
      type: Boolean,
      default: false
    },
    isIncoming: {
      type: Boolean,
      default: true
    },
    canEditLines: {
      type: Boolean,
      default: true
    },
    canEditPrices: {
      type: Boolean,
      default: false
    },
    withType: {
      type: Boolean,
      default: true
    },
    partner: {
      type: Object,
      default: null
    },
    orderType: {
      type: String,
      default: 'EXP'
    },
    currencyCode: {
      type: String,
      default: null
    },
    validator: {
      default: () => null
    },
    size: {
      type: String,
      default: ''
    },
    cargoStatus: {
      type: Number,
      default: null
    },
    cargo: {
      type: Object,
      default: null
    },
    parentView: {
      type: String,
      default: null
    },
    canAttachRfids: {
      type: Boolean,
      default: false
    },
    orderdata: {
      type: Object,
      default: () => {}
    },
    logistics: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      d365lines: [],
      quantityPrecision: 3,
      customQty: null
    };
  },
  computed: {
    baseTransParam () {
      return 'cargo.d365lines';
    },
    allowedTemplates () {
      return null;
    },
    tableExpandConfig () {
      const matchingLine = this.d365lines.find(d365line => this.isForPurchaseInBags(d365line));
      return matchingLine ? { component: 'slot' } : null;
    },
    disabledEdit365Products () {
      const status = this.$store.getters.getDataFromConfigById('cargo_statuses', this.cargo?.status_id)
      if (status && status.code == 'FINISHED') {
        return true
      }
      return false
    },
    printDeviceIds () {
      const ids = [];
      if (this.cargo && this.cargo.order && this.cargo.order.warehouse_id) {
        const workstationIds = this.$store.getters.getParamFromConfig(
          'warehouses',
          this.cargo.order.warehouse_id,
          'workstation_ids',
          this.$i18n.locale
        );

        if (workstationIds) {
          const deviceIds = this.$store.getters.getConfigOptions(
            'workstations',
            this.$i18n.locale,
            'id',
            workstationIds,
            false,
            false,
            true,
            ['device_ids']
          );
          deviceIds.forEach(el => {
            el.device_ids.forEach(deviceId => {
              ids.push(deviceId);
            });
          });
        }
      }
      return [...new Set(ids)];
    },
    tableHeaders () {
      const actions = [];
      let headers = [];
      if (this.logistics) {
        headers = [
          { key: '', label: '', type: 'slot', slotcode: 'exclamation' },
          { key: 'product_type_id', label: this.$t(this.baseTransParam + '.product_type_id'), type: 'slot', slotcode: 'product_type' },
          { key: 'product_id', label: this.$t(this.baseTransParam + '.product_id'), type: 'slot', slotcode: 'product' },
          { key: 'quality_id', label: this.$t(this.baseTransParam + '.quality_id'), type: 'slot', slotcode: 'quality' }
        ];
      } else {
        headers = [
          { key: '', label: '', type: 'slot', slotcode: 'exclamation' },
          { key: 'product_id', label: this.$t(this.baseTransParam + '.product_id'), type: 'slot', slotcode: 'product' }
        ];
        if (this.isTransferOrder || !this.isIncoming) {
          headers.push({ key: 'batch.code', label: this.$t(this.baseTransParam + '.batch') });
        } else {
          headers.push({ key: 'quality_id', label: this.$t(this.baseTransParam + '.quality_id'), type: 'slot', slotcode: 'quality' });
        }
        if (this.isIncoming) {
          if (this.hasProductsWithUnitCount) {
            headers.push({ key: 'pieces', label: this.$t(this.baseTransParam + '.pieces'), type: 'slot', slotcode: 'pieces' });
          }
        } else {
          headers.push({ key: 'pieces', label: this.$t(this.baseTransParam + '.pieces'), type: 'slot', slotcode: 'pieces' });
        }
        headers.push({ key: 'quantity', label: this.$t(this.baseTransParam + '.quantity'), type: 'slot', slotcode: 'quantity' });
        headers.push({ key: 'measure_type_id', label: this.$t(this.baseTransParam + '.measure_type_id'), format: 'formatFromConfig', configKey: 'measure_types', configParam: 'name' });
        if (this.withType) {
          headers.unshift({ key: 'product_type_id', label: this.$t(this.baseTransParam + '.product_type_id'), type: 'slot', slotcode: 'product_type' });
          if (!this.isTransferOrder) {
            headers.push({ key: 'activeRate', label: this.$t(this.baseTransParam + '.activeRate', { currency: PetHelper.getCurrencyNameFromCode(this.currencyCode) }) });
          }
          headers.push({ key: 'apus_waste_class_id', label: this.$t(this.baseTransParam + '.apus_waste_class'), type: 'slot', slotcode: 'apus_waste_class' });
          if (!this.isTransferOrder) {
            headers.push({ key: 'price', label: this.$t(this.baseTransParam + '.price', { currency: PetHelper.getCurrencyNameFromCode(this.currencyCode) }), type: 'slot', slotcode: 'price' });
          }
        }
        if (this.isIncoming) {
          headers.push({ key: 'vendorcode', label: this.$t(this.baseTransParam + '.vendorcode'), type: 'slot', slotcode: 'vendorcode' });
        }
        if (this.isIncoming && this.hasProductsWithUnitCount) {
          headers.push({ key: 'rfids_count', label: this.$t(this.baseTransParam + '.rfids_count'), type: 'slot', slotcode: 'rfids' });
        }
        if (this.canAttachRfids) {
          actions.push({ label: this.$t(this.baseTransParam + '.actions.add-rfids'), emit: 'addRfids', bicon: 'upc-scan', class: 'btn-sm btn-info', ifconfig: { useFunc: true, func: this.canAttachRfid } });
        }
      }
      if (this.canEditLines) {
        actions.push({ label: this.$t('general.actions.delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-sm btn-danger', ifconfig: { useFunc: true, func: this.canDeleteLine } });
      }
      if (this.allowPrint) {
        actions.push({ label: this.$t('cargo.actions.print'), emit: 'print', bicon: 'printer-fill', class: 'btn-sm btn-info' });
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    allowPrint () {
      if (this.isIncoming && !this.readonly && this.cargo != null &&
          !['TEMPLATE', 'PLANNED', 'ASSEMBLY', 'ASSEMBLED'].includes(this.BasicHelper.getConfigValue('cargo_statuses',
            'id', this.cargo.status_id, 'code'))) {
        return true
      }
      return false;
    },
    isTransferOrder () {
      return this.orderType == 'TNF';
    },
    checkPartnerProducts () {
      return this.orderType != 'IMP-R'
    },
    canEditPurchaseApusWasteId () {
      return !this.readonly && this.ApiRequest.userHasPermission('edit-order-purchase-line-apus-waste-id');
    },
    productTypeOptions () {
      if (this.orderType == 'IMP') {
        return this.PetHelper.getProductTypes().filter(el => (el.inAxapta && (el.isBought || el.isChemical)));
      }
      return this.PetHelper.getProductTypes().filter(el => (el.inAxapta));
    },
    productOptions () {
      if (this.orderType == 'IMP') {
        return this.PetHelper.getD365ProductOptions().filter(el => (el.isBought || el.isChemical));
      }
      return this.PetHelper.getD365ProductOptions();
    },
    qualityOptions () {
      return this.$store.getters.getConfigOptions('product_qualities', this.$i18n.locale);
    },
    apusWasteOptions () {
      const apusWasteOptions = this.$store.getters.getConfigOptions('apus_waste_classes', this.$i18n.locale);
      return apusWasteOptions.map((apusWasteOption) => {
        apusWasteOption.text = apusWasteOption.code + ' (' + apusWasteOption.text + ')';
        return apusWasteOption;
      })
    },
    partnerProducts () {
      if (this.partner) {
        return this.partner.products;
      }
      return null;
    },
    checkStatusIds () {
      return this.$store.getters.getConfigOptions('cargo_statuses', this.$i18n.locale).filter(el => ['FINISHED'].includes(el.code)).map(el => el.value);
    },
    hasProductsWithUnitCount () {
      const productsWithUnitCount = this.d365lines.filter(line => {
        return this.productOptions.find((option) => option.value === line.product_id)?.isUnitCount;
      })

      return Boolean(productsWithUnitCount.length);
    }
  },
  methods: {
    getQualityBadgeColors (qualityId) {
      return this.$store.getters.getQualityBadgeColors(qualityId);
    },
    resolveSalesOrderTemplateQrUrl (el) {
      const routeData = this.$router.resolve({ name: 'menu.warehouse.cargos.view-simple', params: { cargocode: el.localcode } });
      return routeData.href;
    },
    price (data) {
      let price = null;
      if (data.item.quantity) {
        price = data.item.quantity * (data.item.activeRate ?? 1);
      }
      return price;
    },
    apusWasteClass (id) {
      let apusWasteClass = '-';
      const item = this.$store.getters.getDataFromConfigById('apus_waste_classes', id);
      if (item) {
        const code = item.code;
        const name = this.BasicHelper.getTranslation(item.name);
        if (code && name) {
          apusWasteClass = code + ' (' + name + ')';
        }
      }
      return apusWasteClass;
    },
    getOrderProductAndQualityLineMap (productLine) {
      const orderProductAndQualityLineMap = {};
      if (this.orderdata.products != undefined) {
        this.orderdata.products.forEach(orderProductLine => {
          if (orderProductLine.product_id) {
            if (!orderProductAndQualityLineMap[orderProductLine.product_id]) {
              orderProductAndQualityLineMap[orderProductLine.product_id] = {};
            }
            if (orderProductLine.quality_id) {
              orderProductAndQualityLineMap[orderProductLine.product_id][orderProductLine.quality_id] = 1;
            } else {
              this.qualityOptions.forEach(qualityOption => {
                orderProductAndQualityLineMap[orderProductLine.product_id][qualityOption.value] = 1;
              })
            }
          }
        })
      }
      if (!this.isIncoming) {
        // IMP & IMP-R: remove unique check, because chemicals might arrtive two lines, same material, same quality, but different vendorcode
        this.d365lines.forEach(d365line => {
          if (d365line.product_id) {
            if (d365line.quality_id) {
              if (productLine.quality_id !== d365line.quality_id) {
                delete orderProductAndQualityLineMap[d365line.product_id][d365line.quality_id];
              }
            } else if (d365line.product_id !== productLine.product_id) {
              delete orderProductAndQualityLineMap[d365line.product_id];
            }
          }
        })
      }
      return orderProductAndQualityLineMap;
    },
    getFilteredProductOptions (productLine) {
      const orderProductAndQualityLineMap = this.getOrderProductAndQualityLineMap(productLine);
      return this.productOptions.filter(productOption => {
        if (orderProductAndQualityLineMap[productOption.value]) {
          return Object.values(orderProductAndQualityLineMap[productOption.value]).length > 0;
        }
        return false;
      })
    },
    getFilteredQualityOptions (productLine) {
      const orderProductAndQualityLineMap = this.getOrderProductAndQualityLineMap(productLine);
      return this.qualityOptions.filter(qualityOption => {
        return orderProductAndQualityLineMap?.[productLine.product_id]?.[qualityOption.value];
      })
    },
    addLine () {
      const el = this.BasicHelper.cloneObject(defaultLine);
      el.quality_id = this.qualityOptions[0].value;
      el.productOptions = this.getFilteredProductOptions(el);
      this.d365lines.push(el);
      this.$refs.tableview.$forceUpdate();
    },
    deleteLine (el) {
      el.deleted = 1;
      const idx = this.d365lines.findIndex(el => el.deleted == 1);
      this.d365lines.splice(idx, 1);
      this.$refs.tableview.$forceUpdate();
    },
    productTypeChanged (typeId, index) {
      this.productChanged(null, index);
    },
    productChanged (item, index) {
      if (!item || item.value !== this.d365lines[index].product_id) {
        if (item) {
          this.d365lines[index].measure_type_id = item.default_measure_type_id;
          this.d365lines[index].apus_waste_class_id = item.apus_waste_class_id;
          this.d365lines[index].availableApusWasteClasses = item.availableApusWasteClasses;
          this.d365lines[index].product_type_id = item.type_id;
          if (this.isIncoming && !item.isUnitCount) {
            this.d365lines[index].pieces = 1;
          }
        } else {
          this.d365lines[index].measure_type_id = null;
          this.d365lines[index].product_id = null;
        }
        if (item && this.orderdata !== undefined && this.orderdata.products != null && this.orderdata.products.length > 0) {
          const findProduct = Object.values(this.orderdata.products).find(el => el.product_id === item.value)
          if (findProduct !== null && findProduct !== undefined && findProduct.apus_waste_class_id !== null && this.d365lines[index].id == null) {
            this.d365lines[index].apus_waste_class_id = findProduct.apus_waste_class_id
          }
        }
        if (this.partnerProducts !== undefined && this.partnerProducts != null && this.partnerProducts.length > 0) {
          const checkProductId = (item != null ? item.value : this.d365lines[index].product_id);
          const qualityFromProduct = this.partnerProducts.find(el => el.product_id === checkProductId)
          if (qualityFromProduct !== undefined) {
            let isProductQualityAlreadyUsed = false;
            this.d365lines.forEach(cargoLine => {
              if (cargoLine !== this.d365lines[index] && cargoLine.product_id === item.value && cargoLine.quality_id === qualityFromProduct.quality_id) {
                isProductQualityAlreadyUsed = true;
              }
            });
            // Auto-fill quality
            if (!isProductQualityAlreadyUsed) {
              const orderProductAndQualityLineMap = this.getOrderProductAndQualityLineMap(this.d365lines[index]);
              if (Object.keys(orderProductAndQualityLineMap[checkProductId]).includes(qualityFromProduct.quality_id.toString())) {
                this.d365lines[index].quality_id = qualityFromProduct.quality_id;
              } else {
                this.d365lines[index].quality_id = Object.keys(orderProductAndQualityLineMap[checkProductId]).shift();
              }
            }
          }
        }
      }
    },
    canDeleteLine (el) {
      return ((el.rfids_count == 0 && ['IMP'].includes(this.orderType) && !this.checkStatusIds.includes(this.cargoStatus)) || el.id == null);
    },
    canEditQuantity (el) {
      if (this.isIncoming && el.product_id) {
        return this.productOptions.find((option) => option.value === el.product_id).isUnitCount;
      }
      return true;
    },
    canAttachRfid (el) {
      if (this.isIncoming && this.isForPurchaseInBags(el)) {
        return false
      }
      return (['IMP'].includes(this.orderType) && el.id && this.productOptions.find((option) => option.value === el.product_id).isUnitCount);
    },
    getPriceInfo (el) {
      if (el.price !== null && el.activePrice !== null && el.activePrice != el.price) {
        return this.$t(this.baseTransParam + '.priceDelta',
          {
            activePrice: this.BasicHelper.formatDecimal(el.activePrice, 2, this.$i18n.locale),
            deltaPrice: this.BasicHelper.formatDecimal(el.price - el.activePrice, 2, this.$i18n.locale)
          }
        );
      }
      return '';
    },
    hasUnPlannedProduct (data) {
      let exclamation = false
      if (this.cargo != null && this.isIncoming && this.cargo.order != null && this.cargo.order.products != undefined) {
        if (this.cargo.order.products == null || this.cargo.order.products.length == 0) {
          exclamation = true
        } else {
          const orderProductIds = this.cargo.order.products.map(el => el.product_id)
          const findUnplannedProduct = this.cargo.d365lines.find(el => !orderProductIds.includes(data.product_id))
          if (findUnplannedProduct != null && findUnplannedProduct != undefined) {
            exclamation = true
          }
        }
      }
      return exclamation
    },
    hasQualityMismatch (data) {
      if (!data.id) {
        return false;
      }
      const d365line = this.cargo.d365lines.find(line => line.id === data.id)
      if (!this.isIncoming || !this.cargo.cargoQualityTestEnabled) {
        return false;
      }
      if (!d365line.quality_id || !d365line?.incomingfactoryproduct?.quality_id) {
        return false;
      }
      return d365line.quality_id !== d365line?.incomingfactoryproduct?.quality_id;
    },
    setAvailableOptions (val) {
      val.forEach(el => {
        el.product_type_id = this.BasicHelper.getConfigValue('products', 'id', el.product_id, 'type_id');
        el.productOptions = this.getFilteredProductOptions(el);
        const item = this.productOptions.find(subEl => subEl.value == el.product_id);
        if (item) {
          el.availableApusWasteClasses = item.availableApusWasteClasses;
        } else {
          el.availableApusWasteClasses = [];
        }
      });
      this.d365lines = val;
    },
    addRfids (el) {
      this.$refs.cargoRfidList.show(el.cargo_id, el.id, el.pieces);
    },
    showRfidCount (el) {
      if (this.isIncoming && el.product_id) {
        return this.productOptions.find((option) => option.value === el.product_id).isUnitCount;
      }
      return true;
    },
    printLine (el, specificFactoryProductId = null, preselectedCustomQty = null) {
      const additionalData = [];
      if (el.pieces !== undefined) {
        this.customQty = el.pieces == null ? 0 : el.pieces
        if (this.isForPurchaseInBags(el)) {
          if (specificFactoryProductId == null || specificFactoryProductId == false) {
            additionalData.printAllLineProducts = true
          }
        }
      }
      if (preselectedCustomQty != null) {
        this.customQty = preselectedCustomQty
      }
      additionalData.id = el.id
      additionalData.specificFactoryProductId = specificFactoryProductId
      additionalData.product_id = el.product_id
      this.$refs.printForm.show(null, 'cargo-orders/print-label/' + this.cargo.id, this.printDeviceIds,
        this.resolveSalesOrderTemplateQrUrl(this.cargo), additionalData);
    },
    isForPurchaseInBags (line) {
      if (this.isIncoming && this.orderdata && this.orderdata.products != undefined) {
        const findProd = this.orderdata.products.find(el2 => el2.product_id == line.product_id && line.quality_id == el2.quality_id)
        if (findProd != null && findProd.purchased_in_bags) {
          return true
        }
      }
      return false
    },
    rfidsChanged (el) {
      const match = this.d365lines.find(subel => subel.id == el.lineId);
      if (match) {
        match.rfids_count = el.length;
      }
    }
  },
  mounted () {
  },
  watch: {
    lines: {
      immediate: true,
      handler (val) {
        if (val !== null) {
          this.setAvailableOptions(val);
        }
      }
    },
    partner: {
      immediate: true,
      handler (val) {
        this.partner = val;
        if (this.d365lines != null) {
          this.setAvailableOptions(this.d365lines);
        }
      }
    }
  }
}
