const defaultFilterValues = {
  status: []
};

export default {
  name: 'CargoScreeningList',
  components: {
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { },
      isMounted: false,
      needProductSelect: false
    };
  },
  computed: {
    basePathComp () {
      return 'quality/cargo-screenings'
    },
    basePerm () {
      return 'cargo-screenings';
    },
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-' + this.basePerm);
    },
    testerProfileId () {
      return this.$route.fullPath.split('/')[(this.$route.fullPath.split('/')).length - 2]
    },
    currentRouteName () {
      return this.$route.name;
    },
    baseTransParam () {
      return 'menu.quality.cargo-screening'
    },
    tableHeaders () {
      const headers = [
        { key: 'status_id', label: '', type: 'slot', slotcode: 'statuscolor', classTd: 'position-relative p-0 w-color' },
        { key: 'localcode', label: this.$t(this.baseTransParam + '.cargo.localcode'), type: 'slot', slotcode: 'localcode', filterKey: 'localcode', sortable: true },
        { key: 'deliveryDate', label: this.$t(this.baseTransParam + '.cargo.cargoDate'), type: 'date', sortable: true, filterKey: 'deliveryDate' },
        { key: 'vendor_name', label: this.$t(this.baseTransParam + '.cargo.vendor-name'), type: 'slot', slotcode: 'vendorName', filterKey: 'vendorName', sortable: true },
        { key: 'unscreened', label: this.$t(this.baseTransParam + '.cargo.unscreened'), sortable: true },
        { key: 'screened', label: this.$t(this.baseTransParam + '.cargo.screened'), sortable: true }
      ];
      const actions = [];
      actions.push({ label: this.$t(this.baseTransParam + '.tested'), emit: 'itemTested', bicon: 'check', class: 'btn-primary btn-sm', ifconfig: { func: this.displayTestedButton, useFunc: true } });
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    tableExpandConfig () {
      return null;
    },
    tableFilterConfig () {
      const filters = { };
      filters.status = {
        type: 'checkbox',
        label: this.$t('menu.quality.qualityElement.cargoStatus'),
        options: this.statusOptionsUsed,
        class: 'col-lg-6',
        textField: 'name',
        textTranslate: true
      }
      filters.localcode = { type: 'text', label: '', colOnlyFilter: true };
      filters.vendorName = { type: 'text', label: '', colOnlyFilter: true };
      filters.deliveryDate = { type: 'date-range', label: '', colOnlyFilter: true };
      return filters;
    },
    tableActions () {
      return { download: false };
    },
    statusOptionsUsed () {
      let options = Object.values(this.$store.getters.getItemsFromConfigWithFilter('cargo_statuses', null, null));
      options = this.BasicHelper.sortArrayByKeyValue(options, 'order');
      options = options.filter(el => !['TEMPLATE', 'PLANNED', 'ASSEMBLY', 'ASSEMBLED'].includes(el.code));
      options.sort((a, b) => a.order - b.order);
      options.forEach(el => {
        el.colorCheckbox = false;
        el.labelSpanClass = null;
      });
      return options;
    },
    laboratoryStatusId () {
      return this.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'code', 'LABORATORY', 'id');
    }
  },
  methods: {
    itemTested (item, approved) {
      if (approved) {
        this.ApiRequest.request('quality/cargo-screenings/change-status/' + item.id, this.ApiRequest.REQUEST_POST, { tostatus: 'LOADED', cargoScreeningChangeStatus: true }, (response) => {
          if (response.error) {
            this.$store.commit('toastAdd', {
              context: 'danger',
              message: this.ApiRequest.getApiResponseErrorMessage(response, {})
            })
          } else {
            this.$store.commit('toastAdd', {
              context: 'success',
              message: this.$t(this.baseTransParam + '.status-changed')
            })
          }
          this.getTableData();
        });
      } else {
        this.$refs.itemTestedMsgBox.show(item, this.$t(this.baseTransParam + '.tested'), this.$t(this.baseTransParam + '.really-tested'))
      }
    },
    displayTestedButton (item) {
      return (item.status_id === this.laboratoryStatusId) && this.canEdit;
    },
    getTableData () {
      if (this.basePathComp) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.loading = true;
        let path = 'quality/cargo-screenings/list?testerProfileId=' + this.testerProfileId;
        const usedFilters = this.BasicHelper.cloneObject(this.filterValues);
        path = this.ApiRequest.addGetParamsToPath(path, usedFilters, true);
        if (this.tableSorting.item) {
          path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
        }
        this.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.tableItems = response.data;
            this.loading = false;
          }
        });
      }
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    },
    getStatusColor (statusId) {
      const status = this.statusOptionsUsed.find(el => el.id === statusId);
      if (status) {
        return status.color;
      }
      return '#FFF';
    },
    getStatusName (statusId) {
      return this.BasicHelper.getConfigValue('cargo_statuses', 'id', statusId, 'name');
    }
  },
  mounted () {
    this.getTableData();
    this.isMounted = true;
  },
  watch: {
    basePathComp: {
      immediate: true,
      handler (val) {
        if (val) {
          this.isMounted = false;
          this.getTableData();
          this.isMounted = true;
        }
      }
    },
    testerProfileId: {
      immediate: true,
      handler (val) {
        if (val && !isNaN(Number(val))) {
          this.isMounted = false;
          this.getTableData();
          this.isMounted = true;
        }
      }
    }
  }
}
