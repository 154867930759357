import { helpers } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import QualityMatchSetupEdit from '@/components/pet/quality/qualityMatchSetup/edit/QualityMatchSetupEdit.vue';
import draggable from 'vuedraggable'

const defaultLine = {
  id: null,
  product_id: null,
  quality_id: null,
  editmode: true,
  name: '',
  category_id: null,
  additionalData: []
}
const mathSymbols = [
  '+',
  '-',
  '/',
  '*',
  '(',
  ')'

];
export default {
  name: 'QualityMatchSetupList',
  components: {
    QualityMatchSetupEdit,
    draggable
  },
  props: {
    orderId: {
      type: Number,
      default: null
    },
    lines: {
      type: Array,
      default: () => null
    },
    readonly: {
      type: Boolean,
      default: true
    },
    editMeasurement: {
      type: Boolean,
      default: true
    },
    qualityIds: {
      type: Array,
      default: []
    },
    qualityCodeIds: {
      type: Array,
      default: []
    }
  },
  data () {
    return {
      matchOption: mathSymbols.map((name, index) => {
        return { name, order: index + 1, fixed: false, id: index, type: 'matchOption' };
      }),
      loading: false,
      currentReq: null,
      solines: [],
      groupedMeasurements: {},
      formulaInput: [],
      editable: true,
      isDragging: true
    };
  },
  validations: {
    solines: {
      $each: helpers.forEach({
      })
    }
  },
  computed: {
    dragOptions () {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      };
    },
    basePathComp () {
      return 'config/mwc-custom/product-quality-match-setup/view/' + this.orderId
    },
    baseTransParam () {
      return 'menu.quality';
    },
    tableHeaders () {
      const headers = [
        { key: 'priority', label: this.$t(this.baseTransParam + '.quality-match-setup.priority'), sortable: false },
        { key: 'minValue', label: this.$t(this.baseTransParam + '.quality-match-setup.min-value'), sortable: false },
        { key: 'maxValue', label: this.$t(this.baseTransParam + '.quality-match-setup.max-value'), sortable: false }
      ];
      const actions = [];
      if (this.canEdit) {
        actions.push({ label: this.$t('general.actions.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-sm btn-warning' });
      }
      if (this.canDelete) {
        actions.push({ label: this.$t('general.actions.delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-sm btn-danger' });
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    qualityOptions () {
      return this.$store.getters.getConfigOptions('product_qualities', this.$i18n.locale);
    },
    qualityCategoryOptions () {
      return this.$store.getters.getConfigOptions('quality_measurement_categories', this.$i18n.locale);
    },
    tableExpandConfig () {
      return { component: 'slot' }
    },
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-product-quality-match-setup');
    },
    canDelete () {
      return this.ApiRequest.userHasPermission('delete-product-quality-match-setup');
    },
    tableActions () {
      const actions = { download: false };
      if (this.canEdit) actions.add = { title: this.$t('general.actions.add') };
      return actions;
    }
  },
  methods: {
    getTableData () {
      if (this.orderId) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.loading = true;
        this.currentReq = this.ApiRequest.request(this.basePathComp, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.solines = response.data.list;
            this.groupedMeasurements = response.data.groupedList;
            this.loading = false;
          }
        });
      }
    },
    addLine () {
      const el = this.BasicHelper.cloneObject(defaultLine);
      this.solines.push(el);
      this.$refs.tableview.$forceUpdate();
    },
    deleteItem (el, approved) {
      if (approved) {
        el.deleted = 1;
        const idx = this.solines.findIndex(el => el.deleted == 1);
        this.solines.splice(idx, 1);
        this.ApiRequest.request('config/mwc-custom/product-quality-match-setup/deleted', this.ApiRequest.REQUEST_GET, { id: el.id, deleted: true }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
            this.loading = false;
          } else {
            this.$refs.tableview.$forceUpdate();
          }
        });
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t('general.item.deleteTitle'), this.$t('general.item.deleteMessage', { name: this.$t('menu.quality.quality-match'), code: '' }))
      }
    },
    editItem (el) {
      this.$refs.editElFrm.show(el);
    },
    toggleEditMode (el) {
      if (el.editmode) {
        el.editmode = false;
      } else {
        el.editmode = true;
      }
      this.$refs.tableview.$forceUpdate();
    }
  },
  mounted () {
    if (this.poproducts) {
      this.products = this.poproducts;
    }
    if (this.lines == null) {
      this.getTableData();
    }
  },
  watch: {
    isDragging (newValue) {
      if (newValue) {
        this.delayedDragging = false;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    },
    lines: {
      immediate: true,
      handler (val) {
        if (val !== null) {
          this.solines = val;
        }
      }
    },
    orderId: {
      immediate: true,
      handler (val) {
        if (this.lines == null) {
          this.getTableData()
        }
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
