export default {
  name: 'DeviceChecklist',
  props: {
    value: {
      default: () => [],
      type: Array
    },
    disabled: {
      default: false,
      type: Boolean
    },
    typeCodes: {
      default: null,
      type: Array
    },
    siteId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      content: this.value,
      checked: this.getChecked()
    }
  },
  computed: {
    deviceOptions () {
      return this.$store.getters.getConfigOptions(
        'devices',
        this.$i18n.locale,
        'site_id',
        this.siteId,
        false,
        false,
        false,
        ['type_id']
      );
    },
    typeOptions () {
      return this.$store.getters.getConfigOptions('device_types', this.$i18n.locale).filter(el => {
        if (this.typeCodes !== null) {
          return this.typeCodes.find(subcode => subcode === el.code);
        }
        return true;
      });
    }
  },
  methods: {
    getChecked () {
      const checked = [];
      if (this.content) {
        this.deviceOptions.forEach(el => {
          checked[el.value] = !!(this.content.find(subVal => subVal == el.value));
        });
      }
      return checked;
    },
    updateValues () {
      const checkedVals = [];
      Object.keys(this.checked).forEach(id => {
        if (this.checked[id] == true) {
          checkedVals.push(id);
        }
      });
      this.content = checkedVals;
      this.$emit('input', this.content);
    }
  },
  watch: {
    value () {
      if (this.content !== this.value) {
        this.content = this.value;
        this.checked = this.getChecked();
      }
    }
  },
  mounted () {
    this.checked = this.getChecked();
  }
}
