import useVuelidate from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';
import FactoryProductPicker from '@/components/pet/factoryproduct/picker/FactoryProductPicker.vue'
import DeviceScaleInput from '@/components/pet/device/scaleinput/DeviceScaleInput.vue'
import FormCarousel from 'ebg-vue-common/src/components/common/FormCarousel/FormCarousel.vue'
import RfidScanner from '@/components/pet/device/rfidreader/scanner/RfidScanner.vue'

const defaultEl = {
  shift_id: null,
  line_id: null,
  flow_id: null,
  stage_id: null,
  product_id: null,
  quantity: null,
  comment: null,
  pieces: null,
  warehouse_id: null,
  factory_product: null,
  selectedWorkStationsId: null,
  rfid: null,
  rfidtext: '',
  upsertComment: false
};

const defaultProductFilters = {
  type_id: null,
  id: null,
  groupname: null
}

export default {
  name: 'ProductFeedingAdd',
  data () {
    return {
      editEl: defaultEl,
      loading: false,
      stage_id: null,
      feedingProducts: [],
      productFilters: this.BasicHelper.cloneObject(defaultProductFilters),
      factoryProduct: null,
      loadingProducts: false,
      currentReq: null,
      isChemical: false,
      shiftWarehouseId: null,
      viewMode: 0,
      warehouseProducts: [],
      warehouseProductIds: [],
      lockedProduct: false,
      invalidAssemblyProductIds: [],
      isLastStep: false,
      activeSlide: 1,
      notInWareHouse: false,
      goLastPage: false,
      skipClickOutSide: false
    }
  },
  validations: {
    editEl: {
      shift_id: { required },
      flow_id: { required },
      stage_id: { required },
      product_id: { required },
      warehouse_id: {
        required: requiredIf(function () {
          return !this.editEl.factory_product && this.selectedProduct.inAxapta
        })
      },
      comment: {
        required: requiredIf(function () {
          return this.selectedProduct && !this.selectedProduct.inAxapta
        })
      },
      pieces: {
        required: requiredIf(function () {
          return !this.editEl.factory_product && this.needBaleQuantity;
        })
      },
      quantity: {
        required: requiredIf(function () {
          return !this.editEl.factory_product && this.needQuantity;
        })
      },
      factory_product: {
        required: requiredIf(function () {
          return this.needFactoryProduct;
        })
      }
    }
  },
  components: {
    FactoryProductPicker,
    DeviceScaleInput,
    FormCarousel,
    RfidScanner
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    },
    isShiftRecipe: {
      type: Boolean,
      default: false
    },
    rfidDeviceId: {
      type: Number | String,
      default: null
    }
  },
  computed: {
    slideOne () {
      if (this.isChemical && !this.isShiftRecipe) {
        return 'slide-2';
      } else if (!this.isChemical && this.isShiftRecipe) {
        return 'slide-1'
      } else {
        return 'slide-3';
      }
    },
    slideTwo () {
      if (this.isChemical && !this.isShiftRecipe) {
        return 'slide-1';
      }
      return 'slide-2';
    },
    slideThree () {
      if (!this.isChemical && !this.isShiftRecipe) {
        return 'slide-1'
      }
      return 'slide-3';
    },
    baseTransParam () {
      return 'fed-products';
    },
    getTitle () {
      if (this.editEl.id) {
        return this.$t(this.baseTransParam + '.actions.edit');
      }
      return this.$t(this.baseTransParam + '.actions.new' + (this.isChemical ? 'Chemical' : ''));
    },
    factoryPickerFilters () {
      const filters = {
        product: '-1',
        status: 'WAREHOUSE',
        withRemainingQuantity: 1,
        removeEmpty: 1
      };
      if (this.activeSlide == 1) {
        filters.product = filters.product + ';' + this.productOptionsFull.map(el => el.code).join(';');
      } else if (this.selectedProduct) {
        filters.product = this.selectedProduct.code;
      }
      return filters;
    },
    mightHaveFactoryProduct () {
      return (this.usageTypeActiveOptions.filter(el => {
        return (el.code == 'isSeparated' || el.code == 'isFinalProduct' || el.code == 'isBought');
      }).length > 0);
    },
    needFactoryProduct () {
      if (this.selectedProduct && (this.selectedProduct.groupname == 'isFinalProduct' || this.selectedProduct.groupname == 'isSeparated')) {
        return true;
      }
      return false;
    },
    productTypeOptions () {
      if (this.productOptionsFull) {
        return this.$store.getters.getConfigOptions(
          'product_types',
          this.$i18n.locale,
          'id',
          Object.values(this.productOptionsFull).filter(el => el.groupname == this.productFilters.groupname).map(el => el.type_id),
          false,
          false,
          true
        );
      }
      return [];
    },
    productTypeOptionsText () {
      if (this.slideNames && this.slideNames[this.activeSlide] == 'Location') {
        return '-';
      }
      const productTypeOptions = this.productTypeOptions.find(o => o.value == this.productFilters.type_id);
      return productTypeOptions !== undefined ? productTypeOptions.text : '';
    },
    productOptionsText () {
      if (this.slideNames && this.slideNames[this.activeSlide] == 'Location') {
        return '-';
      }
      const productOptions = this.productOptions.find(o => o.id == this.editEl.product_id);
      return productOptions !== undefined ? productOptions.text : '';
    },
    stageOptions () {
      const feedingProducts = this.$store.getters.getMatchingProductsWithStages(
        this.editEl.line_id,
        { canFeed: true },
        { isChemical: this.isChemical },
        ((this.lockedProduct || this.editEl.factory_product) ? [this.editEl.product_id] : this.cachedProductIds)
      );
      if (feedingProducts && feedingProducts[this.editEl.flow_id]) {
        return feedingProducts[this.editEl.flow_id].stages;
      }
      return [];
    },
    workStationOptions () {
      if (this.stageOptions.length > 0) {
        const selectedStation = this.stageOptions.find(el => el.id == this.editEl.stage_id);
        if (selectedStation != undefined && selectedStation.workstation_ids.length > 0) {
          const availableWorkStations = this.$store.getters.getConfigOptions(
            'workstations',
            this.$i18n.locale,
            null,
            null,
            false,
            false,
            false,
            ['device_ids']
          );
          const options = availableWorkStations.filter(el => selectedStation.workstation_ids.includes(el.value))
          if (options != undefined && options != null) {
            options.forEach(function callback (value, index) {
              options[index].id = value.value
            })
            return options
          }
        }
      }
      return []
    },
    deviceIds () {
      if (this.feedingProducts && this.feedingProducts[this.editEl.flow_id]) {
        const stage = this.feedingProducts[this.editEl.flow_id].stages.find(el => el.id == this.editEl.stage_id);
        if (stage) {
          if (this.editEl.selectedWorkStationsId != null) {
            const workstationOptions = this.$store.getters.getConfigOptions(
              'workstations',
              this.$i18n.locale,
              'id',
              [this.editEl.selectedWorkStationsId],
              false,
              false,
              true,
              ['device_ids']
            );
            const ids = [];
            workstationOptions.forEach(el => {
              el.device_ids.forEach(devId => {
                if (ids.indexOf(devId) == -1) {
                  ids.push(devId);
                }
              })
            })
            return ids
          }
          return stage.device_ids;
        }
      }
      return [];
    },
    flowOptions () {
      return this.$store.getters.getConfigOptions('line_flows', this.$i18n.locale, 'line_id', this.editEl.line_id)
    },
    defaultMeasureTypeId () {
      return this.$store.getters.getParamFromConfig('products', this.editEl.product_id, 'default_measure_type_id');
    },
    cachedProductIds () {
      if (this.editEl.flow_id) {
        return Object.values(this.$store.getters.getParamFromConfigByFilter('line_flows', 'id', this.editEl?.flow_id, 'availableFeedProducts'));
      }
      return [];
    },
    productOptionFiltered () {
      let data = this.$store.getters.getConfigOptions('products', this.$i18n.locale,
        null,
        null,
        false,
        false,
        false,
        ['groupname']
      );
      if (this.isChemical) {
        data = data.filter(el => el.groupname == 'isChemical' && this.cachedProductIds.includes(el.value))
      } else {
        data = data.filter(el => el.groupname != 'isChemical' && this.cachedProductIds.includes(el.value))
      }
      return data
    },
    usageTypeActiveOptions () {
      const filteredProductsGroupNames = this.productOptionFiltered.map(el => el.groupname)

      return this.usageTypeOptions.filter(el => filteredProductsGroupNames.includes(el.code));
    },
    usageTypeActiveOptionsText () {
      const usageTypeActiveOptions = this.usageTypeActiveOptions.find(el => el.code == this.productFilters.groupname);
      return usageTypeActiveOptions !== undefined ? usageTypeActiveOptions.text : '';
    },
    productOptionsFull () {
      if (this.feedingProducts && this.feedingProducts[this.editEl.flow_id]) {
        return this.BasicHelper.sortArrayByKeyValue(Object.values(this.feedingProducts[this.editEl.flow_id].products).filter(el => {
          let res = true;
          if (this.productFilters.id && el.inAxapta) {
            res = res && (this.productFilters.id.includes(el.id));
          }
          return res;
        }));
      }
      return [];
    },
    productOptions () {
      if (this.feedingProducts && this.feedingProducts[this.editEl.flow_id]) {
        return this.productOptionsFull.filter(el => {
          let res = true;
          Object.keys(this.productFilters).forEach(key => {
            if (this.productFilters[key]) {
              if (!(key == 'id' && !el.inAxapta)) {
                if (Array.isArray(this.productFilters[key])) {
                  res = res && (this.productFilters[key].includes(el[key]));
                } else {
                  res = res && (this.productFilters[key] == el[key]);
                }
              }
            }
          });
          return res;
        });
      }
      return [];
    },
    warehouseOptions () {
      return this.BasicHelper.sortArrayByKeyValue(
        this.$store.getters.getConfigOptions('warehouses', this.$i18n.locale, 'isAssembly', [0, 1], false, false, true, ['isAssembly']),
        'isAssembly',
        1
      );
    },
    warehouseUsableOptions () {
      if (this.warehouseProducts) {
        const items = this.warehouseOptions.filter(el => {
          if (this.selectedProduct && this.selectedProduct.stages && this.selectedProduct.stages[this.editEl.stage_id] &&
            this.warehouseProducts && this.warehouseProducts[el.value] && this.warehouseProducts[el.value][this.selectedProduct.id]
          ) {
            return ((!this.selectedProduct.stages[this.editEl.stage_id].feedNotAssembly && el.value == this.shiftWarehouseId) ||
              (this.selectedProduct.stages[this.editEl.stage_id].feedNotAssembly && (!el.isAssembly || el.value == this.shiftWarehouseId))
            );
          }
          return false;
        });
        if (this.editEl && this.editEl.warehouse_id == null && items.length > 0) {
          this.editEl.warehouse_id = items[0].value;
        }
        return items;
      }
      return [];
    },
    baleQuantityOptions () {
      const options = [];
      let maxOptions = 0;
      if (this.editEl && this.editEl.rfid) {
        maxOptions = 1;
      } else if (this.editEl && this.editEl.factory_product) {
        maxOptions = this.editEl.factory_product.remainingQuantity;
      } else if (this.warehouseQuantitySelected && this.warehouseQuantitySelected.remainingBales) {
        maxOptions = this.warehouseQuantitySelected.remainingBales;
      }
      if (maxOptions > 0) {
        let i = 1;
        while (i <= maxOptions && i <= 6) {
          options.push({ value: i, text: i });
          i += 1;
        }
      }
      return options;
    },
    needQuantity () {
      const warehouse = this.warehouseOptions.find(el => el.value == this.editEl.warehouse_id);
      return this.isChemical || (!warehouse || !warehouse.isAssembly);
    },
    needBaleQuantity () {
      return !this.isChemical && (this.selectedProduct && this.selectedProduct.inAxapta);
    },
    selectedProduct () {
      return (this.productOptions ? this.productOptions.find(el => el.id == this.editEl.product_id) : null);
    },
    usageTypeOptions () {
      return this.PetHelper.getProductUsageTypeOptions(false);
    },
    viewMoveOptions () {
      return [
        { value: 0, text: this.$t('fed-products.viewmode.manual') },
        { value: 1, text: this.$t('fed-products.viewmode.picker') }
      ];
    },
    warehouseQuantitySelected () {
      if (this.warehouseProducts && this.warehouseProducts[this.editEl.warehouse_id] && this.selectedProduct && this.warehouseProducts[this.editEl.warehouse_id][this.selectedProduct.id]) {
        return this.warehouseProducts[this.editEl.warehouse_id][this.selectedProduct.id];
      }
      return null;
    },
    displayFlowInfo () {
      return (this.flowOptions && this.flowOptions.length > 1);
    },
    slideNames () {
      if (this.isShiftRecipe) {
        return { 1: 'Quantity' }
      }
      if (this.isChemical && (this.flowOptions.length == 1)) {
        return {
          1: 'Product',
          2: 'Quantity'
        }
      }
      return {
        1: 'Location',
        2: 'Product',
        3: 'Quantity'
      }
    },
    calculatedQuantity () {
      if (this.editEl && this.editEl.factory_product) {
        const pieces = (this.editEl.pieces ? this.editEl.pieces : 1)
        if (this.editEl.factory_product.remainingBales > 1) {
          return Math.round(pieces * this.editEl.factory_product.remainingQuantity / this.editEl.factory_product.remainingBales);
        } else {
          return this.editEl.factory_product.remainingQuantity;
        }
      }
      return null;
    },
    rfidFilters () {
      const filters = { inWarehouse: true };
      if (this.lockedProduct) {
        filters.product_id = this.editEl.product_id
        filters.warehouse_id = this.editEl.warehouse_id
      }
      return filters;
    }
  },
  methods: {
    canEditComment (productId) {
      return this.$store.getters.isGroupNameProduct(productId, 'withoutD365Product');
    },
    factoryProductPickerChanged () {
      this.notInWareHouse = false
      if (this.editEl.factory_product == null) return;
      const selectedProduct = this.productOptionsFull.find(el => el.id == this.editEl.factory_product.product_id);
      if (selectedProduct == undefined) {
        this.notInWareHouse = true
      } else {
        this.setProductDataByProductId(selectedProduct.id);
        if ([1, 2].includes(this.activeSlide) && Object.values(this.slideNames).length > 1) {
          this.goLastPage = true;
          setTimeout(() => {
            this.$refs.carouselview.goNextSlide();
          }, 500);
        }
      }
    },
    setProductDataByProductId (productId) {
      const selectedProduct = this.productOptionsFull.find(el => el.id == productId);
      if (selectedProduct) {
        const params = ['isFinalProduct', 'isSeparated', 'isChemical', 'isBought'];
        params.every(type => {
          if (selectedProduct[type]) {
            this.productFilters.groupname = type;
            return false;
          }
          return true;
        });
        this.productFilters.type_id = selectedProduct.type_id;
        this.editEl.product_id = selectedProduct.id;
        this.editEl.stage_id = (this.stageOptions.length > 0 ? this.stageOptions[0].id : null);
      }
    },
    show (shift, flowId, isChemical = false, productId = null, qrcode = null, rfidcode = null, onlyReopen = false) {
      if (onlyReopen && this.$refs['modal-window']) {
        if (this.$refs['modal-window'] && this.$refs['modal-window'].modal && this.$refs['modal-window'].modal._isShown) {
          console.log('feeding-already-open');
          return;
        }
      }
      this.goLastPage = false;
      this.productFilters = this.BasicHelper.cloneObject(defaultProductFilters);
      if (isChemical) {
        this.productFilters.groupname = 'isChemical';
      }
      this.editEl = this.BasicHelper.cloneObject(defaultEl);
      this.isChemical = isChemical;
      this.shiftWarehouseId = shift.warehouse_id;
      this.feedingProducts = [];
      this.invalidAssemblyProductIds = shift.warehouseInvalidProductIds;
      this.isLastStep = false;
      this.activeSlide = 1;
      if (productId) {
        this.lockedProduct = true;
        this.editEl.product_id = productId;
      } else {
        this.lockedProduct = false;
        this.editEl.product_id = null;
      }
      this.editEl.line_id = shift.line_id;
      this.editEl.shift_id = shift.id;
      this.editEl.flow_id = (flowId && this.flowOptions.find(el => el.value == flowId) ? flowId : this.flowOptions[0].value);
      this.editEl.stage_id = null;
      this.editEl.rfidtext = rfidcode;
      this.editEl.qrcode = qrcode;
      this.factoryProduct = null;
      this.warehouseProducts = [];
      this.flowChanged();
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    flowChanged () {
      this.getWarehouseProducts();
    },
    stageChanged () {
      this.groupnameChanged();
    },
    groupnameSelected () {
      this.groupnameChanged();
      this.$refs.carouselview.goNextSlide();
    },
    productSelected () {
      this.$refs.carouselview.goNextSlide();
    },
    groupnameChanged () {
      this.productFilters.type_id = (this.productTypeOptions.length > 0 ? this.productTypeOptions[0].value : null);
      this.productTypeChanged();
    },
    productTypeChanged () {
      if (!this.lockedProduct) {
        this.editEl.product_id = (this.productOptions && this.productOptions.length > 0 ? this.productOptions[0].id : null);
      }
    },
    getWarehouseProducts () {
      this.warehouseProducts = null;
      this.loadingProducts = true;
      this.ApiRequest.request('shift/view/' + this.editEl.shift_id + '/feeding/products/' + this.editEl.flow_id, this.ApiRequest.REQUEST_GET, { }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else {
          this.warehouseProducts = response.data.warehouses;
          this.feedingProducts = this.$store.getters.getMatchingProductsWithStages(
            this.editEl.line_id,
            { canFeed: true },
            { isChemical: this.isChemical },
            (this.lockedProduct ? [this.editEl.product_id] : response.data.productIds)
          );
          if (this.lockedProduct) {
            this.setProductDataByProductId(this.editEl.product_id);
          }
          this.editEl.stage_id = (this.stageOptions.length > 0 ? this.stageOptions[0].id : null);
          this.editEl.selectedWorkStationsId = (this.workStationOptions.length > 0 ? this.workStationOptions[0].id : null);
          this.groupnameChanged();
        }
        this.loadingProducts = false;
        this.setQrRfidData();
      });
    },
    save () {
      if (!this.isLastStep && !this.isShiftRecipe) {
        return;
      }
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        this.editEl.upsertComment = this.canEditComment(this.editEl.product_id);
        if (
          this.editEl.factory_product &&
          this.selectedProduct &&
          this.selectedProduct.inAxapta &&
          (this.selectedProduct.isBought || this.selectedProduct.isChemical) &&
          !this.editEl.quantity
        ) {
          this.editEl.quantity = this.calculatedQuantity;
        }
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        this.ApiRequest.request('fed-products/add', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    },
    slideChanged (res) {
      setTimeout(() => {
        if ((res.slide != undefined && !(Object.values(this.slideNames).length >= res.slide)) || (res.slide != undefined && (this.activeSlide + 1 != res.slide && this.activeSlide - 1 != res.slide))) {
          return
        }
        if (res.slide != undefined && res.slide == 1) {
          this.skipClickOutSide = true;
        }
        this.isLastStep = res.isLast;
        this.activeSlide = res.slide;
        if (!res.isLast && this.goLastPage) {
          this.$refs.carouselview.goNextSlide()
          this.skipClickOutSide = false;
        } else if (res.isLast) {
          this.goLastPage = false;
        }
      }, 1000);
    },
    focusScanners () {
      const basename = this.slideNames[this.activeSlide];
      if (this.$refs[`rfidpicker${basename}`]) {
        this.$refs[`rfidpicker${basename}`].focusInput();
      } else if (this.$refs[`factoryProductPicker${basename}`]) {
        this.$refs[`factoryProductPicker${basename}`].focusInput();
      }
    },
    rfidLoaded (rfid) {
      this.editEl.rfid = rfid;
      if (rfid && rfid.factoryproduct) {
        this.editEl.factory_product = rfid.factoryproduct;
        this.editEl.pieces = 1;
      } else {
        this.editEl.factory_product = null;
      }
      this.factoryProductPickerChanged();
    },
    setQrRfidData () {
      const basename = this.slideNames[this.activeSlide];
      if (this.$refs[`rfidpicker${basename}`] && this.editEl.rfidtext) {
        this.$refs[`rfidpicker${basename}`].scandone(this.editEl.rfidtext);
      } else if (this.$refs[`factoryProductPicker${basename}`] && this.editEl.qrcode) {
        this.$refs[`factoryProductPicker${basename}`].setSearchtext(this.editEl.qrcode)
      }
      this.focusScanners();
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
