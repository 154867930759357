import ProducedEdit from '@/components/pet/manufacture/produced/edit/ProducedEdit.vue'
import ProducedItemHistory from '@/components/pet/manufacture/produced/history/ProducedItemHistory.vue'
import { mapState } from 'vuex';
import DevicePrinterCallprint from '@/components/pet/device/printer/callprint/DevicePrinterCallprint.vue'
import MqttDevice from 'ebg-vue-common/src/components/common/mqttdevice.vue'

const defaultFilterValues = {
  created: null,
  code: '',
  name: '',
  deleted: ['0'],
  usageTypes: [],
  line_id: [],
  flow_id: [],
  type_name: ''
};

export default {
  name: 'ProducedHistory',
  components: {
    ProducedEdit,
    ProducedItemHistory,
    DevicePrinterCallprint,
    MqttDevice
  },
  data () {
    return {
      tableItems: [],
      isMounted: false,
      loading: true,
      currentReq: null,
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      editType: 'product'
    };
  },
  computed: {
    ...mapState({
      currentShift: state => state.pet.currentShift,
      currentFlowId: state => state.pet.currentFlowId
    }),
    baseTransParam () {
      return 'produced';
    },
    basePathComp () {
      return 'factory-products'
    },
    basePerm () {
      return 'shift-produced';
    },
    requestPath () {
      return this.ApiRequest.addGetParamsToPath(this.basePathComp + '/produced?order=created_at,desc&perPage=' + this.paging.limit + '&page=' + this.paging.page, this.filterValues, true);
    },
    tableHeaders () {
      const headers = [
        { key: 'warningIndicator', label: this.$t('produced.changedNumberWarning'), filterKey: 'warningIndicator', type: 'slot', slotcode: 'warningIndicator', headerClass: 'text-break w-5' },
        { key: 'created_at', label: this.$t('produced.created'), type: 'datetime', filterKey: 'created' },
        { key: 'product_type', label: this.$t('produced.type'), sortable: false, type: 'slot', slotcode: 'product_type', filterKey: 'type_name' },
        { key: 'product_id', label: this.$t('produced.name'), format: 'formatFromConfig', configKey: 'products', configParam: 'name', filterKey: 'name' },
        { key: 'product', label: this.$t('produced.application'), type: 'slot', slotcode: 'type', headerClass: 'text-nowrap' },
        { key: 'code', label: this.$t('produced.code'), filterKey: 'code' },
        { key: 'flow_id', label: this.$t(this.baseTransParam + '.flow_id'), format: 'formatFromConfig', configKey: 'line_flows', configParam: 'flowcode' },
        { key: 'hasWeight', label: this.$t('produced.hasWeight'), sortable: false, filterKey: 'hasWeight', format: 'formatYesNo', headerClass: 'text-nowrap' },
        { key: 'produced_journal_id', label: this.$t('produced.hasJournal'), sortable: false, filterKey: 'hasJournal', format: 'formatYesNo', headerClass: 'text-nowrap' },
        { key: 'wetQuantity', label: this.$t('produced.realWeight'), sortable: false },
        { key: 'startQuantity', label: this.$t('produced.correctedWeight'), sortable: false },
        { key: 'quantityWithRation', label: this.$t('produced.quantityWithRation'), sortable: false },
        { key: 'lastupdateduser.fullname', label: this.$t('produced.lastEditUser'), sortable: false }
      ];
      const actions = [];
      if (this.ApiRequest.userHasPermission('print-shift-produced')) {
        actions.push({ label: this.$t('general.actions.print'), emit: 'printLabelView', bicon: 'printer', class: 'btn-sm btn-secondary' });
      }
      actions.push({ label: this.$t('general.actions.history'), emit: 'itemHistory', bicon: 'list-task', class: 'btn-sm btn-info' });
      actions.push({ label: this.$t('general.actions.overview'), emit: 'itemFactoryProductOverview', bicon: 'bi bi-eye-fill', class: 'btn-sm btn-info' });
      if (this.ApiRequest.userHasPermission('edit-' + this.basePerm)) {
        actions.push({ label: this.$t('general.actions.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-sm btn-primary', ifconfig: { func: this.canEdit, useFunc: true } });
      }
      if (this.ApiRequest.userHasPermission('delete-' + this.basePerm)) {
        actions.push({ label: this.$t('general.actions.delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-sm btn-danger', ifconfig: { func: this.canDelete, useFunc: true } });
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-20' });
      return headers;
    },
    tableFilterConfig () {
      const filters = { };
      filters.created = { type: 'date-range', label: '', colOnlyFilter: true };
      filters.line_id = { type: 'slot', label: this.$t(this.baseTransParam + '.line_id'), slotcode: 'linefilter' };
      filters.flow_id = { type: 'checkbox', label: (this.flowOptions.length > 0 ? this.$t(this.baseTransParam + '.flow_id') : ''), options: this.flowOptions, textField: 'flowcode', valueField: 'value' };
      filters.usageTypes = { type: 'checkbox', label: this.$t('produced.application'), options: this.usageTypeOptions, colOnlyFilter: false, valueField: 'code', class: 'col-3' };
      filters.name = { type: 'text', label: '', colOnlyFilter: true };
      filters.code = { type: 'text', label: '', colOnlyFilter: true };
      filters.hasWeight = { type: 'checkbox', label: '', options: this.yesNoOptions, colOnlyFilter: true };
      filters.hasJournal = { type: 'checkbox', label: '', options: this.yesNoOptions, colOnlyFilter: true };
      filters.type_name = { type: 'text', label: this.$t('produced.type'), colOnlyFilter: true };
      filters.deleted = { type: 'checkbox', label: this.$t('general.item.deleted'), options: this.yesNoOptions, colOnlyFilter: false, class: 'col-1' };
      filters.warningIndicator = {
        type: 'checkbox',
        label: '',
        options: this.yesNoOptions,
        colOnlyFilter: true
      };
      return filters;
    },
    tableExpandConfig () {
      return null;
    },
    tableActions () {
      const actions = { download: true, reload: true, filters: true };
      return actions;
    },
    productTypeOptions () {
      return this.$store.getters.getConfigOptions('product_types', this.$i18n.locale);
    },
    lineOptions () {
      return this.$store.getters.getAvailableLineOptions(this.$i18n.locale);
    },
    flowOptions () {
      return this.$store.getters.getConfigOptions(
        'line_flows',
        this.$i18n.locale,
        'line_id',
        this.filterValues.line_id,
        false,
        false,
        true,
        ['flowcode']
      );
    },
    usageTypeOptions () {
      return this.PetHelper.getProductUsageTypeOptions().filter(el => ['isSeparated', 'isFinalProduct'].includes(el.code));
    },
    yesNoOptions () {
      return this.BasicHelper.yesNoOptions();
    },
    currentQRDeviceId () {
      return this.$store.state.pet.currentQRDeviceId;
    },
    qrDevice () {
      if (this.currentQRDeviceId) {
        return this.$store.getters.getDataFromConfigById('devices', this.currentQRDeviceId);
      }
    }
  },
  methods: {
    getTableData () {
      if (this.basePathComp) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        this.loading = true;
        this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.tableItems = response.data;
            this.paging = response.paging;
            this.loading = false;
          }
        });
      }
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    download (html, exportCols) {
      this.$refs.tableview.downloadTableData(this.requestPath, exportCols);
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    },
    producedDataChanged () {
      this.getTableData();
      this.$emit('saved');
    },
    deleteItem (el, approved) {
      if (approved) {
        this.setDeleted(el, 1);
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t(this.baseTransParam + '.actions.deleteTitle'), this.$t(this.baseTransParam + '.actions.deleteMessage', { name: this.BasicHelper.getTranslation(el.product.name), code: el.product.name }))
      }
    },
    setDeleted (el, isDeleted) {
      this.loading = true;
      const self = this;
      this.ApiRequest.request(this.basePathComp + '/deleted', this.ApiRequest.REQUEST_POST, { id: el.id, code: el.code, deleted: isDeleted }, function (response) {
        if (response.error) {
          self.ApiRequest.displayErrorDialog(response, self);
          self.loading = false;
        } else {
          self.getTableData();
        }
      });
    },
    editItem (el) {
      this.editType = this.isFinalProduct(el.product_id) ? 'isFinalProduct' : 'isSeparated';
      if (el) {
        this.$refs.editForm.showByCode(el.code);
      } else {
        this.$refs.editForm.show(null)
      }
    },
    itemHistory (item) {
      this.$refs.itemHistory.show(item.id)
    },
    restoreItem (el) {
      this.setDeleted(el, 0);
    },
    canEdit (row) {
      if (row.deleted_at || row.codeChangeDeleted) {
        return false;
      }
      return true;
    },
    canDelete (row) {
      if (row.produced_journal_id !== null || row.deleted_at) {
        return false;
      }
      if (row.shift.closedAt && this.ApiRequest.userHasPermission('delete-closed-' + this.basePerm)) {
        return true;
      }
      return !row.shift.closedAt && this.ApiRequest.userHasPermission('delete-' + this.basePerm);
    },
    printLabelView (el) {
      this.$refs.callprintfrm.show(null, this.basePathComp + '/print-label/' + el.id);
    },
    qrDeviceRead (val) {
      if (val) {
        this.$refs.editForm.showByCode(val);
      }
    },
    itemFactoryProductOverview (el, newtab = 0) {
      if (newtab) {
        const routeData = this.$router.resolve({
          name: 'menu.products.factoryProductOverview',
          params: { code: el.code }
        });
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push({ name: 'menu.products.factoryProductOverview', params: { code: el.code } });
      }
    },
    isSeparatedProduct (productId) {
      return this.$store.getters.isSeparatedProduct(productId)
    },
    isFinalProduct (productId) {
      return this.$store.getters.isFinalProduct(productId)
    },
    lineFilterChanged () {
      this.filterValues.flow_id = this.filterValues.flow_id.filter(flowId => (this.flowOptions.findIndex(el => el.value == flowId) >= 0));
      this.getTableData();
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.isMounted = true;
    this.getTableData();
  }
}
