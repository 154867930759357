export default {
  name: 'ShiftFinder',
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    },
    linesFilteredInit: {
      type: Array,
      default: null
    },
    compact: {
      type: Boolean,
      default: false
    },
    linesWithAssemblyWarehouse: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      content: this.value,
      startdate: null,
      type_id: null,
      line_id: null,
      currentReq: null
    }
  },
  computed: {
    lineOptions () {
      const items = this.$store.getters.getConfigOptions(
        'lines',
        this.$i18n.locale,
        null,
        null,
        false,
        false,
        false,
        ['shiftWarehouseMap']
      );
      if (this.linesFilteredInit != null) {
        if (this.line_id == null) {
          this.line_id = this.linesFilteredInit[0].value
        }
        return this.linesFilteredInit
      }
      if (this.linesWithAssemblyWarehouse) {
        return items.filter(el => {
          return (Object.values(el.shiftWarehouseMap).filter(warehouseId => { return warehouseId !== null }).length > 0);
        })
      }
      return items;
    },
    typeOptions () {
      if (this.linesFilteredInit != null && this.type_id == null) {
        this.type_id = this.$store.getters.getConfigOptions('shift_types', this.$i18n.locale)[0].value
      }
      return this.$store.getters.getConfigOptions('shift_types', this.$i18n.locale);
    }
  },
  methods: {
    findShift (date, line, type) {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.content = null;
      if (date && line && type) {
        const reqData = {
          id: null,
          line_id: line,
          startdate: date,
          type_id: type
        };
        this.currentReq = this.ApiRequest.request('shift/find', this.ApiRequest.REQUEST_GET, reqData, (response) => {
          if (response.data) {
            this.content = this.BasicHelper.cloneObject(response.data);
          }
        });
      }
    }
  },
  watch: {
    content () {
      this.$emit('input', this.content);
    },
    value () {
      if (this.content !== this.value) {
        this.content = this.value;
        if (this.content) {
          this.startdate = this.content.startdate;
          this.type_id = this.content.type_id;
          this.line_id = this.content.line_id
        } else {
          this.startdate = null;
          this.type_id = null;
          this.line_id = null;
        }
      }
    }
  },
  mounted () {
    if (this.content) {
      this.startdate = this.content.startdate;
      this.type_id = this.content.type_id;
      this.line_id = this.content.line_id;
    }
  }
}
