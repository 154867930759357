import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import BFormJson from 'ebg-vue-common/src/components/common/fromjson.vue'

const defaultEl = {
  id: null,
  code: '',
  name: {},
  shortname: {},
  order: 0,
  type_id: null,
  host: '',
  config: {},
  measure_type_id: null,
  precision: null,
  requiredMeasurement: false,
  product_id: null,
  hasComment: false,
  templatespivot: [],
  warehouse_id: null,
  plateWeight: 0,
  useMeasurementAutoUpdate: false,
  site_id: null
};

export default {
  name: 'DeviceEdit',
  data () {
    return {
      editEl: defaultEl,
      loading: false
    }
  },
  components: {
    BFormJson
  },
  validations: {
    editEl: {
      code: { required },
      name: { required },
      shortname: { },
      type_id: { required },
      site_id: { required }
    }
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    baseTransParam () {
      return 'device';
    },
    getTitle () {
      if (this.editEl.id) {
        return this.$t(this.baseTransParam + '.actions.edit');
      }
      return this.$t(this.baseTransParam + '.actions.new');
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission('edit-devices');
    },
    typeOptions () {
      return this.$store.getters.getConfigOptions('device_types', this.$i18n.locale);
    },
    productOptions () {
      return this.$store.getters.getConfigOptions('products', this.$i18n.locale);
    },
    measureTypeOptions () {
      return this.$store.getters.getConfigOptions('measure_types', this.$i18n.locale);
    },
    printerTemplateOptions () {
      return this.$store.getters.getConfigOptions('printer_templates', this.$i18n.locale);
    },
    warehouseOptions () {
      const options = this.$store.getters.getConfigOptions('warehouses', this.$i18n.locale, 'isAssembly', 2);
      options.unshift({ value: null, text: '-' });
      return options;
    },
    isPrinter () {
      const match = this.typeOptions.find(el => el.value == this.editEl.type_id);
      if (match && match.code == 'PRINTZ') {
        return true;
      }
      return false;
    },
    templatesHeaders () {
      const headers = [
        { key: 'template_id', label: this.$t('device.printer.template.text'), type: 'slot', slotcode: 'template' },
        { key: 'default_locale_id', label: this.$t('device.printer.template.defaultLocale'), type: 'slot', slotcode: 'default_locale_id' },
        { key: 'isDefault', label: this.$t('device.printer.template.isDefault'), type: 'slot', slotcode: 'checkbox' }
      ];
      const actions = [];
      if (this.canEdit) {
        actions.push({ label: this.$t('general.actions.delete'), emit: 'deleteTemplate', bicon: 'trash', class: 'btn-sm btn-danger' });
      }
      headers.push({ key: 'actions', label: '', actions: actions, classTd: 'w-10' });
      return headers;
    },
    operationalSiteOptions () {
      return this.$store.getters.getConfigOptions('operational_sites', this.$i18n.locale);
    },
    localesOptions () {
      const options = this.$store.getters.getConfigOptions('locales', this.$i18n.locale);
      options.unshift({ code: null, text: '' });
      return options;
    }
  },
  methods: {
    show (elData) {
      if (elData) {
        this.editEl = this.BasicHelper.cloneObject(elData);
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
        this.editEl.type_id = this.typeOptions[0].value;
      }
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        const self = this;
        this.ApiRequest.request('devices/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, function (response) {
          if (response.error) {
            self.ApiRequest.displayErrorDialog(response, self);
          } else {
            self.$store.dispatch('reloadConfig');
            self.$emit('saved', response.data);
            self.close();
          }
          self.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    },
    addTemplate () {
      this.editEl.templatespivot.push({ template_id: null, isDefault: false, default_locale_id: null });
    },
    deleteTemplate (el, newtab, action, idx) {
      this.editEl.templatespivot.splice(idx, 1);
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
