import CustomerEdit from '@/components/pet/partners/customers/edit/CustomerEdit.vue'
import { mapState } from 'vuex';

const defaultFilterValues = {
  code: '',
  name: '',
  orgNumberOrPersonCode: '',
  legalAddress: '',
  forProducts: [],
  partner_type: [],
  isActual: [1],
  deliverytypes: [],
  deleted: ['0'],
  myPartners: null
};

export default {
  name: 'CustomerList',
  components: {
    CustomerEdit
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { item: 'name', direction: 'asc' },
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false
    };
  },
  computed: {
    ...mapState({
      currentUser: state => state.user
    }),
    basePathComp () {
      return 'customers'
    },
    basePerm () {
      return 'customers';
    },
    hasSalesPurchaseRoles () {
      return this.ApiRequest.userHasRole('sales') || this.ApiRequest.userHasRole('purchase')
    },
    baseTransParam () {
      return 'partners.customers';
    },
    tableHeaders () {
      const headers = [
        { key: 'code', label: this.$t('partners.customers.code'), sortable: true, filterKey: 'code' },
        { key: 'ax2009code', label: this.$t('partners.vendors.ax2009code'), sortable: true, filterKey: 'ax2009code' },
        { key: 'name', label: this.$t('partners.customers.name'), sortable: true, filterKey: 'name' },
        { key: 'orgNumberOrPersonCode', label: this.$t('partners.customers.orgNumberOrPersonCode'), sortable: true, filterKey: 'orgNumberOrPersonCode' },
        { key: 'legalAddress', label: this.$t('partners.customers.legalAddress'), sortable: true, filterKey: 'legalAddress' },
        { key: 'deliveryType_id', label: this.$t('partners.customers.deliveryType'), sortable: false, type: 'slot', slotcode: 'deliveryType' },
        { key: 'ax2009Balance', label: this.$t('partners.customers.ax2009Balance'), sortable: false, format: 'formatDecimal', preicion: 2 },
        { key: 'sales_manager.fullname', class: 'col-2', label: this.$t(this.baseTransParam + '.salesManager') },
        { key: 'customerStatus', label: this.$t(this.baseTransParam + '.status'), type: 'slot', slotcode: 'customerStatus' },
        { key: 'deleted', label: this.$t('general.item.deleted'), filterKey: 'deleted', format: 'formatYesNo', reverseMode: true, classTd: 'w-10' }
      ];
      const actions = [];
      if (this.canEdit) {
        actions.push({ label: this.$t('general.actions.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-primary btn-sm' });
        actions.push({ label: this.$t('partners.customers.actions.notActual'), emit: 'setNotActual', bicon: 'eye-slash-fill', class: 'btn-danger btn-sm', ifconfig: { param: 'isActual', value: 0, notCheck: true } });
      }

      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    tableExpandConfig () {
      return null;
    },
    tableFilterConfig () {
      const filters = { };
      filters.code = { type: 'text', label: '', colOnlyFilter: true };
      filters.ax2009code = { type: 'text', label: '', colOnlyFilter: true };
      filters.name = { type: 'text', label: '', colOnlyFilter: true };
      filters.orgNumberOrPersonCode = { type: 'text', label: '', colOnlyFilter: true };
      filters.legalAddress = { type: 'text', label: '', colOnlyFilter: true };
      filters.deleted = { type: 'checkbox', label: '', options: this.yesNoOptions, colOnlyFilter: true };

      // Materiāla veidi
      filters.forProducts = {
        type: 'checkbox',
        label: this.$t('partners.vendors.productTypes'),
        options: this.productTypesOptions,
        class: 'col-md-8 col-sm-12',
        valueField: 'value'
      };
      // Piegādes veids
      filters.deliverytypes = { type: 'checkbox', label: this.$t('partners.vendors.deliveryType'), options: this.deliveryTypesOptions, class: 'col-lg-4 col-sm-12 col-md-12 mb-lg-3', valueField: 'value' };
      // Partnera veids
      filters.partner_type = { type: 'checkbox', label: this.$t('partners.customers.partnerType'), options: this.vendorTypesOptions, class: 'col-lg-auto col-sm-12 col-md-12', valueField: 'value' };
      // Aktīvs
      filters.isActual = { type: 'checkbox', label: this.$t('partners.customers.isActual'), options: this.yesNoOptions, class: 'col-lg-auto col-sm-12 col-md-12', valueField: 'code' };
      // Piegādātāja pārbaudes statuss
      filters.partnerCheckStatus = { type: 'checkbox', label: this.$t('partners.customers.partnerCheckStatus'), options: this.partnerCheckStatusOptions, class: 'col-md-auto col-sm-12', valueField: 'value' };
      // Piegādātāja materiālu statuss
      filters.materials = {
        type: 'checkbox',
        label: this.$t('partners.customers.materials'),
        options: this.materialsOptions,
        class: 'col-md-auto col-sm-12',
        valueField: 'value'
      };
      // Piegādātāja materiālu cenu statuss
      filters.materialPrices = {
        type: 'checkbox',
        label: this.$t('partners.customers.materialPrices'),
        options: this.materialPriceOptions,
        class: 'col-md-auto col-sm-12',
        valueField: 'value'
      };
      // Tirdzniecības menedžeris
      filters.salesManager = { type: 'text', label: this.$t(this.baseTransParam + '.salesManager'), colOnlyFilter: false, class: 'col-lg-2 col-md-3 col-sm-11 text-nowrap' };
      filters.myPartners = { type: 'checkbox', label: ' ', valueField: 'value', class: 'col-1', colOnlyFilter: false, options: [{ value: this.currentUser?.id, bicon: 'person-circle', text: this.$t(this.baseTransParam + '.myPartners') }] };

      return filters;
    },
    canDelete () {
      return this.ApiRequest.userHasPermission('delete-' + this.basePerm);
    },
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-' + this.basePerm);
    },
    yesNoOptions () {
      return this.BasicHelper.yesNoOptions();
    },
    partnerCheckStatusOptions () {
      return [
        { value: 'ACCEPTED', text: this.$t('partners.customers.isApprovedYes'), bicon: 'shield-fill-check', colorCheckbox: true, color: '#619403FF' },
        { value: 'DECLINED', text: this.$t('partners.customers.isApprovedNo'), bicon: 'shield-fill-check', colorCheckbox: true, color: '#DC3545FF' },
        { value: 'UNKNOWN', text: this.$t('partners.customers.isApprovedUnknow'), bicon: 'shield-fill-check', colorCheckbox: true, color: '#6C757DFF' }
      ]
    },
    materialsOptions () {
      return [
        { value: 'SPECIFIED', text: this.$t('partners.customers.hasMaterials.1'), bicon: 'clipboard2-check text-black', colorCheckbox: true, color: '#619403FF' },
        { value: 'NOT_SPECIFIED', text: this.$t('partners.customers.hasMaterials.0'), bicon: 'clipboard2-x text-black', colorCheckbox: true, color: '#DC3545FF' }
      ]
    },
    materialPriceOptions () {
      // TODO: Add translations
      return [
        { value: 'NOT_SPECIFIED', text: this.$t('partners.customers.materialPriceStatuses.NOT_SPECIFIED'), bicon: 'currency-euro', colorCheckbox: true, color: '#6C757DFF' },
        { value: 'ACTUAL', text: this.$t('partners.customers.materialPriceStatuses.ACTUAL'), bicon: 'currency-euro', colorCheckbox: true, color: '#619403FF' },
        { value: 'PARTLY_NOT_ACTUAL', text: this.$t('partners.customers.materialPriceStatuses.PARTLY_NOT_ACTUAL'), bicon: 'currency-euro', colorCheckbox: true, color: '#FFC107FF' },
        { value: 'NOT_ACTUAL', text: this.$t('partners.customers.materialPriceStatuses.NOT_ACTUAL'), bicon: 'currency-euro', colorCheckbox: true, color: '#DC3545FF' }
      ]
    },
    requestPath () {
      let path = this.ApiRequest.addGetParamsToPath(this.basePathComp + '/list?perPage=' + this.paging.limit + '&page=' + this.paging.page, this.filterValues, true);
      if (this.tableSorting.item) {
        path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
      }
      return path;
    },
    tableActions () {
      const actions = { extra: [], download: false };
      if (this.canEdit && !this.$store.getters.isFeatureEnabled('d365_api', 'partners.sync')) {
        actions.add = { title: this.$t('general.actions.add') };
      }
      actions.extra.push({
        class: 'btn-primary mt-3 mx-0',
        event: 'addPotencialPartner',
        icon: 'bi bi-plus',
        title: this.$t('partners.potencialPartner.add')
      })
      return actions;
    },
    deliveryTypesOptions () {
      return this.$store.getters.getConfigOptions('delivery_types', this.$i18n.locale);
    },
    productTypesOptions () {
      return this.PetHelper.getProductTypes().filter(el => (el.inAxapta && (el.isSeparated || el.isFinalProduct)));
    },
    vendorTypesOptions () {
      return this.$store.getters.getConfigOptions('partner_types', this.$i18n.locale);
    }
  },
  methods: {
    addPotencialPartner () {
      const routeData = this.$router.resolve({ name: 'menu.partners.customers.view', params: { code: 'NEW', query: { q1: 'q1' } }, query: { isPotencial: 1 } });
      window.open(routeData.href, '_blank');
    },
    getTableData () {
      if (this.basePathComp) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        this.loading = true;
        this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.tableItems = response.data;
            this.paging = response.paging;
            this.loading = false;
          }
        });
      }
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      if (this.hasSalesPurchaseRoles) {
        this.filterValues.myPartners = [this.currentUser?.id]
      }
      this.getTableData();
    },
    editItem (el, newtab = 0) {
      if (newtab) {
        const routeData = this.$router.resolve({ name: 'menu.partners.customers.view', params: { code: (el ? el.code : 'NEW') } });
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push({ name: 'menu.partners.customers.view', params: { code: (el ? el.code : 'NEW') } });
      }
    },
    setNotActual (el) {
      this.loading = true;
      this.ApiRequest.request(this.basePathComp + '/edit/' + el.id + '/notactual', this.ApiRequest.REQUEST_POST, { }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        } else {
          this.getTableData();
        }
      });
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    },
    deleteItem (el, approved) {
      if (approved) {
        this.setDeleted(el, 1);
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t(this.baseTransParam + '.actions.deleteTitle'), this.$t(this.baseTransParam + '.actions.deleteMessage', { name: this.BasicHelper.getTranslation(el.name), code: el.code }))
      }
    },
    restoreItem (el) {
      this.setDeleted(el, 0);
    },
    setDeleted (el, isDeleted) {
      this.loading = true;
      this.ApiRequest.request(this.basePathComp + '/deleted', this.ApiRequest.REQUEST_POST, { id: el.id, code: el.code, deleted: isDeleted }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        } else {
          this.getTableData();
        }
      });
    },
    getRateStatusIcon (status) {
      if (status == 1) {
        return 'btn-success';
      } else if (status == 2) {
        return 'btn-warning';
      } else if (status == 10) {
        return 'btn-danger';
      }
      return '';
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.getTableData();
    this.isMounted = true;
  },
  watch: {
    basePathComp: {
      immediate: true,
      handler (val) {
        if (val) {
          this.isMounted = false;
          this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
          this.getTableData();
          this.isMounted = true;
        }
      }
    }
  }
}
