import useVuelidate from '@vuelidate/core';
import PartnerPicker from '@/components/pet/partners/picker/PartnerPicker.vue'
import SalesStatusInfo from '@/components/pet/sales/statusinfo/SalesStatusInfo.vue'
import LogList from 'ebg-vue-common/src/components/log/list/LogList.vue'
import SalesOrderLineList from '@/components/pet/sales/salesorder/linelist/SalesOrderLineList.vue'
import SalesOrderFactoryProducts from '@/components/pet/sales/salesorder/factoryproducts/SalesOrderFactoryProducts.vue'
import Comments from 'ebg-vue-common/src/components/common/comments/Comments.vue';
import ModelFileList from '@/components/modelfile/list/ModelFileList.vue'
import LinkToDifferentSystem from 'ebg-vue-common/src/components/common/LinkToDifferentSystem.vue'
import { helpers, required, requiredIf } from '@vuelidate/validators';
import TransportCostList from '@/components/pet/sales/order/transportCost/list/TransportCostList.vue';
import CargoD365LineList from '@/components/pet/sales/cargo/d365linelist/CargoD365LineList.vue'
import SalesOrderRequirements from '@/components/pet/sales/salesorder/requirements/SalesOrderRequirements.vue'
import UserPicker from 'ebg-vue-common/src/components/user/picker/UserPicker.vue'
import CargoWeightings from '@/components/pet/sales/cargo/weightings/CargoWeightings.vue'
import DevicePrinterCallprint from '@/components/pet/device/printer/callprint/DevicePrinterCallprint.vue';
import Gallery from 'ebg-vue-common/src/components/common/Gallery/Gallery.vue';
import QualityTestEnablingModal from '@/components/pet/sales/cargo/editsimple/QualityTestEnablingModal.vue';
import ContainerPicker from '@/components/pet/sales/cargo/container/ContainerPicker.vue'

const defaultEl = {
  id: null,
  deliveryDate: null,
  receivedAt: null,
  sendDate: null,
  order_cargo_type_id: null,
  delivery_type_id: null,
  cargo_type_id: null,
  transport_provider_id: null,
  transportLicensePlate: '',
  transportTrailerPlate: '',
  transportDriverName: '',
  transportDriverPhone: '',
  container_number: '',
  apuscode: '',
  lines: [],
  requirements: [],
  d365lines: [],
  order_id: null,
  factoryproducts: [],
  weightStart_1: null,
  weightStart_2: null,
  weightEnd_1: null,
  weightEnd2: null,
  clientcode: null,
  ax2009code: '',
  stage_id: null,
  localbatchcode: null,
  cargoQualityTestEnabled: false
};

const defaultElOrder = {
  id: null,
  order_cargo_type_id: null,
  vendor_id: null,
  customer_id: null,
  vendor: null,
  customer: null,
  orderDate: null,
  clientcode: '',
  plannedDeliveryMonth: null,
  partner_address_id: null,
  local_warehouse_id: null,
  warehouse_id: null
};

const checkPartner = (value, siblings, vm) => {
  if (vm.cargoPartner == undefined || vm.cargoPartner == null) return true
  const findProduct = vm.cargoPartner.products.find(el => el.product_id == value)
  if (findProduct == undefined) {
    return false
  } else {
    return true
  }
}

export default {
  name: 'CargoEditSimple',
  data () {
    return {
      container: [],
      canEditPartner: false,
      mountload: false,
      loading: false,
      cargo: null,
      order: null,
      commentCount: null,
      responseDataManagers: {},
      initCargo: null
    }
  },
  validations: {
    cargo: {
      sendDate: { },
      deliveryDate: {},
      receivedAt: { required },
      transportLicensePlate: {
        required: requiredIf(function () {
          return this.cargo.needDeliveryInfo;
        })
      },
      requirements: {
        $each: helpers.forEach({
          requirement_id: { required }
        })
      },
      d365lines: {
        $each: helpers.forEach({
          product_id: {
            required: requiredIf(function () {
              return this.isIncoming;
            }),
            checkPartner
          },
          quality_id: {
            required: requiredIf(function () {
              return this.isIncoming;
            })
          },
          quantity: {
            required: requiredIf(function () {
              return this.isIncoming && this.cargo && this.checkStatusIds.includes(this.cargo.status_id);
            })
          },
          measure_type_id: {
            required: requiredIf(function () {
              return this.isIncoming;
            })
          },
          pieces: {
            required: requiredIf(function () {
              return this.isIncoming && this.cargo && this.checkStatusIds.includes(this.cargo.status_id);
            })
          }
        })
      }
    }
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  components: {
    DevicePrinterCallprint,
    UserPicker,
    TransportCostList,
    PartnerPicker,
    SalesStatusInfo,
    LogList,
    SalesOrderLineList,
    SalesOrderFactoryProducts,
    Comments,
    ModelFileList,
    LinkToDifferentSystem,
    CargoD365LineList,
    SalesOrderRequirements,
    CargoWeightings,
    Gallery,
    QualityTestEnablingModal,
    ContainerPicker
  },
  computed: {
    allowEditCargoQualityTestEnabled () {
      return (this.cargo && this.initCargo != null && this.cargo.cargoQualityTestEnabled == false && this.canEditSimple);
    },
    checkboxOption () {
      return { title: 'test1', bicon: 'check' }
    },
    checkStatusIds () {
      return this.$store.getters.getConfigOptions('cargo_statuses', this.$i18n.locale).filter(el => ['LOADED', 'FINISHED'].includes(el.code)).map(el => el.value);
    },
    qrReaderDeviceIds () {
      return [this.$store.state.pet.currentQRDeviceId]
    },
    orderCargoTypeId () {
      let orderCargoTypeId = null;
      if (this.cargo) {
        orderCargoTypeId = this.cargo.order_cargo_type_id;
      }
      return orderCargoTypeId;
    },
    selectedOrdertype () {
      return this.$store.getters.getDataFromConfigWithFilter('order_cargo_types', 'id', this.orderCargoTypeId);
    },
    isIncoming () {
      if (this.selectedOrdertype) {
        return this.selectedOrdertype.isIncoming;
      }
      return true;
    },
    isTransferOrder () {
      return this.selectedOrdertype && this.selectedOrdertype.code == 'TNF';
    },
    isImportOrder () {
      return this.selectedOrdertype && this.selectedOrdertype.code == 'IMP';
    },
    partnerPath () {
      return (this.isIncoming ? 'vendor' : 'customer');
    },
    baseTransParam () {
      return (this.selectedOrdertype ? this.selectedOrdertype.transbase : 'purchase') + '-order';
    },
    orderType () {
      if (this.cargo) {
        return this.BasicHelper.getConfigValue('order_cargo_types', 'id', this.cargo.order_cargo_type_id, 'code');
      }
      return null;
    },
    rampOptions () {
      return this.$store.getters.getConfigOptions(
        'warehouses_ramps',
        this.$i18n.locale,
        'warehouse_id',
        this.order.local_warehouse_id,
        false,
        false,
        false,
        ['id', 'site_id', 'order']
      ).sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
    },
    productOptions () {
      return this.$store.getters.getConfigOptions('products', this.$i18n.locale, null, null, false, false, false, ['sales_manager_id', 'warehouse_editor_id']);
    },
    cargoPartner () {
      if (this.order) {
        return (this.isIncoming ? this.order.vendor : this.order.customer);
      }
      return null;
    },
    d365Partners () {
      return this.$store.getters.isFeatureEnabled('d365_api', 'partners.sync')
    },
    routeParamsView () {
      return {
        company: process.env.VUE_APP_D365_AREAID,
        code: (this.cargoPartner) ? this.cargoPartner.partycode : null
      }
    },
    editPerm () {
      return 'edit-warehouse-order';
    },
    getTitle () {
      if (!this.isModal) {
        return ''
      }
      if (this.cargo && this.cargo.id) {
        return this.$tc('cargo.actions.edit', {}, this.editEl);
      }
      return this.$t('cargo.actions.add');
    },
    deliveryTypeOptions () {
      const options = this.$store.getters.getConfigOptions(
        'delivery_types',
        this.$i18n.locale,
        null,
        null,
        false,
        false,
        false,
        ['description']
      );
      options.forEach(el => {
        el.text += ' (' + this.BasicHelper.getTranslation(el.description) + ')';
      });
      return options;
    },
    cargoTypeOptions () {
      return this.$store.getters.getConfigOptions('cargo_types', this.$i18n.locale);
    },
    requirementOptions () {
      const requirementOption = this.$store.getters.getConfigOptions(
        'sales_order_requirements_options',
        this.$i18n.locale,
        null,
        null,
        false,
        false,
        false,
        ['mandatory']
      );
      return requirementOption
    },
    hasEditPerm () {
      return this.ApiRequest.userHasPermission(this.editPerm);
    },
    hasDeleteFilesPerm () {
      return this.ApiRequest.userHasPermission('delete-cargo-files');
    },
    canEdit () {
      return !this.readonly && this.cargo && !this.loading && this.hasEditPerm && (!this.cargo.id || this.cargo.canEditCargo);
    },
    canEditSimple () {
      return !this.readonly && this.order && !this.loading && this.hasEditPerm && !this.cargo.bookedAt;
    },
    partnerPickerFilters () {
      const filters = {
        actual: 1,
        with: 'activeLoadingAddresses;products'
      }
      if (this.isIncoming) {
        filters.isProductProvider = 1
      } else {
        filters.with += ';productRequirements';
      }
      return filters;
    },
    warehouseOptions () {
      return this.$store.getters.getConfigOptions(
        'warehouses',
        this.$i18n.locale,
        null,
        null,
        false,
        false,
        false,
        ['receiveOrders', 'inAxapta', 'isAssembly', 'sendOrders']
      );
    },
    warehouseOptionsLocal () {
      return this.warehouseOptions.filter(el => {
        if (!this.isIncoming) {
          return el.inAxapta && el.sendOrders
        }
        return el.receiveOrders;
      });
    },
    warehouseOptionsD365 () {
      return this.warehouseOptions.filter(el => {
        if (!this.isIncoming) {
          return el.inAxapta && el.sendOrders;
        }
        return el.receiveOrders && el.inAxapta;
      });
    },
    statusChangeButtons () {
      if (this.cargo && this.cargo.id && this.cargo.toStatusCodes && this.canEdit) {
        return this.PetHelper.getCargoStatusChangeOptions(
          this.cargo.toStatusCodes,
          !this.isIncoming,
          this.order.products.length,
          this.cargo.lines.length,
          this.cargo.localcode,
          true,
          this.cargo.status_id
        );
      }
      return [];
    },
    tabOptions () {
      const options = [];
      if (this.cargo) {
        if (this.cargo.id) {
          if (this.isIncoming) {
            options.push({ code: 'd365lines', text: this.$t('cargo.tabs.d365lines'), invalid: this.v$.cargo.d365lines.$error });
          } else {
            options.push({ code: 'lines', text: this.$t('cargo.tabs.lines') });
          }
          options.push({ code: 'files', text: this.$t('cargo.tabs.files') });
          options.push({ code: 'comments', text: this.$t(`${this.baseTransParam}.tabs.comments`) });
        }
      }
      return options;
    },
    mandatoryRequirements () {
      const customerProdReqIds = [];
      if (this.order && this.order.customer) {
        this.order.customer.product_requirements.forEach(el => {
          if (this.order.products.find(subEl => subEl.product_id == el.product_id) && customerProdReqIds.indexOf(el.requirement_id)) {
            customerProdReqIds.push(el.requirement_id);
          }
        });
      }
      const items = this.requirementOptions.filter(el => el.mandatory || customerProdReqIds.find(reqId => reqId == el.value));
      return items;
    },
    requirementOptionFilter () {
      return { value: this.requirementOptions.filter(el => !this.cargo.requirements.find(subEl => subEl.requirement_id == el.value)).map(el => el.value) }
    },
    customerProducts () {
      return (this.order && this.order.customer ? this.order.customer.products : []);
    },
    loadingAddresses () {
      const options = [];
      if (this.order && this.order[this.partnerPath]) {
        this.order[this.partnerPath].active_loading_addresses.forEach(el => {
          options.push({
            id: el.id,
            text: this.BasicHelper.getTranslation(el.name),
            address: el.address
          })
        });
        if (this.order.partner_address_id && this.order.address && !options.find(el => el.id == this.order.partner_address_id)) {
          options.push({
            id: this.order.address.id,
            text: this.BasicHelper.getTranslation(this.order.address.name),
            address: this.order.address.address
          })
        }
      }
      return options;
    },
    address () {
      if (this.order && this.order.partner_address_id) {
        const addr = this.loadingAddresses.find(el => el.id == this.order.partner_address_id);
        if (addr) {
          return addr.address;
        }
      }
      return '';
    },
    generateLinesStatuses () {
      return [
        this.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'code', 'TEMPLATE', 'id'),
        this.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'code', 'PLANNED', 'id'),
        this.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'code', 'LOADING', 'id')
      ]
    },
    allowCheckRequirementStatuses () {
      return [
        this.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'code', 'LOADING', 'id'),
        this.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'code', 'LOADED', 'id'),
        this.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'code', 'FINISHED', 'id')
      ]
    },
    isCheckRequirementInAllowedStatus () {
      return this.allowCheckRequirementStatuses.includes(this.cargo.status_id);
    },
    cargoRouteName () {
      if (this.isModal) {
        return 'menu.warehouse.cargos.view-simple';
      }
      return 'menu.warehouse.cargos.view';
    },
    orderTypeOptions () {
      return this.$store.getters.getConfigOptions('order_cargo_types', this.$i18n.locale, 'isIncoming', this.isIncoming);
    },
    allowedTemplates () {
      return ['BATCH_OUT'];
    },
    currencyCode () {
      let currency = null;
      if (this.cargo && this.cargo.order && this.cargo.order.currency_id) {
        currency = this.$store.getters.getDataFromConfigById('currencies', this.cargo.order.currency_id).code;
      }
      return currency;
    },
    paymentTermOptions () {
      return this.$store.getters.getConfigOptions('payment_terms', this.$i18n.locale, null, null, false, false, false, ['days']);
    },
    documentParamOptions () {
      if (!this.isIncoming && !this.isTransferOrder) {
        return ['BILL.INFO', 'BILL.ENDINFO'];
      }
      return [];
    },
    cargoPhotoFiles () {
      const files = [];
      if (this.cargo && this.cargo.files != undefined) {
        this.cargo.files.forEach(file => {
          if (file.model_attachment?.code === 'Cargo_photo') {
            files.push(file);
          }
        })
      }
      return files;
    },
    cargoPhotoModelAttachmentId () {
      return this.$store.getters.getDataFromConfigWithFilter('model_attachments', 'code', 'Cargo_photo')?.id;
    },
    filesPath () {
      return `cargo-orders/files/${this.cargo?.id}`;
    }
  },
  methods: {
    resolveSalesOrderTemplateQrUrl () {
      const routeData = this.$router.resolve({ name: 'menu.warehouse.cargos.view-simple', params: { cargocode: this.cargo.localcode } });
      return routeData.href;
    },
    printDeviceIds () {
      const ids = [];
      if (this.cargo && this.cargo.order && this.cargo.order.warehouse_id) {
        const workstationIds = this.$store.getters.getParamFromConfig(
          'warehouses',
          this.cargo.order.warehouse_id,
          'workstation_ids',
          this.$i18n.locale
        );

        if (workstationIds) {
          const deviceIds = this.$store.getters.getConfigOptions(
            'workstations',
            this.$i18n.locale,
            'id',
            workstationIds,
            false,
            false,
            true,
            ['device_ids']
          );
          deviceIds.forEach(el => {
            el.device_ids.forEach(deviceId => {
              ids.push(deviceId);
            });
          });
        }
      }
      // to make ids unique
      return [...new Set(ids)];
    },
    printLabelForm () {
      this.$refs.printForm.show(null, 'cargo-orders/print-label/' + this.cargo.id, this.printDeviceIds(), this.resolveSalesOrderTemplateQrUrl());
    },
    updateDueDate () {
      this.cargo.dueDate = null
      if (this.cargo.payment_term_id != null && this.cargo.billdate != null) {
        const selectedPaymentTerm = this.paymentTermOptions.find(el => el.value == this.cargo.payment_term_id)
        if (selectedPaymentTerm != undefined) {
          const date = new Date(Date.parse(this.cargo.billdate))
          date.setDate(date.getDate() + selectedPaymentTerm.days)
          this.cargo.dueDate = date
        }
      }
    },
    setCargoData (res, products) {
      if (!res.receivedAt) {
        res.receivedAt = new Date();
      }
      if (res.isIncoming && res.d365lines.length == 0 && this.generateLinesStatuses.find(statusId => statusId == res.status_id)) {
        products.forEach(prod => {
          const match = res.d365lines.find(el => el.product_id == prod.quality_id && el.product_id == prod.quality_id);
          if (!match) {
            const prodEl = this.$store.getters.getDataFromConfigById('products', prod.product_id);
            res.d365lines.push({
              id: null,
              product_type_id: prod.product_type_id,
              product_id: prod.product_id,
              quality_id: prod.quality_id,
              quantity: '',
              pieces: (prodEl.isUnitCount ? '' : 1),
              measure_type_id: (prodEl ? prodEl.default_measure_type_id : null),
              apus_waste_class_id: prod.apus_waste_class_id
            });
          }
        })
      }
      return res;
    },
    show (elData, readonly = false) {
      this.mountload = true;
      this.commentCount = null;
      this.canEditPartner = false;
      if (elData.localcode && elData.localcode != 'NEW') {
        // existing - load front backend
        this.ApiRequest.request('cargo-orders/view-by-code/' + elData.localcode, this.ApiRequest.REQUEST_GET, { }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.order = this.BasicHelper.cloneObject(response.data.order);
            this.cargo = this.setCargoData(this.BasicHelper.cloneObject(response.data), response.data.order.products);
            this.initCargo = this.BasicHelper.cloneObject(this.cargo);
          }
          if (!this.order.vendor && this.isIncoming) {
            this.canEditPartner = true;
          }
          this.mountload = false;
          this.v$.$reset();
        });
      } else {
        // Is not used
        const newcargo = this.BasicHelper.cloneObject(defaultEl);
        Object.keys(elData).forEach(param => {
          newcargo[param] = elData[param];
        });
        newcargo.receivedAt = new Date();
        newcargo.isIncoming = this.isIncoming;
        this.order = this.BasicHelper.cloneObject(defaultElOrder);
        this.order.order_cargo_type_id = this.orderTypeOptions[0].value;
        this.order.warehouse_id = this.warehouseOptionsD365[0].value;
        this.order.local_warehouse_id = this.warehouseOptionsLocal[0].value;
        this.cargo = this.setCargoData(newcargo, []);
        this.cargo.order_cargo_type_id = this.order.order_cargo_type_id;
        this.canEditPartner = true;
        this.mountload = false;
        this.initCargo = this.BasicHelper.cloneObject(this.cargo);
        this.v$.$reset();
      }
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    getUserOptionText (item) {
      return (item ? item.fullname : '');
    },
    save (moveToStatus = null) {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        this.$forceUpdate();
        const reqData = { };
        if (this.cargo.container_number) {
          this.cargo.container_number = this.cargo.container_number.toUpperCase();
        }
        for (const key of Object.keys(this.cargo)) {
          if (key != 'lines') {
            reqData[key] = this.cargo[key];
          }
        }
        reqData.order = {
          id: this.order.id,
          vendor_id: this.order.vendor_id,
          customer: this.order.customer_id,
          partner_address_id: this.order.partner_address_id,
          warehouse_id: this.order.warehouse_id,
          local_warehouse_id: this.order.local_warehouse_id
        };
        reqData.deliveryDate = this.cargo.receivedAt;
        if (this.isIncoming) {
          const sendLines = [];
          this.cargo.d365lines.forEach(el => {
            sendLines.push(el);
          });
          reqData.d365lines = sendLines;
        }
        this.ApiRequest.request('cargo-orders/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
            this.loading = false;
            this.$forceUpdate();
          } else {
            this.order = this.BasicHelper.cloneObject(response.data.order);
            this.cargo = this.setCargoData(this.BasicHelper.cloneObject(response.data), response.data.order.products);
            this.initCargo = this.BasicHelper.cloneObject(this.cargo);
            if (moveToStatus) {
              this.moveToStatus(moveToStatus);
            } else {
              this.loading = false;
              this.$forceUpdate();
            }
          }
        });
      }
    },
    saveMarkForQualityTest () {
      this.loading = true;
      this.ApiRequest.request('cargo-orders/mark-for-quality-test/' + this.cargo.id, this.ApiRequest.REQUEST_POST, null, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else {
          this.cargo.cargoQualityTestEnabled = true;
        }
        this.loading = false;
      });
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    },
    partnerChanged (el) {
      if (el) {
        this.order[this.partnerPath + '_id'] = el.id;
        this.order.partner_address_id = (this.loadingAddresses.length > 0 ? this.loadingAddresses[0].id : null);
      } else {
        this.order[this.partnerPath + '_id'] = null;
        this.order.partner_address_id = null;
      }
      this.$forceUpdate();
    },
    transportProviderChanged (el) {
      if (el) {
        this.cargo.transport_provider_id = el.id;
      } else {
        this.cargo.transport_provider_id = null;
      }
    },
    filesUpdated (files = null) {
      this.$emit('filesUpdated');
      if (files) {
        this.cargo.files = files;
      }
    },
    changeStatus (btn, approved = 0) {
      if (approved || !btn.recheck) {
        if (btn.callSave) {
          this.save(btn.tostatus);
        } else {
          this.moveToStatus(btn.tostatus);
        }
      } else {
        this.$refs.changeStatusMsgBox.show(
          btn,
          btn.title,
          btn.message
        )
      }
    },
    moveToStatus (status) {
      this.loading = true;
      this.ApiRequest.request('cargo-orders/change-status/' + this.cargo.id, this.ApiRequest.REQUEST_POST, { tostatus: status }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        } else {
          this.cargo = this.setCargoData(this.BasicHelper.cloneObject(response.data), this.order.products);
          this.loading = false;
          this.$forceUpdate();
        }
      });
    },
    commentsChanged (newComments) {
      this.commentCount = newComments;
    },
    handleCargoQualityTestEnabledToggle () {
      if (!this.cargo.cargoQualityTestEnabled) {
        this.$refs.cargoQualityTestEnabledCommentModal.show();
      }
    },
    handleCargoQualityTestCommentSaved () {
      this.saveMarkForQualityTest();
      this.$refs.commentsComponent.getTableData();
    }
  },
  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'CargoEditSimple' }) }
  },
  mounted () {
    if (!this.isModal && this.$route.params.cargocode) {
      this.show({
        localcode: this.$route.params.cargocode
      });
    }
  },
  watch: {
    '$route' () {
      if (!this.isModal && this.$route.params.cargocode) {
        this.show({
          localcode: this.$route.params.cargocode
        });
      }
    }
  }
}
