import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import ShiftFlowProduced from '@/components/pet/shift/recipeedit/shiftFlowProduced/ShiftFlowProduced.vue'

const defaultItem = {
  id: null,
  product_id: null,
  quantity: '',
  fedQuantity: null
};

const QUANTITY_CLASS_DANGER = 'text-danger';
const QUANTITY_CLASS_WARNING = 'text-warning';

export default {
  name: 'RecipeEdit',
  data () {
    return {
      editEl: null,
      loading: false,
      currentReq: null,
      warehouseProductQuantityReq: null,
      elId: null,
      recipeCopyDate: null,
      recipeCopyType: null,
      isMounted: false,
      totals: [],
      sum: 0,
      fedSum: 0,
      warehouseProductQuantity: null,
      shift_flow_produced: []
    }
  },
  components: {
    ShiftFlowProduced
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    },
    recipeId: {
      type: Number,
      default: null
    },
    isParentModal: {
      type: Boolean,
      default: false
    }
  },
  validations: {
    editEl: {
      items: {
        $each: helpers.forEach({
          product_id: { required },
          quantity: { required }
        })
      }
    }
  },
  computed: {
    baseTransParam () {
      return 'recipe';
    },
    tabOptions () {
      const options = [];
      options.push(
        {
          code: 'recipeItems',
          text: this.$t('menu.shift.recipe')
        },
        {
          code: 'shiftFlowProduced',
          text: this.$t('menu.shift.shiftFlowProduced')
        }
      )
      return options;
    },
    getTitle () {
      if ((this.editEl && this.editEl.id) || this.elId) {
        return this.$t(this.baseTransParam + '.actions.edit');
      }
      return this.$t(this.baseTransParam + '.actions.new');
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission('edit-shift-recipe');
    },
    canCopy () {
      return !this.loading && this.ApiRequest.userHasPermission('edit-all-recipes');
    },
    productOptions () {
      return this.PetHelper.getFeadableBaleProducts().filter(el => el.inAxapta == true);
    },
    availableFeedProducts () {
      return this.$store.getters.getParamFromConfig('line_flows', this.editEl.flow_id, 'availableFeedProducts');
    },
    productOptionsById () {
      const productOptions = this.productOptions;
      const map = {};
      for (const option of productOptions) {
        map[option.value] = option;
      }
      return map;
    },
    shiftTypeOptions () {
      return this.$store.getters.getConfigOptions('shift_types', this.$i18n.locale);
    },
    productsInItems () {
      const map = {};
      if (!this.editEl?.items) return map;

      for (const item of this.editEl.items) {
        if (!item.product_id) continue;
        map[item.product_id] = item.product_id;
      }
      return map;
    },
    productOptionsWithoutSelected () {
      const feedProductOptions = this.productOptions.filter((option) => this.availableFeedProducts.includes(option.value));
      if (!this.productsInItems) return feedProductOptions;
      return feedProductOptions.filter(option => !(option.value in this.productsInItems));
    },
    totalsClassesFromQuantity () {
      const map = {};
      if (!this.editEl.items) return map;

      for (const item of this.editEl.items) {
        const typeId = this.productOptionsById[item.product_id]?.type_id ?? null;
        if (!typeId) continue;
        const existingQuantityClass = map[typeId] ?? null;
        if (existingQuantityClass === QUANTITY_CLASS_DANGER) continue;
        const quantityClass = this.classFromQuantity(item);
        if (quantityClass === '') continue;
        map[typeId] = quantityClass;
      }
      return map;
    }
  },
  methods: {
    setTotals () {
      if (!this.editEl?.items) return;
      this.totals = [];
      let sum = 0;
      let fedSum = 0;
      Object.values(this.editEl.items).forEach(el => {
        if (el.product_id && !el.deleted) {
          const typeId = this.BasicHelper.getConfigValue('products', 'id', el.product_id, 'type_id', false);
          if (!this.totals[typeId]) {
            this.totals[typeId] = {
              quantity: (el.quantity ? el.quantity : 0),
              fedQuantity: (el.fedQuantity ? el.fedQuantity : 0)
            };
          } else {
            this.totals[typeId].quantity += (el.quantity ? el.quantity : 0);
            this.totals[typeId].fedQuantity += (el.fedQuantity ? el.fedQuantity : 0)
          }
        }
      });
      this.totals.forEach(t => {
        sum += t.quantity;
        fedSum += t.fedQuantity;
      })
      this.sum = sum;
      this.fedSum = fedSum;
    },
    show (editEl, id) {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.elId = id;
      this.editEl = null;
      this.loading = true;
      this.recipeCopyDate = null;
      this.currentReq = this.ApiRequest.request('recipe/view/' + id, this.ApiRequest.REQUEST_GET, [], (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.close();
        } else {
          this.editEl = response.data;
          this.recipeCopyType = this.editEl.shift.type_id;
          this.setTotals()
        }
        this.loading = false;
      });

      if (this.warehouseProductQuantityReq) {
        this.warehouseProductQuantityReq.cancel('changed search query');
      }
      this.warehouseProductQuantity = null;
      this.warehouseProductQuantityReq = this.ApiRequest.request('warehouses/products', this.ApiRequest.REQUEST_GET, [], (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.close();
        } else {
          this.warehouseProductQuantity = response.data ?? null;
        }
      });

      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        if (this.editEl.shift_flow_produced_config != undefined) {
          this.editEl.shift_flow_produced_config.forEach(shiftFlowProduced => {
            const { targetQuantity, calculatedTargetQuantity } = shiftFlowProduced;
            if ((targetQuantity == null || targetQuantity === '') && calculatedTargetQuantity != null) {
              shiftFlowProduced.targetQuantity = calculatedTargetQuantity;
            }
          });
        }
        const reqData = {
          id: this.editEl.id,
          items: [],
          shift_flow_produced_config: this.editEl.shift_flow_produced_config
        };
        this.editEl.items.forEach(el => {
          reqData.items.push({
            quantity: el.quantity,
            product_id: el.product_id,
            id: el.id,
            deleted: el.deleted
          })
        });
        this.loading = true;
        this.ApiRequest.request('recipe/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      } else if (this.isParentModal) {
        this.$emit('close-parent');
      }
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
    },
    addItem () {
      const el = this.BasicHelper.cloneObject(defaultItem);
      if (!this.canCopy) {
        el.quantity = 0;
      }
      this.editEl.items.push(el);
    },
    removeItem (index) {
      if (this.editEl.items[index].id) {
        this.editEl.items[index].deleted = true;
      } else {
        this.editEl.items.splice(index, 1);
      }
    },
    copyRecipe () {
      if (this.recipeCopyDate) {
        this.loading = true;
        this.ApiRequest.request('recipe/copy/' + this.editEl.id, this.ApiRequest.REQUEST_POST, { copydate: this.recipeCopyDate, type_id: this.recipeCopyType }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.editEl = response.data;
            this.$emit('saved', response.data);
          }
          this.loading = false;
        });
      }
    },
    remainingQuantity (productId) {
      return this.warehouseProductQuantity?.[productId]?.remainingQuantity;
    },
    itemProductOptions (productId) {
      if (!productId) return this.productOptionsWithoutSelected;
      const itemProductOptions = [...this.productOptionsWithoutSelected]
      itemProductOptions.push(this.productOptionsById[productId]);
      return itemProductOptions;
    },
    classFromQuantity (item) {
      if (!this.warehouseProductQuantity) return '';
      if (item === null || item === undefined || item?.product_id === null) {
        return '';
      }

      const quantityInWarehouse = this.remainingQuantity(item.product_id);
      let quantityPlanned = item.quantity;
      let quantityFed = item.fedQuantity;

      if (quantityInWarehouse === null || quantityInWarehouse === undefined) {
        return QUANTITY_CLASS_DANGER;
      }

      if (quantityInWarehouse === 0) {
        return QUANTITY_CLASS_DANGER;
      }

      if (quantityPlanned == null) {
        quantityPlanned = 0;
      }
      if (quantityFed == null) {
        quantityFed = 0;
      }
      if (quantityInWarehouse < (quantityPlanned - quantityFed)) {
        return QUANTITY_CLASS_WARNING;
      }

      return '';
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  mounted () {
    this.isMounted = true;
    if (this.recipeId && !this.isModal) {
      this.show(null, this.recipeId);
    }
  },
  watch: {
    recipeId: {
      immediate: true,
      handler (val, prevVal) {
        if (this.isMounted && val != prevVal) {
          this.show(null, this.recipeId);
        }
      }
    },
    editEl: {
      deep: true,
      handler () {
        this.setTotals()
      }
    }
  }
}
