<template>
    <div class="home">
      <NavigationDesk />
    </div>
</template>

<script>
import NavigationDesk from 'ebg-vue-common/src/components/common/navigationdesk/NavigationDesk.vue'

export default {
  name: 'home',
  components: {
    NavigationDesk
  },
  methods: {
  }
}
</script>
