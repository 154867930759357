const defaultFilterValues = {

}
export default {
  name: 'RfidList',
  components: {
  },
  props: {
    cargo_id: {
      type: Number,
      default: null
    },
    cargo_d365_line_id: {
      type: Number,
      default: null
    },
    factory_product_id: {
      type: Number,
      default: null
    },
    useRouteFilters: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      mounted: false
    }
  },
  computed: {
    basePathComp () {
      return 'rfid/list?perPage=' + this.paging.limit + '&page=' + this.paging.page;
    },
    baseTransParam () {
      return 'rfid'
    },
    tableHeaders () {
      const headers = [
        { key: 'rfid', label: this.$t(this.baseTransParam + '.rfid'), sortable: true },
        { key: 'rfid.cargo_id', label: this.$t(this.baseTransParam + '.cargo_id'), type: 'slot', slotcode: 'cargo', sortable: true },
        { key: 'feeding.fed_at', label: this.$t(this.baseTransParam + '.fed_at'), type: 'datetime' },
        { key: 'assembly_bale_id', label: this.$t(this.baseTransParam + '.assembly_bale_id'), format: 'formatYesNo' },
        { key: 'actions', label: '', actions: [] }
      ];
      return headers;
    }
  },
  methods: {
    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      if (this.useRouteFilters) {
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
      }
      if (this.cargo_id) {
        this.filterValues.cargo_id = this.cargo_id;
      } else {
        this.filterValues.cargo_id = null;
      }
      if (this.cargo_d365_line_id) {
        this.filterValues.cargo_d365_line_id = this.cargo_d365_line_id;
      } else {
        this.filterValues.cargo_d365_line_id = null;
      }
      if (this.factory_product_id) {
        this.filterValues.factory_product_id = this.factory_product_id;
      } else {
        this.filterValues.factory_product_id = null;
      }
      this.loading = true;
      this.currentReq = this.ApiRequest.request(this.basePathComp, this.ApiRequest.REQUEST_GET, this.filterValues, (response) => {
        if (response.data) {
          this.tableItems = response.data;
          this.paging = response.paging;
          this.loading = false;
        }
      });
    },
    setFiltersByProp () {
      const params = ['cargo_id', 'cargo_d365_line_id', 'factory_product_id'];
      params.forEach(param => {
        this.filterValues[param] = this[param];
      })
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.setFiltersByProp();
      this.getTableData();
    }
  },
  mounted () {
    if (this.useRouteFilters) {
      this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    }
    this.getTableData();
    this.mounted = true;
  },
  watch: {
    cargo_id: {
      immediate: true,
      handler (val) {
        if (this.mounted) {
          this.filterValues.cargo_id = val;
          this.getTableData()
        }
      }
    },
    cargo_d365_line_id: {
      immediate: true,
      handler (val) {
        if (this.mounted) {
          this.filterValues.cargo_d365_line_id = val;
          this.getTableData()
        }
      }
    },
    factory_product_id: {
      immediate: true,
      handler (val) {
        if (this.mounted) {
          this.filterValues.factory_product_id = val;
          this.getTableData()
        }
      }
    }
  }
}
