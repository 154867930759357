import PetHelper from '@/helpers/PetHelper';

const defaultLine = {
  id: null,
  product_type_id: null,
  apus_waste_class_id: null,
  product_id: null,
  quantity: '',
  actualQuantity: '',
  pieces: '',
  individual_rate: '',
  measure_type_id: null,
  customerSaleRate: null,
  originCountry: null,
  saleInBags: false,
  purchased_in_bags: false
}
export default {
  name: 'OrderProductList',
  components: {
  },
  props: {
    orderId: {
      type: Number,
      default: null
    },
    partner_address: {
      type: String,
      default: null
    },
    orderproducts: {
      type: Array,
      default: () => null
    },
    d365lines: {
      type: Array,
      default: () => null
    },
    readonly: {
      type: Boolean,
      default: true
    },
    usedProducts: {
      type: Array,
      default: () => []
    },
    isIncoming: {
      type: Boolean,
      default: false
    },
    isExport: {
      type: Boolean,
      default: false
    },
    orderCargoTypeId: {
      type: Number | String,
      default: null
    },
    validator: {
      default: () => null
    },
    size: {
      type: String,
      default: ''
    },
    transbase: {
      type: String,
      default: 'sales-order'
    },
    currencyCode: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      currentReq: null,
      products: []
    };
  },
  validations: {
  },
  computed: {
    basePathComp () {
      return 'orders/view/' + this.orderId
    },
    countryOptions () {
      return this.$store.getters.getCountryOptions();
    },
    baseTransParam () {
      return this.transbase + '.product';
    },
    apusWasteOptions () {
      const apusWasteOptions = this.$store.getters.getConfigOptions('apus_waste_classes', this.$i18n.locale);
      return apusWasteOptions.map((apusWasteOption) => {
        apusWasteOption.text = apusWasteOption.code + ' (' + apusWasteOption.text + ')';
        return apusWasteOption;
      })
    },
    selectedOrdertype () {
      return this.$store.getters.getDataFromConfigWithFilter('order_cargo_types', 'id', this.orderCargoTypeId);
    },
    isTransfer () {
      return this.selectedOrdertype && this.selectedOrdertype.code == 'TNF';
    },
    canEditApussCLass () {
      return true
    },
    tableHeaders () {
      const headers = [
        { key: 'product_type_id', label: this.$t(this.baseTransParam + '.product_type_id'), type: 'slot', slotcode: 'product_type' },
        { key: 'product_id', label: this.$t(this.baseTransParam + '.materialLv'), type: 'slot', slotcode: 'product' },
        { key: 'productNameEn', label: this.$t(this.baseTransParam + '.materialEn'), type: 'slot', slotcode: 'productNameEn' }
      ];
      if (!this.isIncoming) {
        if (!this.isTransfer) {
          headers.push({ key: 'min_quality_id', label: this.$t(this.baseTransParam + '.min_quality_id'), type: 'slot', slotcode: 'minquality' });
          headers.push({ key: 'maxPieces', label: this.$t(this.baseTransParam + '.maxPieces'), type: 'slot', slotcode: 'number' });
          headers.push({ key: 'maxWeight', label: this.$t(this.baseTransParam + '.maxWeight'), type: 'slot', slotcode: 'number' });
        }
        headers.push({ key: 'quantity', label: this.$t(this.baseTransParam + '.quantity'), type: 'slot', slotcode: 'quantity' });
        headers.push({ key: 'measure_type_id', label: this.$t(this.baseTransParam + '.measure_type_id'), type: 'slot', slotcode: 'measure_type' });
        if (!this.isTransfer) {
          headers.push({ key: 'allowBroken', label: this.$t(this.baseTransParam + '.allowBroken'), type: 'slot', slotcode: 'allowBroken' });
          headers.push({ key: 'customsCode', label: this.$t(this.baseTransParam + '.customsCode'), type: 'slot', slotcode: 'customsCode' });
        }
      } else if (this.isIncoming) {
        headers.push({ key: 'purchased_in_bags', label: this.$t(this.baseTransParam + '.purchased_in_bags'), type: 'slot', slotcode: 'purchased_in_bags' });
        headers.push({ key: 'quality_id', label: this.$t(this.baseTransParam + '.quality_id'), type: 'slot', slotcode: 'quality_id' });
        headers.push({ key: 'quantity', label: this.$t(this.baseTransParam + '.quantity'), type: 'slot', slotcode: 'quantity' });
        headers.push({ key: 'measure_type_id', label: this.$t(this.baseTransParam + '.measure_type_id'), type: 'slot', slotcode: 'measure_type' });
      }
      headers.push({ key: 'apus_waste_class', label: this.$t(this.baseTransParam + '.apus_waste_class'), type: 'slot', slotcode: 'apus_waste_class' });
      headers.push({ key: 'actualPieces', label: this.$t(this.baseTransParam + '.actualPieces'), type: 'slot', slotcode: 'number' });
      headers.push({ key: 'actualQuantity', label: this.$t(this.baseTransParam + '.actualQuantity'), type: 'slot', slotcode: 'number' });
      headers.push({ key: 'residualWeight', label: this.$t(this.baseTransParam + '.residualWeight'), type: 'slot', slotcode: 'residualWeight' })
      if (!this.isTransfer) {
        headers.push({ key: 'rate', label: this.$t(this.baseTransParam + '.rate', { currency: PetHelper.getCurrencyNameFromCode(this.currencyCode) }), type: 'slot', slotcode: 'rate' });
        headers.push({ key: 'individual_rate', label: this.$t(this.baseTransParam + '.individual_rate', { currency: PetHelper.getCurrencyNameFromCode(this.currencyCode) }), type: 'slot', slotcode: 'individual_rate' });
        headers.push({ key: 'plannedAmount', label: this.$t(this.baseTransParam + '.plannedAmount', { currency: PetHelper.getCurrencyNameFromCode(this.currencyCode) }), type: 'slot', slotcode: 'plannedAmount' });
      }
      if (this.isTransfer || this.isExport) {
        headers.push({ key: 'saleInBags', label: this.$t('order.saleInBags'), type: 'slot', slotcode: 'saleInBags' });
      }
      if (this.isIncoming) {
        headers.push({ key: 'originCountry', label: this.$t('purchase-order.originCountry'), type: 'slot', slotcode: 'originCountry' });
      }
      const actions = [];
      if (this.canEdit) {
        actions.push({ label: this.$t('general.actions.delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-sm btn-danger' });
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    canEdit () {
      return !this.readonly;
    },
    productTypeOptions () {
      return this.$store.getters.getConfigOptions(
        'product_types',
        this.$i18n.locale,
        'id',
        this.activeProductTypeIds,
        false,
        false,
        true
      );
    },
    allProductTypeOptions () {
      return this.$store.getters.getConfigOptions(
        'product_types',
        this.$i18n.locale
      );
    },
    activeProductTypeIds () {
      return this.productOptions.map(el => el.type_id);
    },
    productOptions () {
      return this.$store.getters.getConfigOptions(
        'products',
        'lv',
        null,
        null,
        false,
        false,
        false,
        ['isBought', 'isSeparated', 'isFinalProduct', 'isBagPacked', 'inAxapta', 'type_id', 'default_measure_type_id', 'availableMeasureTypes', 'availableApusWasteClasses', 'apus_waste_class_id']
      ).filter(el => {
        let res = (el.inAxapta && (!this.isIncoming || el.isBought || el.isChemical));
        if (res && !this.isTransfer) {
          if (this.selectedOrdertype != null && this.selectedOrdertype != undefined && this.selectedOrdertype.code == 'IMP-R' &&
              this.selectedOrdertype.config instanceof Array && this.selectedOrdertype.config.length > 0) {
            const prodType = this.allProductTypeOptions.find(el2 => el2.value == el.type_id)
            if (!this.selectedOrdertype.config.includes(prodType.code)) {
              res = false
            }
          } else {
            const custMat = this.usedProducts.find(subEl => subEl.product_id == el.value);
            if (!custMat) {
              res = false;
            }
          }
        }
        return res;
      });
    },
    availableProductIds () {
      const ids = [];
      this.productOptions.forEach(el => {
        if (!this.products.find(subEl => subEl.product_id == el.value)) {
          ids.push(el.value);
        }
      })
      return ids;
    },
    qualityOptions () {
      return this.$store.getters.getConfigOptions('product_qualities', this.$i18n.locale);
    }
  },
  methods: {
    getQualityBadgeColors (qualityId) {
      return this.$store.getters.getQualityBadgeColors(qualityId);
    },
    getProductOptions (selectedProductId) {
      const qualityOptionsLength = this.qualityOptions.length;
      const usedProductsAndQualityMap = {};
      this.products.forEach(line => {
        if (!usedProductsAndQualityMap[line.product_id]) {
          usedProductsAndQualityMap[line.product_id] = {};
        }
        usedProductsAndQualityMap[line.product_id][line.quality_id || 0] = 1;
      })
      return this.productOptions.filter(product => {
        if (selectedProductId === product.value) {
          return true;
        }
        if (usedProductsAndQualityMap[product.value]) {
          if (usedProductsAndQualityMap[product.value][0]) {
            return false;
          }
          if (Object.values(usedProductsAndQualityMap[product.value]).length === qualityOptionsLength) {
            return false;
          }
        }
        return true;
      });
    },
    getQualityOptions (selectedProductId, selectedQualityId) {
      const usedProductsAndQualityMap = {};
      this.products.forEach(line => {
        if (!usedProductsAndQualityMap[line.product_id]) {
          usedProductsAndQualityMap[line.product_id] = {};
        }
        usedProductsAndQualityMap[line.product_id][line.quality_id || 0] = 1;
      })
      return this.qualityOptions.filter(qualityOption => {
        if (!usedProductsAndQualityMap[selectedProductId]) {
          return true;
        }
        if (qualityOption.value === selectedQualityId) {
          return true;
        }
        return !usedProductsAndQualityMap[selectedProductId][qualityOption.value];
      });
    },
    getAvailableApusOptions (productId) {
      const prod = this.productOptions.find(el => el.value == productId)
      if (prod != undefined) {
        return this.apusWasteOptions.filter(el => prod.availableApusWasteClasses.includes(el.value))
      }
      return []
    },
    checkOriginCountryCanEdit (orderProduct) {
      if (orderProduct.id == null || (orderProduct.originCountry == null || orderProduct.originCountry == '')) {
        return true;
      }
      if (this.d365lines != null && this.d365lines.length > 0) {
        const existingProductInBatch = this.d365lines.find(el => el.product_id == orderProduct.product_id && el.batch_id != null)
        if (existingProductInBatch != undefined && existingProductInBatch != null) {
          return false
        }
      }
      return true
    },
    checkPurchaseInBagsCanEdit (orderProduct) {
      if (orderProduct.id == null) {
        return true;
      }
      if (this.d365lines != null && this.d365lines.length > 0) {
        const existingProductInBatch = this.d365lines.find(el => el.product_id == orderProduct.product_id && el.batch_id != null)
        if (existingProductInBatch != undefined && existingProductInBatch != null) {
          return false
        }
      }
      if (!orderProduct.purchased_in_bags && orderProduct.product && !orderProduct.product.isUnitCount) {
        return false;
      }
      return true
    },
    customsCode (data) {
      let customsCode = '-';
      if (data.item.product && data.item.product.customsCode) {
        customsCode = data.item.product.customsCode;
      }
      return customsCode;
    },
    plannedAmount (data) {
      let plannedAmount = null;
      let rate = null;
      let individualRate = null;
      if (!this.isTransfer) {
        if (data.item.individual_rate !== null && data.item.individual_rate !== '') {
          individualRate = data.item.individual_rate;
        }
        if (!this.isIncoming) {
          rate = individualRate ?? data.item.customerSaleRate ?? data.item.defaultSaleRate;
        } else {
          rate = individualRate ?? data.item.vendorBuyRate ?? data.item.defaultBuyRate;
        }
      }
      if (data.item.quantity && rate) {
        plannedAmount = data.item.quantity * rate;
      }
      return plannedAmount;
    },
    residualWeight (data) {
      let residualWeight = '-';
      if (data.item.quantity || data.item.actualQuantity) {
        residualWeight = (data.item.actualQuantity ?? 0) - (data.item.quantity ?? 0);
      }
      return residualWeight;
    },
    apusWasteClass (data) {
      let apusWasteClass = '-';
      const code = PetHelper.getProductConfigName(data.item.product_id, 'apus_waste_class_id', 'code', false);
      const name = PetHelper.getProductConfigName(data.item.product_id, 'apus_waste_class_id', 'name', true);
      if (code && name) {
        apusWasteClass = code + ' (' + name + ')';
      }
      return apusWasteClass;
    },
    getTableData () {
      if (this.orderId) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.loading = true;
        this.currentReq = this.ApiRequest.request(this.basePathComp, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.products = response.data.products;
            this.loading = false;
          }
        });
      }
    },
    addLine () {
      const el = this.BasicHelper.cloneObject(defaultLine);
      if (this.partner_address != null) {
        el.originCountry = this.partner_address
      }
      this.products.push(el);
      this.$refs.tableview.$forceUpdate();
    },
    deleteLine (el) {
      el.deleted = 1;
      const idx = this.products.findIndex(el => el.deleted == 1);
      this.products.splice(idx, 1);
      this.$refs.tableview.$forceUpdate();
    },
    productChanged (newValue, index) {
      this.attachedRelatedConfig(newValue, index);
      this.$emit('productChanged');
    },
    handleProductSelect (product, oldValue, index) {
      // Handled in `@select` as it is triggered before `@input` and `newValue` / `oldValue` can be obtained
      const newValue = product?.value;
      if (newValue !== oldValue) {
        let qualityId = null;
        if (newValue && this.usedProducts) {
          const partnerProduct = this.usedProducts.find(product => product.product_id === newValue);
          if (partnerProduct) {
            let isDefaultQualityAlreadyUsed = false;
            this.products.forEach(line => {
              if (line.product_id === newValue && line.quality_id === partnerProduct.quality_id) {
                isDefaultQualityAlreadyUsed = true;
              }
            })
            if (!isDefaultQualityAlreadyUsed) {
              qualityId = partnerProduct.quality_id;
            }
          }
        }
        this.$set(this.products[index], 'quality_id', qualityId);
      }
    },
    attachedRelatedConfig (productId, index) {
      const match = this.usedProducts?.find(el => el.product_id == productId);
      const prod = this.productOptions.find(el => el.value == productId);
      const params = [
        'customerSaleRate', 'defaultSaleRate', 'vendorBuyRate', 'defaultBuyRate',
        'maxPieces', 'maxWeight', 'maxWeight', 'allowBroken',
        'contractNo'
      ];

      this.products[index].purchased_in_bags = prod.isBagPacked;

      if (match) {
        params.forEach(param => {
          this.products[index][param] = match[param];
        });
        this.products[index].min_quality_id = match.quality_id;
        this.products[index].apus_waste_class_id = prod != undefined ? prod.apus_waste_class_id : null;
      } else {
        params.forEach(param => {
          this.products[index][param] = null;
        });
        this.products[index].min_quality_id = null;
        this.products[index].apus_waste_class_id = null;
      }
      if ((this.isExport || this.isTransfer) && this.products[index].id == null) {
        const product = this.productOptions.find(el => el.value == productId)
        if (product != undefined && product.isFinalProduct) {
          this.products[index].saleInBags = true
        }
      }
      this.$refs.tableview.$forceUpdate();
    },
    getProductQualityCodesText (ids) {
      let text = '';
      ids.forEach(id => {
        const name = this.BasicHelper.getConfigValue('product_qualities_code', 'id', id, 'name');
        text += (text ? `, ${name}` : name)
      })
      if (text) {
        text = `(${text})`;
      }
      return text;
    }
  },
  mounted () {
    if (this.orderproducts == null) {
      this.getTableData();
    }
    this.$store.dispatch('loadDynamicConfig', { type: 'Countries' });
  },
  watch: {
    orderproducts: {
      immediate: true,
      handler (val) {
        if (val !== null) {
          this.products = val;
        }
      }
    },
    orderId: {
      immediate: true,
      handler (val) {
        if (this.orderproducts == null) {
          this.getTableData()
        }
      }
    }
  }
}
