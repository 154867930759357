import ApiRequest from 'ebg-vue-common/src/helpers/ApiRequest.js'
import BasicHelper from 'ebg-vue-common/src/helpers/BasicHelper.js'
import i18n from '@/i18n';

export default {
  state: {
    currentShift: JSON.parse(localStorage.getItem('currentShift')),
    currentFlowId: parseInt(localStorage.getItem('currentFlowId')),
    warehouseShift: JSON.parse(localStorage.getItem('warehouseShift')),
    warehouseFlowId: parseInt(localStorage.getItem('warehouseFlowId')),
    technologicalStages: JSON.parse(localStorage.getItem('technologicalStages')),
    availableDowntimes: JSON.parse(localStorage.getItem('availableDowntimes')),
    currentQRDeviceId: parseInt(localStorage.getItem('currentQRDeviceId')),
    currentRfidDeviceId: parseInt(localStorage.getItem('currentRfidDeviceId')),
    visualOverviewLastTab: localStorage.getItem('visualOverviewLastTab'),
    testerSelectedSubmenu: localStorage.getItem('testerSelectedSubmenu'),
    defaultSiteId: localStorage.getItem('defaultSiteId'),
    dynconfigCountries: null,
    availableSites: JSON.parse(localStorage.getItem('availableSites'))
  },
  getters: {
    userShiftFilters: (state, getters, rootState) => {
      const filters = { prev: 0, next: 0, mustBeInShift: true, allowDatepicker: false };
      if (rootState.user && rootState.config.roles) {
        Object.values(rootState.config.roles).forEach(el => {
          if (rootState.user.roles.includes(el.id)) {
            if (!el.shiftRole) {
              filters.mustBeInShift = false;
            }
            if (el.prevShifts === null || el.prevShifts > filters.prev) {
              filters.prev = el.nextShifts
            }
            if (el.nextShifts === null || el.nextShifts > filters.next) {
              filters.next = el.nextShifts
            }
          }
        });
      }
      filters.allowDatepicker = (filters.prev == null || filters.next == null);
      filters.prev = (filters.prev !== null ? (new Date().getTime() - (2 * 1000 * 12 * 60 * 60 * filters.prev)) / 1000 : null);
      filters.next = (filters.next !== null ? (new Date().getTime() + (2 * 1000 * 12 * 60 * 60 * filters.next)) / 1000 : null);
      return filters;
    },
    userWarehouseShiftFilters: (state, getters, rootState) => {
      const filters = { prev: 0, next: 0 };

      if (rootState.user && rootState.config.roles) {
        Object.values(rootState.config.roles).forEach(el => {
          if (rootState.user.roles.includes(el.id)) {
            if (el.prevWarehouseShifts === null || el.prevWarehouseShifts > filters.prev) {
              filters.prev = el.prevWarehouseShifts
            }
            if (el.nextWarehouseShifts === null || el.nextWarehouseShifts > filters.next) {
              filters.next = el.nextWarehouseShifts
            }
          }
        });
      }

      return filters;
    },
    userVisibleShifts: (state, getters, rootState) => {
      const filters = getters.userShiftFilters;
      if (rootState.config && rootState.user && rootState.config.activeshifts && rootState.user.id) {
        return Object.values(rootState.config.activeshifts).filter(el => {
          return (!filters.mustBeInShift || el.userroles[rootState.user.id]) &&
          (filters.prev == null || filters.prev <= el.startAtTimestamp) &&
          (filters.next == null || filters.next >= el.startAtTimestamp);
        });
      }
      return [];
    },
    canProduceProduct: (state, getters, rootState) => (id) => {
      if (rootState.config.products && rootState.config.products[id]) {
        return (rootState.config.products[id].isSeparated || rootState.config.products[id].isFinalProduct);
      }
      return false;
    },
    canFeedProduct: (state, getters, rootState) => (id) => {
      return true;
    },
    isChemicalProduct: (state, getters, rootState) => (id) => {
      if (rootState.config.products && rootState.config.products[id]) {
        return (rootState.config.products[id].isChemical);
      }
      return false;
    },
    shiftCompletionItems: () => {
      return {
        warehouseApprovedAt: 'bi-house-door-fill',
        qualityCompletedAt: 'bi-bookmark-star-fill',
        fullyWeighedAt: 'bi-ticket-fill',
        journalsCreatedAt: 'bi-journal-text'
      }
    },
    getDevicesByType: (state, getters, rootState) => (typeCode = null, deviceFilters = {}) => {
      let res = []
      if (rootState.config && rootState.config.devices) {
        if (typeCode) {
          deviceFilters.type_id = getters.getParamFromConfigByFilter('device_types', 'code', typeCode, 'id');
        }
        res = BasicHelper.sortArrayByKeyValue(Object.values(rootState.config.devices).filter(el => {
          let isValid = true;
          if (el.deleted) {
            isValid = false;
          }
          Object.keys(deviceFilters).forEach(filterKey => {
            if (Array.isArray(deviceFilters[filterKey])) {
              isValid = isValid && deviceFilters[filterKey].includes(el[filterKey]);
            } else {
              isValid = isValid && (el[filterKey] == deviceFilters[filterKey]);
            }
          });
          return isValid;
        }), 'order');
      }
      return res;
    },
    getMatchingProductsWithStages: (state, getters, rootState) => (lineId = null, stageFilters = {}, prodFilters = {}, productIds = null) => {
      const res = {}
      if (rootState.config && rootState.config.line_flows && (lineId == null || rootState.config.lines[lineId])) {
        Object.values(rootState.config.line_flows).forEach(flow => {
          if (lineId == null || flow.line_id == lineId) {
            Object.values(flow.stages).forEach(stage => {
              stage.products.forEach(el => {
                let subres = true;
                if (Array.isArray(productIds) && !productIds.includes(el.product_id)) {
                  subres = false
                }
                Object.keys(stageFilters).forEach(filterKey => {
                  subres = subres && (el[filterKey] == stageFilters[filterKey]);
                });
                const product = rootState.config.products[el.product_id];
                if (subres && product) {
                  Object.keys(prodFilters).forEach(filterKey => {
                    subres = subres && (product[filterKey] == prodFilters[filterKey]);
                  });
                }
                if (subres && product) {
                  if (!res[flow.id]) {
                    res[flow.id] = { stages: {}, products: {}, line_id: flow.line_id };
                  }
                  if (!res[flow.id].stages[stage.id]) {
                    res[flow.id].stages[stage.id] = {
                      id: stage.id,
                      text: stage.name[i18n.locale],
                      type_id: stage.type_id,
                      device_ids: [],
                      workstation_ids: stage.workstation_ids,
                      lineId: stage.line_id
                    }
                  }
                  if (flow.availableDevices[stage.id]) {
                    flow.availableDevices[stage.id].forEach(devId => {
                      if (!res[flow.id].stages[stage.id].device_ids.includes(devId)) {
                        res[flow.id].stages[stage.id].device_ids.push(devId);
                      }
                    });
                  }
                  if (!res[flow.id].products[product.id]) {
                    res[flow.id].products[product.id] = BasicHelper.cloneObject(product);
                    res[flow.id].products[product.id].text = product.name[i18n.locale];
                    res[flow.id].products[product.id].stages = {};
                  }
                  res[flow.id].products[el.product_id].stages[stage.id] = {
                    bruttoDelta: el.bruttoDelta,
                    feedNotAssembly: el.feedNotAssembly,
                    produceEmptyWeight: el.produceEmptyWeight,
                    produceuses: el.produceuses,
                    yieldrate: el.yieldrate
                  }
                }
              });
            });
          }
        });
      }
      Object.keys(res).forEach(flowId => {
        res[flowId].stages = Object.values(res[flowId].stages);
      });
      return res;
    },
    isBoughtProduct: (state, getters, rootState) => (id) => {
      if (rootState.config.products && rootState.config.products[id]) {
        return rootState.config.products[id].isBought;
      }
      return false;
    },
    isSeparatedProduct: (state, getters, rootState) => (id) => {
      if (rootState.config.products && rootState.config.products[id]) {
        return rootState.config.products[id].isSeparated;
      }
      return false;
    },
    isFinalProduct: (state, getters, rootState) => (id) => {
      if (rootState.config.products && rootState.config.products[id]) {
        return rootState.config.products[id].isFinalProduct;
      }
      return false;
    },
    isGroupNameProduct: (state, getters, rootState) => (id, groupname) => {
      if (rootState.config.products && rootState.config.products[id]) {
        return rootState.config.products[id].groupname == groupname;
      }
      return false;
    },
    getWarehouseWorkstationIds: (state, getters, rootState) => (warehouseId) => {
      const res = [];
      if (rootState.config.warehouses) {
        if (warehouseId) {
          return (rootState.config.warehouses[warehouseId] ? rootState.config.warehouses[warehouseId].workstation_ids : []);
        }
        Object.values(rootState.config.warehouses).forEach(warehouse => {
          warehouse.workstation_ids.forEach(workstId => {
            if (res.indexOf(workstId) < 0) {
              res.push(workstId);
            }
          });
        });
      }
      return res;
    },
    inheritMeasurementValueFromShift: (state, getters, rootState) => (measurement, shiftQualityMeasurements) => {
      if (typeof measurement === 'object' && measurement.inheritShiftValue != undefined && measurement.inheritShiftValue) {
        measurement.value = '';
        if (shiftQualityMeasurements !== null && shiftQualityMeasurements.length > 0) {
          let numberOfValudEl = 0;
          let totalValue = 0;
          shiftQualityMeasurements.forEach(shiftQualityMeasurementElement => {
            const findShiftElement = shiftQualityMeasurementElement.measurementDataSet.find(el =>
              el.quality_id == measurement.quality_measurement_id)
            if (findShiftElement != undefined && findShiftElement !== null && !isNaN(findShiftElement.value) && findShiftElement.value !== null) {
              totalValue += parseFloat(findShiftElement.value)
              numberOfValudEl++;
            }
          })
          if (numberOfValudEl > 0) {
            measurement.value = totalValue / numberOfValudEl;
          }
        }
      }
    },
    updateCargoTransportCosts: (state, getters, rootState) => (cargo, deliveryType) => {
      const defaultCostEl = {
        id: null,
        chargeCode_id: null,
        plannedCost: null,
        actualCost: null,
        generatedCost: null,
        additionalCost: null,
        totalActualCost: null,
        D365CostNumber: null,
        cargo_id: null,
        order_id: null
      }
      const availbleCostTypeSections = deliveryType.availbleCostTypeSections
      const availbleCostTypeIds = deliveryType.availbleCostTypeSections.map(el => el.order_cost_type_id)
      if (cargo.transport_costs != null && cargo.transport_costs.length > 0) {
        cargo.transport_costs.forEach(function callback (value, index) {
          if ((value.plannedCost == null || value.plannedCost == '') && (!availbleCostTypeIds.includes(value.chargeCode_id) || value.id == null)) {
            cargo.transport_costs.splice(index, 1)
          }
        });
      }
      if (availbleCostTypeSections.length > 0) {
        availbleCostTypeSections.forEach(availableCostType => {
          const existingTransportCost = cargo.transport_costs.find(transportCost => transportCost.chargeCode_id == availableCostType.order_cost_type_id)
          if (existingTransportCost == undefined) {
            const el = BasicHelper.cloneObject(defaultCostEl);
            el.cargo_id = cargo.id
            el.chargeCode_id = availableCostType.order_cost_type_id
            el.order_id = cargo.order_id
            cargo.transport_costs.push(el)
          }
        })
      }
      return cargo.transport_costs
    },
    getAvailableWarehouseOptions: (state, getters, rootState) => (
      locale = null,
      filterKey = null,
      filterValue = null,
      extraParams = []
    ) => {
      return getters.getConfigOptions('warehouses', locale, filterKey, filterValue, false, false, true);
      // turn off for now
      // const options = getters.getConfigOptions(
      //   'warehouses',
      //   locale,
      //   filterKey,
      //   filterValue,
      //   false,
      //   false,
      //   true,
      //   ['site_id', ...extraParams]
      // ).filter(el => state.availableSites.includes(el.site_id));
      // return options;
    },
    getAvailableLineOptions: (state, getters, rootState) => (
      locale = null,
      filterKey = null,
      filterValue = null,
      extraParams = [],
      inValueFilter
    ) => {
      return getters.getConfigOptions('lines', locale);
      // turn off for now
      // const options = getters.getConfigOptions(
      //   'lines',
      //   locale,
      //   filterKey,
      //   filterValue,
      //   false,
      //   false,
      //   inValueFilter,
      //   ['site_id', ...extraParams]
      // ).filter(el => state.availableSites.includes(el.site_id));
      // return options;
    },
    getDeliveryTypeOptions: (state, getters, rootState) => (locale, orderTypeId = null, deliveryTypeId = null) => {
      const options = getters.getConfigOptions(
        'delivery_types',
        locale,
        null,
        null,
        false,
        false,
        false,
        ['deliveryTypeCargoTypes', 'costTypeSections']
      );
      options.forEach(el => {
        el.description = '';
        el.availbleCostTypeSections = []
        if (el.deliveryTypeCargoTypes && el.deliveryTypeCargoTypes[orderTypeId]) {
          const additionalText = BasicHelper.getTranslation(el.deliveryTypeCargoTypes[orderTypeId].description);
          if (typeof additionalText === 'string') {
            el.description = additionalText;
            el.text += ' (' + additionalText + ')';
          }
          if (deliveryTypeId != null && el.value == deliveryTypeId) {
            el.availbleCostTypeSections = el.costTypeSections.filter(allCostTypeSection => allCostTypeSection.order_cargo_type_id == orderTypeId)
          }
        }
      });
      return options
    },
    getQualityBadgeColors: (state, getters, rootState) => (qualityId) => {
      const bgColor = getters.getParamFromConfigByFilter('product_qualities', 'id', qualityId, 'color');
      return {
        bgColor: bgColor,
        textColor: bgColor ? BasicHelper.getContrastColor(bgColor) : '#000'
      }
    }
  },
  actions: {
    validateCurrentShift ({ commit, dispatch, rootState, getters, state }, payload = {}) {
      if (rootState.config && rootState.user) {
        const currentSiteId = (payload && payload.defaultSiteId) ? payload.defaultSiteId : rootState.defaultSiteId;
        let siteChanged = false;
        if (state.currentShift) {
          const lineMatch = Object.values(rootState.config.lines).find(el => el.id == state.currentShift.line_id);
          if (!lineMatch || lineMatch.site_id != currentSiteId) {
            siteChanged = true;
          }
        }
        const filters = getters.userShiftFilters;
        if (filters.mustBeInShift || !state.currentShift || siteChanged) {
          let newShiftEl = null;
          const shiftPool = (filters.mustBeInShift ? getters.userVisibleShifts : rootState.config.activeshifts);
          if (shiftPool && Object.values(shiftPool).length > 0) {
            const matchShifts = Object.values(shiftPool).filter(el => el.matchesCurrentTime);
            const lineOptions = Object.values(rootState.config.lines).filter(
              el =>
                el.deleted == false &&
                el.site_id == currentSiteId
            ).sort((a, b) => a.order - b.order);

            if (matchShifts.length > 0) {
              for (const index in lineOptions) {
                newShiftEl = matchShifts.find(el => el.line_id == lineOptions[index].id);
                if (newShiftEl) {
                  break;
                }
              }
            }
            if (!newShiftEl) {
              for (const index in lineOptions) {
                newShiftEl = Object.values(shiftPool).find(el => el.line_id == lineOptions[index].id);
                if (newShiftEl) {
                  break;
                }
              }
            }
          }

          if (newShiftEl) {
            dispatch('changeShift', { filters: { currentShift: { id: newShiftEl.id } } });
          }
        }
      }
    },
    reloadShiftData ({ commit, dispatch, rootState, getters, state }, payload = {}) {
      if (rootState.user) {
        const ids = {};
        let shiftParamKeys = payload.shiftParamKeys;
        if (!shiftParamKeys) {
          shiftParamKeys = ['warehouseShift', 'currentShift'];
        }
        shiftParamKeys.forEach(paramKey => {
          if (rootState.pet[paramKey] && rootState.pet[paramKey].id) {
            ids[paramKey] = { id: rootState.pet[paramKey].id }
          }
        });
        dispatch('changeShift', { filters: ids, isOnlyCheck: true });
      }
    },
    changeShift ({ commit, dispatch, rootState }, payload = {}) {
      return new Promise((resolve, reject) => {
        ApiRequest.request('shift/find-multi', ApiRequest.REQUEST_POST, payload, function (response) {
          if (response.error) {
            const errorMessage = ApiRequest.getApiResponseErrorMessage(response, {})
            if (errorMessage != 'error.usertoken') {
              commit('toastAdd', {
                context: 'danger',
                message: errorMessage
              })
            } else {
              console.log(errorMessage)
            }
          } else if (response.data) {
            const changedParams = {};
            Object.keys(response.data).forEach(paramKey => {
              if (!payload.isOnlyCheck || !rootState.pet[paramKey] || rootState.pet[paramKey].id == response.data[paramKey].id) {
                localStorage.setItem(paramKey, JSON.stringify(response.data[paramKey]));
                const shiftData = response.data[paramKey];
                const matchFlows = Object.values(rootState.config.line_flows).filter(el => el.line_id == shiftData.line_id);
                let flowId = ((paramKey == 'warehouseShift') ? rootState.pet.warehouseFlowId : rootState.pet.currentFlowId);
                if (!matchFlows.find(el => el.id == flowId)) {
                  flowId = matchFlows[0] ? matchFlows[0].id : null;
                }
                if (paramKey == 'warehouseShift') {
                  changedParams.warehouseShift = shiftData;
                  if (rootState.pet.warehouseFlowId != flowId) {
                    localStorage.setItem('warehouseFlowId', flowId);
                    changedParams.warehouseFlowId = flowId;
                  }
                } else if (paramKey == 'currentShift') {
                  changedParams.currentShift = shiftData;
                  if (rootState.pet.currentFlowId != flowId) {
                    localStorage.setItem('currentFlowId', flowId);
                    changedParams.currentFlowId = flowId;
                  }
                }
              } else {
                console.log('skip overwrite shift data');
              }
            });
            commit('STATE_CHANGES', changedParams);
            resolve(true);
          } else if (!payload.isOnlyCheck) {
            commit('toastAdd', {
              context: 'warning',
              message: i18n.t('shift.not-found')
            })
            resolve(false);
          }
        });
      });
    },
    changeVisualOverviewLastTab ({ commit, dispatch, rootState }, visualOverviewLastTab) {
      localStorage.setItem('visualOverviewLastTab', visualOverviewLastTab);
      commit('STATE_CHANGES', { visualOverviewLastTab: visualOverviewLastTab });
    },
    changeStateValues ({ commit }, payload = {}) {
      Object.keys(payload).forEach(key => {
        localStorage.setItem(key, payload[key]);
      });
      commit('STATE_CHANGES', payload);
    },
    getTechnologicalStages ({ commit, dispatch, rootState }, payload = {}) {
      return new Promise((resolve, reject) => {
        ApiRequest.request('downtimes/technologicalstages', ApiRequest.REQUEST_GET, payload, function (response) {
          if (response.error) {
            commit('toastAdd', {
              context: 'danger',
              message: ApiRequest.getApiResponseErrorMessage(response, {})
            })
          } else if (response.data) {
            const result = [];
            response.data.forEach((item) => {
              const name = item && item.name && item.name[i18n.locale] ? item.name[i18n.locale] : '';
              result.push({
                value: item.id,
                text: name
              });
            });
            localStorage.setItem('technologicalStages', JSON.stringify(result));
            commit('STATE_CHANGES', { technologicalStages: result });
            resolve(true);
          } else {
            commit('toastAdd', {
              context: 'warning',
              message: i18n.t('downtimes.technologicalstages-not-found')
            })
            resolve(false);
          }
        });
      });
    },
    getAvailableDowntimes ({ commit, dispatch, rootState }, payload = {}) {
      return new Promise((resolve, reject) => {
        ApiRequest.request('downtimes/availabledowntimes', ApiRequest.REQUEST_GET, payload, function (response) {
          if (response.error) {
            commit('toastAdd', {
              context: 'danger',
              message: ApiRequest.getApiResponseErrorMessage(response, {})
            })
          } else if (response.data) {
            localStorage.setItem('availableDowntimes', JSON.stringify(response.data));
            commit('STATE_CHANGES', { availableDowntimes: response.data });
            resolve(true);
          } else {
            commit('toastAdd', {
              context: 'warning',
              message: i18n.t('downtimes.availabledowntimes-not-found')
            })
            resolve(false);
          }
        });
      });
    }
  },
  mutations: {
    STATE_CHANGES (state, newValues) {
      Object.keys(newValues).forEach(el => {
        state[el] = newValues[el];
      });
    },
    CONFIG_CHANGED (state) {
      // console.log(this.currentshift);
      // this.currentshift = Object.values(state.config.shifts)[0];
      // console.log('changed');
    },
    USER_LOGGED_IN (state, payload) {
      if (payload.user.default_site_id) {
        localStorage.setItem('defaultSiteId', payload.user.default_site_id);
        state.defaultSiteId = payload.user.default_site_id;
      }
    },
    SESSION_UPDATE (state, payload) {
      if (payload && payload.user) {
        if (payload.user.visible_sites) {
          localStorage.setItem('availableSites', JSON.stringify(payload.user.visible_sites));
          state.availableSites = payload.user.visible_sites;
        } else {
          localStorage.setItem('availableSites', '[]');
          state.availableSites = []
        }
      }
    },
    SESSION_CLEAR (state) {
      const keys = [
        'currentShift',
        'currentShift',
        'warehouseShift',
        'warehouseFlowId',
        'technologicalStages',
        'availableDowntimes',
        'visualOverviewLastTab',
        'defaultSiteId',
        'availableSites'
      ];
      keys.forEach(key => {
        localStorage.setItem(key, null);
        state[key] = null;
      });
    }
  }
}
