import { QrcodeStream } from 'vue-qrcode-reader';

export default {
  name: 'QRScanner',
  props: {},
  data () {
    return {
      camera: 'auto',
      result: null,
      capabilities: null
    };
  },
  components: {
    QrcodeStream
  },
  computed: {
  },
  methods: {
    logErrors: console.error,

    async onInit (promise) {
      this.$emit('qrCameraStatus', 'btn-warning');
      try {
        this.capabilities = await promise
      } catch (error) {
        console.error(error)
      } finally {
        this.$emit('qrCameraStatus', 'btn-primary');
      }
    },

    async onDecode (content) {
      this.$emit('qrCameraStatus', 'btn-secondary');
      this.$emit('read', content);
      this.turnCameraOff();
    },

    turnCameraOff () {
      this.camera = 'off'
    }

  }
}
