import CargoScheduleEdit from '@/components/pet/sales/cargo/schduleedit/CargoScheduleEdit.vue'
import CargoLogisticsCalendar from '@/components/pet/sales/logistics/view/CargoLogisticsCalendar.vue';
import CargoLogisticsCard from '@/components/pet/sales/logistics/card/CargoLogisticsCard.vue';
import { Draggable } from '@fullcalendar/interaction';
import moment from 'moment';

const defaultIncomingFilters = {
  deliveryDate: {
    start: null,
    end: null
  },
  ordercargotype: ['IMP'],
  simple: false,
  stageids: []
}

const defaultSchedulingFilters = {
  productTypes: [],
  partner: null,
  ordercargotype: ['IMP']
}

export default {
  name: 'CargoLogisticsView',
  components: {
    CargoLogisticsCalendar,
    CargoScheduleEdit,
    CargoLogisticsCard
  },
  data () {
    return {
      draggable: null,
      mounted: false,
      incoming: {
        loading: false,
        currentReq: null,
        items: [],
        filterValues: defaultIncomingFilters
      },
      scheduling: {
        loading: false,
        currentReq: null,
        items: [],
        filterValues: defaultSchedulingFilters,
        paging: {
          pages: null,
          page: 1,
          limit: 11
        }
      }
    };
  },
  computed: {
    cargoOrderTypeOptions () {
      return this.$store.getters.getConfigOptions('order_cargo_types', this.$i18n.locale);
    },
    productTypeOptions () {
      return this.$store.getters.getConfigOptions('product_types', this.$i18n.locale);
    },
    rampOptions () {
      const options = this.$store.getters.getConfigOptions(
        'warehouses_ramps',
        this.$i18n.locale,
        null,
        null,
        false,
        false,
        false,
        ['id', 'site_id', 'order']
      );
      options.unshift({ id: 'NULL', text: this.$t('general.fullcalendar.noresource'), order: 0 });
      return options.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
    },
    selectedRampOptions () {
      if (this.incoming.filterValues.stageids && this.incoming.filterValues.stageids.length) {
        return this.rampOptions.filter(el => {
          let match = false;
          this.incoming.filterValues.stageids.forEach(stageId => {
            if (el.id == stageId) {
              match = true;
            }
          });
          return match;
        });
      }
      return this.rampOptions;
    }
  },
  methods: {
    editSchedule (event) {
      this.$refs.cargoscheduleedit.show(
        event.cargoCode,
        2,
        (event.scheduledStartAt ?? null),
        event.date ?? null,
        false,
        (event.stage_id ? event.stage_id : undefined)
      );
    },
    statusName (id) {
      return this.$store.getters.getParamFromConfig('cargo_statuses', id, 'name', this.$i18n.locale);
    },
    statusColor (id) {
      return this.$store.getters.getParamFromConfig('cargo_statuses', id, 'color');
    },
    statusTextColor (id) {
      return this.BasicHelper.getContrastColor(this.statusColor(id));
    },
    dateChanged (date) {
      if (date.start) {
        this.incoming.filterValues.deliveryDate.start = moment(date.start).format('YYYY-MM-DD');
      }
      if (date.end) {
        this.incoming.filterValues.deliveryDate.end = moment(date.end).format('YYYY-MM-DD');
      } else {
        date.end = null;
      }
      this.incoming.filterValues.simple = date.hasSimpleCargo;
      this.getIncomingCargos();
    },
    productTypeNames (ids) {
      const res = [];
      ids.forEach(id => {
        res.push(this.BasicHelper.getConfigValue('product_types', 'id', id, 'name', this.$i18n.locale));
      });
      if (res.length === 0) {
        res.push('-');
      }
      return res.join('; ');
    },
    getIncomingCargos () {
      if (!this.mounted) {
        return;
      }
      if (this.incoming.currentReq) {
        this.incoming.currentReq.cancel('changed search query');
      }
      this.incoming.loading = true;
      this.incoming.filterValues.ordercargotype = this.scheduling.filterValues.ordercargotype;
      this.BasicHelper.updateFilterValues(this.$route.name + '_incoming', this.incoming.filterValues);
      const filterValues = this.BasicHelper.cloneObject(this.incoming.filterValues);
      // -1 day because of minight cargos DST
      filterValues.deliveryDate.start = moment(filterValues.deliveryDate.start).subtract(1, 'd').format('YYYY-MM-DD');
      const path = this.ApiRequest.addGetParamsToPath('cargo-orders/schedule/incoming', filterValues);
      this.incoming.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.data) {
          this.incoming.items.splice(0, this.incoming.items.length);
          response.data.forEach(el => {
            this.incoming.items.push(el);
          });
          this.incoming.loading = false;
        }
      });
    },
    getScheduledCargos () {
      if (!this.mounted) {
        return;
      }
      if (this.scheduling.currentReq) {
        this.scheduling.currentReq.cancel('changed search query');
      }
      this.BasicHelper.updateFilterValues(this.$route.name + '_scheduling', this.scheduling.filterValues);
      this.scheduling.loading = true;
      this.scheduling.filterValues.perPage = this.scheduling.paging.limit;
      this.scheduling.filterValues.page = this.scheduling.paging.page;
      const path = this.ApiRequest.addGetParamsToPath('cargo-orders/schedule/missing', this.scheduling.filterValues);
      this.scheduling.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.data) {
          this.scheduling.items = response.data;
          this.scheduling.paging = response.paging;
          this.scheduling.loading = false;
        }
      });
    },
    refreshData () {
      this.getIncomingCargos();
      this.getScheduledCargos();
    },
    changePage (pageNr) {
      this.scheduling.paging.page = pageNr;
      this.getScheduledCargos();
    },
    cargeScheduleEdited (el) {
      if (el.statusWasChanged) {
        this.getScheduledCargos();
      }
      this.getIncomingCargos();
    },
    setResources () {
      this.BasicHelper.updateFilterValues(this.$route.name + '_incoming', this.incoming.filterValues);
      this.$refs.calendar.setResources(this.selectedRampOptions);
    },
    clearFilters () {
      this.incoming.filterValues = this.BasicHelper.cloneObject(defaultIncomingFilters);
      this.scheduling.filterValues = this.BasicHelper.cloneObject(defaultSchedulingFilters);
      this.setDefaultStageIds();
      this.refreshData();
      this.setResources();
    },
    setDefaultStageIds () {
      if (this.incoming.filterValues.stageids.length == 0) {
        this.rampOptions.forEach(el => {
          if (el.value) {
            this.incoming.filterValues.stageids.push(el.value);
          }
        });
      }
    }
  },
  mounted () {
    this.draggable = new Draggable(this.$refs.externalEventsContainer, {
      itemSelector: '.dragcard',
      eventData: function (eventEl) {
        return {
          create: false
        }
      }
    });
    this.incoming.filterValues = this.BasicHelper.fillFilterValues(this.$route.name + '_incoming', this.incoming.filterValues, this.$session);
    this.scheduling.filterValues = this.BasicHelper.fillFilterValues(this.$route.name + '_scheduling', this.scheduling.filterValues, this.$session);
    this.setDefaultStageIds();
    this.mounted = true;
    this.refreshData();
    this.setResources();
  }
}
