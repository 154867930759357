const defaultFilterValues = {
  code: '',
  shiftManager: '',
  usageTypes: [],
  name: [],
  line_id: [],
  qualityNotDefined: true,
  flow_id: [],
  stage_id: []
};

export default {
  name: 'QualityTestList',
  components: {
  },
  data () {
    return {
      shiftManagers: [],
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { item: 'created_at', direction: 'desc' },
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false,
      baseConfigColumns: [],
      baseConfigsProductTypeIds: [],
      needProductSelect: false
    };
  },
  computed: {
    basePathComp () {
      return 'quality/product-quality-tests'
    },
    testerProfileId () {
      return this.$route.fullPath.split('/')[(this.$route.fullPath.split('/')).length - 2]
    },
    currentRouteName () {
      return this.$route.name;
    },
    basePerm () {
      return 'product-quality-tests';
    },
    availableTesterCategory () {
      const availableTesterCategory = JSON.parse(localStorage.getItem('availableTesterCategories'))
      return Array.isArray(availableTesterCategory) ? availableTesterCategory.find(el => el.id == this.testerProfileId) : undefined
    },
    availableTesterBaseConfigs () {
      if (this.availableTesterCategory != undefined && this.availableTesterCategory != null) {
        return this.availableTesterCategory.baseConfigs
      }
      return []
    },
    availableTesterLineIds () {
      if (this.availableTesterCategory != undefined && this.availableTesterCategory != null) {
        return this.availableTesterCategory.lineIds
      }
      return []
    },
    flowOptions () {
      return this.$store.getters.getConfigOptions(
        'line_flows',
        this.$i18n.locale,
        'line_id',
        this.filterValues.line_id,
        false,
        false,
        true,
        ['flowcode']
      );
    },
    lineOptions () {
      return this.$store.getters.getAvailableLineOptions(this.$i18n.locale).filter(el => this.availableTesterCategory != undefined ? this.availableTesterCategory.lineCodes.includes(el.code) : true);
    },
    editPermComp () {
      if (this.$route.meta.configPermabase) {
        return 'edit-' + this.$route.meta.configPermabase;
      }
      return this.editPerm;
    },
    compactLocales () {
      return false;
    },
    baseTransParam () {
      return 'factory-product'
    },
    isForImportedCargoCategory () {
      if (this.availableTesterCategory != undefined && this.availableTesterCategory.isForImportedCargos == true) {
        return true
      }
      return false
    },
    tableHeaders () {
      const headers = [
        { key: 'created_at', label: this.$t(this.baseTransParam + '.created_at'), type: 'datetime', filterKey: 'created' },
        { key: 'product_id', label: this.$t('product.name'), sortable: false, format: 'formatFromConfig', configKey: 'products', configParam: 'name', classTd: 'w-10', filterKey: (this.productOptions.length > 5 ? 'nameText' : '') },
        { key: 'code', label: this.$t('produced.code'), sortable: true, filterKey: 'code', classTd: 'w-10' }
      ];
      if (!this.isForImportedCargoCategory) {
        headers.push({ key: 'shift.shortname', label: this.$t('shift.shortName'), sortable: false, classTd: 'w-10' });
        headers.push({ key: 'shift.manager.fullname', label: this.$t('brigade.manager') });
      }
      if (this.baseConfigColumns.length > 0) {
        this.baseConfigColumns.forEach(function callback (value, index) {
          headers.push(value);
        });
      }
      if (this.isForImportedCargoCategory) {
        headers.push({ key: 'purchasecargo.localcode', label: this.$t('menu.quality.qualityElement.cargoLocalCode'), sortable: false, filterKey: 'cargoLocalCode', classTd: 'w-10', type: 'slot', slotcode: 'cargoLocalCode' })
        headers.push({ key: 'purchasecargo.receivedAt', label: this.$t('menu.quality.qualityElement.cargoReceivedAt'), type: 'datetime', filterKey: 'cargoReceivedAt' })
        headers.push({ key: 'purchasecargo.cargoQualityTestEnabled', label: this.$t('menu.quality.qualityElement.cargoQualityTestEnabled'), filterKey: 'cargoQualityTestEnabled', format: 'formatYesNo', reverseMode: true, classTd: 'w-10' })
      }
      headers.push({ key: 'summary_test.calculated_quality_id', label: this.$t(this.baseTransParam + '.quality_id'), type: 'slot', slotcode: 'calculatedQualityId' });
      headers.push({ key: 'summary_test.summary_quality_code', label: this.$t(this.baseTransParam + '.qualitycode'), format: 'formatFromConfig', configKey: 'product_qualities_code', configParam: 'code' });
      const actions = [];
      if (this.canEdit) {
        actions.push({ label: this.$t('general.actions.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-primary btn-sm' });
        actions.push({ label: this.$t('menu.quality.shift-product-measurements.over-view'), emit: 'overView', bicon: 'eye-fill', class: 'btn-sm btn-primary', ifconfig: { func: this.displayOverviewEdit, useFunc: true } });
      }
      if (this.canDelete) {
        // actions.push({ label: this.$t('general.actions.delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-sm btn-danger', ifconfig: { param: 'deleted_at', value: null } });
        // actions.push({ label: this.$t('general.actions.enable'), emit: 'restoreItem', bicon: 'clock-history', class: 'btn-sm btn-warning', ifconfig: { param: 'deleted_at', value: null, notCheck: true } });
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    tableExpandConfig () {
      return null;
    },
    statusOptionsUsed () {
      let options = Object.values(this.$store.getters.getItemsFromConfigWithFilter('cargo_statuses', null, null));
      options = this.BasicHelper.sortArrayByKeyValue(options, 'order');
      options = options.filter(el => !['TEMPLATE', 'PLANNED', 'ASSEMBLY', 'ASSEMBLED'].includes(el.code));
      options.sort((a, b) => a.order - b.order);
      options.forEach(el => {
        el.colorCheckbox = false;
        el.labelSpanClass = null;
      });
      return options;
    },
    tableFilterConfig () {
      const filters = { };
      let lineCol = 'col-4'
      if (this.stageOptions.length < 2 && this.flowOptions.length < 2) {
        lineCol = 'col-12'
      }
      if (!this.isForImportedCargoCategory) {
        filters.line_id = { type: 'slot', label: this.$t('shift.line_id'), slotcode: 'linefilter', class: lineCol };
      }
      let flowCol = 'col-8'
      if (this.stageOptions.length > 1) {
        flowCol = 'col-4'
      }
      if (this.flowOptions.length > 1 && this.filterValues.line_id.length > 0) {
        filters.flow_id = { type: 'checkbox', label: this.$t('produced.flow_id'), options: this.flowOptions, valueField: 'value', class: flowCol };
      }
      let stageCol = 'col-8'
      if (this.flowOptions.length > 1) {
        stageCol = 'col-4'
      }
      if (this.stageOptions.length > 1) {
        filters.stage_id = { type: 'checkbox', label: this.$t('menu.quality.qualityElement.technologicalStage'), options: this.stageOptions, valueField: 'id', class: stageCol }
      }

      if (this.availableTesterCategory.isForImportedCargos) {
        filters.cargoStatus = {
          type: 'checkbox',
          label: this.$t('menu.quality.qualityElement.cargoStatus'),
          options: this.statusOptionsUsed,
          class: 'col-lg-6',
          textField: 'name',
          textTranslate: true
        }
      }
      filters.code = { type: 'text', label: '', colOnlyFilter: true };
      if (this.productOptions.length > 5) {
        filters.nameText = { type: 'text', label: '', colOnlyFilter: true };
      } else {
        filters.name = { type: 'checkbox', label: this.$t('product.name'), options: this.productOptions, valueField: 'value' };
      }
      if (!this.isForImportedCargoCategory) {
        filters.shiftManager = { type: 'slot', label: this.$t('brigade.manager'), slotcode: 'shiftManagerFilter', class: 'col-2', classTd: 'w-6' };
        filters.shiftDateRange = { type: 'date-range', label: this.$t('menu.quality.shiftDateRange'), colOnlyFilter: false, manualFilter: true, class: 'col-2' }
      }
      filters.calculatedQualityCode = { type: 'advancedSelect', class: 'col-2', size: 'md', label: this.$t(this.baseTransParam + '.qualitycode'), colOnlyFilter: false, options: this.qualityCodeOptions };
      filters.qualityNotDefined = { type: 'checkbox-single', label: this.$t('menu.quality.qualityNotDefined'), class: 'col-2 mt-3', inlineLabel: true };
      if (this.isForImportedCargoCategory) {
        filters.cargoLocalCode = { type: 'text', label: '', colOnlyFilter: true };
        filters.cargoReceivedAt = { type: 'date-range', label: '', colOnlyFilter: true, manualFilter: true };
        filters.cargoQualityTestEnabled = { type: 'checkbox', label: '', options: this.yesNoOptions, colOnlyFilter: true };
      }
      filters.created = { type: 'date-range', label: '', colOnlyFilter: true, manualFilter: true };
      return filters;
    },
    getShiftManagers () {
      const dataSet = []
      this.shiftManagers.forEach(manager => {
        dataSet.push({
          value: manager.id,
          text: manager.fullname
        })
      })
      return dataSet;
    },
    qualityCodeOptions () {
      return this.$store.getters.getConfigOptions('product_qualities_code', this.$i18n.locale);
    },
    productOptions () {
      let data = this.$store.getters.getConfigOptions('products', this.$i18n.locale, null, null, null, false, false, ['type_id'], true);
      if (this.filterValues.type != undefined && this.filterValues.type.length > 0) {
        const allowedTypeIds = this.$store.getters.getConfigOptions('product_types', this.$i18n.locale).filter(el => this.filterValues.type.includes(el.code)).map(el => el.value)
        data = data.filter(el => allowedTypeIds.includes(el.type_id))
      }
      const allowedProducts = []
      const vm = this
      data.forEach(product => {
        vm.availableTesterBaseConfigs.forEach(baseConfig => {
          if (product.type_id == baseConfig.product_type) {
            if (baseConfig.product_id != null) {
              if (baseConfig.product_id == product.value) {
                allowedProducts.push(product)
              }
            } else {
              allowedProducts.push(product)
            }
          }
        })
      })
      return allowedProducts;
    },
    canDelete () {
      return this.ApiRequest.userHasPermission('delete-' + this.basePerm);
    },
    canEdit () {
      return (this.ApiRequest.userHasPermission('edit-' + this.basePerm) || (this.availableTesterCategory != null && this.availableTesterCategory != undefined));
    },
    yesNoOptions () {
      return this.BasicHelper.yesNoOptions();
    },
    usageTypeOptions () {
      return this.PetHelper.getProductUsageTypeOptions(true, ['isSeparated', 'isFinalProduct']);
    },
    matchingProductsWithStages () {
      return this.$store.getters.getMatchingProductsWithStages(null, { canProduce: true }, { isFinalProduct: true })
    },
    stageOptions () {
      const validFlows = this.filterValues.flow_id.map(Number)
      const foundStageIds = []
      const validStages = []
      let validStageIds = []
      const vm = this
      Object.keys(this.matchingProductsWithStages).map(Number).forEach(function callback (flowId) {
        if (validFlows.includes(flowId) || validFlows.length == 0) {
          vm.availableTesterBaseConfigs.forEach(baseConfig => {
            let validProducts = Object.values(vm.matchingProductsWithStages[flowId].products).filter(
              el => el.type_id == baseConfig.product_type && vm.typeOptions.map(typeOption => typeOption.value).includes(el.type_id))
            if (validProducts.length > 0) {
              validProducts.forEach(validProduct => {
                validStageIds = validStageIds.concat(Object.keys(validProduct.stages).map(el => parseInt(el)))
              })
              if (baseConfig.product_id != null) {
                validProducts = validProducts.filter(el => el.id == baseConfig.product_id)
                if (validProducts.length > 0 && vm.availableTesterLineIds.map(el => parseInt(el)).includes(vm.matchingProductsWithStages[flowId].line_id)) {
                  vm.matchingProductsWithStages[flowId].stages.forEach(stage => {
                    if (!foundStageIds.includes(stage.id) && validStageIds.includes(stage.id)) {
                      validStages.push(stage)
                      foundStageIds.push(stage.id)
                    }
                  })
                }
              } else {
                if (vm.availableTesterLineIds.map(el => parseInt(el)).includes(vm.matchingProductsWithStages[flowId].line_id)) {
                  vm.matchingProductsWithStages[flowId].stages.forEach(stage => {
                    if (!foundStageIds.includes(stage.id) && validStageIds.includes(stage.id)) {
                      validStages.push(stage)
                      foundStageIds.push(stage.id)
                    }
                  })
                }
              }
            }
          })
        }
      });
      if (this.filterValues.line_id.length > 0) {
        return validStages.filter(el => this.filterValues.line_id.map(Number).includes(el.lineId))
      }
      return validStages
    },
    typeOptions () {
      return this.PetHelper.getProductTypes().filter(el => this.baseConfigsProductTypeIds.includes(el.value));
    },
    tableActions () {
      const actions = { download: false };
      return actions;
    }
  },
  methods: {
    displayOverviewEdit (el) {
      return el.summary_test !== null;
    },
    getTableData () {
      if (this.basePathComp) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        this.loading = true;
        let path = 'quality/tester-product-quality-tests/list?perPage=' + this.paging.limit + '&page=' + this.paging.page + '&testerProfileId=' + this.testerProfileId;
        const usedFilters = this.BasicHelper.cloneObject(this.filterValues);
        if (!this.isForImportedCargoCategory && !usedFilters.line_id.length) {
          usedFilters.line_id = this.lineOptions.map(el => el.value);
        }
        path = this.ApiRequest.addGetParamsToPath(path, usedFilters, true);
        if (this.tableSorting.item) {
          path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
        }
        this.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data.list) {
            this.shiftManagers = response.data.shiftManagers
            this.baseConfigsProductTypeIds = response.data.baseConfigsProductTypeIds
            this.baseConfigColumns = [];

            if (response.data.dataSetByMeasurementGroupCode != undefined && Object.keys(response.data.dataSetByMeasurementGroupCode).length > 0) {
              const vm = this;
              const objectList = Object.values(response.data.dataSetByMeasurementGroupCode);
              Object.keys(response.data.dataSetByMeasurementGroupCode).forEach(function callback (value, index) {
                if (objectList[index].showTesterCalculatedValue == true) {
                  vm.baseConfigColumns.push({
                    key: 'tableSummary.' + value + '.' + 'value',
                    label: objectList[index].measurementName,
                    classCondition: function (item) {
                      if (!('tableSummary' in item) || item.tableSummary[value] == undefined) return '';
                      if (item.tableSummary[value].hasWarning == true) {
                        return 'bg-warning';
                      }
                      return '';
                    }
                  });
                }
                if (objectList[index].showTesterInputValue == true) {
                  vm.baseConfigColumns.push({
                    key: 'tableSummary.' + value + '.' + 'inputValue',
                    label: objectList[index].inputMeasurementName,
                    classCondition: function (item) {
                      if (!('tableSummary' in item) || item.tableSummary[value] == undefined) return '';
                      if (item.tableSummary[value].hasInputWarning == true) {
                        return 'bg-warning';
                      }
                      return '';
                    }
                  });
                }
              })
            } else {
              if (response.data.columnsByGroupedCode != undefined && Object.keys(response.data.columnsByGroupedCode).length > 0) {
                const vm = this;
                const objectList = Object.values(response.data.columnsByGroupedCode);
                Object.keys(response.data.columnsByGroupedCode).forEach(function callback (value, index) {
                  if (objectList[index].showTesterCalculatedValue == true) {
                    vm.baseConfigColumns.push({
                      key: 'columnsByGroupedCode',
                      label: objectList[index].measurementName
                    });
                  }
                  if (objectList[index].showTesterInputValue == true) {
                    vm.baseConfigColumns.push({
                      key: 'columnsByGroupedCode',
                      label: objectList[index].inputMeasurementName
                    });
                  }
                })
              }
            }
            this.tableItems = response.data.list;
            this.paging = response.paging;
            this.loading = false;
          }
        });
      }
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    editItem (el, newtab = 0) {
      if (newtab) {
        const routeData = this.$router.resolve({
          name: 'menu.quality.productQualityTestEdit',
          params: { id: el.id }
        });
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push({ name: 'menu.quality.productQualityTestEdit' + this.availableTesterCategory?.id, params: { id: el.id, testerViewName: this.currentRouteName, availableTesterCategory: this.availableTesterCategory, testerCategoryId: this.availableTesterCategory?.id } });
      }
    },
    overView (el, newtab = 0) {
      if (newtab) {
        const routeData = this.$router.resolve({
          name: 'menu.quality.productQualityTestOverView',
          params: { id: el.id }
        });
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push({ name: 'menu.quality.productQualityTestOverView' + this.availableTesterCategory?.id, params: { id: el.id, testerViewName: this.currentRouteName, availableTesterCategory: this.availableTesterCategory } });
      }
    },
    lineFilterChanged () {
      this.filterValues.flow_id = this.filterValues.flow_id.filter(flowId => (this.flowOptions.findIndex(el => el.value == flowId) >= 0));
      this.getTableData();
    },
    cloneItem (el) {
      el.id = null;
      el.code = null;
      el.shortCode = null;
      this.$refs.editElFrm.show(el);
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    },
    deleteItem (el, approved) {
      if (approved) {
        this.setDeleted(el, 1);
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t(this.baseTransParam + '.actions.deleteTitle'), this.$t(this.baseTransParam + '.actions.deleteMessage', { name: this.BasicHelper.getTranslation(el.name), code: el.code }))
      }
    },
    restoreItem (el) {
      this.setDeleted(el, 0);
    },
    setDeleted (el, isDeleted) {
      this.loading = true;
      this.ApiRequest.request(this.basePathComp + '/deleted', this.ApiRequest.REQUEST_POST, { id: el.id, code: el.code, deleted: isDeleted }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        } else {
          this.getTableData();
        }
      });
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.getTableData();
    this.isMounted = true;
  },
  watch: {
    basePathComp: {
      immediate: true,
      handler (val) {
        if (val) {
          this.isMounted = false;
          this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
          this.getTableData();
          this.isMounted = true;
        }
      }
    },
    testerProfileId: {
      immediate: true,
      handler (val) {
        if (val && !isNaN(Number(val))) {
          this.isMounted = false;
          this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
          this.getTableData();
          this.isMounted = true;
        }
      }
    }
  }
}
