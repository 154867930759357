import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
export default {
  name: 'FactoryProductEditBales',
  data () {
    return {
      editEl: null,
      loading: false
    }
  },
  components: {
  },
  validations: {
    editEl: {
      remainingBales: { required }
    }
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getTitle () {
      return this.$t('factory-product.actions.editBales');
    }
  },
  methods: {
    show (el) {
      this.editEl = this.BasicHelper.cloneObject(el);
      this.loading = false;
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    save () {
      this.v$.$touch();
      if (!this.loading && !this.v$.$invalid) {
        this.loading = true;
        const reqData = {
          remainingBales: this.editEl.remainingBales
        }

        this.ApiRequest.request('factory-products/editBales/' + this.editEl.id, this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.data) {
            this.$emit('saved', response.data);
            this.close();
          } else {
            this.ApiRequest.displayErrorDialog(response, this);
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
