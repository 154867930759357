import MqttDevice from 'ebg-vue-common/src/components/common/mqttdevice.vue'

export default {
  name: 'DeviceScaleInput',
  props: {
    value: {
      type: Number | String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: Number,
      default: 100
    },
    maxValue: {
      type: Number,
      default: null
    },
    minValue: {
      type: Number,
      default: null
    },
    precision: {
      type: Number,
      default: null
    },
    placeholder: {
      type: Number | String,
      default: null
    },
    controlClass: {
      type: String,
      default: ''
    },
    measureTypeId: {
      type: Number,
      default: null
    },
    inputGroupClass: {
      type: String,
      default: ''
    },
    deviceIds: {
      type: Array,
      default: []
    },
    deviceTypeCode: {
      type: String,
      default: 'SCALE'
    },
    clearOnDeviceChange: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      content: this.value,
      deviceId: null
    }
  },
  components: {
    MqttDevice
  },
  computed: {
    deviceOptions () {
      return this.$store.getters.getDevicesByType(this.deviceTypeCode).filter(el => {
        el.text = this.BasicHelper.getTranslation(el.name);
        return this.deviceIds.find(devId => { return devId == el.id });
      });
    },
    selectedScale () {
      return this.deviceOptions.find(el => el.id == this.deviceId);
    }
  },
  methods: {
    deviceChanged () {
      if (this.clearOnDeviceChange) {
        this.updateValue('');
      }
    },
    updateValue (val) {
      if (val) {
        this.content = parseInt(val) + '';
      } else {
        this.content = '';
      }
      this.$emit('input', this.content);
    },
    triggerRead () {
      this.$refs.scalemqtt.publish('');
    },
    setBaseDevice () {
      if (this.deviceOptions.length > 0) {
        this.deviceId = this.deviceOptions[0].id;
      } else {
        this.deviceId = null;
      }
    }
  },
  mounted () {
    this.setBaseDevice();
  },
  watch: {
    value () {
      if (this.content !== this.value) {
        this.content = this.value;
      }
    },
    deviceIds () {
      this.setBaseDevice();
    }
  }
}
