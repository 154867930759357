import useVuelidate from '@vuelidate/core';
import ShiftProductMeasurementElement
  from '@/components/pet/quality/shiftProductsMeasurements/edit/ShiftProductMeasurementElement/ShiftProductMeasurementElement.vue';

const validateTotalWeight = (value, siblings, vm) => {
  const findCategory = vm.categoriesWhichHaveTotalWeightValidation.find(el => el.value == vm.categoryId)
  if (findCategory == undefined) return true
  let findTotal = 0
  if (value != null && value.length > 0) {
    let findMeasurement = null
    let findMeasurementType = null
    value.forEach(qualityMeasurement => {
      if (qualityMeasurement.category_id == vm.categoryId) {
        findTotal += parseFloat(qualityMeasurement.value == null ? 0 : qualityMeasurement.value)
        if (findMeasurement == null && vm.qualityBaseConfig.quality_measurements != undefined) {
          findMeasurement = vm.qualityBaseConfig.quality_measurements.find(el => el.id == qualityMeasurement.quality_id)
          if (findMeasurement != undefined) {
            findMeasurementType = vm.measureTypeOptions.find(el => el.value == findMeasurement.measurement_id)
          }
        }
      }
    });
    findTotal = Math.round(parseFloat(findTotal) * 100) / 100;
    if (
      findMeasurementType != null &&
      findMeasurementType != undefined &&
      vm.percentQualityFormulaMeasurementConstants.value == findMeasurementType.code &&
      findTotal != 100
    ) {
      return false
    }
  }
  return true;
}

export default {
  name: 'ShiftProductMeasurementGroup',
  data () {
    return {
      categoryParentInherit: false
    }
  },
  validations: {
    testMeasurement: { validateTotalWeight }
  },
  components: {
    ShiftProductMeasurementElement
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    isNewColumn: {
      type: Boolean,
      default: true
    },
    testMeasurement: {
      type: Array,
      default: () => []
    },
    qualityBaseConfig: {
      type: Object,
      default: () => {}
    },
    categoryId: {
      type: Number,
      default: null
    },
    groupedMeasurement: {
      type: Array,
      default: () => []
    },
    productDataSet: {
      type: Object,
      default: () => {}
    },
    productTests: {
      type: Array,
      default: () => []
    },
    productTestOptionCode: {
      type: Number,
      default: null
    }
  },
  computed: {
    measureTypeOptions () {
      return this.$store.getters.getConfigOptions('measure_types', this.$i18n.locale);
    },
    percentQualityFormulaMeasurementConstants () {
      return JSON.parse(this.$store.state.config.qualityFormulaMeasurementConstants).find(el => el.name == 'PERCENT')
    },
    categoriesWhichCanUseAverageDataOption () {
      return this.$store.getters.getConfigOptions('quality_measurement_categories', this.$i18n.locale,
        'canUseAverageData', 1)
    },
    categoriesWhichHaveTotalWeightValidation () {
      return this.$store.getters.getConfigOptions('quality_measurement_categories', this.$i18n.locale,
        'validateTotalWeight', 1)
    },
    checkParentCategoryInherit () {
      let result = false;
      if (this.categoriesWhichCanUseAverageDataOption.length > 0) {
        const checkCategory = this.categoriesWhichCanUseAverageDataOption.find(el => el.value == this.categoryId)
        if (checkCategory != undefined) {
          this.groupedMeasurement.forEach(measurement => {
            if ('canEditOnFactoryProductShiftMeasurement' in measurement.additionalData && measurement.additionalData.canEditOnFactoryProductShiftMeasurement) {
              if (result == false) result = true;
            } else {
              result = false;
            }
          })
        }
      }
      return result;
    }
  },
  methods: {
  },
  setup () {
    return { v$: useVuelidate() }
  },
  mounted () {
    this.isMounted = true
  },
  watch: {
    '$route' () {
    }
  }
}
