const defaultFilterValues = {
}

export default {
  name: 'AssemblyApprovalBalesList',
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { item: 'created_at', direction: 'desc' },
      paging: { limit: 100, page: 1, total: 0, pages: 0 }
    }
  },
  props: {
    approvalId: {
      type: Number,
      default: null
    }
  },
  components: {
  },
  computed: {
    tableHeaders () {
      const headers = [
        { key: 'bale_id', label: this.$t('general.item.id') },
        { key: 'startQuantity', label: this.$t('assembly.approval.bale.startQuantity'), format: 'formatDecimal', precision: 0 },
        { key: 'endQuantity', label: this.$t('assembly.approval.bale.endQuantity'), format: 'formatDecimal', precision: 0, classCondition: this.getEndQuantityClass },
        { key: 'code', label: this.$t('factoryproduct.code') },
        { key: 'fed_at', label: this.$t('assembly.bale.fed_at'), type: 'datetime', classCondition: this.getFeedingreversedClass },
        { key: 'feedingReversed', label: this.$t('assembly.approval.bale.feedingReversed'), format: 'formatYesNo' },
        { key: 'feedingAdded', label: this.$t('assembly.approval.bale.feedingAdded'), format: 'formatYesNo' }
      ];
      return headers;
    },
    tableExpandConfig () {
      return null;
    },
    tableFilterConfig () {
      const filters = { };
      return filters;
    },
    requestPath () {
      let path = this.ApiRequest.addGetParamsToPath(`assembly/approvals/approvedbales/${this.approvalId}?perPage=${this.paging.limit}&page=${this.paging.page}`, this.filterValues, true);
      if (this.tableSorting.item) {
        path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
      }
      path = this.ApiRequest.addGetParamsToPath(path, this.filterValues);
      return path;
    }
  },
  methods: {
    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.loading = true;
      this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, [], (response) => {
        if (response.data) {
          this.tableItems = response.data;
          this.paging = response.paging;
        }
        this.loading = false;
      });
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    },
    getEndQuantityClass (el) {
      if (el.startQuantity != el.endQuantity) {
        return 'text-dangerinfo fw-bold';
      }
      return '';
    },
    getFeedingreversedClass (el) {
      if (el.feedingReversed) {
        return 'text-decoration-line-through'
      }
      return '';
    }
  },
  mounted () {
    this.getTableData();
  }
}
