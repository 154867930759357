import PartnerPicker from '@/components/pet/partners/picker/PartnerPicker.vue';
import useVuelidate from '@vuelidate/core';
import { requiredIf, helpers, required, email } from '@vuelidate/validators';
import LogList from 'ebg-vue-common/src/components/log/list/LogList.vue'
import WareHouseCargoList from '@/components/pet/sales/cargo/listfull/WarehouseCargosList.vue';
import D365AddressPicker from 'ebg-vue-common/src/components/d365/addresspicker/D365AddressPicker.vue';
import CountryList from '@/components/pet/sales/cargo/country/CountryList.vue';
import Characterization from '@/components/pet/characterizations/Characterization.vue';
import Permits from '@/components/pet/sales/cargo/permits/Permits.vue';

const defaultValues = {
  id: null,
  vendor_id: null,
  name: '',
  orgNumberOrPersonCode: '',
  vendorD365Code: '',
  ax2009code: '',
  locale_id: '',
  legalAddress: '',
  hasVzdCode: true,
  vzdcode: null,
  country: 'LVA',
  isActual: true,
  vendor: null,
  carriages: [],
  permits: [],
  phone: '',
  email: ''
};

const defaultPermitValues = {
  id: null,
  permit_type_id: null,
  countries: null,
  isActual: false,
  is_saved: false
};

const defaultCarriageValues = {
  id: null,
  cargo_type_id: null,
  countries: null,
  vehicles: null,
  email: null,
  phone: null,
  comment: null,
  is_saved: false
}

export default {
  name: 'TransportProviderEdit',
  components: { Characterization, WareHouseCargoList, PartnerPicker, LogList, D365AddressPicker, CountryList, Permits },
  data () {
    return {
      transportProvider: this.BasicHelper.cloneObject(defaultValues),
      availableCountries: [],
      loading: false
    }
  },
  validations: {
    transportProvider: {
      vendor_id: { },
      name: {
        required: requiredIf(function () {
          return !this.hasSelectedVendor;
        })
      },
      orgNumberOrPersonCode: {
        required: requiredIf(function () {
          return !this.hasSelectedVendor;
        })
      },
      locale_id: {
        required: requiredIf(function () {
          return !this.hasSelectedVendor;
        })
      },
      country: {
        required: requiredIf(function () {
          return !this.hasSelectedVendor;
        })
      },
      legalAddress: {
        required: requiredIf(function () {
          return !this.hasSelectedVendor;
        })
      },
      carriages: {
        $each: helpers.forEach({
          email: { email, required }
        })
      },
      permits: {
        $each: helpers.forEach({
          permit_type_id: { required }
        })
      }
    }
  },
  computed: {
    tabOptions () {
      const options = [
        {
          code: 'COUNTRY',
          text: this.$t('transport-provider.country-tab'),
          invalid: this.v$.transportProvider.carriages.$error
        },
        {
          code: 'PERMISSIONS',
          text: this.$t('transport-provider.permits-tab'),
          invalid: this.v$.transportProvider.permits.$error
        }
      ];
      if (this.transportProvider && this.transportProvider.id) {
        options.push({ code: 'CHARACTERIZATION', text: this.$t('classifiers.characterizations.name'), addNew: false });
        options.push({ code: 'CARGOS', text: this.$t('transport-provider.cargos-tab') });
        options.push({ code: 'HISTORY', text: this.$t('transport-provider.history-tab') });
      }
      return options;
    },
    hasSelectedVendor () {
      if (this.transportProvider && this.transportProvider.vendor) {
        return true;
      }
      return false;
    },
    vendorD365Code () {
      if (this.transportProvider && this.transportProvider.vendor) {
        return this.transportProvider.vendor.code;
      }
      return null;
    },
    canEdit () {
      return !this.loading;
    },
    canEditAll () {
      return !this.loading && this.transportProvider && !this.transportProvider.vendor;
    },
    partnerPickerFilters () {
      return { isActual: 1, with: 'primaryAddress' }
    },
    orderCargoTypeOptions () {
      return this.$store.getters.getConfigOptions('order_cargo_types', this.$i18n.locale);
    },
    countryOptions () {
      return this.$store.getters.getCountryOptions();
    },
    localesOptions () {
      return this.$store.getters.getConfigOptions('locales', this.$i18n.locale)
    }
  },
  methods: {
    addrChanged (val) {
      this.transportProvider.vzdcode = val.id;
      this.transportProvider.legalAddress = val.text;
      this.transportProvider.hasVzdCode = !val.manualEntry;
    },
    setHasVzdCode (val) {
      if (val != 'LVA') {
        this.transportProvider.hasVzdCode = false;
        this.transportProvider.vzdcode = null;
      }
    },

    addCountry () {
      const el = this.BasicHelper.cloneObject(defaultCarriageValues);
      el.cargo_type_id = this.orderCargoTypeOptions[0].value;
      this.transportProvider.carriages.push(el);
    },
    addPermission () {
      this.transportProvider.permits.push(this.BasicHelper.cloneObject(defaultPermitValues));
    },
    partnerChanged (vendor) {
      if (vendor) {
        this.transportProvider.orgNumberOrPersonCode = vendor.orgNumberOrPersonCode.toString();
        this.transportProvider.name = vendor.name;
        this.transportProvider.vendor_id = vendor.id;
        this.transportProvider.locale_id = vendor.locale_id;
        this.transportProvider.ax2009code = vendor.ax2009code;
        if (vendor.primary_address) {
          this.transportProvider.legalAddress = vendor.primary_address.address;
          this.transportProvider.country = vendor.primary_address.country;
          this.transportProvider.vzdcode = vendor.primary_address.vzdcode;
        } else {
          this.transportProvider.legalAddress = vendor.legalAddress;
          this.transportProvider.country = vendor.country;
          this.transportProvider.vzdcode = null;
        }
        this.transportProvider.hasVzdCode = (!!this.transportProvider.vzdcode);
        if (vendor.isActual) {
          this.transportProvider.isActual = true;
        } else {
          this.transportProvider.isActual = false;
        }
      } else {
        this.transportProvider.orgNumberOrPersonCode = null;
        this.transportProvider.name = null;
        this.transportProvider.vendor_id = null;
        this.transportProvider.locale_id = null;
        this.transportProvider.ax2009code = null;
        this.transportProvider.legalAddress = null;
        this.transportProvider.country = 'LVA';
        this.transportProvider.vzdcode = null;
        this.transportProvider.hasVzdCode = true;
      }
      this.$forceUpdate();
    },
    setData (id) {
      this.v$.$reset();
      if (id !== 'NEW') {
        this.loading = true;
        this.ApiRequest.request('transport-providers/view/' + id, this.ApiRequest.REQUEST_GET, [], (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.transportProvider = this.BasicHelper.cloneObject(response.data);
          }
          this.loading = false;
        });
      }
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        this.ApiRequest.request('transport-providers/edit', this.ApiRequest.REQUEST_POST, { item: this.transportProvider }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.transportProvider = this.BasicHelper.cloneObject(response.data);
            if (this.$refs.logList) {
              this.$refs.logList.reload();
            }
            this.$store.dispatch('reloadConfig');
          }
          this.loading = false;
        });
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  mounted () {
    this.$store.dispatch('loadDynamicConfig', { type: 'Countries' });
    this.setData(this.$route.params.id);
  }
}
