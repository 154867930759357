import useVuelidate from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';

const defaultShiftFlowProduced = {
  id: null,
  product_ids_data_set: [],
  technological_stage_id: null,
  recipe_id: null,
  flow_id: null,
  shift_id: null,
  targetQuantity: null
};

export default {
  name: 'ShiftFlowProduced',
  data () {
    return {
    }
  },
  components: {
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    },
    flowId: {
      type: Number,
      default: null
    },
    shiftId: {
      type: Number,
      default: null
    },
    lineId: {
      type: Number,
      default: null
    },
    shiftFlowProduced: {
      type: Array,
      default: () => []
    },
    shiftAvailableRecipes: {
      type: Array,
      default: () => []
    },
    allowEditFlowRecipe: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    shiftAvailabilityData: {
      type: Object,
      default: () => {}
    },
    shiftProductionEffectivityData: {
      type: [Object, Array],
      default: () => {}
    }
  },
  validations: {
    shiftFlowProduced: {
      $each: helpers.forEach({
        technological_stage_id: { required },
        product_ids_data_set: { required },
        recipe_id: {
          required: requiredIf(function (key, row) {
            return this.recipeOptions(row.flow_id).length > 0;
          })
        }
      })
    }
  },
  computed: {
    recipeRequired () {
      return this.shiftAvailableRecipes.length > 0;
    },
    flowOptions () {
      if (this.lineId == null) {
        return this.$store.getters.getConfigOptions('line_flows', this.$i18n.locale);
      }
      return this.$store.getters.getConfigOptions('line_flows', this.$i18n.locale, 'line_id', this.lineId);
    },
    baseTransParam () {
      return 'shiftFlowProduced';
    },
    canEdit () {
      return !this.readOnly && !this.loading && this.ApiRequest.userHasPermission('edit-shift-flow-produced');
    },
    canViewShiftFlowProduced () {
      return (this.ApiRequest.userHasPermission(['view-shift-flow-produced']));
    },
    productOptions () {
      return this.$store.getters.getConfigOptions('products', this.$i18n.locale);
    },
    matchingProductsWithStages () {
      return this.$store.getters.getMatchingProductsWithStages(null, { canProduce: true }, { isFinalProduct: true })
    }
  },
  methods: {
    calculateTargetQuantity (index, flowId, stageId) {
      if (!this.canEdit || flowId == null || stageId == null || this.shiftFlowProduced[index].product_ids_data_set.length == 0) {
        return 0;
      }

      if (
        this.matchingProductsWithStages[flowId] != undefined &&
        this.matchingProductsWithStages[flowId].stages != undefined &&
        this.matchingProductsWithStages[flowId].stages.length > 0
      ) {
        const products = Object.values(this.matchingProductsWithStages[flowId].products).filter(el => {
          return this.shiftFlowProduced[index].product_ids_data_set.includes(el.id);
        });

        let yieldrateSum = 0;

        Object.values(products).forEach(product => {
          if (product.stages[stageId] != undefined) {
            yieldrateSum += product.stages[stageId].yieldrate;
          }
        });

        const targetQuantity = (yieldrateSum / products.length) *
          this.shiftAvailabilityData.flows[flowId].plannedProductionTime * 1000;

        this.shiftFlowProduced[index].calculatedTargetQuantity = targetQuantity;

        return targetQuantity;
      }
    },
    finalProductShiftFlowProduced () {
      const matchingProducts = this.matchingProductsWithStages;
      const matchingLineProducts = Object.entries(matchingProducts).filter(([key, el]) => {
        return this.lineId == el.line_id;
      }).reduce((acc, [key, el]) => {
        acc[key] = el;
        return acc;
      }, {});

      const options = {};

      for (const [flowId, flow] of Object.entries(matchingLineProducts)) {
        for (const product of Object.values(flow.products)) {
          for (const stageId of Object.keys(product.stages)) {
            options[flowId] = options[flowId] || {};
            options[flowId][stageId] = options[flowId][stageId] || {};
            options[flowId][stageId][product.id] = product.text;
          }
        }
      }

      return options;
    },
    getFlowName (flowId) {
      return this.$store.getters.getParamFromConfig('line_flows', flowId, 'name', this.$i18n.locale);
    },
    getStageName (flowId, stageId) {
      let text = '';
      const stages = this.$store.getters.getParamFromConfig('line_flows', flowId, 'stages', this.$i18n.locale);
      const findStage = stages.find(el => el.id == stageId);
      if (findStage != undefined) {
        text = findStage.name[this.$i18n.locale];
      }
      return text;
    },
    getActualYieldValue (stageId, flowId) {
      return this.shiftProductionEffectivityData.stages[stageId].totalCalculatedWeight /
             this.shiftAvailabilityData.flows[flowId].actualProductionTime
    },
    matchingProductsWithStagesOptions (flowId) {
      if (flowId != null && this.matchingProductsWithStages[flowId] != undefined && this.matchingProductsWithStages[flowId].stages != undefined &&
          this.matchingProductsWithStages[flowId].stages.length > 0) {
        return this.matchingProductsWithStages[flowId].stages.map(item => {
          const container = {}
          container.value = item.id
          container.text = item.text
          return container
        })
      }
      return []
    },
    recipeOptions (flowId) {
      if (flowId != null && this.shiftAvailableRecipes.length > 0 && this.allowEditFlowRecipe) {
        const formattedData = this.shiftAvailableRecipes.map(item => {
          const container = {}
          container.value = item.value
          container.flow_id = item.flow_id
          container.text = this.BasicHelper.formatDate(item.startAt, 'HH:mm:ss') + ' - ' + this.BasicHelper.formatDate(item.endAt, 'HH:mm:ss')
          return container
        })
        return formattedData.filter(el => el.flow_id == flowId)
      }
      return []
    },
    itemProductOptions (itemId) {
      const selectedStageId = this.shiftFlowProduced[itemId].technological_stage_id;
      if (selectedStageId != null && this.shiftFlowProduced[itemId].flow_id != null) {
        const validProductIds = [];
        Object.values(this.matchingProductsWithStages[this.shiftFlowProduced[itemId].flow_id].products).forEach(el => {
          if (Object.keys(el.stages).map(el => parseInt(el)).includes(selectedStageId)) {
            validProductIds.push(el.id)
          }
        })
        return this.productOptions.filter(el => validProductIds.includes(el.value))
      }
      return []
    },
    addItem () {
      const el = this.BasicHelper.cloneObject(defaultShiftFlowProduced);
      if (this.flowId != null) {
        el.flow_id = this.flowId
      }
      if (this.shiftId != null) {
        el.shift_id = this.shiftId
      }
      this.shiftFlowProduced.push(el);
    },
    removeItem (index) {
      if (this.shiftFlowProduced[index].id) {
        this.shiftFlowProduced[index].deleted = true;
      } else {
        this.shiftFlowProduced.splice(index, 1);
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  mounted () {
  },
  watch: {
  }
}
