import ClearPage from '@/views/ClearPage.vue';
import TesterWorkPlace from '@/components/pet/quality/testerProfile/TesterWorkPlace.vue';
import QualityTestEdit from '@/components/pet/quality/test/edit/QualityTestEdit.vue';
import ProductQualityTestOverView from '@/components/pet/quality/test/overView/ProductQualityTestOverView.vue';
import WarehouseLayout from '@/components/pet/warehouse/sku/WarehouseLayout.vue'

class PetHelper {
  setParams (component) {
    this.component = component
  }

  getShiftName (value, fullLineName = true) {
    if (value) {
      let txt = value.shortname;
      if (fullLineName) {
        txt += ' | ' + this.component.BasicHelper.getConfigValue('lines', 'id', value.line_id, 'name', true)
      }
      return txt;
    }
    return null;
  }

  getD365ProductOptions () {
    return this.component.$store.getters.getConfigOptions(
      'products',
      this.component.$i18n.locale,
      null,
      null,
      false,
      false,
      false,
      ['isBought', 'isSeparated', 'isChemical', 'isFinalProduct', 'inAxapta', 'isUnitCount', 'type_id', 'default_measure_type_id', 'availableMeasureTypes', 'availableApusWasteClasses', 'apus_waste_class_id']
    ).filter(el => {
      return (el.inAxapta);
    })
  }

  getFeadableBaleProducts (fulltitle = false, inAx2009code = true, filterUsage = true) {
    const options = this.component.$store.getters.getConfigOptions(
      'products',
      this.component.$i18n.locale,
      null,
      null,
      false,
      false,
      false,
      [
        'isBought', 'isSeparated', 'isFinalProduct', 'inAxapta', 'type_id', 'default_measure_type_id', 'availableMeasureTypes',
        'availableApusWasteClasses', 'apus_waste_class_id',
        'ax2009code', 'd365_product_id'
      ]
    ).filter(el => {
      if (filterUsage) {
        return (el.isBought || el.isSeparated || el.isFinalProduct);
      }
      return true;
    })
    if (fulltitle) {
      options.forEach(el => {
        const extraInfo = [];
        if (el.ax2009code && inAx2009code) {
          extraInfo.push('Ax: ' + el.ax2009code);
        }
        if (el.inAxapta && el.d365_product_id && this.component.$store.state.config.d365_products && this.component.$store.state.config.d365_products[el.d365_product_id]) {
          extraInfo.push('D365: ' + this.component.$store.state.config.d365_products[el.d365_product_id].code);
        }
        if (extraInfo.length > 0) {
          el.text += ' (' + extraInfo.join('; ') + ')'
        }
      });
    }
    return options;
  }

  getProductTypes () {
    return this.component.$store.getters.getConfigOptions(
      'product_types',
      this.component.$i18n.locale,
      null,
      null,
      false,
      false,
      false,
      ['isBought', 'isSeparated', 'isFinalProduct', 'inAxapta', 'isChemical']
    );
  }

  getProductConfigName (productId, paramId, param = 'name', translate = true) {
    const configMap = {
      variant_id: 'product_variants',
      type_id: 'product_types',
      apus_waste_class_id: 'apus_waste_classes',
      default_measure_type_id: 'measure_types',
      d365_product_id: 'd365_products'
    }
    const product = this.component.$store.getters.getDataFromConfigById('products', productId);
    if (product && product[paramId] && configMap[paramId]) {
      return this.component.BasicHelper.getConfigValue(configMap[paramId], 'id', product[paramId], param, translate)
    }
    return null;
  }

  getCurrencyNameFromCode (code = null, includeText = true, includeSymbol = false) {
    let currency = this.component.$store.getters.getConfigOptions('currencies', this.component.$i18n.locale, 'code', code, false, false, false, ['symbol']);
    if (!currency.length) {
      code = this.component.$store.state.config.default_currency_code;
      currency = this.component.$store.getters.getConfigOptions('currencies', this.component.$i18n.locale, 'code', code, false, false, false, ['symbol']);
    }
    let text = '';
    if (includeText && currency[0].text) {
      text = currency[0].text;
    }
    if (includeSymbol && currency[0].symbol) {
      if (text.length === 0) {
        text = currency[0].symbol;
      } else {
        text = text + ' (' + currency[0].symbol + ')';
      }
    }
    return text;
  }

  getProductUsageTypeOptions (onlyWithProduct = true, filterValues = []) {
    const options = [
      { code: 'isBought', text: this.component.$t('product.isBought'), hasProduct: true },
      { code: 'isSeparated', text: this.component.$t('product.isSeparated'), hasProduct: true },
      { code: 'isFinalProduct', text: this.component.$t('product.isFinalProduct'), hasProduct: true },
      { code: 'isChemical', text: this.component.$t('product.isChemical'), hasProduct: true },
      { code: 'withoutD365Product', text: this.component.$t('product.withoutD365Product'), hasProduct: false },
      { code: 'isBagPacked', text: this.component.$t('product.isBagPacked'), hasProduct: true },
      { code: 'isUnitCount', text: this.component.$t('product.isUnitCount'), hasProduct: true }
    ];
    if (onlyWithProduct) {
      return options.filter(el => filterValues.length ? filterValues.includes(el.code) && el.hasProduct === true : el.hasProduct === true);
    }
    return options;
  }

  getShiftCompletionClass (param, el) {
    if (param == 'warehouseApprovedAt') {
      if (el.warehouse_id == null) {
        return 'text-tertiary'
      } else if (el.warehouseInvalidProductIds && el.warehouseInvalidProductIds.length > 0) {
        return 'text-warning'
      }
    }
    if (param == 'journalsCreatedAt' && el.journalsCreatedAt) {
      if (el.journalsBookedAt) {
        return 'text-primary';
      }

      if (el.journalsFinishedAt) {
        return 'text-warning';
      }

      if (el.journalsCreatedAt) {
        return 'text-info';
      }
    }
    if (el[param]) {
      return 'text-primary';
    }
    return 'text-danger'
  }

  getShiftCompletionTooltip (param, el) {
    if (param == 'warehouseApprovedAt') {
      if (el.warehouse_id == null) {
        return ''
      } else if (el.warehouseInvalidProductIds && el.warehouseInvalidProductIds.length > 0) {
        return this.component.$t('shift.warehouseInvalidProductIds');
      }
    }
    if (param == 'journalsCreatedAt' && el.journalsBookedAt) {
      return this.component.$t('shift.journalsBookedAt') + ': ' + this.component.$options.filters.formatDate(el.journalsBookedAt, this.component.$t('general.dateTimeFormat'))
    }
    if (param == 'journalsCreatedAt' && el.journalsFinishedAt) {
      return this.component.$t('shift.journalsFinishedAt') + ': ' + this.component.$options.filters.formatDate(el.journalsFinishedAt, this.component.$t('general.dateTimeFormat'))
    }
    return this.component.$t('shift.' + param) + ': ' + this.component.$options.filters.formatDate(el[param], this.component.$t('general.dateTimeFormat'))
  }

  validShiftDateInterval (shift) {
    if ((shift == undefined || Object.keys(shift).length === 0) || shift.isValidDateTimePeriod) {
      return true;
    }
    return false;
  }

  getCargoStatusChangeOptions (toStatusCodes, isSale, productcount, linecount, localcode, cargoview = false, currentStatusId = null) {
    const options = [];
    let currentStatusCode = null;
    if (currentStatusId) {
      currentStatusCode = this.component.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'id', currentStatusId, 'code');
    }
    toStatusCodes.forEach(status => {
      const color = this.component.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'code', status, 'color');
      let addOption = true;
      if (cargoview) {
        if (status == 'FINISHED') {
          addOption = false;
        }
      }
      if (isSale) {
        if (status == 'PLANNED' && productcount == 0) {
          addOption = false;
        }
        if (status == 'ASSEMBLY' && linecount == 0) {
          addOption = false;
        }
      }
      if (currentStatusCode == status) {
        addOption = false;
      }
      if (addOption) {
        let statusTrans = '.' + status
        if (this.component.$te('cargo.actions.toStatusCustom.' + currentStatusCode + '_' + status)) {
          statusTrans = 'Custom.' + currentStatusCode + '_' + status;
        }
        options.push({
          tostatus: status,
          title: this.component.$t('cargo.actions.toStatus' + statusTrans),
          class: 'btn btn-outline-dark me-3 fw-bold',
          style: 'background-color:' + color + ';color:' + this.component.BasicHelper.getContrastColor(color),
          message: this.component.$t('cargo.actions.toStatusMessage' + statusTrans, { localcode: localcode }),
          recheck: !(status == 'PLANNED' || status == 'TEMPLATE'),
          callSave: !(status == 'TEMPLATE' || status == 'CANCELED')
        })
      }
    });
    return options;
  }

  getOrdertype (ordertype) {
    const parts = ordertype.split(';');
    let match = this.component.$store.getters.getDataFromConfigWithFilter('order_cargo_types', 'code', parts[0]);
    if (!match) {
      match = this.component.$store.getters.getDataFromConfigWithFilter('order_cargo_types', 'code', 'IMP');
    }
    return match
  }

  updateDynamicRoutes (router, defaultSiteId = null) {
    let refresh = false;
    const currentRoutes = router.getRoutes();
    const availableTesterCategories = JSON.parse(localStorage.getItem('availableTesterCategories'));
    if (availableTesterCategories != null && availableTesterCategories.length > 0) {
      const findTesterProfileRoute = router.options.routes.find(el => el.path == '/testerProfile')
      if (findTesterProfileRoute) {
        const keepRoutes = [];
        const routeIndex = router.options.routes.indexOf(findTesterProfileRoute);
        availableTesterCategories.forEach(function callback (value, index) {
          const routeData = {
            path: '/testerProfile/' + value.id.toString(),
            name: value.name,
            component: ClearPage,
            query: { id: value.id },
            title: value.name.toString(),
            redirect: { name: value.name.toString() },
            meta: {
              public: false,
              noSession: false,
              isMenuItem: true,
              permissions: null,
              isConfigPage: false,
              dynamicChildrenComponents: 'TesterProfileSubMenu'
            },
            children: [
              {
                path: '',
                name: value.name.toString(),
                component: TesterWorkPlace,
                meta: { public: false, noSession: false, isMenuItem: false, permissions: null, parentPath: '/testerProfile/' + value.id.toString(), dynamicChildrenComponents: 'TesterProfileSubMenu' }
              },
              {
                path: 'view/:id',
                name: 'menu.quality.productQualityTestEdit' + value.id.toString(),
                component: QualityTestEdit,
                meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-quality-tests'], parentPath: '/testerProfile/' + value.id.toString(), isConfigPage: false, dynamicChildrenComponents: 'TesterProfileSubMenu' }
              },
              {
                path: 'overView/:id',
                name: 'menu.quality.productQualityTestOverView' + value.id.toString(),
                component: ProductQualityTestOverView,
                meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-quality-tests'], parentPath: '/testerProfile/' + value.id.toString(), isConfigPage: false }
              }
            ]
          }
          if (!currentRoutes.find(el => el.name == value.name.toString())) {
            router.addRoute(routeData);
            refresh = true;
          }
          keepRoutes.push(routeData);
        })
        router.options.routes[routeIndex].children = keepRoutes;
      }
    }
    if (defaultSiteId) {
      refresh = true;
      const warehouseSKURoute = router.options.routes.find(el => el.path === '/sku-layout');
      if (warehouseSKURoute) {
        const keepRoutes = [];
        const warehouses = this.component.$store.getters.getItemsFromConfigWithFilter('warehouses');
        const filteredWarehouses = [];
        if (warehouses) {
          Object.values(warehouses).forEach(warehouse => {
            if (warehouse.rows && (parseInt(warehouse.site_id) === parseInt(defaultSiteId))) {
              filteredWarehouses.push(warehouse);
            }
          });
        }
        const routeIndex = router.options.routes.indexOf(warehouseSKURoute);
        filteredWarehouses.forEach(warehouse => {
          const routeName = 'skulayout-' + warehouse.id.toString();
          const routeData = {
            path: '/sku-layout/' + warehouse.id.toString(),
            name: routeName,
            title: this.component.BasicHelper.getTranslation(warehouse.name),
            component: WarehouseLayout,
            meta: {
              public: false,
              noSession: false,
              isMenuItem: true,
              permissions: null,
              parentPath: '/sku-layout',
              isConfigPage: false
            }
          }
          if (!currentRoutes.find(el => el.name == routeName)) {
            router.addRoute(routeData);
          }
          keepRoutes.push(routeData);
        });
        router.options.routes[routeIndex].children = keepRoutes;
      }
    }
    return refresh;
  }
}

export default new PetHelper()
