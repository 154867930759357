import { mapState } from 'vuex';
import OrderProductList from '@/components/pet/sales/order/productlist/OrderProductList.vue'

const defaultFilterValues = {
  localcode: '',
  code: '',
  status: ['TEMPLATE', 'PLANNED', 'ACTIVE', 'APPROVED', 'CLOSED'],
  vendor: '',
  customer: '',
  plannedDeliveryMonth: null,
  deliveryDate: null,
  cargoStatuses: false,
  withoutvendor: false,
  clientcode: '',
  vendorId: null,
  customerId: null,
  ordercargotype: []
};

export default {
  name: 'OrderList',
  components: {
    OrderProductList
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: null,
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false
    };
  },
  props: {
    vendorId: {
      type: Number,
      default: null
    },
    customerId: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.user
    }),
    defaultFilterValues () {
      const values = this.BasicHelper.cloneObject(defaultFilterValues);
      return values;
    },
    ordertypes () {
      if (this.vendorId) {
        return 'IMP;IMP-R'
      }
      if (this.customerId) {
        return 'EXP';
      }
      if (this.$route.meta.ordertype) {
        return this.$route.meta.ordertype
      }
      return '';
    },
    primaryOrderType () {
      return this.PetHelper.getOrdertype(this.ordertypes);
    },
    isIncoming () {
      return (this.primaryOrderType && this.primaryOrderType.isIncoming);
    },
    basePerm () {
      return (this.primaryOrderType ? this.primaryOrderType.transbase : 'purchase');
    },
    basePathComp () {
      return 'orders';
    },
    baseTransParam () {
      return (this.primaryOrderType ? this.primaryOrderType.transbase : 'purchase') + '-order';
    },
    tableHeaders () {
      const headers = [];
      headers.push({ key: 'status_id', label: '', sortable: false, type: 'slot', slotcode: 'statuscolor', classTd: 'position-relative p-0 w-color' })
      headers.push({ key: 'localcode', label: this.$t(this.baseTransParam + '.localcode'), filterKey: 'localcode' });
      headers.push({ key: 'clientcode', label: this.$t(this.baseTransParam + '.clientcode'), filterKey: 'clientcode' });
      if (this.basePerm == 'sales') {
        headers.push({ key: 'customer.name', label: this.$t(this.baseTransParam + '.customer_id'), filterKey: 'customer' });
      } else if (this.basePerm == 'purchase') {
        headers.push({ key: 'vendor.name', label: this.$t(this.baseTransParam + '.vendor_id'), filterKey: 'vendor' });
      } else {
        headers.push({ key: 'local_warehouse_id', label: this.$t(this.baseTransParam + '.local_warehouse_id'), format: 'formatFromConfig', configKey: 'warehouses', configParam: 'code' });
        headers.push({ key: 'to_warehouse_id', label: this.$t(this.baseTransParam + '.to_warehouse_id'), format: 'formatFromConfig', configKey: 'warehouses', configParam: 'code' });
      }
      headers.push({ key: 'plannedDeliveryMonth', label: this.$t(this.baseTransParam + '.orderMonth'), filterKey: 'plannedDeliveryMonth', type: 'slot', slotcode: 'plannedDeliveryMonth' });
      headers.push({ key: 'plannedWeight', label: this.$t(this.baseTransParam + '.plannedWeight'), format: 'formatDecimal', precision: 0 });
      headers.push({ key: 'weight', label: this.$t(this.baseTransParam + '.weight'), format: 'formatDecimal', precision: 0, filterKey: 'weightUntil' });
      headers.push({ key: 'delta', label: this.$t(this.baseTransParam + '.delta'), type: 'slot', slotcode: 'delta' });
      headers.push({ key: 'deliveryDate', label: this.$t(this.baseTransParam + '.deliveryDate'), filterKey: 'deliveryDate', type: 'slot', slotcode: 'deliveryDate' });

      const actions = [];
      if (this.canEdit) {
        actions.push({ label: this.$t(this.baseTransParam + '.actions.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-sm btn-primary' });
      }
      if (this.canDelete) {
        actions.push({ label: this.$t(this.baseTransParam + '.actions.cancel'), emit: 'cancelItem', bicon: 'trash', class: 'btn-sm btn-danger', ifconfig: { param: 'canCancel', value: true } });
        actions.push({ label: this.$t(this.baseTransParam + '.actions.restore'), emit: 'restoreItem', bicon: 'clock-history', class: 'btn-sm btn-warning', ifconfig: { useFunc: true, func: this.canRestoreOrder } });
      }

      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10 text-nowrap' });
      return headers;
    },
    tableExpandConfig () {
      return { component: 'slot' };
    },
    tableFilterConfig () {
      const filters = { };
      if (this.orderTypeOptions.length > 1) {
        filters.ordercargotype = { type: 'checkbox', label: this.$t(this.baseTransParam + '.order_cargo_type_id'), options: this.orderTypeOptions, valueField: 'code' };
      }
      filters.status = { type: 'checkbox', label: this.$t(this.baseTransParam + '.status_id'), options: this.statusOptionsUsed, class: 'col-md-6 col-sm-12', textField: 'name', textTranslate: true };
      filters.localcode = { type: 'text', label: '', colOnlyFilter: true };
      filters.clientcode = { type: 'text', label: '', colOnlyFilter: true };
      filters.vendor = { type: 'text', label: '', colOnlyFilter: true };
      filters.customer = { type: 'text', label: '', colOnlyFilter: true };
      filters.plannedDeliveryMonth = { type: 'date', label: '', colOnlyFilter: true, justMonth: true };
      filters.deliveryDate = { type: 'date-range', label: '', class: 'col-md-6 col-sm-12', colOnlyFilter: true };
      filters.deliveryType = { type: 'checkbox', label: this.$t(this.baseTransParam + '.delivery_type_id'), class: 'col-md-6 col-sm-12', options: this.deliveryTypeOptions };
      filters.productTypes = { type: 'checkbox', label: this.$t(this.baseTransParam + '.product_type_id'), options: this.productTypes, class: 'col-md-10 col-sm-12' };
      if (this.isIncoming) {
        filters.withoutvendor = { type: 'checkbox-single', label: this.$t(this.baseTransParam + '.withoutvendor'), class: 'col-md-2 col-sm-12 mt-3', inlineLabel: true };
      }
      return filters;
    },
    canDelete () {
      return this.ApiRequest.userHasPermission(`edit-${this.basePerm}`);
    },
    canEdit () {
      return this.ApiRequest.userHasPermission(`edit-${this.basePerm}`);
    },
    canAdd () {
      return this.canEdit;
    },
    statusOptions () {
      const options = Object.values(this.$store.getters.getItemsFromConfigWithFilter('order_statuses', null, null));
      options.forEach(el => {
        el.colorCheckbox = true;
        el.labelSpanClass = 'rounded ps-1 pe-1';
      })
      return options;
    },
    statusOptionsUsed () {
      return this.statusOptions;
    },
    tableActions () {
      const actions = { download: false };
      if (this.canAdd) {
        actions.add = { title: this.$t('general.actions.add') };
      }
      return actions;
    },
    deliveryTypeOptions () {
      const options = this.$store.getters.getConfigOptions('delivery_types', this.$i18n.locale);
      options.push({ code: 'null', text: '-' })
      return options;
    },
    productTypes () {
      return this.PetHelper.getProductTypes().filter(el => (el.inAxapta && (el.isBought || el.isChemical)));
    },
    statusIdCanceled () {
      return this.$store.getters.getParamFromConfigByFilter('order_statuses', 'code', 'CANCELED', 'id')
    },
    orderTypeOptions () {
      const options = this.$store.getters.getConfigOptions('order_cargo_types', this.$i18n.locale);
      if (this.ordertypes) {
        const codes = this.ordertypes.split(';');
        return options.filter(el => {
          return codes.includes(el.code);
        });
      }
      return options;
    }
  },
  methods: {
    currencyCode (currencyId) {
      let currency = null;
      if (currencyId) {
        currency = this.$store.getters.getDataFromConfigById('currencies', currencyId).code;
      }
      return currency;
    },
    getTableData () {
      if (this.basePathComp) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        if (this.vendorId != null) this.filterValues.vendorId = this.vendorId
        if (this.customerId != null) this.filterValues.customerId = this.customerId;
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        this.loading = true;
        const usedFilters = this.BasicHelper.cloneObject(this.filterValues)
        if (this.ordertypes && usedFilters.ordercargotype.length < 1) {
          usedFilters.ordercargotype = this.ordertypes.split(';');
        }
        let path = this.ApiRequest.addGetParamsToPath(this.basePathComp + '/list?perPage=' + this.paging.limit + '&page=' + this.paging.page, usedFilters, true);
        if (this.tableSorting && this.tableSorting.item) {
          path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
        }
        this.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.tableItems = response.data;
            this.paging = response.paging;
            this.loading = false;
          }
        });
      }
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(this.defaultFilterValues);
      this.getTableData();
    },
    editItem (el, newtab = 0) {
      const queryparams = {};
      if (!el) {
        if (this.vendorId) {
          queryparams.vendor_id = this.vendorId;
        }
        if (this.customerId) {
          queryparams.customer_id = this.customerId;
        }
      }
      if (newtab) {
        const routeData = this.$router.resolve({ name: 'menu.warehouse.' + this.baseTransParam + 's.view', params: { code: (el ? el.localcode : 'NEW') }, query: queryparams });
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push({ name: 'menu.warehouse.' + this.baseTransParam + 's.view', params: { code: (el ? el.localcode : 'NEW') }, query: queryparams });
      }
    },
    viewItem (el) {
      this.$refs.itemViewFrm.show(el, true);
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    },
    getStatusColor (statusId) {
      const status = this.statusOptions.find(el => el.id == statusId);
      if (status) {
        return status.color;
      }
      return '#FFF';
    },
    canRestoreOrder (el) {
      return (el && el.status_id == this.statusIdCanceled);
    },
    cancelItem (el, approved) {
      if (approved) {
        this.changeStatus(el, 'CANCELED');
      } else {
        this.$refs.itemCancelMsgBox.show(el, this.$t(this.baseTransParam + '.actions.cancelTitle'), this.$t(this.baseTransParam + '.actions.cancelMessage', { localcode: el.localcode }))
      }
    },
    restoreItem (el) {
      this.changeStatus(el, 'TEMPLATE');
    },
    getMonthName (date) {
      const event = new Date(date);
      const options = { month: 'long' };
      return event.toLocaleDateString(this.$i18n.locale, options).toUpperCase()
    },
    getMonthNameColor (date) {
      const plannedDate = new Date(date);
      const currentDate = new Date();
      let colorClass = '';
      if (plannedDate.getMonth() == currentDate.getMonth() && plannedDate.getYear() == currentDate.getYear()) {
        colorClass = ' badge badge-secondary bg-success border border-success text-white ';
      } else if ((plannedDate.getMonth() > currentDate.getMonth() && plannedDate.getYear() == currentDate.getYear()) || (plannedDate.getYear() > currentDate.getYear())) {
        colorClass = ' badge badge-secondary bg-warning border border-warning text-white ';
      }
      return colorClass;
    },
    changeStatus (el, status, open = false) {
      this.loading = true;
      this.ApiRequest.request(this.basePathComp + '/change-status/' + el.id, this.ApiRequest.REQUEST_POST, { tostatus: status }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        } else {
          if (open) {
            this.editItem(el);
          }
          this.getTableData();
        }
      });
    },
    getStatusName (statusId) {
      return this.BasicHelper.getConfigValue('order_statuses', 'id', statusId, 'name');
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.cloneObject(this.defaultFilterValues);
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.getTableData();
    this.isMounted = true;
  },
  watch: {
    basePathComp: {
      immediate: true,
      handler (val) {
        if (val) {
          this.isMounted = false;
          this.filterValues = this.BasicHelper.cloneObject(this.defaultFilterValues);
          this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
          this.getTableData();
          this.isMounted = true;
        }
      }
    },
    '$route' () {
      this.filterValues = this.BasicHelper.cloneObject(this.defaultFilterValues);
      this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
      this.getTableData()
    }
  }
}
