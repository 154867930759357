import PetHelper from '@/helpers/PetHelper';

const defaultFilterValues = {
  isSale: []
};

export default {
  props: {
    productId: {
      type: Number,
      default: null
    },
    customerId: {
      type: Number,
      default: null
    },
    vendorId: {
      type: Number,
      default: null
    }
  },
  name: 'RatesList',
  components: {},
  data () {
    return {
      loading: true,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      paging: { limit: 20, page: 1, total: 0, pages: 0 }
    }
  },
  computed: {
    basePathComp () {
      return 'rates'
    },
    basePerm () {
      return 'rates';
    },
    baseTransParam () {
      return 'product.rates';
    },
    requestPath () {
      let path = this.ApiRequest.addGetParamsToPath(this.basePathComp + '/list/' + (this.productId) + '?perPage=' + this.paging.limit + '&page=' + this.paging.page, this.filterValues, true);
      if (this.vendorId) {
        path += '&vendor_id=' + this.vendorId;
      }
      if (this.customerId) {
        path += '&customer_id=' + this.customerId;
      }
      return path;
    },
    tableHeaders () {
      const headers = [
        { key: 'startAt', label: this.$t('product.rates.startAt'), sortable: false, type: 'date' },
        { key: 'endAt', label: this.$t('product.rates.endAt'), sortable: false, type: 'date' },
        { key: 'rate', label: this.$t('product.rate', { currency: PetHelper.getCurrencyNameFromCode() }), sortable: false },
        { key: 'measure_type_id', label: this.$t(this.baseTransParam + '.measure_type_id'), format: 'formatFromConfig', configKey: 'measure_types', configParam: 'name' },
        { key: 'isActive', label: this.$t('product.rates.active'), sortable: false, format: 'formatYesNo' }
      ];
      if (!(this.customerId || this.vendorId)) {
        headers.push({ key: 'isSale', label: this.$t('product.rates.isSale'), sortable: false, format: 'formatYesNo', filterKey: 'isSale' });
      } else {
        headers.push({ key: 'contractNo', label: this.$t('product.rates.contractNo') });
      }
      const actions = [];
      if (this.canEdit) {
        actions.push({ label: this.$t('general.actions.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-primary btn-sm' });
      }
      if (this.canDelete) {
        actions.push({ label: this.$t('general.actions.delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-sm btn-danger', ifconfig: { param: 'deleted_at', value: null } });
        actions.push({ label: this.$t('general.actions.enable'), emit: 'restoreItem', bicon: 'clock-history', class: 'btn-sm btn-warning', ifconfig: { param: 'deleted_at', value: null, notCheck: true } });
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    tableActions () {
      return {
        add: { title: this.$t('product.actions.addRate'), classTd: 'w-10' }
      };
    },
    canDelete () {
      return this.ApiRequest.userHasPermission('delete-' + this.basePerm);
    },
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-' + this.basePerm);
    },
    yesNoOptions () {
      return this.BasicHelper.yesNoOptions();
    },
    tableFilterConfig () {
      const filters = { };
      filters.isSale = { type: 'checkbox', label: '', colOnlyFilter: true, options: this.yesNoOptions };
      return filters;
    }
  },
  methods: {
    getTableData () {
      if (this.productId) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        this.loading = true;
        this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.tableItems = response.data;
            this.paging = response.paging;
            this.loading = false;
          }
        });
      }
    },
    deleteItem (el, approved) {
      if (approved) {
        this.setDeleted(el, 1);
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t(this.baseTransParam + '.actions.deleteTitle'), this.$t(this.baseTransParam + '.actions.deleteMessage', { name: this.BasicHelper.getTranslation(el.name), code: el.code }))
      }
    },
    setDeleted (el, isDeleted) {
      this.loading = true;
      this.ApiRequest.request(this.basePathComp + '/deleted', this.ApiRequest.REQUEST_POST, { id: el.id, code: el.code, deleted: isDeleted }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        } else {
          this.$emit('refresh', response.data)
          this.getTableData();
        }
      });
    },
    addItem () {
      this.$emit('addItem');
    },
    editItem (row) {
      this.$emit('editItem', row);
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    }
  },
  mounted () {
    this.getTableData();
  },
  watch: {
    productId: {
      immediate: true,
      handler (value) {
        this.getTableData();
      }
    }
  }
}
