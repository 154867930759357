var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"sales-order-line-list"},[(_vm.editFactoryProducts)?_c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-3"},[_c('b-form-checkbox',{on:{"input":_vm.focusSearch},model:{value:(_vm.addFactoryProducts),callback:function ($$v) {_vm.addFactoryProducts=$$v},expression:"addFactoryProducts"}},[_c('LabelTooltip',{attrs:{"code":"cargo.factoryproduct.add","componentName":"span"}})],1)],1),_c('div',{staticClass:"col-9"},[(_vm.addFactoryProducts)?[_c('LabelTooltip',{attrs:{"code":"factoryproduct.search"}}),_c('FactoryProductPicker',{ref:"factoryproductpicker",attrs:{"filterValues":_vm.factoryPickerFilters,"deviceIds":_vm.qrReaderDeviceIds,"autoPublishQR":false,"withQuality":true,"findBySpecificCode":_vm.findBySpecificCode,"specificQuantity":_vm.specificQuantity,"poproducts":_vm.poproducts},on:{"input":_vm.productAdded},model:{value:(_vm.addedProduct),callback:function ($$v) {_vm.addedProduct=$$v},expression:"addedProduct"}})]:_vm._e()],2)]):_vm._e(),_c('Tableview',{ref:"tableview",attrs:{"fields":_vm.tableHeaders,"loading":_vm.loading,"items":_vm.solines,"tableClass":"table-striped table-sm table-hover","expandableConfig":_vm.tableExpandConfig},on:{"saved":_vm.getTableData,"deleteItem":_vm.deleteLine,"toggleEditMode":_vm.toggleEditMode,"reserveProduct":_vm.reserveProduct},scopedSlots:_vm._u([{key:"product",fn:function(ref){
var data = ref.data;
return [(_vm.canEdit && _vm.solines[data.index].editmode && (_vm.editProduct || _vm.solines[data.index].id == null))?_c('SelectAutocomplete',{attrs:{"options":_vm.productOptions,"filters":_vm.productFilters,"isInvalid":(_vm.validator && _vm.validator[data.index].product_id.length ? true : false)},on:{"input":function($event){return _vm.productChanged($event, data.index)}},model:{value:(_vm.solines[data.index].product_id),callback:function ($$v) {_vm.$set(_vm.solines[data.index], "product_id", $$v)},expression:"solines[data.index].product_id"}}):[_vm._v(" "+_vm._s(_vm.BasicHelper.getConfigValue('products', 'id', data.item.product_id, 'name'))+" ")]]}},{key:"quality",fn:function(ref){
var data = ref.data;
return [(_vm.canEditSales && _vm.solines[data.index].editmode)?_c('b-form-select',{attrs:{"options":_vm.qualityOptions},model:{value:(_vm.solines[data.index].quality_id),callback:function ($$v) {_vm.$set(_vm.solines[data.index], "quality_id", $$v)},expression:"solines[data.index].quality_id"}}):[_vm._v(" "+_vm._s(_vm.BasicHelper.getConfigValue('product_qualities', 'id', data.item.quality_id, 'name'))+" ")]]}},{key:"quality_code",fn:function(ref){
var data = ref.data;
return [(_vm.canEditSales && _vm.solines[data.index].editmode)?_c('AdvancedSelect',{attrs:{"items":_vm.getLineQualityCodeOptions(_vm.solines[data.index]),"value-field":"value","text-field":"text","disabled":!_vm.solines[data.index].product_id},model:{value:(_vm.solines[data.index].quality_code_id),callback:function ($$v) {_vm.$set(_vm.solines[data.index], "quality_code_id", $$v)},expression:"solines[data.index].quality_code_id"}}):[_vm._v(" "+_vm._s(_vm.BasicHelper.getConfigValue('product_qualities_code', 'id', data.item.quality_code_id, 'name'))+" ")]]}},{key:"pieces",fn:function(ref){
var data = ref.data;
return [(_vm.canEdit && _vm.solines[data.index].editmode)?_c('NumberInput',{attrs:{"precision":0,"minValue":0,"maxlength":10,"controlClass":(_vm.validator && _vm.validator[data.index].pieces.length ? 'is-invalid' : '')},model:{value:(_vm.solines[data.index].pieces),callback:function ($$v) {_vm.$set(_vm.solines[data.index], "pieces", $$v)},expression:"solines[data.index].pieces"}}):[(!_vm.checkReservationCondition(data.item))?_c('span',[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.item.assembledPieces, 0, _vm.$i18n.locale))+" / "+_vm._s(_vm.BasicHelper.formatDecimal(data.value, 0, _vm.$i18n.locale))+" ")]):_c('span',[_vm._v("0")])]]}},{key:"quantity",fn:function(ref){
var data = ref.data;
return [(_vm.canEdit && _vm.solines[data.index].editmode)?_c('NumberInput',{attrs:{"precision":3,"minValue":0,"maxlength":10,"controlClass":(_vm.validator && _vm.validator[data.index].quantity.length ? 'is-invalid' : '')},model:{value:(_vm.solines[data.index].quantity),callback:function ($$v) {_vm.$set(_vm.solines[data.index], "quantity", $$v)},expression:"solines[data.index].quantity"}}):[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.item.assembledQuantity, 0, _vm.$i18n.locale))+" / "+_vm._s(_vm.BasicHelper.formatDecimal(data.value, 0, _vm.$i18n.locale))+" ")]]}},{key:"reserveProduct",fn:function(ref){
var data = ref.data;
return [(_vm.checkReservationCondition(data.item))?_c('button',{staticClass:"btn btn-warning",attrs:{"type":"button"},on:{"click":function($event){return _vm.reserveProduct(data.item)}}},[_vm._v(_vm._s(_vm.$t('cargo.factoryproduct.reserveProduct')))]):_vm._e()]}},{key:"rate",fn:function(ref){
var data = ref.data;
return [_c('span',[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.item.activeRate, 6, _vm.$i18n.locale))+" ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t('general.currencyrate', { currency: _vm.PetHelper.getCurrencyNameFromCode(), measure: _vm.PetHelper.getProductConfigName(data.item.product_id, 'default_measure_type_id') }))+" ")]),(data.item.contractNo)?_c('span',[_vm._v(" ("+_vm._s(_vm.$t('product.rates.contractNoInfo', { contractNo: data.item.contractNo }))+") ")]):_vm._e()]}},{key:"expandrow",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"p-2"},[_c('SalesOrderFactoryProducts',{attrs:{"data":data.factoryproducts,"editFactoryProducts":_vm.editFactoryProducts,"specificQuantity":_vm.specificQuantity,"orderId":_vm.orderId,"lineId":data.id,"cargo_id":_vm.cargo_id},on:{"assembledChanged":function($event){return _vm.assembledChanged($event, data.id)}}})],1)]}},{key:"table-footer",fn:function(){return [_c('tr',{staticClass:"fw-bold border-dark border-5 border-bottom-0 border-end-0 border-start-0"},[_c('td',{staticClass:"text-end",attrs:{"colspan":_vm.tableHeaders.length - 3 - (_vm.displayRate ? 1 : 0)}},[_vm._v(" "+_vm._s(_vm.$t('general.total'))+": ")]),_c('td',[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(_vm.totalInfo.assembledPieces, 0, _vm.$i18n.locale))+" / "+_vm._s(_vm.BasicHelper.formatDecimal(_vm.totalInfo.pieces, 0, _vm.$i18n.locale))+" ")]),_c('td',{attrs:{"colspan":(_vm.displayRate ? 3 : 2)}},[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(_vm.totalInfo.assembledQuantity, 0, _vm.$i18n.locale))+" / "+_vm._s(_vm.BasicHelper.formatDecimal(_vm.totalInfo.quantity, 0, _vm.$i18n.locale))+" ")])])]},proxy:true}])}),(_vm.canEdit && _vm.editProduct)?_c('div',{staticClass:"float-start me-2"},[_c('span',{staticClass:"btn btn-primary",on:{"click":_vm.addLine}},[_c('i',{staticClass:"bi bi-plus"})])]):_vm._e(),(_vm.canPrintLabel)?_c('div',{on:{"click":function($event){return _vm.$emit('printLabelForm')}}},[_c('span',{staticClass:"btn btn-secondary "},[_c('i',{staticClass:"bi bi-printer"}),_vm._v(" "+_vm._s(_vm.$t('cargo.actions.printLabel'))+" ")])]):_vm._e(),_c('ReserveProduct',{ref:"reserveProduct",on:{"reserveProduct":_vm.reserveProductSave}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }