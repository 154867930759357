import ShiftEdit from '@/components/pet/shift/edit/ShiftEdit.vue'
import UserWorklist from '@/components/pet/user/worklist/UserWorklist.vue'
import ShiftProductionJournalList from '@/components/pet/shift/productionjournal/ShiftProductionJournalList.vue'
import RecipeEdit from '@/components/pet/shift/recipeedit/RecipeEdit.vue';
import ShiftFlowProduced from '@/components/pet/shift/recipeedit/shiftFlowProduced/ShiftFlowProduced.vue';

const defaultFilterValues = {
  stardate: null,
  type_id: null,
  line_id: null,
  isMaintenance: null
};

export default {
  name: 'ShiftList',
  components: {
    ShiftFlowProduced,
    RecipeEdit,
    ShiftEdit,
    UserWorklist,
    ShiftProductionJournalList
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { item: 'startAt', direction: 'desc' },
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false,
      editRecipesTabOptions: [],
      shiftCode: null,
      hasOnlyOneRecipe: false,
      recipeId: null
    };
  },
  computed: {
    basePathComp () {
      return 'shift'
    },
    basePerm () {
      return 'all-shifts';
    },
    baseTransParam () {
      return 'shift';
    },
    tableHeaders () {
      const headers = [
        { key: 'startdate', label: this.$t(this.baseTransParam + '.startdate'), sortable: false, type: 'date', filterKey: 'startdate', headerClass: 'w-15' },
        { key: 'type_id', label: this.$t(this.baseTransParam + '.type_id'), sortable: false, format: 'formatFromConfig', configKey: 'shift_types', configParam: 'name', filterKey: 'type', headerClass: 'w-15' },
        { key: 'line_id', label: this.$t(this.baseTransParam + '.line_id'), sortable: false, format: 'formatFromConfig', configKey: 'lines', configParam: 'name', headerClass: 'w-10' },
        { key: 'brigade.name.' + this.$i18n.locale, label: this.$t(this.baseTransParam + '.brigade_id'), headerClass: 'w-10' },
        { key: 'shiftusers.length', label: this.$t('brigade.usercount'), sortable: false, format: 'formatDecimal', precision: 0, headerClass: 'w-5' },
        { key: 'completion', label: this.$t(this.baseTransParam + '.completion'), type: 'slot', slotcode: 'shiftcompletion', headerClass: 'w-15' },
        { key: 'closed_at', label: this.$t(this.baseTransParam + '.isClosed'), type: 'slot', slotcode: 'shiftclosed', headerClass: 'w-5' }
      ];
      const actions = [];
      if (this.ApiRequest.userHasPermission('book-shift-journals') && this.$store.getters.isFeatureEnabled('d365_api', 'production.book')) {
        actions.push({ label: this.$t(this.baseTransParam + '.actions.bookJournals'), emit: 'bookJournals', bicon: 'journal-bookmark-fill', class: 'btn-sm btn-warning', ifconfig: { useFunc: true, func: this.canBookShiftJournals } });
      }
      if (this.ApiRequest.userHasPermission('generate-shift-journals')) {
        if (this.$store.getters.isFeatureEnabled('d365_api', 'production.edit')) {
          actions.push({ label: this.$t(this.baseTransParam + '.actions.finishJournal'), emit: 'finishJournal', bicon: 'journal-check', class: 'btn-sm btn-dangerinfo', ifconfig: { useFunc: true, func: this.canFinishJournals } });
          actions.push({ label: this.$t(this.baseTransParam + '.actions.sendJournals'), emit: 'sendJournals', bicon: 'journal-arrow-up', class: 'btn-sm btn-success', ifconfig: { useFunc: true, func: this.canSendShiftJournals } });
        }
        actions.push({ label: this.$t(this.baseTransParam + '.actions.generateJournals'), emit: 'generateJournals', bicon: 'journal-text', class: 'btn-sm btn-info', ifconfig: { useFunc: true, func: this.canGenerateShiftJournals } });
      }
      if (this.canEdit) {
        actions.push({ label: this.$t('recipe.actions.edit'), emit: 'editRecipes', bicon: 'bi bi-layers-half', class: 'btn-sm btn-primary', ifconfig: { useFunc: true, func: this.hasRecipes } });
        actions.push({ label: this.$t('shift.actions.reopen'), emit: 'reopenShift', bicon: 'bi bi-unlock', class: 'btn-sm btn-primary', ifconfig: { useFunc: true, func: this.canBeReopened } });
      }
      if (this.canForceClose) {
        actions.push({ label: this.$t(this.baseTransParam + '.actions.forceClose'), emit: 'forceClose', bicon: 'sign-stop', class: 'btn-sm btn-danger', ifconfig: { param: 'closedAt', value: null } });
      }
      if (this.canEdit) {
        actions.push({ label: this.$t('general.actions.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-sm btn-primary' });
      }
      if (this.canDelete) {
        actions.push({ label: this.$t('general.actions.delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-sm btn-danger', ifconfig: { param: 'deleted_at', value: null } });
      }
      headers.push({ key: 'actions', label: '', actions });
      return headers;
    },
    shiftCompletionItems () {
      return this.$store.getters.shiftCompletionItems;
    },
    tableExpandConfig () {
      return { component: 'slot' }
    },
    tableFilterConfig () {
      const filters = { };
      filters.startdate = { type: 'date-range', label: this.$t('general.filters.date'), colOnlyFilter: true };
      filters.type = { type: 'checkbox', label: this.$t(this.baseTransParam + '.type_id'), options: this.shiftTypeOptions, colOnlyFilter: true };
      filters.line = { type: 'checkbox', label: this.$t(this.baseTransParam + '.line_id'), options: this.lineOptions, colOnlyFilter: false, class: 'col-12' };
      filters.isMaintenance = { type: 'checkbox', label: this.$t(this.baseTransParam + '.isMaintenance'), options: this.yesNoOptions, colOnlyFilter: false };
      filters.journalsStatus = { type: 'checkbox', label: this.$t(this.baseTransParam + '.journalsStatus'), options: this.journalsStatusOptions, class: 'col-md-3 col-sm-12 col-lg-2', valueField: 'value' };
      return filters;
    },
    canForceClose () {
      return this.ApiRequest.userHasPermission('force-close-shift');
    },
    canDelete () {
      return this.ApiRequest.userHasPermission('delete-' + this.basePerm);
    },
    yesNoOptions () {
      return this.BasicHelper.yesNoOptions();
    },
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-' + this.basePerm);
    },
    requestPath () {
      let path = this.ApiRequest.addGetParamsToPath(this.basePathComp + '/list?perPage=' + this.paging.limit + '&page=' + this.paging.page, this.filterValues, true);
      if (this.tableSorting.item) {
        path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
      }
      return path;
    },
    tableActions () {
      const actions = { download: false };
      return actions;
    },
    lineOptions () {
      return this.$store.getters.getAvailableLineOptions(this.$i18n.locale);
    },
    shiftTypeOptions () {
      return this.$store.getters.getConfigOptions('shift_types', this.$i18n.locale);
    },
    journalsStatusOptions () {
      return [
        { value: 'NOT_CREATED', text: this.$t('shift.journalsNotCreated'), bicon: 'journal-text', colorCheckbox: true, color: '#DC3545FF' },
        { value: 'CREATED', text: this.$t('shift.journalsCreatedAt'), bicon: 'journal-text', colorCheckbox: true, color: '#0DCAF0' },
        { value: 'FINISHED', text: this.$t('shift.journalsFinishedAt'), bicon: 'journal-text', colorCheckbox: true, color: '#FFC107FF' },
        { value: 'BOOKED', text: this.$t('shift.journalsBookedAt'), bicon: 'journal-text', colorCheckbox: true, color: '#619403FF' }
      ]
    },
    tabOptions () {
      return [
        { code: 'shiftusers', text: this.$t('shift.tabs.users') },
        { code: 'productionjournals', text: this.$t('shift.tabs.productionjournals') },
        { code: 'shiftFlowProduced', text: this.$t('menu.shift.shiftFlowProduced') }
      ];
    }
  },
  methods: {
    getTableData () {
      if (this.basePathComp) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        this.loading = true;
        this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.tableItems = response.data;
            this.paging = response.paging;
            this.loading = false;
          }
        });
      }
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    editItem (el) {
      this.$refs.editElFrm.show(el);
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    },
    deleteItem (el, approved) {
      if (approved) {
        this.setDeleted(el, 1);
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t(this.baseTransParam + '.actions.deleteTitle'), this.$t(this.baseTransParam + '.actions.deleteMessage', { name: this.BasicHelper.getTranslation(el.name), code: el.code }))
      }
    },
    restoreItem (el) {
      this.setDeleted(el, 0);
    },
    forceClose (el, approved = 0) {
      if (approved) {
        this.loading = true;
        this.ApiRequest.request(this.basePathComp + '/close-force/' + el.id, this.ApiRequest.REQUEST_POST, {}, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
            this.loading = false;
          } else {
            this.getTableData();
          }
        });
      } else {
        this.$refs.itemForceCloseMsgBox.show(
          el,
          this.$t(this.baseTransParam + '.actions.forceCloseTitle'),
          this.$t(this.baseTransParam + '.actions.forceCloseMessage',
            { name: this.BasicHelper.getTranslation(el.name), code: el.code })
        )
      }
    },
    setDeleted (el, isDeleted) {
      this.loading = true;
      this.ApiRequest.request(this.basePathComp + '/deleted', this.ApiRequest.REQUEST_POST, { id: el.id, code: el.code, deleted: isDeleted }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        } else {
          this.getTableData();
        }
      });
    },
    reopenShift (el, approved = 0) {
      if (approved) {
        this.loading = true;
        this.ApiRequest.request(this.basePathComp + '/reopenShift', this.ApiRequest.REQUEST_POST, { id: el.id }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
            this.loading = false;
          } else {
            this.getTableData();
          }
        });
      } else {
        this.$refs.itemReopenShiftMsgBox.show(
          el,
          this.$t(this.baseTransParam + '.actions.forceReopenTitle'),
          this.$t(this.baseTransParam + '.actions.forceReopenMessage',
            { name: this.BasicHelper.getTranslation(el.name), code: el.code })
        )
      }
    },
    generateJournals (el) {
      this.journalActions('shift/generate-journals/' + el.id, {}, el)
    },
    sendJournals (el) {
      this.journalActions('shift/send-journals/' + el.id, {}, el)
    },
    finishJournal (el) {
      this.journalActions('shift/finish-journals/' + el.id, {}, el)
    },
    bookJournals (el) {
      this.journalActions('shift/book-journals/' + el.id, {}, el)
    },
    journalActions (path, reqData, el) {
      el.loading = true;
      this.$refs.tableview.$forceUpdate();
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, reqData, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else {
          const idx = this.tableItems.findIndex(subel => subel.id == el.id);
          if (idx >= 0) {
            this.tableItems[idx] = this.BasicHelper.cloneObject(response.data);
          }
        }
        el.loading = false;
        this.$refs.tableview.$forceUpdate();
      });
    },
    canBookShiftJournals (el) {
      return (el.canD365Send && el.journalsBookedAt === null && el.journalsFinishedAt !== null);
    },
    canFinishJournals (el) {
      return (el.canD365Send && el.productionjournals.length > 0 && el.journalsCreatedAt && el.journalsFinishedAt === null)
    },
    canGenerateShiftJournals (el) {
      return (el.canD365Send && el.journalsFinishedAt === null && el.closedAt !== null);
    },
    canSendShiftJournals (el) {
      return (el.canD365Send && el.productionjournals.length > 0 && el.journalsFinishedAt == null && el.journalsCreatedAt);
    },
    editRecipes (el) {
      this.editRecipesTabOptions = [];
      this.shiftCode = null;
      if (el.shift_flow_recipes.length === 1) {
        this.hasOnlyOneRecipe = true;
        this.recipeId = el.shift_flow_recipes[0].id;
      } else {
        this.hasOnlyOneRecipe = false;
        this.recipeId = null;
        for (const recipe of el.shift_flow_recipes) {
          this.editRecipesTabOptions.push({
            code: recipe.id,
            title: this.BasicHelper.getConfigValue('line_flows', 'id', recipe.flow_id, 'name', true)
          });
        }
      }
      this.shiftCode = el.shortname;
      this.$refs.editRecipes.show();
    },
    hasRecipes (el) {
      return (el.shift_flow_recipes && el.shift_flow_recipes.length > 0);
    },
    canBeReopened (el) {
      return el.canBeReopened == true && this.ApiRequest.userHasPermission('close-open-shift');
    },
    closeRecipesEdit () {
      this.$refs.editRecipes.hide();
    },
    getRecipesStatusIcon (status) {
      switch (status) {
        case 0: return 'text-tertiary';
        case 1: return 'text-danger';
        case 2: return 'text-warning';
        case 3: return 'text-primary';
      }
    },
    getRecipeStatusTooltip (status) {
      if (status === 0) {
        return '';
      }
      return this.$t('menu.shifts.recipes');
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.getTableData();
    this.isMounted = true;
  },
  watch: {
    basePathComp: {
      immediate: true,
      handler (val) {
        if (val) {
          this.isMounted = false;
          this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
          this.getTableData();
          this.isMounted = true;
        }
      }
    }
  }
}
