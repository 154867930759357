import DeviceTypeEdit from '@/components/pet/device/typeedit/DeviceTypeEdit.vue'

const defaultFilterValues = {
  code: '',
  name: '',
  deleted: ['0']
};

export default {
  name: 'DeviceTypeList',
  components: {
    DeviceTypeEdit
  },
  props: {
    editPerm: {
      type: String,
      default: 'edit-settings'
    },
    deletePerm: {
      type: String,
      default: 'delete-settings'
    },
    basePath: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { item: 'order', direction: 'asc' },
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false
    };
  },
  computed: {
    editPermComp () {
      if (this.$route.meta.configPermabase) {
        return 'edit-' + this.$route.meta.configPermabase;
      }
      return this.editPerm;
    },
    deletePermComp () {
      if (this.$route.meta.configPermabase) {
        return 'edit-' + this.$route.meta.configPermabase;
      }
      return this.editPerm;
    },
    basePathComp () {
      if (this.basePath == null && this.$route.meta.configpath) {
        return this.$route.meta.configpath;
      }
      return this.basePath
    },
    tableHeaders () {
      const headers = [
        { key: 'id', label: this.$t('general.item.id'), sortable: false },
        { key: 'name.' + this.$i18n.locale, label: this.$t('general.item.name'), sortable: false, filterKey: 'name' },
        { key: 'code', label: this.$t('general.item.code'), sortable: true, filterKey: 'code', classTd: 'w-10' },
        { key: 'order', label: this.$t('general.item.order'), sortable: true, classTd: 'w-10' },
        { key: 'isChemoData', label: this.$t('device-type.isChemoData'), format: 'formatYesNo', classTd: 'w-5' },
        { key: 'isResourceData', label: this.$t('device-type.isResourceData'), format: 'formatYesNo', classTd: 'w-5' },
        { key: 'isTechnoData', label: this.$t('device-type.isTechnoData'), format: 'formatYesNo', classTd: 'w-10' },
        { key: 'deleted', label: this.$t('general.item.deleted'), filterKey: 'deleted', format: 'formatYesNo', reverseMode: true, classTd: 'w-10' }
      ];
      const actions = [];
      if (this.canEdit) {
        actions.push({ label: this.$t('general.actions.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-sm btn-primary' });
      }
      if (this.canDelete) {
        actions.push({ label: this.$t('general.actions.delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-sm btn-danger', ifconfig: { param: 'deleted_at', value: null } });
        actions.push({ label: this.$t('general.actions.enable'), emit: 'restoreItem', bicon: 'clock-history', class: 'btn-sm btn-warning', ifconfig: { param: 'deleted_at', value: null, notCheck: true } });
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    tableExpandConfig () {
      return null;
    },
    tableFilterConfig () {
      const filters = { };
      filters.code = { type: 'text', label: '', colOnlyFilter: true };
      filters.name = { type: 'text', label: '', colOnlyFilter: true };
      filters.deleted = { type: 'checkbox', label: '', options: this.yesNoOptions, colOnlyFilter: true };
      return filters;
    },
    canDelete () {
      return this.ApiRequest.userHasPermission(this.deletePermComp);
    },
    canEdit () {
      return this.ApiRequest.userHasPermission(this.editPermComp);
    },
    yesNoOptions () {
      return this.BasicHelper.yesNoOptions();
    },
    requestPath () {
      let path = this.ApiRequest.addGetParamsToPath(this.basePathComp + '/list?perPage=' + this.paging.limit + '&page=' + this.paging.page, this.filterValues, true);
      if (this.tableSorting.item) {
        path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
      }
      return path;
    },
    tableActions () {
      const actions = { download: false };
      if (this.canEdit) {
        actions.add = { title: this.$t('general.actions.add') };
      }
      return actions;
    }
  },
  methods: {
    getTableData () {
      if (this.basePathComp) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        this.loading = true;
        const self = this;
        this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, function (response) {
          if (response.data) {
            self.tableItems = response.data;
            self.paging = response.paging;
            self.loading = false;
          }
        });
      }
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    editItem (el) {
      this.$refs.editElFrm.show(el);
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    },
    deleteItem (el, approved) {
      if (approved) {
        this.setDeleted(el, 1);
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t('general.item.deleteTitle'), this.$t('general.item.deleteMessage', { name: this.BasicHelper.getTranslation(el.name), code: el.code }))
      }
    },
    restoreItem (el) {
      this.setDeleted(el, 0);
    },
    setDeleted (el, isDeleted) {
      this.loading = true;
      const self = this;
      this.ApiRequest.request(this.basePathComp + '/deleted', this.ApiRequest.REQUEST_POST, { id: el.id, code: el.code, deleted: isDeleted }, function (response) {
        if (response.error) {
          self.ApiRequest.displayErrorDialog(response, self);
          self.loading = false;
        } else {
          self.getTableData();
        }
      });
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.getTableData();
    this.isMounted = true;
  },
  watch: {
    basePathComp: {
      immediate: true,
      handler (val) {
        if (val) {
          this.isMounted = false;
          this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
          this.getTableData();
          this.isMounted = true;
        }
      }
    }
  }
}
