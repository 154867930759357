import useVuelidate from '@vuelidate/core';
import QualityTestMeasurement from '@/components/pet/quality/test/edit/qualityTestMeasurement/QualityTestMeasurement.vue';
import QualityTestGroup from '@/components/pet/quality/test/edit/qualityTestGroup/QualityTestGroup.vue';
import { helpers, requiredIf } from '@vuelidate/validators';
import Gallery from 'ebg-vue-common/src/components/common/Gallery/Gallery.vue';
import { mapState } from 'vuex';
const defaultEl = {
  factory_product_id: null,
  cargo_id: null,
  productTests: [],
  validationEnabled: true,
  files: []
};
const defaultTest = {
  id: null,
  factory_product_id: null,
  value: null,
  quality_test_measurements: [],
  base_config_id: null,
  calculated_quality_id: null,
  set_quality_id: null,
  quality_code_id: null,
  sample_weight: null,
  validationEnabled: true

}
const defaultTestMeasurement = {
  id: null,
  quality_measurement_id: null,
  test_id: null,
  value: null,
  inheritShiftValue: false,
  category_id: null,
  availableQualityMeasurementOptions: [],
  qualityMeasurementOptions: []
}

export default {
  name: 'QualityTestEdit',
  data () {
    return {
      editEl: this.BasicHelper.cloneObject(defaultEl),
      defaultTest: this.BasicHelper.cloneObject(defaultTest),
      defaultTestMeasurement: this.BasicHelper.cloneObject(defaultTestMeasurement),
      qualityBaseConfig: {},
      tabOptions: [],
      productDataSet: [],
      loading: true,
      testerViewName: null,
      validationEnabled: true,
      testerCategoryId: null,
      summaryTest: null
    }
  },
  validations: {
    editEl: {
      productTests: {
        $each: helpers.forEach({
          quality_code_id: {
            required: requiredIf(function (key, row) {
              return row.validationEnabled === true
            })
          }
        })
      }
    }
  },
  components: {
    QualityTestMeasurement,
    QualityTestGroup,
    Gallery
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState({ qualityMeasurementCategoriesNewColumn: state => state.config.quality_measurement_categories }),
    qualityMeasurementOptions () {
      return this.$store.getters.getConfigOptions('quality_measurement_options', this.$i18n.locale);
    },
    currentRouteName () {
      return this.$route.name;
    },
    categoryOptionsIsOptions () {
      return this.$store.getters.getConfigOptions('quality_measurement_categories', this.$i18n.locale, 'isOption', 1);
    },
    qualityCategoryOptions () {
      return this.$store.getters.getConfigOptions('quality_measurement_categories', this.$i18n.locale);
    },
    qualityCategoryOptionsNewColumnOder () {
      return this.$store.getters.getConfigOptions('quality_measurement_categories', this.$i18n.locale, 'newColumnOder', 1);
    },
    testerProfileId () {
      return this.$route.fullPath.split('/')[(this.$route.fullPath.split('/')).length - 3]
    },
    testerProfileCategory () {
      if (this.availableTesterCategory) {
        const testerProfileCategory = this.availableTesterCategory.find(el => el.id == this.testerProfileId)
        if (testerProfileCategory != undefined) {
          return testerProfileCategory
        }
      }
      return false
    },
    qualityCodeOptions () {
      let qualities = [];
      if (this.qualityBaseConfig.qualityCodeIds.length > 0) {
        qualities = this.$store.getters.getConfigOptions('product_qualities_code', this.$i18n.locale, 'id', this.qualityBaseConfig.qualityCodeIds, false, false, true);
      } else {
        qualities = this.$store.getters.getConfigOptions('product_qualities_code', this.$i18n.locale);
      }
      return qualities;
    },
    qualityOptions () {
      return this.$store.getters.getConfigOptions('product_qualities', this.$i18n.locale);
    },
    measureTypeOptions () {
      return this.$store.getters.getConfigOptions('measure_types', this.$i18n.locale);
    },
    baseTransParam () {
      return 'menu.quality.productQualityTest';
    },
    getTitle () {
      return this.$t(this.baseTransParam + '.actions.edit');
    },
    availableTesterCategory () {
      const availableTesterCategory = JSON.parse(localStorage.getItem('availableTesterCategories'))
      return Array.isArray(availableTesterCategory) && availableTesterCategory.length > 0 ? availableTesterCategory : false
    },
    readOnlyView () {
      return this.$route.name.includes('productQualityTestEditReadOnly')
    },
    canEdit () {
      if (this.productDataSet.shift == null && !this.readOnlyView) return true
      return this.productDataSet.shift.qualityCompletedAt == null && !this.loading &&
          (this.ApiRequest.userHasPermission('edit-product-quality-tests') || (this.availableTesterCategory));
    },
    canGoToOverView () {
      return this.readOnlyView && !this.loading
    },
    canEditSampleWeight () {
      let canEdit = false;
      if (this.availableTesterCategory) {
        const testerCategory = this.availableTesterCategory.find(el => {
          return el.id === parseInt(this.$route.query.testerCategoryId ? this.$route.query.testerCategoryId : 0);
        });
        if (testerCategory) {
          canEdit = testerCategory.isForImportedCargos;
        }
      }
      return canEdit;
    },
    qualityPhotoFiles () {
      const files = [];
      if (this.editEl) {
        this.editEl.files.forEach(file => {
          if (file.model_attachment?.code === 'PRODUCT_QUALITY_PHOTO') {
            files.push(file);
          }
        })
      }
      return files;
    },
    qualityPhotoModelAttachmentId () {
      return this.$store.getters.getDataFromConfigWithFilter('model_attachments', 'code', 'PRODUCT_QUALITY_PHOTO')?.id;
    },
    filesPath () {
      return `quality/product-quality-tests/files/${this.editEl?.cargo_id}`;
    }
  },
  methods: {
    filesUpdated (files = null) {
      if (files) {
        this.editEl.files = files;
      }
    },
    isNewColumn (id) {
      const category = this.qualityMeasurementCategoriesNewColumn[id];
      if (category != undefined && category.newColumnOrder == true) {
        return true;
      }
      return false;
    },
    qualityCategoryText (index) {
      const text = this.qualityCategoryOptions.find(el => el.value == index)
      return text == undefined ? '' : text.text
    },
    setComponentData (id) {
      if (id && id !== 'NEW') {
        this.loading = true;
        this.ApiRequest.request('quality/product-quality-tests/view/' + id, this.ApiRequest.REQUEST_GET, { testerViewName: this.testerViewName }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
            this.loading = false;
          } else if (response.data) {
            this.summaryTest = response.data.summary_test
            if (response.data.product_tests.length > 0 && response.data.product_tests[0].quality_measurement_base_config != null) {
              this.qualityBaseConfig = response.data.product_tests[0].quality_measurement_base_config;
            } else {
              if (response.data.product.quality_measurement_base_config_by_type_and_product != null) {
                this.qualityBaseConfig = response.data.product.quality_measurement_base_config_by_type_and_product;
              } else {
                this.qualityBaseConfig = response.data.product.quality_measurement_base_config_by_type;
              }
            }
            const vm = this;
            this.productDataSet = response.data;
            this.editEl.factory_product_id = response.data.id
            this.editEl.cargo_id = response.data.cargo_id;
            if (response.data.product_tests.length == 0) {
              this.defaultTest.factory_product_id = this.productDataSet.id;
              this.defaultTest.base_config_id = this.qualityBaseConfig.id;
              this.defaultTest.validationEnabled = this.validationEnabled;
              this.qualityBaseConfig.quality_measurements.forEach(function callback (value, index) {
                if (value.deleted_at == null) {
                  const defaultTestMeasurement = vm.BasicHelper.cloneObject(vm.defaultTestMeasurement);
                  defaultTestMeasurement.quality_measurement_id = value.id
                  defaultTestMeasurement.category_id = value.category_id
                  defaultTestMeasurement.qualityMeasurementOptions = value.availableQualityMeasurementOptions
                  if ('defaultValue' in value.additionalData && !isNaN(value.additionalData.defaultValue) &&
                      value.additionalData.defaultValue !== '' && value.additionalData.defaultValue != null) {
                    defaultTestMeasurement.value = value.additionalData.defaultValue;
                  }
                  if ('canEditOnFactoryProductShiftMeasurement' in value.additionalData && value.additionalData.canEditOnFactoryProductShiftMeasurement) {
                    defaultTestMeasurement.inheritShiftValue = true;
                  }
                  vm.defaultTest.quality_test_measurements.push(defaultTestMeasurement);
                }
              });
              this.editEl.productTests.push(this.defaultTest);
            } else {
              this.editEl.productTests = response.data.product_tests;
            }

            const options = [];
            const self = this;
            this.editEl.productTests.forEach(function callback (value, index) {
              if (self.editEl.productTests[index].sample_weight) {
                self.editEl.productTests[index].sample_weight = self.editEl.productTests[index].sample_weight / 1000;
              }
              options.push({ code: index, text: vm.$t('menu.quality.productQualityTest.test') + '_' + (index + 1) });
            });
            this.tabOptions = options;
            if (response.data.purchasecargo) {
              this.editEl.files = response.data.purchasecargo.files;
            }
          } else {
            this.editEl = null;
          }
          this.loading = false;
          this.v$.$reset();
        });
      } else {
        this.loading = false;
      }
    },
    show (elData) {
      this.loading = true;
      if (elData) {
        this.setComponentData(elData.id);
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
      }
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    addTest () {
      if (this.editEl.productTests.length > 3) return;
      const lastEl = this.BasicHelper.cloneObject(this.editEl.productTests[this.editEl.productTests.length - 1]);
      lastEl.id = null;
      lastEl.quality_test_measurements.forEach(function callback (value, index) {
        lastEl.quality_test_measurements[index].id = null;
      });
      this.tabOptions.push({ code: this.editEl.productTests.length, text: this.$t('menu.quality.productQualityTest.test') + '_' + (this.editEl.productTests.length + 1) });
      this.editEl.productTests.push(lastEl);
    },
    editOverview () {
      if (this.readOnlyView) {
        this.$router.push(
          {
            name: 'menu.quality.productQualityTestOverViewReadOnly',
            params: { id: this.editEl.factory_product_id, testerViewName: this.currentRouteName, availableTesterCategory: this.availableTesterCategory }
          });
      } else {
        this.$router.push({
          name: 'menu.quality.productQualityTestOverView' + this.testerProfileCategory?.id,
          params: { id: this.editEl.factory_product_id, testerViewName: this.currentRouteName, availableTesterCategory: this.availableTesterCategory }
        });
      }
    },
    backToTestList () {
      if (this.testerViewName != null) {
        this.$router.push({ name: this.testerViewName, params: {} });
      } else {
        this.$router.push({ name: 'menu.quality.tests.list' });
      }
    },
    validCategoryMeasurement (groupedMeasurementDataSet) {
      return (groupedMeasurementDataSet[Object.keys(groupedMeasurementDataSet)[0]] != undefined &&
          groupedMeasurementDataSet[Object.keys(groupedMeasurementDataSet)[0]][0] != undefined &&
      (groupedMeasurementDataSet[Object.keys(groupedMeasurementDataSet)[0]][0].quality_measurement_category.id != undefined &&
          groupedMeasurementDataSet[Object.keys(groupedMeasurementDataSet)[0]][0].quality_measurement_category.deleted_at == null &&
          groupedMeasurementDataSet[Object.keys(groupedMeasurementDataSet)[0]].find(el => el.deleted_at == null) != undefined)) ||
      (Object.values(this.editEl.productTests)[0].quality_test_measurements.find(el2 => el2.quality_measurement != null &&
          el2.quality_measurement.category_id == Object.keys(groupedMeasurementDataSet)[0].replace('category_id_', '') &&
          ((el2.value != null && el2.value != '') ||
              el2.quality_test_measurement_options.length > 0)) != undefined)
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        reqData.validationEnabled = this.validationEnabled
        this.ApiRequest.request('quality/product-quality-tests/edit', this.ApiRequest.REQUEST_POST, { item: reqData, testerViewName: this.testerViewName }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$store.dispatch('reloadConfig');
            this.$emit('saved', response.data);
            this.close();
            if (this.testerViewName != null) {
              this.$router.push({ name: this.testerViewName, params: {} });
            } else {
              this.$router.push({ name: 'menu.quality.productQualityTestList', params: {} });
            }
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  mounted () {
    this.loading = true;
    this.isMounted = true;
    if (this.testerProfileCategory != false && this.testerProfileCategory.id != undefined) {
      this.validationEnabled = this.testerProfileCategory.validationEnabled == 1
      this.testerCategoryId = this.testerProfileCategory.id
      this.testerViewName = this.testerProfileCategory.name
    }
    this.setComponentData(this.$route.params.id);
  },
  watch: {
    '$route' () {
      this.setComponentData(this.$route.params.id);
    }
  }
}
