<template>
  <div class="shift-recipe">
    <ShiftPdfReports v-if="currentShift" :shiftId="currentShift.id" :shiftpicker="false" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ShiftPdfReports from '@/components/pet/shift/pdfreports/ShiftPdfReports.vue'

export default {
  name: 'ShiftPdfReportView',
  data () {
    return {
    }
  },
  components: {
    ShiftPdfReports
  },
  computed: {
    ...mapState({
      currentShift: state => state.pet.currentShift
    }),
    shiftId () {
      return this.currentShift?.id;
    }
  },
  watch: {
    currentShift: {
      immediate: true,
      handler () {
      }
    }
  }
}
</script>
