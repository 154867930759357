import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

const defaultEl = {
  id: null,
  model_attachment_id: null,
  model_class_id: null,
  order_cargo_type_id: null,
  autoadd: false,
  generated: false,
  model_attachment: {
    type: ''
  },
  order: null,
  multi: false
};

export default {
  name: 'ModelProductDocumentsEdit',
  data () {
    return {
      form: defaultEl,
      loading: false
    }
  },
  validations: {
    form: {
      model_attachment_id: { required },
      model_class_id: { required },
      order: { required }
    }
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getTitle () {
      if (this.form.id) {
        return this.$t(this.baseTransParam + '.actions.edit');
      }
      return this.$t(this.baseTransParam + '.actions.new');
    },
    baseTransParam () {
      return 'modelfiles';
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission('edit-model-product-documents');
    },
    modelAttachmentOptions () {
      const options = this.$store.getters.getConfigOptions('model_attachments', this.$i18n.locale);
      options.forEach(el => {
        el.text = el.text + ' (' + el.code + ')';
      })
      return options;
    },
    modelClassOptions () {
      const items = this.$store.getters.getDataFromConfigWithFilter('model_classes', null, null);
      const options = [];
      Object.values(items).forEach(el => {
        options.push({ value: el.id, text: el.classname.split('\\').pop() })
      });
      return options;
    },
    productOptions () {
      return this.$store.getters.getConfigOptions('products', this.$i18n.locale);
    },
    orderTypeOptions () {
      return this.$store.getters.getConfigOptions('order_cargo_types', this.$i18n.locale);
    }
  },
  methods: {
    attachmentChanged (id) {
      const attachmentType = this.$store.getters.getParamFromConfig('model_attachments', id, 'type');
      if (attachmentType) {
        this.form.model_attachment.type = attachmentType;
      }
    },
    show (elData) {
      if (elData) {
        this.form = this.BasicHelper.cloneObject(elData);
      } else {
        this.form = this.BasicHelper.cloneObject(defaultEl);
      }
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = this.BasicHelper.cloneObject(this.form);
        this.ApiRequest.request('model-product-documents', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
