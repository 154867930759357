export default {
  name: 'ProducedItemHistory',
  data () {
    return {
      historyItems: [],
      itemId: null,
      loading: false
    };
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    },
    items: {
      type: Array,
      default: () => null
    }
  },
  computed: {
    baseTransParam () {
      return 'produced';
    },
    getTitle () {
      return this.$t(this.baseTransParam + '.actions.history');
    },
    tableItems () {
      return this.history;
    },
    tableHeaders () {
      return [
        { key: 'created_at', label: this.$t('produced.editTime'), type: 'datetime' },
        { key: 'user.fullname', label: this.$t('produced.editUser') },
        { key: 'log_record_type_id', label: this.$t('log.type'), format: 'formatFromConfig', configKey: 'log_record_types', configParam: 'name' },
        { key: 'product_type_id', label: this.$t('product.type_id'), type: 'slot', slotcode: 'product_type' },
        { key: 'product_id', label: this.$t('product.name'), format: 'formatFromConfig', configKey: 'products', configParam: 'name' },
        { key: 'flow_id', label: this.$t('produced.flow'), format: 'formatFromConfig', configKey: 'line_flows', configParam: 'name' },
        { key: 'quantity', label: this.$t('produced.weight') },
        { key: 'fromCode', label: this.$t('produced.codechange'), type: 'slot', slotcode: 'codechange' }
      ];
    }
  },
  methods: {
    show (itemId) {
      this.itemId = itemId;
      this.getTableData();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    close () {
      this.$refs['modal-window'].hide();
    },
    getTableData () {
      this.historyItems = null;
      this.loading = true;
      this.ApiRequest.request(`factory-products/view/${this.itemId}`, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.close();
        } else {
          this.historyItems = response.data.history;
        }
        this.loading = false;
      });
    }
  },
  mounted () {
    if (this.items) {
      this.historyItems = this.BasicHelper.cloneObject(this.items);
    }
  },
  watch: {
    items: {
      immediate: true,
      handler (value) {
        if (value) {
          this.historyItems = this.BasicHelper.cloneObject(value);
        }
      }
    }
  }
}
