export default {
  name: 'languages',

  data () {
    return {
      loading: false
    }
  },

  computed: {
    isShow () {
      return this.languagesOptions.length > 1;
    },

    languagesOptions () {
      return this.$store.getters.getConfigOptions('locales', this.$i18n.locale, 'enabled', 1);
    }
  },

  methods: {
    changeLocale (localeCode) {
      this.$root.$i18n.locale = localeCode;
      this.$cookie.set('locale', localeCode, { expires: '1Y' });
      this.$parent.$parent.$forceUpdate();
      if (this.$session.exists()) {
        this.$session.set('locale', localeCode);
        this.$parent.$parent.$forceUpdate();
        this.ApiRequest.request('user/change-locale', this.ApiRequest.REQUEST_POST, { locale: localeCode }, function () {})
      }
    }
  }
}
