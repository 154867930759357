var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"quality-list"},[(_vm.isMounted)?[_c('Tableview',{ref:"tableview",attrs:{"fields":_vm.tableHeaders,"loading":_vm.loading,"items":_vm.tableItems,"filterValues":_vm.filterValues,"expandableConfig":_vm.tableExpandConfig,"filters":_vm.tableFilterConfig,"displayFilterActions":true,"filterActions":_vm.tableActions,"displayColFiltersAlways":true,"tableClass":"table-striped table-sm table-hover","filterActionAlign":"text-start mt-3"},on:{"saved":_vm.getTableData,"editItem":_vm.editItem,"cloneItem":_vm.cloneItem,"updateFilter":_vm.updateFilter,"clearFilters":_vm.clearFilters,"deleteItem":_vm.deleteItem,"restoreItem":_vm.restoreItem,"overView":_vm.overView},scopedSlots:_vm._u([{key:"color",fn:function(ref){
var data = ref.data;
return [_c('span',{staticClass:"badge w-100",style:({background: data.value})},[_vm._v("​")])]}},{key:"linefilter",fn:function(){return [_c('b-form-checkbox-group',{attrs:{"options":_vm.lineOptions},on:{"input":_vm.lineFilterChanged},model:{value:(_vm.filterValues.line_id),callback:function ($$v) {_vm.$set(_vm.filterValues, "line_id", $$v)},expression:"filterValues.line_id"}})]},proxy:true},{key:"cargoLocalCode",fn:function(ref){
var data = ref.data;
return [(data.item.purchasecargo == null)?_c('span',[_vm._v(" - ")]):_c('router-link',{attrs:{"to":{ name: 'menu.warehouse.cargos.view', params: { cargocode: data.item.purchasecargo.localcode }}}},[_vm._v(" "+_vm._s(data.item.purchasecargo.localcode)+" ")])]}},{key:"shiftManagerFilter",fn:function(){return [_c('AdvancedSelect',{attrs:{"items":_vm.getShiftManagers,"close-on-select":false,"multiple":""},on:{"input":_vm.getTableData},model:{value:(_vm.filterValues.shiftManager),callback:function ($$v) {_vm.$set(_vm.filterValues, "shiftManager", $$v)},expression:"filterValues.shiftManager"}})]},proxy:true},{key:"calculatedQualityId",fn:function(ref){
var data = ref.data;
return [(data.value > 0)?_c('span',[_vm._v(_vm._s(_vm.BasicHelper.getConfigValue('product_qualities', 'id', data.value, 'name')))]):_vm._e(),(data.item.summary_test != null && data.item.summary_test.set_quality_id > 0)?_c('span',[_c('i',{staticClass:"bi bi-pencil-fill"})]):_vm._e()]}},{key:"product_type",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.PetHelper.getProductConfigName(data.item.product_id, 'type_id'))+" ")]}}],null,false,1189149524)}),(_vm.paging.pages > 1  && !_vm.loading)?_c('b-pagination',{attrs:{"total-rows":_vm.paging.total,"per-page":_vm.paging.limit},on:{"change":_vm.changePage},model:{value:(_vm.paging.page),callback:function ($$v) {_vm.$set(_vm.paging, "page", $$v)},expression:"paging.page"}}):_vm._e()]:_vm._e(),_c('MessageBox',{ref:"itemDeleteMsgBox",on:{"confirm":function($event){return _vm.deleteItem($event, 1)}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }