import ModelFileList from '@/components/modelfile/list/ModelFileList.vue'
import ShiftPickerForForm from '@/components/pet/shift/pickerforform/ShiftPickerForForm.vue'

export default {
  name: 'ShiftPdfReports',
  components: {
    ModelFileList,
    ShiftPickerForForm
  },
  props: {
    shiftId: {
      type: Number,
      default: null
    },
    shiftpicker: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      shift: null,
      loading: false
    }
  },
  methods: {
    loadShift (shiftId) {
      if (shiftId) {
        this.loading = true;
        this.ApiRequest.request('shift/view/' + shiftId, this.ApiRequest.REQUEST_GET, [], (response) => {
          if (response.data) {
            this.shift = response.data;
          }
          this.loading = false;
        });
      }
    }
  },
  mounted () {
    this.loadShift(this.shiftId);
  },
  watch: {
    shiftId: {
      immediate: true,
      handler (val) {
        this.loadShift(val);
      }
    }
  }
}
