import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import MqttDevice from 'ebg-vue-common/src/components/common/mqttdevice.vue'

const defaultEl = {
  type_id: null,
  product_id: null,
  warehouse_id: null,
  local_warehouse_id: null,
  originalBales: null,
  startQuantity: null
}

export default {
  name: 'UnenteredMaterial',
  data () {
    return {
      editEl: this.BasicHelper.cloneObject(defaultEl),
      loading: false,
      bagSelectedId: null,
      oldMaterial: null,
      printing: {
        printLabel: false,
        forcePrint: false,
        workstationId: null,
        printer: null,
        printTemplates: []
      }
    }
  },
  validations: {
    editEl: {
      type_id: { required },
      product_id: { required },
      warehouse_id: { required },
      local_warehouse_id: { required },
      originalBales: { required },
      startQuantity: { required }
    }
  },
  components: {
    MqttDevice
  },
  computed: {
    isPet () {
      return (process.env.VUE_APP_CODENAME == 'pet');
    },
    axaptaWarehouseOptions () {
      return this.$store.getters.getConfigOptions('warehouses', this.$i18n.locale, 'inAxapta', 1);
    },
    assemblyWarehouseOptions () {
      return this.$store.getters.getConfigOptions('warehouses', this.$i18n.locale, 'isAssembly', 0);
    },
    productTypeOptions () {
      return this.$store.getters.getConfigOptions('product_types', this.$i18n.locale);
    },
    productOptions () {
      return this.$store.getters.getConfigOptions('products', this.$i18n.locale, 'type_id', this.editEl.type_id);
    },
    deviceOptions () {
      const options = Object.values(this.$store.getters.getDevicesByType('PRINTZ')).filter(el => {
        return this.deviceIds.includes(el.id);
      });
      options.forEach(option => {
        option.text = this.BasicHelper.getTranslation(option.name);
      });
      return options;
    },
    currentWorkstation () {
      return this.workstationOptions.find(el => el.value == this.printing.workstationId);
    },
    deviceIds () {
      if (this.currentWorkstation) {
        return this.currentWorkstation.device_ids;
      }
      return [];
    },
    workstationOptions () {
      return this.$store.getters.getConfigOptions(
        'workstations',
        this.$i18n.locale,
        null,
        null,
        false,
        false,
        false,
        ['device_ids']
      );
    },
    selectedDevice () {
      return this.deviceOptions.find(el => el.code == this.printing.printer);
    },
    printerTemplateOptions () {
      return this.$store.getters.getConfigOptions('printer_templates', this.$i18n.locale);
    },
    devicePrinterTemplates () {
      const options = [];
      if (this.selectedDevice) {
        this.printerTemplateOptions.forEach(el => {
          if (this.selectedDevice.templates[el.value]) {
            this.selectedDevice.templates[el.value].text = el.text;
            this.selectedDevice.templates[el.value].code = el.code;
            options.push(this.BasicHelper.cloneObject(this.selectedDevice.templates[el.value]));
          }
        });
      }
      return options;
    },
    displayForcePrint () {
      return (this.environment != 'production' && this.ApiRequest.userHasPermission('force-print-test'));
    },
    currentQRDeviceId () {
      return this.$store.state.pet.currentQRDeviceId;
    },
    qrDevice () {
      if (this.currentQRDeviceId) {
        return this.$store.getters.getDataFromConfigById('devices', this.currentQRDeviceId);
      }
    }
  },
  methods: {
    add () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        this.ApiRequest.request('factory-products/unentered-material/add', this.ApiRequest.REQUEST_POST, this.editEl, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.ApiRequest.displaySuccessDialog(
              this,
              this.$tc('unentered-material.actions.add-message', {}, response.data),
              this.$t('unentered-material.actions.add-title')
            );
            if (this.printing.printLabel && this.printing.printTemplates.length > 0) {
              this.$store.commit('toastAdd', {
                context: 'info',
                message: this.$t('device.printer.productprint')
              })
              const reqData = {
                locale: this.$i18n.locale,
                device: this.printing.printer,
                templates: this.BasicHelper.cloneObject(this.printing.printTemplates)
              }
              let path = 'factory-products/print-label/' + response.data.id;
              if (this.printing.forcePrint) {
                path += '/1';
              }
              this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, reqData, (response) => {
                if (response.error) {
                  this.ApiRequest.displayErrorDialog(response, this);
                }
              });
            }
            this.editEl = this.BasicHelper.cloneObject(defaultEl);
            this.setDefaultValues();
          }
          this.loading = false;
          this.v$.$reset();
        });
      }
    },
    setDefaultValues () {
      this.bagSelectedId = null;
      this.editEl.warehouse_id = this.axaptaWarehouseOptions[0].value;
      this.editEl.local_warehouse_id = this.assemblyWarehouseOptions[0].value;
    },
    bagSelected (e) {
      this.oldMaterial = e;
      if (this.oldMaterial) {
        this.editEl.type_id = this.oldMaterial.product_type_id;
        this.editEl.product_id = this.oldMaterial.product_id;
        this.editEl.originalBales = 1;
        this.editEl.startQuantity = this.oldMaterial.weight;
      } else {
        this.editEl.type_id = null;
        this.editEl.product_id = null;
        this.editEl.originalBales = '';
        this.editEl.startQuantity = '';
      }
    },
    workstationChanged () {
      this.printing.printer = (this.deviceOptions.length ? this.deviceOptions[0].code : null);
      this.deviceChanged();
    },
    deviceChanged () {
      this.printing.printTemplates = [];
    },
    qrDeviceRead (val) {
      if (val) {
        this.ApiRequest.request('old-pet/manufactured/find', this.ApiRequest.REQUEST_GET, { apiId: val }, (response) => {
          if (response.data) {
            if (response.data.length > 0) {
              this.bagSelected(response.data[0]);
              this.bagSelectedId = this.oldMaterial.id;
            } else {
              this.$store.commit('toastAdd', {
                context: 'warning',
                message: this.$tc('unentered-material.oldSystemProduct.not-found', {}, { apiId: val })
              })
            }
          }
        });
      }
    }
  },
  created () {
    this.setDefaultValues();
    this.printing.workstationId = (this.workstationOptions.length ? this.workstationOptions[0].value : null);
    this.workstationChanged();
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
