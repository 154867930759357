export default {
  name: 'WarehouseBin',
  data () {
    return {
    }
  },
  validations: {
  },
  components: {
  },
  props: {
    bin: {
      type: Object,
      default: null
    },
    isFactoryProductSelectable: {
      type: Boolean,
      default: true
    }
  },
  computed: {
  },
  methods: {
  }
}
