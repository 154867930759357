export default {
  name: 'Permits',

  props: {
    value: {
      type: Array,
      default: () => []
    },
    error: {
      type: Object | Array,
      default: () => []
    }
  },

  data () {
    return {
      content: this.value,
      isLoading: false,
      paging: { limit: 10, page: 1, total: 0, pages: 0 }
    }
  },

  computed: {
    tableHeaders () {
      const headers = [
        { key: 'permit_type_id', label: this.$t('transport-provider.permits.permit'), type: 'slot', slotcode: 'permitType' },
        { key: 'countries', label: this.$t('transport-provider.permits.country'), type: 'slot', slotcode: 'countries' },
        { key: 'isActual', label: this.$t('transport-provider.permits.active'), type: 'slot', slotcode: 'actual' }
      ];

      let actions = [];
      actions = [
        { label: this.$t('user.jobs.btn.title-edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-warning btn-sm', ifconfig: { func: this.isShowEdit, useFunc: true } },
        { label: this.$t('user.jobs.btn.title-delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-danger btn-sm' }
      ];

      headers.push({ key: 'actions', label: '', actions: actions, headerClass: 'col-1' });
      return headers;
    },

    transportProvidersTypeOptions () {
      return this.$store.getters.getConfigOptions('transport_providers_types', this.$i18n.locale);
    },

    countryOptions () {
      return this.$store.getters.getCountryOptions();
    }
  },

  methods: {
    getFormatCounty (countryIsoList) {
      const formattedCountries = [];
      if (countryIsoList) {
        Object.values(this.countryOptions).forEach(el => {
          if (countryIsoList.includes(el.code)) {
            formattedCountries.push(el.text);
          }
        });
      }
      return formattedCountries.join(', ');
    },

    addPermission () {
      this.$emit('addPermission');
    },

    isShowEdit (el) {
      return el.is_saved;
    },

    editItem (el) {
      el.is_saved = false;
    },

    deleteItem (el) {
      this.content.splice(el.itemIndex, 1);
    }
  },

  watch: {
    value () {
      if (this.content !== this.value) {
        this.content = this.value;
      }
    },

    content () {
      this.$emit('input', this.content);
    }
  }
}
