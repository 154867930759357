var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"country-list my-2"},[_c('div',{staticClass:"d-flex justify-content-end mb-1"},[_c('button',{staticClass:"btn btn-primary btn-sm",attrs:{"type":"button"},on:{"click":function($event){return _vm.addCountry()}}},[_c('i',{staticClass:"bi bi-plus"}),_vm._v(" "+_vm._s(_vm.$t('transport-provider.carriages.actions.add'))+" ")])]),_c('Tableview',{ref:"tableview",attrs:{"tableClass":"table-striped table-sm table-hover small","fields":_vm.tableHeaders,"loading":_vm.isLoading,"items":_vm.content,"smallScreenCards":true},on:{"editItem":_vm.editItem,"deleteItem":_vm.deleteItem},scopedSlots:_vm._u([{key:"cargoType",fn:function(ref){
var data = ref.data;
return [(!data.item.is_saved)?[_c('AdvancedSelect',{attrs:{"size":"sm","hideClearIcon":"","name":"cargo_type","disabled":_vm.isLoading,"items":_vm.orderCargoTypeOptions,"searchable":true},model:{value:(data.item.cargo_type_id),callback:function ($$v) {_vm.$set(data.item, "cargo_type_id", $$v)},expression:"data.item.cargo_type_id"}})]:[_vm._v(" "+_vm._s(_vm.BasicHelper.getConfigValue('order_cargo_types', 'id', data.item.cargo_type_id, 'name'))+" ")]]}},{key:"countries",fn:function(ref){
var data = ref.data;
return [(!data.item.is_saved)?[_c('AdvancedSelect',{attrs:{"multiple":"","size":"sm","value-field":"code","items":_vm.countryOptions,"searchable":true,"name":"countries","disabled":_vm.isLoading,"close-on-select":false},model:{value:(data.item.countries),callback:function ($$v) {_vm.$set(data.item, "countries", $$v)},expression:"data.item.countries"}})]:[_vm._v(" "+_vm._s(_vm.getFormatCounty(data.item.countries))+" ")]]}},{key:"vehicles",fn:function(ref){
var data = ref.data;
return [(!data.item.is_saved)?[_c('NumberInput',{attrs:{"controlClass":"form-control-sm","minValue":0,"disabled":_vm.isLoading,"name":"vehicles"},model:{value:(data.item.vehicles),callback:function ($$v) {_vm.$set(data.item, "vehicles", $$v)},expression:"data.item.vehicles"}})]:[_vm._v(" "+_vm._s(data.item.vehicles)+" ")]]}},{key:"email",fn:function(ref){
var data = ref.data;
return [(!data.item.is_saved)?[_c('BFormInput',{attrs:{"type":"email","controlClass":(_vm.error.$each.$response.$errors[data.item.itemIndex].email.length ? 'is-invalid' : '') + ' form-control-sm',"disabled":_vm.isLoading,"dbparam":"email"},model:{value:(data.item.email),callback:function ($$v) {_vm.$set(data.item, "email", $$v)},expression:"data.item.email"}})]:[(data.item.email)?_c('a',{attrs:{"href":'mailto:'+ data.item.email}},[_vm._v(_vm._s(data.item.email))]):_vm._e()]]}},{key:"phone",fn:function(ref){
var data = ref.data;
return [(!data.item.is_saved)?[_c('BFormInput',{attrs:{"controlClass":"form-control-sm","disabled":_vm.isLoading,"dbparam":"phone"},model:{value:(data.item.phone),callback:function ($$v) {_vm.$set(data.item, "phone", $$v)},expression:"data.item.phone"}})]:[_vm._v(" "+_vm._s(data.item.phone)+" ")]]}},{key:"comment",fn:function(ref){
var data = ref.data;
return [(!data.item.is_saved)?[_c('BFormInput',{attrs:{"controlClass":"form-control-sm","disabled":_vm.isLoading,"dbparam":"comment"},model:{value:(data.item.comment),callback:function ($$v) {_vm.$set(data.item, "comment", $$v)},expression:"data.item.comment"}})]:[_vm._v(" "+_vm._s(data.item.comment)+" ")]]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }