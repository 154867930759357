var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-product-list"},[_c('Tableview',{ref:"tableview",attrs:{"fields":_vm.tableHeaders,"loading":_vm.loading,"items":_vm.products,"tableClass":"table-striped table-sm table-hover"},on:{"saved":_vm.getTableData,"deleteItem":_vm.deleteLine},scopedSlots:_vm._u([{key:"product_type",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.PetHelper.getProductConfigName(data.item.product_id, 'type_id'))+" ")]}},{key:"product",fn:function(ref){
var data = ref.data;
return [(_vm.canEdit)?_c('AdvancedSelect',{attrs:{"items":_vm.getProductOptions(data.value),"hasError":(_vm.validator && _vm.validator[data.index].product_id.length ? true : false),"searchable":"","size":_vm.size},on:{"input":function($event){return _vm.productChanged($event, data.index)},"select":function($event){return _vm.handleProductSelect($event, _vm.products[data.index].product_id, data.index)}},model:{value:(_vm.products[data.index].product_id),callback:function ($$v) {_vm.$set(_vm.products[data.index], "product_id", $$v)},expression:"products[data.index].product_id"}}):[_vm._v(" "+_vm._s(_vm.$store.getters.getParamFromConfig('products', data.item.product_id, 'name', 'lv'))+" ")]]}},{key:"productNameEn",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.$store.getters.getParamFromConfig('products', data.item.product_id, 'name', 'en'))+" ")]}},{key:"minquality",fn:function(ref){
var data = ref.data;
return [(data.value)?_c('span',{staticClass:"text-center badge",style:(("background-color: " + (_vm.getQualityBadgeColors(data.value).bgColor) + "; color: " + (_vm.getQualityBadgeColors(data.value).textColor)))},[_vm._v(" "+_vm._s(_vm.BasicHelper.getConfigValue('product_qualities', 'id', data.value, 'name'))+" "+_vm._s(_vm.getProductQualityCodesText(_vm.products[data.index].qualitiesCodes))+" ")]):_vm._e()]}},{key:"purchased_in_bags",fn:function(ref){
var data = ref.data;
return [_c('b-form-checkbox',{attrs:{"disabled":!_vm.products[data.index].product_id || !_vm.checkPurchaseInBagsCanEdit(_vm.products[data.index]),"dbparam":("products." + (data.index) + ".purchased_in_bags")},model:{value:(_vm.products[data.index].purchased_in_bags),callback:function ($$v) {_vm.$set(_vm.products[data.index], "purchased_in_bags", $$v)},expression:"products[data.index].purchased_in_bags"}})]}},{key:"quality_id",fn:function(ref){
var data = ref.data;
return [(_vm.canEdit)?_c('AdvancedSelect',{attrs:{"items":_vm.getQualityOptions(_vm.products[data.index].product_id, data.value),"hasError":(_vm.validator && _vm.validator[data.index].quality_id.length > 0),"size":_vm.size,"hide-clear-icon":true,"disabled":!_vm.products[data.index].product_id},model:{value:(_vm.products[data.index].quality_id),callback:function ($$v) {_vm.$set(_vm.products[data.index], "quality_id", $$v)},expression:"products[data.index].quality_id"}}):[(data.value)?_c('span',{staticClass:"text-center badge",style:(("background-color: " + (_vm.getQualityBadgeColors(data.value).bgColor) + "; color: " + (_vm.getQualityBadgeColors(data.value).textColor)))},[_vm._v(" "+_vm._s(_vm.BasicHelper.getConfigValue('product_qualities', 'id', data.value, 'name'))+" ")]):_vm._e()]]}},{key:"saleInBags",fn:function(ref){
var data = ref.data;
return [_c('b-form-checkbox',{staticClass:"mt-1",attrs:{"disabled":!_vm.canEdit},model:{value:(_vm.products[data.index].saleInBags),callback:function ($$v) {_vm.$set(_vm.products[data.index], "saleInBags", $$v)},expression:"products[data.index].saleInBags"}})]}},{key:"number",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(_vm.products[data.index][data.field.key], 0, _vm.$i18n.locale))+" ")]}},{key:"allowBroken",fn:function(ref){
var data = ref.data;
return [_c('span',{class:'fw-bold ' + (_vm.products[data.index].allowBroken ? 'text-success': 'text-danger')},[_vm._v(" "+_vm._s(_vm.$t(_vm.BasicHelper.formatYesNo(_vm.products[data.index].allowBroken)))+" ")])]}},{key:"pieces",fn:function(ref){
var data = ref.data;
return [(_vm.canEdit)?_c('NumberInput',{attrs:{"precision":0,"minValue":0,"maxlength":10,"controlClass":'form-control-' + _vm.size},model:{value:(_vm.products[data.index].pieces),callback:function ($$v) {_vm.$set(_vm.products[data.index], "pieces", $$v)},expression:"products[data.index].pieces"}}):[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.value, 0, _vm.$i18n.locale))+" ")]]}},{key:"quantity",fn:function(ref){
var data = ref.data;
return [(_vm.canEdit)?_c('NumberInput',{attrs:{"precision":3,"minValue":0,"maxlength":10,"controlClass":'form-control-' + _vm.size + ' ' + (_vm.validator && _vm.validator[data.index].quantity.length ? 'is-invalid' : '')},model:{value:(_vm.products[data.index].quantity),callback:function ($$v) {_vm.$set(_vm.products[data.index], "quantity", $$v)},expression:"products[data.index].quantity"}}):[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.value, 0, _vm.$i18n.locale))+" ")]]}},{key:"individual_rate",fn:function(ref){
var data = ref.data;
return [(_vm.canEdit)?_c('NumberInput',{attrs:{"precision":6,"minValue":0,"maxlength":10,"controlClass":'form-control-' + _vm.size + ' ' + (_vm.validator && _vm.validator[data.index].individual_rate.length ? 'is-invalid' : '')},model:{value:(_vm.products[data.index].individual_rate),callback:function ($$v) {_vm.$set(_vm.products[data.index], "individual_rate", $$v)},expression:"products[data.index].individual_rate"}}):[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.value, 6, _vm.$i18n.locale))+" ")]]}},{key:"measure_type",fn:function(ref){
var data = ref.data;
return [_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip"}],attrs:{"title":_vm.PetHelper.getProductConfigName(data.item.product_id, 'default_measure_type_id')}},[_vm._v(" "+_vm._s(_vm.PetHelper.getProductConfigName(data.item.product_id, 'default_measure_type_id', 'name', true))+" ")])]}},{key:"apus_waste_class",fn:function(ref){
var data = ref.data;
return [_c('AdvancedSelect',{attrs:{"items":_vm.getAvailableApusOptions(_vm.products[data.index].product_id),"dbparam":"apus_waste_class_id","disabled":!_vm.canEditApussCLass,"hasError":(_vm.validator && _vm.validator[data.index].apus_waste_class_id.length ? true : false),"size":"sm"},model:{value:(_vm.products[data.index].apus_waste_class_id),callback:function ($$v) {_vm.$set(_vm.products[data.index], "apus_waste_class_id", $$v)},expression:"products[data.index].apus_waste_class_id"}})]}},{key:"residualWeight",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.residualWeight(data))+" ")]}},{key:"plannedAmount",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(_vm.plannedAmount(data), 2, _vm.$i18n.locale))+" ")]}},{key:"originCountry",fn:function(ref){
var data = ref.data;
return [_c('AdvancedSelect',{attrs:{"disabled":!_vm.checkOriginCountryCanEdit(_vm.products[data.index]) || !_vm.canEdit,"items":_vm.countryOptions,"textField":"text","valueField":"code","size":"sm","searchable":true,"controlClass":'form-control-' + _vm.size + ' ' + (_vm.validator && _vm.validator[data.index].originCountry.length ? 'is-invalid' : '')},model:{value:(_vm.products[data.index].originCountry),callback:function ($$v) {_vm.$set(_vm.products[data.index], "originCountry", $$v)},expression:"products[data.index].originCountry"}})]}},{key:"rate",fn:function(ref){
var data = ref.data;
return [(!_vm.isIncoming && !_vm.isTransfer)?[(data.item.customerSaleRate !== null)?_c('span',[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.item.customerSaleRate, 6, _vm.$i18n.locale))+" ")]):(data.item.defaultSaleRate !== null)?_c('span',{staticClass:"text-warning fw-bold"},[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.item.defaultSaleRate, 6, _vm.$i18n.locale))+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("-")])]:(_vm.isIncoming)?[(data.item.vendorBuyRate !== null)?_c('span',[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.item.vendorBuyRate, 6, _vm.$i18n.locale))+" ")]):(data.item.defaultBuyRate !== null)?_c('span',{staticClass:"text-warning fw-bold"},[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.item.defaultBuyRate, 6, _vm.$i18n.locale))+" ")]):_c('span',{staticClass:"text-danger"},[_vm._v("-")])]:_vm._e()]}},{key:"customsCode",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.customsCode(data))+" ")]}}])}),(_vm.canEdit)?_c('div',[_c('span',{class:("btn btn-" + _vm.size + " btn-primary"),on:{"click":_vm.addLine}},[_c('i',{staticClass:"bi bi-plus"})])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }