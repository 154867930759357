import moment from 'moment';

export default {
  name: 'ShiftPickerForForm',
  components: {
  },
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      shift: null,
      findEl: {
        line_id: null,
        type_id: null,
        startdate: null
      }
    }
  },
  computed: {
    lineOptions () {
      return this.$store.getters.getAvailableLineOptions(this.$i18n.locale);
    },
    shiftTypeOptions () {
      return this.$store.getters.getConfigOptions('shift_types', this.$i18n.locale);
    }
  },
  methods: {
    loadShift () {
      this.updateValue(null);
      if (this.findEl.line_id && this.findEl.startdate && this.findEl.type_id) {
        const reqData = this.BasicHelper.cloneObject(this.findEl);
        this.ApiRequest.request('shift/find', this.ApiRequest.REQUEST_GET, reqData, (response) => {
          if (response.error) {
            this.$store.commit('toastAdd', {
              context: 'danger',
              message: this.ApiRequest.getApiResponseErrorMessage(response, {})
            })
          } else if (response.data) {
            this.updateValue(response.data);
          } else {
            this.$store.commit('toastAdd', {
              context: 'warning',
              message: this.$t('shift.not-found')
            })
          }
        });
      }
    },
    updateValue (val) {
      this.shift = val;
      this.$emit('input', this.shift);
    }
  },
  mounted () {
    this.findEl.line_id = this.lineOptions[0]?.value;
    this.findEl.type_id = this.shiftTypeOptions[0]?.value;
    this.findEl.startdate = moment().format('YYYY-MM-DD')
    this.loadShift();
  }
}
