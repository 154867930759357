import useVuelidate from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';

const defaultEl = {
  id: null,
  comment: '',
  startAt: null,
  endAt: null,
  stage_id: null,
  flow_id: null,
  line_id: null,
  type_id: null,
  scheduled: false,
  inputMode: 1,
  device_ids: []
}

export default {
  name: 'DowntimeEdit',
  data () {
    return {
      editEl: this.BasicHelper.cloneObject(defaultEl),
      shift: null,
      loading: false,
      editDowntimeLocation: false
    }
  },
  validations: {
    editEl: {
      startAt: { required },
      comment: {
        required: requiredIf(function () {
          return this.commentRequired
        })
      },
      line_id: { required },
      flow_id: {
        required: requiredIf(function () {
          return this.editEl.inputMode > 1
        })
      },
      stage_id: {
        required: requiredIf(function () {
          return this.editEl.inputMode == 3
        })
      },
      type_id: { required },
      endAt: { required },
      device_ids: { }
    }
  },
  components: {
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    baseTransParam () {
      return 'downtimes';
    },
    getTitle () {
      if (this.editEl.id) {
        return this.$t(this.baseTransParam + '.actions.edit');
      }
      return this.$t(this.baseTransParam + '.actions.new');
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission('edit-shift-downtimes');
    },
    canEditFull () {
      return this.canEdit && (!this.shift || !this.editEl.id);
    },
    lineOptions () {
      const items = this.$store.getters.getConfigOptions(
        'lines',
        this.$i18n.locale,
        (this.shift ? 'id' : null),
        (this.shift ? this.shift.line_id : null),
        false,
        false,
        false,
        ['availableDowntimes', 'availableDevices']
      );
      if (this.editEl.inputMode == 1) {
        return items.filter(el => el.availableDowntimes[0]);
      }
      const validLineIds = [];
      this.flowOptions.forEach(flow => {
        if (this.editEl.inputMode == 2) {
          if (flow.availableDowntimes[0]) {
            validLineIds.push(flow.line_id)
          }
        } else {
          let isValid = false;
          Object.keys(flow.availableDowntimes).forEach(stageId => {
            if (stageId != 0) {
              isValid = true;
            }
          });
          if (isValid) {
            validLineIds.push(flow.line_id)
          }
        }
      });
      return items.filter(el => validLineIds.find(lineId => el.value == lineId));
    },
    isScheduledOptions () {
      return [
        { value: 1, text: this.$t('downtimes.isScheduled') },
        { value: 0, text: this.$t('downtimes.isUnscheduled') }
      ]
    },
    flowOptions () {
      return this.$store.getters.getConfigOptions(
        'line_flows',
        this.$i18n.locale,
        (this.shift ? 'line_id' : null),
        (this.shift ? this.shift.line_id : null),
        false,
        false,
        false,
        ['availableDowntimes', 'availableDevices', 'stages', 'line_id']
      );
    },
    flowOptionsActive () {
      if (this.editEl.inputMode == 2) {
        return this.flowOptions.filter(el =>
          el.line_id == this.editEl.line_id && el.availableDowntimes[0]
        );
      }
      return this.flowOptions.filter(el => {
        let isValid = false;
        Object.keys(el.availableDowntimes).forEach(key => {
          if (key > 0) {
            isValid = true;
          }
        })
        return (el.line_id == this.editEl.line_id) && isValid;
      });
    },
    stageOptions () {
      if (this.selectedFlow && this.selectedFlow && this.editEl.type_id) {
        const validStageIds = [];
        Object.keys(this.selectedFlow.availableDowntimes).forEach(stageId => {
          if (stageId > 0 && this.selectedFlow.availableDowntimes[stageId].find(typeId => typeId == this.editEl.type_id)) {
            validStageIds.push(stageId);
          }
        });
        return this.selectedFlow.stages.filter(el => {
          el.text = this.BasicHelper.getTranslation(el.name)
          return validStageIds.find(stageId => el.id == stageId)
        });
      }
      return [];
    },
    inputModeOptions () {
      const options = [];
      options.push({ value: 1, text: this.$t('downtimes.lineInputMode') });
      if (!this.shift || this.editEl.id || this.flowOptions.length > 1) {
        options.push({ value: 2, text: this.$t('downtimes.flowInputMode') });
      }
      options.push({ value: 3, text: this.$t('downtimes.stageInputMode') });
      return options;
    },
    isDatepickerWithDate () {
      return !this.shift;
    },
    allDowntimeTypes () {
      return this.$store.getters.getConfigOptions('downtime_types', this.$i18n.locale, null, null, false, false, false, ['canBeScheduled', 'canBeUnscheduled', 'withcomment']);
    },
    activeDowntimeTypes () {
      const filterKey = (this.editEl.scheduled == 1 ? 'canBeScheduled' : 'canBeUnscheduled');
      return this.allDowntimeTypes.filter(el => el[filterKey] == true);
    },
    selectedLine () {
      return this.lineOptions.find(el => el.value == this.editEl.line_id);
    },
    selectedFlow () {
      return this.flowOptions.find(el => el.value == this.editEl.flow_id);
    },
    typeOptions () {
      let validIds = [];
      if (this.editEl.inputMode == 1) {
        if (this.selectedLine && this.selectedLine.availableDowntimes && this.selectedLine.availableDowntimes[0]) {
          validIds = this.BasicHelper.cloneObject(this.selectedLine.availableDowntimes[0]);
        }
      } else if (this.selectedFlow && this.selectedFlow.availableDowntimes) {
        if (this.editEl.inputMode == 2) {
          validIds = (this.selectedFlow.availableDowntimes[0] ? this.BasicHelper.cloneObject(this.selectedFlow.availableDowntimes[0]) : [])
        } else {
          Object.keys(this.selectedFlow.availableDowntimes).forEach(stageId => {
            if (stageId > 0) {
              this.selectedFlow.availableDowntimes[stageId].forEach(typeId => {
                validIds.push(typeId);
              });
            }
          })
        }
      }
      return this.activeDowntimeTypes.filter(el => validIds.includes(el.value));
    },
    shiftType () {
      if (this.shift) {
        return this.$store.getters.getDataFromConfigById('shift_types', this.shift.type_id);
      }
      return null;
    },
    validTimeHours () {
      if (this.shiftType) {
        if (this.shiftType.startHour > this.shiftType.endHour) {
          const res = [];
          let i = this.shiftType.startHour;
          while (i !== this.shiftType.endHour) {
            res.push(i);
            i += 1;
            if (i === 24) {
              i = 0;
            }
          }
          return res;
        }
        return { min: this.shiftType.startHour, max: (this.shiftType.endHour - 1) };
      }
      return { min: 0, max: 0 };
    },
    selectedDowntimeType () {
      return this.$store.getters.getDataFromConfigWithFilter('downtime_types', 'id', this.editEl.type_id);
    },
    commentRequired () {
      if (this.selectedDowntimeType && this.selectedDowntimeType.withcomment) {
        return true;
      }
      return false;
    },
    deviceOptions () {
      if (this.selectedLine && this.editEl.inputMode == 1) {
        return this.$store.getters.getConfigOptions('devices', this.$i18n.locale);
      }

      if (this.editEl.stage_id && this.editEl.inputMode == 3 && this.selectedFlow.availableDevices[this.editEl.stage_id]) {
        const availableDeviceIds = this.selectedFlow.availableDevices[this.editEl.stage_id];
        const options = this.$store.getters.getConfigOptions('devices', this.$i18n.locale);
        return options.filter(option => availableDeviceIds.includes(option.value));
      }

      return [];
    }
  },
  methods: {
    show (el, shift) {
      this.editDowntimeLocation = false;
      this.shift = this.BasicHelper.cloneObject(shift);
      if (el) {
        this.editEl = this.BasicHelper.cloneObject(el);
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
        this.editEl.line_id = (this.lineOptions.length > 0 ? this.lineOptions[0].value : null);
      }
      if (this.editEl.stage_id) {
        this.editEl.inputMode = 3
      } else if (this.editEl.flow_id) {
        this.editEl.inputMode = 2
      } else {
        this.editEl.inputMode = 1;
      }
      if (!this.editEl.id) {
        this.isScheduledChanged();
      }
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        if (this.shift) {
          reqData.shift_id = this.shift.id;
        }
        this.ApiRequest.request('downtimes/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    },
    isScheduledChanged () {
      this.editEl.inputMode = (this.inputModeOptions.length > 0 ? this.inputModeOptions[0].value : null);
      this.inputModeChanged();
    },
    inputModeChanged () {
      this.editEl.line_id = (this.lineOptions.length > 0 ? this.lineOptions[0].value : null);
      this.lineChanged();
    },
    lineChanged () {
      if (this.editEl.inputMode == 1) {
        this.editEl.flow_id = null;
      } else {
        this.editEl.flow_id = (this.flowOptionsActive.length > 0 ? this.flowOptionsActive[0].value : null);
      }
      this.flowChanged();
    },
    flowChanged () {
      this.editEl.stage_id = null;
      this.editEl.type_id = (this.typeOptions.length > 0 ? this.typeOptions[0].value : null);
      this.typeChanged();
    },
    stageChanged () {
      this.editEl.device_ids = [];
    },
    typeChanged () {
      this.editEl.stage_id = null;
      if (this.editEl.inputMode == 3 && (this.stageOptions.length > 0 && this.stageOptions.length < 6)) {
        this.editEl.stage_id = this.stageOptions[0].id;
      }
      this.stageChanged();
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
