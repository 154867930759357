import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

const defaultEl = {
  id: null,
  product_id: null,
  vendor_id: null,
  quality_id: null,
  customerName: '',
  screening_interval: 0
};

export default {
  name: 'MaterialEdit',
  data () {
    return {
      editEl: defaultEl,
      vendor: null,
      loading: false
    }
  },
  components: {
  },
  validations: {
    editEl: {
      product_id: { required },
      quality_id: { }
    }
  },
  computed: {
    getTitle () {
      if (this.editEl.id) {
        return this.$t('partners.vendors.products.actions.edit');
      }
      return this.$t('partners.vendors.products.actions.new');
    },
    canEdit () {
      return true;
    },
    productOptions () {
      return this.PetHelper.getFeadableBaleProducts(true).filter(el => (el.isBought || el.isChemical));
    },
    productFilters () {
      if (!this.vendor?.products) {
        return {};
      }
      const existing = this.vendor.products.map(el => el.product_id);
      const products = this.productOptions.filter(el => {
        return existing.indexOf(el.value) < 0 && this.vendor.product_types.some(obj => obj.product_type_id == el.type_id);
      }).map(el => el.value); // all feed-able products excluding already added

      return { value: products };
    },
    qualityOptions () {
      const options = this.$store.getters.getConfigOptions('product_qualities', this.$i18n.locale);
      options.unshift({ value: null, text: '-' });
      return options;
    }
  },
  methods: {
    show (elData, vendorId = null, vendor) {
      this.vendor = this.BasicHelper.cloneObject(vendor);
      if (elData) {
        this.editEl = this.BasicHelper.cloneObject(elData);
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
        this.editEl.vendor_id = vendorId;
      }
      this.v$.$reset();
      this.$refs['modal-window'].show();
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        this.ApiRequest.request('vendors/materialedit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      this.$refs['modal-window'].hide();
    }
  },
  mounted () {
    this.isMounted = true;
  },
  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'MaterialEdit' }) }
  }
}
