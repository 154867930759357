import useVuelidate from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';
import { mapState } from 'vuex';
import ShiftFinder from '@/components/pet/shift/finder/ShiftFinder.vue';

const defaultEl = {
  factory_product_id: null,
  line_id: null,
  flow_id: null,
  stage_id: null,
  product_id: null,
  quantity: null,
  pieces: null,
  feeding_type: null
};

export default {
  name: 'FactoryProductWriteoff',
  data () {
    return {
      editEl: null,
      loading: false,
      minusFromEl: null,
      editShift: false,
      usedShift: null,
      initShift: null,
      shiftIdJournalCheck: null
    }
  },
  validations: {
    editEl: {
      factory_product_id: { required },
      line_id: {
        required: requiredIf(function () {
          return !this.isWriteOffJournal;
        })
      },
      flow_id: {
        required: requiredIf(function () {
          return !this.isWriteOffJournal;
        })
      },
      stage_id: {
        required: requiredIf(function () {
          return !this.isWriteOffJournal;
        })
      },
      pieces: { required },
      quantity: { required },
      feeding_type: { required }
    }
  },
  components: {
    ShiftFinder
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      currentShift: state => state.pet.currentShift
    }),
    baseTransParam () {
      return 'factory-product';
    },
    getTitle () {
      return this.$t(this.baseTransParam + '.actions.writeoff');
    },
    writeOfJournalOptionSelected () {
      const findWriteOfJournal = this.$store.getters.getConfigOptions('feeding_types', this.$i18n.locale).find(el => el.code == 'WRITEOFFJOURNAL')
      if (findWriteOfJournal != undefined && this.editEl != null && findWriteOfJournal.code == this.editEl.feeding_type) {
        return true
      }
      return false
    },
    feedingTypeOptions () {
      const usedCodes = ['FEDBROKEN', 'WRITEOFF', this.writeOffJournalCode];
      const options = []
      this.$store.getters.getConfigOptions('feeding_types', this.$i18n.locale).forEach(el => {
        if (usedCodes.includes(el.code)) {
          options.push(el);
        }
      });

      if (!this.productHasStages) {
        return options.filter(el => {
          return el.code === this.writeOffJournalCode;
        })
      }
      return options;
    },
    productHasStages () {
      return (Object.values(this.productStages).length > 0)
    },
    productStages () {
      if (this.minusFromEl) {
        return this.$store.getters.getMatchingProductsWithStages(null, { canFeed: true }, { id: this.minusFromEl.product_id })
      }
      return {};
    },
    lineOptionsInit () {
      const lineIds = Object.values(this.productStages).map(el => el.line_id);
      return this.$store.getters.getConfigOptions('lines', this.$i18n.locale, 'id', lineIds, false, false, true);
    },
    lineOptions () {
      const lineIds = Object.values(this.productStages).map(el => el.line_id);
      if (this.initShift != this.usedShift && this.usedShift != null && this.editShift) {
        return this.$store.getters.getConfigOptions('lines', this.$i18n.locale, 'id', [this.usedShift.line_id], false, false, true);
      }
      return this.$store.getters.getConfigOptions('lines', this.$i18n.locale, 'id', lineIds, false, false, true);
    },
    flowOptions () {
      const flowIds = Object.keys(this.productStages);
      let filteredFlows = this.$store.getters.getConfigOptions(
        'line_flows',
        this.$i18n.locale,
        'id',
        flowIds,
        false,
        false,
        true,
        ['line_id']
      );
      if (this.initShift != this.usedShift && this.usedShift != null && this.editShift) {
        filteredFlows = filteredFlows.filter(el => el.line_id == this.usedShift.line_id)
      }
      return filteredFlows
    },
    flowOptionsUsed () {
      if (this.editEl && this.editEl.line_id) {
        return this.flowOptions.filter(el => el.line_id == this.editEl.line_id);
      }
      return [];
    },
    stageOptions () {
      if (this.productStages && this.editEl && this.editEl.flow_id && this.productStages[this.editEl.flow_id]) {
        return this.productStages[this.editEl.flow_id].stages;
      }
      return [];
    },
    writeOffJournalCode () {
      return 'WRITEOFFJOURNAL';
    },
    isWriteOffJournal () {
      return this.editEl.feeding_type === this.writeOffJournalCode;
    }
  },
  methods: {
    show (productEl) {
      this.minusFromEl = this.BasicHelper.cloneObject(productEl);
      this.editShift = false
      this.shiftIdJournalCheck = null
      this.usedShift = this.BasicHelper.cloneObject(productEl).shift
      this.initShift = this.BasicHelper.cloneObject(productEl).shift
      const el = this.BasicHelper.cloneObject(defaultEl);
      el.factory_product_id = this.minusFromEl.id;
      el.product_id = this.minusFromEl.product_id;
      this.editEl = el;
      this.fillConfigFields()
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    shiftChanged (shift) {
      this.shiftIdJournalCheck = null
      if (shift && (this.initShift == null || shift.id != this.initShift.id)) {
        if (!this.writeOfJournalOptionSelected) {
          this.shiftIdJournalCheck = shift.id
        }
      }
      this.fillConfigFields()
    },
    feedingTypeChanged (val) {
      if (val == 'WRITEOFF') {
        this.editEl.pieces = this.minusFromEl.remainingBales;
        this.editEl.quantity = this.minusFromEl.remainingQuantity;
      }
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        if (this.writeOfJournalOptionSelected || !this.editShift) {
          this.shiftIdJournalCheck = null
        }
        this.ApiRequest.request('fed-products/add-writeoff', this.ApiRequest.REQUEST_POST, { item: reqData, shiftIdJournalCheck: this.shiftIdJournalCheck }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    },
    fillConfigFields () {
      if (this.lineOptions.length > 0) {
        this.editEl.line_id = this.lineOptions[0].value;
        this.editEl.flow_id = this.flowOptionsUsed[0].value;
        this.editEl.stage_id = this.stageOptions[0].id;
      }
    }
  },
  watch: {
    editEl (newEl, oldEl) {
      if (!this.productHasStages) {
        this.editEl.feeding_type = this.writeOffJournalCode;
      }
    },
    'editEl.feeding_type': {
      handler () {
        if (this.isWriteOffJournal) {
          this.editEl.line_id = null;
          this.editEl.flow_id = null;
          this.editEl.stage_id = null;
        } else {
          this.fillConfigFields();
        }
      },
      deep: true
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
