import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

const defaultEl = {
  id: null,
  order: 0,
  weight: 200,
  product_type: null,
  product_id: null,
  groupType: null,
  makeClone: false,
  qualityIds: [],
  qualityCodeIds: []
};

const checkConfigTypeExists = (value, siblings, vm) => {
  if (value == null) return false
  if (value > 0 && vm.editEl.product_id == null && vm.existingConfigs.length > 0) {
    const findConfig = vm.existingConfigs.find(el => el.product_type == value && el.product_id == null);
    if (findConfig != undefined && (findConfig.id != vm.editEl.id || vm.editEl.makeClone == true)) {
      return false;
    }
  }
  return true;
}
const checkConfigTypeAndProductExists = (value, siblings, vm) => {
  if (value > 0 && vm.editEl.product_type != null && vm.existingConfigs.length > 0) {
    const findConfig = vm.existingConfigs.find(el => el.product_type == vm.editEl.product_type && el.product_id == value);
    if (findConfig != undefined && (findConfig.id != vm.editEl.id || vm.editEl.makeClone == true)) {
      return false;
    }
  }
  return true;
}
export default {
  name: 'QualityBaseConfigEdit',
  data () {
    return {
      editEl: defaultEl,
      loading: false
    }
  },
  validations: {
    editEl: {
      weight: { required },
      groupType: { required },
      product_type: { checkConfigTypeExists },
      product_id: { checkConfigTypeAndProductExists }
    }
  },
  props: {
    editPerm: {
      type: String,
      default: 'edit-product-quality-measurements-baseConfig'
    },
    basePath: {
      type: String,
      default: ''
    },
    isModal: {
      type: Boolean,
      default: true
    },
    compactLocales: {
      type: Boolean,
      default: false
    },
    existingConfigs: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    productQualityMeasurementGroupingType () {
      const data = [];
      const thisVar = this;
      this.$store.state.config.productQualityMeasurementGroupingType.forEach(function callback (value, index) {
        data.push({ value: value, text: thisVar.$t('menu.quality.qualityElement.groupType_' + value) });
      });
      return data;
    },
    getTitle () {
      if (this.editEl.id) {
        return this.$t('general.actions.edit');
      }
      return this.$t('general.actions.new');
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission(this.editPerm);
    },
    typeOptions () {
      return this.$store.getters.getConfigOptions('product_types', this.$i18n.locale);
    },
    productOptions () {
      return this.$store.getters.getConfigOptions('products', this.$i18n.locale, 'type_id', this.editEl.product_type);
    },
    qualityOptions () {
      return this.$store.getters.getConfigOptions('product_qualities', this.$i18n.locale);
    },
    qualityCodeOptions () {
      return this.$store.getters.getConfigOptions('product_qualities_code', this.$i18n.locale);
    }
  },
  methods: {
    isOptionChanged () {
      if (this.editEl.isOption) this.editEl.canUseAverageData = false;
    },
    show (elData) {
      if (elData) {
        this.editEl = this.BasicHelper.cloneObject(elData);
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
      }
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        this.ApiRequest.request('config/mwc-custom/quality-measurement-base-config/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
