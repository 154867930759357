var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Tableview',{ref:"tableview",attrs:{"fields":_vm.tableHeaders,"loading":_vm.loading,"items":_vm.tableItems,"filters":_vm.tableFilterConfig,"filterValues":_vm.filterValues,"tableClass":"table-striped table-sm table-hover","displayColFiltersAlways":true,"displayFilterActions":true,"filterActionAlign":"text-end","filterActionColClass":"position-relative","exportCols":_vm.exportCols,"tableSorting":{ item: 'typeName' },"filterActions":{download: (_vm.exportCols ? true : false), filters: true, reload: true}},on:{"clearFilters":_vm.clearFilters,"saved":function($event){return _vm.getTableData()},"download":_vm.download},scopedSlots:_vm._u([{key:"linefilter",fn:function(){return [_c('b-form-checkbox-group',{attrs:{"options":_vm.lineOptions},on:{"input":_vm.lineFilterChanged},model:{value:(_vm.filterValues.line_id),callback:function ($$v) {_vm.$set(_vm.filterValues, "line_id", $$v)},expression:"filterValues.line_id"}})]},proxy:true},{key:"product_type",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.PetHelper.getProductConfigName(data.item.product_id, 'type_id'))+" ")]}},{key:"product_ax2009code",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.BasicHelper.getConfigValue('products', 'id', data.item.product_id, 'ax2009code', false))+" ")]}},{key:"usagetype",fn:function(ref){
var data = ref.data;
return [(_vm.$store.getters.isFinalProduct(data.item.product_id))?[_vm._v(" "+_vm._s(_vm.$t('product.isFinalProduct'))+" ")]:(_vm.$store.getters.isSeparatedProduct(data.item.product_id))?[_vm._v(" "+_vm._s(_vm.$t('product.isSeparated'))+" ")]:_vm._e()]}},{key:"qualities",fn:function(ref){
var data = ref.data;
return _vm._l((data.item.qualities),function(quality,id){return _c('div',{key:id},[(quality.id)?[_vm._v(" "+_vm._s(_vm.BasicHelper.getConfigValue('product_qualities', 'id', quality.id, 'name'))+" ")]:[_vm._v(" "+_vm._s(_vm.$t('quality.WITHOUT'))+" ")],_vm._v(" ("+_vm._s(_vm.BasicHelper.formatDecimal(quality.sumStartQuantity, 0, _vm.$i18n.locale))+") ")],2)})}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }