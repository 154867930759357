import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

const defaultEl = {
  id: null,
  product_id: null,
  quality_id: null,
  maxPieces: null,
  maxWeight: null,
  allowBroken: false,
  requirementIds: [],
  customerName: '',
  qualities_codes: []
};

export default {
  name: 'CustomerProductEdit',
  data () {
    return {
      editEl: defaultEl,
      customer: null,
      loading: false,
      productTypeId: null
    }
  },
  components: {
  },
  validations: {
    editEl: {
      product_id: { required },
      quality_id: { required },
      maxPieces: { required },
      maxWeight: { required },
      qualities_codes: { }
    }
  },
  computed: {
    getTitle () {
      if (this.editEl.id) {
        return this.$t('partners.customers.products.actions.edit');
      }
      return this.$t('partners.customers.products.actions.new');
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission('edit-customers');
    },
    productOptions () {
      return this.PetHelper.getFeadableBaleProducts(true).filter(el => (el.inAxapta));
    },
    qualityOptions () {
      return this.$store.getters.getConfigOptions('product_qualities', this.$i18n.locale);
    },
    requirementOptions () {
      return this.$store.getters.getConfigOptions(
        'sales_order_requirements_options',
        this.$i18n.locale,
        'mandatory',
        0
      );
    },
    productTypeOptions () {
      return this.PetHelper.getProductTypes().filter(el => el.inAxapta);
    },
    productFilters () {
      if (!this.customer) {
        return {};
      }
      const productIds = this.productOptions.filter(el => {
        const match = this.customer.products.find(subEl => (subEl.product_id == el.value && subEl.id != this.editEl.id));
        if (match) {
          return false;
        }
        return true;
      }).map(el => el.value);
      const filters = { value: productIds };
      if (this.productTypeId) {
        filters.type_id = this.productTypeId;
      }
      return filters;
    },
    qualitiesCodesOptions () {
      return this.$store.getters.getConfigOptions('product_qualities_code', this.$i18n.locale);
    }
  },
  methods: {
    show (elData, customerId, customer) {
      this.productTypeId = null;
      this.customer = this.BasicHelper.cloneObject(customer);
      if (elData) {
        this.editEl = this.BasicHelper.cloneObject(elData);
        this.editEl.quality_id = this.qualityOptions[0].value;
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
        this.editEl.customer_id = customer.id;
      }
      this.v$.$reset();
      this.$refs['modal-window'].show();
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        this.ApiRequest.request('customers/edit/' + this.editEl.customer_id + '/products', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      this.$refs['modal-window'].hide();
    }
  },
  mounted () {
    this.isMounted = true;
  },
  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'CustomerMaterialEdit' }) }
  }
}
