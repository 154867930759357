import { helpers, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import LinkToDifferentSystem from 'ebg-vue-common/src/components/common/LinkToDifferentSystem.vue'
import PetHelper from '@/helpers/PetHelper';
import PartnerPicker from '@/components/pet/partners/picker/PartnerPicker.vue';

const defaultCostEl = {
  id: null,
  chargeCode_id: null,
  plannedCost: null,
  actualCost: null,
  generatedCost: null,
  additionalCost: null,
  totalActualCost: null,
  D365CostNumber: null,
  cargo_id: null,
  order_id: null,
  transport_provider_id: null,
  billdata: []
}

const checkDuplicateCostType = (value, siblings, vm) => {
  const checkIds = []
  let res = true;
  if (value.length > 0) {
    value.forEach(el => {
      if (!res) return;
      if (checkIds.includes(el.chargeCode_id) && el.chargeCode_id != null) {
        res = false
      } else {
        if (el.chargeCode_id != null) checkIds.push(el.chargeCode_id)
      }
    })
  }
  return res;
}
export default {
  name: 'TransportCostList',
  components: {
    PartnerPicker,
    LinkToDifferentSystem
  },
  props: {
    orderId: {
      type: Number,
      default: null
    },
    cargoId: {
      type: Number,
      default: null
    },
    products: {
      type: Array,
      default: () => null
    },
    readonly: {
      type: Boolean,
      default: true
    },
    usedProducts: {
      type: Array,
      default: () => []
    },
    transport_costs: {
      type: Array,
      default: () => []
    },
    checkCargoData: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      default: null
    },
    cargo: {
      type: Object,
      default: null
    },
    size: {
      type: String,
      default: ''
    },
    currencyCode: {
      type: String,
      default: null
    },
    withActualCost: {
      type: Boolean,
      default: true
    },
    permbase: {
      type: String,
      default: ''
    },
    canEditBillnumber: {
      type: Boolean,
      default: false
    },
    providerTransport: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      currentReq: null,
      transportCosts: []
    };
  },
  validations: {
    transportCosts: {
      $each: helpers.forEach({
        chargeCode_id: { required }
      }),
      checkDuplicateCostType
    }
  },
  computed: {
    editPermComp () {
      return 'edit-purchase';
    },
    basePathComp () {
      return 'cargo-orders/viewTransportCosts'
    },
    tableHeaders () {
      const headers = [
        { key: 'chargeCode_id', label: this.$t('order.orderCostType'), type: 'slot', slotcode: 'chargeCodeType' },
        { key: 'plannedCost', label: this.$t('order.plannedCost', { currency: PetHelper.getCurrencyNameFromCode(this.currencyCode) }), format: 'formatDecimal', precision: 2, type: 'slot', slotcode: 'plannedCost' }
      ];
      if (this.checkCargoData) {
        headers.push({ key: 'actualCost', label: this.$t('order.generatedCost', { currency: PetHelper.getCurrencyNameFromCode(this.currencyCode) }), format: 'formatDecimal', precision: 2, type: 'slot', slotcode: 'generatedCost' });
      }
      headers.push({ key: 'transport_provider_id', label: this.$t('cargo.transport_provider_id'), type: 'slot', slotcode: 'transportprovider' });
      headers.push({ key: 'billdata', label: this.$t('downtimes.comment'), type: 'slot', slotcode: 'comment' });
      if (this.withActualCost) {
        if (this.isAdmin) {
          headers.push({ key: 'actualCost', label: this.$t('order.actualCost', { currency: PetHelper.getCurrencyNameFromCode(this.currencyCode) }), type: 'slot', slotcode: 'actualCost' });
          headers.push({
            key: 'additionalCost',
            label: this.$t('order.additionalCost', { currency: PetHelper.getCurrencyNameFromCode(this.currencyCode) }),
            format: 'formatDecimal',
            precision: 2
          });
        }
        headers.push({ key: 'totalActualCost', label: this.$t('order.totalActualCost', { currency: PetHelper.getCurrencyNameFromCode(this.currencyCode) }), type: 'slot', slotcode: 'totalActualCost' });
      }
      if (this.checkCargoData) {
        headers.push({ key: 'D365CostNumber', label: this.$t('order.D365Cost'), type: 'slot', slotcode: 'D365Cost' })
        headers.push({ key: 'billdata', label: this.$t('order.billdata'), type: 'slot', slotcode: 'billdata', classTd: 'align-top' })
      }
      const actions = [];
      if (this.canEdit && this.canEditTransportCost) {
        actions.push({ label: this.$t('general.actions.delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-sm btn-danger', ifconfig: { func: this.canDeleteCost, useFunc: true } });
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    canEdit () {
      return !this.readonly;
    },
    canEditBillnumbers () {
      return this.canEditBillnumber && this.permbase && this.cargoId && !this.$store.getters.isFeatureEnabled('d365_api', `${this.permbase}.edit`)
    },
    chargeCodeOptions () {
      const data = this.$store.getters.getConfigOptions('order_cost_types', this.$i18n.locale);
      return data
    },
    transportCostsUsedTypeIds () {
      return this.transportCosts.map(el => el.chargeCode_id)
    },
    canEditTransportCost () {
      let res = true;
      const cargoStatuses = this.$store.getters.getConfigOptions('cargo_statuses', this.$i18n.locale).find(el => el.code == 'FINISHED')
      if (this.order != null && this.order.cargos.length > 0 && !this.checkCargoData) {
        const findCardFinished = this.order.cargos.find(el => el.status_id == cargoStatuses.value)
        if (findCardFinished != null && findCardFinished != undefined) res = false
      }
      if (this.cargo && this.checkCargoData) {
        if (this.cargo.status_id == cargoStatuses.value) res = false
      }
      return res
    },
    transportProviderFilter () {
      return { isActual: 1, order: 'name,ASC' };
    },
    transportChargeId () {
      return this.$store.getters.getParamFromConfigByFilter('order_cost_types', 'code', 'TRANSPORTS', 'id')
    },
    isAdmin () {
      return this.ApiRequest.userHasRole('admin');
    }
  },
  methods: {
    chargeCodeOptionsFiltered (id = 0) {
      let data = this.chargeCodeOptions;
      if (this.transportCostsUsedTypeIds.length > 0) {
        data = data.filter(el => !this.transportCostsUsedTypeIds.includes(el.value) || el.value == id)
      }
      return data;
    },
    getTableData () {
      if (this.orderId) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.loading = true;
        const reqData = {
          orderId: this.orderId,
          cargoId: this.cargoId,
          checkCargoData: this.checkCargoData
        }
        this.currentReq = this.ApiRequest.request(this.basePathComp, this.ApiRequest.REQUEST_GET, reqData, (response) => {
          if (response.data) {
            this.attachOptions(response.data);
            this.transportCosts = response.data;
            this.loading = false;
          }
        });
      }
    },
    addLine () {
      const el = this.BasicHelper.cloneObject(defaultCostEl);
      el.cargo_id = this.cargoId
      el.order_id = this.orderId
      this.transportCosts.push(el);
      this.$refs.tableview.$forceUpdate();
    },
    deleteLine (el) {
      el.deleted = 1;
      const idx = this.transportCosts.findIndex(el => el.deleted == 1);
      this.transportCosts.splice(idx, 1);
      this.$refs.tableview.$forceUpdate();
    },
    productChanged (val, index) {
      this.attachedRelatedConfig(index);
      this.$emit('productChanged');
    },
    attachOptions (costs) {
      Object.keys(costs).forEach(key => {
        costs[key].billdataoptions = [];
        if (costs[key].billdata) {
          Object.values(costs[key].billdata).forEach(param => {
            costs[key].billdataoptions.push({ text: param, value: param });
          });
        }
      })
      return costs;
    },
    getPlannedCostNotMatching (el) {
      if (el.d365PlannedCost && el.d365PlannedCost != el.plannedCost) {
        return true;
      }
      return false;
    },
    canDeleteCost (el) {
      return !(el.D365CostNumber);
    },
    getTransportCosts () {
      return this.BasicHelper.cloneObject(this.transportCosts);
    },
    transportProviderChanged (el, index) {
      if (el) {
        this.transportCosts[index].transport_provider_id = el.id;
      } else {
        this.transportCosts[index].transport_provider_id = null;
      }
    },
    getProviderName (el) {
      return el.vendor?.name;
    },
    costFromBills (index, mainCost, additionalCost = null) {
      let billPrice = this.transportCosts[index].billdata?.reduce((sum, line) => sum + line.price, 0);
      if (billPrice && billPrice !== mainCost) {
        if (additionalCost) {
          billPrice += additionalCost;
        }
        billPrice = this.BasicHelper.formatDecimal(billPrice, 2, this.$i18n.locale);
        return `(${billPrice})`;
      }
    }
  },
  mounted () {
    if (this.transportCosts == null) {
      this.getTableData();
    }
  },
  watch: {
    transport_costs: {
      immediate: true,
      handler (val) {
        if (val !== null) {
          this.attachOptions(val);
          this.transportCosts = val;
        }
      }
    },
    orderId: {
      immediate: true,
      handler (val) {
        if (this.transportCosts == null) {
          this.getTableData()
        }
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
