import { requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

export default {
  name: 'CargoBookApprove',
  data () {
    return {
      loading: false,
      btn: null,
      getTitle: null,
      message: null,
      d365data: null,
      additionalMessageData: {}
    }
  },
  validations: {
    cargo: {
      billdate: {
        required: requiredIf(function () {
          return !this.isTransfer;
        })
      },
      billnumber: {
        required: requiredIf(function () {
          return (this.selectedOrdertype && this.selectedOrdertype.code == 'IMP');
        })
      },
      taxdate: {
        required: requiredIf(function () {
          return !this.isTransfer;
        })
      },
      documentdate: {
        required: requiredIf(function () {
          return !this.isTransfer;
        })
      }
    }
  },
  components: {
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    },
    cargoType: {
      type: String,
      default: null
    },
    cargo: {
      type: Object,
      default: null
    }
  },
  computed: {
    tabOptions () {
      const options = [];
      options.push({ code: 'mainDataView', text: this.$t('cargo.bookApprove.mainData') })
      options.push({ code: 'bookingPdfView', text: this.$t('cargo.bookApprove.bookingPdfView') })
      return options
    },
    selectedOrdertype () {
      return this.$store.getters.getDataFromConfigWithFilter('order_cargo_types', 'id', this.cargo.order_cargo_type_id);
    },
    isTransfer () {
      return this.selectedOrdertype && this.selectedOrdertype.code == 'TNF';
    }
  },
  methods: {
    show (btn, title, message) {
      if (!this.cargo.documentdate) {
        this.cargo.documentdate = this.cargo.deliveryDate
      }
      if (!this.cargo.billdate) {
        this.cargo.billdate = this.cargo.deliveryDate;
      }
      if (!this.cargo.taxdate) {
        this.cargo.taxdate = this.cargo.billdate;
      }
      this.btn = btn
      this.getTitle = title
      this.message = message;
      this.$refs['modal-window'].show();
      this.getBookingInfo();
    },
    getBookingInfo () {
      this.d365data = null;
      if (!this.isTransfer) {
        this.loading = true;
        this.ApiRequest.request(`cargo-orders/checkD365ViewData/${this.cargo.id}`, this.ApiRequest.REQUEST_GET, { }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
            this.loading = false;
          } else {
            if (response.data) {
              this.d365data = response.data;
              this.loading = false;
            }
          }
        });
      }
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.$emit('doCargoBookAction', this.btn);
        this.close()
      }
    },
    close () {
      this.$refs['modal-window'].hide();
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
