import FactoryProductDamagedEdit from '@/components/pet/factoryproduct/damaged/edit/FactoryProductDamagedEdit.vue'

const defaultFilterValues = {
  created: null,
  code: '',
  productname: '',
  decision: [],
  isDamaged: []
};

export default {
  name: 'FactoryProductDamagedList',
  components: {
    FactoryProductDamagedEdit
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { item: 'created_at', direction: 'desc' },
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false
    };
  },
  computed: {
    basePathComp () {
      return 'factory-products/damaged'
    },
    basePerm () {
      return 'warehouse-damaged';
    },
    baseTransParam () {
      return 'factory-product';
    },
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-' + this.basePerm);
    },
    canMark () {
      return this.ApiRequest.userHasPermission('mark-' + this.basePerm);
    },
    tableHeaders () {
      const headers = [
        { key: 'created_at', label: this.$t(this.baseTransParam + '.created_at'), type: 'datetime', filterKey: 'created' },
        { key: 'factoryproduct.code', label: this.$t(this.baseTransParam + '.code'), sortable: false, filterKey: 'code' },
        { key: 'factoryproduct.product_id', label: this.$t(this.baseTransParam + '.product_id'), format: 'formatFromConfig', configKey: 'products', configParam: 'name', filterKey: 'productname' },
        { key: 'factoryproduct.quality_id', label: this.$t(this.baseTransParam + '.qualitycode'), format: 'formatFromConfig', configKey: 'product_qualities', configParam: 'displaycode' },
        { key: 'factoryproduct.quality_id', label: this.$t(this.baseTransParam + '.quality_id'), format: 'formatFromConfig', configKey: 'product_qualities', configParam: 'name' },
        { key: 'startWeight', label: this.$t(this.baseTransParam + '.damaged.startWeight'), format: 'formatDecimal', precision: 0 },
        { key: 'decision_id', label: this.$t(this.baseTransParam + '.damaged.decision_id'), format: 'formatFromConfig', configKey: 'damaged_product_decisions', configParam: 'name' },
        { key: 'isDamaged', label: this.$t(this.baseTransParam + '.damaged.isDamaged'), format: 'formatYesNo', filterKey: 'isDamaged' }
      ];
      const actions = [];
      actions.push({
        label: this.$t(this.baseTransParam + '.actions.edit'),
        emit: 'editItem',
        bicon: 'pencil-fill',
        class: 'btn-primary',
        ifconfig: { param: 'decision_user_id', value: null }
      });
      actions.push({
        label: this.$t(this.baseTransParam + '.damaged.actions.view'),
        emit: 'editItem',
        bicon: 'eye-fill',
        class: 'btn-primary',
        ifconfig: { param: 'decision_user_id', value: null, notCheck: true }
      });
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    tableExpandConfig () {
      return null;
    },
    tableFilterConfig () {
      const filters = { };
      filters.created = { type: 'date-range', label: '', colOnlyFilter: true };
      filters.code = { type: 'text', label: '', colOnlyFilter: true };
      filters.productname = { type: 'text', label: '', colOnlyFilter: true };
      filters.decision = { type: 'checkbox', label: this.$t(this.baseTransParam + '.damaged.decision_id'), options: this.decisionOptions, colOnlyFilter: false, class: 'col-12' };
      filters.isDamaged = { type: 'checkbox', label: '', colOnlyFilter: true, options: this.yesNoOptions };
      return filters;
    },
    requestPath () {
      let path = this.ApiRequest.addGetParamsToPath(this.basePathComp + '/list?perPage=' + this.paging.limit + '&page=' + this.paging.page, this.filterValues, true);
      if (this.tableSorting.item) {
        path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
      }
      return path;
    },
    tableActions () {
      const actions = { download: false };
      if (this.canMark) {
        actions.add = { title: this.$t('general.actions.add') };
      }
      return actions;
    },
    decisionOptions () {
      const options = this.$store.getters.getConfigOptions('damaged_product_decisions', this.$i18n.locale);
      options.unshift({ value: null, code: null, text: '-' });
      return options;
    },
    yesNoOptions () {
      return this.BasicHelper.yesNoOptions();
    },
    deviceIds () {
    }
  },
  methods: {
    getTableData () {
      if (this.basePathComp) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        this.loading = true;
        const self = this;
        this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, function (response) {
          if (response.data) {
            self.tableItems = response.data;
            self.paging = response.paging;
            self.loading = false;
          }
        });
      }
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    },
    editItem (el) {
      this.$refs.itemEditFrm.show(el);
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.getTableData();
    this.isMounted = true;
  },
  watch: {
    basePathComp: {
      immediate: true,
      handler (val) {
        if (val) {
          this.isMounted = false;
          this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
          this.getTableData();
          this.isMounted = true;
        }
      }
    }
  }
}
