<template>
  <div class="shift-techno-data">
    <ShiftDeviceMeasureList
        :shiftId="currentShift.id"
        :shiftTypeId="currentShift.type_id"
        viewtype="techno"
        :currentShift="currentShift"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ShiftDeviceMeasureList from '@/components/pet/shift/devicemeasurelist/ShiftDeviceMeasureList.vue'

export default {
  name: 'ShiftTechnoData',
  data () {
    return {
    }
  },
  components: {
    ShiftDeviceMeasureList
  },
  computed: {
    ...mapState({
      currentShift: state => state.pet.currentShift
    }),
    shiftId () {
      return this.currentShift?.id;
    }
  },
  watch: {
    currentShift: {
      immediate: true,
      handler () {
      }
    }
  }
}
</script>
