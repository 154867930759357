import useVuelidate from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import DeviceScaleInput from '@/components/pet/device/scaleinput/DeviceScaleInput.vue'
import ShiftFinder from '@/components/pet/shift/finder/ShiftFinder.vue'
import { mapState } from 'vuex';
import PetHelper from '@/helpers/PetHelper';
import AdditionalUsedProducts from '@/components/pet/manufacture/produced/additionalUsedProducts/AdditionalUsedProducts.vue';
import RoundedBlockList from 'ebg-vue-common/src/components/common/roundedblocklist/RoundedBlockList.vue';

const defaultEl = {
  id: null,
  line_id: null,
  shift_id: null,
  flow_id: null,
  type_id: null,
  product_id: null,
  stage_id: null,
  wetRate: null,
  startQuantity: null,
  wetQuantity: null,
  created_at: null,
  usagefeedings: [],
  isImpurity: false,
  factory_product_impurities: []
};

const isValidWeightCheck = (value, siblings, vm) => {
  if ((vm.stageProduct.produceEmptyWeight && (vm.editEl.id == null || (value === '' || value === null))) || value >= vm.factoryProductsMinWetQuantity) return true;
  return false;
};

const defaultProductFilters = {
  type_id: null,
  id: null
}

export default {
  name: 'ProducedEdit',
  components: {
    AdditionalUsedProducts,
    DeviceScaleInput,
    ShiftFinder,
    RoundedBlockList
  },
  data () {
    return {
      editEl: defaultEl,
      productFilters: this.BasicHelper.cloneObject(defaultProductFilters),
      loading: false,
      usedShift: null,
      printer: null,
      printerTemplates: [],
      editShift: false,
      workstationId: null,
      editProduct: true,
      selectedFeedings: null,
      feedingEdit: false,
      availableAdditionalUsedProducts: []
    }
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'product'
    },
    withShiftChange: {
      type: Boolean,
      default: false
    },
    useWarehouseWorkstations: {
      type: Boolean,
      default: false
    },
    skipShiftFlowProduced: {
      type: Boolean,
      default: false
    },
    currentShift: {
      type: Object,
      default: () => {}
    },
    currentFlowId: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapState({ factoryProductsMinWetQuantity: state => state.config.factoryProductsMinWetQuantity }),

    tableHeaders () {
      const headers = [
        { key: 'product_id', label: this.$t('produced.produceuses.product_id'), format: 'formatFromConfig', configKey: 'products', configParam: 'name' },
        { key: 'isDetector', label: this.$t('produced.impurity.isDetector'), type: 'slot', slotcode: 'isDetector' },
        { key: 'isAdmixture', label: this.$t('produced.impurity.isAdmixture'), type: 'slot', slotcode: 'isAdmixture' },
        { key: 'admixture', label: this.$t('produced.impurity.admixture'), type: 'slot', slotcode: 'admixture' }
      ];
      return headers;
    },

    baseTransParam () {
      return 'produced';
    },
    getTitle () {
      if (this.editEl && this.editEl.id) {
        return this.$t(this.baseTransParam + '.actions.edit');
      }
      return this.$t(this.baseTransParam + '.actions.new');
    },
    usedMaterialGroupTypeOptions () {
      const data = []
      const vm = this
      JSON.parse(this.$store.state.config.usedMaterialGroupType).forEach(function callback (value, index) {
        data[index] = {}
        data[index].value = value.value;
        data[index].text = vm.$t('technologicalStage.produceuses.usedMaterialGroupTypeOptions.' + value.name);
        data[index].initName = value.name;
      });
      return data
    },
    canEdit () {
      if (!PetHelper.validShiftDateInterval(this.currentShift)) return false;
      if (this.editEl && !this.editEl.id) {
        return true;
      }
      if (this.editEl.produced_journal_id !== null || this.editEl.codeChangeDeleted) {
        return false;
      }
      if (this.editEl.shift.closedAt && this.ApiRequest.userHasPermission('edit-closed-shift-produced')) {
        return true;
      }
      return !this.editEl.shift.closedAt && this.ApiRequest.userHasPermission('edit-shift-produced');
    },
    canEditTime () {
      return this.canEdit && this.ApiRequest.userHasPermission('edittime-shift-produced');
    },
    canEditFedproducts () {
      return this.ApiRequest.userHasPermission('edit-closed-shift-produced-shift');
    },
    canChangeShift () {
      return this.withShiftChange && this.ApiRequest.userHasPermission('edit-closed-shift-produced-shift');
    },
    flowOptions () {
      if (this.editEl) {
        return this.$store.getters.getConfigOptions('line_flows', this.$i18n.locale, 'line_id', this.editEl.line_id)
      }
      return [];
    },
    defaultMeasureTypeId () {
      if (this.editEl) {
        return this.$store.getters.getParamFromConfig('products', this.editEl.product_id, 'default_measure_type_id');
      }
      return null;
    },
    currentStage () {
      if (this.producibleProducts && this.producibleProducts[this.editEl.flow_id]) {
        return this.producibleProducts[this.editEl.flow_id].stages.find(el => el.id == this.editEl.stage_id);
      }
      return null;
    },
    shiftFlowProducedFilteredProducts () {
      const filteredIds = []
      if (this.type == 'isFinalProduct' && this.currentFlowId != null && !this.skipShiftFlowProduced) {
        const shiftFlowProducedConfig = this.currentShift.shift_flow_produced_config
        if (shiftFlowProducedConfig != null && shiftFlowProducedConfig != undefined &&
            shiftFlowProducedConfig.length > 0) {
          shiftFlowProducedConfig.forEach(shiftFlowProducedConfigEl => {
            if (shiftFlowProducedConfigEl.flow_id == this.currentFlowId) {
              if (shiftFlowProducedConfigEl.product_ids_data_set != null && shiftFlowProducedConfigEl.product_ids_data_set != undefined &&
                  shiftFlowProducedConfigEl.product_ids_data_set.length > 0) {
                shiftFlowProducedConfigEl.product_ids_data_set.forEach(productId => {
                  filteredIds.push(productId)
                })
              }
            }
          })
        }
      }
      return filteredIds;
    },
    shiftFlowProducedFilteredStagesByProduct () {
      const filteredIds = []
      if (this.type == 'isFinalProduct' && this.currentFlowId != null && !this.skipShiftFlowProduced) {
        const shiftFlowProducedConfig = this.currentShift.shift_flow_produced_config
        if (shiftFlowProducedConfig != null && shiftFlowProducedConfig != undefined &&
            shiftFlowProducedConfig.length > 0) {
          shiftFlowProducedConfig.forEach(shiftFlowProducedConfigEl => {
            if (shiftFlowProducedConfigEl.flow_id == this.currentFlowId) {
              if (shiftFlowProducedConfigEl.product_ids_data_set != null && shiftFlowProducedConfigEl.product_ids_data_set != undefined &&
                  shiftFlowProducedConfigEl.product_ids_data_set.length > 0) {
                if (shiftFlowProducedConfigEl.product_ids_data_set.includes(this.editEl.product_id)) {
                  filteredIds.push(shiftFlowProducedConfigEl.technological_stage_id)
                }
              }
            }
          })
        }
      }
      return filteredIds
    },
    currentWorkstation () {
      return this.workstationOptions.find(el => el.value == this.workstationId);
    },
    deviceIds () {
      if (this.currentWorkstation) {
        return this.currentWorkstation.device_ids;
      }
      return [];
    },
    productTypeOptions () {
      if (this.producibleProducts && this.producibleProducts[this.editEl.flow_id]) {
        return this.$store.getters.getConfigOptions(
          'product_types',
          this.$i18n.locale,
          'id',
          Object.values(this.producibleProducts[this.editEl.flow_id].products).map(el => el.type_id),
          false,
          false,
          true
        );
      }
      return [];
    },
    productOptions () {
      if (this.producibleProducts && this.producibleProducts[this.editEl.flow_id]) {
        let dataSet = this.BasicHelper.sortArrayByKeyValue(Object.values(this.producibleProducts[this.editEl.flow_id].products).filter(el => {
          let res = true;
          Object.keys(this.productFilters).forEach(key => {
            if (this.productFilters[key]) {
              if (!(key == 'id' && !el.inAxapta)) {
                if (Array.isArray(this.productFilters[key])) {
                  res = res && (this.productFilters[key].includes(el[key]));
                } else {
                  res = res && (this.productFilters[key] == el[key]);
                }
              }
            }
          });
          return res;
        }));
        if (dataSet.length > 0 && this.shiftFlowProducedFilteredProducts.length > 0) {
          dataSet = dataSet.filter(el => this.shiftFlowProducedFilteredProducts.includes(el.id))
        }
        return dataSet
      }
      return [];
    },
    stageOptions () {
      if (this.producibleProducts && this.producibleProducts[this.editEl.flow_id] && this.editEl.product_id) {
        return this.producibleProducts[this.editEl.flow_id].stages.filter(stage => {
          return (
            (this.shiftFlowProducedFilteredStagesByProduct.length > 0 ? this.shiftFlowProducedFilteredStagesByProduct.includes(stage.id) : true) &&
            this.producibleProducts[this.editEl.flow_id].products &&
            this.producibleProducts[this.editEl.flow_id].products[this.editEl.product_id] &&
            this.producibleProducts[this.editEl.flow_id].products[this.editEl.product_id].stages &&
            this.producibleProducts[this.editEl.flow_id].products[this.editEl.product_id].stages[stage.id]
          );
        });
      }
      return [];
    },
    workstationIds () {
      if (this.useWarehouseWorkstations) {
        return this.$store.getters.getWarehouseWorkstationIds();
      } else if (this.currentStage) {
        return this.currentStage.workstation_ids
      }
      return [];
    },
    workstationOptions () {
      return this.$store.getters.getConfigOptions(
        'workstations',
        this.$i18n.locale,
        'id',
        this.workstationIds,
        false,
        false,
        true,
        ['device_ids']
      );
    },
    stageProduct () {
      if (!this.editEl.flow_id || !this.editEl.stage_id || !this.editEl.product_id) {
        return null;
      }
      const flowStage = this.$store.getters.getDataFromConfigWithFilter('line_flows', 'id', this.editEl.flow_id)
        .stages.find(st => st.id == this.editEl.stage_id)
      if (flowStage) {
        return flowStage.products.find(pr => pr.product_id === this.editEl.product_id);
      }
      return null;
    },
    producibleProducts () {
      const filter = {
        isFinalProduct: { isFinalProduct: true },
        isSeparated: { isSeparated: true }
      }[this.type];
      if (this.usedShift) {
        return this.$store.getters.getMatchingProductsWithStages(this.usedShift.line_id, { canProduce: true }, filter);
      }
      return [];
    },
    deviceOptions () {
      const options = Object.values(this.$store.getters.getDevicesByType('PRINTZ')).filter(el => {
        return this.deviceIds.includes(el.id);
      });
      options.forEach(option => {
        option.text = this.BasicHelper.getTranslation(option.name);
      });
      return options;
    },
    flowHasProducts () {
      return this.producibleProducts[this.editEl.flow_id];
    },
    printerTemplateOptions () {
      return this.$store.getters.getConfigOptions('printer_templates', this.$i18n.locale);
    },
    selectedDevice () {
      return this.deviceOptions.find(el => el.code == this.printer);
    },
    devicePrinterTemplates () {
      const options = [];
      if (this.selectedDevice) {
        this.printerTemplateOptions.forEach(el => {
          if (this.selectedDevice.templates[el.value]) {
            this.selectedDevice.templates[el.value].text = el.text;
            this.selectedDevice.templates[el.value].code = el.code;
            options.push(this.BasicHelper.cloneObject(this.selectedDevice.templates[el.value]));
          }
        });
      }
      return options;
    },
    produsesConfiguration () {
      if (this.stageProduct && this.stageProduct.produceuses) {
        const config = {
          always: [],
          groups: {}
        }
        this.stageProduct.produceuses.forEach(el => {
          if (el.alwaysAdd) {
            el.name = this.BasicHelper.getConfigValue('products', 'id', el.product_id, 'name', true);
            el.title = el.name + ' (' + el.quantity + ')';
            config.always.push(el)
          } else {
            if (!config.groups[el.usedMaterialGroupType]) {
              config.groups[el.usedMaterialGroupType] = {};
            }
            if (!config.groups[el.usedMaterialGroupType][el.product_group_id]) {
              config.groups[el.usedMaterialGroupType][el.product_group_id] = []
              if (el.usedMaterialGroupType == 0) {
                config.groups[el.usedMaterialGroupType][el.product_group_id].push({ id: null, name: this.$t('produced.produceuses-none'), product_id: null, canEditQuantity: false, quantity: 0 });
              }
            }
            el.name = this.BasicHelper.getConfigValue('products', 'id', el.product_id, 'name', true);
            config.groups[el.usedMaterialGroupType][el.product_group_id].push(el);
          }
        });
        if (config.always.length || Object.keys(config.groups).length) {
          return config;
        }
      }
      return null;
    },

    yesNoOptions () {
      return this.BasicHelper.yesNoOptions();
    }
  },
  methods: {
    flowChanged () {
      const match = this.productTypeOptions.find(el => el.value == this.editEl.type_id);
      if (!match || !this.editEl.type_id) {
        this.editEl.type_id = (this.productTypeOptions.length > 0 ? this.productTypeOptions[0].value : null);
      }
      this.productTypeChanged();
    },
    productTypeChanged () {
      this.productFilters.type_id = this.editEl.type_id;
      const match = this.productOptions.find(el => el.id == this.editEl.product_id);
      if ((!match || !this.editEl.product_id) && !['isSeparated', 'isFinalProduct'].includes(this.type)) {
        this.editEl.product_id = (this.productOptions.length > 0 ? this.productOptions[0].id : null);
      }
      this.productChanged();
    },
    productChanged (productId) {
      const match = this.stageOptions.find(el => el.id == this.editEl.stage_id);
      this.editEl.isImpurity = false;
      this.editEl.factory_product_impurities = [];
      if (productId) {
        this.impurities(productId);
      }
      if (!match || !this.editEl.stage_id) {
        this.editEl.stage_id = (this.stageOptions.length > 0 ? this.stageOptions[0].id : null);
      }
      this.setDefaultFeedings();
      this.stageChanged();
    },

    impurities (productId) {
      const product = this.productOptions.find(el => el.id == productId);
      if (product && product.availableImpurities && product.availableImpurities.length > 0) {
        this.editEl.isImpurity = true;
        product.availableImpurities.forEach((id, key) => {
          const existImpurities = this.editEl.factory_product_impurities.find(el => el.product_id == id)
          if (existImpurities === undefined) {
            this.editEl.factory_product_impurities.push({
              product_id: id,
              isDetector: true,
              isAdmixture: false,
              admixture: null
            })
          }
        });
      }
    },

    stageChanged () {
      if (!this.editEl.id) {
        this.editEl.wetQuantity = null;
      }
      const match = this.workstationOptions.find(el => el.value == this.workstationId);
      if (!match || !this.workstationId) {
        this.workstationId = (this.workstationOptions.length > 0 ? this.workstationOptions[0].value : null);
      }
      this.workstationChanged();
      this.setDefaultFeedings();
    },
    workstationChanged () {
      this.printer = (this.deviceOptions.length > 0 ? this.deviceOptions[0].code : null);
      this.printerChanged();
      this.weightChanged();
    },
    printerChanged () {
      this.printerTemplates.splice(0, this.printerTemplates.length)
      if (this.devicePrinterTemplates.length > 0) {
        const matches = this.devicePrinterTemplates.filter(el => el.isDefault == 1);
        if (matches.length == 0) {
          matches.push(this.devicePrinterTemplates[0]);
        }
        matches.forEach(match => {
          this.printerTemplates.push(match.code);
        });
      }
    },
    weightChanged () {
      if (this.stageProduct && this.stageProduct.wetRate) {
        this.editEl.wetRate = this.stageProduct.wetRate;
      } else {
        this.editEl.wetRate = 1;
      }
      this.v$.$touch();
      // limit to 3 decimal places & remove trailing zeros
      this.editEl.startQuantity = this.editEl.wetQuantity ? Number((this.editEl.wetQuantity * this.editEl.wetRate).toFixed(3)) : null;
    },
    show (elData, shift = null, flowId = null) {
      this.editProduct = true;
      this.editShift = false;
      this.feedingEdit = false;
      this.usedShift = this.BasicHelper.cloneObject(shift);
      if (elData) {
        this.editProduct = false;
        if (this.canChangeShift) {
          this.ApiRequest.request('factory-products/view-by-code/' + elData.code, this.ApiRequest.REQUEST_GET, {}, (response) => {
            if (response.error) {
              this.ApiRequest.displayErrorDialog(response, this);
              this.close();
            } else {
              this.usedShift = this.BasicHelper.cloneObject(response.data.shift);
              this.editEl = this.BasicHelper.cloneObject(response.data);
              this.editEl.type_id = this.BasicHelper.getConfigValue('products', 'id', this.editEl.product_id, 'type_id');
              this.editEl.line_id = this.usedShift.line_id;
            }
            this.stageChanged();
            this.loading = false;
          });
        } else {
          this.editEl = this.BasicHelper.cloneObject(elData);
          this.editEl.type_id = this.BasicHelper.getConfigValue('products', 'id', this.editEl.product_id, 'type_id');
          this.editEl.line_id = this.usedShift.line_id;
          this.impurities(this.editEl.product_id);
          this.stageChanged();
        }
      } else {
        const prevEl = this.BasicHelper.cloneObject(this.editEl);
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
        this.editEl.type_id = prevEl.type_id;
        if (!['isSeparated', 'isFinalProduct'].includes(this.type)) {
          this.editEl.product_id = prevEl.product_id;
        }
        this.editEl.stage_id = prevEl.stage_id;
        this.editEl.line_id = this.usedShift.line_id;
        this.editEl.shift_id = this.usedShift.id;
        this.editEl.workstationId = prevEl.workstationId;
        if (!this.editEl.flow_id && this.flowOptions.length > 0) {
          this.editEl.flow_id = this.flowOptions[0].value;
          if (this.currentFlowId != null && this.flowOptions.find(el => el.value == this.currentFlowId) != undefined) {
            this.editEl.flow_id = this.flowOptions.find(el => el.value == this.currentFlowId).value
          }
        }
        this.flowChanged();
      }
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
      this.setDefaultFeedings();
    },
    showByCode (code) {
      this.editProduct = false;
      this.editEl = this.BasicHelper.cloneObject(defaultEl);
      this.loading = true;
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
      this.ApiRequest.request('factory-products/view-by-code/' + code, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.error) {
          this.$store.commit('toastAdd', {
            context: 'danger',
            message: this.ApiRequest.getApiResponseErrorMessage(response, {})
          })
          this.loading = false;
          this.close();
        } else {
          this.usedShift = this.BasicHelper.cloneObject(response.data.shift);
          this.editEl = this.BasicHelper.cloneObject(response.data);
          this.editEl.type_id = this.BasicHelper.getConfigValue('products', 'id', this.editEl.product_id, 'type_id');
          this.editEl.line_id = this.usedShift.line_id;
          this.editEl.isImpurity = null;
          this.impurities(this.editEl.product_id);
          this.loading = false;
          this.stageChanged();
        }
      });

      this.v$.$reset();
    },
    confirmShiftOptionReset (el, approved) {
      if (approved) {
        this.save(el.printLabelsEdit);
      }
    },
    setAllFeedingsFromConfig () {
      this.editEl.usagefeedings = [];
      if (this.selectedFeedings) {
        Object.entries(this.selectedFeedings).forEach(([groupMode, groupData]) => {
          Object.entries(groupData).forEach(([groupId, groupOption]) => {
            if (groupMode < 2) {
              const option = this.getFeedingOption(groupOption, groupId);
              if (option) {
                this.editEl.usagefeedings.push(option);
              }
            } else {
              Object.values(groupOption).forEach(el => {
                const option = this.getFeedingOption(el, groupId);
                if (option) {
                  this.editEl.usagefeedings.push(option);
                }
              });
            }
          });
        });
      }
      if (this.produsesConfiguration && this.produsesConfiguration.always) {
        this.produsesConfiguration.always.forEach(el => {
          this.editEl.usagefeedings.push(this.getFeedingOption(el, el.product_group_id))
        });
      }
    },
    getFeedingOption (el, groupId) {
      if (el.quantity && el.product_id) {
        return {
          id: null,
          product_id: el.product_id,
          quantity: el.quantity,
          quantityInKg: el.quantityRatio * el.quantity,
          measure_type_id: el.measure_type_id,
          canWriteOffAsUsed: el.canWriteOffAsUsed,
          canEditQuantity: el.canEditQuantity,
          from_warehouse_id: el.from_warehouse_id,
          product_group_id: groupId
        }
      }
      return null;
    },
    checkAndSave (printLabelsEdit = false) {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        this.$forceUpdate();
        if (!this.editEl.id) {
          this.setAllFeedingsFromConfig();
        }
        const reqData = this.BasicHelper.cloneObject(this.editEl);
        if (reqData.id && !this.feedingEdit) {
          delete reqData.usagefeedings;
        }
        this.ApiRequest.request('factory-products/checkShiftQualityCompletedOptionResetNeeded', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
            this.loading = false;
          } else {
            if (response.data.shiftQualityCompletedOptionResetNeeded == true) {
              this.loading = false;
              this.$refs.itemConfirmMsgBox.show({ printLabelsEdit: printLabelsEdit }, this.$t('menu.quality.shiftQualityMeasurementsResetClosedAt'), this.$t('menu.quality.shiftQualityMeasurementsResetClosedAtApprove', { }))
            } else {
              this.save(printLabelsEdit, false);
            }
          }
        });
      }
    },
    save (printLabelsEdit = false, checkLoading = true) {
      this.v$.$touch();
      if (!this.v$.$invalid && (!checkLoading || !this.loading)) {
        this.loading = true;
        const reqData = this.BasicHelper.cloneObject(this.editEl);
        this.ApiRequest.request('factory-products/produced-add', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
            this.loading = false;
          } else {
            if (reqData.id == null) {
              this.printLabels(response.data, this.printer, null);
            } else if (printLabelsEdit && this.printer && this.printerTemplates.length > 0) {
              this.printLabels(response.data, this.printer, this.printerTemplates);
            }
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    printLabels (el, printerCode, templateCodes = null) {
      if (el && el.id) {
        this.$store.commit('toastAdd', {
          context: 'info',
          message: this.$t('device.printer.productprint')
        })
        let reqData = { device: printerCode };
        let path = 'factory-products/print-default-labels/' + el.id;
        if (templateCodes) {
          path = 'factory-products/print-label/' + el.id;
          reqData = {
            locale: null,
            device: printerCode,
            templates: templateCodes
          }
        }
        this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, reqData, (response) => {
          if (response.error) {
            this.$store.commit('toastAdd', {
              context: 'danger',
              message: this.ApiRequest.getApiResponseErrorMessage(response, {})
            })
          }
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    },
    shiftChanged (shift) {
      if (shift) {
        this.editEl.shift_id = shift.id;
        this.editEl.line_id = shift.line_id;
      }
    },
    setDefaultFeedings () {
      if (this.produsesConfiguration && this.produsesConfiguration.groups) {
        const res = {};
        Object.entries(this.produsesConfiguration.groups).forEach(([groupMode, groupData]) => {
          if (!res[groupMode]) {
            res[groupMode] = {};
          }
          Object.entries(groupData).forEach(([groupId, options]) => {
            if (groupMode < 2) {
              let defaultOption = options.find(el => el.primary);
              if (!defaultOption) {
                defaultOption = options[0];
              }
              res[groupMode][groupId] = {
                id: null,
                config_id: defaultOption.id,
                product_id: defaultOption.product_id,
                quantity: defaultOption.quantity,
                canEditQuantity: defaultOption.canEditQuantity,
                measure_type_id: defaultOption.measure_type_id,
                quantityRatio: defaultOption.quantityRatio,
                canWriteOffAsUsed: defaultOption.canWriteOffAsUsed,
                from_warehouse_id: defaultOption.from_warehouse_id
              }
            } else {
              const primaryOptions = options.filter(el => el.primary);
              res[groupMode][groupId] = [];
              primaryOptions.forEach(el => {
                res[groupMode][groupId].push({
                  id: null,
                  config_id: el.id,
                  product_id: el.product_id,
                  quantity: el.quantity,
                  canEditQuantity: el.canEditQuantity,
                  measure_type_id: el.measure_type_id,
                  quantityRatio: el.quantityRatio,
                  canWriteOffAsUsed: el.canWriteOffAsUsed,
                  from_warehouse_id: el.from_warehouse_id
                })
              });
            }
          });
        });
        this.selectedFeedings = res;
      } else {
        this.selectedFeedings = null;
      }
    },
    changeSelectedFeeding (val, groupMode, groupId, rowIndex = null) {
      const option = this.produsesConfiguration.groups[groupMode][groupId].find(el => el.id == val);
      if (option) {
        if (rowIndex !== null) {
          this.selectedFeedings[groupMode][groupId][rowIndex].product_id = option.product_id;
          this.selectedFeedings[groupMode][groupId][rowIndex].quantity = option.quantity;
          this.selectedFeedings[groupMode][groupId][rowIndex].canEditQuantity = option.canEditQuantity;
          this.selectedFeedings[groupMode][groupId][rowIndex].measure_type_id = option.measure_type_id;
          this.selectedFeedings[groupMode][groupId][rowIndex].quantityRatio = option.quantityRatio;
          this.selectedFeedings[groupMode][groupId][rowIndex].canWriteOffAsUsed = option.canWriteOffAsUsed;
          this.selectedFeedings[groupMode][groupId][rowIndex].from_warehouse_id = option.from_warehouse_id;
        } else {
          this.selectedFeedings[groupMode][groupId].product_id = option.product_id;
          this.selectedFeedings[groupMode][groupId].quantity = option.quantity;
          this.selectedFeedings[groupMode][groupId].canEditQuantity = option.canEditQuantity;
          this.selectedFeedings[groupMode][groupId].measure_type_id = option.measure_type_id;
          this.selectedFeedings[groupMode][groupId].quantityRatio = option.quantityRatio;
          this.selectedFeedings[groupMode][groupId].canWriteOffAsUsed = option.canWriteOffAsUsed;
          this.selectedFeedings[groupMode][groupId].from_warehouse_id = option.from_warehouse_id;
        }
      }
    },
    deleteRowFeedings (groupMode, groupId, rowIndex) {
      this.selectedFeedings[groupMode][groupId].splice(rowIndex, 1);
    },
    addRowFeedings (groupMode, groupId) {
      this.selectedFeedings[groupMode][groupId].push({
        id: null,
        product_id: null,
        quantity: null,
        canEditQuantity: false,
        measure_type_id: null
      });
    },
    getImpurityMeasureTypeId (impurity) {
      if (impurity.measure_type_id) {
        return impurity.measure_type_id;
      }
      return this.$store.getters.getParamFromConfig('products', impurity.product_id, 'default_measure_type_id');
    }
  },
  validations: {
    editEl: {
      line_id: { required },
      shift_id: { required },
      flow_id: { required },
      stage_id: { required },
      type_id: { required },
      product_id: { required },
      startQuantity: {
        required: requiredIf(function () {
          return !(this.stageProduct && this.stageProduct.produceEmptyWeight);
        })
      },
      wetQuantity: { isValidWeightCheck },
      factory_product_impurities: {
        $each: helpers.forEach({
          admixture: { }
        })
      }
    }
  },

  setup () {
    return { v$: useVuelidate() }
  }
}
