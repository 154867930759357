var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"report-incoming-cargo"},[_c('Tableview',{ref:"tableview",attrs:{"tableClass":"table-striped table-sm table-hover fs-7","fields":_vm.tableHeaders,"loading":_vm.loading,"items":_vm.tableItems,"filters":_vm.tableFilters,"filterValues":_vm.filterValues,"displayColFiltersAlways":true,"filterActions":_vm.tableActions,"displayFilterActions":true},on:{"clearFilters":function($event){return _vm.clearFilters()},"saved":function($event){return _vm.getTableData()},"download":_vm.download},scopedSlots:_vm._u([{key:"statuscolor",fn:function(ref){
var data = ref.data;
return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"position-absolute h-100 w-100 top-0 text-center align-middle",style:('background-color:' + _vm.getStatusColor(data.value)),attrs:{"title":_vm.getStatusName(data.value)}}),(data.value == _vm.quarantineStatusId)?_c('div',{staticClass:"position-relative text-center"},[_c('i',{staticClass:"bi bi-bug-fill text-danger"})]):_vm._e()]}},{key:"localcode",fn:function(ref){
var data = ref.data;
return [_c('router-link',{attrs:{"to":{ name: 'menu.warehouse.cargos.view', params: { cargocode: data.item.localcode } },"target":"_blank"}},[_vm._v(" "+_vm._s(data.item.localcode)+" ")])]}},{key:"productIds",fn:function(ref){
var data = ref.data;
return _vm._l((data.value),function(productId){return _c('div',{key:productId},[_vm._v(" "+_vm._s(_vm.BasicHelper.getConfigValue('products', 'id', productId, 'name'))+" ")])})}},{key:"apuswasteclassIds",fn:function(ref){
var data = ref.data;
return _vm._l((data.value),function(wasteClassId){return _c('div',{key:wasteClassId},[_vm._v(" "+_vm._s(_vm.BasicHelper.getConfigValue('apus_waste_classes', 'id', wasteClassId, 'code', false))+" ")])})}},{key:"weight",fn:function(ref){
var data = ref.data;
return _vm._l((data.item.d365lines),function(d365line){return _c('div',{key:d365line.id},[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(d365line.quantity, 0, _vm.$i18n.locale))+" ")])})}},{key:"weightDifferenceKg",fn:function(ref){
var data = ref.data;
return [_c('span',{class:!!data.item.weightDifferenceKg ? 'text-danger' : ''},[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.item.weightDifferenceKg, 0, _vm.$i18n.locale))+" ")])]}},{key:"licencePlateOrTrailerPlateOrContainerNumber",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.BasicHelper.formatLineOnEmpty(data.item.transportLicensePlate))+" / "+_vm._s(_vm.BasicHelper.formatLineOnEmpty(data.item.transportTrailerPlate))+" / "+_vm._s(_vm.BasicHelper.formatLineOnEmpty(data.item.container_number))+" ")]}},{key:"costtype",fn:function(ref){
var data = ref.data;
return _vm._l((data.item.transport_costs),function(cost){return _c('div',{key:cost.id},[_c('span',{staticClass:"text-nowrap"},[_vm._v(" "+_vm._s(_vm.BasicHelper.getConfigValue('order_cost_types', 'id', cost.chargeCode_id, 'name'))+" ")])])})}},{key:"plannedTransportProvider",fn:function(ref){
var data = ref.data;
return _vm._l((data.item.transport_costs),function(cost){return _c('div',{key:cost.id},[_vm._v(" "+_vm._s(cost.transport_provider ? cost.transport_provider.name : '-')+" ")])})}},{key:"plannedcost",fn:function(ref){
var data = ref.data;
return _vm._l((data.item.transport_costs),function(cost){return _c('div',{key:cost.id},[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(cost.plannedCost, 2, _vm.$i18n.locale))+" ")])})}},{key:"billdata",fn:function(ref){
var data = ref.data;
return _vm._l((data.item.transport_costs),function(cost){return _c('div',{key:cost.id},_vm._l((cost.billdata),function(bill,key){return _c('div',{key:key},[(bill && bill.billNumber)?[_vm._v(" "+_vm._s(bill.billNumber)+" ")]:[_vm._v(" "+_vm._s(bill)+" ")]],2)}),0)})}},{key:"documents",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"d-flex"},[(data.item.bill_document && data.item.bill_document.d365Id)?_c('LinkToDifferentSystem',{attrs:{"host":(_vm.$store.state.config ? _vm.$store.state.config.d365supportfronturl : ''),"nextRouteName":"menu.billing.record.view","routeParams":_vm.fileLinkParams(data.item.bill_document),"newtab":true,"iconParams":_vm.getIconParam(data.item.bill_document, 'd365brnumber', 'journal-check')}}):(data.item.bill_document)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"btn btn-sm p-0 m-1 text-dark",attrs:{"title":_vm.$t('modelfiles.link.d365brnumber', null, { number: _vm.$t('modelfiles.link.not-added') })}},[_c('i',{staticClass:"bi bi-journal-check"})]):_vm._e(),(data.item.cmr_document)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"text-primary btn btn-sm p-0 m-1",attrs:{"title":_vm.$t('general.actions.view')},on:{"click":function($event){$event.stopPropagation();return _vm.viewFile(data.item.id, data.item.cmr_document.id)}}},[_c('i',{staticClass:"bi bi-file-earmark-pdf-fill"})]):(data.item.cmr_bundle_document)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"text-primary btn btn-sm p-0 m-1",attrs:{"title":_vm.$t('general.actions.view')},on:{"click":function($event){$event.stopPropagation();return _vm.viewFile(data.item.id, data.item.cmr_bundle_document.id)}}},[_c('i',{staticClass:"bi bi-file-earmark-pdf-fill"})]):_vm._e()],1)]}}])}),_c('b-modal',{ref:"filepreview",attrs:{"size":"full modal-fullscreen"}},[_c('div',{staticStyle:{"height":"85vh"}},[_c('iframe',{staticClass:"w-100 h-100",attrs:{"src":_vm.fileBase64}})])]),(_vm.paging.pages > 1  && !_vm.loading)?_c('b-pagination',{attrs:{"total-rows":_vm.paging.total,"per-page":_vm.paging.limit},on:{"change":_vm.changePage},model:{value:(_vm.paging.page),callback:function ($$v) {_vm.$set(_vm.paging, "page", $$v)},expression:"paging.page"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }