import Vue from 'vue'
import Vuex from 'vuex';
import App from './App.vue'
import 'ebg-vue-common/src/filters/common.js'
import BasicHelper from 'ebg-vue-common/src/helpers/BasicHelper.js'
import ApiRequest from 'ebg-vue-common/src/helpers/ApiRequest.js'
import store from 'ebg-vue-common/src/store/index.js'
import { tooltip } from 'ebg-vue-common/src/directives/tooltip.js'
import EbgVueCommonSimple from 'ebg-vue-common/src/install.js'
import { clickoutside } from 'ebg-vue-common/src/directives/clickoutside.js';
import WarehouseLayout from '@/components/pet/warehouse/sku/WarehouseLayout.vue';
import PetHelper from './helpers/PetHelper.js'
import DeviceHelper from './helpers/DeviceHelper.js'
import router from './router'
import VueSession from 'vue-session'
import VueCookie from 'vue-cookie'
import i18n from './i18n'
import petstore from './store/pet.js'
import './assets/scss/custom-bootstrap.scss'
import '@/filters/pet.js'
import moment from 'moment'

Vue.config.productionTip = false

Vue.use(Vuex);

Vue.use(VueSession, { persist: true })
Vue.use(VueCookie)
Vue.use(EbgVueCommonSimple);
Vue.component('WarehouseLayout', WarehouseLayout);

Vue.prototype.ApiRequest = ApiRequest
Vue.prototype.BasicHelper = BasicHelper
Vue.prototype.PetHelper = PetHelper
Vue.prototype.DeviceHelper = DeviceHelper

Vue.directive('tooltip', tooltip);
Vue.directive('click-outside', clickoutside);

store.registerModule('pet', petstore);

Vue.prototype.moment = moment;
Vue.prototype.PetHelper.updateDynamicRoutes(router)

new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app');
