import { mapState } from 'vuex';

export default {
  name: 'OperationSite',

  data () {
    return {
      loading: false
    }
  },

  computed: {
    ...mapState({
      currentUser: state => state.user,
      defaultSiteId: state => state.pet.defaultSiteId
    }),

    isShow () {
      return (this.$route.meta.operationalSite === undefined || this.$route.meta.operationalSite);
    },

    operationalSiteId: {
      get () {
        if (this.defaultSiteId && this.defaultSiteId !== 'null') {
          return this.defaultSiteId;
        } else if (this.operationalSiteOptions.length) {
          this.$store.dispatch('changeStateValues', { defaultSiteId: this.operationalSiteOptions[0].value })
          return this.operationalSiteOptions[0].value;
        } else {
          return null;
        }
      },
      set (val) {
        this.$store.dispatch('changeStateValues', { defaultSiteId: val })
      }
    },

    canChangeOperationalSite () {
      return this.operationalSiteOptions.length > 1;
    },

    operationalSiteOptions () {
      if (this.currentUser) {
        return this.$store.getters.getConfigOptions('operational_sites', this.$i18n.locale, 'id', this.currentUser.visible_sites, false, false, true);
      }
      return [];
    }
  },

  methods: {
    changeOperationalSite (operationalSiteId) {
      if (this.operationalSiteId != operationalSiteId) {
        this.loading = true;
        this.operationalSiteId = operationalSiteId;
        this.ApiRequest.request(`user/change-operational-site/${operationalSiteId}`, this.ApiRequest.REQUEST_GET, {}, function () {})
        this.loading = false;
      }
    }
  },

  watch: {
    operationalSiteId: {
      immediate: true,
      handler (val) {
        this.$store.dispatch('changeStateValues', { defaultSiteId: val })
      }
    }
  }
}
