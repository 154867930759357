<template>
  <div class="shift-resouce-data">
    <ProductFeedingList :shift="currentShift"
                        :flowId="currentFlowId"
                        :isChemical="false"
                        :singleShift="true"
                        :rfidDeviceId="currentRfidDeviceId"
                        :qrDeviceId="currentQRDeviceId"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ProductFeedingList from '@/components/pet/product/feeding/list/ProductFeedingList.vue'

export default {
  name: 'ShiftFedData',
  data () {
    return {
    }
  },
  components: {
    ProductFeedingList
  },
  computed: {
    ...mapState({
      currentShift: state => state.pet.currentShift,
      currentFlowId: state => state.pet.currentFlowId,
      currentRfidDeviceId: state => state.pet.currentRfidDeviceId,
      currentQRDeviceId: state => state.pet.currentQRDeviceId
    }),
    shiftId () {
      return this.currentShift?.id;
    }
  },
  watch: {
    currentShift: {
      immediate: true,
      handler () {
      }
    },
    currentFlowId: {
      immediate: true,
      handler () {
      }
    },
    currentRfidDeviceId: {
      immediate: true,
      handler () {
      }
    },
    currentQRDeviceId: {
      immediate: true,
      handler () {
      }
    }
  }
}
</script>
