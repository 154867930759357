export default {
  name: 'PartnerPicker',
  props: {
    value: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: 'vendors'
    },
    filterValues: {
      type: Object,
      default: () => {}
    },
    preselectedPartner: {
      type: Object,
      default: () => null
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    controlClass: {
      type: String,
      default: ''
    },
    hasApproval: {
      type: Boolean,
      default: true
    },
    size: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      loading: false,
      content: null,
      partnerId: null
    };
  },
  computed: {
    partnerIsApproved () {
      return (this.hasApproval && this.content && this.content.approval_IsApproved);
    },
    approvalInfo () {
      if (this.partnerIsApproved) {
        return this.$t(`partners.${this.partnerGroupKey}.isApprovedYes`)
      } else if (this.content && this.content.approval_created_at) {
        return this.$t(`partners.${this.partnerGroupKey}.isApprovedNo`)
      }
      return this.$t(`partners.${this.partnerGroupKey}.isApprovedUnknow`);
    },
    partnerGroupKey () {
      if (this.basePath == 'customers') {
        return 'customers';
      } else if (this.basePath == 'vendors') {
        return 'vendors';
      }
      return null;
    }
  },
  methods: {
    updateValue () {
      this.$emit('input', this.content);
      this.$emit('changed', this.content);
    },
    partnerSelected (val, item) {
      this.content = item;
      this.updateValue();
    },
    viewPartner () {
      if (this.content && this.content.code) {
        const routeData = this.$router.resolve({ name: `menu.partners.${this.partnerGroupKey}.view`, params: { code: this.content.code } });
        window.open(routeData.href, '_blank');
      }
    }
  },
  mounted () {
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        if (val != this.content) {
          this.content = val;
          this.partnerId = (this.content ? this.content.id : null);
        }
      }
    },
    preselectedPartner: {
      immediate: true,
      handler (val) {
        if (val != this.content && val != null) {
          this.content = val;
          this.partnerId = (this.content ? this.content.id : null);
          this.updateValue();
        }
      }
    }
  }
}
