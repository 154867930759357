import FactoryProductPicker from '@/components/pet/factoryproduct/picker/FactoryProductPicker.vue'
import DeviceScaleInput from '@/components/pet/device/scaleinput/DeviceScaleInput.vue'
import { mapState } from 'vuex';
import { required, requiredIf } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

const defaultEl = {
  id: null,
  factory_product_id: null,
  decision_id: null,
  isDamaged: 1,
  setWeight: null,
  line_id: null,
  flow_id: null,
  stage_id: null
};

const validateSetWeight = (value, rec) => {
  if (!(value == null || value == '')) {
    const calcVal = parseFloat(value);
    if (value >= 0 && rec.factoryproduct && rec.factoryproduct.remainingQuantity >= calcVal) {
      return true;
    }
    return false;
  }
  return true;
}

export default {
  name: 'FactoryProductDamagedEdit',
  data () {
    return {
      editEl: null,
      loading: false,
      hasDecision: false
    }
  },
  components: {
    FactoryProductPicker,
    DeviceScaleInput
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    }
  },
  validations: {
    editEl: {
      factory_product_id: { required },
      decision_id: requiredIf(function () {
        return this.hasDecision;
      }),
      setWeight: { validateSetWeight },
      stage_id: requiredIf(function () {
        return (this.hasDecision && this.decisionWithWeight && !(this.editEl.setWeight === null))
      })
    }
  },
  computed: {
    ...mapState({
      qrReaderDeviceIds: state => [state.pet.currentQRDeviceId]
    }),
    getTitle () {
      if (this.editEl && this.editEl.id) {
        return this.$t('factory-product.damaged.actions.edit');
      }
      return this.$t('factory-product.damaged.actions.mark');
    },
    decisionOptions () {
      return this.$store.getters.getConfigOptions('damaged_product_decisions', this.$i18n.locale);
    },
    canEdit () {
      return !this.loading && this.editEl && (!this.editEl.id || (!this.editEl.decision_user_id && this.canMakeDecision));
    },
    canMakeDecision () {
      return this.ApiRequest.userHasPermission('edit-warehouse-damaged');
    },
    factoryPickerFilters () {
      return {
        status: 'WAREHOUSE',
        usageTypes: ['isFinalProduct'],
        withRemainingQuantity: 1,
        remainingBales: 1,
        isDamaged: 0
      }
    },
    yesNoOptions () {
      return this.BasicHelper.yesNoOptions();
    },
    decisionWithWeight () {
      const match = this.decisionOptions.find(el => el.value == this.editEl.decision_id);
      if (match && match.code == 'RETURN') {
        return true;
      }
      return false;
    },
    deviceIds () {
      let res = [];
      if (this.editEl && this.editEl.factoryproduct) {
        const stageDevices = this.$store.getters.getParamFromConfig('warehouses', this.editEl.factoryproduct.local_warehouse_id, 'availableDevices');
        if (stageDevices) {
          Object.values(stageDevices).forEach(el => {
            res = res.concat(el);
          });
        }
      }
      return res;
    },
    maxSetWeight () {
      if (this.editEl && this.editEl.factoryproduct) {
        return this.editEl.factoryproduct.remainingQuantity;
      }
      return 0;
    },
    productStages () {
      if (this.editEl.factoryproduct) {
        return this.$store.getters.getMatchingProductsWithStages(null, { canFeed: true }, { id: this.editEl.factoryproduct.product_id })
      }
      return {};
    },
    lineOptions () {
      const lineIds = Object.values(this.productStages).map(el => el.line_id);
      return this.$store.getters.getConfigOptions('lines', this.$i18n.locale, 'id', lineIds, false, false, true);
    },
    flowOptions () {
      const flowIds = Object.keys(this.productStages);
      return this.$store.getters.getConfigOptions(
        'line_flows',
        this.$i18n.locale,
        'id',
        flowIds,
        false,
        false,
        true,
        ['line_id']
      );
    },
    stageOptions () {
      if (this.productStages && this.editEl && this.editEl.flow_id && this.productStages[this.editEl.flow_id]) {
        return this.productStages[this.editEl.flow_id].stages;
      }
      return [];
    },
    writeoffWeight () {
      if (this.editEl.factoryproduct && !(this.editEl.setWeight == null || this.editEl.setWeight == '')) {
        return this.editEl.factoryproduct.remainingQuantity - parseFloat(this.editEl.setWeight);
      }
      return null;
    }
  },
  methods: {
    show (el) {
      this.hasDecision = false;
      if (el) {
        this.editEl = this.BasicHelper.cloneObject(el);
        this.hasDecision = true;
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
      }
      this.setDefaultStage();
      this.loading = false;
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
      this.v$.$reset();
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        let action = 'mark';
        if (this.hasDecision) {
          action = 'edit';
          for (const key of Object.keys(defaultEl)) {
            reqData[key] = this.editEl[key];
          }
        } else {
          reqData.factory_product_id = this.editEl.factory_product_id;
          reqData.isBroken = true;
        }
        this.loading = false;
        this.ApiRequest.request('factory-products/damaged/' + action, this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    },
    productChanged (el) {
      if (el) {
        this.editEl.factory_product_id = el.id;
        this.editEl.startWeight = el.remainingQuantity;
        this.setDefaultStage();
      }
    },
    setDefaultStage () {
      this.editEl.line_id = (this.lineOptions.length > 0 ? this.lineOptions[0].value : null);
      this.lineChanged();
    },
    lineChanged () {
      this.editEl.flow_id = (this.flowOptions.length > 0 ? this.flowOptions[0].value : null);
      this.flowChanged();
    },
    flowChanged () {
      this.editEl.stage_id = (this.stageOptions.length > 0 ? this.stageOptions[0].id : null);
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
