import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

const defaultEl = {
  product_id: null,
  shift_id: null,
  line_id: null,
  moveToWarehouseId: null,
  warehouse_id: null,
  bales: []
};

export default {
  name: 'AssemblyReturn',
  data () {
    return {
      editEl: defaultEl,
      loading: false,
      warehouseBales: [],
      missingAmount: null,
      returnAll: false
    }
  },
  validations: {
    editEl: {
      product_id: { required },
      shift_id: { required }
    }
  },
  components: {
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    warehouseOptions () {
      let options = null;
      if (this.editEl.line_id && this.warehouseBales) {
        const shiftWarehouses = Object.values(this.$store.getters.getParamFromConfigByFilter('lines', 'id', this.editEl.line_id, 'shiftWarehouseMap'));
        options = shiftWarehouses
          .filter((warehouseId) => warehouseId !== this.editEl.warehouse_id)
          .map((warehouseId) => this.$store.getters.getConfigOptions('warehouses', this.$i18n.locale, 'id', warehouseId)).flat();
        options.push({
          text: this.$t('assembly.movement.move-to-previous'),
          value: this.editEl.warehouse_id
        });
      }
      return options;
    },
    toggleReturnAll () {
      if (this.returnAll) {
        this.selectAllBales();
      } else {
        this.clearSelectedBales();
      }
    },
    getTitle () {
      return this.$tc(
        'assembly.movement.returntitle',
        null,
        {
          warehouse: this.BasicHelper.getConfigValue('warehouses', 'id', this.editEl.warehouse_id, 'name', true)
        }
      );
    },
    canEdit () {
      return !this.loading;
    },
    tableHeaders () {
      return [
        { key: 'quantity', label: this.$t('assembly.bale.quantity'), format: 'formatDecimal', precision: 0 },
        { key: 'factoryproduct.code', label: this.$t('factoryproduct.code') },
        { key: 'movement.created_at', label: this.$t('assembly.movement.created_at'), type: 'datetime' },
        { key: 'movement.user.fullname', label: this.$t('assembly.movement.created_at') },
        { key: 'return', label: this.$t('assembly.bale.return'), type: 'slot', slotcode: 'checkbox' }
      ];
    },
    missingAmountClass () {
      if (this.missingAmount <= 0) {
        return 'bg-danger';
      } else if (this.missingAmount <= 500) {
        return 'bg-warning';
      }
      return 'bg-success';
    }
  },
  methods: {
    selectAllBales () {
      this.editEl.bales = this.warehouseBales.map((warehouse) => warehouse.id);
    },
    clearSelectedBales () {
      this.editEl.bales = [];
    },
    show (elData) {
      this.editEl = this.BasicHelper.cloneObject(defaultEl);
      this.editEl.product_id = elData.product_id;
      this.editEl.warehouse_id = elData.warehouse_id;
      this.editEl.moveToWarehouseId = elData.warehouse_id;
      this.editEl.shift_id = elData.shift.id;
      this.editEl.line_id = elData.shift.line_id;
      this.missingAmount = parseFloat(elData.missingAmount);
      this.warehouseBales = [];
      this.getWarehouseBales();
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    getWarehouseBales () {
      this.warehouseProducts = null;
      this.ApiRequest.request('assembly/warehouse/' + this.editEl.warehouse_id + '/bales/' + this.editEl.product_id, this.ApiRequest.REQUEST_GET, { }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else {
          this.warehouseBales = response.data;
        }
        this.loading = false;
      });
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        reqData.toAdjacentWarehouse = !(this.editEl.warehouse_id === this.editEl.moveToWarehouseId);
        this.ApiRequest.request('assembly/movements/' + this.editEl.shift_id + '/return', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
