import useVuelidate from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';
import DeviceScaleInput from '@/components/pet/device/scaleinput/DeviceScaleInput.vue'
import RfidScanner from '@/components/pet/device/rfidreader/scanner/RfidScanner.vue'
import { mapState } from 'vuex';

const defaultEl = {
  product_id: null,
  pieces: 1,
  quantity: null,
  from_warehouse_id: null,
  to_warehouse_id: null,
  shift_id: null,
  missingAmount: null,
  rfids: []
};

export default {
  name: 'AssemblyMoveTo',
  data () {
    return {
      editEl: defaultEl,
      loading: false,
      warehouseProducts: null
    }
  },
  validations: {
    editEl: {
      product_id: { required },
      from_warehouse_id: { required },
      to_warehouse_id: { required },
      pieces: { required },
      quantity: {
        required: requiredIf(function () {
          return (!this.editEl && this.editEl.rfids.length == 0);
        })
      }
    }
  },
  components: {
    DeviceScaleInput,
    RfidScanner
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    },
    asBales: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      currentRfidDeviceId: state => state.pet.currentRfidDeviceId
    }),
    getTitle () {
      return this.$tc(
        'assembly.movement.addtitle',
        null,
        { warehouse: this.BasicHelper.getConfigValue('warehouses', 'id', this.editEl.to_warehouse_id, 'name', true) }
      );
    },
    canEdit () {
      return !this.loading;
    },
    warehouseOptions () {
      return this.$store.getters.getConfigOptions('warehouses', this.$i18n.locale, 'isAssembly', 0);
    },
    warehouseUsableOptions () {
      if (this.warehouseProducts) {
        return this.warehouseOptions.filter(el => this.warehouseProducts.find(subEl => subEl.warehouse_id == el.value));
      }
      return [];
    },
    defaultMeasureTypeId () {
      return this.$store.getters.getParamFromConfig('products', this.editEl.product_id, 'default_measure_type_id');
    },
    warehouseQuantities () {
      return (this.warehouseProducts ? this.warehouseProducts.find(el => el.warehouse_id == this.editEl.from_warehouse_id) : null);
    },
    baleQuantityOptions () {
      const options = [];
      if (this.warehouseQuantities && this.warehouseQuantities.remainingBales) {
        let i = 1;
        while (i <= this.warehouseQuantities.remainingBales && i <= 6) {
          options.push({ value: i, text: i });
          i += 1;
        }
      }
      return options;
    },
    deviceIds () {
      const stageDevices = this.$store.getters.getParamFromConfig('warehouses', this.editEl.to_warehouse_id, 'availableDevices');
      let res = [];
      if (stageDevices) {
        Object.values(stageDevices).forEach(el => {
          res = res.concat(el);
        });
      }
      return res;
    },
    missingAmountClass () {
      if (this.editEl.missingAmount <= 0) {
        return 'bg-danger';
      } else if (this.editEl.missingAmount <= 500) {
        return 'bg-warning';
      }
      return 'bg-success';
    }
  },
  methods: {
    show (elData) {
      this.loading = true;
      this.editEl = this.BasicHelper.cloneObject(defaultEl);
      this.editEl.product_id = elData.product_id;
      this.editEl.to_warehouse_id = elData.to_warehouse_id;
      this.editEl.shift_id = elData.shift_id;
      this.editEl.missingAmount = elData.missingAmount;
      if (!this.asBales) {
        this.editEl.pieces = 0;
      }
      this.getWarehouseProducts();
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    getWarehouseProducts () {
      this.warehouseProducts = null;
      this.ApiRequest.request('warehouses/product/' + this.editEl.product_id + '/0/' + (this.asBales ? '0' : '1'), this.ApiRequest.REQUEST_GET, { }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else {
          const items = [];
          Object.values(response.data).forEach(el => {
            Object.values(el).forEach(prodWarehouse => {
              items.push(prodWarehouse);
            });
          });
          this.warehouseProducts = items;
          this.editEl.from_warehouse_id = (this.warehouseUsableOptions.length > 0 ? this.warehouseUsableOptions[0].value : null);
        }
        this.loading = false;
      });
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        reqData.rfidIds = Object.values(this.editEl.rfids).map(el => el.id);
        this.ApiRequest.request('assembly/movements/' + this.editEl.shift_id + '/receive', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    },
    rfidLoaded (rfid) {
      if (rfid) {
        const match = this.editEl.rfids.find(el => el.id == rfid.id);
        if (!match) {
          this.editEl.rfids.push(rfid);
        }
        this.$refs.rfidscanner.focusscan();
      }
    },
    removeRfid (key) {
      this.editEl.rfids.splice(key, 1);
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
