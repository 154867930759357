import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import UserWorklist from '@/components/pet/user/worklist/UserWorklist.vue'

const defaultEl = {
  id: null,
  name: {},
  default_line_id: null,
  brigadeusers: []
};

export default {
  name: 'BrigadeEdit',
  data () {
    return {
      editEl: defaultEl,
      loading: false
    }
  },
  components: {
    UserWorklist
  },
  validations: {
    editEl: {
      name: { required },
      default_line_id: { required }
    }
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    baseTransParam () {
      return 'brigade';
    },
    getTitle () {
      if (this.editEl.id) {
        return this.$t(this.baseTransParam + '.actions.edit');
      }
      return this.$t(this.baseTransParam + '.actions.new');
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission('edit-brigades');
    },
    lineOptions () {
      return this.$store.getters.getConfigOptions('lines', this.$i18n.locale);
    }
  },
  methods: {
    show (elData) {
      if (elData) {
        this.editEl = this.BasicHelper.cloneObject(elData);
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
      }
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
      if (this.$refs.userWorklist) {
        this.$refs.userWorklist.hideValidation();
      }
    },
    save () {
      this.v$.$touch();
      this.$refs.userWorklist.isValid();
      if (!this.v$.$invalid && !this.loading && this.$refs.userWorklist.isValid()) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        this.ApiRequest.request('brigades/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, self);
          } else {
            this.$store.commit('toastAdd', {
              context: 'success',
              message: this.$t('general.actions.saved')
            });
            this.$store.dispatch('reloadConfig');
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
