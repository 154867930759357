<template>
  <div class="shift-recipe">
    <div v-if="warehouseShift">
      <div v-if="warehouseShift.closedAt" class="text-danger fs-3">
        {{ $t('shift.is-closed') }}
      </div>
    </div>
    <ShiftRecipeView v-if="warehouseShift" :shiftId="warehouseShift.id" :flowId="warehouseFlowId" viewtype="assembly"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ShiftRecipeView from '@/components/pet/shift/recipeview/ShiftRecipeView.vue'

export default {
  name: 'ShiftRecipe',
  data () {
    return {
    }
  },
  components: {
    ShiftRecipeView
  },
  computed: {
    ...mapState({
      warehouseShift: state => state.pet.warehouseShift,
      warehouseFlowId: state => state.pet.warehouseFlowId
    })
  },
  watch: {
    warehouseShift: {
      immediate: true,
      handler () {
      }
    }
  }
}
</script>
