import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import PartnerPicker from '@/components/pet/partners/picker/PartnerPicker.vue';

const defaultEl = {
  id: null,
  code: '',
  name: {},
  order: 0,
  orderCargoTypes: {},
  cost_type_sections: []
};

const defaultCargoTypes = {
  id: null,
  transport_provider_id: null,
  order_cargo_type_id: null,
  description: {},
  transport_provider: null
}

const defaultTransportCostsSection = {
  id: null,
  order_cost_type_id: null,
  order_cargo_type_id: null,
  isAutomatic: true,
  delete: false
};

export default {
  name: 'DeliveryTypeEdit',
  data () {
    return {
      editEl: defaultEl,
      loading: false
    }
  },
  validations: {
    editEl: {
      code: { required },
      name: { required },
      order: { }
    }
  },
  props: {
    editPerm: {
      type: String,
      default: 'edit-delivery-types'
    },
    isModal: {
      type: Boolean,
      default: true
    }
  },
  components: {
    PartnerPicker
  },
  computed: {
    getTitle () {
      if (this.editEl.id) {
        return this.$t('delivery-type.actions.edit');
      }
      return this.$t('delivery-type.actions.new');
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission(this.editPerm);
    },
    partnerPickerFilters () {
      return { isActual: 1, order: 'name,ASC' };
    },
    costTypeOptions () {
      return this.$store.getters.getConfigOptions('order_cost_types', this.$i18n.locale);
    },
    cargoTypeOptions () {
      return this.$store.getters.getConfigOptions('order_cargo_types', this.$i18n.locale);
    }
  },
  methods: {
    show (elData) {
      this.editEl = this.BasicHelper.cloneObject(defaultEl);
      const availableCargoTypes = {};
      this.cargoTypeOptions.forEach(cargoType => {
        const defaultObject = { ...defaultCargoTypes };
        defaultObject.order_cargo_type_id = cargoType.value;
        availableCargoTypes[cargoType.value] = defaultObject;
      });
      if (elData) {
        this.BasicHelper.syncObjectValuesByKeys(this.editEl, elData);
      }
      Object.keys(availableCargoTypes).forEach(key => {
        if (!this.editEl.orderCargoTypes[key]) {
          this.editEl.orderCargoTypes[key] = availableCargoTypes[key];
        }
      });
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        this.ApiRequest.request('delivery-types/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$store.dispatch('reloadConfig');
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    },
    partnerChanged (el, key) {
      if (el) {
        this.editEl.orderCargoTypes[key].transport_provider_id = el.id;
      } else {
        this.editEl.orderCargoTypes[key].transport_provider_id = null;
      }
      this.$forceUpdate();
    },
    addTransportCostsSection () {
      this.editEl.cost_type_sections.push(this.BasicHelper.cloneObject(defaultTransportCostsSection));
    },
    softDeleteTransportCost (transportCost) {
      transportCost.delete = true;
      this.$forceUpdate();
    },
    restoreTransportCost (transportCost) {
      transportCost.delete = false;
      this.$forceUpdate();
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
