import ShiftDeviceMeasureMultiEdit from '@/components/pet/shift/devicemeasuremultiedit/ShiftDeviceMeasureMultiEdit.vue'
import ShiftDeviceMeasureCard from '@/components/pet/shift/devicemeasurecard/ShiftDeviceMeasureCard.vue'
import PetHelper from '@/helpers/PetHelper';

export default {
  name: 'ShiftDeviceMeasurementList',
  data () {
    return {
      devices: null,
      stages: null,
      loading: true,
      isMounted: false,
      currentReq: null,
      tableItems: [],
      loadedShiftId: null,
      loadedShiftTypeId: null,
      disabled: true
    }
  },
  props: {
    shiftId: {
      type: Number,
      default: null
    },
    currentShift: {
      type: Object,
      default: () => {}
    },
    shiftTypeId: {
      type: Number,
      default: null
    },
    viewtype: {
      type: String,
      default: 'all'
    },
    tableview: {
      type: Boolean,
      default: true
    },
    splitByType: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ShiftDeviceMeasureMultiEdit,
    ShiftDeviceMeasureCard
  },
  computed: {
    canEdit () {
      return !this.disabled && this.ApiRequest.userHasPermission('edit-shift-device-measurements') && PetHelper.validShiftDateInterval(this.currentShift);
    },
    defaultStageTypeId () {
      return this.$store.getters.getParamFromConfigByFilter('technological_stage_types', 'code', 'OTHER', 'id');
    },
    boolMeasureTypeId () {
      return this.$store.getters.getParamFromConfigByFilter('measure_types', 'code', 'BOOL', 'id');
    },
    tableHeaders () {
      const headers = [
        { key: 'measured_at', label: this.$t('device.measurement.measured_at'), type: 'time' }
      ];
      const actions = [];
      if (this.canEdit) {
        actions.push({ label: this.$t('general.actions.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-sm btn-primary' });
      }
      if (this.devices && this.stages) {
        this.devices.forEach(deviceEl => {
          const headerConfig = {
            key: 'measurements.dev_' + deviceEl.id + '.measurevalue',
            label: this.BasicHelper.getTranslation(deviceEl.shortname),
            format: 'formatDecimal',
            precision: deviceEl.precision,
            hidePrecision: true
          }
          if (deviceEl.measure_type_id == this.boolMeasureTypeId) {
            headerConfig.format = 'formatYesNo';
            headerConfig.checkbutton = true
          }
          headers.push(headerConfig);
          if (deviceEl.hasComment) {
            headers.push({
              key: 'measurements.dev_' + deviceEl.id + '.comment',
              label: this.$t('device.measurement.comment')
            })
          }
        });
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    tableActions () {
      const actions = { download: false };
      if (this.canEdit && PetHelper.validShiftDateInterval(this.currentShift)) {
        actions.add = { title: this.$t('device.measurement.actions.add') };
      }
      return actions;
    },
    sortByDevices () {
      if (this.tableview) {
        return 'displayorder';
      }
      return 'type_id';
    },
    typeOptions () {
      if (this.devices) {
        return this.$store.getters.getConfigOptions('device_types', this.$i18n.locale).filter(el => {
          return this.devices.find(dev => dev.type_id == el.value);
        });
      }
      return [];
    },
    typeDevices () {
      const res = {};
      this.typeOptions.forEach(type => {
        const items = this.devices.filter(el => el.type_id == type.value);
        if (items.length > 0) {
          res[type.value] = this.BasicHelper.sortArrayByKeyValue(items, 'order');
        }
      });
      return res;
    }
  },
  methods: {
    getTableData () {
      if (this.shiftId) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.loading = true;
        const path = 'shift/view/' + this.shiftId + '/measurements/' + this.viewtype;
        this.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, [], (response) => {
          if (response.data) {
            this.loadedShiftId = this.shiftId;
            this.loadedShiftTypeId = this.shiftTypeId;
            this.disabled = response.data.disabled;
            this.devices = this.BasicHelper.sortArrayByKeyValue(Object.values(response.data.devices), this.sortByDevices);
            this.stages = response.data.stages;
            if (this.tableview) {
              const items = {};
              this.devices.forEach(deviceEl => {
                deviceEl.measurements.forEach(measure => {
                  if (!items[measure.measured_at]) {
                    items[measure.measured_at] = { measured_at: measure.measured_at, measurements: {} };
                  }
                  items[measure.measured_at].measurements['dev_' + deviceEl.id] = measure;
                });
              });
              this.tableItems = this.BasicHelper.sortArrayByKeyValue(Object.values(items), 'measured_at');
            }
          } else {
            this.devices = [];
            this.stages = [];
          }
          this.loading = false;
        });
      }
    },
    editItem (el) {
      this.$refs.editElFrm.show(el, this.stages, this.devices, this.loadedShiftId, this.loadedShiftTypeId);
    },
    deviceValueSaved (data) {
      if (data && data.id) {
        const dev = this.devices.find(el => el.id == data.id);
        if (dev) {
          const params = ['measurements', 'consumedTotal', 'fedcalculated', 'movedtotal'];
          params.forEach(param => {
            dev[param] = data[param];
          });
        }
        this.$forceUpdate();
      }
    },
    addedProduct () {
      this.getTableData();
      this.$emit('addedProduct');
    }
  },
  mounted () {
    this.isMounted = true;
    this.getTableData();
  },
  watch: {
    shiftId: {
      immediate: true,
      handler (val) {
        if (this.isMounted && this.loadedShiftId != val) {
          this.getTableData();
        }
      }
    }
  }
}
