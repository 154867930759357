import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

const defaultEl = {
  id: null,
  customer_id: null,
  vendor_id: null,
  name: {},
  address: null,
  active: true
};

export default {
  name: 'LoadingAddressesEdit',
  data () {
    return {
      editEl: defaultEl,
      loading: false
    }
  },
  components: {
  },
  validations: {
    editEl: {
      name: { required }
    }
  },
  computed: {
    partnerpath () {
      if (this.editEl.customer_id) {
        return 'customers';
      }
      return 'vendors';
    },
    getTitle () {
      if (this.editEl.id) {
        return this.$t(`partners.${this.partnerpath}.loadingAddressesEdit`);
      }
      return this.$t(`partners.${this.partnerpath}.loadingAddressesNew`);
    }
  },
  methods: {
    show (elData, vendorId = null, customerId = null) {
      if (elData) {
        this.editEl = this.BasicHelper.cloneObject(elData);
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
        this.editEl.customer_id = customerId;
        this.editEl.vendor_id = vendorId;
      }
      this.v$.$reset();
      this.$refs['modal-window'].show();
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        this.ApiRequest.request('partners/address/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      this.$refs['modal-window'].hide();
    }
  },
  mounted () {
    this.isMounted = true;
  },
  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'LoadingAddressesEdit' }) }
  }
}
