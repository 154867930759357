<template>
  <div class="shift-resouce-data">
    <template v-if="canAdd">
      <span class="btn btn-primary" data-action="add" @click="feedChemical">
        <i class="bi bi-plus" />
        {{ $t('fed-products.actions.addChemical') }}
      </span>
    </template>
    <div v-if="currentShift && !currentShift.canFeed" class="text-danger fs-3">
      <div v-if="currentShift.closedAt">
        {{ $t('shift.is-closed') }}
      </div>
      <div v-else-if="currentShift.warehouse_id && !currentShift.warehouseApprovedAt">
        {{ $t('shift.warehouse-not-approved') }}
      </div>
      <div v-else>
        {{ $t('shift.some-invalid-approvals') }}
      </div>
    </div>
    <ShiftDeviceMeasureList
        ref="shiftMeasureList"
        :shiftId="currentShift.id"
        :shiftTypeId="currentShift.type_id"
        viewtype="chemo"
        :tableview="false"
        :splitByType="false"
        class="mb-3"
        @addedProduct="addedProduct"
        :currentShift="currentShift"
    />
    <ProductFeedingList ref="chemoFeedingList" :shift="currentShift" :isChemical="true" :singleShift="true"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ShiftDeviceMeasureList from '@/components/pet/shift/devicemeasurelist/ShiftDeviceMeasureList.vue'
import ProductFeedingList from '@/components/pet/product/feeding/list/ProductFeedingList.vue'
import PetHelper from '@/helpers/PetHelper';

export default {
  name: 'ShiftTechnoData',
  data () {
    return {
    }
  },
  components: {
    ShiftDeviceMeasureList,
    ProductFeedingList
  },
  computed: {
    ...mapState({
      currentShift: state => state.pet.currentShift,
      currentFlowId: state => state.pet.currentFlowId
    }),
    shiftId () {
      return this.currentShift?.id;
    },
    canAdd () {
      return (this.currentShift && this.currentShift.canFeed && PetHelper.validShiftDateInterval(this.currentShift) &&
          this.ApiRequest.userHasPermission('add-shift-fed-products'));
    }
  },
  methods: {
    addedProduct () {
      if (this.$refs.movementlist) {
        this.$refs.movementlist.getTableData();
      }
    },
    feedChemical () {
      this.$refs.chemoFeedingList.editItem(null);
    }
  },
  watch: {
    currentShift: {
      immediate: true,
      handler () {
      }
    }
  }
}
</script>
