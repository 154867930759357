const defaultFilterValues = {
  datePeriod: null,
  shiftType: null,
  lines: [],
  flows: []
};

export default {
  name: 'SubmittedProductProportion',
  components: {
  },
  props: {
    basePath: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      fullDataSet: null,
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { item: 'order', direction: 'asc' },
      isMounted: false,
      timeRanges: [],
      totalWithInTimeRange: null
    };
  },
  computed: {
    flowOptions () {
      return this.$store.getters.getConfigOptions(
        'line_flows',
        this.$i18n.locale,
        'line_id',
        this.lineIds,
        false,
        false,
        true,
        ['flowcode']
      );
    },
    lineIds () {
      const res = [];
      if (this.filterValues.lines) {
        this.lineOptions.forEach(el => {
          if (this.filterValues.lines.includes(el.code)) {
            res.push(el.value);
          }
        });
      }
      return res;
    },
    lineOptions () {
      return this.$store.getters.getAvailableLineOptions(this.$i18n.locale);
    },
    shiftTypeOptions () {
      return this.$store.getters.getConfigOptions('shift_types', this.$i18n.locale);
    },
    basePathComp () {
      if (this.basePath == null && this.$route.meta.configpath) {
        return this.$route.meta.configpath;
      }
      return this.basePath
    },
    tableHeaders () {
      const headers = [
        { key: 'product_id', label: this.$t('shift.reports.submittedVolumeName'), type: 'slot', slotcode: 'materialName' },
        { key: 'sumQuantity', label: this.$t('shift.reports.plannedVolume'), headerClass: 'text-end', classTd: 'text-end', format: 'formatDecimal', precision: 0 }];

      if (this.timeRanges.length > 0) {
        this.timeRanges.forEach(value => {
          headers.push(value)
        })
      }
      headers.push({ key: 'sumFedQuantity', label: this.$t('shift.reports.submittedProducts'), headerClass: 'text-end', classTd: 'text-end', format: 'formatDecimal', precision: 0 },
        { key: 'proportionalValue', label: this.$t('shift.reports.proportionToSubmitted'), classTd: 'text-end', headerClass: 'text-end', format: 'formatDecimal', precision: 2 })
      return headers;
    },
    tableExpandConfig () {
      return null;
    },
    tableFilterConfig () {
      const filters = { };
      filters.datePeriod = { type: 'date-range', label: this.$t('shift.reports.datePeriod'), colOnlyFilter: false, class: 'col-2' };
      filters.shiftType = { type: 'checkbox', label: this.$t('shift.type_id'), options: this.shiftTypeOptions, colOnlyFilter: false, class: 'col-2' };
      filters.lines = { type: 'checkbox', label: this.$t('produced.line_id'), options: this.lineOptions, colOnlyFilter: false, class: 'col-4' };
      filters.flows = { type: 'checkbox', label: (this.flowOptions.length > 0 ? this.$t('produced.flow_id') : ''), options: this.flowOptions, textField: 'flowcode', valueField: 'code', class: 'col-4' };

      return filters;
    },
    requestPath () {
      let path = this.ApiRequest.addGetParamsToPath(this.basePathComp + '/list', this.filterValues, true);
      if (this.tableSorting.item) {
        path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
      }
      return path;
    },
    tableActions () {
      const actions = { download: true };
      return actions;
    }
  },
  methods: {
    getTableData () {
      this.tableItems = [];
      if (this.basePathComp) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        this.loading = true;
        this.timeRanges = []
        this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.fullDataSet = response.data
            if (response.data.items != undefined && Object.keys(response.data.items).length > 0) {
              Object.keys(response.data.items).forEach(key => {
                this.tableItems.push(response.data.items[key])
              })
              Object.keys(response.data.timeRanges).forEach(key => {
                let label = '';
                const timeKey = 'dataSetWithTimeRange.' + key
                if (response.data.timeRanges[key].type == 'withInShiftRange') {
                  label = response.data.timeRanges[key].opening_hour + ' ' + response.data.timeRanges[key].closing_hour
                } else {
                  label = this.$t('menu.reports.' + response.data.timeRanges[key].type)
                }
                this.timeRanges.push({
                  key: timeKey,
                  label: label,
                  headerClass: 'text-end',
                  classTd: 'text-end',
                  format: 'formatDecimal',
                  precision: 0
                })
              })
            }
            this.paging = response.paging;
            this.loading = false;
          }
        });
      }
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    },
    download (html, exportCols) {
      html = html.replaceAll(/&nbsp;/g, '');
      this.BasicHelper.tablesToExcelFile(
        html,
        'PET_fed_products_' + (new Date()).toISOString()
      );
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.isMounted = true;
    this.getTableData();
  }
}
