export default {
  name: 'FactoryProductEdit',
  data () {
    return {
      editEl: null,
      loading: false,
      new_status_id: null,
      changeStatus: false
    }
  },
  components: {
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getTitle () {
      return this.$t('factory-product.actions.edit' + (this.canEditStatus ? '' : '-quality'));
    },
    qualityOptions () {
      return this.$store.getters.getConfigOptions('product_qualities', this.$i18n.locale);
    },
    qualityCodeOptions () {
      return this.$store.getters.getConfigOptions('product_qualities_code', this.$i18n.locale);
    },
    canEditStatus () {
      return this.ApiRequest.userHasPermission('edit-factory-product-status');
    },
    statusOptions () {
      return this.$store.getters.getConfigOptions(
        'factory_product_statuses',
        this.$i18n.locale,
        'code',
        ['WAREHOUSE', 'USED', 'SOLD'],
        false,
        false,
        true
      );
    }
  },
  methods: {
    show (el) {
      this.changeStatus = false;
      this.new_status_id = null;
      this.editEl = this.BasicHelper.cloneObject(el);
      this.loading = false;
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    save () {
      if (!this.loading) {
        this.loading = true;
        const reqData = {
          quality_id: this.editEl.quality_id,
          quality_code_id: this.editEl.quality_code_id
        }
        if (this.canEditStatus && this.changeStatus && this.new_status_id) {
          reqData.new_status_id = this.new_status_id
        }
        this.ApiRequest.request('factory-products/edit/' + this.editEl.id, this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    }
  }
}
