import RfidScanner from '@/components/pet/device/rfidreader/scanner/RfidScanner.vue'
import { mapState } from 'vuex';
export default {
  name: 'CargoRfidList',
  components: {
    RfidScanner
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    },
    cargoId: {
      type: Number,
      default: null
    },
    lineId: {
      type: Number,
      default: null
    },
    canEdit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rfids: [],
      loading: true,
      saving: false,
      scanning: false,
      item: {
        lineId: null,
        cargoId: null
      },
      maxitems: 0
    }
  },
  computed: {
    ...mapState({
      currentRfidDeviceId: state => state.pet.currentRfidDeviceId
    }),
    getTitle () {
      if (this.isModal) {
        return this.$t('cargo.d365lines.actions.add-rfids');
      }
      return null;
    },
    currentCargoId () {
      return (this.item && this.item.cargoId) ? this.item.cargoId : this.cargoId;
    },
    currentLineId () {
      return (this.item && this.item.lineId) ? this.item.lineId : this.lineId;
    }
  },
  methods: {
    close () {
      this.scanning = false;
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    },
    show (cargoId, lineId = null, maxitems = 0) {
      this.rfids = [];
      this.item.cargoId = cargoId;
      this.item.lineId = lineId;
      this.maxitems = maxitems;
      this.getTableData();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    startScan () {
      if (this.maxitems > this.rfids.length) {
        this.scanning = true;
        this.$refs.scanner.focusscan();
      }
    },
    getTableData () {
      this.loading = true;
      this.ApiRequest.request('rfid/list?simple=1&order=updated_at,desc', this.ApiRequest.REQUEST_GET, { cargo_id: this.currentCargoId, cargo_d365_line_id: this.currentLineId }, (response) => {
        if (response.data) {
          this.rfids = response.data;
          this.rfidsChanged();
          if (this.canEdit) {
            this.startScan();
          }
        }
        this.loading = false;
      });
    },
    saveRfid (val) {
      const match = this.rfids.find(el => el.rfid == val);
      if (match) {
        this.deleteDuplicate(match, false);
      } else {
        const reqData = {
          cargo_id: this.currentCargoId,
          cargo_d365_line_id: this.currentLineId,
          rfid: val
        }
        this.ApiRequest.request('rfid/add', this.ApiRequest.REQUEST_POST, reqData, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this, { rfid: 'rfid.rfid' });
            this.startScan();
          } else if (response.data) {
            this.rfids.push(response.data);
            this.rfidsChanged();
            if (this.maxitems == this.rfids.length) {
              this.close()
            } else {
              this.startScan();
            }
          }
        });
      }
    },
    deleteDuplicate (el, approved = false) {
      if (approved) {
        this.deleteRfid(el);
      } else {
        this.$refs.deleteRfidMsgBox.show(el, this.$t('cargo.d365lines.rfids.deleteTitle'), this.$t('cargo.d365lines.rfids.deleteMessage'))
      }
    },
    deleteRfid (el) {
      el.loading = true;
      this.$forceUpdate();
      this.ApiRequest.request('rfid/delete/' + el.id, this.ApiRequest.REQUEST_DELETE, {}, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else if (response.data) {
          const splitIndex = this.rfids.findIndex(rfid => rfid.id == el.id);
          if (splitIndex > -1) {
            this.rfids.splice(splitIndex, 1);
          }
          this.rfidsChanged();
          this.startScan();
        }
      });
    },
    rfidsChanged () {
      this.$emit('rfidsChanged', { lineId: this.currentLineId, length: this.rfids.length })
    }
  },
  watch: {
    currentRfidDeviceId: {
      immediate: true,
      handler () {
      }
    }
  }
}
