import useVuelidate from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';

const isValidModifiedQuantity = (value, siblings, vm) => {
  if (!vm.editBalesManual) {
    return true;
  }
  if (!(value == null || value == '') && siblings.inWarehouse) {
    if (parseInt(value) < 50) {
      return false;
    }
    return true;
  }
  return true;
};

const defaultEl = {
  product_id: null,
  invalidData: false,
  comment: '',
  targetQuantity: null,
  targetBales: null,
  bales: [],
  stage_id: null
};

export default {
  name: 'AssemblyApprovalEdit',
  data () {
    return {
      editEl: defaultEl,
      loading: false,
      shiftId: null,
      lineId: null,
      balesLoaded: false,
      editBalesManual: false
    }
  },
  validations: {
    editEl: {
      product_id: { required },
      targetQuantity: requiredIf(function () {
        return this.editBalesManual;
      }),
      targetBales: requiredIf(function () {
        return this.editBalesManual;
      }),
      bales: {
        $each: helpers.forEach({
          modifiedQuantity: { isValidModifiedQuantity }
        })
      }
    }
  },
  components: {
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getTitle () {
      return this.$t('assembly.approval.title');
    },
    canEditFedBales () {
      return this.ApiRequest.userHasPermission(['edit-assembly-fed-bales']);
    },
    canEdit () {
      return !this.loading;
    },
    tableHeaders () {
      const headers = [];
      headers.push({ key: 'quantity', label: this.$t('assembly.approval.quantity') });
      headers.push({ key: 'modifiedQuantity', label: this.$t('assembly.approval.modifiedQuantity'), type: 'slot', slotcode: 'modifiedQuantity', classTd: 'w-25' });
      if (this.canEditFedBales) {
        headers.push({ key: 'factoryproduct.code', label: this.$t('factoryproduct.code') });
        headers.push({ key: 'feeding.fed_at', label: this.$t('assembly.bale.fed_at'), type: 'datetime' });
      }
      headers.push({ key: 'inWarehouse', label: this.$t('assembly.approval.inWarehouse'), type: 'slot', slotcode: 'checkbox' });
      return headers;
    },
    warehouseRemainingWeight () {
      let total = null;
      if (this.balesLoaded) {
        total = 0;
        this.editEl.bales.forEach(el => {
          if (el.inWarehouse) {
            if (el.modifiedQuantity) {
              total += parseFloat(el.modifiedQuantity);
            } else {
              total += el.quantity;
            }
          }
        });
      }
      return total;
    },
    warehouseRemainingBales () {
      if (this.balesLoaded) {
        return this.editEl.bales.filter(el => el.inWarehouse).length;
      }
      return null;
    },
    stageOptions () {
      const products = this.$store.getters.getMatchingProductsWithStages(this.lineId, {}, { id: this.editEl.product_id });
      const options = [];
      Object.values(products).forEach(el => {
        el.stages.forEach(subel => {
          options.push(subel);
        })
      });
      return options;
    }
  },
  methods: {
    approveWithEnter (e) {
      if (this.editEl.targetBales != null && this.editEl.targetQuantity != null) {
        this.autoFixApproval()
      }
    },
    show (elData, shiftId, lineId) {
      this.balesLoaded = false;
      this.editBalesManual = false;
      this.editEl = this.BasicHelper.cloneObject(defaultEl);
      this.lineId = lineId;
      this.editEl.product_id = elData.product_id;
      this.editEl.stage_id = (this.stageOptions.length > 0 ? this.stageOptions[0].id : null);
      this.editEl.comment = (elData.approval ? elData.approval.comment : '');
      this.editEl.targetBales = (elData.approval ? elData.approval.targetBales : null);
      this.editEl.targetQuantity = (elData.approval ? elData.approval.targetQuantity : null)
      if (!this.canEditFedBales) {
        this.editEl.invalidData = (elData.approval ? elData.approval.invalidData : false);
      }
      this.editEl.bales = [];
      this.shiftId = shiftId;
      this.getWarehouseBales();
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    autoFixApproval () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = {
          product_id: this.editEl.product_id,
          targetQuantity: this.editEl.targetQuantity,
          targetBales: this.editEl.targetBales,
          stage_id: this.editEl.stage_id
        };
        this.ApiRequest.request('assembly/approvals/' + this.shiftId + '/autofix', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
            this.$emit('saved', null);
            this.close();
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        this.ApiRequest.request('assembly/approvals/' + this.shiftId + '/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      } else if (this.v$.$invalid) {
        this.ApiRequest.displaySuccessDialog(null, this.$t('assembly.approval.invalidQuantityInfo'), this.$t('general.error'));
      }
    },
    getWarehouseBales () {
      this.balesLoaded = false;
      this.ApiRequest.request('assembly/approvals/' + this.shiftId + '/bales/' + this.editEl.product_id, this.ApiRequest.REQUEST_GET, { includeFed: this.canEditFedBales }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else {
          const bales = [];
          response.data.forEach(el => {
            el.modifiedQuantity = '';
            el.inWarehouse = (el.feeding_id == null);
            bales.push(el);
          });
          this.editEl.bales = bales;
          this.balesLoaded = true;
        }
      });
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
