const defaultFilterValues = {
  datePeriod: {
    start: null,
    end: null
  },
  shiftType: null,
  lines: [],
  flows: [],
  producttypes: [],
  products: [],
  feedingtypes: [],
  groupmode: 'FLOW',
  timegroup: 'DAY'
};

export default {
  name: 'FedGroupedReport',
  components: {
  },
  props: {
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      headers: [],
      preheaders: [],
      summaryRow: [],
      fullDataSet: null,
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      isMounted: false,
      timeRanges: [],
      totalWithInTimeRange: null
    };
  },
  computed: {
    feedingTypeOptions () {
      return this.$store.getters.getConfigOptions('feeding_types', this.$i18n.locale);
    },
    flowOptions () {
      return this.$store.getters.getConfigOptions(
        'line_flows',
        this.$i18n.locale,
        'line_id',
        this.lineIds,
        false,
        false,
        true,
        ['flowcode']
      );
    },
    lineIds () {
      const res = [];
      if (this.filterValues.lines) {
        this.lineOptions.forEach(el => {
          if (this.filterValues.lines.includes(el.code)) {
            res.push(el.value);
          }
        });
      }
      return res;
    },
    lineOptions () {
      return this.$store.getters.getAvailableLineOptions(this.$i18n.locale);
    },
    shiftTypeOptions () {
      return this.$store.getters.getConfigOptions('shift_types', this.$i18n.locale);
    },
    groupingTypeOptions () {
      return [
        { code: 'FLOW', text: this.$t('fed-products.reports.grouptype.FLOW') },
        { code: 'LINE', text: this.$t('fed-products.reports.grouptype.LINE') },
        { code: 'NONE', text: this.$t('fed-products.reports.grouptype.NONE') }
      ]
    },
    timeGroupOptions () {
      return [
        { code: 'SHIFT', text: this.$t('fed-products.reports.grouptime.SHIFT') },
        { code: 'DAY', text: this.$t('fed-products.reports.grouptime.DAY') },
        { code: 'MONTH', text: this.$t('fed-products.reports.grouptime.MONTH') }
      ]
    },
    tableHeaders () {
      const headers = [
        { key: 'shift_startdate', label: '' }
      ];
      this.headers.forEach(el => {
        headers.push({ key: el.headerkey, label: el.title, format: 'formatDecimal', precision: 2, sortable: true });
      });
      return headers;
    },
    tableExpandConfig () {
      return null;
    },
    productTypeOptions () {
      return this.PetHelper.getProductTypes();
    },
    productOptions () {
      return this.PetHelper.getFeadableBaleProducts(true, false, false);
    },
    filteredProducts () {
      return this.productOptions;
    },
    tableFilterConfig () {
      const filters = { };
      filters.datePeriod = { type: 'date-range', label: this.$t('shift.reports.datePeriod'), colOnlyFilter: false, class: 'col-2', controlClass: 'form-control-sm' };
      filters.shiftType = { type: 'checkbox', label: this.$t('shift.type_id'), options: this.shiftTypeOptions, colOnlyFilter: false, class: 'col-2', controlClass: 'btn-sm' };
      filters.groupmode = { type: 'radio', label: this.$t('fed-products.reports.grouptype.title'), options: this.groupingTypeOptions, colOnlyFilter: false, class: 'col-2', controlClass: 'btn-outline-primary btn-sm' };
      filters.timegroup = { type: 'radio', label: this.$t('fed-products.reports.grouptime.title'), options: this.timeGroupOptions, colOnlyFilter: false, class: 'col-2', controlClass: 'btn-outline-primary btn-sm' };
      filters.products = { type: 'advancedSelect', label: this.$t('product.name'), options: this.filteredProducts, colOnlyFilter: false, class: 'col-4', controlClass: 'btn-sm', valueField: 'value' };
      filters.producttypes = { type: 'checkbox', label: this.$t('product.type_id'), options: this.productTypeOptions, colOnlyFilter: false, class: 'col-12', controlClass: 'btn-sm', valueField: 'value' };
      filters.feedingtypes = { type: 'checkbox', label: this.$t('shift.type_id'), options: this.feedingTypeOptions, colOnlyFilter: false, class: 'col-12', controlClass: 'btn-sm', valueField: 'value' };
      filters.lines = { type: 'checkbox', label: this.$t('produced.line_id'), options: this.lineOptions, colOnlyFilter: false, class: 'col-12', controlClass: 'btn-sm' };
      filters.flows = { type: 'checkbox', label: (this.flowOptions.length > 0 ? this.$t('produced.flow_id') : ''), options: this.flowOptions, textField: 'flowcode', valueField: 'code', class: 'col-4', controlClass: 'btn-sm' };

      return filters;
    },
    tableActions () {
      const actions = { download: true, filters: true };
      return actions;
    }
  },
  methods: {
    getRequestPath () {
      return 'fed-products/report-grouped';
    },
    getRequestSendData () {
      const sendFilter = this.BasicHelper.cloneObject(this.filterValues);
      Object.keys(sendFilter).forEach(key => {
        if (!sendFilter[key] || (typeof sendFilter[key] === 'object' && Object.values(sendFilter[key]).length == 0)) {
          delete sendFilter[key];
        }
      })
      return sendFilter;
    },
    getTableData () {
      if (!this.filterValues.datePeriod.start) {
        return;
      }
      this.tableItems = [];
      this.headers = [];
      this.preheaders = null;
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
      this.loading = true;

      this.currentReq = this.ApiRequest.request(this.getRequestPath(), this.ApiRequest.REQUEST_POST, this.getRequestSendData(), (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else if (response.data) {
          this.headers = response.data.headers;
          this.preheaders = response.data.preheaders;
          this.summaryRow = response.data.summary;
          this.tableItems = response.data.items;
        }
        this.loading = false;
      });
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(this.getDefaultFilters());
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    },
    download (html, exportCols) {
      if (this.$refs.tableview) {
        this.$refs.tableview.setDownloading(1);
      }
      const sendData = this.getRequestSendData();
      sendData.download = 1;
      this.ApiRequest.request(this.getRequestPath(), this.ApiRequest.REQUEST_DOWNLOAD, sendData, (response) => {
        if (response.error) {
          this.$store.commit('toastAdd', {
            context: 'danger',
            message: this.ApiRequest.getApiResponseErrorMessage(response, {})
          })
        }
        if (this.$refs.tableview) {
          this.$refs.tableview.setDownloading(0);
        }
      })
    },
    getDefaultFilters () {
      const filters = this.BasicHelper.cloneObject(defaultFilterValues);
      const currentDate = new Date();
      filters.datePeriod.start = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      filters.datePeriod.end = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1).setDate(0);
      return filters;
    }
  },
  mounted () {
    this.filterValues = this.getDefaultFilters();
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.isMounted = true;
    this.getTableData();
  }
}
