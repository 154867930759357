const defaultFilterValues = {

};

export default {
  name: 'OrderCargoDocuments',
  components: {
  },
  props: {
    orderId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      shiftManagers: [],
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { item: 'created_at', direction: 'desc' },
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false,
      fileBase64: null
    };
  },
  computed: {
    basePathComp () {
      return 'orders/orderCargoDocuments'
    },
    baseTransParam () {
      return 'factory-product'
    },
    tableHeaders () {
      const headers = [
        { key: 'cargo', label: this.$t('fed-products.cargo'), type: 'slot', slotcode: 'cargo' },
        { key: 'title', label: this.$t('modelfiles.title'), filterKey: 'title' },
        { key: 'model_attachment.name.' + this.$i18n.locale, label: this.$t('modelfiles.model_attachment_id'), filterKey: 'documentType' },
        { key: 'd365LastSyncAt', label: this.$t('modelfiles.actions.D365UpdatedTimeStamp'), type: 'slot', slotcode: 'd365LastSyncAt' },
        { key: 'actualFileD365Update', label: this.$t('modelfiles.actions.actualFileD365Update'), format: 'formatYesNo', reverseMode: true, classTd: 'w-10' }
      ];
      const actions = [];
      actions.push({ label: this.$t('general.actions.overview'), emit: 'viewFile', bicon: 'bi bi-file-earmark-pdf-fill fs-1', class: 'text-primary', ifconfig: { func: this.validFileType, useFunc: true } });
      actions.push({ label: this.$t('modelfiles.actions.download'), emit: 'downloadFile', bicon: 'bi bi-file-earmark-arrow-down-fill fs-1', class: 'text-info' });
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-20' });
      return headers;
    },
    tableExpandConfig () {
      return null;
    },
    tableFilterConfig () {
      const filters = { };
      filters.title = { type: 'text', label: '', colOnlyFilter: true };
      filters.documentType = { type: 'text', label: '', colOnlyFilter: true };
      return filters;
    },
    requestPath () {
      let path = this.ApiRequest.addGetParamsToPath(this.basePathComp + '/?perPage=' + this.paging.limit + '&page=' + this.paging.page, this.filterValues, true);
      if (this.tableSorting.item) {
        path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
      }
      return path;
    },
    tableActions () {
      const actions = { download: false };
      return actions;
    }
  },
  methods: {
    validFileType (el) {
      if (el.model_attachment != null && el.model_attachment.type == 'xlsx') {
        return false
      }
      return true
    },
    viewFile (el, download = 0) {
      if (el.model_attachment.type == 'xlsx') {
        download = true
      }
      this.fileBase64 = null;
      const reqType = (download ? this.ApiRequest.REQUEST_DOWNLOAD : this.ApiRequest.REQUEST_GET);
      el.loading = true
      const findEl = this.tableItems.find(elFind => elFind == el)
      if (findEl) {
        findEl.loading = true;
      }
      this.ApiRequest.request('cargo-orders/files/' + el.model_element_id + '/view/' + el.id, reqType, {}, (response) => {
        if (response.error) {
          this.$store.commit('toastAdd', {
            context: 'danger',
            message: this.ApiRequest.getApiResponseErrorMessage(response, {})
          })
        } else if (response.data && !download) {
          this.fileBase64 = response.data.base64;
          if (!this.viewButtonMode) {
            this.$refs.filepreview.show();
          }
        }
        findEl.loading = false;
      });
    },
    getTableData () {
      if (this.basePathComp) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        this.loading = true;
        this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, { orderId: this.orderId }, (response) => {
          if (response.data) {
            response.data.forEach(function callback (value, index) {
              response.data[index].loading = false
            });
            this.tableItems = response.data;
            this.paging = response.paging;
            this.loading = false;
          }
        });
      }
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.getTableData();
    this.isMounted = true;
  },
  watch: {
    basePathComp: {
      immediate: true,
      handler (val) {
        if (val) {
          this.isMounted = false;
          this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
          this.getTableData();
          this.isMounted = true;
        }
      }
    }
  }
}
