import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import draggable from 'vuedraggable'
import { evaluate } from 'mathjs';

const defaultEl = {
  id: null,
  priority: 0,
  formula: [],
  minValue: null,
  maxValue: null,
  includingMin: true,
  includingMax: true,
  base_config_id: null,
  quality_id: null,
  quality_code_id: null
};
const mathSymbols = [
  '+',
  '-',
  '/',
  '*',
  '(',
  ')'
];
const checkFormula = (value, siblings, vm) => {
  if (value.length == 0) return true;
  let stringFormula = '';
  value.forEach(function callback (valueElement, index) {
    stringFormula += 'type' in valueElement && valueElement.type == 'matchOption' ? valueElement.name : Math.floor(Math.random() * 1000) + 10;
  });
  try {
    evaluate(stringFormula)
  } catch (e) {
    if (e instanceof SyntaxError) {
      return false;
    }
  }
  return true;
}
export default {
  name: 'QualityMatchSetupEdit',
  components: {
    draggable
  },
  data () {
    return {
      form: defaultEl,
      loading: false,
      matchOption: mathSymbols.map((name, index) => {
        return { name, order: index + 1, fixed: false, id: index, type: 'matchOption' };
      }),
      isDragging: true
    }
  },
  validations: {
    form: {
      quality_id: { required },
      quality_code_id: { required },
      formula: { checkFormula }
    }
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    },
    baseConfigId: {
      type: Number,
      default: null
    },
    groupedMeasurements: {
      type: Object,
      default: null
    },
    qualityIds: {
      type: Array,
      default: []
    },
    qualityCodeIds: {
      type: Array,
      default: []
    }
  },
  computed: {
    dragOptions () {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost'
      };
    },
    checkboxOption () {
      return { title: 'test1', bicon: 'check' }
    },
    baseTransParam () {
      return 'menu.quality.quality-match-setup';
    },
    basePerm () {
      return this.$route.meta.configPermabase;
    },
    getTitle () {
      if (this.form.id) {
        return this.$t(this.baseTransParam + '.actions.edit');
      }
      return this.$t(this.baseTransParam + '.actions.new');
    },
    qualityOptions () {
      let qualities = [];
      if (this.qualityIds.length > 0) {
        qualities = this.$store.getters.getConfigOptions('product_qualities', this.$i18n.locale, 'id', this.qualityIds, false, false, true);
      } else {
        qualities = this.$store.getters.getConfigOptions('product_qualities', this.$i18n.locale);
      }
      return qualities;
    },
    qualityCodeOptions () {
      let qualities = [];
      if (this.qualityIds.length > 0) {
        qualities = this.$store.getters.getConfigOptions('product_qualities_code', this.$i18n.locale, 'id', this.qualityCodeIds, false, false, true);
      } else {
        qualities = this.$store.getters.getConfigOptions('product_qualities_code', this.$i18n.locale);
      }
      return qualities;
    },
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-' + this.basePerm);
    },
    measureTypeOptions () {
      return this.$store.getters.getConfigOptions('measure_types', this.$i18n.locale);
    },
    typeOptions () {
      return this.$store.getters.getConfigOptions('product_types', this.$i18n.locale);
    },
    baseConfigOptions () {
      return this.$store.getters.getConfigOptions('quality_measurement_base_config', this.$i18n.locale);
    },
    categoryOptions () {
      return this.$store.getters.getConfigOptions('quality_measurement_categories', this.$i18n.locale);
    },
    qualityCategoryOptions () {
      return this.$store.getters.getConfigOptions('quality_measurement_categories', this.$i18n.locale);
    },
    categoryOptionsIsOptions () {
      return this.$store.getters.getConfigOptions('quality_measurement_categories', this.$i18n.locale, 'isOption', 1);
    },
    productQualityMeasurementGroupingType () {
      const data = [];
      const thisVar = this;
      this.$store.state.config.productQualityMeasurementGroupingType.forEach(function callback (value, index) {
        data.push({ value: value, text: thisVar.$t('menu.quality.qualityElement.groupType_' + value) });
      });
      return data;
    }
  },
  methods: {
    qualityCategoryText (index) {
      const text = this.qualityCategoryOptions.find(el => el.value == index)
      return text == undefined ? '' : text.text
    },
    show (elData) {
      if (elData) {
        this.form = this.BasicHelper.cloneObject(elData);
      } else {
        this.form = this.BasicHelper.cloneObject(defaultEl);
      }
      this.form.base_config_id = this.baseConfigId;
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = this.BasicHelper.cloneObject(this.form);
        this.ApiRequest.request('config/mwc-custom/product-quality-match-setup/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$store.dispatch('reloadConfig');
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    onMove ({ relatedContext, draggedContext }) {
    },
    onDragchange (event) {
      if ('removed' in event && 'element' in event.removed) {
        if ('type' in event.removed.element && event.removed.element.type == 'matchOption') {
          if (this.matchOption.find(el => el.id == event.removed.element.id) == undefined) {
            this.matchOption.push(event.removed.element);
          }
        }
        if ('category_id' in event.removed.element) {
          if (this.groupedMeasurements[event.removed.element.category_id].find(el => el.id == event.removed.element.id) == undefined) {
            this.groupedMeasurements[event.removed.element.category_id].push(event.removed.element);
          }
        }
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  watch: {
    isDragging (newValue) {
      if (newValue) {
        this.delayedDragging = false;
        return;
      }
      this.$nextTick(() => {
        this.delayedDragging = false;
      });
    }
  }
}
