class DeviceHelper {
  getDefaultPublishMessage (device, _uid, defaultValue = 'ref') {
    let message = this.getConfigValue(device.config, 'defaultPublishMessage', defaultValue);

    if (message !== defaultValue) {
      const timestamp = Date.now();
      message = message.replace('::REQ_ID::', `${_uid}/${timestamp}`);
    }

    return message;
  }

  getSubscribeParamPath (device, defaultValue = 'message') {
    return this.getConfigValue(device.config, 'subscribeParamPath', defaultValue);
  }

  getIgnoreReqData (device, defaultValue = 'ref') {
    return this.getConfigValue(device.config, 'ignoreReqData', defaultValue);
  }

  getConfigValue (config, key, defaultValue) {
    return config[key] !== undefined ? config[key] : defaultValue;
  }
}

export default new DeviceHelper()
