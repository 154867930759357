var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shift-production-journal-lines"},[(_vm.isMounted)?[(_vm.journal && _vm.journal.bookedAt)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('LabelTooltip',{staticClass:"fw-bold",attrs:{"code":"shift.production-journal.productioncost","readonly":true}}),_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(_vm.journal.usedTotalCost + _vm.journal.trashTotalCost, 2, _vm.$i18n.locale ))+" ")],1),_c('div',{staticClass:"col-md-4"},[_c('LabelTooltip',{staticClass:"fw-bold",attrs:{"code":"shift.production-journal.usedTotalCost","readonly":true}}),_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(_vm.journal.usedTotalCost, 2, _vm.$i18n.locale ))+" ")],1),_c('div',{staticClass:"col-md-4"},[_c('LabelTooltip',{staticClass:"fw-bold",attrs:{"code":"shift.production-journal.trashCost","readonly":true}}),_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(_vm.journal.trashTotalCost, 2, _vm.$i18n.locale ))+" ")],1)]):_vm._e(),(_vm.journal && !_vm.journal.bookedAt && !_vm.readonly)?_c('div',{staticClass:"text-end"},[_c('span',{staticClass:"btn btn-warning",on:{"click":function($event){return _vm.setLineDefaultCosts()}}},[_vm._v(" "+_vm._s(_vm.$t('shift.production-journal.actions.defaultcosts'))+" ")])]):_vm._e(),_c('Tableview',{ref:"tableview",attrs:{"fields":_vm.tableHeaders,"loading":_vm.loading,"items":_vm.tableItems,"displayFilterActions":true,"filterActions":_vm.tableActions,"tableClass":"table-striped table-sm table-hover","rowClassText":_vm.getRowClassTxt},on:{"saved":_vm.getTableData,"setCostByRate":_vm.setCostByRateLineClick},scopedSlots:_vm._u([{key:"warningIndicator",fn:function(ref){
var data = ref.data;
return [(_vm.journal.lines[data.index].hasQuantity === 0)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"bi bi-exclamation-triangle-fill d-flex justify-content-center text-danger",attrs:{"title":_vm.$t('product.hasNoQuantity')}}):(_vm.journal.lines[data.index].hasQuantity === 1)?_c('span',{staticClass:"bi bi-check d-flex justify-content-center"}):_vm._e()]}},{key:"targetProductPercentage",fn:function(ref){
var data = ref.data;
return [(!_vm.readonly && data.item.mode == 1)?[_c('NumberInput',{attrs:{"minValue":0,"maxValue":100,"precision":0,"controlClass":'form-control-sm ' + (_vm.validator && _vm.validator.$error ? 'is-invalid' : ''),"dbparam":((data.index) + ".targetProductPercentage")},model:{value:(_vm.journal.lines[data.index].targetProductPercentage),callback:function ($$v) {_vm.$set(_vm.journal.lines[data.index], "targetProductPercentage", $$v)},expression:"journal.lines[data.index].targetProductPercentage"}})]:[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.value, 0, _vm.$i18n.locale))+" ")]]}},{key:"costPercentage",fn:function(ref){
var data = ref.data;
return [(!_vm.readonly && data.item.mode == 1)?[_c('NumberInput',{attrs:{"controlClass":'form-control-sm ' + (_vm.validator && _vm.validator.$error ? 'is-invalid' : ''),"dbparam":((data.index) + ".costPercentage"),"precision":6,"maxValue":100,"minValue":0},model:{value:(_vm.journal.lines[data.index].costPercentage),callback:function ($$v) {_vm.$set(_vm.journal.lines[data.index], "costPercentage", $$v)},expression:"journal.lines[data.index].costPercentage"}})]:[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.value, 4, _vm.$i18n.locale))+" ")]]}},{key:"costValue",fn:function(ref){
var data = ref.data;
return [(!_vm.readonly && data.item.mode == 2)?[_c('NumberInput',{attrs:{"controlClass":"form-control-sm","dbparam":((data.index) + ".costValue"),"precision":2,"maxValue":0},model:{value:(_vm.journal.lines[data.index].costValue),callback:function ($$v) {_vm.$set(_vm.journal.lines[data.index], "costValue", $$v)},expression:"journal.lines[data.index].costValue"}})]:[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.value, 2, _vm.$i18n.locale))+" ")]]}},{key:"mode",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.$t(("production.modes." + (data.value))))+" ")]}},{key:"marketcost",fn:function(ref){
var data = ref.data;
return [(data.value != null)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip"}],attrs:{"title":_vm.$t('shift.production-journal.line.defaultBuyRate', { rate: _vm.BasicHelper.formatDecimal(data.item.defaultBuyRate, 6, _vm.$i18n.locale) })}},[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.value, 2, _vm.$i18n.locale))+" ")]):_vm._e()]}}],null,false,307045499)})]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }