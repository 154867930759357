import BarChart from 'ebg-vue-common/src/components/common/charts/BarChart.js'
import PieChart from 'ebg-vue-common/src/components/common/charts/PieChart.js'
import ShiftDeviceMeasureList from '@/components/pet/shift/devicemeasurelist/ShiftDeviceMeasureList.vue'

const defaultFilterValues = {
}

export default {
  name: 'ShiftSummary',
  components: {
    PieChart,
    BarChart,
    ShiftDeviceMeasureList
  },
  props: {
    shiftId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      shift: null,
      summary: null,
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      isMounted: false,
      availableFlowStages: [],
      shift_flow_produced_config: []
    };
  },
  computed: {
    finalProductOption () {
      const getMatchingProductsWithStages = this.$store.getters.getMatchingProductsWithStages(this.shift !== null ? this.shift.line_id : null, { canProduce: true }, { isFinalProduct: true })
      const validProductIds = [];
      if (getMatchingProductsWithStages != null) {
        Object.values(getMatchingProductsWithStages).forEach(productsWithStages => {
          Object.values(productsWithStages.products).forEach(product => {
            validProductIds.push(product.id)
          })
        })
      }
      const products = this.$store.getters.getConfigOptions('products')
      return products.filter(el => validProductIds.includes(el.value))
    },
    requestPath () {
      return this.ApiRequest.addGetParamsToPath('shift/view/' + this.shiftId + '/summary', this.filterValues, false);
    },
    products () {
      return this.$store.getters.getConfigOptions('products', this.$i18n.locale);
    },
    pieCharts () {
      const res = {
        manufactured: {
          code: 'manufactured',
          title: 'shift.summary.manufactured',
          labels: [],
          datasets: [{
            data: [],
            backgroundColor: [],
            borderColor: []
          }]
        },
        manfacttypes: {
          code: 'manfacttypes',
          title: 'shift.summary.manfacttypes',
          labels: [],
          datasets: [{
            data: [],
            backgroundColor: [],
            borderColor: []
          }]
        }
      }
      if (this.summary) {
        Object.values(this.summary.manufactured).forEach(el => {
          const product = this.productOptions.find(prodEl => prodEl.value == el.product_id);
          res.manufactured.labels.push((product ? product.text : el.product_id) + ' (' + el.quantity + ')');
          res.manufactured.datasets[0].backgroundColor.push(product ? product.color : '#000');
          res.manufactured.datasets[0].borderColor.push('#6c757d');
          res.manufactured.datasets[0].data.push(el.quantity);
        });
        Object.values(this.summary.manfacttypes).forEach(el => {
          const type = this.productTypeOptions.find(prodEl => prodEl.value == el.type_id);
          res.manfacttypes.labels.push((type ? type.text : el.product_id) + ' (' + el.quantity + ')');
          res.manfacttypes.datasets[0].backgroundColor.push(type ? type.color : '#000');
          res.manfacttypes.datasets[0].borderColor.push('#6c757d');
          res.manfacttypes.datasets[0].data.push(el.quantity);
        });
      }
      return res;
    },
    barCharts () {
      const res = {
        recipepercent: {
          code: 'recipepercent',
          title: 'shift.summary.recipepercent',
          labels: [],
          datasets: [{
            data: [],
            backgroundColor: []
          }]
        },
        fedshift: {
          code: 'fedshift',
          title: 'shift.summary.fedshift',
          labels: [],
          datasets: [{
            data: [],
            backgroundColor: []
          }]
        },
        fedtotal: {
          code: 'fedtotal',
          title: 'shift.summary.fedtotal',
          labels: [],
          datasets: [{
            data: [],
            backgroundColor: []
          }]
        }
      };
      if (this.summary) {
        Object.values(this.summary.fedshift).forEach(el => {
          const product = this.productOptions.find(prodEl => prodEl.value == el.product_id);
          res.fedshift.labels.push((product ? product.text : el.product_id) + ' (' + el.quantity + ')');
          res.fedshift.datasets[0].backgroundColor.push(product ? product.color : '#000');
          res.fedshift.datasets[0].data.push(el.quantity);

          res.recipepercent.labels.push((product ? product.text : el.product_id) + ' (' + el.recipepercent + ')');
          res.recipepercent.datasets[0].backgroundColor.push(product ? product.color : '#000');
          res.recipepercent.datasets[0].data.push(el.recipepercent);
        });
        Object.values(this.summary.fedtotal).forEach(el => {
          const product = this.productOptions.find(prodEl => prodEl.value == el.product_id);
          res.fedtotal.labels.push((product ? product.text : el.product_id) + ' (' + el.quantity + ')');
          res.fedtotal.datasets[0].backgroundColor.push(product ? product.color : '#000');
          res.fedtotal.datasets[0].data.push(el.quantity);
        });
      }
      return res;
    },
    productOptions () {
      return this.$store.getters.getConfigOptions(
        'products',
        this.$i18n.locale,
        null,
        null,
        false,
        true,
        false,
        ['color']
      )
    },
    productTypeOptions () {
      return this.$store.getters.getConfigOptions(
        'product_types',
        this.$i18n.locale,
        null,
        null,
        false,
        true,
        false,
        ['color']
      )
    }
  },
  productOptions () {
    return this.$store.getters.getConfigOptions('products', this.$i18n.locale)
  },
  methods: {
    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.loading = true;
      this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.data) {
          this.shift = response.data.shift;
          this.availableStages = this.$store.getters.getConfigOptions('line_flows', this.$i18n.locale, 'line_id', this.shift.line_id,
            false, false, false, ['stages'])
          this.shift_flow_produced_config = this.shift.shift_flow_produced_config
          this.summary = response.data.summary;
          this.loading = false;
        }
      });
    },
    getStageName (stageId, flowId) {
      let text = '';
      const findFlow = this.availableStages.find(el => el.value == flowId)
      if (findFlow != undefined && findFlow.stages.length > 0) {
        const findStage = findFlow.stages.find(el => el.id == stageId)
        if (findStage != undefined) {
          text = findStage.name[this.$i18n.locale]
        }
      }
      return text
    },
    getTotalCalculatedWeight (shift, stageId) {
      if (
        shift.shiftProductionEffectivityData.stages &&
        shift.shiftProductionEffectivityData.stages[stageId]
      ) {
        return shift.shiftProductionEffectivityData.stages[stageId].totalCalculatedWeight;
      }
      return '-';
    },
    getProductionEffectivity (shift, stageId) {
      if (
        shift.shiftProductionEffectivityData.stages &&
        shift.shiftProductionEffectivityData.stages[stageId]
      ) {
        return shift.shiftProductionEffectivityData.stages[stageId].productionEffectivity;
      }
      return '-';
    },
    getProductNames (productIds) {
      let text = ''
      productIds.forEach(id => {
        const findProduct = this.productOptions.find(el => el.value == id)
        if (findProduct != undefined) {
          if (text == '') {
            text = findProduct.text
          } else {
            text = text + ', ' + findProduct.text
          }
        }
      })
      return text
    }
  },
  mounted () {
    this.getTableData();
    this.isMounted = true;
  },
  watch: {
    shiftId: {
      immediate: true,
      handler (val) {
        if (this.isMounted && val !== (this.shift ? this.shift.id : null)) {
          this.getTableData();
        }
      }
    }
  }
}
