import useVuelidate from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';

const defaultEl = {
  id: null,
  quantity: null,
  fed_at: null,
  comment: null
};

export default {
  name: 'ProductFeedingEdit',
  data () {
    return {
      editEl: defaultEl,
      loading: false,
      canEditQuantity: false,
      canEditComment: false
    }
  },
  validations: {
    editEl: {
      quantity: { required },
      comment: {
        required: requiredIf(function () {
          return this.canEditComment;
        })
      }
    }
  },
  components: {
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    baseTransParam () {
      return 'fed-products';
    },
    getTitle () {
      if (this.editEl.id) {
        return this.$t(this.baseTransParam + '.actions.edit');
      }
      return this.$t(this.baseTransParam + '.actions.new');
    },
    canEditQuantityCalc () {
      return !this.loading && this.canEditQuantity;
    },
    canEditTime () {
      return !this.loading && this.ApiRequest.userHasPermission('edittime-shift-fed-products');
    },
    defaultMeasureTypeId () {
      return this.$store.getters.getParamFromConfig('products', this.editEl.product_id, 'default_measure_type_id');
    }
  },
  methods: {
    show (elData, canEditQuantity = false, canEditComment = false) {
      if (elData) {
        this.editEl = this.BasicHelper.cloneObject(elData);
        this.editEl.quantity = parseFloat(this.editEl.quantity);
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
      }
      this.canEditQuantity = canEditQuantity;
      this.canEditComment = canEditComment;
      if (canEditComment && elData.comment) {
        this.editEl.comment = elData.comment.comment;
      }
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        this.ApiRequest.request('fed-products/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
