import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

export default {
  name: 'CargoForceStatusChange',
  data () {
    return {
      status_id: null,
      cargo_id: null,
      loading: false
    }
  },
  validations: {
    status_id: { required },
    cargo_id: { required }
  },
  computed: {
    cargoStatusOptions () {
      return this.$store.getters.getConfigOptions('cargo_statuses', this.$i18n.locale)
    }
  },
  methods: {
    show (cargoId, statusId) {
      this.cargo_id = cargoId;
      this.status_id = statusId;
      this.$refs['modal-window'].show();
    },
    close () {
      this.$refs['modal-window'].hide();
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        this.ApiRequest.request(`cargo-orders/change-status-force/${this.cargo_id}`, this.ApiRequest.REQUEST_POST, { status_id: this.status_id }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$emit('saved')
            this.close();
          }
          this.loading = false;
        });
      }
    }
  },
  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'CargoForceStatusChange' }) }
  }
}
