var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"purchase-order-list"},[(_vm.isMounted)?[_c('Tableview',{ref:"tableview",attrs:{"fields":_vm.tableHeaders,"loading":_vm.loading,"items":_vm.tableItems,"filterValues":_vm.filterValues,"expandableConfig":_vm.tableExpandConfig,"filters":_vm.tableFilterConfig,"displayFilterActions":true,"filterActions":_vm.tableActions,"displayColFiltersAlways":true,"tableClass":"table-striped table-sm  table-hover","filterActionAlign":"text-start mt-3","smallScreenCards":true},on:{"saved":_vm.getTableData,"editItem":_vm.editItem,"addItem":function($event){return _vm.editItem(null)},"updateFilter":_vm.updateFilter,"clearFilters":_vm.clearFilters,"viewItem":_vm.viewItem,"restoreItem":_vm.restoreItem,"cancelItem":_vm.cancelItem},scopedSlots:_vm._u([{key:"statuscolor",fn:function(ref){
var data = ref.data;
return [_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"position-absolute h-100 w-100 top-0",style:('background-color:' + _vm.getStatusColor(data.value)),attrs:{"title":_vm.getStatusName(data.value)}})]}},{key:"plannedDeliveryMonth",fn:function(ref){
var data = ref.data;
return [_c('div',{class:"text-center"},[_c('span',{class:("text-center " + (_vm.getMonthNameColor(data.item.plannedDeliveryMonth)))},[_vm._v(_vm._s(_vm.getMonthName(data.item.plannedDeliveryMonth)))])])]}},{key:"deliveryDate",fn:function(ref){
var data = ref.data;
return [(data.value)?[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDate(data.value, _vm.$t('general.dateFormat')))+" ")]:[_vm._v(" ?."+_vm._s(_vm.BasicHelper.formatDate(data.item.plannedDeliveryMonth, 'MM.YYYY'))+" ")]]}},{key:"delta",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.item.weight - data.item.plannedWeight, 0, _vm.$i18n.locale))+" ")]}},{key:"expandrow",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"p-3"},[_c('OrderProductList',{attrs:{"orderId":data.id,"readonly":true,"isIncoming":data.isIncoming,"orderCargoTypeId":data.order_cargo_type_id,"transbase":_vm.baseTransParam,"currencyCode":_vm.currencyCode(data.currency_id)}})],1)]}}],null,false,2783559859)}),(_vm.paging.pages > 1  && !_vm.loading)?_c('b-pagination',{attrs:{"total-rows":_vm.paging.total,"per-page":_vm.paging.limit},on:{"change":_vm.changePage},model:{value:(_vm.paging.page),callback:function ($$v) {_vm.$set(_vm.paging, "page", $$v)},expression:"paging.page"}}):_vm._e()]:_vm._e(),_c('MessageBox',{ref:"itemCancelMsgBox",on:{"confirm":function($event){return _vm.cancelItem($event, 1)}}}),_c('MessageBox',{ref:"itemOvertakeMsgBox",on:{"confirm":function($event){return _vm.changeStatus($event, 'LOADING', true)}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }