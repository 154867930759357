import FactoryProductWriteoff from '@/components/pet/factoryproduct/writeoff/FactoryProductWriteoff.vue'
import FactoryProductUsageInfo from '@/components/pet/factoryproduct/usageinfo/FactoryProductUsageInfo.vue'
import FactoryProductEdit from '@/components/pet/factoryproduct/edit/FactoryProductEdit.vue'
import DevicePrinterCallprint from '@/components/pet/device/printer/callprint/DevicePrinterCallprint.vue'
import FactoryProductEditBales from '@/components/pet/factoryproduct/editBales/FactoryProductEditBales.vue';
import FactoryProductD365Batch from '@/components/pet/factoryproduct/d365batch/FactoryProductD365Batch.vue';

const defaultFilterValues = {
  created: null,
  code: '',
  type: [],
  status: [],
  quality: [],
  name: '',
  remainingQuantityUntil: '',
  remainingBalesUntil: '',
  usageTypes: [],
  includeEmptyRemainingQuantity: false,
  batch: ''
};

export default {
  name: 'FactoryProductList',
  components: {
    FactoryProductD365Batch,
    FactoryProductEditBales,
    FactoryProductWriteoff,
    FactoryProductUsageInfo,
    FactoryProductEdit,
    DevicePrinterCallprint
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { item: 'created_at', direction: 'desc' },
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false
    };
  },
  computed: {
    basePathComp () {
      return 'factory-products'
    },
    quarantineStatusId () {
      return this.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'code', 'QUARANTINE', 'id')
    },
    basePerm () {
      return 'warehouse-products';
    },
    baseTransParam () {
      return 'factory-product';
    },
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-warehouse-products');
    },
    canEditD365Batch () {
      return this.ApiRequest.userHasPermission('edit-d365-batch');
    },
    tableHeaders () {
      const headers = [
        { key: 'created_at', label: this.$t(this.baseTransParam + '.created_at'), type: 'datetime', filterKey: 'created' },
        { key: 'status_id', label: this.$t(this.baseTransParam + '.status_id'), format: 'formatFromConfig', configKey: 'factory_product_statuses', configParam: 'name', classCondition: this.findClassCondition },
        { key: 'product_id', label: this.$t(this.baseTransParam + '.product_id'), format: 'formatFromConfig', configKey: 'products', configParam: 'name', filterKey: 'name' },
        { key: 'quality_id', label: this.$t(this.baseTransParam + '.quality_id'), type: 'slot', slotcode: 'quality' },
        { key: 'code', label: this.$t(this.baseTransParam + '.code'), sortable: false, filterKey: 'code' },
        { key: 'measure_type_id', label: this.$t(this.baseTransParam + '.measure_type_id'), format: 'formatFromConfig', configKey: 'measure_types', configParam: 'name' },
        { key: 'countrycode', label: this.$t(this.baseTransParam + '.countrycode'), filterKey: 'countryCode' },
        { key: 'warehouse', label: this.$t(this.baseTransParam + '.warehouse'), type: 'slot', slotcode: 'warehouse', filterKey: 'warehouseCode' },
        { key: 'startQuantity', label: this.$t(this.baseTransParam + '.startQuantity') },
        { key: 'remainingQuantity', label: this.$t(this.baseTransParam + '.remainingQuantity'), filterKey: 'remainingQuantityUntil' },
        { key: 'originalBales', label: this.$t(this.baseTransParam + '.originalBales') },
        { key: 'remainingBales', label: this.$t(this.baseTransParam + '.remainingBales'), filterKey: 'remainingBalesUntil' }
      ];
      const actions = [];
      actions.push({ label: this.$t('factoryproduct.createD365Batch'), emit: 'D365BatchFrom', bicon: 'layers', class: 'btn-sm btn-tertiary', ifconfig: { func: this.displayCreateD365Batch, useFunc: true } });
      actions.push({ label: this.$t('general.actions.print'), emit: 'printLabelView', bicon: 'printer-fill', class: 'btn-sm btn-secondary' });
      if (this.canEdit) {
        actions.push({
          label: this.$t(this.baseTransParam + '.actions.writeoff'),
          emit: 'doWriteoff',
          bicon: 'slash-circle',
          class: 'btn-danger btn-sm',
          ifconfig: { useFunc: true, func: this.canWriteoffProduct }
        });
        actions.push({
          label: this.$t(this.baseTransParam + '.actions.editBales'),
          emit: 'editBales',
          bicon: 'clipboard2-check-fill',
          class: 'btn-warning btn-sm text-white'
        });
      }
      if (this.ApiRequest.userHasPermission('edit-factory-product-quality')) {
        const actionTitle = (this.ApiRequest.userHasPermission('edit-factory-product-status') ? '' : '-quality');
        actions.push({
          label: this.$t(this.baseTransParam + '.actions.edit' + actionTitle),
          emit: 'editItem',
          bicon: 'pencil-fill',
          class: 'btn-primary btn-sm'
        });
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    tableExpandConfig () {
      return { component: 'slot' }
    },
    tableFilterConfig () {
      const filters = { };
      filters.warehouseCode = { type: 'text', label: '', colOnlyFilter: true, controlClass: 'form-control-sm' };
      filters.countryCode = { type: 'text', label: '', colOnlyFilter: true, controlClass: 'form-control-sm' };
      filters.created = { type: 'date-range', label: '', colOnlyFilter: true };
      filters.code = { type: 'text', label: '', colOnlyFilter: true };
      filters.usageTypes = { type: 'checkbox', label: this.$t('product.usageTypes'), class: 'col-3', options: this.usageTypeOptions };
      filters.quality = { type: 'checkbox', label: this.$t(this.baseTransParam + '.quality_id'), options: this.qualityOptions, colOnlyFilter: false, class: 'col-md-2' };
      filters.status = { type: 'checkbox', label: this.$t(this.baseTransParam + '.status_id'), options: this.statusOptions, colOnlyFilter: false, class: 'col-md-5' };
      filters.includeEmptyRemainingQuantity = { type: 'checkbox-single', label: this.$t(this.baseTransParam + '.includeEmptyRemainingQuantity'), class: 'col-2 mt-3', inlineLabel: true };
      filters.type = { type: 'checkbox', label: this.$t(this.baseTransParam + '.type_id'), options: this.typeOptions, colOnlyFilter: false, class: 'col-12' };
      filters.batch = { type: 'text', label: this.$t(this.baseTransParam + '.batch_id'), class: 'col-2' };
      filters.quality_code_id = { type: 'text', label: this.$t(this.baseTransParam + '.quality_code_id'), class: 'col-2' };
      filters.name = { type: 'text', label: '', colOnlyFilter: true };
      filters.remainingQuantityUntil = { type: 'text', label: '', colOnlyFilter: true };
      filters.remainingBalesUntil = { type: 'text', label: '', colOnlyFilter: true };
      return filters;
    },
    requestPath () {
      let path = this.ApiRequest.addGetParamsToPath(this.basePathComp + '/list?perPage=' + this.paging.limit + '&page=' + this.paging.page, this.filterValues, true);
      if (!this.filterValues.includeEmptyRemainingQuantity) {
        path = this.ApiRequest.addGetParamsToPath(path, { removeEmpty: true });
      }
      if (this.tableSorting.item) {
        path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
      }
      return path;
    },
    usageTypeOptions () {
      return this.PetHelper.getProductUsageTypeOptions();
    },
    tableActions () {
      const actions = { download: false };
      return actions;
    },
    qualityOptions () {
      const options = this.$store.getters.getConfigOptions('product_qualities', this.$i18n.locale);
      options.unshift({ code: 'null', text: this.$t('quality.code') });
      return options;
    },
    typeOptions () {
      const productTypeIds = [];
      const checkValue = this.filterValues.usageTypes;
      checkValue.push('type_id');
      const products = this.$store.getters.getConfigOptions('products', this.$i18n.locale, null, null, false, false, false, checkValue);
      checkValue.pop();
      products.forEach(
        el => {
          this.filterValues.usageTypes.forEach(subEl => {
            if (el[subEl] === true && !productTypeIds.includes(el.type_id)) {
              productTypeIds.push(el.type_id);
            }
          })
        }
      );
      return this.PetHelper.getProductTypes().filter(el => (this.filterValues.usageTypes.length ? el.inAxapta && productTypeIds.includes(el.value) : el.inAxapta));
    },
    statusOptions () {
      return this.$store.getters.getConfigOptions('factory_product_statuses', this.$i18n.locale);
    },
    inWarehouseStatusId () {
      return this.statusOptions.find(el => el.code == 'WAREHOUSE')?.value;
    }
  },
  methods: {
    getTableData () {
      if (this.basePathComp) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        this.loading = true;
        const self = this;
        this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, { viewName: this.$route.path }, function (response) {
          if (response.data) {
            self.tableItems = response.data;
            self.paging = response.paging;
            self.loading = false;
          }
        });
      }
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    findClassCondition (el) {
      if (el.purchasecargo != null && this.quarantineStatusId == el.purchasecargo.status_id) {
        return 'bg-warning';
      }
      return '';
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    },
    getQualityBadgeColors (qualityId) {
      return this.$store.getters.getQualityBadgeColors(qualityId);
    },
    doWriteoff (el) {
      this.$refs.itemWriteoffFrm.show(el);
    },
    canWriteoffProduct (el) {
      return (el.status_id == this.inWarehouseStatusId && (el.remainingQuantity > 0 || el.remainingBales > 0));
    },
    editItem (el) {
      this.$refs.itemEditFrm.show(el);
    },
    printLabelView (el) {
      this.$refs.callprintfrm.show(null, this.basePathComp + '/print-label/' + el.id);
    },
    editBales (el) {
      this.$refs.itemEditBalesFrm.show(el);
    },
    openD365BatchForm (el) {
      const isBatchSupported = this.$store.getters.getParamFromConfigByFilter('d365_products', 'id', el.product.d365_product_id, 'hasBatchNumbers');
      this.$refs.D365BatchForm.show(el.id, el.countrycode, isBatchSupported);
    },
    displayCreateD365Batch (el) {
      return !el.batch_id && this.canEditD365Batch;
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.getTableData();
    this.isMounted = true;
  },
  watch: {
    basePathComp: {
      immediate: true,
      handler (val) {
        if (val) {
          this.isMounted = false;
          this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
          this.getTableData();
          this.isMounted = true;
        }
      }
    }
  }
}
