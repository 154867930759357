import RecipeEdit from '@/components/pet/shift/recipeedit/RecipeEdit.vue'
import ShiftRecipeView from '@/components/pet/shift/recipeview/ShiftRecipeView.vue';

const defaultFilterValues = {
  datefrom: null,
  dateto: null,
  type_id: null,
  line_id: null
};

export default {
  name: 'ShiftList',
  components: {
    ShiftRecipeView,
    RecipeEdit
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { item: '', direction: '' },
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false
    };
  },
  computed: {
    basePathComp () {
      return 'recipe'
    },
    basePerm () {
      return 'shift-recipe';
    },
    baseTransParam () {
      return 'recipe';
    },
    tableHeaders () {
      const headers = [
        { key: 'startdate', label: this.$t('shift.startdate'), sortable: false, type: 'date' },
        {
          key: 'type_id',
          label: this.$t('shift.type_id'),
          sortable: false,
          format: 'formatFromConfig',
          configKey: 'shift_types',
          configParam: 'name'
        },
        {
          key: 'line_id',
          label: this.$t('shift.line_id'),
          sortable: false,
          format: 'formatFromConfig',
          configKey: 'lines',
          configParam: 'name'
        },
        {
          key: 'flow_id',
          label: this.$t('flow.name'),
          sortable: false,
          format: 'formatFromConfig',
          configKey: 'line_flows',
          configParam: 'name'
        },
        {
          key: 'items_sum_quantity',
          label: this.$t('shift.total-quantity'),
          sortable: false,
          format: 'formatDecimal',
          precision: 0
        },
        { label: this.$t('shift.product-types'), type: 'slot', slotcode: 'productTypes', sortable: false },
        {
          key: 'countShiftFlowProductsProduced',
          label: this.$t('menu.shift.shiftFlowProduced'),
          sortable: false,
          format: 'formatDecimal',
          precision: 0
        }
      ];
      const actions = [];
      actions.push({ class: 'btn-info', emit: 'downloadRecipe', bicon: 'filetype-pdf', label: '' });
      if (this.canEdit) {
        actions.push({
          label: this.$t('general.actions.edit'),
          emit: 'editItem',
          bicon: 'pencil-fill',
          class: 'btn-sm btn-primary'
        });
      }
      if (this.canDelete) {
        actions.push({
          label: this.$t('general.actions.delete'),
          emit: 'deleteItem',
          bicon: 'trash',
          ifconfig: { param: 'deleted_at', value: null }
        });
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    shiftCompletionItems () {
      return this.$store.getters.shiftCompletionItems;
    },
    tableExpandConfig () {
      return { component: 'slot' };
    },
    tableFilterConfig () {
      const filters = {};
      filters.datefrom = { type: 'date', label: this.$t('general.filters.datefrom') };
      filters.dateto = { type: 'date', label: this.$t('general.filters.dateto') };
      filters.type = {
        type: 'checkbox',
        label: this.$t('shift.type_id'),
        options: this.shiftTypeOptions,
        colOnlyFilter: false
      };
      filters.line = {
        type: 'checkbox',
        label: this.$t('shift.line_id'),
        options: this.lineOptions,
        colOnlyFilter: false,
        class: 'col-12'
      };
      return filters;
    },
    canDelete () {
      return this.ApiRequest.userHasPermission('delete-' + this.basePerm);
    },
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-' + this.basePerm);
    },
    requestPath () {
      let path = this.ApiRequest.addGetParamsToPath(this.basePathComp + '/list?perPage=' + this.paging.limit + '&page=' + this.paging.page, this.filterValues, true);
      if (this.tableSorting.item) {
        path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
      }
      return path;
    },
    tableActions () {
      const actions = { download: false };
      return actions;
    },
    lineOptions () {
      return this.$store.getters.getConfigOptions('lines', this.$i18n.locale);
    },
    shiftTypeOptions () {
      return this.$store.getters.getConfigOptions('shift_types', this.$i18n.locale);
    }
  },
  methods: {
    getTableData () {
      if (this.basePathComp) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        this.loading = true;
        this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.tableItems = response.data;
            this.paging = response.paging;
            this.loading = false;
          }
        });
      }
    },
    downloadRecipe (el) {
      el.loading = true;
      this.$refs.tableview.$forceUpdate();
      const path = `recipe/generate-recipe-pdf/${el.id}`;
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_DOWNLOAD, {}, () => {
        el.loading = false;
        this.$refs.tableview.$forceUpdate();
      });
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    editItem (el) {
      this.$refs.editElFrm.show(null, el.id);
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    },
    deleteItem (el, approved) {
      if (approved) {
        this.setDeleted(el, 1);
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t(this.baseTransParam + '.actions.deleteTitle'), this.$t(this.baseTransParam + '.actions.deleteMessage', {
          name: this.BasicHelper.getTranslation(el.name),
          code: el.code
        }))
      }
    },
    restoreItem (el) {
      this.setDeleted(el, 0);
    },
    setDeleted (el, isDeleted) {
      this.loading = true;
      this.ApiRequest.request(this.basePathComp + '/deleted', this.ApiRequest.REQUEST_POST, {
        id: el.id,
        code: el.code,
        deleted: isDeleted
      }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        } else {
          this.getTableData();
        }
      });
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.getTableData();
    this.isMounted = true;
  },
  watch: {
    basePathComp: {
      immediate: true,
      handler (val) {
        if (val) {
          this.isMounted = false;
          this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
          this.getTableData();
          this.isMounted = true;
        }
      }
    }
  }
}
