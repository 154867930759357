import ReportIncomingProductCargoLines from '@/components/pet/product/report/ReportIncomingProductCargoLines.vue'

const currentDate = new Date();
const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1).toISOString();
const lastDayOfYear = new Date(currentDate.getFullYear(), 11, 31).toISOString();

const defaultFilterValues = {
  vendor: '',
  ax2009code: '',
  d365code: '',
  vendorOrgNumber: '',
  currency: '',
  productSum: '',
  wasteClass: '',
  startQuantity: '',
  measure_type: '',
  countrycode: '',
  product: '',
  date: {
    start: firstDayOfYear,
    end: lastDayOfYear
  }
};
export default {
  name: 'ReportImportedProducts',
  components: {
    ReportIncomingProductCargoLines
  },
  props: {
  },
  data () {
    return {
      loading: false,
      tableItems: [],
      paging: { limit: 50, page: 1, total: 0, pages: 0 },
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues)
    }
  },
  computed: {
    basePathComp () {
      return 'factory-products/report-imported';
    },
    baseTransParam () {
      return 'factory-product.reportImported.';
    },
    tableHeaders () {
      return [
        { key: 'vendor_name', label: this.$t(this.baseTransParam + 'vendor.name'), sortable: true, localFilter: true },
        { key: 'vendor_ax2009code', label: this.$t(this.baseTransParam + 'vendor.ax2009'), sortable: true, localFilter: true },
        { key: 'vendor_d365code', label: this.$t(this.baseTransParam + 'vendor.D365code'), sortable: true, localFilter: true },
        { key: 'vendor_orgNumberOrPersonCode', label: this.$t(this.baseTransParam + 'vendor.regNumber'), sortable: true, localFilter: true },
        { key: 'product_id', label: this.$t(this.baseTransParam + 'product'), format: 'formatFromConfig', configKey: 'products', configParam: 'name', sortable: true, localFilter: true },
        { key: 'quantity_sum', label: this.$t(this.baseTransParam + 'quantity'), sortable: true, format: 'formatDecimal', precision: 3, localFilter: true },
        { key: 'measure_type_id', label: this.$t(this.baseTransParam + 'measureType'), format: 'formatFromConfig', configKey: 'measure_types', configParam: 'name', sortable: true, localFilter: true },
        { key: 'countrycode', label: this.$t(this.baseTransParam + 'country'), sortable: true, localFilter: true },
        { key: 'apus_waste_class_id', label: this.$t(this.baseTransParam + 'wasteClass'), format: 'formatFromConfig', configKey: 'apus_waste_classes', configParam: 'name', sortable: true, localFilter: true },
        // { key: 'product_sum', label: this.$t(this.baseTransParam + 'sum'), format: 'formatDecimal', sortable: true, precision: 2, localFilter: true },
        { key: 'currency_id', label: this.$t(this.baseTransParam + 'currency'), format: 'formatFromConfig', configKey: 'currencies', configParam: 'name', sortable: true, localFilter: true }
      ];
    },
    tableFilters () {
      const filters = { };
      filters.date = { type: 'date-range', label: this.$t('general.filters.date'), colOnlyFilter: false, class: 'mx-1 col-md-2', controlClass: 'form-control-sm' };
      return filters;
    },
    tableExpandConfig () {
      return { component: 'slot' }
    },
    tableActions () {
      return { download: true, reload: true, filters: true };
    }
  },
  methods: {
    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
      this.loading = true;
      const path = this.ApiRequest.addGetParamsToPath(this.basePathComp, this.filterValues);
      this.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, [], (response) => {
        if (response.data) {
          this.tableItems = response.data;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    download (html, exportCols) {
      const path = this.ApiRequest.addGetParamsToPath(this.basePathComp, this.filterValues);
      this.$refs.tableview.downloadTableData(path, exportCols);
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.getTableData();
  }
}
