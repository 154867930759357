import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

const defaultEl = {
  id: null,
  rate: '',
  product_id: null,
  startAt: null,
  endAt: null,
  vendor_id: null,
  composer_id: null,
  measure_type_id: null,
  isSale: false,
  contractNo: null
};

export default {
  name: 'RateEdit',
  data () {
    return {
      form: defaultEl,
      loading: false
    }
  },
  validations: {
    form: {
      rate: { required },
      startAt: { required },
      measure_type_id: { required }
    }
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getTitle () {
      if (this.form.id) {
        return this.$t(this.baseTransParam + '.actions.edit');
      }
      return this.$t(this.baseTransParam + '.actions.new');
    },
    baseTransParam () {
      return 'product.rates';
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission('edit-rates');
    },
    measureTypeOptions () {
      return this.$store.getters.getConfigOptions('measure_types', this.$i18n.locale);
    },
    product () {
      return this.$store.getters.getDataFromConfigById('products', this.form.product_id)
    },
    measureTypeName () {
      let measureTypeName = '-';
      if (this.product && this.product.id) {
        const measureType = this.$store.getters.getParamFromConfig('products', this.product.id, 'default_measure_type_id', this.$i18n.locale);
        measureTypeName = this.BasicHelper.getConfigValue('measure_types', 'id', measureType, 'name');
      }
      return measureTypeName;
    }
  },
  methods: {
    show (elData, productId, vendorId, customerId) {
      if (elData) {
        this.form = this.BasicHelper.cloneObject(elData);
      } else {
        this.form = this.BasicHelper.cloneObject(defaultEl);
        this.form.product_id = productId;
        this.form.vendor_id = vendorId || null;
        this.form.customer_id = customerId || null;
        if (this.form.customer_id) {
          this.form.isSale = true;
        }
        this.form.measure_type_id = (this.product ? this.product.default_measure_type_id : {});
      }
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = this.BasicHelper.cloneObject(this.form);
        this.ApiRequest.request('rates/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
