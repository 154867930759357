var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"order-line-list"},[_c('Tableview',{ref:"tableview",attrs:{"fields":_vm.tableHeaders,"loading":_vm.loading,"items":_vm.transportCosts,"tableClass":"table-striped table-sm table-hover"},on:{"saved":_vm.getTableData,"deleteItem":_vm.deleteLine},scopedSlots:_vm._u([{key:"chargeCodeType",fn:function(ref){
var data = ref.data;
return [(_vm.canEdit)?_c('AdvancedSelect',{attrs:{"items":_vm.chargeCodeOptionsFiltered(_vm.transportCosts[data.index].chargeCode_id),"hasError":(_vm.v$.transportCosts.$each != undefined && _vm.v$.transportCosts.$each.$response.$data[data.index].chargeCode_id.$error ),"disabled":!_vm.canEditTransportCost,"size":_vm.size,"controlClass":("form-control-" + _vm.size),"searchable":true},model:{value:(_vm.transportCosts[data.index].chargeCode_id),callback:function ($$v) {_vm.$set(_vm.transportCosts[data.index], "chargeCode_id", $$v)},expression:"transportCosts[data.index].chargeCode_id"}}):[_vm._v(" "+_vm._s(_vm.BasicHelper.getConfigValue('order_cost_types', 'id', data.item.chargeCode_id, 'name'))+" ")]]}},{key:"plannedCost",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"position-relative"},[(_vm.canEdit)?_c('NumberInput',{attrs:{"dbparam":("transportCosts." + (data.index) + ".plannedCost"),"precision":2,"maxlength":10,"disabled":!_vm.canEditTransportCost,"controlClass":("form-control-" + _vm.size)},model:{value:(_vm.transportCosts[data.index].plannedCost),callback:function ($$v) {_vm.$set(_vm.transportCosts[data.index], "plannedCost", $$v)},expression:"transportCosts[data.index].plannedCost"}}):[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.item.plannedCost, 2, _vm.$i18n.locale))+" ")],(_vm.getPlannedCostNotMatching(data.item))?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"text-danger position-absolute end-0 top-0",attrs:{"title":_vm.$t('order.d365PlannedCost', { d365PlannedCost: _vm.BasicHelper.formatDecimal(data.item.d365PlannedCost, 2, _vm.$i18n.locale) })}},[_c('i',{staticClass:"bi bi-exclamation-diamond"})]):_vm._e()],2)]}},{key:"actualCost",fn:function(ref){
var data = ref.data;
return [(_vm.canEdit)?_c('NumberInput',{attrs:{"dbparam":("transportCosts." + (data.index) + ".actualCost"),"precision":2,"maxlength":10,"disabled":true,"controlClass":("form-control-" + _vm.size)},model:{value:(_vm.transportCosts[data.index].actualCost),callback:function ($$v) {_vm.$set(_vm.transportCosts[data.index], "actualCost", $$v)},expression:"transportCosts[data.index].actualCost"}}):[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.item.actualCost, 2, _vm.$i18n.locale))+" "+_vm._s(_vm.costFromBills(data.index, data.item.actualCost))+" ")]]}},{key:"totalActualCost",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.item.totalActualCost, 2, _vm.$i18n.locale))+" "+_vm._s(_vm.costFromBills(data.index, data.item.actualCost, data.item.additionalCost))+" ")]}},{key:"transportprovider",fn:function(ref){
var data = ref.data;
return [(_vm.canEdit)?[(_vm.transportCosts[data.index].chargeCode_id == _vm.transportChargeId)?[_vm._v(" "+_vm._s(_vm.providerTransport ? _vm.providerTransport.name: '')+" ")]:_c('PartnerPicker',{attrs:{"disabled":!_vm.canEditTransportCost,"filterValues":_vm.transportProviderFilter,"basePath":"transport-providers","hasApproval":false,"size":"sm"},on:{"input":function($event){return _vm.transportProviderChanged($event, data.index)}},model:{value:(_vm.transportCosts[data.index].transport_provider),callback:function ($$v) {_vm.$set(_vm.transportCosts[data.index], "transport_provider", $$v)},expression:"transportCosts[data.index].transport_provider"}})]:[_c('div',{class:{ 'text-decoration-line-through': _vm.transportCosts[data.index].differentActualProvider }},[_vm._v(" "+_vm._s(_vm.transportCosts[data.index].transport_provider ? _vm.transportCosts[data.index].transport_provider.name : '')+" ")]),(_vm.transportCosts[data.index].differentActualProvider)?_c('div',_vm._l((_vm.transportCosts[data.index].actualproviders),function(provider){return _c('div',{key:provider.id},[_c('span',{staticClass:"badge bg-warning text-white"},[_vm._v(" "+_vm._s(_vm.getProviderName(provider))+" ")])])}),0):_vm._e()]]}},{key:"D365Cost",fn:function(ref){
var data = ref.data;
return [(data.item.D365CostNumber)?_c('span',{staticClass:"fw-bold text-success"},[_vm._v(_vm._s(_vm.$t('general.yes')))]):_c('span',{staticClass:"fw-bold text-danger"},[_vm._v(_vm._s(_vm.$t('general.no')))])]}},{key:"billdata",fn:function(ref){
var data = ref.data;
return [(_vm.canEditBillnumbers)?[_c('AdvancedSelect',{attrs:{"items":data.item.billdataoptions,"name":"billnumbers","value-field":"text","multiple":"","searchable":"","multipleAddNewOption":"","close-on-select":true,"size":_vm.size},model:{value:(_vm.transportCosts[data.index].billdata),callback:function ($$v) {_vm.$set(_vm.transportCosts[data.index], "billdata", $$v)},expression:"transportCosts[data.index].billdata"}})]:(data.item.billdata)?[_vm._v(" "+_vm._s(data.billdata)+" "),_vm._l((data.item.billdata),function(bill,key){return _c('div',{key:key},[(bill.id && bill.code)?_c('LinkToDifferentSystem',{attrs:{"host":(_vm.$store.state.config ? _vm.$store.state.config.d365supportfronturl : ''),"nextRouteName":"menu.billing.record.view","routeParams":{ code: bill.code },"title":"general.actions.edit","newtab":true},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(bill.billNumber)+" ")]},proxy:true}],null,true)}):_c('span',[_vm._v(" "+_vm._s(bill)+" ")])],1)})]:_vm._e()]}},{key:"comment",fn:function(ref){
var data = ref.data;
return [(data.item.billdata)?_vm._l((data.item.billdata),function(bill,index){return _c('span',{key:index},[(bill.id && bill.comment)?[_vm._v(" "+_vm._s(bill.comment)+" "),(index !== data.item.billdata.length - 1)?[_c('br')]:_vm._e()]:_vm._e()],2)}):_vm._e()]}},{key:"generatedCost",fn:function(ref){
var data = ref.data;
return [(_vm.canEdit)?_c('NumberInput',{attrs:{"dbparam":("transportCosts." + (data.index) + ".generatedCost"),"disabled":true,"precision":2,"maxlength":10,"controlClass":("form-control-" + _vm.size)},model:{value:(_vm.transportCosts[data.index].generatedCost),callback:function ($$v) {_vm.$set(_vm.transportCosts[data.index], "generatedCost", $$v)},expression:"transportCosts[data.index].generatedCost"}}):[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.item.generatedCost, 2, _vm.$i18n.locale))+" ")]]}}])}),(_vm.v$.$error && _vm.v$.transportCosts.checkDuplicateCostType.$invalid)?_c('div',{staticClass:"text-danger fs-2"},[_vm._v(" "+_vm._s(_vm.$t('order.transportCostDuplicateError'))+" ")]):_vm._e(),(_vm.canEdit && _vm.canEditTransportCost)?_c('div',[_c('span',{class:("btn btn-primary btn-" + _vm.size),on:{"click":_vm.addLine}},[_c('i',{staticClass:"bi bi-plus"})])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }