import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

const defaultEl = {
  id: null,
  code: '',
  shortCode: '',
  name: {},
  color: '#000',
  default_quality_id: null,
  default_measure_type_id: null,
  apus_waste_class_id: null,
  availableMeasureTypes: [],
  ax2009code: '',
  order: '',
  availableApusWasteClasses: [],
  availableImpurities: [],
  d365_product_id: null,
  customsCode: ''
};

export default {
  name: 'ProductEdit',
  data () {
    return {
      form: defaultEl,
      loading: false
    }
  },
  validations: {
    form: {
      code: { required },
      shortCode: { },
      name: { required },
      type_id: { required },
      default_quality_id: { required },
      default_measure_type_id: { required },
      apus_waste_class_id: { required },
      availableApusWasteClasses: { required },
      d365_product_id: { },
      customsCode: { }
    }
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    baseTransParam () {
      return 'product';
    },
    getTitle () {
      if (this.form.id) {
        return this.$t(this.baseTransParam + '.actions.edit');
      }
      return this.$t(this.baseTransParam + '.actions.new');
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission('edit-products');
    },
    d365Options () {
      const options = this.$store.getters.getConfigOptions('d365_products', this.$i18n.locale);
      options.forEach(el => {
        el.text = el.text + ' (' + el.code + ')';
      })
      return options;
    },
    apusWasteOptions () {
      return this.$store.getters.getConfigOptions('apus_waste_classes', this.$i18n.locale);
    },
    apusWasteOptionsForPrimarySelect () {
      return this.$store.getters.getConfigOptions('apus_waste_classes', this.$i18n.locale).filter(el => this.form.availableApusWasteClasses.includes(el.value));
    },
    measureTypeOptions () {
      return this.$store.getters.getConfigOptions('measure_types', this.$i18n.locale);
    },
    qualityOptions () {
      return this.$store.getters.getConfigOptions('product_qualities', this.$i18n.locale, null, null, null, false, false, [], true);
    },
    productLabelOptions () {
      return this.$store.getters.getConfigOptions('product_labels', this.$i18n.locale, null, null, null, false, false, [], true);
    },
    productOptions () {
      return this.PetHelper.getFeadableBaleProducts(true, false, false);
    },
    typeOptions () {
      return this.$store.getters.getConfigOptions('product_types', this.$i18n.locale);
    },
    variantOptions () {
      return this.$store.getters.getConfigOptions('product_variants', this.$i18n.locale, null, null, null, false, false, [], true);
    }
  },
  methods: {
    onD365ProductChanged () {
      if (this.form.d365_product_id != null) {
        const customsCode = this.$store.getters.getParamFromConfig('d365_products', this.form.d365_product_id, 'customsCode');
        this.form.customsCode = customsCode;
        return;
      }
      this.form.customsCode = null;
    },
    show (elData) {
      if (elData) {
        this.form = this.BasicHelper.cloneObject(elData);
      } else {
        this.form = this.BasicHelper.cloneObject(defaultEl);
      }
      this.onD365ProductChanged();
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = this.BasicHelper.cloneObject(this.form);
        this.ApiRequest.request('products/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$store.dispatch('reloadConfig');
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
