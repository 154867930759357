import useVuelidate from '@vuelidate/core';
const defaultEl = {
  factory_product_id: null,
  set_quality_id: null,
  switch_to_manual: false
};
export default {
  name: 'QualityTestEdit',
  data () {
    return {
      editEl: this.BasicHelper.cloneObject(defaultEl),
      qualityBaseConfig: {},
      tabOptions: [],
      productDataSet: [],
      loading: true,
      loadComponent: false,
      productTests: [],
      summaryTest: {},
      testerViewName: null,
      summableParametersData: {}
    }
  },
  validations: {
  },
  components: {
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    readOnlyView () {
      return this.$route.name.includes('productQualityTestOverViewReadOnly')
    },
    productOption () {
      return this.$store.getters.getConfigOptions('products', this.$i18n.locale, 'id', this.productDataSet.product_id);
    },
    getQualityCode () {
      if (this.summaryTest != null && this.summaryTest.summary_quality_code != undefined) {
        const firstTestCode = this.qualityCodeOptions.find(el => el.value == this.summaryTest.summary_quality_code);
        if (firstTestCode != undefined) {
          return firstTestCode.text;
        }
      }
      return false;
    },
    qualityCodeOptions () {
      return this.$store.getters.getConfigOptions('product_qualities_code', this.$i18n.locale);
    },
    lineOption () {
      if (this.productDataSet.shift == null) return [];
      return this.$store.getters.getConfigOptions('lines', this.$i18n.locale, 'id', this.productDataSet.shift.line_id);
    },
    qualityMeasurementOptions () {
      return this.$store.getters.getConfigOptions('quality_measurement_options', this.$i18n.locale);
    },
    categoryOptionsIsOptions () {
      return this.$store.getters.getConfigOptions('quality_measurement_categories', this.$i18n.locale, 'isOption', 1);
    },
    qualityCategoryOptions () {
      return this.$store.getters.getConfigOptions('quality_measurement_categories', this.$i18n.locale);
    },
    qualityOptions () {
      let qualities = [];
      if (this.qualityBaseConfig.qualityIds.length > 0) {
        qualities = this.$store.getters.getConfigOptions('product_qualities', this.$i18n.locale, 'id', this.qualityBaseConfig.qualityIds, false, false, true);
      } else {
        qualities = this.$store.getters.getConfigOptions('product_qualities', this.$i18n.locale);
      }
      return qualities;
    },
    measureTypeOptions () {
      return this.$store.getters.getConfigOptions('measure_types', this.$i18n.locale);
    },
    baseTransParam () {
      return 'menu.quality.productQualityTest';
    },
    getTitle () {
      return this.$t(this.baseTransParam + '.actions.edit');
    },
    testerProfileId () {
      return this.$route.fullPath.split('/')[(this.$route.fullPath.split('/')).length - 3]
    },
    availableTesterCategory () {
      const availableTesterCategory = JSON.parse(localStorage.getItem('availableTesterCategories'))
      return Array.isArray(availableTesterCategory) && availableTesterCategory.length > 0 ? availableTesterCategory : false
    },
    testerProfileCategory () {
      if (this.availableTesterCategory) {
        const testerProfileCategory = this.availableTesterCategory.find(el => el.id == this.testerProfileId)
        if (testerProfileCategory != undefined) {
          return testerProfileCategory
        }
      }
      return false
    },
    canEdit () {
      if (this.productDataSet.shift == null && !this.readOnlyView) return true
      return this.productDataSet.shift.qualityCompletedAt == null && !this.loading &&
          (this.ApiRequest.userHasPermission('edit-qualities-code') || (this.availableTesterCategory));
    },
    lineOptions () {
      return this.$store.getters.getConfigOptions('lines', this.$i18n.locale);
    },
    categoryWithMixedMeasureType () {
      return this.$store.getters.getConfigOptions('quality_measurement_categories', this.$i18n.locale, 'hasMixedMeasureTypes', 1);
    }
  },
  methods: {
    getTestMeasurementValue (measurementId, categoryId) {
      const findValue = this.productTests.avgTest.quality_test_measurements.find(el => el.quality_measurement.category_id == categoryId &&
          el.quality_measurement_id == measurementId)
      if (findValue !== undefined && !isNaN(findValue.value) && findValue.value !== null) {
        return findValue.value
      } else {
        return '-'
      }
    },
    getTestMeasurementName (measurement) {
      let text = measurement.name[this.$i18n.locale]
      if (this.categoryWithMixedMeasureType != null && this.categoryWithMixedMeasureType != undefined &&
          this.categoryWithMixedMeasureType.find(el2 => el2.value == measurement.category_id) != undefined) {
        const findName = this.BasicHelper.getConfigValue('measure_types', 'id', measurement.measurement_id, 'name');
        if (findName != null && findName != undefined && findName != '') {
          text = text + ', ' + findName
        }
      }
      return text
    },
    measureTypeOptionsName (groupedMeasurement) {
      const additionalDataMeasurement = groupedMeasurement.find(el => el.isOption == 0).additionalData;
      if (additionalDataMeasurement != undefined && 'calcInputMeasurementType' in additionalDataMeasurement) {
        const findAdditionalDataMeasurement = this.measureTypeOptions.find(el => el.value == additionalDataMeasurement.calcInputMeasurementType);
        if (findAdditionalDataMeasurement != undefined) {
          if (groupedMeasurement.length > 0 && this.categoryWithMixedMeasureType != null && this.categoryWithMixedMeasureType != undefined &&
              this.categoryWithMixedMeasureType.find(el2 => el2.value == groupedMeasurement[0].category_id) != undefined) {
            return '-';
          }
          return findAdditionalDataMeasurement.text;
        }
      }
      if (groupedMeasurement.length > 0 && this.categoryWithMixedMeasureType != null && this.categoryWithMixedMeasureType != undefined &&
          this.categoryWithMixedMeasureType.find(el2 => el2.value == groupedMeasurement[0].category_id) != undefined) {
        return '-';
      }
      return this.measureTypeOptions.find(el => el.value == groupedMeasurement.find(el => el.isOption == 0).measurement_id).text
    },
    setComponentData (id) {
      if (id && id !== 'NEW') {
        this.loadComponent = true;
        this.loading = true;
        this.ApiRequest.request('quality/product-quality-tests-overview/view/' + id, this.ApiRequest.REQUEST_GET, { testerViewName: this.testerViewName }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else if (response.data) {
            if (response.data.product_tests.length > 0 && response.data.product_tests[0].quality_measurement_base_config != null) {
              this.qualityBaseConfig = response.data.product_tests[0].quality_measurement_base_config;
            } else {
              if (response.data.product.quality_measurement_base_config_by_type_and_product != null) {
                this.qualityBaseConfig = response.data.product.quality_measurement_base_config_by_type_and_product;
              } else {
                this.qualityBaseConfig = response.data.product.quality_measurement_base_config_by_type;
              }
            }
            this.productDataSet = response.data;
            this.editEl.factory_product_id = response.data.id;
            this.productTests = response.data.product_tests;
            this.summaryTest = response.data.summary_test;
            this.summableParametersData = response.data.summableParametersData;
            this.editEl.set_quality_id = this.summaryTest.set_quality_id;
            if (this.editEl.set_quality_id > 0) {
              this.editEl.switch_to_manual = true;
            }
            const avgTest = this.BasicHelper.cloneObject(this.productTests[0]);
            const vm = this;
            this.productTests[0].quality_test_measurements.forEach(function callback (value, index) {
              let sumValue = 0;
              let countEl = 0;
              const allOptions = [];
              vm.productTests.forEach(function callback (value2, index2) {
                if (value.quality_measurement.isOption == 0) {
                  sumValue += value2.quality_test_measurements[index].calcInputValue != null && parseFloat(value2.quality_test_measurements[index].calcInputValue) > 0
                    ? parseFloat(value2.quality_test_measurements[index].calcInputValue)
                    : parseFloat(value2.quality_test_measurements[index].value)
                  countEl++
                } else {
                  value2.quality_test_measurements[index].availableQualityMeasurementOptions.forEach(function callback (value3, index3) {
                    if (vm.qualityMeasurementOptions.find(el => el.value == value3) != undefined &&
                      !allOptions.includes(vm.qualityMeasurementOptions.find(el => el.value == value3).text)) {
                      allOptions.push(vm.qualityMeasurementOptions.find(el => el.value == value3).text);
                    }
                  })
                }
              })
              avgTest.quality_test_measurements[index].minMaxMatch = true;
              let minCheck = true;
              let maxCheck = true;
              if (value.quality_measurement.isOption == 1) {
                avgTest.quality_test_measurements[index].availableQualityMeasurementOptions = allOptions;
              } else {
                avgTest.quality_test_measurements[index].value = sumValue / countEl;
                if ('precision' in avgTest.quality_test_measurements[index].quality_measurement.additionalData) {
                  avgTest.quality_test_measurements[index].value = parseFloat(avgTest.quality_test_measurements[index].value.toFixed(avgTest.quality_test_measurements[index].quality_measurement.additionalData.precision))
                }
                if (avgTest.quality_test_measurements[index].calcInputValue != null && parseFloat(avgTest.quality_test_measurements[index].calcInputValue) > 0) {
                  minCheck = !('warningMinRangeValue' in avgTest.quality_test_measurements[index].quality_measurement.additionalData &&
                      avgTest.quality_test_measurements[index].value < avgTest.quality_test_measurements[index].quality_measurement.additionalData.warningMinRangeValue);
                  maxCheck = !('warningMaxRangeValue' in avgTest.quality_test_measurements[index].quality_measurement.additionalData &&
                      avgTest.quality_test_measurements[index].value > avgTest.quality_test_measurements[index].quality_measurement.additionalData.warningMaxRangeValue);
                }
                if (minCheck) {
                  minCheck = !('minRangeValue' in avgTest.quality_test_measurements[index].quality_measurement.additionalData &&
                      avgTest.quality_test_measurements[index].value < avgTest.quality_test_measurements[index].quality_measurement.additionalData.minRangeValue);
                }
                if (maxCheck) {
                  maxCheck = !('maxRangeValue' in avgTest.quality_test_measurements[index].quality_measurement.additionalData &&
                      avgTest.quality_test_measurements[index].value > avgTest.quality_test_measurements[index].quality_measurement.additionalData.maxRangeValue);
                }
                avgTest.quality_test_measurements[index].minMaxMatch = minCheck && maxCheck;
              }
              vm.$store.getters.inheritMeasurementValueFromShift(
                value,
                vm.productDataSet.shiftQualityMeasurement
              );
            });
            this.productTests.avgTest = avgTest;
          } else {
            this.editEl = null;
          }
          this.loadComponent = false;
          this.v$.$reset();
        });
        this.loading = false;
      }
    },
    show (elData) {
      this.loading = true;
      if (elData) {
        this.setComponentData(elData.id);
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
      }
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    backToTestList () {
      this.$router.push({ name: this.testerViewName, params: {} });
    },
    addTest () {
      if (this.editEl.productTests.length > 3) return;
      const lastEl = this.BasicHelper.cloneObject(this.editEl.productTests[this.editEl.productTests.length - 1]);
      lastEl.id = null;
      lastEl.quality_test_measurements.forEach(function callback (value, index) {
        lastEl.quality_test_measurements[index].id = null;
      });
      this.tabOptions.push({ code: this.editEl.productTests.length, text: this.$t('menu.quality.productQualityTest.test') + '_' + (this.editEl.productTests.length + 1) });
      this.editEl.productTests.push(lastEl);
    },
    editQualityTest () {
      if (this.readOnlyView) {
        this.$router.push({ name: 'menu.quality.productQualityTestEditReadOnly', params: { id: this.editEl.factory_product_id, testerViewName: this.testerProfileCategory?.name, availableTesterCategory: this.availableTesterCategory, testerCategoryId: this.testerProfileCategory?.id } });
      } else {
        this.$router.push({ name: 'menu.quality.productQualityTestEdit' + this.testerProfileCategory?.id.toString(), params: { id: this.editEl.factory_product_id, testerViewName: this.testerProfileCategory?.name, availableTesterCategory: this.availableTesterCategory, testerCategoryId: this.testerProfileCategory?.id } });
      }
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        this.ApiRequest.request('quality/product-quality-tests-overview/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$store.dispatch('reloadConfig');
            this.$emit('saved', response.data);
            this.close();
            if (this.testerViewName != null) {
              this.$router.push({ name: this.testerViewName, params: {} }).catch(() => { });
            } else {
              this.$router.push({ name: 'menu.quality.productQualityTestList', params: {} }).catch(() => { });
            }
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  mounted () {
    this.isMounted = true;
    if (this.$route.params.testerViewName != null && this.$route.params.testerViewName != undefined) {
      this.testerViewName = this.$route.params.testerViewName
    }
    this.setComponentData(this.$route.params.id);
  },
  watch: {
    '$route' () {
      this.setComponentData(this.$route.params.id);
    }
  }
}
