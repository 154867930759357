import { render, staticRenderFns } from "./MoveSKU.vue?vue&type=template&id=652282ce"
import script from "./MoveSKU.js?vue&type=script&lang=js&external"
export * from "./MoveSKU.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports