<template>
  <div class="shift-recipe">
    <div v-if="currentShift && !currentShift.canFeed" class="text-danger fs-3">
      <div v-if="currentShift.closedAt">
        {{ $t('shift.is-closed') }}
      </div>
      <div v-else-if="currentShift.warehouse_id && !currentShift.warehouseApprovedAt">
        {{ $t('shift.warehouse-not-approved') }}
      </div>
      <div v-else>
        {{ $t('shift.some-invalid-approvals') }}
      </div>
    </div>
    <ShiftRecipeView v-if="currentShift" :shiftId="(currentShift ? currentShift.id : null)" :flowId="currentFlowId" :shift="currentShift"/>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ShiftRecipeView from '@/components/pet/shift/recipeview/ShiftRecipeView.vue'

export default {
  name: 'ShiftRecipe',
  data () {
    return {
    }
  },
  components: {
    ShiftRecipeView
  },
  computed: {
    ...mapState({
      currentShift: state => state.pet.currentShift,
      currentFlowId: state => state.pet.currentFlowId
    }),
    shiftId () {
      return this.currentShift?.id;
    }
  },
  watch: {
    currentShift: {
      immediate: true,
      handler () {
      }
    },
    currentFlowId: {
      immediate: true,
      handler () {
      }
    }
  }
}
</script>
