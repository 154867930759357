import MqttDevice from 'ebg-vue-common/src/components/common/mqttdevice.vue'
import QRScanner from '@/components/pet/factoryproduct/picker/scanner/QRScanner.vue';
import SpecifyQuantity from '@/components/pet/factoryproduct/picker/SpecifyQuantity.vue';

export default {
  name: 'FactoryProductPicker',
  props: {
    value: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    specificQuantity: {
      type: Boolean,
      default: false
    },
    poproducts: {
      type: Array,
      default: () => null
    },
    findBySpecificCode: {
      type: Boolean,
      default: true
    },
    filterValues: {
      type: Object,
      default: () => {}
    },
    controlClass: {
      type: String,
      default: 'form-control'
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    deviceIds: {
      type: Array,
      default: () => []
    },
    withQuality: {
      type: Boolean,
      default: false
    },
    skipClickOutSide: {
      type: Boolean,
      default: false
    },
    qrScannerAbsolute: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      qrScannerOpened: false,
      content: this.value,
      itemOptions: null,
      searchTxt: '',
      currentReq: null,
      qrCameraStatus: 'btn-secondary',
      isEmpty: true,
      searchStartTime: null,
      specificQuantityValue: null
    };
  },
  components: {
    QRScanner,
    MqttDevice,
    SpecifyQuantity
  },
  computed: {
    hasAnyResults () {
      return this.itemOptions && this.searchTxt && this.searchTxt.length > 0 && this.itemOptions.length === 0;
    },
    qrDeviceOptions () {
      return this.$store.getters.getDevicesByType('QRREADER').filter(el => {
        return this.deviceIds.find(devId => { return devId == el.id });
      });
    },
    qrDevice () {
      if (this.qrDeviceOptions && this.qrDeviceOptions.length > 0) {
        return this.qrDeviceOptions[0];
      }
      return null;
    }
  },
  methods: {
    qrCameraStatusChange (status) {
      this.qrCameraStatus = status;
    },
    checksSaleInBagsCondition (item = null) {
      let saleInBags = false;
      if (item != null && this.poproducts != null) {
        const findProduct = this.poproducts.find(el => el.product_id == item.product_id)
        if (findProduct != undefined && findProduct.saleInBags) {
          saleInBags = true
        }
      }
      return saleInBags
    },
    openQRScanner () {
      this.qrScannerOpened = !this.qrScannerOpened;
      if (!this.qrScannerOpened) {
        this.qrCameraStatus = 'btn-secondary';
      }
    },
    getItemText (item) {
      let txt = '';
      if (item && item.code) {
        txt = item.code + ' (' + this.BasicHelper.getConfigValue('products', 'id', item.product_id, 'name') + ')';
        if (this.withQuality && item.quality_id) {
          txt += ' ' + this.BasicHelper.getConfigValue('product_qualities', 'id', item.quality_id, 'name');
        }
        txt += ' (' + item.remainingQuantity + ')';
      }
      return txt;
    },
    updateValue (specificQuantityModal = false) {
      if (!specificQuantityModal && this.specificQuantity && this.content && this.content.remainingBales != undefined &&
          this.content.remainingBales > 1 && this.checksSaleInBagsCondition(this.content)) {
        this.$refs.specifyQuantity.show(this.content);
      } else {
        if (this.content && this.content.id != undefined) {
          this.content.specificQuantityValue = this.specificQuantityValue
        }
        this.$emit('input', this.content);
        this.$emit('changed', this.content);
      }
    },
    searchFocus () {
      if (this.content === null) {
        this.searchItem(false)
      }
    },
    focusInput () {
      this.$refs.searchInput.focus();
    },
    searchItem (byMqtt = false, event = null) {
      this.specificQuantityValue = null
      if (this.searchTxt == '' || this.searchTxt == null) {
        this.isEmpty = true;
        this.searchStartTime = null;
      } else {
        if (this.isEmpty) {
          this.searchStartTime = (new Date()).getTime()
        }
        this.isEmpty = false;
      }
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      if (event && event.inputType == 'insertFromPaste') {
        byMqtt = true;
      }
      if (!byMqtt && this.searchStartTime) {
        const timeNow = (new Date()).getTime();
        const timeDelta = (timeNow - this.searchStartTime);
        if (timeDelta > 0 && timeDelta < 400) {
          byMqtt = true;
        }
      }
      if (this.content) {
        this.content = null;
        this.updateValue();
      }
      const search = this.searchTxt.trim();
      if (search.length > 0) {
        setTimeout(
          () => {
            if (search === this.searchTxt.trim()) {
              this.loading = true;
              const path = 'factory-products/list';
              const usedFilters = this.BasicHelper.cloneObject(this.filterValues);
              usedFilters.code = search;
              if (byMqtt && this.findBySpecificCode) {
                usedFilters.specificCode = search;
              }
              this.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, usedFilters, (response) => {
                if (response.data) {
                  this.itemOptions = response.data;
                  if (byMqtt && this.findBySpecificCode && this.itemOptions != null && this.itemOptions[0] != undefined) {
                    this.content = this.itemOptions[0]
                    this.updateValue()
                  }
                  this.loading = false;
                }
              });
            }
          }, 500);
      } else {
        this.loading = false;
        this.itemOptions = null;
      }
    },
    specifyQuantity (value) {
      this.specificQuantityValue = value
      this.updateValue(true)
    },
    selectItem (item) {
      this.specificQuantityValue = null
      this.searchTxt = this.getItemText(item);
      this.content = item;
      this.updateValue();
      this.itemOptions = null;
      this.loading = false;
    },
    setSearchtext (text) {
      this.itemOptions = null;
      this.searchTxt = text;
      this.content = null;
      this.loading = false;
      this.searchItem(true);
    },
    clearSearch () {
      this.specificQuantityValue = null
      this.itemOptions = null;
      this.searchTxt = '';
      this.content = null;
      this.loading = false;
      this.updateValue();
    },
    mqttDataRead (val) {
      if (val) {
        this.searchTxt = val;
        this.content = null;
        this.searchItem(true);
      }
      this.qrCameraStatus = 'btn-secondary';
      this.qrScannerOpened = false;
    },
    onClickOut () {
      if (!this.content && !this.skipClickOutSide) {
        this.clearSearch();
      }
    }
  },
  mounted () {
    this.searchTxt = this.getItemText(this.content);
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        if (val != this.content) {
          this.content = val;
          this.searchTxt = this.getItemText(this.content);
        }
      }
    }
  }
}
