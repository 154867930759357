import useVuelidate from '@vuelidate/core';
import QualityTestMeasurement from '@/components/pet/quality/test/edit/qualityTestMeasurement/QualityTestMeasurement.vue';

const validateTotalWeight = (value, siblings, vm) => {
  if (vm.validationEnabled == false) {
    return true
  }
  const findCategory = vm.categoriesWhichHaveTotalWeightValidation.find(el => el.value == vm.categoryId)
  if (findCategory == undefined || vm.categoryParentInherit) return true
  let findTotal = 0
  if (value[vm.productTestOptionCode] != undefined && value[vm.productTestOptionCode].quality_test_measurements !== null) {
    let findMeasurement = null
    let findMeasurementType = null
    value[vm.productTestOptionCode].quality_test_measurements.forEach(qualityTestMeasurement => {
      if ((qualityTestMeasurement.id == null && qualityTestMeasurement.category_id == vm.categoryId) ||
          (qualityTestMeasurement.id > 0 && qualityTestMeasurement.quality_measurement.category_id == vm.categoryId)) {
        findTotal += parseFloat(qualityTestMeasurement.value)
        if (findMeasurement == null && vm.qualityBaseConfig.quality_measurements != undefined) {
          findMeasurement = vm.qualityBaseConfig.quality_measurements.find(el => el.id == qualityTestMeasurement.quality_measurement_id)
          if (findMeasurement != undefined) {
            findMeasurementType = vm.measureTypeOptions.find(el => el.value == findMeasurement.measurement_id)
          }
        }
      }
    })
    if (findMeasurementType != null && findMeasurementType != undefined && vm.percentQualityFormulaMeasurementConstants.value == findMeasurementType.code && findTotal != 100) {
      return false
    }
  }
  return true;
}

export default {
  name: 'QualityTestGroup',
  data () {
    return {
      categoryParentInherit: false,
      categoryParentInheritChecked: false
    }
  },
  validations: {
    productTests: { validateTotalWeight }
  },
  components: {
    QualityTestMeasurement
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    isNewColumn: {
      type: Boolean,
      default: true
    },
    validationEnabled: {
      type: Boolean,
      default: true
    },
    testMeasurement: {
      type: Object,
      default: () => {}
    },
    qualityBaseConfig: {
      type: Object,
      default: () => {}
    },
    categoryId: {
      type: Number,
      default: null
    },
    groupedMeasurement: {
      type: Array,
      default: () => []
    },
    productDataSet: {
      type: Object,
      default: () => {}
    },
    productTests: {
      type: Array,
      default: () => []
    },
    productTestOptionCode: {
      type: Number,
      default: null
    }
  },
  computed: {
    activeGroupedMeasurement () {
      return this.groupedMeasurement.filter(measurement => {
        return ((this.productDataSet.product_tests.length > 0 || measurement.deleted == false) &&
          this.productTests[this.productTestOptionCode].quality_test_measurements.find(el => el.quality_measurement_id == measurement.id) != 'undefined');
      });
    },
    summableParameters () {
      const validMeasurementValues = [];

      const groupedMeasurements = this.activeGroupedMeasurement;
      groupedMeasurements.forEach(groupedMeasurement => {
        const measurement = this.productTests[this.productTestOptionCode].quality_test_measurements
          .find(el => el.quality_measurement_id == groupedMeasurement.id &&
          ('category_id' in el ? el.category_id == this.categoryId : el.quality_measurement.category_id == this.categoryId));

        if (measurement && !isNaN(measurement.value) && measurement.value != '' && measurement.value != null) {
          validMeasurementValues.push(parseFloat(measurement.value));
        }
      });

      const category = this.$store.getters.getDataFromConfigById('quality_measurement_categories', this.categoryId);
      const summedParams = {};

      category.summable_parameters.forEach(summableParameter => {
        const summableParamType = this.$store.getters.getDataFromConfigById('summable_parameter_types', summableParameter.type_id);

        switch (summableParamType.code) {
          case 'SUM':
            summedParams.SUM = validMeasurementValues.length > 0
              ? this.BasicHelper.formatDecimal(validMeasurementValues.reduce((a, b) => a + b, 0), summableParamType.precision, this.$i18n.locale)
              : null;
            break;
          case 'MIN':
            summedParams.MIN = validMeasurementValues.length > 0
              ? this.BasicHelper.formatDecimal(Math.min(...validMeasurementValues), summableParamType.precision, this.$i18n.locale)
              : null;
            break;
          case 'MAX':
            summedParams.MAX = validMeasurementValues.length > 0
              ? this.BasicHelper.formatDecimal(Math.max(...validMeasurementValues), summableParamType.precision, this.$i18n.locale)
              : null;
            break;
          case 'AVG':
            summedParams.AVG = validMeasurementValues.length > 0
              ? this.BasicHelper.formatDecimal(validMeasurementValues.reduce((a, b) => a + b, 0) / validMeasurementValues.length, summableParamType.precision, this.$i18n.locale)
              : null;
            break;
        }
      });

      return summedParams;
    },
    measureTypeOptions () {
      return this.$store.getters.getConfigOptions('measure_types', this.$i18n.locale);
    },
    categoriesWhichCanUseAverageDataOption () {
      return this.$store.getters.getConfigOptions('quality_measurement_categories', this.$i18n.locale,
        'canUseAverageData', 1)
    },
    categoriesWhichHaveTotalWeightValidation () {
      return this.$store.getters.getConfigOptions('quality_measurement_categories', this.$i18n.locale,
        'validateTotalWeight', 1)
    },
    percentQualityFormulaMeasurementConstants () {
      return JSON.parse(this.$store.state.config.qualityFormulaMeasurementConstants).find(el => el.name == 'PERCENT')
    },
    checkParentCategoryInherit () {
      let result = null;
      let checkSavedTestMeasurementInheritance = null;
      const listOfMeasurementIds = []
      if (this.categoriesWhichCanUseAverageDataOption.length > 0) {
        const checkCategory = this.categoriesWhichCanUseAverageDataOption.find(el => el.value == this.categoryId)
        if (checkCategory != undefined) {
          this.groupedMeasurement.forEach(measurement => {
            listOfMeasurementIds.push(measurement.id)
            if ('canEditOnFactoryProductShiftMeasurement' in measurement.additionalData && measurement.additionalData.canEditOnFactoryProductShiftMeasurement) {
              if (result == null) result = true;
            } else {
              result = false;
            }
          })
          if (result == true && this.productTests[this.productTestOptionCode] != undefined && this.productTests[this.productTestOptionCode].id !== null) {
            this.productTests[this.productTestOptionCode].quality_test_measurements.forEach(measurementTest => {
              if (listOfMeasurementIds.includes(measurementTest.quality_measurement_id)) {
                if (measurementTest.inheritShiftValue) {
                  if (checkSavedTestMeasurementInheritance == null) checkSavedTestMeasurementInheritance = true
                } else {
                  checkSavedTestMeasurementInheritance = false
                }
              }
            })
          }
        }
      }
      if (this.productTests[this.productTestOptionCode] != undefined && this.productTests[this.productTestOptionCode].id == null &&
          result && !this.categoryParentInheritChecked) {
        this.categoryParentInherit = true
      }
      if (this.productTests[this.productTestOptionCode] != undefined &&
          this.productTests[this.productTestOptionCode].id != null && result && checkSavedTestMeasurementInheritance && !this.categoryParentInheritChecked) {
        this.categoryParentInherit = true
      }
      return result;
    }
  },
  methods: {
  },
  setup () {
    return { v$: useVuelidate() }
  },
  mounted () {
    this.isMounted = true
    this.categoryParentInheritChecked = true
  },
  watch: {
    '$route' () {
    }
  }
}
