import BasicHelper from 'ebg-vue-common/src/helpers/BasicHelper';

const characterization = {
  metadata_id: null,
  class_id: null,
  class_model_id: null,
  allow_multiple: false,
  selected_classifier_id: null,
  selected_classifier_ids: [],
  text: null,
  available_classifier_ids: []
};
export default {
  name: 'Characterization',
  components: { },
  data () {
    return {
      characterizations: [],
      metadata: null,
      classifiers: null,
      loading: false
    }
  },
  props: {
    modelClass: {
      type: String,
      default: null
    },
    classModelId: {
      type: Number,
      default: null
    }
  },
  computed: {
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission('edit-characterizations');
    },
    classId () {
      return this.$store.getters.getParamFromConfigByFilter('model_classes', 'classname', this.modelClass, 'id');
    }
  },
  methods: {
    classifierOptions (availableIds) {
      let classifierOptions = [];
      if (availableIds && availableIds.length > 0) {
        classifierOptions = this.classifiers.filter(el => availableIds.includes(el.id)).map((classifier) => {
          return { value: classifier.id, text: BasicHelper.getTranslation(classifier.name) };
        })
      }
      return classifierOptions;
    },
    loadData () {
      this.loading = true;
      this.characterizations = [];
      this.metadata = null;
      this.classifiers = null;
      this.currentReq = this.ApiRequest.request('characterizations?classId=' + this.classId + '&classModelId=' + this.classModelId, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.data) {
          this.metadata = response.data;
          this.metadata.forEach(el => {
            const row = this.BasicHelper.cloneObject(characterization);
            row.metadata_id = el.id;
            row.class_id = this.classId;
            row.class_model_id = this.classModelId;
            row.allow_multiple = el.allow_multiple;
            row.available_classifier_ids = el.available_classifier_ids ?? [];
            if (el.allow_multiple) {
              row.selected_classifier_ids = el.selected_classifier_ids ?? [];
            } else {
              row.selected_classifier_id = el.selected_classifier_ids[0] ?? null;
            }
            row.text = el.text;
            this.characterizations.push(row);
          });
        }
      });
      this.currentReq = this.ApiRequest.request('classifiers?noPaging=true', this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.data) {
          this.classifiers = response.data;
          this.loading = false;
        }
      });
    },
    save () {
      if (!this.loading) {
        this.loading = true;
        this.ApiRequest.request('characterizations/edit', this.ApiRequest.REQUEST_POST, { items: this.characterizations }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.loadData();
          }
        });
      }
    }
  },
  mounted () {
    this.loadData();
  }
}
