import PartnerPicker from '@/components/pet/partners/picker/PartnerPicker.vue';

export default {
  name: 'PartnerMerger',
  components: { PartnerPicker },
  props: {
    partnerType: {
      type: String,
      default: null
    },
    id: {
      type: Number,
      default: null
    },
    potentialPartner: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      selectedPartner: null,
      preselectedPartner: null,
      loading: false
    };
  },
  computed: {
    title () {
      return this.$t('partner.merge.title');
    },
    path () {
      return 'partners/merge/' + this.id + '?partnerType=' + this.partnerType;
    },
    optionsPath () {
      return (this.partnerType + 's') ?? '';
    },
    partnerPickerFilters () {
      return {
        isActual: 1,
        notPotencial: true
      };
    }
  },
  methods: {
    openPartnerMergeForm () {
      this.loadPreselectedPartner();
      this.$refs['modal-window'].show();
    },
    closePartnerMergeForm () {
      this.$refs['modal-window'].hide();
    },
    savePartnerMergeForm () {
      this.loading = true;
      this.ApiRequest.request(this.path, this.ApiRequest.REQUEST_POST, { mergePartnerId: this.selectedPartner.id, potentialPartnerId: this.id }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else if (response.data.mergedD365Code) {
          this.$store.commit('toastAdd', {
            context: 'success',
            message: this.$t('general.actions.saved')
          });
          this.closePartnerMergeForm();
          this.$emit('saved', response.data.mergedD365Code);
        }
        this.loading = false;
      });
    },
    loadPreselectedPartner () {
      if (!this.preselectedPartner) {
        this.loading = true;
        const preselectedPath = this.optionsPath + '/find?exactOrgNumber=' + this.potentialPartner.orgNumberOrPersonCode + '&notPotencial=true';
        this.ApiRequest.request(preselectedPath, this.ApiRequest.REQUEST_GET, { }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else if (response.data.length > 0) {
            this.preselectedPartner = response.data[0]
          } else {
            this.$store.commit('toastAdd', {
              context: 'warning',
              message: this.$t('partner.merge.noMatch')
            })
          }
          this.loading = false;
        });
      }
    }
  },
  mounted () {
  }
}
