import useVuelidate from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import TransportCostList from '@/components/pet/sales/order/transportCost/list/TransportCostList.vue'
import PartnerPicker from '@/components/pet/partners/picker/PartnerPicker.vue'
import CargoD365LineList from '@/components/pet/sales/cargo/d365linelist/CargoD365LineList.vue'
import ContainerPicker from '@/components/pet/sales/cargo/container/ContainerPicker.vue'
import moment from 'moment';

const defaultEl = {
  id: null,
  deliveryDate: null,
  scheduledStartAt: null,
  scheduledEndAt: null,
  transport_provider_id: null,
  transportLicensePlate: '',
  transportTrailerPlate: '',
  transportDriverName: '',
  transportDriverPhone: '',
  container_number: '',
  transport_costs: [],
  stage_id: null,
  cargo_type_id: null,
  delivery_type_id: null,
  d365lines: [],
  delivery_comment: {
    comment: ''
  }
};

export default {
  name: 'CargoScheduleEdit',
  data () {
    return {
      container: [],
      mountload: false,
      loading: false,
      cargo: null,
      scheduledAt: null,
      hourDelta: 2,
      readonly: false
    }
  },
  validations: {
    cargo: {
      deliveryDate: { required },
      transport_provider_id: {
        required: requiredIf(function () {
          return this.cargo.needTransportInfo;
        })
      },
      transportLicensePlate: {
        required: requiredIf(function () {
          return true
        })
      },
      cargo_type_id: { required },
      delivery_type_id: { required },
      stage_id: { required },
      d365lines: {
        $each: helpers.forEach({
          product_id: {
            required: requiredIf(function () {
              return this.isIncoming;
            })
          }
        })
      }
    }
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    }
  },
  components: {
    PartnerPicker,
    TransportCostList,
    CargoD365LineList,
    ContainerPicker
  },
  computed: {
    isIncoming () {
      if (this.cargo && this.cargo.id) {
        return this.cargo.isIncoming;
      }
      return false;
    },
    transportProviderFilter () {
      return { isActual: 1, order: 'name,ASC' };
    },
    partnerPath () {
      return (this.isIncoming ? 'vendor' : 'customer');
    },
    baseTransParam () {
      return (this.isIncoming ? 'purchase-order' : 'sales-order');
    },
    orderType () {
      if (this.cargo) {
        return this.BasicHelper.getConfigValue('order_cargo_types', 'id', this.cargo.order_cargo_type_id, 'code');
      }
      return null;
    },
    editPerm () {
      return 'edit-logistics-cargos';
    },
    getTitle () {
      if (!this.isModal) {
        return ''
      }
      return this.$t('cargo.actions.schedule');
    },
    hasEditPerm () {
      return this.ApiRequest.userHasPermission(this.editPerm);
    },
    cargoTemplateStatusId () {
      return this.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'code', 'TEMPLATE', 'id')
    },
    canEdit () {
      return !this.readonly && this.cargo && !this.loading && this.hasEditPerm && (!this.cargo.id || this.cargo.canEditCargo);
    },
    cargoproductypes () {
      if (this.cargo) {
        const res = [];
        this.cargo.cargoProductTypeIds.forEach(id => {
          res.push(this.BasicHelper.getConfigValue('product_types', 'id', id, 'name', this.$i18n.locale));
        });
        return res.join(', ');
      }
      return '';
    },
    canAddTransportProviders () {
      return this.ApiRequest.userHasPermission('edit-transport-providers');
    },
    rampOptions () {
      return this.$store.getters.getConfigOptions(
        'warehouses_ramps',
        this.$i18n.locale,
        null,
        null,
        false,
        false,
        false,
        ['id', 'site_id', 'order']
      ).sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
    },
    cargoTypeOptions () {
      return this.$store.getters.getConfigOptions('cargo_types', this.$i18n.locale);
    },
    deliveryTypeOptions () {
      if (this.cargo) {
        return this.$store.getters.getDeliveryTypeOptions(this.$i18n.locale, this.cargo.order_cargo_type_id, this.cargo != null ? this.cargo.delivery_type_id : null);
      }
      return [];
    },
    tabOptions () {
      const options = [
        { code: 'TransportCostList', text: this.$t('order.transportCost') }
      ];
      if (this.isIncoming) {
        options.push({ code: 'd365lines', text: this.$t('cargo.tabs.d365lines'), invalid: this.v$.cargo.d365lines.$error });
      }
      return options;
    },
    cargoPartner () {
      if (this.cargo) {
        return (this.isIncoming ? this.cargo.order.vendor : this.cargo.order.customer);
      }
      return null;
    },
    generateLinesStatuses () {
      return [
        this.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'code', 'TEMPLATE', 'id'),
        this.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'code', 'PLANNED', 'id'),
        this.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'code', 'LOADING', 'id')
      ]
    },
    scheduleDateLabel () {
      return this.cargo.order_cargo_type_id === this.$store.getters.getParamFromConfigByFilter('order_cargo_types', 'code', 'EXP', 'id')
        ? 'cargo.sendDate'
        : 'cargo.scheduledDate';
    }
  },
  methods: {
    getTemplateStatusStyles () {
      const color = this.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'code', 'TEMPLATE', 'color');
      const contrastColor = this.BasicHelper.getContrastColor(color);

      return {
        backgroundColor: color,
        color: contrastColor
      }
    },
    /**
     * @param localcode
     * @param hourDelta
     * @param scheduledStartAt - if not provided not overwritten
     * @param deliveryDate - if not provided, takes, scheduledStartAt
     */
    show (localcode, hourDelta = 2, scheduledStartAt = null, deliveryDate = null, readonly = false, stageId = undefined) {
      this.readonly = readonly;
      this.mountload = true;
      this.hourDelta = (hourDelta && hourDelta > 0 ? hourDelta : 2);
      this.ApiRequest.request('cargo-orders/view-by-code/' + localcode + '/1', this.ApiRequest.REQUEST_GET, { withOrder: true }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else {
          const item = response.data;
          if (scheduledStartAt) {
            let newDate = moment(scheduledStartAt);
            item.scheduledStartAt = newDate;
            item.deliveryDate = item.scheduledStartAt.format('YYYY-MM-DD');
            newDate = moment(scheduledStartAt);
            newDate.add(this.hourDelta, 'hours');
            item.scheduledEndAt = newDate;
          } else if (deliveryDate) {
            item.deliveryDate = deliveryDate;
          }
          if (typeof (stageId) != typeof (undefined)) {
            item.stage_id = stageId;
          }
          this.cargo = this.setCargoData(item, response.data.order.products);
          this.updateTransportCosts()
        }
        this.mountload = false;
        this.v$.$reset();
      });
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    setCargoData (res, products) {
      if (res.isIncoming && res.d365lines.length == 0 && this.generateLinesStatuses.find(statusId => statusId == res.status_id)) {
        products.forEach(prod => {
          const match = res.d365lines.find(el => el.product_id == prod.product_id);
          if (!match) {
            const prodEl = this.$store.getters.getDataFromConfigById('products', prod.product_id);
            res.d365lines.push({
              id: null,
              product_type_id: prod.product_type_id,
              product_id: prod.product_id,
              quality_id: (prodEl ? prodEl.default_quality_id : null),
              measure_type_id: (prodEl ? prodEl.default_measure_type_id : null),
              apus_waste_class_id: prod.apus_waste_class_id
            });
          }
        })
      }
      if (!res.delivery_comment) {
        res.delivery_comment = {
          comment: ''
        }
      }
      return res;
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        this.$forceUpdate();
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          if (key == 'd365lines' && this.isIncoming) {
            reqData.d365lines = [];
            Object.values(this.cargo.d365lines).forEach(el => {
              const sendEl = { id: el.id, product_id: el.product_id, quality_id: el.quality_id };
              if (!el.id) {
                sendEl.apus_waste_class_id = el.apus_waste_class_id;
                sendEl.measure_type_id = el.measure_type_id
              }
              reqData.d365lines.push(sendEl);
            });
          } else if (key != 'd365lines') {
            reqData[key] = this.cargo[key];
          }
        }
        this.ApiRequest.request('cargo-orders/schedule/edit/' + this.cargo.id, this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
            this.loading = false;
            this.$forceUpdate();
          } else {
            const res = response.data;
            if (res.status_id != this.cargo.status_id) {
              res.statusWasChanged = true;
            }
            this.$emit('saved', res);
            this.loading = false;
            this.close();
          }
        });
      }
    },
    moveToStatus (status) {
      this.loading = true;
      this.ApiRequest.request('cargo-orders/change-status/' + this.cargo.id, this.ApiRequest.REQUEST_POST, { tostatus: status }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        } else {
          this.cargo = this.BasicHelper.cloneObject(response.data);
          this.container = this.cargo.container_number;
          this.loading = false;
          this.$emit('saved', response.data);
          this.close();
        }
      });
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    },
    transportProviderChanged (el) {
      if (el) {
        this.cargo.transport_provider_id = el.id;
      } else {
        this.cargo.transport_provider_id = null;
      }
    },
    scheduledDateChanged (val) {
      if (val) {
        const newDate = moment(val);
        if (this.cargo.scheduledStartAt) {
          const curDate = moment(this.cargo.scheduledStartAt);
          newDate.set('hour', curDate.hours());
          newDate.set('minute', curDate.minutes());
          this.cargo.scheduledStartAt = newDate;
          const endDate = moment(newDate);
          endDate.add(this.hourDelta, 'hours');
          this.cargo.scheduledEndAt = endDate
        } else if (this.cargo.scheduledEndAt) {
          const curDate = moment(this.scheduledEndAt.scheduledStartAt);
          newDate.set('hour', curDate.hours());
          newDate.set('minute', curDate.minutes());
          this.cargo.scheduledEndAt = newDate;
        }
      } else {
        this.cargo.scheduledStartAt = null;
        this.cargo.scheduledEndAt = null;
      }
    },
    updateTransportCosts () {
      if (this.cargo != null && this.cargo.delivery_type_id != null) {
        const deliveryType = this.deliveryTypeOptions.find(el => el.value == this.cargo.delivery_type_id)
        const cargoTemplateStatusId = this.cargoTemplateStatusId
        if (deliveryType != undefined && this.cargo.status_id == cargoTemplateStatusId) {
          this.cargo.transport_costs = this.$store.getters.updateCargoTransportCosts(this.cargo, deliveryType);
        }
      }
    },
    scheduledStartChanged (val) {
      if (val) {
        let endDate = moment(val);
        if (this.cargo.deliveryDate) {
          const curDate = moment(this.cargo.deliveryDate);
          curDate.set('hour', endDate.hours());
          curDate.set('minute', endDate.minutes());
          this.cargo.scheduledStartAt = curDate;
          endDate = moment(curDate);
        }
        endDate.add(this.hourDelta, 'hours');
        this.cargo.scheduledEndAt = endDate;
      }
    }
  },
  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'CargoScheduleEdit' }) }
  },
  mounted () {
  }
}
