import DeviceChecklist from '@/components/pet/device/checklist/DeviceChecklist.vue'
import ProduceusesEdit from '@/components/pet/produceuses/edit/ProducesesEdit.vue';
import DowntimeEditactive from '@/components/pet/downtime/editactive/DowntimeEditactive.vue';
import DeviceEditactive from '@/components/pet/device/editactive/DeviceEditactive.vue'

const defaultEl = {
  id: null,
  type_id: null,
  name: {},
  parent_stage_id: null,
  downtimes: [],
  device_ids: [],
  devices: [],
  products: [],
  feedNotAssembly: false,
  bruttoDelta: 0,
  workstation_ids: [],
  finalprodcode: null,
  rin_number: ''
};

const defaultProduct = {
  id: null,
  product_id: null,
  wetRate: 1,
  canFeed: false,
  canProduce: false,
  produceEmptyWeight: false,
  produced_warehouse_id: null,
  produceuses: [],
  overwriteTemplates: false,
  printtemplates: [],
  productionrate: null,
  production_stage_id: null,
  yieldrate: null,
  default_quality_id: null
};

const defaultUsed = {
  id: null,
  product_id: null,
  quantity: null,
  from_warehouse_id: null,
  measure_type_id: null,
  alwaysAdd: true,
  primary: false,
  canEditQuantity: false,
  canWriteOffAsUsed: false,
  measureAsKg: false,
  product_group_id: null,
  usedMaterialGroupType: null,
  quantityRatio: 1
};

export default {
  name: 'TechnologicalStageEdit',
  data () {
    return {
      content: (this.value ? this.value : []),
      activeTabIndex: 0,
      displayErrors: false
    }
  },
  props: {
    value: {
      type: Array,
      default () {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isFlow: {
      type: Boolean,
      default: false
    },
    isWarehouse: {
      type: Boolean,
      default: false
    },
    siteId: {
      type: Number,
      default: null
    }
  },
  components: {
    DeviceChecklist,
    ProduceusesEdit,
    DowntimeEditactive,
    DeviceEditactive
  },
  computed: {
    displayProducts () {
      return this.isFlow && !this.isWarehouse
    },
    displayDowntimes () {
      return this.isFlow;
    },
    displayDevices () {
      return true;
    },
    displayWorkstations () {
      return this.isFlow || this.isWarehouse
    },
    typeOptions () {
      return this.$store.getters.getConfigOptions('technological_stage_types', this.$i18n.locale)
    },
    technnologicalStageOptions () {
      return this.value.map(stage => ({
        value: stage.id,
        text: stage.name[this.$i18n.locale]
      }));
    },
    printerTemplateOptions () {
      return this.$store.getters.getConfigOptions('printer_templates', this.$i18n.locale);
    },
    usedMaterialGroupTypeOptions () {
      const data = []
      const vm = this
      JSON.parse(this.$store.state.config.usedMaterialGroupType).forEach(function callback (value, index) {
        data[index] = {}
        data[index].value = value.value;
        data[index].text = vm.$t('technologicalStage.produceuses.usedMaterialGroupTypeOptions.' + value.name);
        data[index].initName = value.name;
      });
      return data
    },
    materialGroupType () {
      return this.$store.getters.getConfigOptions('product_groups', this.$i18n.locale);
    },
    productOptions () {
      return this.$store.getters.getConfigOptions(
        'products',
        this.$i18n.locale,
        (this.isFlow ? null : 'isChemical'),
        (this.isFlow ? null : true),
        false,
        false,
        false,
        ['isBought', 'isSeparated', 'isFinalProduct', 'isChemical', 'inAxapta', 'default_measure_type_id', 'd365_product_id']
      );
    },
    productOptionsInAxapta () {
      return this.productOptions.filter(el => (el.d365_product_id === null));
    },
    workstationOptions () {
      return this.$store.getters.getConfigOptions(
        'workstations',
        this.$i18n.locale,
        'site_id',
        this.siteId
      );
    },
    formErrors () {
      const errors = {};
      if (this.displayErrors) {
        this.content.forEach((stage, idx) => {
          if (!stage.deleted) {
            if (!stage.name || !stage.name[this.$i18n.locale]) {
              errors[idx] = { name: true, products: [] };
            }
            if (this.displayProducts) {
              stage.products.forEach((prodEl, prodIdx) => {
                if (!prodEl.deleted && !prodEl.product_id) {
                  if (!errors[idx]) {
                    errors[idx] = { products: [] };
                  }
                  errors[idx].products[prodIdx] = true
                }
              });
            }
          }
        });
      }
      return errors;
    },
    warehouseOptions () {
      return this.$store.getters.getConfigOptions('warehouses', this.$i18n.locale, 'inAxapta', true);
    },
    qualityOptions () {
      return this.$store.getters.getConfigOptions('product_qualities', this.$i18n.locale, null, null, false, false, false, [], true);
    }
  },
  methods: {
    updateValue () {
      this.$emit('input', this.content);
      this.$emit('changed', this.content);
    },
    addItem () {
      defaultEl.type_id = this.typeOptions[0].value;
      this.content.push(this.BasicHelper.cloneObject(defaultEl));
      this.activeTabIndex = this.content.length - 1;
      this.updateValue();
    },
    removeItem (index) {
      if (this.content[index].id) {
        this.content[index].deleted = true;
        this.$forceUpdate();
      } else {
        this.content.splice(index, 1);
      }
      this.updateValue();
    },
    tabchange (index) {
      this.activeTabIndex = index;
      this.$forceUpdate();
    },
    usageTypeOptions () {
      return this.PetHelper.getProductUsageTypeOptions();
    },
    addProduct (index) {
      this.content[index].products.push(this.BasicHelper.cloneObject(defaultProduct));
      this.updateValue();
    },
    removeProduct (index, productIdx) {
      if (this.content[index].products[productIdx].id) {
        this.content[index].products[productIdx].deleted = true;
        this.$forceUpdate();
      } else {
        this.content[index].products.splice(productIdx, 1);
      }
      this.updateValue();
    },
    addProductUsed (index, productIdx) {
      this.content[index].products[productIdx].produceuses.push(this.BasicHelper.cloneObject(defaultUsed));
      this.updateValue();
    },
    removeProductUsed (index, productIdx, usedIdx) {
      if (this.content[index].products[productIdx].produceuses[usedIdx].id) {
        this.content[index].products[productIdx].produceuses[usedIdx].deleted = true;
      } else {
        this.content[index].products[productIdx].produceuses.splice(usedIdx, 1);
      }
      this.$forceUpdate();
      this.updateValue();
    },
    toggleProduceUsed (index, productIdx) {
      if (this.content[index].products[productIdx].displayProduceuses) {
        this.content[index].products[productIdx].displayProduceuses = false;
      } else {
        this.content[index].products[productIdx].displayProduceuses = true;
      }
      this.$forceUpdate();
      this.updateValue();
    },
    canProduceProduct (productId) {
      return this.$store.getters.canProduceProduct(productId)
    },
    canFeedProduct (productId) {
      return this.$store.getters.canFeedProduct(productId);
    },
    productChanged (el, index, productIdx) {
      if (el) {
        if (!this.canProduceProduct(el)) {
          this.content[index].products[productIdx].canFeed = false
        }
        if (!this.canFeedProduct(el)) {
          this.content[index].products[productIdx].canProduce = false
        }
      }
      this.content[index].products[productIdx].product_id = el;
      this.updateValue();
    },
    changeCanProduce (val, index, productIdx) {
      if (!val) {
        this.content[index].products[productIdx].displayProduceuses = false;
      } else {
        this.content[index].products[productIdx].displayProduceuses = true;
      }
      this.$forceUpdate();
      this.updateValue();
    },
    useProdChanged (val, index, productIdx, useIdx) {
      if (val) {
        const prod = this.productOptions.find(el => el.value == val);
        if (prod) {
          this.content[index].products[productIdx].produceuses[useIdx].measure_type_id = prod.default_measure_type_id;
        } else {
          this.content[index].products[productIdx].produceuses[useIdx].measure_type_id = null;
        }
      } else {
        this.content[index].products[productIdx].produceuses[useIdx].measure_type_id = null;
      }
      this.$forceUpdate();
      this.updateValue();
    },
    isValid () {
      this.displayErrors = true;
      return (Object.values(this.formErrors).length == 0);
    },
    hideValidation () {
      this.displayErrors = false;
    },
    addPrintTemplate (index, productIdx) {
      this.content[index].products[productIdx].printtemplates.push({
        printer_template_id: null
      });
      this.updateValue();
    },
    deletePrintTemplate (index, productIdx, templateIdx) {
      this.content[index].products[productIdx].printtemplates.splice(templateIdx, 1);
      this.updateValue();
    },
    updateAlwaysAddOption (index, productIdx, useIdx) {
      if (this.content[index].products[productIdx].produceuses[useIdx] != undefined && this.content[index].products[productIdx].produceuses[useIdx].alwaysAdd == false) {
        this.content[index].products[productIdx].produceuses[useIdx].primary = false
        this.content[index].products[productIdx].produceuses[useIdx].canEditQuantity = false
        this.content[index].products[productIdx].produceuses[useIdx].canWriteOffAsUsed = false
        this.content[index].products[productIdx].produceuses[useIdx].primary = false
        this.content[index].products[productIdx].produceuses[useIdx].measureAsKg = false
        this.content[index].products[productIdx].produceuses[useIdx].usedMaterialGroupType = null
      }
    },
    productGroupChanged (index, productIdx, useIdx) {
      const currentEl = this.content[index].products[productIdx].produceuses[useIdx];
      const findEl = this.content[index].products[productIdx].produceuses.find(el => el.product_group_id == currentEl.product_group_id &&
          currentEl.usedMaterialGroupType != el.usedMaterialGroupType)
      if (findEl != undefined && findEl != null) {
        this.content[index].products[productIdx].produceuses[useIdx].usedMaterialGroupType = findEl.usedMaterialGroupType
      }
    },
    usedMaterialGroupTypeChanged (index, productIdx, useIdx) {
      const currentEl = this.content[index].products[productIdx].produceuses[useIdx];
      const findEl = this.content[index].products[productIdx].produceuses.find(el => el.product_group_id != currentEl.product_group_id &&
          currentEl.usedMaterialGroupType == el.usedMaterialGroupType)
      if (findEl != undefined && findEl != null) {
        this.content[index].products[productIdx].produceuses[useIdx].product_group_id = findEl.product_group_id
      }
    },
    calculateBruttoDelta (index, productIdx) {
      let result = '';
      const produces = this.content[index].products[productIdx];
      let min = 0;
      let max = 0;
      let canEditValue = false;
      if (produces.produceuses != null && produces.produceuses.length > 0) {
        const oneUsedMaterialGroupType = this.usedMaterialGroupTypeOptions.find(el => el.initName == 'ONE')
        const manyUsedMaterialGroupType = this.usedMaterialGroupTypeOptions.find(el => el.initName == 'MANY')
        const constGroupedData = []
        produces.produceuses.forEach(function callback (value, index) {
          if (constGroupedData[value.product_group_id] == undefined) {
            constGroupedData[value.product_group_id] = [];
          }
          constGroupedData[value.product_group_id].push(value)
        });
        constGroupedData.forEach(function callback (value, index) {
          let findMinGroupValue = null
          let findMaxGroupValue = null
          let findTotalMaxGroupValue = null
          value.forEach(function callback (value2, index2) {
            if (canEditValue == false && value2.canEditQuantity) {
              canEditValue = true
            }
            if (value2.usedMaterialGroupType == oneUsedMaterialGroupType.value || value2.usedMaterialGroupType == manyUsedMaterialGroupType.value) {
              if (findMinGroupValue == null) {
                findMinGroupValue = parseInt(value2.quantity) * value2.quantityRatio
              } else if (findMinGroupValue > value2.quantity) {
                findMinGroupValue = parseInt(value2.quantity) * value2.quantityRatio
              }
            }
            if (value2.usedMaterialGroupType != manyUsedMaterialGroupType.value) {
              if (findMaxGroupValue == null) {
                findMaxGroupValue = value2.quantity
              } else if (findMaxGroupValue < value2.quantity) {
                findMaxGroupValue = parseInt(value2.quantity) * value2.quantityRatio
              }
            } else {
              findTotalMaxGroupValue += parseInt(value2.quantity) * value2.quantityRatio
            }
          });
          if (findMinGroupValue != null) {
            min += parseInt(findMinGroupValue)
          }
          if (findTotalMaxGroupValue == null) {
            max += parseInt(findMaxGroupValue)
          } else {
            max += parseInt(findTotalMaxGroupValue)
          }
        });
      }
      if (min == max) {
        result = min;
      } else {
        result = min + '-' + max
      }
      if (canEditValue) {
        result = result + '*'
      }
      return result;
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        if (val != this.content) {
          this.content = (val || []);
        }
      }
    }
  }
}
