import FactoryProductVisualOverview from '@/components/pet/factoryproduct/visualorverview/FactoryProductVisualOverview.vue'

const defaultFilterValues = {
  includeEmpty: false,
  type_ids: [],
  local_warehouse_ids: [],
  usageTypes: ['isBought']
};

export default {
  name: 'WarehouseGraphic',
  components: {
    FactoryProductVisualOverview
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      isMounted: false,
      summaryRow: null
    };
  },
  computed: {
    warehouseOptions () {
      return this.$store.getters.getConfigOptions(
        'warehouses',
        this.$i18n.locale,
        'isAssembly',
        ['0', '1'],
        false,
        false,
        true
      );
    },
    usageTypeOptions () {
      return this.PetHelper.getProductUsageTypeOptions();
    },
    requestPath () {
      return this.ApiRequest.addGetParamsToPath('warehouses/summary', this.filterValues, false);
    }
  },
  methods: {
    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.loading = true;
      this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.data) {
          this.tableItems = response.data.filter(el => {
            if (el.product_id) {
              el.productname = this.BasicHelper.getConfigValue('products', 'id', el.product_id, 'name', true);
              return true;
            } else {
              this.summaryRow = this.BasicHelper.cloneObject(el);
            }
            return false;
          });
          this.loading = false;
        }
      });
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    }
  },
  mounted () {
    this.getTableData();
  }
}
