import Vue from 'vue'
import VueRouter from 'vue-router'
import ClearPage from '@/views/ClearPage.vue'
import ClearView from '@/views/ClearView.vue'
import Login from 'ebg-vue-common/src/components/user/login/Login.vue';
import Home from '@/views/Home.vue';
import ConfigEmailsList from 'ebg-vue-common/src/components/configs/emails/list/ConfigEmailsList.vue';
import ScheduledTasksList from 'ebg-vue-common/src/components/scheduledtasks/list/ScheduledTasksList.vue';
import TranslationsList from 'ebg-vue-common/src/components/translations/list/TranslationsList.vue'
import LogGeneral from 'ebg-vue-common/src/components/log/general/LogGeneral.vue'
import ShiftRecipe from '@/views/shift/ShiftRecipe';
import AssemblyApprovalHistoryList from '@/components/pet/assembly/approvalhistorylist/AssemblyApprovalHistoryList.vue'
import ShiftAssemblyApproval from '@/views/assembly/ShiftAssemblyApproval.vue'
import AssemblyRecipe from '@/views/assembly/AssemblyRecipe'
import ConstantList from 'ebg-vue-common/src/components/configs/constant/list/ConstantList.vue'
import DeviceTypeList from '@/components/pet/device/typelist/DeviceTypeList.vue'
import ProductList from '@/components/pet/product/list/ProductList.vue'
import QualityCategoryList from '@/components/pet/quality/category/list/QualityCategoryList.vue'
import QualityBaseConfigList from '@/components/pet/quality/baseConfig/list/QualityBaseConfigList.vue'
import ProductRateList from '@/components/pet/partners/rates/list/ProductRateList.vue'
import LineList from '@/components/pet/line/list/LineList.vue'
import FlowList from '@/components/pet/flow/list/FlowList.vue'
import DeviceList from '@/components/pet/device/list/DeviceList.vue'
import BrigadeList from '@/components/pet/brigade/list/BrigadeList.vue'
import UserList from 'ebg-vue-common/src/components/user/list/UserList.vue'
import RecipeList from '@/components/pet/shift/recipelist/RecipeList.vue'
import ShiftList from '@/components/pet/shift/list/ShiftList.vue'
import ShiftEmployees from '@/views/shift/ShiftEmployees.vue'
import WarehouseList from '@/components/pet/warehouse/list/WarehouseList.vue'
import ShiftTechnoData from '@/views/shift/ShiftTechnoData.vue'
import ShiftResourceData from '@/views/shift/ShiftResourceData.vue'
import ShiftChemoData from '@/views/shift/ShiftChemoData.vue'
import VendorsList from '@/components/pet/partners/vendors/list/VendorList.vue'
import VendorsEdit from '@/components/pet/partners/vendors/edit/VendorEdit.vue'
import ShiftFedData from '@/views/shift/ShiftFedData.vue'
import ProductFeedingList from '@/components/pet/product/feeding/list/ProductFeedingList.vue'
import ShiftSummaryView from '@/views/shift/ShiftSummaryView.vue'
import DevicePrinterTemplateList from '@/components/pet/device/printer/templatelist/DevicePrinterTemplateList.vue'
import ProducedProductList from '@/components/pet/manufacture/produced/list/ProductList.vue'
import ProducedSeparatedList from '@/components/pet/manufacture/produced/list/SeparatedList.vue'
import ProducedHistory from '@/components/pet/manufacture/history/ProducedHistory.vue'
import ShiftDowntimes from '@/views/shift/ShiftDowntimes.vue'
import DowntimeList from '@/components/pet/shift/downtimes/list/DowntimeList.vue'
import SalesRequirementsList from '@/components/configs/salesrequirements/list/SalesRequirementsList.vue'
import CustomerList from '@/components/pet/partners/customers/list/CustomerList.vue'
import CustomerEdit from '@/components/pet/partners/customers/edit/CustomerEdit.vue'
import AssemblyMovementList from '@/components/pet/assembly/movementlist/AssemblyMovementList.vue'
import DeliveryTypeList from '@/components/pet/partners/deliverytypes/list/DeliveryTypeList.vue'
import TransportProviderList from '@/components/pet/partners/transportprovider/list/TransportProviderList.vue'
import ProductTypeList from '@/components/pet/product/types/list/ProductTypeList.vue';
import FactoryProductList from '@/components/pet/factoryproduct/list/FactoryProductList.vue'
import UserRoleList from 'ebg-vue-common/src/components/user/roles/list/UserRoleList.vue'
import FactoryProductDamagedList from '@/components/pet/factoryproduct/damaged/list/FactoryProductDamagedList.vue'
import D365ProductList from '@/components/pet/product/d365/list/D365ProductList.vue'
import WarehouseSummary from '@/components/pet/warehouse/summary/WarehouseSummary.vue'
import ShiftPdfReportView from '@/views/shift/ShiftPdfReportView.vue'
import WorkstationList from '@/components/pet/workstation/list/WorkstationList.vue'
import UnenteredMaterial from '@/components/pet/warehouse/unenteredmaterial/UnenteredMaterial.vue';
import ShiftPdfReports from '@/components/pet/shift/pdfreports/ShiftPdfReports.vue'
import ProducedReport from '@/components/pet/manufacture/produced/report/ProducedReport.vue';
import IssueReportList from 'ebg-vue-common/src/components/common/issuereport/list/IssueReportList.vue'
import FlowEdit from '@/components/pet/flow/edit/FlowEdit.vue'
import DowntimeTypeList from '@/components/pet/downtime/type/list/DowntimeTypeList.vue'
import WarehouseEdit from '@/components/pet/warehouse/edit/WarehouseEdit.vue';
import SubmittedProductProportion from '@/components/pet/product/submittedProductProportion/SubmittedProductProportion.vue';
import WarehouseCargosList from '@/components/pet/sales/cargo/listfull/WarehouseCargosList.vue';
import OrderList from '@/components/pet/sales/order/list/OrderList.vue'
import OrderEdit from '@/components/pet/sales/order/edit/OrderEdit.vue'
import Cargo from '@/components/pet/sales/cargo/Cargo.vue'
import CargoEditSimple from '@/components/pet/sales/cargo/editsimple/CargoEditSimple.vue'
import QualityTesterCategoryList from '@/components/pet/quality/testerCategory/list/QualityTesterCategoryList.vue';
import CargoBoxTypeList from '@/components/pet/sales/cargo/CargoBoxType/CargoBoxTypeList.vue';
import TransportProviderEdit from '@/components/pet/partners/transportprovider/edit/TransportProviderEdit.vue';
import CargoLogisticsView from '@/components/pet/sales/logistics/view/CargoLogisticsView.vue';
import FactoryProductOverview from '@/components/pet/product/factoryProductOverview/FactoryProductOverview.vue'
import WarehouseSnapshotList from '@/components/pet/warehouse/snappshots/list/WarehouseSnapshotList.vue';
import WarehouseSnapshotView from '@/components/pet/warehouse/snappshots/view/WarehouseSnapshotView.vue';
import ReportIncomingCargo from '@/components/pet/sales/cargo/Report/ReportIncomingCargo.vue';
import CommentTypeList from 'ebg-vue-common/src/components/comments/list/CommentTypeList.vue';
import ModelProductDocumentsList from '@/components/configs/modeldocument/ModelProductDocumentsList.vue';
import ReportImportedProducts from '@/components/pet/product/report/ReportImportedProducts.vue';
import VendorProductsReport from '@/components/pet/product/vendorProductsReport/VendorProductsReport.vue';
import ClassifierTypeList from '@/components/pet/classifiers/ClassifierTypeList.vue';
import CharacterizationMetadataList from '@/components/pet/characterizations/metadata/CharacterizationMetadataList.vue';
import ProductQualityList from '@/components/pet/quality/productQuality/list/ProductQualityList.vue';
import ProductLabelList from '@/components/pet/product/labels/list/ProductLabelList.vue';
import WarehouseGraphic from '@/components/pet/warehouse/graphic/WarehouseGraphic.vue'
import SummableParametersTypeList from '@/components/pet/quality/category/summableParametersType/list/SummableParametersTypeList.vue';
import QualityTestEdit from '@/components/pet/quality/test/edit/QualityTestEdit.vue';
import ProductQualityTestOverView from '@/components/pet/quality/test/overView/ProductQualityTestOverView.vue';
import FedGroupedReport from '@/components/pet/reports/fedgrouped/FedGroupedReport.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'menu.user.login',
    component: Login,
    meta: { public: true, noSession: true, isMenuItem: false, permissions: null }
  },
  {
    path: '/home',
    name: 'menu.home.title',
    component: Home,
    meta: { public: false, noSession: false, isMenuItem: true, permissions: null, shiftpicker: true, homeroute: true, operationalSite: true }
  },
  {
    path: '/shift',
    title: 'menu.shift.title',
    component: ClearPage,
    meta: { public: false, noSession: false, isMenuItem: true, permissions: null },
    children: [
      {
        path: 'recipe',
        name: 'menu.shift.recipe',
        component: ShiftRecipe,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-shift-recipe'], parentPath: '/shift', isConfigPage: false, shiftpicker: true }
      },
      {
        path: 'fed-product',
        name: 'menu.shift.fed-product',
        component: ShiftFedData,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-shift-fed-products'], parentPath: '/shift', isConfigPage: false, shiftpicker: true, qrreaderpicker: true, rfidreaderpicker: true }
      },
      {
        path: 'technological-data',
        name: 'menu.shift.technological-data',
        component: ShiftTechnoData,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-shift-device-measurements'], parentPath: '/shift', isConfigPage: false, shiftpicker: true, shiftHideFlows: true }
      },
      {
        path: 'resources',
        name: 'menu.shift.resources',
        component: ShiftResourceData,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-shift-device-measurements'], parentPath: '/shift', isConfigPage: false, shiftpicker: true, shiftHideFlows: true }
      },
      {
        path: 'chemicals',
        name: 'menu.shift.chemicals',
        component: ShiftChemoData,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-shift-fed-chemicals'], parentPath: '/shift', isConfigPage: false, shiftpicker: true, shiftHideFlows: true }
      },
      {
        path: 'downtimes',
        name: 'menu.shift.downtimes',
        component: ShiftDowntimes,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-shift-downtimes'], parentPath: '/shift', isConfigPage: false, shiftpicker: true, shiftHideFlows: true }
      },
      {
        path: 'summary',
        name: 'menu.shift.summary',
        component: ShiftSummaryView,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-shift-summary'], parentPath: '/shift', isConfigPage: false, shiftpicker: true, shiftHideFlows: true }
      },
      {
        path: 'users',
        name: 'menu.shift.users',
        component: ShiftEmployees,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-shift-users'], parentPath: '/shift', isConfigPage: false, shiftpicker: true, shiftHideFlows: true }
      },
      {
        path: 'pdf-reports',
        name: 'menu.shift.pdf-reports',
        component: ShiftPdfReportView,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-shift-summary'], parentPath: '/shift', isConfigPage: false, shiftpicker: true, shiftHideFlows: true }
      }
    ]
  },
  {
    path: '/assembly',
    title: 'menu.assembly.title',
    component: ClearPage,
    meta: { public: false, noSession: false, isMenuItem: true, permissions: null },
    children: [
      {
        path: 'recipe',
        name: 'menu.assembly.recipe',
        component: AssemblyRecipe,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-assembly-recipe'], parentPath: '/assembly', isConfigPage: false, warehouseshift: true, rfidreaderpicker: true }
      },
      {
        path: 'warehouse',
        name: 'menu.assembly.warehouse',
        component: ShiftAssemblyApproval,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-assembly-warehouse'], parentPath: '/assembly', isConfigPage: false, warehouseshift: true, rfidreaderpicker: true }
      },
      {
        path: 'history',
        name: 'menu.assembly.history',
        component: AssemblyMovementList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-assembly-history'], parentPath: '/assembly', isConfigPage: false }
      }
    ]
  },
  {
    path: '/warehouse',
    title: 'menu.warehouse.title',
    component: ClearPage,
    meta: { public: false, noSession: false, isMenuItem: true, permissions: null },
    children: [
      {
        path: 'orders-in',
        name: 'menu.warehouse.orders-in',
        component: ClearView,
        redirect: { name: 'menu.warehouse.orders-in.list' },
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-warehouse-order', 'view-purchase'], parentPath: '/warehouse', isConfigPage: false },
        children: [
          {
            path: 'list',
            name: 'menu.warehouse.orders-in.list',
            component: WarehouseCargosList,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-warehouse-order', 'view-purchase'], parentPath: 'orders-in', isConfigPage: false, cargoView: true, cargotype: 'IMP;IMP-R', rfidreaderpicker: true }
          }
        ]
      },
      {
        path: 'orders-out',
        name: 'menu.warehouse.orders-out',
        component: ClearView,
        redirect: { name: 'menu.warehouse.orders-out.list' },
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-warehouse-order', 'view-sales'], parentPath: '/warehouse', isConfigPage: false },
        children: [
          {
            path: 'list',
            name: 'menu.warehouse.orders-out.list',
            component: WarehouseCargosList,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-warehouse-order', 'view-sales'], parentPath: 'orders-out', isConfigPage: false, qrreaderpicker: true, rfidreaderpicker: true, cargoView: true, cargotype: 'EXP;TNF' }
          }
        ]
      },
      {
        path: 'cargos',
        name: 'menu.warehouse.cargos',
        component: ClearView,
        redirect: { name: 'menu.warehouse.cargos.list' },
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-warehouse-order'], parentPath: '/warehouse', isConfigPage: false },
        children: [
          {
            path: 'list',
            name: 'menu.warehouse.cargos.list',
            component: WarehouseCargosList,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-warehouse-order'], parentPath: 'cargos', isConfigPage: false, cargoView: false }
          },
          {
            path: 'view/:cargocode',
            name: 'menu.warehouse.cargos.view',
            component: Cargo,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-warehouse-order'], parentPath: 'cargos', isConfigPage: false, qrreaderpicker: true, rfidreaderpicker: true }
          },
          {
            path: 'view-simple/:cargocode/:cargotype?',
            name: 'menu.warehouse.cargos.view-simple',
            component: CargoEditSimple,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-warehouse-order'], parentPath: 'cargos', isConfigPage: false, qrreaderpicker: true, rfidreaderpicker: true }
          }
        ]
      },
      {
        path: 'graphic',
        name: 'menu.warehouse.graphic',
        component: WarehouseGraphic,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-warehouse-summary'], parentPath: '/warehouse', isConfigPage: false }
      },
      {
        path: 'summary',
        name: 'menu.warehouse.summary',
        component: WarehouseSummary,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-warehouse-summary'], parentPath: '/warehouse', isConfigPage: false }
      },
      {
        path: 'write-off',
        name: 'menu.warehouse.write-off',
        component: FactoryProductList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-warehouse-products'], parentPath: '/warehouse', isConfigPage: false }
      },
      {
        path: 'damaged',
        name: 'menu.warehouse.damaged',
        component: FactoryProductDamagedList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-warehouse-damaged'], parentPath: '/warehouse', isConfigPage: false, qrreaderpicker: true }
      },
      {
        path: 'unentered-material',
        name: 'menu.warehouse.unentered-material',
        component: UnenteredMaterial,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['add-unentered-material'], parentPath: '/warehouse', isConfigPage: false, qrreaderpicker: true }
      }
    ]
  },
  {
    path: '/logistic',
    title: 'menu.logistics.title',
    component: ClearPage,
    meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-logistics-cargos'] },
    children: [
      {
        path: 'cargo-planning',
        name: 'menu.logistics.cargo-planning',
        component: CargoLogisticsView,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-logistics-cargos'], parentPath: '/logistic', isConfigPage: false }
      }
    ]
  },
  {
    path: '/manufacture',
    title: 'menu.manufacture.title',
    component: ClearPage,
    meta: { public: false, noSession: false, isMenuItem: true, permissions: null },
    children: [
      {
        path: 'separated',
        name: 'menu.manufacture.produced-separated',
        component: ProducedSeparatedList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-shift-produced'], parentPath: '/manufacture', isConfigPage: false, shiftpicker: true }
      },
      {
        path: 'products',
        name: 'menu.manufacture.produced-product',
        component: ProducedProductList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-shift-produced'], parentPath: '/manufacture', isConfigPage: false, shiftpicker: true }
      },
      {
        path: 'history',
        name: 'menu.manufacture.history',
        component: ClearView,
        redirect: { name: 'menu.manufacture.history.list' },
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-produced-history'], parentPath: '/manufacture', isConfigPage: false, qrreaderpicker: true },
        children: [
          {
            path: 'list',
            name: 'menu.manufacture.history.list',
            component: ProducedHistory,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-produced-history'], parentPath: 'manufacture', isConfigPage: false, qrreaderpicker: true }
          },
          {
            path: 'factoryProductOverview/:code',
            name: 'menu.products.factoryProductOverview',
            component: FactoryProductOverview,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-produced-history'], parentPath: 'manufacture', isConfigPage: false }
          }
        ]
      }
    ]
  },
  {
    path: '/quality',
    title: 'menu.quality.title',
    component: ClearPage,
    meta: { public: false, noSession: false, isMenuItem: true, permissions: null },
    children: [
    ]
  },
  {
    path: '/salesmodule',
    title: 'menu.salesmodule.title',
    component: ClearPage,
    meta: { public: false, noSession: false, isMenuItem: true, permissions: null },
    children: [
      {
        path: 'purchase-orders',
        name: 'menu.warehouse.purchase-orders',
        component: ClearView,
        redirect: { name: 'menu.warehouse.purchase-orders.list' },
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-purchase'], parentPath: '/salesmodule', isConfigPage: false },
        children: [
          {
            path: 'list',
            name: 'menu.warehouse.purchase-orders.list',
            component: OrderList,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-purchase'], parentPath: 'purchase-orders', isConfigPage: false, ordertype: 'IMP;IMP-R' }
          },
          {
            path: 'view/:code',
            name: 'menu.warehouse.purchase-orders.view',
            component: OrderEdit,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-purchase'], parentPath: 'purchase-orders', isConfigPage: false, ordertype: 'IMP;IMP-R', cargoSectionMenu: true }
          }
        ]
      },
      {
        path: 'sales-orders',
        name: 'menu.warehouse.sales-orders',
        component: ClearView,
        redirect: { name: 'menu.warehouse.sales-orders.list' },
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-sales'], parentPath: '/salesmodule', isConfigPage: false },
        children: [
          {
            path: 'list',
            name: 'menu.warehouse.sales-orders.list',
            component: OrderList,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-sales'], parentPath: 'sales-orders', isConfigPage: false, ordertype: 'EXP' }
          },
          {
            path: 'view/:code',
            name: 'menu.warehouse.sales-orders.view',
            component: OrderEdit,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-sales'], parentPath: 'sales-orders', isConfigPage: false, ordertype: 'EXP', cargoSectionMenu: true }
          }
        ]
      },
      {
        path: 'transfer-orders',
        name: 'menu.warehouse.transfer-orders',
        component: ClearView,
        redirect: { name: 'menu.warehouse.transfer-orders.list' },
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-transfer'], parentPath: '/salesmodule', isConfigPage: false },
        children: [
          {
            path: 'list',
            name: 'menu.warehouse.transfer-orders.list',
            component: OrderList,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-transfer'], parentPath: 'transfer-orders', isConfigPage: false, ordertype: 'TNF' }
          },
          {
            path: 'view/:code',
            name: 'menu.warehouse.transfer-orders.view',
            component: OrderEdit,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-transfer'], parentPath: 'transfer-orders', isConfigPage: false, ordertype: 'TNF', cargoSectionMenu: true }
          }
        ]
      },
      {
        path: 'vendors',
        name: 'menu.partners.vendors',
        component: ClearView,
        redirect: { name: 'menu.partners.vendors.list' },
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-vendors'], parentPath: '/salesmodule', isConfigPage: false },
        children: [
          {
            path: 'list',
            name: 'menu.partners.vendors.list',
            component: VendorsList,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-vendors'], parentPath: 'vendors', isConfigPage: false }
          },
          {
            path: 'view/:code',
            name: 'menu.partners.vendors.view',
            component: VendorsEdit,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-vendors'], parentPath: 'vendors', isConfigPage: false }
          }
        ]
      },
      {
        path: 'customers',
        name: 'menu.partners.customers',
        component: ClearView,
        redirect: { name: 'menu.partners.customers.list' },
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-customers'], parentPath: '/salesmodule', isConfigPage: false },
        children: [
          {
            path: 'list',
            name: 'menu.partners.customers.list',
            component: CustomerList,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-customers'], parentPath: 'customers', isConfigPage: false }
          },
          {
            path: 'view/:code',
            name: 'menu.partners.customers.view',
            component: CustomerEdit,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-customers'], parentPath: 'customers', isConfigPage: false }
          }
        ]
      },
      {
        path: 'transport-providers',
        name: 'menu.partners.transport-providers',
        component: ClearView,
        redirect: { name: 'menu.partners.transport-providers.list' },
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-transport-providers'], parentPath: '/salesmodule', isConfigPage: false },
        children: [
          {
            path: 'list',
            name: 'menu.partners.transport-providers.list',
            component: TransportProviderList,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-transport-providers'], parentPath: '/transport-providers', isConfigPage: false }
          },
          {
            path: 'view/:id',
            name: 'transport-providers.edit',
            component: TransportProviderEdit,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-transport-providers'], parentPath: '/transport-providers', isConfigPage: false }
          }
        ]
      },
      {
        path: 'rates',
        name: 'menu.partners.rates',
        component: ProductRateList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-rates'], parentPath: '/salesmodule', isConfigPage: false }
      }
    ]
  },
  {
    path: '/reports',
    title: 'menu.reports.title',
    component: ClearPage,
    meta: { public: false, noSession: false, isMenuItem: true, permissions: null },
    children: [
      {
        path: 'warehouse-approvals',
        name: 'menu.reports.warehouse-approvals',
        component: AssemblyApprovalHistoryList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-warehouse-approvals'], parentPath: '/reports', isConfigPage: false }
      },
      {
        path: 'produced',
        name: 'menu.reports.produced',
        component: ProducedReport,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-report-produced'], parentPath: '/reports', isConfigPage: false }
      },
      {
        path: 'fed',
        name: 'menu.reports.fed',
        component: ProductFeedingList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-report-fed'], parentPath: '/reports', isConfigPage: false }
      },
      {
        path: 'fedgrouped',
        name: 'menu.reports.fedgrouped',
        component: FedGroupedReport,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-report-fed'], parentPath: '/reports', isConfigPage: false }
      },
      {
        path: 'submitted-product-proportion',
        name: 'menu.reports.submitted-product-proportion',
        component: SubmittedProductProportion,
        meta: {
          public: false,
          noSession: false,
          isMenuItem: true,
          permissions: ['view-report-submitted-product-proportion'],
          parentPath: '/reports',
          isConfigPage: false,
          configpath: 'shift/submittedProductList'
        }
      },
      {
        path: 'pdf-reports',
        name: 'menu.reports.pdf-reports',
        component: ShiftPdfReports,
        meta: {
          public: false,
          noSession: false,
          isMenuItem: true,
          permissions: ['view-report-produced', 'view-report-fed'],
          parentPath: '/reports',
          isConfigPage: false
        }
      },
      {
        path: 'incoming-cargo',
        name: 'menu.reports.incoming-cargo',
        component: ReportIncomingCargo,
        meta: {
          public: false,
          noSession: false,
          isMenuItem: true,
          permissions: ['view-incoming-cargo-report'],
          parentPath: '/reports',
          isConfigPage: false
        }
      },
      {
        path: 'imported-products',
        name: 'menu.reports.imported-products',
        component: ReportImportedProducts,
        meta: {
          public: false,
          noSession: false,
          isMenuItem: true,
          permissions: ['view-imported-products-report'],
          parentPath: '/reports',
          isConfigPage: false
        }
      },
      {
        path: 'warehouse-snapshots',
        title: 'menu.reports.warehouse-snapshots.list',
        name: 'menu.warehouse.warehouse-snapshots',
        component: ClearPage,
        redirect: { name: 'menu.warehouse.warehouse-snapshots.list' },
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-warehouse-snapshots'], parentPath: '/reports', isConfigPage: false },
        children: [
          {
            path: 'list',
            name: 'menu.warehouse.warehouse-snapshots.list',
            component: WarehouseSnapshotList,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-warehouse-snapshots'], parentPath: 'warehouse-snapshots', isConfigPage: false }
          },
          {
            path: 'view/:id',
            name: 'menu.warehouse.warehouse-snapshots.view',
            component: WarehouseSnapshotView,
            meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-warehouse-snapshots'], parentPath: 'warehouse-snapshots', isConfigPage: false }
          }
        ]
      },
      {
        path: 'vendor-products',
        name: 'menu.reports.vendor-products',
        component: VendorProductsReport,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-report-vendor-products'], parentPath: '/reports', isConfigPage: false }
      }
    ]
  },
  {
    path: '/sku-layout',
    name: 'menu.sku.menu-name',
    component: ClearPage,
    meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-warehouse-summary'] },
    children: [
    ]
  },
  {
    path: '/testerProfile',
    name: 'menu.quality.testerProfile',
    component: ClearPage,
    meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-quality-tests'], dynamicChildrenComponents: 'TesterProfileSubMenu' },
    children: [
    ]
  },
  {
    path: '/testViewReadOnly/:id',
    name: 'menu.quality.productQualityTestEditReadOnly',
    component: QualityTestEdit,
    meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-quality-tests-read-only'] }
  },
  {
    path: '/testOverViewReadOnly/:id',
    name: 'menu.quality.productQualityTestOverViewReadOnly',
    component: ProductQualityTestOverView,
    meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-quality-tests-read-only'] }
  },
  {
    path: '/system',
    title: 'menu.system.title',
    component: ClearPage,
    meta: { public: false, noSession: false, isMenuItem: true, permissions: null },
    children: [
      {
        path: 'employees',
        name: 'menu.employees.title',
        component: ClearView,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-users', 'view-brigades'], parentPath: '/system', isConfigPage: false },
        children: [
          {
            path: 'users',
            name: 'menu.employees.users',
            component: UserList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-users'], parentPath: 'employees', isConfigPage: false }
          },
          {
            path: 'brigades',
            name: 'menu.brigades.list',
            component: BrigadeList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-brigades'], parentPath: 'employees', isConfigPage: false }
          },
          {
            path: 'roles',
            name: 'menu.system.roles',
            component: UserRoleList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-settings'], parentPath: 'employees' }
          }
        ]
      },
      {
        path: 'comments',
        name: 'menu.comments.title',
        component: ClearView,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-comment-types'], parentPath: '/system', isConfigPage: false },
        children: [
          {
            path: 'commentsTypes',
            name: 'menu.comments.types',
            component: CommentTypeList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-comment-types'], parentPath: 'comments', isConfigPage: false }
          }
        ]
      },
      {
        path: 'shifts',
        name: 'menu.shifts.title',
        component: ClearView,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-all-shifts', 'view-all-recipes'], parentPath: '/system', isConfigPage: false },
        children: [
          {
            path: 'list',
            name: 'menu.shifts.list',
            component: ShiftList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-all-shifts'], parentPath: 'shifts', isConfigPage: false }
          },
          {
            path: 'recipes',
            name: 'menu.shifts.recipes',
            component: RecipeList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-all-recipes'], parentPath: 'shifts', isConfigPage: false }
          },
          {
            path: 'downtimes',
            name: 'menu.shifts.downtimes',
            component: DowntimeList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-shift-downtimes'], parentPath: 'shifts', isConfigPage: false }
          }
        ]
      },
      {
        path: 'products',
        name: 'menu.products.title',
        component: ClearView,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-products', 'view-product-constants', 'view-warehouses'], parentPath: '/system', isConfigPage: false },
        children: [
          {
            path: 'list',
            name: 'menu.products.list',
            component: ProductList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-products'], parentPath: 'products', isConfigPage: false }
          },
          {
            path: 'd365-products',
            name: 'menu.products.d365-products',
            component: D365ProductList,
            meta: {
              public: false,
              noSession: false,
              isMenuItem: true,
              permissions: ['view-product-constants'],
              parentPath: 'products',
              isConfigPage: false
            }
          },
          {
            path: 'types',
            name: 'menu.products.types',
            component: ProductTypeList,
            meta: {
              public: false,
              noSession: false,
              isMenuItem: true,
              permissions: ['view-product-constants'],
              parentPath: 'products',
              isConfigPage: false
            }
          },
          {
            path: 'variants',
            name: 'menu.products.variants',
            component: ConstantList,
            meta: {
              public: false,
              noSession: false,
              isMenuItem: true,
              permissions: ['view-product-constants'],
              parentPath: 'products',
              isConfigPage: false,
              configPermabase: 'product-constants',
              configpath: 'config/mwc-custom/product-variants'
            }
          },
          {
            path: 'apus',
            name: 'menu.products.apus',
            component: ConstantList,
            meta: {
              public: false,
              noSession: false,
              isMenuItem: true,
              permissions: ['view-product-constants'],
              parentPath: 'products',
              isConfigPage: false,
              configPermabase: 'product-constants',
              configpath: 'config/mwc-custom/apus-waste-classes'
            }
          },
          {
            path: 'groups',
            name: 'menu.products.groups',
            component: ConstantList,
            meta: {
              public: false,
              noSession: false,
              isMenuItem: true,
              permissions: ['view-product-groups'],
              parentPath: 'products',
              isConfigPage: false,
              configPermabase: 'product-groups',
              configpath: 'config/mwc-custom/product-groups'
            }
          },
          {
            path: 'warehouses',
            name: 'menu.products.warehouses',
            component: ClearView,
            redirect: { name: 'menu.products.warehouses.list' },
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-warehouses'], parentPath: 'products', isConfigPage: false },
            children: [
              {
                path: 'list',
                name: 'menu.products.warehouses.list',
                component: WarehouseList,
                meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-warehouses'], parentPath: 'products', isConfigPage: false }
              },
              {
                path: 'view/:code',
                name: 'menu.products.warehouses.view',
                component: WarehouseEdit,
                meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-warehouses'], parentPath: 'products', isConfigPage: false }
              }
            ]
          }
        ]
      },
      {
        path: 'quality',
        name: 'menu.quality.admin-title',
        component: ClearView,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-qualities'], parentPath: '/system', isConfigPage: false },
        children: [
          {
            path: 'list',
            name: 'menu.quality.list',
            component: ProductQualityList,
            meta: {
              public: false,
              noSession: false,
              isMenuItem: true,
              permissions: ['view-qualities'],
              parentPath: 'quality',
              isConfigPage: false
            }
          },
          {
            path: 'qualityCodeList',
            name: 'menu.quality.QualityCodelist',
            component: ConstantList,
            meta: {
              public: false,
              noSession: false,
              isMenuItem: true,
              permissions: ['view-qualities-code'],
              parentPath: 'quality',
              isConfigPage: false,
              configPermabase: 'qualities-code',
              configpath: 'config/mwc-custom/product-qualities-code'
            }
          },
          {
            path: 'options',
            name: 'menu.quality.options',
            component: ConstantList,
            meta: {
              public: false,
              noSession: false,
              isMenuItem: true,
              permissions: ['view-product-quality-measurements-options'],
              parentPath: 'quality',
              isConfigPage: false,
              configPermabase: 'product-quality-measurements-options',
              configpath: 'config/mwc-custom/quality-measurement-options'
            }
          },
          {
            path: 'categories',
            name: 'menu.quality.categories',
            component: QualityCategoryList,
            meta: {
              public: false,
              noSession: false,
              isMenuItem: true,
              permissions: ['view-product-quality-measurements-categories'],
              parentPath: 'quality',
              isConfigPage: false,
              configPermabase: 'product-quality-measurements-categories',
              configpath: 'config/mwc-custom/quality-measurement-categories'
            }
          },
          {
            path: 'baseConfig',
            name: 'menu.quality.qualityBaseConfig.title',
            component: QualityBaseConfigList,
            meta: {
              public: false,
              noSession: false,
              isMenuItem: true,
              permissions: ['view-product-quality-measurements-baseConfig'],
              parentPath: 'quality',
              isConfigPage: false,
              configPermabase: 'product-quality-measurements-baseConfig',
              configpath: 'config/mwc-custom/product-quality-measurements'
            }
          },
          {
            path: 'testerCategory',
            name: 'menu.quality.testerCategory',
            component: QualityTesterCategoryList,
            meta: {
              public: false,
              noSession: false,
              isMenuItem: true,
              permissions: ['view-product-quality-measurements-tester-categories'],
              parentPath: 'quality',
              isConfigPage: false,
              configPermabase: 'product-quality-measurements-tester-categories',
              configpath: 'config/mwc-custom/quality-measurement-tester-categories'
            }
          }
        ]
      },
      {
        path: 'manufacture',
        name: 'menu.manufacture.title-admin',
        component: ClearView,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-lines', 'view-flows', 'view-devices', 'view-workstations'], parentPath: '/system', isConfigPage: false },
        children: [
          {
            path: 'lines',
            name: 'menu.manufacture.lines',
            component: LineList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-lines'], parentPath: 'manufacture', isConfigPage: true }
          },
          {
            path: 'flows',
            name: 'menu.manufacture.flows',
            component: ClearView,
            redirect: { name: 'menu.manufacture.flows.list' },
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-flows'], parentPath: 'manufacture', isConfigPage: false },
            children: [
              {
                path: 'list',
                name: 'menu.manufacture.flows.list',
                component: FlowList,
                meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-flows'], parentPath: 'flows', isConfigPage: false }
              },
              {
                path: 'view/:code',
                name: 'menu.manufacture.flows.view',
                component: FlowEdit,
                meta: { public: false, noSession: false, isMenuItem: false, permissions: ['view-flows'], parentPath: 'flows', isConfigPage: false }
              }
            ]
          },
          {
            path: 'devices',
            name: 'menu.manufacture.devices',
            component: DeviceList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-devices'], parentPath: 'manufacture', isConfigPage: true }
          },
          {
            path: 'workstations',
            name: 'menu.manufacture.workstations',
            component: WorkstationList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-workstations'], parentPath: 'manufacture', isConfigPage: true }
          }
        ]
      },
      {
        path: 'partners',
        name: 'menu.partners.title',
        component: ClearView,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-delivery-types', 'view-cargo-types', 'view-sales-requirements'], parentPath: '/system', isConfigPage: false },
        children: [
          {
            path: 'delivery-types',
            name: 'menu.partners.delivery-types',
            component: DeliveryTypeList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-delivery-types'], parentPath: 'partners', isConfigPage: false }
          },
          {
            path: 'cargo-types',
            name: 'menu.partners.cargo-types',
            component: CargoBoxTypeList,
            meta: {
              public: false,
              noSession: false,
              isMenuItem: true,
              permissions: ['view-cargo-types'],
              parentPath: 'partners',
              isConfigPage: false,
              configPermabase: 'cargo-types',
              configpath: 'cargo-types'
            }
          },
          {
            path: 'sales-requirements',
            name: 'menu.partners.sales-requirements',
            component: SalesRequirementsList,
            meta: {
              public: false,
              noSession: false,
              isMenuItem: true,
              permissions: ['view-sales-requirements'],
              parentPath: 'partners',
              isConfigPage: false,
              configPermabase: 'sales-requirements',
              configpath: 'config/sales-order-requirements-options'
            }
          },
          {
            path: 'transport-providers-types',
            name: 'menu.partners.transport-providers-types',
            component: ConstantList,
            meta: {
              public: false,
              noSession: false,
              isMenuItem: true,
              permissions: ['view-transport-providers-types'],
              parentPath: 'partners',
              isConfigPage: false,
              configPermabase: 'transport-providers-types',
              configpath: 'config/mwc-custom/transport-providers-types'
            }
          }
        ]
      },
      {
        path: 'other',
        name: 'menu.system.other',
        component: ClearView,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-settings'], parentPath: '/system', isConfigPage: false },
        children: [
          {
            path: 'locales',
            name: 'menu.system.locales',
            component: ConstantList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-settings'], parentPath: 'other', isConfigPage: true, configpath: 'config/mwc/locales' }
          },
          {
            path: 'downtime-types',
            name: 'menu.system.downtime-types',
            component: DowntimeTypeList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-settings'], parentPath: 'other', isConfigPage: false }
          },
          {
            path: 'device-types',
            name: 'menu.system.device-types',
            component: DeviceTypeList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-settings'], parentPath: 'other', isConfigPage: true, configpath: 'config/mwc/device-types' }
          },
          {
            path: 'technological-stage-types',
            name: 'menu.system.technological-stage-types',
            component: ConstantList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-settings'], parentPath: 'other', isConfigPage: false, configpath: 'config/mwc/technological-stage-types' }
          },
          {
            path: 'shift-types',
            name: 'menu.system.shift-types',
            component: ConstantList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-settings'], parentPath: 'other', isConfigPage: true, configpath: 'config/mwc/shift-types' }
          },
          {
            path: 'summable-parameter-types',
            name: 'menu.system.summable-parameter-types',
            component: SummableParametersTypeList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-settings'], parentPath: 'other', isConfigPage: true, configpath: 'config/mwc/summable-parameter-types' }
          },
          {
            path: 'feeding-types',
            name: 'menu.system.feeding-types',
            component: ConstantList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-settings'], parentPath: 'other', isConfigPage: true, configpath: 'config/mwc/feeding-types' }
          },
          {
            path: 'factory-product-statuses',
            name: 'menu.system.factory-product-statuses',
            component: ConstantList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-settings'], parentPath: 'other', isConfigPage: true, configpath: 'config/mwc/factory-product-statuses' }
          },
          {
            path: 'measure-types',
            name: 'menu.system.measure-types',
            component: ConstantList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-settings'], parentPath: 'other', isConfigPage: true, configpath: 'config/mwc/measure-types' }
          },
          {
            path: 'printer-templates',
            name: 'menu.manufacture.printer-templates',
            component: DevicePrinterTemplateList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-settings'], parentPath: 'other', isConfigPage: true, configpath: 'printer-templates' }
          },
          {
            path: 'product-labels',
            name: 'menu.system.product-labels',
            component: ProductLabelList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-settings'], parentPath: 'other', isConfigPage: true, configpath: 'config/mwc/product-labels' }
          },
          {
            path: 'issue-reports',
            name: 'menu.issue-reports.title',
            component: IssueReportList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-issue-reports'], parentPath: 'other', isConfigPage: false, configpath: 'printer-templates' }
          },
          {
            path: 'model-files',
            name: 'menu.model-file.title',
            component: ModelProductDocumentsList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-model-product-documents'], parentPath: 'other', isConfigPage: false }
          }
        ]
      },
      {
        path: 'other-classifiers',
        name: 'menu.system.otherClassifiers',
        component: ClearView,
        meta: {
          public: false,
          noSession: false,
          isMenuItem: true,
          permissions: ['view-classifiers', 'view-characterization-metadata'],
          parentPath: '/system',
          isConfigPage: false
        },
        children: [
          {
            path: 'classifier-types',
            name: 'menu.system.classifierTypes',
            component: ClassifierTypeList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-classifiers'], parentPath: 'other-classifiers', isConfigPage: false }
          },
          {
            path: 'characterization-metadata',
            name: 'menu.system.characterizationMetadata',
            component: CharacterizationMetadataList,
            meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-characterization-metadata'], parentPath: 'other-classifiers', isConfigPage: false }
          }
        ]
      }
    ]
  },
  {
    path: '/admin',
    title: 'menu.admin.title',
    component: ClearPage,
    meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-settings'] },
    children: [
      {
        path: '',
        redirect: { name: 'menu.admin.scheduled-tasks' }
      },
      {
        path: 'scheduled-tasks',
        name: 'menu.admin.scheduled-tasks',
        component: ScheduledTasksList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['edit-cron'], parentPath: '/admin', isConfigPage: true }
      },
      {
        path: 'general-log',
        name: 'menu.admin.general-log',
        component: LogGeneral,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-settings'], parentPath: '/admin', isConfigPage: false }
      },
      {
        path: 'emails',
        name: 'menu.config.emails.title',
        component: ConfigEmailsList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['edit-settings'], parentPath: '/admin', isConfigPage: true }
      },
      {
        path: 'translations',
        name: 'menu.admin.translations',
        component: TranslationsList,
        meta: { public: false, noSession: false, isMenuItem: true, permissions: ['view-settings'], parentPath: '/admin', isConfigPage: false }
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  let availableTesterCategory = false;
  const rootListCheck = ['menu.quality.productQualityTestEdit', 'menu.quality.productQualityTestOverView']
  if (rootListCheck.includes(to.name)) {
    availableTesterCategory = true;
  }
  if (to.matched.length === 0) {
    next({
      path: '/login'
    });
  } else {
    if (router.app.$session.exists() && to.name === 'menu.user.reset-password') {
      router.app.$store.dispatch('logout', router.app.$session);
    }
    if (!router.app.$session.exists() && !to.meta.public) {
      next({
        path: '/login'
      });
    } else if (router.app.$session.exists() && to.meta.noSession && !availableTesterCategory) {
      next({
        path: '/home'
      });
    } else {
      if (!to.meta.public) {
        const permissions = router.app.$session.get('permissions');
        if (availableTesterCategory || (to.meta.permissions != null && to.meta.permissions.length == 0) || to.meta.permissions === null || to.meta.permissions.filter(Set.prototype.has, new Set(permissions)).length > 0) {
          next();
        } else {
          next({ path: '/home' });
        }
      } else {
        next();
      }
    }
  }
})

export default router
