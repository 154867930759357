const defaultFilterValues = {
  shift_startAt: null,
  line_id: [],
  flow_id: [],
  usageTypes: [],
  name: '',
  shift_type: [],
  type_name: '',
  product_ax2009code: ''
};

export default {
  name: 'ProducedReport',
  components: {
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      isMounted: false,
      exportCols: []
    };
  },
  computed: {
    tableHeaders () {
      return [
        { key: 'shift.shortname', label: this.$t('produced.shift'), filterKey: 'shift_startAt' },
        { key: 'flow_id', label: this.$t('produced.flow_id'), format: 'formatFromConfig', configKey: 'line_flows', configParam: 'flowcode' },
        { key: 'product_type', label: this.$t('produced.type'), type: 'slot', slotcode: 'product_type', filterKey: 'type_name' },
        { key: 'product_id', label: this.$t('produced.name'), format: 'formatFromConfig', configKey: 'products', configParam: 'name', filterKey: 'name' },
        { key: 'prodaxcode', label: this.$t('product.ax2009code'), type: 'slot', slotcode: 'product_ax2009code', filterKey: 'product_ax2009code' },
        { key: 'product', label: this.$t('produced.application'), type: 'slot', slotcode: 'usagetype' },
        { key: 'bags', label: this.$t('produced.bags'), sortable: false },
        { key: 'sumStartQuantity', label: this.$t('produced.weightTotal'), sortable: false, format: 'formatDecimal', precision: 0 },
        { label: this.$t('menu.quality.title'), type: 'slot', slotcode: 'qualities', sortable: false }
      ];
    },
    tableFilterConfig () {
      const filters = { };
      filters.line_id = { type: 'slot', label: this.$t('produced.line_id'), slotcode: 'linefilter' };
      filters.flow_id = { type: 'checkbox', label: (this.flowOptions.length > 0 ? this.$t('produced.flow_id') : ''), options: this.flowOptions, textField: 'flowcode', valueField: 'value' };
      filters.shift_startAt = { type: 'date-range', label: '', colOnlyFilter: true };
      filters.usageTypes = { type: 'checkbox', label: this.$t('product.usageTypes'), options: this.usageTypeOptions, colOnlyFilter: false, valueField: 'code' };
      filters.shift_type = { type: 'checkbox', label: this.$t('shift.type_id'), options: this.shiftTypeOptions, colOnlyFilter: false, valueField: 'code' };
      filters.name = { type: 'text', label: this.$t('produced.name'), colOnlyFilter: true };
      filters.type_name = { type: 'text', label: this.$t('produced.type'), colOnlyFilter: true };
      filters.product_ax2009code = { type: 'text', label: this.$t('product.ax2009code'), colOnlyFilter: true };
      return filters;
    },
    usageTypeOptions () {
      return this.PetHelper.getProductUsageTypeOptions().filter(el => ['isSeparated', 'isFinalProduct'].includes(el.code));
    },
    basePathComp () {
      return 'factory-products'
    },
    requestPath () {
      return this.ApiRequest.addGetParamsToPath(this.basePathComp + '/report', this.filterValues, true);
    },
    productTypeOptions () {
      return this.PetHelper.getProductTypes().filter(el => !el.isChemical && (el.code !== 'TRASH'));
    },
    lineOptions () {
      return this.$store.getters.getAvailableLineOptions(this.$i18n.locale);
    },
    flowOptions () {
      return this.$store.getters.getConfigOptions(
        'line_flows',
        this.$i18n.locale,
        'line_id',
        this.filterValues.line_id,
        false,
        false,
        true,
        ['flowcode']
      );
    },
    shiftTypeOptions () {
      return this.$store.getters.getConfigOptions('shift_types', this.$i18n.locale);
    }
  },
  methods: {
    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.loading = true;
      this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_POST, {}, (response) => {
        if (response.data) {
          this.tableItems = response.data;
          if (response.exportCols) {
            const colConfig = [];
            response.exportCols.forEach(el => {
              const keyParts = el.key.split('.');
              if (keyParts[0] == 'qualities') {
                let title = this.$t('quality.name') + ': ';
                if (keyParts[1] == '0') {
                  title += this.$t('quality.WITHOUT')
                } else {
                  title += this.BasicHelper.getConfigValue('product_qualities', 'id', keyParts[1], 'name');
                }
                colConfig.push({ key: el.key, name: title, group: el.group, required: el.required });
              } else {
                colConfig.push({ key: el.key, name: this.$t(el.key), group: el.group, required: el.required });
              }
            });
            this.exportCols = colConfig;
          }
          this.paging = response.paging;
          this.loading = false;
        }
      });
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    lineFilterChanged () {
      this.filterValues.flow_id = this.filterValues.flow_id.filter(flowId => (this.flowOptions.findIndex(el => el.value == flowId) >= 0));
      this.getTableData();
    },
    download (html, exportCols) {
      this.$refs.tableview.downloadTableData(this.requestPath, exportCols);
    }
  },
  mounted () {
    this.getTableData();
  }
}
