export default {
  name: 'ShiftClose',
  components: {
  },
  data () {
    return {
      loading: false
    };
  },
  props: {
    shift: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    canClose () {
      return (this.shift && !this.shift.closedAt && this.ApiRequest.userHasPermission('close-shift'));
    }
  },
  methods: {
    closeShift () {
      if (!this.loading) {
        this.loading = true;
        this.ApiRequest.request('shift/close/' + this.shift.id, this.ApiRequest.REQUEST_POST, {}, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else if (response.data) {
            this.$store.dispatch('reloadShiftData');
            this.$emit('saved', response.data);
          }
          this.loading = false;
        });
      }
    }
  }
}
