import useVuelidate from '@vuelidate/core';
import AssemblyMoveTo from '@/components/pet/assembly/moveto/AssemblyMoveTo.vue'
import PetHelper from '@/helpers/PetHelper';

const isValidEndValue = (value, siblings, vm) => {
  if (!(value == null || value == '') && vm && vm.device && vm.newDeviceStartValue !== null &&
      parseFloat(vm.newDeviceStartValue) > parseFloat(value) && vm.editStart == false) {
    return false
  }
  if (vm.lastEndValueFound !== null && parseFloat(vm.lastEndValueFound) <= value) {
    return true
  }
  if (vm.lastEndValueFound == null && vm.newDeviceStartValue !== null && vm.newDeviceStartValue < value) {
    return true
  }
  const startValueToCompare = vm.newDeviceStartValue !== null ? vm.newDeviceStartValue : vm.startValue
  if (!(value == null || value == '') && vm && vm.device) {
    if (vm.device.warehouse_id) {
      if (parseFloat(startValueToCompare) + parseFloat(vm.device.movedtotal) - parseFloat(value) < 0) {
        return false;
      }
    } else if (!vm.device.allowReducedValues) {
      if (parseFloat(startValueToCompare) > parseFloat(value)) {
        return false;
      }
      return true;
    }
  }
  return true;
};

export default {
  name: 'ShiftDeviceMeasureCard',
  components: {
    AssemblyMoveTo
  },
  props: {
    device: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: true
    },
    shiftId: {
      type: Number,
      default: null
    },
    extratitle: {
      type: String,
      default: null
    },
    displayDelta: {
      type: Boolean,
      default: true
    },
    currentShift: {
      type: Object,
      default: () => {}
    }
  },
  validations: {
    startValue: { },
    endValue: { isValidEndValue }
  },
  data () {
    return {
      endValueSaved: false,
      startValue: null,
      endValue: null,
      editStart: false,
      loading: false,
      addNewDeviceMeasurement: false,
      newDeviceStartValue: null,
      currentDeviceValue: null,
      updatedInherited: true,
      findSnaps: [],
      lastEndValueFound: null,
      endValueTimeStamp: null
    }
  },
  computed: {
    allowActionAddNewDeviceMeasurement () {
      return ((this.device.inherited == false) ||
              (this.updatedInherited == false)) && this.findSnaps.length == 0 &&
          this.ApiRequest.userHasPermission('add-new-shift-device-measurement-value') && this.device.shiftClosed == false
    },
    allowEditNewDeviceMeasurement () {
      return this.ApiRequest.userHasPermission('add-new-shift-device-measurement-value') && this.device.shiftClosed == false
    },
    displayWarehouseAmount () {
      return this.ApiRequest.userHasPermission('edittime-shift-produced');
    },
    comsumedCalc () {
      if (this.startValue !== null && this.endValue !== null) {
        if (this.findSnaps.length > 0) {
          if (this.lastEndValueFound !== null) {
            return (parseFloat(this.findSnaps[0].measurevalue) - this.startValue) + (this.lastEndValueFound - this.findSnaps[1].measurevalue)
          } else {
            return (parseFloat(this.findSnaps[0].measurevalue) - this.startValue)
          }
        } else {
          return this.endValue - this.startValue;
        }
      }
      return 0;
    },
    canEditStart () {
      return !this.disabled && this.device && this.device.warehouse_id == null;
    },
    canFillWarehouse () {
      return PetHelper.validShiftDateInterval(this.currentShift) && !this.disabled && this.device && this.device.product_id && this.device.warehouse_id;
    },
    hasMismatchQuantity () {
      let startValue = this.device.warehouseamount;
      if (this.device.movedtotal) {
        startValue -= this.device.movedtotal;
      }
      if (startValue != this.startValue) {
        return true;
      }
      return false;
    }
  },
  methods: {
    actionAddNewDeviceMeasurement () {
      if (!this.loading) {
        const reqData = {
          device_id: this.device.id,
          measure_type_id: this.device.measure_type_id,
          newDeviceStartValue: this.newDeviceStartValue,
          currentDeviceValue: this.currentDeviceValue
        };
        this.loading = true;
        this.ApiRequest.request('shift/view/' + this.shiftId + '/measurements/addNewDeviceMeasurementValue', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            if (response.data.devices[this.device.id] !== undefined) {
              this.setEndStartValues(response.data.devices[this.device.id])
              this.device.measurements = response.data.devices[this.device.id].measurements;
              this.addNewDeviceMeasurement = false;
            }
          }
          this.loading = false;
        })
      }
    },
    toggleAddNewDeviceMeasurement () {
      if (this.device.measurements.length < 3) {
        this.addNewDeviceMeasurement = !this.addNewDeviceMeasurement;
      }
    },
    PetHelper () {
      return PetHelper
    },
    changeValues (value, mode) {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        const reqData = {
          device_id: this.device.id,
          measurevalue: value,
          measure_type_id: this.device.measure_type_id
        };
        if (mode == 'edit-start') {
          reqData.measurements = [
            {
              device_id: this.device.id,
              measurevalue: value,
              measure_type_id: this.device.measure_type_id
            }
          ]
          if (this.allowEditNewDeviceMeasurement) {
            reqData.newDeviceStartValue = this.newDeviceStartValue
          }
        } else {
          reqData.startvalue = this.startValue;
        }
        this.loading = true;
        this.ApiRequest.request('shift/view/' + this.shiftId + '/measurements/' + mode, this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            if (mode == 'edit-start') {
              this.editStart = false;
              if (response.data[this.device.id] !== undefined) {
                this.updatedInherited = response.data[this.device.id].inherited;
              }
              this.changeValues(this.endValue, 'edit-ending');
            } else {
              this.$emit('saved', response.data[reqData.device_id]);
              if (this.startValue !== null && this.endValue !== null) {
                this.endValueSaved = true;
              } else {
                this.endValueSaved = false;
              }
            }
          }
          this.loading = false;
        });
      }
    },
    save () {
      if (this.editStart) {
        this.changeValues(this.startValue, 'edit-start');
      } else {
        this.changeValues(this.endValue, 'edit-ending');
      }
    },
    toggleEditStart () {
      this.editStart = !this.editStart;
    },
    fillWarehouse () {
      this.$refs.moveToFrm.show({
        product_id: this.device.product_id,
        to_warehouse_id: this.device.warehouse_id,
        shift_id: this.shiftId,
        missingAmount: null
      })
    },
    addedProduct () {
      this.$emit('addedProduct');
    },
    setEndStartValues (val) {
      let findSnaps = [];
      if (val.measurements != undefined && val.measurements.length > 0) {
        findSnaps = val.measurements.filter(el => el.isSnapShot == true)
      }
      if (findSnaps !== null && findSnaps.length > 0) {
        this.findSnaps = findSnaps
        this.startValue = (val.measurements[0]?.measurevalue ? parseFloat(val.measurements[0]?.measurevalue) : null)
        let endValueFind = val.measurements.filter(el => el.isSnapShot == false && Date.parse(el.measured_at) > Date.parse(findSnaps[1].measured_at))
        if (endValueFind !== null && endValueFind.length > 0) {
          this.endValue = parseFloat(endValueFind[endValueFind.length - 1].measurevalue)
          this.endValueTimeStamp = endValueFind[endValueFind.length - 1].measured_at
          this.lastEndValueFound = parseFloat(endValueFind[endValueFind.length - 1].measurevalue)
        } else {
          endValueFind = val.measurements.filter(el => el.isSnapShot == false && Date.parse(el.measured_at) > Date.parse(val.measurements[0].measured_at))
          if (endValueFind !== null && endValueFind.length > 0) {
            this.lastEndValueFound = parseFloat(endValueFind[endValueFind.length - 1].measurevalue)
            this.endValue = parseFloat(endValueFind[endValueFind.length - 1].measurevalue)
            this.endValueTimeStamp = endValueFind[endValueFind.length - 1].measured_at
          }
        }
        this.newDeviceStartValue = (findSnaps[1]?.measurevalue ? parseFloat(findSnaps[1]?.measurevalue) : null)
        this.currentDeviceValue = (findSnaps[0]?.measurevalue ? parseFloat(findSnaps[0]?.measurevalue) : null)
      } else {
        this.startValue = (val.measurements[0]?.measurevalue ? parseFloat(val.measurements[0]?.measurevalue) : null)
        if (val.useMeasurementAutoUpdate && val.measurements.length > 0) {
          this.endValue = parseFloat(val.measurements[val.measurements.length - 1].measurevalue)
          this.endValueTimeStamp = val.measurements[val.measurements.length - 1].measured_at
        } else {
          this.endValue = (val.measurements[1]?.measurevalue ? parseFloat(val.measurements[1]?.measurevalue) : null)
          this.endValueTimeStamp = val.measurements[1]?.measured_at ? val.measurements[1]?.measured_at : null
        }
      }
    }
  },
  watch: {
    device: {
      immediate: true,
      handler (val) {
        if (Object.keys(this.v$).length !== 0) this.v$.$reset();
        this.startValue = null;
        this.endValue = null;
        this.endValueSaved = false;
        if (val && val.measurements) {
          this.setEndStartValues(val);
          if (this.startValue !== null && this.endValue !== null) {
            this.endValueSaved = true;
          }
        }
      }
    }
  },
  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'ShiftMeasureCard' }) }
  }
}
