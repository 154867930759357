import Cargo from '@/components/pet/sales/cargo/Cargo.vue';
import BNavTabSelect from 'ebg-vue-common/src/components/common/selectNavtab.vue'
export default {
  name: 'CargoList',
  data () {
    return {
      loading: false,
      readonly: false,
      displayNavBar: 1,
      newCargoAmountToAdd: '',
      loadedIndexes: [0, this.selectedCargoId]
    }
  },
  validations: {
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    isIncoming: {
      type: Boolean,
      default: false
    },
    orderContainers: {
      type: Array,
      default: []
    },
    cargos: {
      type: Array,
      default: () => []
    },
    order: {
      type: Object,
      default: () => {}
    },
    selectedCargoId: {
      type: Number,
      default: null
    },
    allAvailableSalesManagerUsers: {
      type: Array,
      default: () => []
    },
    allAvailableWareHouseEditorUsers: {
      type: Array,
      default: () => []
    }
  },
  components: {
    Cargo,
    BNavTabSelect
  },
  computed: {
    editPerm () {
      return 'edit-sales';
    },
    getTitle () {
      if (this.editEl && this.editEl.id) {
        return this.$tc('sales-order.actions.edit', {}, this.editEl);
      }
      return this.$t('sales-order.actions.new');
    },
    deliveryTypeOptions () {
      return this.$store.getters.getConfigOptions('delivery_types', this.$i18n.locale);
    },
    quarantineStatusId () {
      return this.BasicHelper.getConfigValue('cargo_statuses', 'code', 'QUARANTINE', 'id')
    },
    selectedOrdertype () {
      if (this.order) {
        return this.$store.getters.getDataFromConfigWithFilter('order_cargo_types', 'id', this.order.order_cargo_type_id);
      }
      return null;
    },
    cargoTypeOptions () {
      return this.$store.getters.getConfigOptions('cargo_types', this.$i18n.locale);
    },
    hasEditPerm () {
      return this.ApiRequest.userHasPermission(this.editPerm);
    },
    canEdit () {
      return !this.readonly && this.order && !this.loading && this.hasEditPerm && (!this.order.id || this.order.canEdit);
    },
    canAddNewCargo () {
      if (this.order.id != undefined) {
        const orderClosedStatusId = this.BasicHelper.getConfigValue('order_statuses', 'code', 'CLOSED', 'id');
        if (this.order.status_id == orderClosedStatusId) {
          return false
        }
      }
      return true
    },
    canEditPrices () {
      return !this.readonly && this.order && !this.loading && this.hasEditPerm && (!this.editEl.id || this.editEl.canEditPrices);
    },
    tabOptions () {
      const options = [];
      if (this.cargos.length > 0 && this.displayNavBar) {
        const vm = this
        this.cargos.forEach(function callback (value, index) {
          let exclamationTooltip = '';
          let exclamation = false
          if (vm.isIncoming && value.d365lines.length > 0 && vm.order != null && vm.order.products != undefined) {
            if (vm.order.products == null || vm.order.products.length == 0) {
              exclamation = true;
              exclamationTooltip += vm.$t('purchase-order.OrderHasUnplannedCargo') + ' ';
            } else {
              const orderProductIds = vm.order.products.map(el => el.product_id)
              const findUnplannedProduct = value.d365lines.find(el => !orderProductIds.includes(el.product_id))
              if (findUnplannedProduct != null && findUnplannedProduct != undefined) {
                exclamation = true;
                exclamationTooltip += vm.$t('purchase-order.OrderHasUnplannedCargo') + ' ';
              }
              const lineWithQualityMismatch = value.d365lines.find(el => {
                return el?.incomingfactoryproduct?.quality_id && el.quality_id && el?.incomingfactoryproduct?.quality_id !== el.quality_id;
              })
              if (lineWithQualityMismatch != null) {
                exclamation = true;
                exclamationTooltip += vm.$t('purchase-order.cargoLineHasQualityMismatch') + ' ';
              }
            }
          }
          if (vm.order.delivery_type_id && vm.order.delivery_type_id !== value.delivery_type_id) {
            exclamationTooltip += vm.$t('cargo.delivery_type_do_not_match');
            exclamation = true;
          }
          options.push({
            code: index,
            text: vm.$t('cargo.cargoTitle') + ' ' + value.cargoNo,
            isDeleted: value.isDeleted ?? 0,
            isMoved: value.isMoved ?? 0,
            warningText: exclamationTooltip,
            exclamation: exclamation
          });
        });
      }
      return options;
    },
    statusOptions () {
      return this.$store.getters.getConfigOptions(
        'cargo_statuses',
        this.$i18n.locale,
        null,
        null,
        false,
        false,
        false,
        ['color']
      );
    },
    orderContainerUsage () {
      const containerUsage = [];
      if (this.cargos) {
        this.cargos.forEach(el => { if (el.container_number) containerUsage.push(el.container_number); });
      }
      return containerUsage;
    }
  },
  methods: {
    updateContainerUsage (cargoIndex, containerNumber) {
      if (this.cargos) {
        this.cargos[cargoIndex].container_number = containerNumber;
      }
    },
    addCargo () {
      const deliveryTypeId = this.order.delivery_type_id;
      const newCargoPaymentTermId = this.order.id != undefined && this.order.payment_term_id != null
        ? this.order.payment_term_id
        : (this.order.customer ? this.order.customer.payment_term_id : null)

      const newCargo = {
        id: null,
        order_id: this.order.id,
        order_cargo_type_id: this.order.order_cargo_type_id,
        delivery_type_id: (deliveryTypeId ?? this.deliveryTypeOptions[0].value),
        cargo_type_id: this.cargoTypeOptions[0].value,
        payment_term_id: newCargoPaymentTermId,
        sales_manager_id: this.order.sales_manager_id
      }

      if (this.isIncoming && this.order.products.some(product => product.purchased_in_bags)) {
        newCargo.cargoQualityTestEnabled = true;
      }

      if (!this.loading) {
        this.$emit('cargosave');
        this.loading = true;
        this.$forceUpdate();
        const reqData = {
          cargo: newCargo,
          newCargoAmountToAdd: this.newCargoAmountToAdd == '' || this.newCargoAmountToAdd == null ? 1 : this.newCargoAmountToAdd
        };
        this.ApiRequest.request('cargo-orders/addBatchCargo', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
            this.loading = false;
            this.$forceUpdate();
          } else {
            this.$emit('orderUpdated', response.data.order, true);
            this.loading = false;
            this.$forceUpdate();
          }
        });
      }
    },
    cloneCargo () {
      if (this.cargos.length > 0) {
        const activeCargoIndex = this.loadedIndexes[this.loadedIndexes.length - 1];
        this.ApiRequest.request('cargo-orders/cloneCargo', this.ApiRequest.REQUEST_POST,
          { cargoId: this.cargos[activeCargoIndex === null ? 0 : activeCargoIndex].id, newCargoAmountToAdd: this.newCargoAmountToAdd }, (response) => {
            if (response.error) {
              this.ApiRequest.displayErrorDialog(response, this);
              this.loading = false;
              this.$forceUpdate();
            } else {
              this.$emit('orderUpdated', response.data.order, true);
              this.loading = false;
              this.$forceUpdate();
            }
          });
      }
    },
    deleteCargo (cargoIndex) {
      this.cargos[cargoIndex].isDeleted = 1;
      this.displayNavBar++;
      this.$emit('cargoUpdated', this.cargos ?? []);
    },
    moveCargo (cargoIndex) {
      this.cargos[cargoIndex].isMoved = 1;
      this.displayNavBar++;
      this.$emit('cargoUpdated', this.cargos ?? []);
    },
    filesUpdated () {
      this.$emit('filesUpdated')
    },
    show (elData, readonly = false) {
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    },
    getStatusName (statusId) {
      const val = this.statusOptions.find(el => el.value == statusId);
      if (val) {
        return val.text;
      }
      return null;
    },
    getStatusStyle (statusId) {
      const val = this.statusOptions.find(el => el.value == statusId);
      if (val) {
        return `background-color:${val.color}`;
      }
      return '';
    },
    tabChanged (a) {
      this.loadedIndexes.push(a);
    }
  },
  setup () {
  },
  mounted () {
  },
  watch: {
  }
}
