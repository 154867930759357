export default {
  name: 'SalesOrderFactoryProducts',
  components: {
  },
  props: {
    data: {
      type: Array,
      default: () => null
    },
    editFactoryProducts: {
      type: Boolean,
      default: false
    },
    orderId: {
      type: Number,
      default: null
    },
    specificQuantity: {
      type: Boolean,
      default: false
    },
    lineId: {
      type: Number,
      default: null
    },
    totalRow: {
      type: Boolean,
      default: false
    },
    cargo_id: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      loading: false,
      currentReq: null,
      items: []
    };
  },
  computed: {
    basePathComp () {
      return 'cargo-orders/view/' + this.cargo_id + '/factoryproducts';
    },
    baseTransParam () {
      return 'cargo.factoryproduct';
    },
    tableHeaders () {
      const headers = [
        { key: 'code', label: this.$t(this.baseTransParam + '.code'), classTd: 'fw-bold' },
        { key: 'created_at', label: this.$t(this.baseTransParam + '.created_at'), type: 'date' },
        { key: 'product_id', label: this.$t(this.baseTransParam + '.product_id'), format: 'formatFromConfig', configKey: 'products', configParam: 'name' },
        { key: 'salesOrderFactoryProduct.bales', label: this.$t(this.baseTransParam + '.salesOrderFactoryProductBales'), format: 'formatDecimal', precision: 0 },
        { key: 'salesOrderFactoryProduct.quantity', label: this.$t(this.baseTransParam + '.salesOrderFactoryProductQuantity'), format: 'formatDecimal', precision: 0 },
        { key: 'measure_type_id', label: this.$t(this.baseTransParam + '.salesOrderFactoryProductMeasurementType'), format: 'formatFromConfig', configKey: 'measure_types', configParam: 'name', classTd: 'w-10' },
        { key: 'quality_id', label: this.$t(this.baseTransParam + '.qualitycode'), format: 'formatFromConfig', configKey: 'product_qualities', configParam: 'displaycode' },
        { key: 'quality_id', label: this.$t(this.baseTransParam + '.quality_id'), type: 'slot', slotcode: 'quality' },
        { key: 'isDamaged', label: this.$t(this.baseTransParam + '.isDamaged'), format: 'formatYesNo' }
      ];
      const actions = [];
      if (this.editFactoryProducts) {
        if (this.canMarkDamaged) {
          actions.push({ label: this.$t(this.baseTransParam + '.actions.markDamagedTitle'), emit: 'markDamaged', bicon: 'upc-scan', class: 'btn-sm btn-warning' });
        }
        actions.push({ label: this.$t(this.baseTransParam + '.actions.removeTitle'), emit: 'removeProduct', bicon: 'trash', class: 'btn-sm btn-danger' });
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    canMarkDamaged () {
      return this.ApiRequest.userHasPermission(['mark-warehouse-damaged', 'edit-warehouse-damaged']);
    },
    netoWeight () {
      let w = 0;
      this.items.forEach(el => {
        if (el.salesOrderFactoryProduct && el.salesOrderFactoryProduct.quantity) {
          w += el.salesOrderFactoryProduct.quantity;
        } else {
          w += el.remainingQuantity;
        }
      });
      return w;
    }
  },
  methods: {
    getTableData (mountLoad = false) {
      if (this.cargo_id) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.loading = true;
        let path = this.basePathComp;
        if (this.lineId) {
          path += '/' + this.lineId;
        }
        this.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.items = response.data;
            if (!mountLoad) {
              this.emitTotalsInfo();
            }
            this.loading = false;
          }
        });
      }
    },
    emitTotalsInfo () {
      const res = { assembledPieces: this.items.length, assembledQuantity: 0 };
      this.items.forEach(el => {
        res.assembledQuantity += el.remainingQuantity;
      });
      this.$emit('assembledChanged', res);
    },
    markDamaged (el) {
      this.removeProduct(el, 0, 1)
    },
    setDamaged (factProdId) {
      this.ApiRequest.request('factory-products/damaged/mark', this.ApiRequest.REQUEST_POST, { item: { factory_product_id: factProdId } }, (response) => {
        if (response.error) {
          this.$store.commit('toastAdd', {
            context: 'danger',
            message: this.ApiRequest.getApiResponseErrorMessage(response, {})
          })
        }
      });
    },
    removeProduct (el, approved = 0, isDamaged = 0) {
      if (approved) {
        const reqData = {
          factoryproduct_id: el.id,
          sales_order_product_id: el.salesOrderFactoryProduct?.id
        }
        this.ApiRequest.request('cargo-orders/remove-factory-product/' + this.cargo_id, this.ApiRequest.REQUEST_POST, reqData, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            if (el.isDamaged) {
              this.setDamaged(reqData.factoryproduct_id);
            }
            this.getTableData();
            this.$emit('saved');
          }
        });
      } else {
        const reqEl = this.BasicHelper.cloneObject(el);
        reqEl.isDamaged = isDamaged;
        let transKey = 'remove'
        if (isDamaged) {
          transKey = 'markDamaged'
        }
        this.$refs.factoryProductRemoveMsgBox.show(
          reqEl,
          this.$t(this.baseTransParam + '.actions.' + transKey + 'Title'),
          this.$t(this.baseTransParam + '.actions.' + transKey + 'Message', { code: el.code })
        )
      }
    },
    getQualityBadgeColors (qualityId) {
      return this.$store.getters.getQualityBadgeColors(qualityId);
    }
  },
  mounted () {
    if (this.data == null) {
      this.getTableData();
    }
  },
  watch: {
    data: {
      immediate: true,
      handler (val) {
        if (val !== null) {
          this.items = val;
        }
      }
    }
  }
}
