const defaultFilterValues = {
  name: '',
  orgNumberOrPersonCode: '',
  isVendor: [],
  isActual: ['1'],
  deleted: ['0']
};

export default {
  name: 'TransportProviderList',
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { item: 'name', direction: 'asc' },
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false
    };
  },
  computed: {
    editPermComp () {
      return 'edit-transport-providers';
    },
    deletePermComp () {
      return 'delete-transport-providers';
    },
    basePathComp () {
      return 'transport-providers'
    },
    baseTransParam () {
      return 'transport-provider';
    },
    tableHeaders () {
      const headers = [
        { key: 'name', label: this.$t(this.baseTransParam + '.name'), sortable: false, filterKey: 'name' },
        { key: 'orgNumberOrPersonCode', label: this.$t(this.baseTransParam + '.orgNumberOrPersonCode'), sortable: false, filterKey: 'orgNumberOrPersonCode' },
        { key: 'vendor.code', label: this.$t(this.baseTransParam + '.vendor_id'), sortable: false, filterKey: 'isVendor' },
        { key: 'isActual', label: this.$t(this.baseTransParam + '.isActual'), filterKey: 'isActual', format: 'formatYesNo' },
        { key: 'deleted', label: this.$t('general.item.deleted'), filterKey: 'deleted', format: 'formatYesNo', reverseMode: true }
      ];
      const actions = [];
      if (this.canEdit) {
        actions.push({ label: this.$t('general.actions.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-sm btn-primary' });
      }
      if (this.canDelete) {
        actions.push({ label: this.$t('general.actions.delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-sm btn-danger', ifconfig: { param: 'deleted_at', value: null } });
        actions.push({ label: this.$t('general.actions.enable'), emit: 'restoreItem', bicon: 'clock-history', class: 'btn-sm btn-warning', ifconfig: { param: 'deleted_at', value: null, notCheck: true } });
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    tableExpandConfig () {
      return null;
    },
    tableFilterConfig () {
      const filters = { };
      filters.name = { type: 'text', label: '', colOnlyFilter: true };
      filters.orgNumberOrPersonCode = { type: 'text', label: '', colOnlyFilter: true };
      filters.isActual = { type: 'checkbox', label: '', options: this.yesNoOptions, colOnlyFilter: true };
      filters.isVendor = { type: 'checkbox', label: '', options: this.yesNoOptions, colOnlyFilter: true };
      filters.deleted = { type: 'checkbox', label: '', options: this.yesNoOptions, colOnlyFilter: true };
      return filters;
    },
    canDelete () {
      return this.ApiRequest.userHasPermission(this.deletePermComp);
    },
    canEdit () {
      return this.ApiRequest.userHasPermission(this.editPermComp);
    },
    yesNoOptions () {
      return this.BasicHelper.yesNoOptions();
    },
    requestPath () {
      let path = this.ApiRequest.addGetParamsToPath(this.basePathComp + '/list?perPage=' + this.paging.limit + '&page=' + this.paging.page, this.filterValues, true);
      if (this.tableSorting.item) {
        path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
      }
      return path;
    },
    tableActions () {
      const actions = { download: false };
      if (this.canEdit) {
        actions.add = { title: this.$t(this.baseTransParam + '.actions.add') };
      }
      return actions;
    }
  },
  methods: {
    getTableData () {
      if (this.basePathComp) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        this.loading = true;
        this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.tableItems = response.data;
            this.paging = response.paging;
            this.loading = false;
          }
        });
      }
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    editItem (item) {
      this.$router.push({ name: 'transport-providers.edit', params: { id: (item ? item.id : 'NEW') } });
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    },
    deleteItem (el, approved) {
      if (approved) {
        this.setDeleted(el, 1);
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t('general.item.deleteTitle'), this.$t('general.item.deleteMessage', { name: el.name, code: '' }))
      }
    },
    restoreItem (el) {
      this.setDeleted(el, 0);
    },
    setDeleted (el, isDeleted) {
      this.loading = true;
      this.ApiRequest.request(this.basePathComp + '/deleted', this.ApiRequest.REQUEST_POST, { id: el.id, code: el.code, deleted: isDeleted }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        } else {
          this.getTableData();
        }
      });
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.getTableData();
    this.isMounted = true;
  },
  watch: {
    basePathComp: {
      immediate: true,
      handler (val) {
        if (val) {
          this.isMounted = false;
          this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
          this.getTableData();
          this.isMounted = true;
        }
      }
    }
  }
}
