import useVuelidate from '@vuelidate/core';
import UserWorklist from '@/components/pet/user/worklist/UserWorklist.vue'
import Comments from 'ebg-vue-common/src/components/common/comments/Comments.vue';
import ShiftProductionJournalList from '@/components/pet/shift/productionjournal/ShiftProductionJournalList.vue'
import ShiftFlowProduced from '@/components/pet/shift/recipeedit/shiftFlowProduced/ShiftFlowProduced.vue';

const defaultEl = {
  id: null,
  brigade_id: null,
  warehouse_id: null,
  maintenance: false,
  shiftusers: []
};

export default {
  name: 'ShiftEdit',
  data () {
    return {
      editEl: null,
      loading: false,
      primaryBrigades: true,
      currentShiftUsers: []
    }
  },
  validations: {
    editEl: {
    }
  },
  components: {
    ShiftFlowProduced,
    UserWorklist,
    Comments,
    ShiftProductionJournalList
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    },
    shiftFlowProducedReadOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    baseTransParam () {
      return 'shift';
    },
    getTitle () {
      if (this.editEl && this.editEl.id) {
        return this.$t(this.baseTransParam + '.actions.edit') + ' ' + this.editEl.shortname;
      }
      return this.$t(this.baseTransParam + '.actions.new');
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission('edit-all-shifts');
    },
    canEditUsers () {
      return !this.loading && this.ApiRequest.userHasPermission('edit-shift-users');
    },
    warehouseOptions () {
      return this.$store.getters.getConfigOptions(
        'warehouses',
        this.$i18n.locale,
        'isAssembly',
        true,
        false,
        false,
        false,
        [],
        true
      );
    },
    brigadeOptions () {
      return this.$store.getters.getConfigOptions(
        'brigades',
        this.$i18n.locale,
        null,
        null,
        false,
        false,
        false,
        ['default_line_id', 'manager']
      );
    },
    brigadeFilters () {
      if (this.primaryBrigades && this.editEl) {
        return { default_line_id: this.editEl.line_id };
      }
      return null;
    },
    shiftCompletionItems () {
      return this.$store.getters.shiftCompletionItems;
    },
    tabOptions () {
      return [
        { code: 'users', text: this.$t('shift.tabs.users') },
        { code: 'productionjournals', text: this.$t('shift.tabs.productionjournals') },
        { code: 'comments', text: this.$t('shift.tabs.comments') },
        { code: 'shiftFlowProduced', text: this.$t('menu.shift.shiftFlowProduced') }
      ];
    }
  },
  methods: {
    show (elData, id = null, load = false) {
      if (elData) {
        this.editEl = this.BasicHelper.cloneObject(elData);
        this.currentShiftUsers = elData.shiftusers
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
      }
      this.primaryBrigades = true;
      this.v$.$reset();
      if (load) {
        this.editEl = null;
        this.loading = true;
        this.ApiRequest.request('shift/view/' + id, this.ApiRequest.REQUEST_GET, [], (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.editEl = response.data;
          }
          this.loading = false;
        });
      }

      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
      if (this.$refs.userWorklist) {
        this.$refs.userWorklist.hideValidation();
      }
    },
    save () {
      this.v$.$touch();
      this.$refs.userWorklist.isValid();
      if (!this.v$.$invalid && !this.loading && this.$refs.userWorklist.isValid()) {
        if (this.currentShiftUsers.length > 0) {
          const vm = this
          this.editEl.shiftusers.forEach(function callback (value, index) {
            const findShiftUser = vm.currentShiftUsers.find(el => el.user_id == value.user_id)
            if (findShiftUser != undefined && findShiftUser != null && vm.editEl.shiftusers[index].id == null && findShiftUser.id > 0) {
              vm.editEl.shiftusers[index].id = findShiftUser.id
            }
          })
        }
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        if (this.editEl.shift_flow_produced_config != undefined) {
          this.editEl.shift_flow_produced_config.forEach(shiftFlowProduced => {
            const { targetQuantity, calculatedTargetQuantity } = shiftFlowProduced;
            if ((targetQuantity == null || targetQuantity === '') && calculatedTargetQuantity != null) {
              shiftFlowProduced.targetQuantity = calculatedTargetQuantity;
            }
          });
          reqData.shift_flow_produced_config = this.editEl.shift_flow_produced_config
        } else {
          reqData.shift_flow_produced_config = []
        }
        if (!this.canEdit) {
          delete reqData.brigade_id;
          delete reqData.warehouse_id;
        }
        this.loading = true;
        this.ApiRequest.request('shift/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$store.dispatch('reloadConfig');
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    },
    commentsChanged (newComments) {
      this.editEl.comments = this.BasicHelper.cloneObject(newComments);
    },
    getBrigadeManager (text, item) {
      if (item.manager) {
        return text + ' (' + item.manager.fullname + ')';
      }
      return text;
    },
    loadBrigadeEmployees () {
      if (this.editEl.brigade_id && this.$refs.userWorklist) {
        this.$refs.userWorklist.replaceWithBrigadeWorkers();
      }
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
