const defaultFilterValues = {
  ready: [1],
  autogenereted: [],
  haserrors: [],
  created_at: null
};

export default {
  name: 'WarehouseSnapshotList',
  components: {
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { item: 'created_at', direction: 'desc' },
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false
    };
  },
  computed: {
    basePathComp () {
      return 'warehouses/snapshots'
    },
    basePerm () {
      return 'warehouse-snapshots';
    },
    baseTransParam () {
      return 'warehouse.snapshot';
    },
    tableHeaders () {
      const headers = [
        { key: 'created_at', label: this.$t('general.item.created_at'), type: 'datetime', filterKey: 'created_at' },
        { key: 'user.fullname', label: this.$t(`${this.baseTransParam}.user_id`) },
        { key: 'ready', label: this.$t(`${this.baseTransParam}.ready`), format: 'formatYesNo', filterKey: 'ready' },
        { key: 'autogenereted', label: this.$t(`${this.baseTransParam}.autogenereted`), format: 'formatYesNo', filterKey: 'autogenereted' },
        { key: 'haserrors', label: this.$t(`${this.baseTransParam}.haserrors`), format: 'formatYesNo', reverseMode: true, filterKey: 'haserrors' }
      ];
      const actions = [];
      actions.push({ label: this.$t(`${this.baseTransParam}.actions.download`), emit: 'downloadItem', bicon: 'cloud-download', class: 'btn-sm btn-info' });
      actions.push({ label: this.$t(`${this.baseTransParam}.actions.downloadTemplate`), emit: 'downloadTemplateItem', bicon: 'file-earmark-arrow-down', class: 'btn-sm btn-warning' });
      actions.push({ label: this.$t(`${this.baseTransParam}.actions.view`), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-sm btn-primary' });
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    tableExpandConfig () {
      return null;
    },
    tableFilterConfig () {
      const filters = { };
      filters.created_at = { type: 'date-range', label: '', colOnlyFilter: true };
      filters.ready = { type: 'checkbox', label: '', options: this.yesNoOptions, colOnlyFilter: true };
      filters.autogenereted = { type: 'checkbox', label: '', options: this.yesNoOptions, colOnlyFilter: true };
      filters.haserrors = { type: 'checkbox', label: '', options: this.yesNoOptions, colOnlyFilter: true };
      return filters;
    },
    canAdd () {
      return this.ApiRequest.userHasPermission('create-' + this.basePerm);
    },
    requestPath () {
      let path = this.ApiRequest.addGetParamsToPath(this.basePathComp + '/list?perPage=' + this.paging.limit + '&page=' + this.paging.page, this.filterValues, true);
      if (this.tableSorting.item) {
        path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
      }
      return path;
    },
    tableActions () {
      const actions = { download: false };
      if (this.canAdd) {
        actions.add = { title: this.$t(`${this.baseTransParam}.actions.add`) };
      }
      return actions;
    },
    yesNoOptions () {
      return this.BasicHelper.yesNoOptions();
    }
  },
  methods: {
    getTableData () {
      if (this.basePathComp) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        this.loading = true;
        this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.tableItems = response.data;
            this.paging = response.paging;
            this.loading = false;
          }
        });
      }
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    editItem (el, newtab = 0) {
      if (newtab) {
        const routeData = this.$router.resolve({ name: 'menu.warehouse.warehouse-snapshots.view', params: { id: (el ? el.id : 'NEW') } });
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push({
          name: 'menu.warehouse.warehouse-snapshots.view',
          params: { id: (el ? el.id : 'NEW') }
        });
      }
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    },
    downloadItem (el, template = false) {
      el.downloading = true;
      this.ApiRequest.request('warehouses/snapshots/view/' + el.id + '?download=1&asTemplate=' + template, this.ApiRequest.REQUEST_DOWNLOAD, {}, (e) => {
        el.downloading = false;
      });
    },
    downloadTemplateItem (el) {
      this.downloadItem(el, true);
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.getTableData();
    this.isMounted = true;
  }
}
