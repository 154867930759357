export default {
  name: 'QRReaderPicker',
  props: {
  },
  data () {
    return {
      warehouseId: null
    }
  },
  computed: {
    currentQRDeviceId: {
      get () {
        return this.$store.state.pet.currentQRDeviceId;
      },
      set (val) {
        this.$store.dispatch('changeStateValues', { currentQRDeviceId: val })
      }
    },
    warehouseOptions () {
      let items = this.$store.getters.getConfigOptions(
        'warehouses',
        this.$i18n.locale,
        null,
        null,
        false,
        false,
        false,
        ['availableDevices']
      );
      items.forEach(el => {
        el.qrDeviceIds = [];
        Object.values(el.availableDevices).forEach(stageDevices => {
          stageDevices.forEach(deviceId => {
            if (this.deviceOptions.find(subEl => subEl.id == deviceId)) {
              el.qrDeviceIds.push(deviceId);
            }
          });
        });
      });
      items = items.filter(el => {
        return (el.qrDeviceIds.length > 0);
      });
      items.unshift({ value: null, text: '-' });
      return items;
    },
    visibleDeviceOptions () {
      const warehouse = this.warehouseOptions.find(el => el.value == this.warehouseId);
      if (warehouse && warehouse.value) {
        return this.deviceOptions.filter(el => {
          return warehouse.qrDeviceIds.find(deviceId => deviceId == el.id);
        });
      }
      return this.deviceOptions;
    },
    deviceOptions () {
      const items = Object.values(this.$store.getters.getDevicesByType('QRREADER'));
      items.forEach(el => {
        el.text = this.BasicHelper.getTranslation(el.name);
      });
      return items;
    }
  },
  methods: {
    closeDropdown () {
      const item = document.getElementById('dropdown-' + this._uid);
      if (item) {
        item.click();
      }
    },
    clearDevice () {
      this.currentQRDeviceId = null;
    }
  },
  watch: {
    currentQRDeviceId: {
      immediate: true,
      handler () {
      }
    }
  }
}
