import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import TechnologicalStageEdit from '@/components/pet/technologicalstage/edit/TechnologicalStageEdit.vue'
import FlowStagesWarehouseEdit from './FlowStagesWarehouseEdit.vue'
import DowntimeEditactive from '@/components/pet/downtime/editactive/DowntimeEditactive.vue'
import TargetProductBonusList from '../targetProductBonuses/list/TargetProductBonusList.vue'

const defaultEl = {
  id: null,
  code: '',
  name: {},
  order: 0,
  periodContains: 4,
  line_id: null,
  flowdowntimepivot: [],
  stages: [],
  hasRecipe: true,
  printtemplates: [],
  finalProdOffset: 0,
  recipeReportConfigs: [],
  d365config: {
    manufacture: {
      department: '',
      izmaksuCentrs: ''
    }
  }
};

const recipeWorksheetConfigTemplate = {
  materialTypes: [],
  rowCount: null,
  sequence: null
}

const defaultPrintTemplate = {
  printer_template_id: null,
  isFinalProduct: false,
  isSeparated: false
}
export default {
  name: 'FlowEdit',
  data () {
    return {
      editEl: this.BasicHelper.cloneObject(defaultEl),
      loading: false,
      loadComponent: false
    }
  },
  validations: {
    editEl: {
      code: { required },
      name: { required },
      order: {},
      periodContains: { required },
      line_id: { required },
      recipeReportConfigs: {
        $each: helpers.forEach({
          rowCount: { required },
          sequence: { required }
        })
      },
      printtemplates: {
        $each: helpers.forEach({
          printer_template_id: { required }
        })
      }
    }
  },
  components: {
    DowntimeEditactive,
    TechnologicalStageEdit,
    FlowStagesWarehouseEdit,
    TargetProductBonusList
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    baseTransParam () {
      return 'flow';
    },
    getTitle () {
      if (this.editEl.id) {
        return this.$t(this.baseTransParam + '.actions.edit');
      }
      return this.$t(this.baseTransParam + '.actions.new');
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission('edit-flows');
    },
    lineOptions () {
      return this.$store.getters.getConfigOptions('lines', this.$i18n.locale);
    },
    printerTemplateOptions () {
      return this.$store.getters.getConfigOptions('printer_templates', this.$i18n.locale);
    },
    materialTypeList () {
      return this.$store.getters.getConfigOptions('product_types', this.$i18n.locale);
    },
    selectedSiteId () {
      return this.$store.getters.getParamFromConfigByFilter('lines', 'id', this.editEl.line_id, 'site_id')
    }
  },
  methods: {
    setComponentData (code) {
      if (code && ((code === 'NEW' && this.$route.query.cloneCode != undefined) || (code !== 'NEW'))) {
        this.loadComponent = true;
        code = this.$route.query.cloneCode != undefined ? this.$route.query.cloneCode : code;
        this.ApiRequest.request('flows/view-by-code/' + code, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else if (response.data) {
            const baseConfigD365 = this.BasicHelper.cloneObject(defaultEl.d365config);
            if (!response.data.d365config) {
              response.data.d365config = baseConfigD365;
            } else {
              Object.keys(baseConfigD365).forEach(param => {
                if (!response.data.d365config[param]) {
                  response.data.d365config[param] = baseConfigD365[param];
                } else {
                  Object.keys(baseConfigD365[param]).forEach(param2 => {
                    if (!response.data.d365config[param][param2]) {
                      response.data.d365config[param][param2] = baseConfigD365[param][param2];
                    }
                  })
                }
              })
            }
            const editEl = this.BasicHelper.cloneObject(response.data);
            if (this.$route.query.cloneCode != undefined) {
              editEl.id = null;
              editEl.code = null;
              editEl.line_id = null;
              editEl.flowDowntimes = [];
              Object.values(editEl.stages).forEach(stage => {
                stage.id = null;
                stage.flow_id = null;
                stage.devices = [];
                stage.downtimes = [];
              });
            }
            this.editEl = editEl
          } else {
            this.editEl = null;
          }
          this.loadComponent = false;
          this.v$.$reset();
          if (this.$refs.techStageEdit) {
            this.$refs.techStageEdit.hideValidation();
          }
        });
      }
    },
    show (elData) {
      if (elData) {
        this.setComponentData(elData.code);
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
        this.editEl.line_id = this.lineOptions[0].value;
      }
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
      if (this.$refs.techStageEdit) {
        this.$refs.techStageEdit.hideValidation();
      }
    },
    deletePrintTemplate (key) {
      this.editEl.printtemplates.splice(key, 1);
    },
    addPrintTemplate () {
      const el = this.BasicHelper.cloneObject(defaultPrintTemplate);
      this.editEl.printtemplates.push(el);
    },
    deleteRecipeWorksheetConfigTemplate (key) {
      this.editEl.recipeReportConfigs.splice(key, 1);
    },
    addRecipeWorksheetConfigTemplate () {
      const recipeReportConfig = this.BasicHelper.cloneObject(recipeWorksheetConfigTemplate);
      this.editEl.recipeReportConfigs.push(recipeReportConfig)
    },
    save () {
      this.v$.$touch();
      this.$refs.techStageEdit.isValid();
      if (!this.v$.$invalid && !this.loading && this.$refs.techStageEdit.isValid()) {
        this.loading = true;
        const reqData = {};
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        this.ApiRequest.request('flows/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$store.dispatch('reloadConfig');
            this.$emit('saved', response.data);
            this.editEl = this.BasicHelper.cloneObject(response.data);
            this.loadComponent = false;
            this.v$.$reset();
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    },
    lineChanged () {
      this.$refs.warehouseEditFrm.show();
    }
  },
  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'FlowEdit' }) }
  },
  mounted () {
    this.isMounted = true;
    this.setComponentData(this.$route.params.code);
  },
  watch: {
    '$route' () {
      this.setComponentData(this.$route.params.code);
    }
  }
}
