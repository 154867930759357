import MqttDevice from 'ebg-vue-common/src/components/common/mqttdevice.vue'

export default {
  name: 'RfidScanner',
  props: {
    value: {
      type: String | Number,
      default: null
    },
    rfidLength: {
      type: Number,
      default: 24
    },
    deviceId: {
      type: Number,
      default: null
    },
    loadRfidData: {
      type: Boolean,
      default: false
    },
    validateData: {
      type: Object,
      default: null
    },
    continuescan: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      content: this.value,
      scanning: true
    }
  },
  components: {
    MqttDevice
  },
  computed: {
    rfidDeviceOptions () {
      return this.$store.getters.getDevicesByType('RFIDREADER');
    },
    rfidDevice () {
      return this.rfidDeviceOptions.find(el => el.id == this.deviceId);
    }
  },
  methods: {
    focusscan () {
      this.content = '';
      this.$emit('input', this.content);
      this.$emit('rfidLoaded', null);
      this.scanning = true;
      this.$forceUpdate();
      this.focusInput();
    },
    focusInput () {
      setTimeout(() => {
        this.$refs.rfidinput.focus();
      }, 50)
    },
    mqttDataRead (val) {
      if (this.scanning) {
        this.content = val;
        this.scandone();
      }
    },
    scanned () {
      if (this.content.length == this.rfidLength) {
        this.scandone();
      }
    },
    scandone (newvalue = null) {
      if (newvalue) {
        this.content = newvalue;
      }
      this.$emit('input', this.content);
      this.$emit('scanned', this.content);
      if (this.loadRfidData) {
        this.getRfidData(this.content);
      }
      this.scanning = false;
    },
    getRfidData (rfid) {
      this.ApiRequest.request('rfid/view-by-code/' + rfid, this.ApiRequest.REQUEST_GET, { validate: this.validateData }, (response) => {
        if (response && response.data) {
          this.$emit('rfidLoaded', response.data);
        } else if (this.continuescan) {
          this.focusscan();
        }
      }, true);
    }
  },
  watch: {
    deviceId: {
      immediate: true,
      handler (val) {
      }
    },
    value: {
      immediate: true,
      handler (val) {
        if (val != this.content) {
          this.content = val;
        }
      }
    }
  }
}
