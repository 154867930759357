import Cargo from '@/components/pet/sales/cargo/Cargo.vue'
import CargoEditSimple from '@/components/pet/sales/cargo/editsimple/CargoEditSimple.vue'
import { mapState } from 'vuex';
import CargoD365LineList from '@/components/pet/sales/cargo/d365linelist/CargoD365LineList.vue';
import DevicePrinterCallprint from '@/components/pet/device/printer/callprint/DevicePrinterCallprint.vue';

const defaultFilterValues = {
  localcode: '',
  code: '',
  status: [],
  partner: '',
  orderDate: null,
  deliveryDate: { start: null, end: null },
  cargoStatuses: false,
  withoutvendor: false,
  clientcode: '',
  ordercargotype: [],
  deliveryType: [],
  licencePlateOrTrailerPlateOrContainerNumber: '',
  transport_provider_id: null,
  isReceived: [],
  isBooked: [],
  selectedDateOption: 3,
  myPartners: null
};

export default {
  name: 'WareHouseCargoList',
  components: {
    DevicePrinterCallprint,
    CargoD365LineList,
    Cargo,
    CargoEditSimple
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { item: 'deliveryDate', direction: 'desc' },
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false,
      cargoView: !!this.$route.meta.cargoView,
      allAvailableSalesManagerUsers: [],
      allAvailableWareHouseEditorUsers: [],
      fileBase64: null
    };
  },
  props: {
    vendorId: {
      type: Number,
      default: null
    },
    transport_provider_id: {
      type: Number,
      default: null
    }
  },
  computed: {
    ...mapState({
      currentUser: state => state.user
    }),
    ordertypes () {
      if (this.$route.meta.cargotype) {
        return this.$route.meta.cargotype
      }
      return '';
    },
    defaultFilterValues () {
      const values = this.BasicHelper.cloneObject(defaultFilterValues);
      if (!this.cargoView) {
        values.status = this.statusOptions.filter(el => el.code != 'CANCELED' && el.code != 'BOOKCANCEL').map(el => el.code);
      }
      return values;
    },
    canViewFull () {
      return this.ApiRequest.userHasPermission(['view-purchase', 'view-sales']);
    },
    basePathComp () {
      return 'cargo-orders'
    },
    baseTransParam () {
      if (!this.ordertypes) {
        return 'cargo'
      }
      return (this.primaryOrderType ? this.primaryOrderType.transbase : 'purchase') + '-order';
    },
    primaryOrderType () {
      return this.PetHelper.getOrdertype(this.ordertypes);
    },
    isIncoming () {
      return (this.primaryOrderType && this.primaryOrderType.isIncoming);
    },
    tableHeaders () {
      const headers = [];
      if (this.cargoView) {
        headers.push({ key: 'statusicon', label: '', sortable: false, type: 'slot', slotcode: 'statusicon' })
      } else {
        headers.push({ key: 'status_id', label: '', sortable: false, type: 'slot', slotcode: 'statuscolor', classTd: 'position-relative p-0 w-color' })
      }
      if (!this.cargoView && this.orderTypeOptions.length > 1) {
        headers.push({ key: 'type', label: '', sortable: false, type: 'slot', slotcode: 'cargotype' })
      }
      headers.push({ key: 'localcode', label: this.$t('cargo.localcode'), filterKey: 'localcode' });
      if (!this.ordertypes) {
        headers.push({ key: 'clientcode', label: this.$t('cargo.clientcode'), filterKey: 'clientcode' });
      }
      headers.push({ key: 'partner', label: this.$t('cargo.partner'), filterKey: 'partner', type: 'slot', slotcode: 'orderPartner' });
      headers.push({ key: 'preselected_cargo_products', label: this.$t('cargo.productType'), filterKey: 'productType', type: 'slot', slotcode: 'productTypes' });
      if (!this.cargoView) {
        headers.push({ key: 'sales_manager.fullname', class: 'col-2', label: this.$t(this.baseTransParam + '.salesManager') });
        headers.push({ key: 'warehouse_editor.fullname', class: 'col-2', label: this.$t(this.baseTransParam + '.warehouseEditor') });
      }
      headers.push({ key: 'order.local_warehouse_id', label: this.$t(this.baseTransParam + '.local_warehouse_id'), format: 'formatFromConfig', configKey: 'warehouses', configParam: 'name' });
      headers.push({ key: 'weight', label: this.$t(this.baseTransParam + '.weight'), format: 'formatDecimal', precision: 0, filterKey: 'weight' });
      headers.push({ key: 'deliveryDate', label: this.$t(this.baseTransParam + '.cargoDate'), filterKey: 'deliveryDate', type: 'slot', slotcode: 'deliveryDate' });
      headers.push({ key: 'cmrDocument', label: this.$t(this.baseTransParam + '.cmrDocument'), type: 'slot', slotcode: 'cmrDocument' });
      headers.push({ key: 'transportLicensePlate', label: this.$t('cargo.transportLicensePlate') + '/' + this.$t('cargo.transportTrailerPlate') + '/' + this.$t('cargo.containerNumber'), type: 'slot', slotcode: 'licencePlateOrTrailerPlateOrContainerNumber', filterKey: 'licencePlateOrTrailerPlateOrContainerNumber' });

      const actions = [];
      if (!this.isIncoming) {
        // To enable label printing from list
        // actions.push({ label: this.$t('general.actions.print'), emit: 'printLabelForm', bicon: 'printer-fill', class: 'btn-sm btn-secondary' });
      }
      actions.push({ label: this.$t('cargo.actions.view'), emit: 'viewItem', bicon: 'eye-fill', class: 'btn-sm btn-info' })
      if (this.canEditWarehouse) {
        actions.push({ label: this.$t('cargo.actions.markArrived'), emit: 'markArrivedItem', bicon: 'truck', class: 'btn-sm btn-warning', ifconfig: { useFunc: true, func: this.canMarkArrived } });
        if (this.cargoView) {
          // incoming actions
          actions.push({ label: this.$t('cargo.actions.resume'), emit: 'resumeItem', bicon: 'play-fill', class: 'btn-sm btn-warning', ifconfig: { useFunc: true, func: this.canResumeOrder } });
          actions.push({ label: this.$t('cargo.actions.resumeMarking'), emit: 'editItem', bicon: 'tags-fill', class: 'btn-sm btn-warning', ifconfig: { useFunc: true, func: this.canResumeMarking } });

          // outgoing actions
          actions.push({ label: this.$t('cargo.actions.toStatus.ASSEMBLY'), emit: 'resumePlannedToAssembly', bicon: 'play-fill', class: 'btn-sm btn-warning', ifconfig: { useFunc: true, func: this.canResumePlanned } });
          actions.push({ label: this.$t('cargo.actions.resumeAssembly'), emit: 'resumeAssemble', bicon: 'play-fill', class: 'btn-sm btn-warning', ifconfig: { useFunc: true, func: this.canResumeAssemble } });
          actions.push({ label: this.$t('cargo.actions.markLoading'), emit: 'markLoadingItem', bicon: 'truck', class: 'btn-sm btn-warning', ifconfig: { useFunc: true, func: this.canMarkLoading } });
          actions.push({ label: this.$t('cargo.actions.resume'), emit: 'resumeLoading', bicon: 'truck', class: 'btn-sm btn-warning', ifconfig: { useFunc: true, func: this.canResumeLoading } });
          actions.push({ label: this.$t('cargo.actions.cancelLoading'), emit: 'cancelLoading', bicon: 'x-circle', class: 'btn-sm btn-danger', ifconfig: { useFunc: true, func: this.canResumeLoading } });
        }
      }
      if (this.canEdit || !this.cargoView) {
        actions.push({ label: this.$t('cargo.actions.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-sm btn-primary', ifconfig: { func: this.displayEditAction, useFunc: true } });
      }
      if (!this.cargoView) {
        if (this.canDelete) {
          actions.push({ label: this.$t('cargo.actions.cancel'), emit: 'cancelItem', bicon: 'trash', class: 'btn-sm btn-danger', ifconfig: { param: 'canCancel', value: true } });
          actions.push({ label: this.$t('cargo.actions.restore'), emit: 'restoreItem', bicon: 'clock-history', class: 'btn-sm btn-warning', ifconfig: { useFunc: true, func: this.canRestoreOrder } });
        }
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10 text-nowrap' });
      return headers;
    },
    tableExpandConfig () {
      return { component: 'slot' };
    },
    tableFilterConfig () {
      const filters = { };
      filters.status = {
        type: 'checkbox',
        label: this.$t('purchase-order.status_id'),
        options: this.statusOptionsUsed,
        class: 'col-md-auto',
        controlClass: (this.cargoView ? 'pe-1 ps-1 me-1 rounded' : ''),
        textField: 'name',
        textTranslate: true
      };
      filters.localcode = { type: 'text', label: '', colOnlyFilter: true };
      filters.weight = { type: 'text', label: '', colOnlyFilter: true };
      filters.clientcode = { type: 'text', label: '', colOnlyFilter: true };
      filters.partner = { type: 'text', label: '', colOnlyFilter: true };
      filters.orderDate = { type: 'date-range', label: '', colOnlyFilter: true };
      filters.stageIds = { type: 'checkbox', label: this.$t('cargo.stage_id'), options: this.rampOptions, valueField: 'id', class: 'col-md-auto' }
      if (this.orderTypeOptions.length > 1) {
        filters.ordercargotype = { type: 'checkbox', label: this.$t('cargo.order_cargo_type_id'), options: this.orderTypeOptions, valueField: 'code', class: 'col-md-auto' }
      }

      if (this.cargoView) {
        filters.quickFilters = { type: 'checkbox', label: this.$t('cargo.only'), options: this.quickFilterOptions, class: 'col-md-auto', controlClass: 'pe-2 ps-2 me-2 rounded' };
        filters.dateNavBar = { type: 'slot', label: '', slotcode: 'dateNavBar', class: 'col-12' };
      } else {
        filters.deliveryDate = { type: 'date-range', label: '', colOnlyFilter: true };
      }

      if (!this.cargoView) {
        if (this.isD365Enabled) {
          filters.isReceived = { type: 'checkbox', label: this.$t('cargo.d365ReceivedAt'), options: this.yesNoOptions, class: 'col-md-auto' };
          filters.isBooked = { type: 'checkbox', label: this.$t('cargo.bookedAt'), options: this.yesNoOptions, class: 'col-md-auto' };
        }
        filters.salesManager = { type: 'text', class: 'col-md-auto', label: this.$t(this.baseTransParam + '.salesManager'), colOnlyFilter: false };
        filters.warehouseEditor = { type: 'text', class: 'col-md-auto', label: this.$t(this.baseTransParam + '.warehouseEditor'), colOnlyFilter: false };
        filters.myPartners = { type: 'checkbox', class: 'col-md-auto', label: ' ', valueField: 'value', colOnlyFilter: false, options: [{ value: this.currentUser?.id, bicon: 'person-circle', text: this.$t(this.baseTransParam + '.myCargos') }] };
      }
      filters.licencePlateOrTrailerPlateOrContainerNumber = { type: 'text', label: '', colOnlyFilter: true };
      filters.productType = { type: 'text', label: '', colOnlyFilter: true };
      return filters;
    },
    orderTypeOptions () {
      const options = this.$store.getters.getConfigOptions('order_cargo_types', this.$i18n.locale);
      if (this.ordertypes) {
        const codes = this.ordertypes.split(';');
        return options.filter(el => {
          return codes.includes(el.code);
        });
      }
      return options;
    },
    isD365Enabled () {
      return this.$store.getters.isFeatureEnabled('d365_api', 'purchase.edit');
    },
    canDelete () {
      return this.ApiRequest.userHasPermission('edit-purchase');
    },
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-purchase');
    },
    canAdd () {
      if (!this.isCargoTypeSupported('IMP')) {
        return false;
      }
      if (this.cargoView) {
        return this.ApiRequest.userHasPermission('add-warehouse-order');
      }
      return this.canEdit;
    },
    canEditWarehouse () {
      return this.ApiRequest.userHasPermission('edit-warehouse-order');
    },
    statusOptions () {
      let options = Object.values(this.$store.getters.getItemsFromConfigWithFilter('cargo_statuses', null, null));
      options = this.BasicHelper.sortArrayByKeyValue(options, 'order');
      if (this.cargoView && this.ordertypes) {
        options = options.filter(el => {
          if (this.isCargoTypeSupported('IMP') && (el.code == 'ASSEMBLY' || el.code == 'ASSEMBLED')) {
            return false;
          } else if (!this.isCargoTypeSupported('IMP') && el.code == 'MARKING') {
            return false;
          }
          return el.displayInCargo;
        });
        options.forEach(el => {
          el.colorCheckbox = false;
          el.labelSpanClass = null;
          if (this.statusicons[el.id] && this.statusicons[el.id].includes('fas')) {
            el.fontawesome = (this.statusicons[el.id] ? this.statusicons[el.id] : null);
            el.bicon = null
          } else {
            el.fontawesome = null
            el.bicon = (this.statusicons[el.id] ? this.statusicons[el.id][0] : null);
          }
        });
      } else {
        options.forEach(el => {
          el.colorCheckbox = true;
          el.labelSpanClass = 'rounded ps-1 pe-1';
        })
      }
      return options;
    },
    statusOptionsUsed () {
      return this.statusOptions;
    },
    tableActions () {
      const actions = { download: false };
      if (this.canAdd) {
        actions.add = { title: this.$t('general.actions.add') };
      }
      return actions;
    },
    statusIdCanceled () {
      return this.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'code', 'CANCELED', 'id')
    },
    statusIdsAssembled () {
      return [
        this.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'code', 'ASSEMBLED', 'id')
      ]
    },
    statusIdsLoading () {
      return [
        this.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'code', 'LOADING', 'id')
      ]
    },
    statusIdsMarking () {
      return [
        this.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'code', 'MARKING', 'id')
      ]
    },
    statusIdsAssembling () {
      return [
        this.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'code', 'ASSEMBLY', 'id')
      ]
    },
    statusIdsCanArrive () {
      return [
        this.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'code', 'PLANNED', 'id')
      ];
    },
    statusicons () {
      const map = {
        PLANNED: ['bi bi-hourglass-split', ''],
        ASSEMBLY: ['bi bi-bell-fill', 'text-danger'],
        ASSEMBLED: ['bi bi-check-lg', 'text-success'],
        LOADING: ['bi bi-truck', ' text-warning'],
        LOADED: ['bi bi-clipboard-check-fill', 'text-success'],
        MARKING: ['bi bi-tags-fill', ''],
        LABORATORY: ['fas', 'flask-vial'],
        FINISHED: ['bi bi-clipboard-check-fill', 'text-success'],
        TEMPLATE: ['bi bi-clipboard', ''],
        CANCELED: ['', '']
      };
      const options = {};
      Object.keys(map).forEach(statuscode => {
        const id = this.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'code', statuscode, 'id');
        if (id) {
          options[id] = map[statuscode];
        }
      })
      return options;
    },
    quarantineStatusId () {
      return this.BasicHelper.getConfigValue('cargo_statuses', 'code', 'QUARANTINE', 'id')
    },
    allowedTemplates () {
      return ['BATCH_OUT'];
    },
    yesNoOptions () {
      return this.BasicHelper.yesNoOptions();
    },
    rampOptions () {
      const options = this.$store.getters.getConfigOptions(
        'warehouses_ramps',
        this.$i18n.locale,
        null,
        null,
        false,
        false,
        false,
        ['id', 'site_id', 'order']
      );
      options.unshift({ id: 'NULL', text: this.$t('general.fullcalendar.noresource'), order: 0 });
      return options.sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
    },
    dateOptions () {
      return [
        { code: 1, text: this.$t('general.fullcalendar.timeGridDay') },
        { code: 3, text: this.$t('general.fullcalendar.timeGridThree') },
        { code: 5, text: this.$t('general.fullcalendar.timeGridWeek') },
        { code: 'month', text: this.$t('general.fullcalendar.dayGridMonth') }
      ]
    },
    quickFilterOptions () {
      return [
        { code: 'filter-available', text: this.$t('cargo.filter-available'), bicon: 'person-fill-slash' },
        { code: 'filter-mine', text: this.$t('cargo.filter-mine'), bicon: 'person-circle' }
      ]
    }
  },
  methods: {
    viewFile (cargoId, fileId) {
      this.fileBase64 = null;
      this.ApiRequest.request(this.basePathComp + '/files/' + cargoId + '/view/' + fileId, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.error) {
          this.$store.commit('toastAdd', {
            context: 'danger',
            message: this.ApiRequest.getApiResponseErrorMessage(response, {})
          })
        } else if (response.data) {
          this.fileBase64 = response.data.base64;
          this.$refs.filepreview.show();
        }
      });
    },
    isCargoTypeSupported (code) {
      if (this.ordertypes) {
        return this.orderTypeOptions.find(el => el.code == code);
      }
      return false;
    },
    dateOptionChanged () {
      if (this.filterValues.selectedDateOption === 'month') {
        const startPeriodChange = 0;
        const endPeriodChange = 1;
        this.setMonth(new Date(this.filterValues.deliveryDate.start), startPeriodChange, endPeriodChange);
      } else {
        const startPeriodChange = 0;
        const endPeriodChange = parseInt(this.filterValues.selectedDateOption) - 1;
        this.setDate(new Date(this.filterValues.deliveryDate.start), startPeriodChange, endPeriodChange);
      }
    },
    startDateChanged () {
      if (this.filterValues.selectedDateOption === 'month') {
        const startPeriodChange = 0;
        const endPeriodChange = 1;
        this.setMonth(new Date(this.filterValues.deliveryDate.start), startPeriodChange, endPeriodChange);
      } else {
        const startPeriodChange = 0;
        const endPeriodChange = parseInt(this.filterValues.selectedDateOption) - 1;
        this.setDate(new Date(this.filterValues.deliveryDate.start), startPeriodChange, endPeriodChange);
      }
    },
    goToday () {
      if (this.filterValues.selectedDateOption === 'month') {
        const startPeriodChange = 0;
        const endPeriodChange = 1;
        this.setMonth(new Date(), startPeriodChange, endPeriodChange);
      } else {
        const startPeriodChange = (Math.floor(this.filterValues.selectedDateOption / 2) * -1);
        const endPeriodChange = parseInt(this.filterValues.selectedDateOption) - 1;
        this.setDate(new Date(), startPeriodChange, endPeriodChange);
      }
    },
    goPrevDate () {
      if (this.filterValues.selectedDateOption === 'month') {
        const startPeriodChange = -1;
        const endPeriodChange = 1;
        this.setMonth(new Date(this.filterValues.deliveryDate.start), startPeriodChange, endPeriodChange);
      } else {
        const startPeriodChange = parseInt(this.filterValues.selectedDateOption) * -1;
        const endPeriodChange = parseInt(this.filterValues.selectedDateOption) - 1;
        this.setDate(new Date(this.filterValues.deliveryDate.start), startPeriodChange, endPeriodChange);
      }
    },
    goNextDate () {
      if (this.filterValues.selectedDateOption === 'month') {
        const startPeriodChange = 1;
        const endPeriodChange = 1;
        this.setMonth(new Date(this.filterValues.deliveryDate.start), startPeriodChange, endPeriodChange);
      } else {
        const startPeriodChange = parseInt(this.filterValues.selectedDateOption);
        const endPeriodChange = parseInt(this.filterValues.selectedDateOption) - 1;
        this.setDate(new Date(this.filterValues.deliveryDate.start), startPeriodChange, endPeriodChange);
      }
    },
    setMonth (startDate, startPeriodChange, endPeriodChange) {
      const endDate = new Date(startDate);
      startDate.setDate(1);
      endDate.setDate(1);
      startDate.setMonth(startDate.getMonth() + startPeriodChange);
      endDate.setMonth(startDate.getMonth() + endPeriodChange);
      this.filterValues.deliveryDate.start = startDate.toISOString().split('T')[0];
      this.filterValues.deliveryDate.end = endDate.toISOString().split('T')[0];
      this.getTableData();
    },
    setDate (startDate, startPeriodChange, endPeriodChange) {
      const endDate = new Date(startDate);
      startDate.setDate(startDate.getDate() + startPeriodChange);
      endDate.setDate(startDate.getDate() + endPeriodChange);
      this.filterValues.deliveryDate.start = startDate.toISOString().split('T')[0];
      this.filterValues.deliveryDate.end = endDate.toISOString().split('T')[0];
      this.getTableData();
    },
    productTypeNames (el) {
      const res = [];
      let productType = null;
      el.forEach(cargoProduct => {
        productType = this.BasicHelper.getConfigValue('product_types', 'id', cargoProduct.product_type_id, 'name', this.$i18n.locale);
        if (!res.includes(productType)) {
          res.push(this.BasicHelper.getConfigValue('product_types', 'id', cargoProduct.product_type_id, 'name', this.$i18n.locale));
        }
      });
      if (res.length === 0) {
        res.push('-');
      }
      return res.join(', ');
    },
    resolveSalesOrderTemplateQrUrl (el) {
      const routeData = this.$router.resolve({ name: 'menu.warehouse.cargos.view-simple', params: { cargocode: el.localcode } });
      return routeData.href;
    },
    printDeviceIds (el) {
      const ids = [];
      if (el.order && el.order.warehouse_id) {
        const workstationIds = this.$store.getters.getParamFromConfig(
          'warehouses',
          el.order.warehouse_id,
          'workstation_ids',
          this.$i18n.locale
        );

        if (workstationIds) {
          const deviceIds = this.$store.getters.getConfigOptions(
            'workstations',
            this.$i18n.locale,
            'id',
            workstationIds,
            false,
            false,
            true,
            ['device_ids']
          );
          deviceIds.forEach(el => {
            el.device_ids.forEach(deviceId => {
              ids.push(deviceId);
            });
          });
        }
      }
      // to make ids unique
      return [...new Set(ids)];
    },
    displayEditAction (el) {
      let result = true;
      if (this.currentUser != null && 'roles' in this.currentUser) {
        const vm = this;
        this.currentUser.roles.forEach(function callback (value, index) {
          const findRoleName = vm.BasicHelper.getConfigValue('roles', 'id', value, 'name');
          if (findRoleName == 'car-warehouse') {
            const findStatusCode = vm.BasicHelper.getConfigValue('cargo_statuses', 'id', el.status_id, 'code')
            if (findStatusCode == 'LOADED') {
              result = false;
            }
          }
        })
      }
      return result;
    },
    getTableData () {
      if (this.basePathComp) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        if (this.vendorId != null) this.filterValues.vendorId = this.vendorId;
        if (this.transport_provider_id) {
          this.filterValues.transport_provider_id = this.transport_provider_id;
        } else {
          this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        }
        this.loading = true;
        const usedFilters = this.BasicHelper.cloneObject(this.filterValues)
        if (this.ordertypes && usedFilters.ordercargotype.length < 1) {
          usedFilters.ordercargotype = this.ordertypes.split(';');
        }
        let path = this.basePathComp + '/list?perPage=' + this.paging.limit + '&page=' + this.paging.page;
        path = this.ApiRequest.addGetParamsToPath(path, usedFilters, true);
        if (this.tableSorting.item) {
          path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
        }
        this.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.tableItems = response.data.data != undefined ? response.data.data : response.data;
            if (response.data.saleManagerUsers != undefined) {
              this.allAvailableSalesManagerUsers = response.data.saleManagerUsers
            }
            if (response.data.wareHouseEditorUsers != undefined) {
              this.allAvailableWareHouseEditorUsers = response.data.wareHouseEditorUsers
            }
            this.paging = response.paging;
            this.loading = false;
          }
        });
      }
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(this.defaultFilterValues);
      this.getTableData();
    },
    editItem (el, newtab = 0) {
      const routeParams = {
        cargocode: (el ? el.localcode : 'NEW')
      }
      if (!el) {
        routeParams.cargotype = this.ordertypes;
      }
      if (newtab) {
        const routeData = this.$router.resolve({ name: 'menu.warehouse.cargos.' + ((this.cargoView || !this.canViewFull) ? 'view-simple' : 'view'), params: routeParams });
        window.open(routeData.href, '_blank');
      } else {
        this.$router.push({ name: 'menu.warehouse.cargos.' + ((this.cargoView || !this.canViewFull) ? 'view-simple' : 'view'), params: routeParams });
      }
    },
    viewItem (el) {
      if (this.cargoView || !this.canViewFull) {
        this.$refs.itemViewCargoFrm.show(el, true);
      } else {
        this.$refs.itemViewFrm.show(el, true);
      }
    },
    markArrivedItem (el) {
      this.changeStatus(el, 'LOADING', true);
    },
    markLoadingItem (el) {
      if (this.canMarkLoading(el)) {
        this.changeStatus(el, 'LOADING', true);
      }
    },
    resumeLoading (el) {
      if (this.canResumeLoading(el)) {
        if (el.loaded_by_user_id != null && el.loaded_by_user_id != this.$store.state.user.id) {
          this.$refs.itemOvertakeMsgBox.show(el, this.$t(this.baseTransParam + '.actions.overtakeTitle'), this.$t(this.baseTransParam + '.actions.overtakeMessage'))
        } else {
          this.changeStatus(el, 'LOADING', true);
        }
      }
    },
    resumeItem (el) {
      if (el.assembler && el.assembled_by_user_id != this.$store.state.user.id) {
        this.$refs.itemOvertakeMsgBox.show(el, this.$t(this.baseTransParam + '.actions.overtakeTitle'), this.$t(this.baseTransParam + '.actions.overtakeMessage'))
      } else {
        this.changeStatus(el, 'LOADING', true);
      }
    },
    resumeAssemble (el) {
      if (this.canResumeAssemble(el)) {
        if (el.assembled_by_user_id != null && el.assembled_by_user_id != this.$store.state.user.id) {
          this.$refs.itemOvertakeSaleMsgBox.show(el, this.$t(this.baseTransParam + '.actions.overtakeAssemblyTitle'), this.$t(this.baseTransParam + '.actions.overtakeAssemblyMessage'))
        } else {
          this.changeStatus(el, 'ASSEMBLY', true);
        }
      } else if ((el && !el.isIncoming && this.statusIdsAssembling.find(statusId => statusId == el.status_id))) {
        if (el.assembler && el.assembled_by_user_id != this.$store.state.user.id) {
          this.$refs.itemOvertakeSaleMsgBox.show(el, this.$t(this.baseTransParam + '.actions.overtakeTitle'), this.$t(this.baseTransParam + '.actions.overtakeMessage'))
        } else {
          this.changeStatus(el, 'ASSEMBLY', true);
        }
      }
    },
    resumePlannedToAssembly (el) {
      if (this.canResumePlanned(el)) {
        this.changeStatus(el, 'ASSEMBLY', true);
      }
    },
    cancelLoading (el) {
      if (this.canResumeLoading(el)) {
        this.changeStatus(el, 'ASSEMBLED', false);
      }
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    },
    getStatusColor (statusId) {
      const status = this.statusOptions.find(el => el.id == statusId);
      if (status) {
        return status.color;
      }
      return '#FFF';
    },
    canRestoreOrder (el) {
      return (el && el.status_id == this.statusIdCanceled);
    },
    canMarkArrived (el) {
      return (el && el.isIncoming && this.statusIdsCanArrive.find(statusId => statusId == el.status_id) && el.ordertypeCode !== 'IMP-R');
    },
    canMarkLoading (el) {
      return (el && !el.isIncoming && this.statusIdsAssembled.find(statusId => statusId == el.status_id));
    },
    canResumeLoading (el) {
      return (el && !el.isIncoming && this.statusIdsLoading.find(statusId => statusId == el.status_id));
    },
    canResumeOrder (el) {
      return (el && el.isIncoming && this.statusIdsLoading.find(statusId => statusId == el.status_id));
    },
    canResumeMarking (el) {
      return (el && el.isIncoming && this.statusIdsMarking.find(statusId => statusId == el.status_id));
    },
    canResumeAssemble (el) {
      return (el && !el.isIncoming && this.statusIdsAssembling.find(statusId => statusId == el.status_id));
    },
    canResumePlanned (el) {
      return (el && !el.isIncoming && this.statusIdsCanArrive.find(statusId => statusId == el.status_id));
    },
    cancelItem (el, approved) {
      if (approved) {
        this.changeStatus(el, 'CANCELED');
      } else {
        this.$refs.itemCancelMsgBox.show(el, this.$t(this.baseTransParam + '.actions.cancelTitle'), this.$t(this.baseTransParam + '.actions.cancelMessage', { localcode: el.localcode }))
      }
    },
    restoreItem (el) {
      this.changeStatus(el, 'TEMPLATE');
    },
    changeStatus (el, status, open = false) {
      this.loading = true;
      this.ApiRequest.request(this.basePathComp + '/change-status/' + el.id, this.ApiRequest.REQUEST_POST, { tostatus: status }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        } else {
          if (open) {
            this.editItem(el);
          }
          this.getTableData();
        }
      });
    },
    getStatusName (statusId) {
      return this.BasicHelper.getConfigValue('cargo_statuses', 'id', statusId, 'name');
    },
    setDefaultFilter () {
      if (this.baseTransParam == 'purchase-order' && this.filterValues.status.length == 0) {
        this.filterValues.status = ['PLANNED', 'LOADING', 'MARKING', 'LOADED'];
      }
      if (this.baseTransParam == 'sales-order' && this.filterValues.status.length == 0) {
        this.filterValues.status = ['PLANNED', 'ASSEMBLY', 'ASSEMBLED', 'LOADING', 'LOADED'];
      }
      if (this.baseTransParam !== 'cargo') {
        if (!this.filterValues.deliveryDate.start) {
          this.goToday();
        }
      }
    },
    printLabelForm (el) {
      this.$refs.printForm.show(null, this.basePathComp + '/print-label/' + el.id, this.printDeviceIds(el), this.resolveSalesOrderTemplateQrUrl(el));
    },
    getD365StatusText (el) {
      if (el.bookedAt) {
        return this.$t('cargo.bookedAt');
      } else if (el.d365ReceivedAt) {
        return this.$t('cargo.d365ReceivedAt');
      } else if (el.d365LastUpdatedAt) {
        return this.$t('cargo.d365LastUpdatedAt');
      }
      return this.$t('cargo.notInD365')
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.cloneObject(this.defaultFilterValues);
    if (!this.transport_provider_id) {
      this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    }
    this.setDefaultFilter();
    this.filterValues.cargoStatuses = this.cargoView;
    this.getTableData();
    this.isMounted = true;
  },
  watch: {
    '$route' () {
      this.isMounted = false;
      this.$forceUpdate();
      if (this.$route.meta.cargoView) {
        this.cargoView = true;
      } else {
        this.cargoView = false;
      }
      this.filterValues = this.BasicHelper.cloneObject(this.defaultFilterValues);
      if (!this.transport_provider_id) {
        this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
      }
      this.setDefaultFilter();
      this.filterValues.cargoStatuses = this.cargoView;
      this.$forceUpdate();
      this.getTableData();
      this.isMounted = true;
    }
  }
}
