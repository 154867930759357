<template>
  <div class="shift-recipe">
    <ShiftEdit ref="shiftedit" :isModal="false" @saved="shiftDataUpdated" :shiftFlowProducedReadOnly="false" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import ShiftEdit from '@/components/pet/shift/edit/ShiftEdit.vue'

export default {
  name: 'ShiftEmployees',
  data () {
    return {
    }
  },
  components: {
    ShiftEdit
  },
  computed: {
    ...mapState({
      currentShift: state => state.pet.currentShift,
      currentFlowId: state => state.pet.currentFlowId
    }),
    shiftId () {
      return this.currentShift?.id;
    }
  },
  methods: {
    shiftDataUpdated (val) {
      this.$store.dispatch('changeShift', { filters: { currentShift: { id: val.id } } });
    }
  },
  watch: {
    currentShift: {
      immediate: true,
      handler (val) {
        if (this.$refs.shiftedit && val && (!this.$refs.shiftedit.editEl || this.$refs.shiftedit.editEl.id != val.id)) {
          this.$refs.shiftedit.show(val, val.id, true);
        }
      }
    },
    currentFlowId: {
      immediate: true,
      handler () {
      }
    }
  },
  mounted () {
    this.$refs.shiftedit.show(this.currentShift, this.currentShift.id, true);
  }
}
</script>
