import useVuelidate from '@vuelidate/core';
import { required, requiredIf } from '@vuelidate/validators';
import PartnerCheck from '@/components/pet/partners/check/PartnerCheck.vue'
import LoadingAddressesEdit from '@/components/pet/partners/editloadingaddresses/LoadingAddressesEdit.vue'
import MaterialEdit from '@/components/pet/partners/vendors/editmaterial/MaterialEdit.vue'
import LogList from 'ebg-vue-common/src/components/log/list/LogList.vue'
import ProductRateList from '@/components/pet/partners/rates/list/ProductRateList.vue'
import OrderList from '@/components/pet/sales/order/list/OrderList.vue'
import LinkToDifferentSystem from 'ebg-vue-common/src/components/common/LinkToDifferentSystem.vue'
import PartyAddressList from 'ebg-vue-common/src/components/d365/party/address/list/PartyAddressList.vue'
import UserPicker from 'ebg-vue-common/src/components/user/picker/UserPicker.vue'
import Comments from 'ebg-vue-common/src/components/common/comments/Comments.vue';
import PetHelper from '@/helpers/PetHelper';
import ModelFileList from '@/components/modelfile/list/ModelFileList.vue'
import Characterization from '@/components/pet/characterizations/Characterization.vue';
import Gallery from 'ebg-vue-common/src/components/common/Gallery/Gallery.vue';
import PartnerMerger from '@/components/pet/partners/merge/PartnerMerger.vue';

const defaultForm = {
  id: null,
  isActual: true,
  primaryContactName: '',
  deliveryType_id: null,
  products: [],
  product_types: [],
  loading_addresses: [],
  approvalUser: {},
  isTransportProvider: false,
  isProductProvider: false,
  ax2009code: '',
  partner_type_id: null,
  name: '',
  isIntercompany: false,
  orgNumberOrPersonCode: '',
  taxExemptNumber: '',
  code: '',
  legalAddress: '',
  primaryContactPhone: '',
  primaryContactEmail: '',
  locale_id: null,
  sales_manager: null,
  payment_term_id: null,
  documentParameters: [],
  modelDocuments: [],
  country: '',
  currency_id: null
}

export default {
  name: 'VendorEdit',
  data () {
    return {
      isMounted: false,
      editEl: this.BasicHelper.cloneObject(defaultForm),
      loading: false,
      loadingTab: false,
      saveButtonVisible: true,
      checkedProducts: [],
      vendorId: null,
      vendorCode: null,
      currentReq: null,
      commentCount: 0
    }
  },
  validations: {
    editEl: {
      name: { required },
      currency_id: { required },
      orgNumberOrPersonCode: { required },
      code: {
        required: requiredIf(function () {
          return !this.isPotencialPartner
        })
      },
      primaryContactName: { },
      taxExemptNumber: { }
    }
  },
  components: {
    PartnerMerger,
    Characterization,
    UserPicker,
    OrderList,
    PartnerCheck,
    LoadingAddressesEdit,
    MaterialEdit,
    LogList,
    ModelFileList,
    ProductRateList,
    LinkToDifferentSystem,
    PartyAddressList,
    Comments,
    Gallery
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    controlClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    d365Partners () {
      return this.$store.getters.isFeatureEnabled('d365_api', 'partners.sync')
    },
    canEditAll () {
      return this.canEdit && !this.d365Partners;
    },
    basePerm () {
      return 'vendors';
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission('edit-' + this.basePerm);
    },
    productTypes () {
      return this.$store.getters.getConfigOptions('product_types', this.$i18n.locale);
    },
    displayD365Balance () {
      return this.$store.getters.isFeatureEnabled('d365_api', 'sales.book');
    },
    countryOptions () {
      return this.$store.getters.getCountryOptions();
    },
    partnerPotencialTypeId () {
      return this.BasicHelper.getConfigValue('partner_types', 'code', 'POTENTIAL', 'id')
    },
    partnerExistingTypeId () {
      return this.BasicHelper.getConfigValue('partner_types', 'code', 'EXISTING', 'id')
    },
    partnerTypes () {
      if (this.editEl) {
        return this.$store.getters.getConfigOptions('partner_types', this.$i18n.locale)
      }
      return [];
    },
    isPotencialPartner () {
      if (this.editEl.partner_type_id == this.partnerPotencialTypeId) {
        return true
      }
      return false
    },
    canEditPotencial () {
      return this.canEdit && this.isPotencialPartner
    },
    deliveryTypeOptions () {
      return this.$store.getters.getDeliveryTypeOptions(
        this.$i18n.locale,
        this.BasicHelper.getConfigValue('order_cargo_types', 'code', 'IMP', 'id')
      );
    },
    getPaymentTerms () {
      return this.$store.getters.getConfigOptions('payment_terms', this.$i18n.locale);
    },
    localesOptions () {
      return this.$store.getters.getConfigOptions('locales', this.$i18n.locale)
    },
    basePathComp () {
      return 'vendors'
    },
    baseTransParam () {
      return 'partners.vendors';
    },
    getTitle () {
      if (this.$route.params.code) {
        if (this.editEl.name) {
          return this.$t(this.baseTransParam + '.notModalTitle') + this.editEl.name;
        } else {
          return this.$t(this.baseTransParam + '.notModalTitle');
        }
      }
      if (this.editEl.id) {
        return this.$t(this.baseTransParam + '.actions.edit');
      }
      return '';
    },
    tabOptions () {
      let tabs = [
        {
          code: 'products',
          text: this.$t('partners.vendors.tabs.products.title'),
          addNew: true
        },
        {
          code: 'ratesTab',
          text: this.$t('partners.vendors.tabs.ratesTab.title'),
          addNew: false
        },
        {
          code: 'purchaseList',
          text: this.$t('partners.vendors.tabs.purchaseList.title'),
          addNew: false
        },
        {
          code: 'characterization',
          text: this.$t('classifiers.characterizations.name'),
          addNew: false
        },
        {
          code: 'files',
          text: this.$t('partners.vendors.tabs.files.title'),
          addNew: false
        },
        {
          code: 'logList',
          text: this.$t('partners.vendors.tabs.logList.title'),
          addNew: false
        },
        {
          code: 'comments',
          text: this.$t('partners.vendors.tabs.comments.title'),
          addNew: false
        }
      ]
      if (this.d365Partners) {
        tabs.unshift({
          code: 'd365_objects',
          text: this.$t('partners.vendors.tabs.loading_addresses.title'),
          addNew: false
        })
      } else {
        tabs.unshift({
          code: 'loading_addresses',
          text: this.$t('partners.vendors.tabs.loading_addresses.title'),
          addNew: !this.d365Partners
        })
      }
      if (this.isPotencialPartner) {
        tabs = tabs.filter(el => !['d365_objects', 'purchaseList'].includes(el.code))
      }
      return tabs;
    },
    tableHeaders () {
      const headers = {
        loading_addresses: [
          { key: 'name.' + this.$i18n.locale, label: this.$t(this.baseTransParam + '.loadingNameAddresses'), classTd: 'w-20' },
          { key: 'address', label: this.$t(this.baseTransParam + '.loadingAddresses'), classTd: 'w-50' },
          { key: 'active', label: this.$t(this.baseTransParam + '.loadingActive'), classTd: 'w-5', format: 'formatYesNo' }
        ],
        products: [
          { key: 'productNameLv', label: this.$t(this.baseTransParam + '.materialLv'), type: 'slot', slotcode: 'productNameLv' },
          { key: 'productNameEn', label: this.$t(this.baseTransParam + '.materialEn'), type: 'slot', slotcode: 'productNameEn' },
          { key: 'customerName', label: this.$t(this.baseTransParam + '.customerName') },
          { key: 'screening_interval', label: this.$t(this.baseTransParam + '.products.screeningInterval') },
          { key: 'ax2009code', label: this.$t('product.ax2009code'), type: 'slot', slotcode: 'ax2009code' },
          { key: 'd365code', label: this.$t('product.d365_product_id'), type: 'slot', slotcode: 'd365code' },
          { key: 'quality_id', label: this.$t(this.baseTransParam + '.quality'), classTd: 'w-30', type: 'slot', slotcode: 'quality' }
        ],
        ratesTab: [
          { key: 'product_id', label: this.$t(this.baseTransParam + '.material'), classTd: 'w-20', format: 'formatFromConfig', configKey: 'products', configParam: 'name' },
          { key: 'base_rate.rate', label: this.$t(this.baseTransParam + '.baseRate', { currency: PetHelper.getCurrencyNameFromCode(this.currencyCode) }), classTd: 'w-10 text-nowrap' },
          { key: 'rate.rate', label: this.$t(this.baseTransParam + '.rate', { currency: PetHelper.getCurrencyNameFromCode(this.currencyCode) }), classTd: 'w-10 text-nowrap' },
          { key: 'rate.measure_type_id', label: this.$t(this.baseTransParam + '.measureType'), classTd: 'w-1', format: 'formatFromConfig', configKey: 'measure_types', configParam: 'name' },
          { key: 'rate.isSale', label: this.$t(this.baseTransParam + '.isSale'), classTd: 'w-5', format: 'formatYesNo' }
        ]
      };
      if (this.canEdit) {
        if (this.canEditAll) {
          headers.loading_addresses.push(
            {
              key: 'actions',
              label: '',
              actions: [
                { label: this.$t(this.baseTransParam + '.loadingAddressesEdit'), emit: 'editItem', icon: 'edit', bicon: 'pencil-fill', class: 'btn btn-primary btn-sm' },
                { label: this.$t('partners.vendors.actions.loadingAddressNotActive'), emit: 'setLoadingAddressNotActive', bicon: 'trash', class: 'btn btn-danger btn-sm', ifconfig: { param: 'active', value: 0, notCheck: true } }
              ]
            }
          );
        }
        headers.products.push(
          {
            key: 'actions',
            label: '',
            actions: [
              { label: this.$t(this.baseTransParam + '.products.actions.edit'), emit: 'editItem', icon: 'edit', bicon: 'pencil-fill', class: 'btn btn-primary btn-sm' },
              { label: this.$t(this.baseTransParam + '.products.actions.delete'), emit: 'setMaterialDeleted', bicon: 'trash', class: 'btn btn-danger btn-sm', ifconfig: { param: 'active', value: 0, notCheck: true } }
            ]
          }
        );
      }
      return headers;
    },
    tableExpandConfig () {
      return {
        loading_addresses: null,
        products: null
      };
    },
    tableActions () {
      return {
        loading_addresses: {},
        products: {}
      };
    },
    filterValues () {
      return {
        loading_addresses: {
          vendor_id: this.vendorId
        }
      };
    },
    tableFilterConfig () {
      return {
        loading_addresses: {}
      };
    },
    routeParamsView () {
      return {
        company: process.env.VUE_APP_D365_AREAID,
        code: this.editEl?.partycode
      }
    },
    currencyCode () {
      let currency = null;
      if (this.editEl && this.editEl.currency_id) {
        currency = this.$store.getters.getDataFromConfigById('currencies', this.editEl.currency_id).code;
      }
      return currency;
    },
    currencyOptions () {
      return this.BasicHelper.sortArrayByKeyValue(
        this.$store.getters.getConfigOptions(
          'currencies',
          this.$i18n.locale,
          null,
          null,
          false,
          false,
          false,
          ['symbol']
        ).map((row) => {
          let text = row.code + ' - ' + row.text;
          if (row.symbol) {
            text = row.code + ', ' + row.symbol + ' - ' + row.text;
          }
          return {
            ...row,
            value: row.value,
            text: text
          }
        })
      );
    },
    partnerPhotoFiles () {
      const files = [];
      if (this.editEl.files) {
        this.editEl.files.forEach(file => {
          if (file.model_attachment?.code === 'Partner_gallery') {
            files.push(file);
          }
        })
      }
      return files;
    },
    partnerGalleryModelAttachmentId () {
      return this.$store.getters.getDataFromConfigWithFilter('model_attachments', 'code', 'Partner_gallery')?.id;
    },
    filesPath () {
      return `vendors/files/${this.editEl?.id}`;
    }
  },
  methods: {
    show (elData) {
      this.checkedProducts = [];
      if (elData) {
        this.editEl = this.BasicHelper.cloneObject(elData);
        this.editEl.product_types.forEach((item) => {
          this.checkedProducts.push(item.product_type_id);
        });
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultForm);
      }
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        if (this.$refs.modelfiles) {
          this.$refs.modelfiles.saveFiles(false);
        }
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultForm)) {
          reqData[key] = this.editEl[key];
        }
        reqData.products = this.checkedProducts;
        if (this.$refs.modelfiles) {
          reqData.documentParameters = this.$refs.modelfiles.getDocumentparams();
        }

        this.ApiRequest.request('vendors/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            if (!reqData.id && !this.isModal) {
              this.$router.push({ name: 'menu.partners.vendors.view', params: { code: response.data.code } });
            } else {
              this.editEl.product_types = response.data.product_types;
              this.$emit('saved', response.data);
              this.close();
            }
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    },
    getUserOptionText (item) {
      return (item ? item.fullname : '');
    },
    getQualityBadgeColors (qualityId) {
      return this.$store.getters.getQualityBadgeColors(qualityId);
    },
    setComponentData (code, updateEditEl = true) {
      if (code == 'NEW') {
        this.editEl = this.BasicHelper.cloneObject(defaultForm);
        this.v$.$reset();
      } else if (code) {
        this.vendorCode = code;
        if (updateEditEl) {
          this.saveButtonVisible = false;
          this.loading = true;
        } else {
          this.loadingTab = true;
        }
        this.ApiRequest.request('vendors/view-by-code/' + code, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else if (response.data) {
            this.checkedProducts = [];
            if (updateEditEl) {
              this.editEl = this.BasicHelper.cloneObject(response.data);
              this.saveButtonVisible = true;
            }
            this.editEl.product_types.forEach((item) => {
              this.checkedProducts.push(item.product_type_id);
            });
            this.vendorId = this.editEl.id;
          } else {
            this.editEl = this.BasicHelper.cloneObject(defaultForm);
          }
          if (updateEditEl) {
            this.$forceUpdate();
            this.loading = false;
          } else {
            this.loadingTab = false;
          }
          this.v$.$reset();
        });
      }
      if (this.$route.query.isPotencial != undefined && this.$route.query.isPotencial == 1) {
        if (this.editEl.partner_type_id == null) {
          this.editEl.partner_type_id = this.partnerPotencialTypeId
        }
      } else {
        if (this.editEl.partner_type_id == null) {
          this.editEl.partner_type_id = this.partnerExistingTypeId
        }
      }
    },
    getTableData (update = false) {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.setComponentData(this.vendorCode, update);
    },
    updateCommentCount (val) {
      this.commentCount = val;
    },
    addNewRecord (tabKey) {
      this.editItem(null, tabKey);
    },
    editItem (data, key) {
      if (key == 'loading_addresses') {
        this.$refs['edit-' + key].show(data, this.vendorId, null);
      } else {
        this.$refs['edit-' + key].show(data, this.vendorId, this.editEl);
      }
    },
    editRate (el, productId) {
      this.$refs['edit-ratesTab'].show(el, productId, this.editEl.id);
    },
    setLoadingAddressNotActive (el) {
      this.loadingTab = true;
      this.ApiRequest.request('partners/address/notactual', this.ApiRequest.REQUEST_POST, { id: el.id }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else {
          this.updateTabItem('loading_addresses', response.data);
        }
        this.loadingTab = false;
      });
    },
    setMaterialDeleted (el, approved) {
      if (approved) {
        this.loadingTab = true;
        this.ApiRequest.request(this.basePathComp + '/setmaterialdisabled', this.ApiRequest.REQUEST_POST, { id: el.id, deleted: 1 }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.updateTabItem('products', el, true);
          }
          this.loadingTab = false;
        });
      } else {
        this.$refs.materialDeleteMsgBox.show(el, this.$t(this.baseTransParam + '.actions.deleteTitle'), this.$t(this.baseTransParam + '.actions.deleteMessage', { material: this.BasicHelper.getTranslation(el.name), code: el.code }))
      }
    },
    updateTabItem (key, item, remove = false) {
      if (key == 'products') {
        if (this.$refs.productRateList) {
          this.$refs.productRateList.getTableData();
        }
      }
      const itemIdx = this.editEl[key].findIndex(el => el.id == item.id);
      if (itemIdx > -1) {
        if (remove) {
          this.editEl[key].splice(itemIdx, 1);
        } else {
          Object.keys(item).forEach(param => {
            this.editEl[key][itemIdx][param] = item[param];
          });
        }
      } else if (!remove) {
        this.editEl[key].push(item);
      }
      this.$refs.tableview.$forceUpdate();
    },
    scrollToElement (element) {
      setTimeout(() => {
        const el = this.$refs[element];
        if (el) el.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      }, 2500);
    },
    filesUpdated (files = null) {
      this.$emit('filesUpdated');
      if (files) {
        this.editEl.files = files;
      }
    }
  },
  mounted () {
    this.isMounted = true;
    this.setComponentData(this.$route.params.code);
    if (this.$route.query.scrollTo !== null) {
      this.scrollToElement(this.$route.query.scrollTo);
    }
    this.$store.dispatch('loadDynamicConfig', { type: 'Countries' });
  },
  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'VendorEdit' }) }
  },
  watch: {
    '$route' () {
      if (!this.isModal && this.$route.params.code) {
        this.setComponentData(this.$route.params.code);
      }
    }
  }
}
