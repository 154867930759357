const defaultFilterValues = {
  quantity: null,
  shift_startAt: null,
  shift_type: []
}

export default {
  name: 'AssemblyApprovalList',
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { item: 'created_at', direction: 'desc' },
      paging: { limit: 20, page: 1, total: 0, pages: 0 }
    }
  },
  props: {
  },
  components: {
  },
  computed: {
    tableHeaders () {
      const headers = [
        { key: 'fromshift.shortname', label: this.$t('assembly.approval.fromshift.shift') },
        { key: 'from_shift_recipe_items_sum_quantity', label: this.$tc('assembly.approval.fromshift.quantity'), format: 'formatDecimal', precision: 0 },
        { key: 'from_shift_fed_sum_quantity', label: this.$tc('assembly.approval.fromshift.fed'), format: 'formatDecimal', precision: 0 },
        { key: 'shift.shortname', label: this.$t('assembly.approval.shift') },
        { key: 'product_type_id', label: this.$t('product.type_id'), type: 'slot', slotcode: 'product_type' },
        { key: 'product_id', label: this.$t('product.name'), format: 'formatFromConfig', configKey: 'products', configParam: 'name' },
        { key: 'quantity', label: this.$t('assembly.approval.quantity'), format: 'formatDecimal' },
        { key: 'bales', label: this.$t('assembly.approval.bales'), format: 'formatDecimal' },
        { key: 'approved_at', label: this.$t('assembly.approval.user_id'), type: 'slot', slotcode: 'approved' }
      ];
      headers.push({ key: 'actions', actions: [] });
      return headers;
    },
    tableExpandConfig () {
      return null;
    },
    tableFilterConfig () {
      const filters = { };
      filters.shift_startAt = { type: 'date-range', label: this.$t('assembly.approval.filter.shift_startAt') };
      filters.shift_type = { type: 'checkbox', label: this.$t('assembly.approval.filter.shift_type'), options: this.shiftTypeOptions };
      filters.quantity = { type: 'number', label: this.$t('assembly.approval.filter.quantity') };
      return filters;
    },
    requestPath () {
      let path = this.ApiRequest.addGetParamsToPath('assembly/approvals/list?perPage=' + this.paging.limit + '&page=' + this.paging.page, this.filterValues, true);
      if (this.tableSorting.item) {
        path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
      }
      path = this.ApiRequest.addGetParamsToPath(path, this.filterValues);
      return path;
    },
    shiftTypeOptions () {
      return this.$store.getters.getConfigOptions('shift_types', this.$i18n.locale);
    }
  },
  methods: {
    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.loading = true;
      this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, [], (response) => {
        if (response.data) {
          this.tableItems = response.data;
          this.paging = response.paging;
        }
        this.loading = false;
      }, true);
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    }
  },
  mounted () {
    this.getTableData();
  }
}
