import WarehouseRack from '@/components/pet/warehouse/sku/WarehouseRack.vue';
import FactoryProductPicker from '@/components/pet/factoryproduct/picker/FactoryProductPicker.vue';
import { mapState } from 'vuex';
import QRReaderPicker from '@/components/pet/device/qrreader/picker/QRReaderPicker.vue';
import MoveSKU from '@/components/pet/warehouse/sku/edit/MoveSKU.vue';

export default {
  name: 'WarehouseLayout',
  data () {
    return {
      maxBinsPerRow: 10,
      loading: false,
      selectedRack: 0,
      selectedChunk: null,
      sku: null,
      factoryProductId: null,
      warehouseId: null,
      selectedBin: null,
      selectedBinCode: null,
      fpMap: {},
      binFitmentMap: {}
    }
  },
  validations: {
  },
  components: {
    MoveSKU,
    QRReaderPicker,
    FactoryProductPicker,
    WarehouseRack
  },
  props: {
    displayActions: {
      type: Boolean,
      default: true
    },
    bagWidth: {
      type: Number,
      default: 1
    }
    // maxBinsPerRow: {
    //   type: Number,
    //   default: 9
    // },
    // minBinsPerRow: {
    //   type: Number,
    //   default: 4
    // }
  },
  computed: {
    ...mapState({
      qrReaderDeviceIds: state => [state.pet.currentQRDeviceId]
    }),
    canInteract () {
      return !this.loading;
    },
    rackOptions () {
      const options = [];
      if (this.sku && this.sku.racks.length > 0) {
        this.sku.racks.forEach(function callback (value, index) {
          options.push({ value: index, text: value.rowIndexCode });
        });
      }
      return options;
    },
    factoryPickerFilters () {
      return {
        status: 'WAREHOUSE',
        withRemainingQuantity: 1,
        warehouse_id: this.warehouseId,
        withWareHousePlacesCount: true
      };
    },
    columnOptions () {
      const options = [];
      if (this.sku && this.sku.binsPerLevel > 0) {
        for (let start = 1; start <= this.sku.binsPerLevel; start += this.maxBinsPerRow) {
          const end = Math.min(start + this.maxBinsPerRow - 1, this.sku.binsPerLevel);
          options.push({ value: start + '-' + end, text: start + '...' + end, start: start - 1, end: end - 1 });
        }
        if (!this.selectedChunk) {
          this.selectedChunk = options[0].value;
        }
      }
      return options;
    },
    productStatusSold () {
      return this.$store.getters.getParamFromConfigByFilter('factory_product_statuses', 'code', 'SOLD', 'id');
    },
    canEditFactoryProductLocation () {
      return this.ApiRequest.userHasPermission('edit-factory-product-location');
    }
  },
  methods: {
    selectBin (bin) {
      this.selectedBin = this.BasicHelper.cloneObject(bin);
      this.selectedBinCode = this.selectedBin.code;
      this.$emit('selectBin', bin);
    },
    mapBinFitment (bin) {
      let fits = true;
      if (this.sku && this.sku.racks[bin.rackIdx] && this.sku.racks[bin.rackIdx].levels[bin.levelIdx]) {
        const level = this.sku.racks[bin.rackIdx].levels[bin.levelIdx];
        this.binFitmentMap[bin.id] = 0;
        for (let i = bin.binIdx; i < (this.sku.binsPerLevel - 1); i++) {
          if (fits) {
            if (!level.bins[i] || level.bins[i].factoryProduct || level.bins[i].hasWarning || level.bins[i].isDisabled) {
              fits = false;
            } else {
              this.binFitmentMap[bin.id]++;
            }
          }
        }
      }
    },
    deselectBin () {
      this.selectedBin = null;
      this.selectedBinCode = null;
    },
    binCode (code) {
      let binCode = null;
      if (code) {
        binCode = code.split('-')[1];
      }
      return binCode;
    },
    measureTypeName () {
      let measureTypeName = ' ';
      if (this.selectedBin && this.selectedBin.factoryProduct) {
        measureTypeName += this.BasicHelper.getTranslation(this.selectedBin.factoryProduct.measureType);
      } else {
        measureTypeName += 'kg';
      }
      return measureTypeName;
    },
    factoryProductChanged (factoryProduct) {
      this.selectPageByFactoryProduct(factoryProduct);
      return factoryProduct;
    },
    selectPageByFactoryProduct (factoryProduct) {
      if (factoryProduct) {
        const location = this.fpMap[factoryProduct.id];
        if (location) {
          this.selectedRack = location.selectedRack;
          this.selectedChunk = this.columnOptions[location.selectedChunkId].value;
          this.selectBin(location.bin);
        }
      }
    },
    mapFactoryProductLocation () {
      if (this.sku) {
        this.sku.racks.forEach((rack, rackIdx) => {
          rack.levels.forEach(level => {
            level.bins.forEach((bin, binIdx) => {
              this.mapBinFitment(bin);
              if (bin.factoryProduct) {
                this.columnOptions.forEach((option, optionIdx) => {
                  if (option.start <= binIdx && option.end >= binIdx) {
                    if (!this.fpMap[bin.factoryProduct.id]) {
                      this.fpMap[bin.factoryProduct.id] = { selectedRack: rackIdx, selectedChunkId: optionIdx, bin: bin };
                    }
                  }
                });
              }
            });
          })
        });
      }
    },
    fetchData (currentPath, fp = null) {
      this.warehouseId = currentPath[currentPath.length - 1];
      this.ApiRequest.request('warehouses/' + this.warehouseId + '/stock-keeping-units', this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else if (response.data) {
          this.sku = this.BasicHelper.cloneObject(response.data);
          this.mapFactoryProductLocation();
          if (fp) {
            this.selectPageByFactoryProduct(fp);
          }
        }
        this.loading = false;
      });
    },
    removeBag () {
      if (!this.loading) {
        this.loading = true;
        const reqData = { };
        reqData.id = this.selectedBin.id
        this.ApiRequest.request('warehouses/removeWarehouseRawData', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.selectedChunk = null;
            this.selectedBin = null;
            this.fetchData(this.$route.path.split('/'));
          }
          this.loading = false;
        });
      }
    },
    insertBag () {
      this.generateBin();
      this.$refs.moveSKU.show(false, this.warehouseId, this.selectedBin);
    },
    moveBag () {
      this.generateBin();
      this.$refs.moveSKU.show(true, this.warehouseId, this.selectedBin);
    },
    generateBin () {
      if (!this.selectedBin) {
        this.selectedBin = {
          id: null,
          code: null,
          factoryProduct: {
            id: null,
            code: null,
            product_id: null,
            quality_id: null,
            remainingQuantity: null
          }
        };
      }
    },
    saved (factoryProduct) {
      this.selectedChunk = null;
      this.fpMap = {};
      this.binFitmentMap = {};
      this.loading = true;
      this.fetchData(this.$route.path.split('/'), factoryProduct);
    }
  },
  created () {
    this.selectedChunk = null;
    this.loading = true;
    this.fetchData(this.$route.path.split('/'))
  },
  watch: {
    '$route' () {
      this.fpMap = {};
      this.binFitmentMap = {};
      this.selectedChunk = null;
      this.loading = true;
      this.fetchData(this.$route.path.split('/'))
    }
  }
}
