import { mapState } from 'vuex'

export default {
  name: 'ShiftPicker',
  components: {
  },
  props: {
    fullsize: {
      type: Boolean,
      default: false
    },
    hideFlows: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: false,
      shiftType: null,
      lineSelected: null,
      typeSelected: null,
      shiftStartDate: null
    }
  },
  computed: {
    ...mapState({
      currentShift: state => state.pet.currentShift,
      currentFlowId: state => state.pet.currentFlowId,
      currentUser: state => state.user,
      activeshifts: state => state.config.activeshifts,
      defaultSiteId: state => state.pet.defaultSiteId
    }),
    currentShiftUser () {
      if (this.currentShift && this.currentShift.userroles && this.currentShift.userroles[this.$session.get('userId')]) {
        return this.currentShift.userroles[this.$session.get('userId')];
      }
      return null;
    },
    flows () {
      let flows = this.$store.getters.getItemsFromConfigWithFilter('line_flows', 'line_id', this.currentShift?.line_id);
      if (flows) {
        flows = Object.values(flows).filter((flow) => {
          return flow.deleted === false;
        })
      }
      return flows;
    },

    lines () {
      return this.$store.getters.getConfigOptions('lines', this.$i18n.locale, 'site_id', [parseInt(this.defaultSiteId)], false, false, true, ['color'])
    },

    shifts () {
      return this.$store.getters.userVisibleShifts;
    },
    visibleShifts () {
      if (this.shiftFilters.mustBeInShift) {
        return this.shifts;
      } else {
        return Object.values(this.shifts).filter(el => {
          return ((el.line_id == this.lineSelected) && (el.type_id == this.shiftType));
        });
      }
    },
    typesShift () {
      return (this.$store.state.config.shift_types ? this.$store.state.config.shift_types : []);
    },
    selectedLine () {
      return this.lines.find(el => el.value == this.currentShift?.line_id);
    },
    lineStyle () {
      return 'background:' + (this.selectedLine ? this.selectedLine.color : '#000');
    },
    shiftFilters () {
      return this.$store.getters.userShiftFilters;
    }
  },
  methods: {
    reloadShift () {
      this.changeShift((this.currentShift ? this.currentShift.id : null), null, null, null, false);
    },
    changeShift (shiftId, lineId = null, typeId = null, startdate = null, closeDropdown = true) {
      if (shiftId || (lineId && typeId && startdate)) {
        this.loading = true;
        this.$store.dispatch('changeShift', { filters: { currentShift: { id: shiftId, line_id: lineId, type_id: typeId, startdate: startdate } } })
          .then((res) => {
            if (res && closeDropdown) {
              const item = document.getElementById('dropdown-' + this._uid);
              if (item && closeDropdown) {
                item.click();
              }
            }
            this.loading = false;
          }).catch(error => {
            console.log(error);
            this.loading = false;
          });
      }
    },
    selectFlow (flowId) {
      this.$store.dispatch('changeStateValues', { currentFlowId: flowId })
    },
    selectLine (lineId) {
      this.lineSelected = lineId;
      this.changeShift(null, lineId, this.shiftType, this.shiftStartDate);
    },
    selectShift (id) {
      this.changeShift(id);
    },
    selectShiftByType (typeId) {
      this.shiftType = typeId;
      this.changeShift(null, this.lineSelected, typeId, this.shiftStartDate, false);
    },
    selectShiftByDate (date) {
      if (date) {
        this.shiftStartDate = date;
        this.changeShift(null, this.lineSelected, this.shiftType, date);
      }
    },
    getShiftTitle (shift) {
      return this.PetHelper.getShiftName(shift, false);
    }
  },
  mounted () {
    this.$store.dispatch('validateCurrentShift');
  },
  watch: {
    defaultSiteId: {
      immediate: true,
      handler (val) {
        if (val) {
          this.$store.dispatch('validateCurrentShift', { defaultSiteId: val });
          this.$forceUpdate();
        }
      }
    },
    currentShift: {
      immediate: true,
      handler (val) {
        if (val) {
          this.lineSelected = val.line_id;
          this.shiftStartDate = val.startdate;
          this.shiftType = val.type_id;
        }
      }
    },
    currentFlowId: {
      immediate: true,
      handler () {
      }
    },
    activeshifts: {
      immediate: true,
      handler () {
        this.$store.dispatch('validateCurrentShift');
        this.$forceUpdate();
      }
    }
  }
}
