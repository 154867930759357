<template>
  <div>
    <ProducedList type="isSeparated" />
  </div>
</template>

<script>
import ProducedList from '@/components/pet/manufacture/produced/list/template/List.vue'

export default {
  name: 'SeparatedList',
  components: {
    ProducedList
  }
}
</script>
