import useVuelidate from '@vuelidate/core';
import SalesStatusInfo from '@/components/pet/sales/statusinfo/SalesStatusInfo.vue'
import LogList from 'ebg-vue-common/src/components/log/list/LogList.vue'
import SalesOrderFactoryProducts from '@/components/pet/sales/salesorder/factoryproducts/SalesOrderFactoryProducts.vue'
import Comments from 'ebg-vue-common/src/components/common/comments/Comments.vue';
import ModelFileList from '@/components/modelfile/list/ModelFileList.vue'
import LinkToDifferentSystem from 'ebg-vue-common/src/components/common/LinkToDifferentSystem.vue'
import { helpers, required, requiredIf } from '@vuelidate/validators';
import TransportCostList from '@/components/pet/sales/order/transportCost/list/TransportCostList.vue';
import CargoD365LineList from '@/components/pet/sales/cargo/d365linelist/CargoD365LineList.vue'
import PartnerPicker from '@/components/pet/partners/picker/PartnerPicker.vue'
import SalesOrderLineList from '@/components/pet/sales/salesorder/linelist/SalesOrderLineList.vue'
import D365SalesOrderLines from '@/components/d365/SalesOrder/lines/D365SalesOrderLines.vue'
import D365PurchaseOrderLines from '@/components/d365/PurchaseOrder/lines/D365PurchaseOrderLines.vue'
import SalesOrderRequirements from '@/components/pet/sales/salesorder/requirements/SalesOrderRequirements.vue'
import CargoOrderMove from '@/components/pet/sales/cargo/MoveOrder/CargoOrderMove.vue';
import UserPicker from 'ebg-vue-common/src/components/user/picker/UserPicker.vue'
import CargoBookApprove from '@/components/pet/sales/cargo/CargoBookApprove/CargoBookApprove.vue';
import D365InventoryTransferLines from '@/components/d365/TransferOrder/lines/D365InventoryTransferLines.vue'
import CargoWeightings from '@/components/pet/sales/cargo/weightings/CargoWeightings.vue'
import DevicePrinterCallprint from '@/components/pet/device/printer/callprint/DevicePrinterCallprint.vue';
import ContainerPicker from '@/components/pet/sales/cargo/container/ContainerPicker.vue'
import CargoForceStatusChange from '@/components/pet/sales/cargo/forcestatus/CargoForceStatusChange.vue';

const defaultEl = {
  id: null,
  deliveryDate: null,
  order_cargo_type_id: null,
  delivery_type_id: null,
  cargo_type_id: null,
  transport_provider_id: null,
  transportLicensePlate: '',
  transportTrailerPlate: '',
  transportDriverName: '',
  transportDriverPhone: '',
  container_number: '',
  apuscode: '',
  billinfo: '',
  lines: [],
  requirements: [],
  d365lines: [],
  order_id: null,
  factoryproducts: [],
  transport_costs: [],
  stage_id: null,
  clientcode: null,
  payment_tem_id: null,
  hasAdvance: false,
  advancePercent: null,
  dueDate: null,
  receivedAt: null,
  billdate: null,
  advanceDueDate: null,
  ax2009code: '',
  sendDate: null,
  sales_manager_id: null,
  warehouse_editor_id: null,
  sales_manager: null,
  warehouse_editor: null,
  localbatchcode: null,
  documentParameters: {},
  cargoQualityTestEnabled: false
};

const checkPartner = (value, siblings, vm) => {
  if (vm.isTransfer) {
    return true;
  }
  if (vm.cargoPartner == undefined || vm.cargoPartner == null) return true
  const findProduct = vm.cargoPartner.products.find(el => el.product_id == value)
  if (findProduct == undefined) {
    return false
  } else {
    return true
  }
}

const checkDuplicateCostType = (value, siblings, vm) => {
  const checkIds = []
  let res = true;
  if (value.length > 0) {
    value.forEach(el => {
      if (!res) return;
      if (checkIds.includes(el.chargeCode_id) && el.chargeCode_id != null) {
        res = false
      } else {
        if (el.chargeCode_id != null) checkIds.push(el.chargeCode_id)
      }
    })
  }
  return res;
}

export default {
  name: 'Cargo',
  data () {
    return {
      container: [],
      originalContainerNumber: null,
      mountload: false,
      loading: false,
      cargo: null,
      order: null,
      commentCount: null,
      checkingBills: false,
      responseDataManagers: {},
      checkD365ViewData: {},
      initCargo: null
    }
  },
  validations: {
    cargo: {
      deliveryDate: {
        required: requiredIf(function () {
          return this.cargo.needDeliveryInfo;
        })
      },
      transport_provider_id: {
        required: requiredIf(function () {
          return this.cargo.needTransportInfo;
        })
      },
      transportLicensePlate: {
        required: requiredIf(function () {
          return this.cargo.needDeliveryInfo;
        })
      },
      requirements: {
        $each: helpers.forEach({
          requirement_id: { required }
        })
      },
      lines: {
        $each: helpers.forEach({
          product_id: { required },
          quality_id: { required },
          quantity: { required },
          pieces: { required }
        })
      },
      d365lines: {
        $each: helpers.forEach({
          product_id: {
            required,
            checkPartner
          },
          quality_id: {
            required: requiredIf(function () {
              return this.isIncoming;
            })
          },
          quantity: {
            required: requiredIf(function () {
              return this.isIncoming && this.cargo && this.checkStatusIds.includes(this.cargo.status_id);
            })
          },
          measure_type_id: { required },
          pieces: {
            required: requiredIf(function () {
              return this.isIncoming && this.cargo && this.checkStatusIds.includes(this.cargo.status_id);
            })
          }
        })
      },
      transport_costs: {
        $each: helpers.forEach({
          chargeCode_id: { required }
        }),
        checkDuplicateCostType
      },
      warehouse_editor: { required },
      receivedAt: { }
    }
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    orderContainers: {
      type: Array,
      default: () => []
    },
    orderContainerUsage: {
      type: Array,
      default: () => []
    },
    cargodata: {
      type: Object,
      default: () => {}
    },
    readonly: {
      type: Boolean,
      default: false
    },
    orderdata: {
      type: Object,
      default: () => {}
    },
    simpleview: {
      type: Boolean,
      default: false
    },
    allAvailableSalesManagerUsers: {
      type: Array,
      default: () => []
    },
    allAvailableWareHouseEditorUsers: {
      type: Array,
      default: () => []
    }
  },
  components: {
    DevicePrinterCallprint,
    D365InventoryTransferLines,
    CargoBookApprove,
    UserPicker,
    CargoOrderMove,
    TransportCostList,
    SalesStatusInfo,
    LogList,
    SalesOrderFactoryProducts,
    Comments,
    ModelFileList,
    LinkToDifferentSystem,
    CargoD365LineList,
    PartnerPicker,
    SalesOrderLineList,
    D365SalesOrderLines,
    D365PurchaseOrderLines,
    SalesOrderRequirements,
    CargoWeightings,
    ContainerPicker,
    CargoForceStatusChange
  },
  computed: {
    orderCargoTypeId () {
      let orderCargoTypeId = null;
      if (this.cargo) {
        orderCargoTypeId = this.cargo.order_cargo_type_id;
      }
      return orderCargoTypeId;
    },
    cargoTemplateStatusId () {
      return this.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'code', 'TEMPLATE', 'id')
    },
    cargoLaboratoryStatusId () {
      return this.$store.getters.getParamFromConfigByFilter('cargo_statuses', 'code', 'LABORATORY', 'id')
    },
    selectedOrdertype () {
      return this.$store.getters.getDataFromConfigWithFilter('order_cargo_types', 'id', this.orderCargoTypeId);
    },
    isTransferOrder () {
      return this.selectedOrdertype && this.selectedOrdertype.code == 'TNF';
    },
    isImportOrder () {
      return this.selectedOrdertype && this.selectedOrdertype.code == 'IMP';
    },
    isExportOrder () {
      return this.selectedOrdertype && this.selectedOrdertype.code == 'EXP';
    },
    currentOrderId () {
      let currentOrderId = null;
      if (this.cargo && this.cargo.order) {
        currentOrderId = this.cargo.order.id;
      }
      return currentOrderId;
    },
    customerId () {
      let customerId = null;
      if (this.cargo && this.cargo.order) {
        customerId = this.cargo.order.customer_id ?? null;
      }
      return customerId;
    },
    vendorId () {
      let vendorId = null;
      if (this.cargo && this.cargo.order) {
        vendorId = this.cargo.order.vendor_id ?? null;
      }
      return vendorId;
    },
    canMove () {
      let canMove = false;
      if (this.cargo && this.cargo.id) {
        const statusIdCode = this.$store.getters.getDataFromConfigById('cargo_statuses', this.cargo.status_id).code;
        canMove = (statusIdCode !== 'FINISHED');
      }
      return canMove;
    },
    canDelete () {
      let canDelete = true;
      if (this.cargo && this.cargo.status_id) {
        const statusIdCode = this.$store.getters.getDataFromConfigById('cargo_statuses', this.cargo.status_id).code;
        canDelete = (statusIdCode === 'TEMPLATE') || (statusIdCode === 'CANCELED');
      }
      return canDelete;
    },
    checkStatusIds () {
      return this.$store.getters.getConfigOptions('cargo_statuses', this.$i18n.locale).filter(el => ['LOADED', 'FINISHED'].includes(el.code)).map(el => el.value);
    },
    qrReaderDeviceIds () {
      return [this.$store.state.pet.currentQRDeviceId]
    },
    isIncoming () {
      if (this.selectedOrdertype) {
        return this.selectedOrdertype.isIncoming;
      }
      return false;
    },
    isIncomingCode () {
      return (this.isIncoming ? 'IN' : 'OUT');
    },
    productOptions () {
      return this.$store.getters.getConfigOptions('products', this.$i18n.locale, null, null, false, false, false, ['sales_manager_id', 'warehouse_editor_id']);
    },
    orderType () {
      if (this.cargo) {
        return this.BasicHelper.getConfigValue('order_cargo_types', 'id', this.cargo.order_cargo_type_id, 'code');
      }
      return null;
    },
    warehouseOptions () {
      return this.$store.getters.getConfigOptions(
        'warehouses',
        this.$i18n.locale,
        null,
        null,
        false,
        false,
        false,
        ['receiveOrders', 'inAxapta', 'isAssembly', 'sendOrders']
      );
    },
    warehouseOptionsLocal () {
      return this.warehouseOptions.filter(el => {
        if (!this.isIncoming) {
          return el.inAxapta && el.sendOrders
        }
        return el.receiveOrders;
      });
    },
    cargoPartner () {
      if (this.order) {
        return (!this.isIncoming ? this.order.customer : this.order.vendor);
      }
      return null;
    },
    d365Partners () {
      return this.$store.getters.isFeatureEnabled('d365_api', 'partners.sync')
    },
    routeParamsView () {
      return {
        company: process.env.VUE_APP_D365_AREAID,
        code: (this.cargoPartner) ? this.cargoPartner.partycode : null
      }
    },
    permbase () {
      return (this.selectedOrdertype ? this.selectedOrdertype.transbase : 'purchase');
    },
    editPerm () {
      return `edit-${this.permbase}`;
    },
    getTitle () {
      if (this.cargo && this.cargo.id) {
        return this.$tc('cargo.actions.edit', {}, this.cargo);
      }
      return this.$t('cargo.actions.add');
    },
    deliveryTypeOptions () {
      return this.$store.getters.getDeliveryTypeOptions(this.$i18n.locale, this.orderCargoTypeId, this.cargo != null ? this.cargo.delivery_type_id : null);
    },
    cargoTypeOptions () {
      return this.$store.getters.getConfigOptions('cargo_types', this.$i18n.locale);
    },
    paymentTermOptions () {
      return this.$store.getters.getConfigOptions('payment_terms', this.$i18n.locale, null, null, false, false, false, ['days']);
    },
    documentParamOptions () {
      if (!this.isIncoming && !this.isTransferOrder) {
        return ['BILL.INFO', 'BILL.ENDINFO'];
      }
      return [];
    },
    hasEditPerm () {
      return this.ApiRequest.userHasPermission(this.editPerm);
    },
    hasDeleteFilesPerm () {
      return this.ApiRequest.userHasPermission('delete-cargo-files');
    },
    canEdit () {
      return !this.readonly && this.cargo && !this.loading && this.hasEditPerm && (!this.cargo.id || this.cargo.canEditProducts);
    },
    canAttachRfids () {
      return !this.readonly && this.cargo && !this.loading && this.hasEditPerm;
    },
    canEditSimple () {
      return !this.readonly && this.order && !this.loading && this.hasEditPerm && !this.cargo.bookedAt;
    },
    canEditPaymentTerms () {
      const statusIdCode = this.$store.getters.getDataFromConfigById('cargo_statuses', this.cargo.status_id).code
      return this.canEditSimple && statusIdCode == 'FINISHED';
    },
    canForceStatusChange () {
      return this.ApiRequest.userHasPermission('force-edit-cargo-status');
    },
    transportProviderFilter () {
      return { isActual: 1, order: 'name,ASC' };
    },
    deliverytype () {
      if (this.cargo && this.cargo.delivery_type_id) {
        return this.$store.getters.getDataFromConfigById('delivery_types', this.cargo.delivery_type_id);
      }
      return null;
    },
    statusChangeButtons () {
      if (this.cargo && this.cargo.id && this.cargo.toStatusCodes && this.canEditSimple) {
        return this.PetHelper.getCargoStatusChangeOptions(
          this.cargo.toStatusCodes,
          !this.isIncoming,
          this.order.products.length,
          this.cargo.lines.length,
          this.cargo.localcode,
          false,
          this.cargo.status_id
        );
      }
      return [];
    },
    allowEditCargoQualityTestEnabled () {
      if ((this.cargo && this.initCargo != null && this.canEdit) ||
          (this.canEdit && ['TEMPLATE', 'PLANNED', 'ASSEMBLY', 'ASSEMBLED', 'LOADING'].includes(this.BasicHelper.getConfigValue('cargo_statuses', 'id', this.cargo.status_id, 'code')))) {
        return true
      }
      return false
    },
    checkboxOption () {
      return { title: 'test1', bicon: 'check' }
    },
    tabOptions () {
      const options = [];
      options.push({
        code: 'transport',
        text: this.$t('cargo.tabs.transport'),
        invalid: (this.v$.cargo.transport_provider_id.$error || this.v$.cargo.transportLicensePlate.$error || this.v$.cargo.warehouse_editor.$error)
      })
      if (this.order && this.order.id) {
        if (!this.isTransferOrder) {
          options.push({ code: 'weightings', text: this.$t('cargo.tabs.weightings') });
        }
        if (!this.isIncoming) {
          options.push({ code: 'lines', text: this.$t('cargo.tabs.lines') });
          options.push({ code: 'factoryproducts', text: this.$t('cargo.tabs.factoryproducts') });
        }
        options.push({ code: 'd365lines', text: this.$t('cargo.tabs.d365lines'), invalid: this.v$.cargo.d365lines.$error });
        options.push({ code: 'files', text: this.$t('cargo.tabs.files') });
        if (!this.isTransferOrder) {
          options.push({ code: 'transportCost', text: this.$t('cargo.tabs.transportCost') });
        }
        options.push({ code: 'comments', text: this.$t('cargo.tabs.comments') });
        if (this.cargo && this.cargo.code) {
          options.push({ code: 'd365', text: this.$t('cargo.tabs.d365') });
        }
      }
      return options;
    },
    rampOptions () {
      return this.$store.getters.getConfigOptions(
        'warehouses_ramps',
        this.$i18n.locale,
        'warehouse_id',
        this.order.local_warehouse_id,
        false,
        false,
        false,
        ['id', 'site_id', 'order']
      ).sort((a, b) => parseFloat(a.order) - parseFloat(b.order));
    },
    customerProdReqIds () {
      const customerProdReqIds = [];
      if (this.order && !this.isTransferOrder && this.order.customer && this.order.customer.product_requirements) {
        this.order.customer.product_requirements.forEach(el => {
          if (this.order.products.find(subEl => subEl.product_id == el.product_id) && customerProdReqIds.indexOf(el.requirement_id)) {
            customerProdReqIds.push(el.requirement_id);
          }
        });
      }
      return customerProdReqIds;
    },
    customerProducts () {
      return (this.order && this.order.customer ? this.order.customer.products : []);
    },
    d365Actions () {
      const actions = [];
      if (!this.loading && this.cargo && this.cargo.id && !this.readonly) {
        if (
          this.isImportOrder &&
          this.ApiRequest.userHasPermission(`edit-${this.permbase}`) &&
          this.cargo.canD365Book &&
          this.cargo.code &&
          this.$store.getters.isFeatureEnabled('d365_api', `${this.permbase}.edit`)
        ) {
          actions.push({ key: 'd365receive', path: 'd365-receive', class: 'btn-warning', bicon: 'bi bi-book-fill', recheck: true })
        }
        if (this.ApiRequest.userHasPermission(`book-${this.permbase}`) &&
          this.cargo.canD365Book &&
          this.$store.getters.isFeatureEnabled('d365_api', `${this.permbase}.book`)
        ) {
          actions.push({ key: 'd365book', path: 'd365-book', class: 'btn-warning', bicon: 'bi bi-book-fill', recheck: true })
        }
        if (this.ApiRequest.userHasPermission(`edit-${this.permbase}`) &&
          this.cargo.canD365Update &&
          this.$store.getters.isFeatureEnabled('d365_api', `${this.permbase}.edit`)
        ) {
          actions.push({ key: 'd365update', path: 'd365-update', class: 'btn-info', bicon: 'bi bi-journal-arrow-up', recheck: false })
        }
      }
      return actions;
    },
    allowedTemplates () {
      return ['BATCH_OUT'];
    },
    currencyCode () {
      let currency = null;
      if (this.cargo && this.cargo.order && this.cargo.order.currency_id) {
        currency = this.$store.getters.getDataFromConfigById('currencies', this.cargo.order.currency_id).code;
      }
      return currency;
    }
  },
  methods: {
    resolveSalesOrderTemplateQrUrl () {
      const routeData = this.$router.resolve({ name: 'menu.warehouse.cargos.view-simple', params: { cargocode: this.cargo.localcode } });
      return routeData.href;
    },
    printDeviceIds () {
      const ids = [];
      if (this.cargo && this.cargo.order && this.cargo.order.warehouse_id) {
        const workstationIds = this.$store.getters.getParamFromConfig(
          'warehouses',
          this.cargo.order.warehouse_id,
          'workstation_ids',
          this.$i18n.locale
        );

        if (workstationIds) {
          const deviceIds = this.$store.getters.getConfigOptions(
            'workstations',
            this.$i18n.locale,
            'id',
            workstationIds,
            false,
            false,
            true,
            ['device_ids']
          );
          deviceIds.forEach(el => {
            el.device_ids.forEach(deviceId => {
              ids.push(deviceId);
            });
          });
        }
      }
      // to make ids unique
      return [...new Set(ids)];
    },
    printLabelForm () {
      this.$refs.printForm.show(null, 'cargo-orders/print-label/' + this.cargo.id, this.printDeviceIds(), this.resolveSalesOrderTemplateQrUrl());
    },
    updateDueDate () {
      this.cargo.dueDate = null
      if (this.cargo.payment_term_id != null && this.cargo.billdate != null) {
        const selectedPaymentTerm = this.paymentTermOptions.find(el => el.value == this.cargo.payment_term_id)
        if (selectedPaymentTerm != undefined) {
          const date = new Date(Date.parse(this.cargo.billdate))
          date.setDate(date.getDate() + selectedPaymentTerm.days)
          this.cargo.dueDate = date
        }
      }
    },
    moveOrder () {
      this.$refs.cargoOrderMove.show();
    },
    getUserOptionText (item) {
      return (item ? item.fullname : '');
    },
    loadcargo (localcode) {
      this.mountload = true;
      this.ApiRequest.request('cargo-orders/view-by-code/' + localcode, this.ApiRequest.REQUEST_GET, { }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.mountload = false;
        } else {
          this.cargo = this.BasicHelper.cloneObject(response.data);
          this.initCargo = this.BasicHelper.cloneObject(this.cargo);
          if (!this.orderdata) {
            this.order = this.BasicHelper.cloneObject(response.data.order);
          }
          if (response.data.availableSalesManagerUsers != undefined && response.data.availableSalesManagerUsers.length > 0) {
            this.responseDataManagers.availableSalesManagerUsers = response.data.availableSalesManagerUsers
          }
          if (response.data.availableWareHouseEditorUsers != undefined && response.data.availableWareHouseEditorUsers.length > 0) {
            this.responseDataManagers.availableWareHouseEditorUsers = response.data.availableWareHouseEditorUsers
          }
          if (this.cargo.container_number) {
            this.container = ['ORIGINAL'];
            this.originalContainerNumber = this.cargo.container_number;
          }
          this.mountload = false;
          this.v$.$reset();
        }
      });
      this.setDefaultCargoManager()
    },
    deleteCargo (el, approved = 0) {
      if (approved) {
        el.deleted = 1;
        this.loading = true;
        if (el.id !== null) {
          this.ApiRequest.request('cargo-orders/deleted', this.ApiRequest.REQUEST_POST, {
            id: el.id,
            deleted: true
          }, (response) => {
            if (response.error) {
              this.ApiRequest.displayErrorDialog(response, this);
            } else {
              this.$emit('cargoDeleted');
            }
          });
        } else {
          this.$emit('cargoDeleted');
        }
        this.loading = false;
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t('general.item.deleteTitle'), this.$t('general.item.deleteMessage', { code: el.localcode ?? this.$t('general.actions.new') }))
      }
    },
    show (elData, readonly = false) {
      this.mountload = true;
      this.commentCount = null;
      if (elData.localcode && elData.localcode != 'NEW') {
        this.loadcargo(elData.localcode);
      } else {
        const newcargo = this.BasicHelper.cloneObject(defaultEl);
        Object.keys(elData).forEach(param => {
          newcargo[param] = elData[param];
        });
        this.cargo = newcargo;
        this.cargo.code = null;
        this.initCargo = this.BasicHelper.cloneObject(this.cargo);
        this.setDefaultCargoManager()
        this.mountload = false;
        this.v$.$reset();
      }
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    save (moveToStatus = null, btnD365Action = null) {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        if (this.$refs.modelfiles) {
          this.$refs.modelfiles.saveFiles(false);
        }
        this.$emit('cargosave');
        this.loading = true;
        this.$forceUpdate();
        if (this.cargo.container_number) {
          this.cargo.container_number = this.cargo.container_number.toUpperCase();
        }
        const reqData = { };
        for (const key of Object.keys(this.cargo)) {
          reqData[key] = this.cargo[key];
        }
        if (this.$refs.modelfiles) {
          reqData.documentParameters = this.$refs.modelfiles.getDocumentparams();
        }
        if (this.$refs.transportCostsList) {
          reqData.transport_costs = this.$refs.transportCostsList.getTransportCosts();
        }
        reqData.move_to_status = moveToStatus;
        this.ApiRequest.request('cargo-orders/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
            this.loading = false;
            this.$forceUpdate();
          } else {
            this.cargo = this.BasicHelper.cloneObject(response.data);
            this.initCargo = this.BasicHelper.cloneObject(this.cargo);
            this.$emit('orderUpdated', response.data.order);
            if (moveToStatus) {
              this.moveToStatus(moveToStatus);
            } else if (btnD365Action) {
              this.loading = false;
              this.doD365Action(btnD365Action, 1, 1);
            } else {
              this.loading = false;
              this.$forceUpdate();
            }
          }
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    },
    transportProviderChanged (el) {
      if (el) {
        this.cargo.transport_provider_id = el.id;
      } else {
        this.cargo.transport_provider_id = null;
      }
    },
    changeStatus (btn, approved = 0) {
      if (approved || !btn.recheck) {
        if (btn.callSave) {
          this.save(btn.tostatus);
        } else {
          this.moveToStatus(btn.tostatus);
        }
      } else {
        if (btn.tostatus === 'LOADED' && this.cargo.status_id === this.cargoLaboratoryStatusId) {
          this.$refs.changeStatusMsgBox.show(
            btn,
            btn.title,
            this.$t('menu.quality.cargo-screening.really-tested')
          )
        } else {
          this.$refs.changeStatusMsgBox.show(
            btn,
            btn.title,
            btn.message
          )
        }
      }
    },
    setDefaultCargoManager () {
      if (this.cargo != null) {
        const allowedManagers = [];
        const orderProducts = this.order.products
        const cargoProducts = !this.isIncoming ? this.cargo.lines : this.cargo.d365lines
        let checkProducts = null
        if (cargoProducts == null || cargoProducts == undefined || cargoProducts.length == 0) {
          checkProducts = orderProducts
        } else {
          checkProducts = cargoProducts
        }
        if (checkProducts != null && checkProducts != undefined && checkProducts.length > 0) {
          checkProducts.forEach(el => {
            const findProduct = this.productOptions.find(prod => prod.value == el.product_id)
            if (findProduct != undefined) {
              if (findProduct.warehouse_editor_id != null && !allowedManagers.includes(findProduct.warehouse_editor_id)) {
                allowedManagers.push(findProduct.warehouse_editor_id)
              }
            }
          })
          if (allowedManagers.length > 0 && this.responseDataManagers.availableWareHouseEditorUsers != undefined) {
            if (allowedManagers.length == 1 && this.cargo.warehouse_editor_id == null) {
              this.cargo.warehouse_editor = this.responseDataManagers.availableWareHouseEditorUsers.filter(el => allowedManagers.includes(el.id))[0]
            }
            this.responseDataManagers.availableWareHouseEditorUsers = this.responseDataManagers.availableWareHouseEditorUsers.filter(el => allowedManagers.includes(el.id))
          }
        }
      }
    },
    moveToStatus (status) {
      this.loading = true;
      this.ApiRequest.request('cargo-orders/change-status/' + this.cargo.id, this.ApiRequest.REQUEST_POST, { tostatus: status }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        } else {
          this.cargo = this.BasicHelper.cloneObject(response.data);
          this.loading = false;
          this.$emit('updateCargoStatus', { status: status, id: this.cargo.id });
          this.$forceUpdate();
        }
      });
    },
    reloadTransportCosts () {
      if (this.$refs.transportCostsList) {
        this.$refs.transportCostsList.getTableData();
      }
    },
    filesUpdated () {
      this.$emit('filesUpdated');
    },
    doD365Action (btn, approved = 0, saved = 0) {
      if (!this.loading) {
        if (approved || !btn.recheck) {
          if (saved) {
            this.loading = true;
            this.$forceUpdate();
            const reqData = this.BasicHelper.cloneObject(this.cargo);
            reqData.fullitem = true;
            this.ApiRequest.request(`cargo-orders/${btn.path}/${this.cargo.id}`, this.ApiRequest.REQUEST_POST, reqData, (response) => {
              if (response.error) {
                this.ApiRequest.displayErrorDialog(response, this);
                this.loading = false;
              } else {
                if (response.data) {
                  this.cargo = this.BasicHelper.cloneObject(response.data);
                }
                this.v$.$reset();
                this.loading = false;
                this.reloadTransportCosts();
                if (this.$refs.d365lines) {
                  this.$refs.d365lines.getTableData();
                }
              }
            });
          } else {
            this.save(null, btn)
          }
        } else {
          if (btn.key == 'd365book' && !this.isTransferOrder) {
            this.$refs.cargoBookApprove.show(btn, this.$t(`${this.permbase}-order.actions.${btn.key}`), `${this.permbase}-order.actions.${btn.key}Message`);
          } else {
            this.$refs.d365ActionMsgBox.show(
              btn,
              this.$t(`${this.permbase}-order.actions.${btn.key}`),
              this.$t(`${this.permbase}-order.actions.${btn.key}Message`)
            )
          }
        }
      }
    },
    commentsChanged (newComments) {
      this.commentCount = newComments;
    },
    doCargoBookAction (btn) {
      this.doD365Action(btn, 1, 1)
    },
    updateTransportCosts () {
      if (this.cargo != null && this.cargo.delivery_type_id != null) {
        const deliveryType = this.deliveryTypeOptions.find(el => el.value == this.cargo.delivery_type_id)
        const cargoTemplateStatusId = this.cargoTemplateStatusId
        if (deliveryType != undefined && this.cargo.status_id == cargoTemplateStatusId) {
          this.cargo.transport_costs = this.$store.getters.updateCargoTransportCosts(this.cargo, deliveryType);
        }
      }
    },
    checkBills () {
      if (!this.checkingBills) {
        this.checkingBills = true;
        this.ApiRequest.request(`cargo-orders/check-bills/${this.cargo.id}`, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
            this.checkingBills = false;
          } else {
            this.loading = true;
            this.cargo = this.BasicHelper.cloneObject(response.data);
            this.v$.$reset();
            this.checkingBills = false;
            this.loading = false;
          }
        });
      }
    }
  },
  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'CargoEdit' }) }
  },
  mounted () {
    this.mountload = true;
    if (!this.isModal && this.$route.params.cargocode) {
      this.show({ localcode: this.$route.params.cargocode });
    } else if (this.cargodata) {
      this.show(this.cargodata);
    } else {
      this.mountload = false;
    }
    this.updateTransportCosts();
  },
  watch: {
    '$route' () {
      if (!this.isModal && this.$route.params.cargocode) {
        this.show({ localcode: this.$route.params.cargocode });
      }
    },
    orderdata: {
      immediate: true,
      handler (val) {
        this.order = val;
        if (this.order != null) {
          this.setDefaultCargoManager()
        }
      }
    },
    cargo: {
      immediate: true,
      handler (val) {
        if (val != null) {
          this.setDefaultCargoManager()
        }
      }
    },
    cargodata: {
      immediate: true,
      handler (val) {
        if (val != null && typeof val == 'object' && this.cargo != null) {
          this.cargo.sales_manager_id = val.sales_manager_id;
        }
      }
    },
    allAvailableSalesManagerUsers: {
      immediate: true,
      handler (val) {
        this.responseDataManagers.availableSalesManagerUsers = val
        this.setDefaultCargoManager()
      }
    },
    allAvailableWareHouseEditorUsers: {
      immediate: true,
      handler (val) {
        this.responseDataManagers.availableWareHouseEditorUsers = val
        this.setDefaultCargoManager()
      }
    }
  }
}
