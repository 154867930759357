import DowntimeEdit from '@/components/pet/shift/downtimes/edit/DowntimeEdit.vue'

const defaultFilterValues = {
  line_id: [],
  startAt: '',
  endAt: '',
  scheduled: [],
  line_code: []
};

export default {
  name: 'DowntimeList',
  components: {
    DowntimeEdit
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { item: 'id', direction: 'desc' },
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false
    };
  },
  props: {
    shift: {
      type: Object,
      default: null
    },
    flowId: {
      type: Number,
      default: null
    }
  },
  computed: {
    basePathComp () {
      return 'downtimes'
    },
    basePerm () {
      return 'shift-downtimes';
    },
    baseTransParam () {
      return 'downtimes';
    },
    tableHeaders () {
      const headers = [
        { key: 'startAt', label: this.$t('downtimes.startAt'), filterKey: 'startAt', type: 'datetime', class: 'col-width-medium' },
        { key: 'endAt', label: this.$t('downtimes.endAt'), filterKey: 'endAt', type: 'datetime', class: 'col-width-medium' },
        { key: 'comment', label: this.$t('downtimes.comment'), sortable: true, filterKey: 'comment', classTd: 'w-10' },
        { key: 'stage.name.' + this.$i18n.locale, label: this.$t(this.baseTransParam + '.stage'), sortable: false, filterKey: 'name' },
        { key: 'flow_id', label: this.$t(this.baseTransParam + '.flow'), sortable: false, format: 'formatFromConfig', configKey: 'line_flows', configParam: 'name' },
        { key: 'line_id', label: this.$t(this.baseTransParam + '.line'), sortable: false, format: 'formatFromConfig', configKey: 'lines', configParam: 'name' },
        { key: 'type_id', label: this.$t(this.baseTransParam + '.type'), sortable: false, format: 'formatFromConfig', configKey: 'downtime_types', configParam: 'name' },
        { key: 'deviceNames', label: this.$t(this.baseTransParam + '.devices'), type: 'slot', slotcode: 'deviceNames' },
        { key: 'scheduled', label: this.$t('downtimes.scheduled'), filterKey: 'scheduled', format: 'formatYesNo', reverseMode: false, classTd: 'w-10' }
      ];
      const actions = [];
      if (this.canEdit) {
        actions.push({ label: this.$t('general.actions.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn btn-sm btn-primary' });
      }
      if (this.canDelete) {
        actions.push({ label: this.$t('general.actions.delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-sm btn-danger', ifconfig: { param: 'deleted_at', value: null } });
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    tableExpandConfig () {
      return null;
    },
    tableFilterConfig () {
      const filters = { };
      if (!this.shift) {
        filters.scheduled = { type: 'checkbox', label: '', options: this.yesNoOptions, colOnlyFilter: true };
        filters.line_code = { type: 'checkbox', label: this.$t(this.baseTransParam + '.line'), options: this.lineOptions, colOnlyFilter: false };
      }
      return filters;
    },
    canDelete () {
      return this.ApiRequest.userHasPermission('delete-' + this.basePerm);
    },
    canEdit () {
      if (this.shift && this.shift.closedAt) {
        return false;
      }
      return this.ApiRequest.userHasPermission('edit-' + this.basePerm);
    },
    yesNoOptions () {
      return this.BasicHelper.yesNoOptions();
    },
    requestPath () {
      let path = this.ApiRequest.addGetParamsToPath(this.basePathComp + '/list?perPage=' + this.paging.limit + '&page=' + this.paging.page, this.filterValues, true);
      if (this.tableSorting.item) {
        path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
      }
      return path;
    },
    tableActions () {
      const actions = { download: false };
      if (this.canEdit) {
        actions.add = { title: this.$t('general.actions.add') };
      }
      return actions;
    },
    lineOptions () {
      return this.$store.getters.getConfigOptions('lines', this.$i18n.locale);
    }
  },
  methods: {
    getTableData () {
      if (this.basePathComp) {
        if (this.shift) {
          this.filterValues.line_id = this.shift.line_id;
          this.filterValues.startAt = this.shift.startAt;
          this.filterValues.endAt = this.shift.endAt;
        }
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        this.loading = true;
        this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.tableItems = response.data;
            this.paging = response.paging;
            this.loading = false;
          }
        });
      }
    },
    getDeviceNames (deviceIds) {
      let devices = [];
      if (deviceIds.length > 0) {
        devices = this.$store.getters.getConfigOptions('devices', this.$i18n.locale, 'id', deviceIds, false, false, true);
      }
      return devices.map(el => el.text).join(', ');
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    editItem (el) {
      this.$refs.editElFrm.show(el, this.shift);
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    },
    deleteItem (el, approved) {
      if (approved) {
        this.setDeleted(el, 1);
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t(this.baseTransParam + '.actions.deleteTitle'), this.$t(this.baseTransParam + '.actions.deleteMessage', { name: this.BasicHelper.getTranslation(el.name), code: el.code, comment: el.comment }))
      }
    },
    restoreItem (el) {
      this.setDeleted(el, 0);
    },
    setDeleted (el, isDeleted) {
      this.loading = true;
      this.ApiRequest.request(this.basePathComp + '/deleted', this.ApiRequest.REQUEST_POST, { id: el.id, code: el.code, deleted: isDeleted }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        } else {
          this.getTableData();
        }
      });
    }
  },
  mounted () {
    this.getTableData();
    this.isMounted = true;
  },
  watch: {
    shift: {
      immediate: true,
      handler (val) {
        if (this.isMounted && (!val || val.line_id != this.filterValues.line_id || val.startAt != this.filterValues.startAt)) {
          this.getTableData();
        }
      }
    }
  }
}
