var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"assembly-approval-list"},[(_vm.shift)?[(!_vm.shift.warehouseApprovalsActive)?_c('div',{staticClass:"mt-2 text-danger"},[_c('div',[_vm._v(_vm._s(_vm.$t('assembly.approval.not-active')))])]):_c('div',[(_vm.shift.warehouseApprovedAt)?_c('div',[(_vm.invalidApprovalCount > 0)?_c('div',{staticClass:"mb-3"},[_c('span',{staticClass:"bg-warning p-1"},[_vm._v(" "+_vm._s(_vm.$tc('assembly.approval.some-invalid-approvals', {}, { count: _vm.invalidApprovalCount }))+" ")])]):_c('div',{staticClass:"text-success mb-3"},[_vm._v(" "+_vm._s(_vm.$t('assembly.approval.all-approved'))+" ")]),_c('div',{staticClass:"fs-2 fw-bold mb-2"},[_vm._v(" "+_vm._s(_vm.$t('assembly.approval.all-approved-title'))+" ")])]):_c('div',[_c('div',{staticClass:"text-danger mb-3"},[_vm._v(" "+_vm._s(_vm.$t('assembly.approval.missing-approvals'))+" ")]),_c('div',{staticClass:"fs-2 fw-bold mb-2"},[_vm._v(" "+_vm._s(_vm.$t('assembly.approval.missing-approvals-title'))+" ")])]),_c('Tableview',{ref:"tableview",attrs:{"fields":_vm.tableHeaders,"loading":_vm.loading,"items":_vm.shift.warehouseProductApprovals,"expandableConfig":_vm.tableExpandConfig,"displayFilterActions":true,"filterActions":_vm.filterActions,"filterActionAlign":'text-start'},on:{"saved":_vm.getTableData,"approveAllQuantity":_vm.approveAllQuantity,"approveQuantity":_vm.approveQuantity,"editQuantity":_vm.editQuantity,"cancelApproval":_vm.cancelApproval},scopedSlots:_vm._u([{key:"expandrow",fn:function(ref){
var data = ref.data;
return [_c('div',{staticClass:"d-block p-2 m-2 border border-primary rounded rounded-1"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('LabelTooltip',{staticClass:"fw-bold",attrs:{"code":"assembly.approval.correctedBales","readonly":true}}),_vm._v(" "+_vm._s(data.approval.correctedBales)+" ")],1),_c('div',{staticClass:"col-6"},[_c('LabelTooltip',{staticClass:"fw-bold",attrs:{"code":"assembly.approval.correctedQuantity","readonly":true}}),_vm._v(" "+_vm._s(data.approval.correctedQuantity)+" ")],1)]),_c('AssemblyApprovalBalesList',{attrs:{"approvalId":data.approval.id}})],1)]}},{key:"approvalq",fn:function(ref){
var data = ref.data;
return [(data.item.approval && data.item.approval.invalidData)?[_vm._v(" - ")]:[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.value, 0, _vm.$i18n.locale, false, true))+" ")]]}},{key:"approved",fn:function(ref){
var data = ref.data;
return [(data.value)?[(data.item.approval.invalidData)?_c('span',{staticClass:"bi bi-x-circle-fill text-danger"}):_c('span',{staticClass:"bi bi-check-circle-fill text-success"}),(data.item.approval.user)?[_vm._v(" "+_vm._s(data.item.approval.user.fullname)+" ")]:_vm._e(),_vm._v(" "+_vm._s(_vm.BasicHelper.formatDate(data.value, _vm.$t('general.dateTimeFormat')))+" ")]:_vm._e()]}},{key:"approvalLogic",fn:function(ref){
var data = ref.data;
return [(data.value)?[_vm._v(" "+_vm._s(_vm.$t(("assembly.approval.approvalLogics." + (data.value))))+" ")]:_vm._e()]}}],null,false,1979484451)})],1)]:_vm._e(),_c('AssemblyApprovalEdit',{ref:"approvalEditFrm",on:{"saved":_vm.getTableData}}),_c('MessageBox',{ref:"itemApproveMessageBox",on:{"confirm":function($event){return _vm.approveQuantity($event, 1)}}}),_c('MessageBox',{ref:"itemCancelApprovalMessageBox",on:{"confirm":function($event){return _vm.cancelApproval($event, 1)}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }