import useVuelidate from '@vuelidate/core';
import { helpers, required } from '@vuelidate/validators';
import SingleFileupload from 'ebg-vue-common/src/components/common/fileupload/single/SingleFileupload.vue'
import LinkToDifferentSystem from 'ebg-vue-common/src/components/common/LinkToDifferentSystem.vue'
import Gallery from 'ebg-vue-common/src/components/common/Gallery/Gallery.vue';

const defaultEl = {
  id: null,
  model_attachment_id: null,
  title: '',
  attachmentconfig: null,
  isAutoAdded: false
};

export default {
  name: 'ModelFileList',
  data () {
    return {
      editEl: defaultEl,
      loading: false,
      files: [],
      fileGroups: {},
      fileBase64: null,
      checkedFiles: [],
      sending: false,
      receivers: '',
      documentparams: {},
      loadingFile: false,
      previewKey: null,
      forceGenerate: false
    }
  },
  components: {
    SingleFileupload,
    LinkToDifferentSystem,
    Gallery
  },
  validations: {
    files: {
      $each: helpers.forEach({
        model_attachment_id: { required }
      })
    }
  },
  props: {
    basefiles: {
      type: Array,
      default: () => []
    },
    modelDocuments: {
      type: Array,
      default: () => []
    },
    disabled: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: true
    },
    isOrderObject: {
      type: Boolean,
      default: false
    },
    path: {
      type: String,
      default: null
    },
    defaultReceivers: {
      type: String,
      default: ''
    },
    documentParamOptions: {
      type: Array,
      default: () => []
    },
    baseDocumentparams: {
      type: Object | Array,
      default: () => {}
    },
    keybase: {
      type: String,
      default: ''
    },
    notify: {
      type: Boolean,
      default: true
    },
    viewButtonMode: {
      type: Boolean,
      default: false
    },
    forceGenerateCheckbox: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ''
    },
    d365Sync: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    linkedItems () {
      return [
        { key: 'd365brnumber', bicon: 'journal-check', code: 'billing_record_id', text: this.$t(this.baseTransParam + '.linkfilter.brnumber') }
      ];
    },
    modelDocumentsOptions () {
      const options = [];
      this.modelDocuments.forEach(el => {
        const item = {
          value: el.model_attachment_id,
          text: this.BasicHelper.getConfigValue('model_attachments', 'id', el.model_attachment_id, 'name', true),
          generated: el.generated,
          autoadd: el.autoadd,
          multi: el.multi,
          attachment: el.model_attachment
        }
        options.push(item);
      });
      return options;
    },
    availableFiles () {
      const options = [];
      this.files.forEach(el => {
        if (el.id && el.filename && !el.file) {
          const optionEl = this.BasicHelper.cloneObject(el);
          optionEl.text = el.title;
          if (el.attachmentconfig) {
            optionEl.text = `${el.attachmentconfig.text}: ${optionEl.text}`;
          }
          options.push(optionEl);
        }
      })
      return options;
    }
  },
  methods: {
    fileLinkParams (file) {
      return { name: 'menu.billing.record.view', path: 'view/:code/:archiveId?', code: file.d365brnumber ?? 'NEW', archiveId: file.d365Id }
    },
    getExtension (file) {
      let extension = '';
      if (file.attachmentconfig != undefined && file.attachmentconfig.attachment != undefined &&
          file.attachmentconfig.attachment.extensions != undefined) {
        extension = file.attachmentconfig.attachment.extensions
      }
      return extension;
    },
    addFile () {
      this.files.push(this.BasicHelper.cloneObject(defaultEl));
    },
    openLink (link) {
      window.open(link, '_blank');
    },
    saveFiles (updateD365AfterSave = false) {
      this.v$.$touch();
      if (!this.v$.$invalid && this.path && !this.loading) {
        this.loading = true;
        const reqData = this.ApiRequest.createFormData(
          { files: this.files },
          ['files'],
          'item',
          this,
          false,
          ['model_attachment_id']
        );
        const formatedData = {}
        Object.keys(this.documentparams).forEach(el => {
          formatedData[el] = this.documentparams[el]
        })
        const docData = typeof this.documentparams == 'object' ? formatedData : this.documentparams
        reqData.append('item', JSON.stringify({ documentparams: docData }));
        this.ApiRequest.request(this.path + 'update', this.ApiRequest.REQUEST_POST, reqData, (response) => {
          if (response.error) {
            this.$store.commit('toastAdd', {
              context: 'danger',
              message: this.ApiRequest.getApiResponseErrorMessage(response, {})
            })
            this.loading = false;
          } else {
            if (updateD365AfterSave) {
              this.loading = false;
              this.updateD365Files();
            } else {
              this.updateFileData(response.data);
              this.loading = false;
            }
            this.$emit('filesUpdated', response.data);
          }
        });
      }
    },
    syncFiles () {
      if (this.d365Sync && !this.loading) {
        this.loading = true;
        this.ApiRequest.request(this.path + 'sync-d365', this.ApiRequest.REQUEST_POST, {}, (response) => {
          if (response.error) {
            this.$store.commit('toastAdd', {
              context: 'danger',
              message: this.ApiRequest.getApiResponseErrorMessage(response, {})
            })
          } else {
            this.updateFileData(response.data);
          }
          this.loading = false;
        });
      }
    },
    updateD365Files () {
      if ((this.d365Sync || this.isOrderObject) && !this.loading) {
        this.ApiRequest.request(this.path + 'upload-d365', this.ApiRequest.REQUEST_POST, {}, (response) => {
          if (response.error) {
            this.$store.commit('toastAdd', {
              context: 'danger',
              message: this.ApiRequest.getApiResponseErrorMessage(response, {})
            })
          } else {
            this.updateFileData(response.data);
          }
          this.loading = false;
        });
      }
    },
    generateFile (key) {
      if (this.path && !this.files[key].generating) {
        this.files[key].generating = true;
        this.$forceUpdate();
        this.ApiRequest.request(this.path + 'generate', this.ApiRequest.REQUEST_POST, this.BasicHelper.cloneObject(this.files[key]), (response) => {
          if (response.error) {
            this.$store.commit('toastAdd', {
              context: 'danger',
              message: this.ApiRequest.getApiResponseErrorMessage(response, {})
            })
            this.files[key].generating = false;
            this.$forceUpdate();
          } else {
            this.updateFileData(response.data);
            this.$emit('filesUpdated');
          }
        });
      }
    },
    viewByAttachment (attachmentId) {
      if (this.path) {
        this.fileBase64 = null;
        this.loadingFile = true;
        this.previewKey = attachmentId;
        const path = this.ApiRequest.addGetParamsToPath(this.path + 'view-attachment/' + attachmentId, { forceGenerate: this.forceGenerate });
        this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.error) {
            this.$store.commit('toastAdd', {
              context: 'danger',
              message: this.ApiRequest.getApiResponseErrorMessage(response, {})
            })
          } else if (response.data) {
            this.fileBase64 = response.data.base64;
            if (!this.viewButtonMode) {
              this.$refs.filepreview.show();
            }
          }
          this.loadingFile = false;
        });
      }
    },
    getTooltipLinkedKey (key, value) {
      let text = ''
      if (value) {
        text = value;
      } else {
        text = this.$t('modelfiles.link.not-added');
      }
      return this.$t(`modelfiles.link.${key}`, null, { number: text })
    },
    getIconParam (file, key, bicon) {
      const value = file[key]
      let text = ''
      if (value) {
        text = value;
      } else {
        text = this.$t('modelfiles.link.not-added');
      }
      const title = this.$t(`modelfiles.link.${key}`, null, { number: text })
      const classData = `bi bi-${bicon}` + (file[key] ? ' text-white' : ' text-secondary ')
      return { title: title, classData: classData }
    },
    viewFile (key, download = false) {
      if (this.path) {
        if (download) {
          this.files[key].downloading = true;
        }
        this.fileBase64 = null;
        const reqType = (download ? this.ApiRequest.REQUEST_DOWNLOAD : this.ApiRequest.REQUEST_GET);
        this.ApiRequest.request(this.path + 'view/' + this.files[key].id, reqType, {}, (response) => {
          if (response.error) {
            this.$store.commit('toastAdd', {
              context: 'danger',
              message: this.ApiRequest.getApiResponseErrorMessage(response, {})
            })
          } else if (response.data && !download) {
            this.fileBase64 = response.data.base64;
            if (!this.viewButtonMode) {
              this.$refs.filepreview.show();
            }
          }
          if (download) {
            this.files[key].downloading = false;
          }
        });
      }
    },
    changeAttachmentConfig (val, key) {
      this.files[key].attachmentconfig = this.modelDocumentsOptions.find(el => el.value == val);
    },
    removeFileData (key) {
      this.files[key].file = null;
      this.files[key].filename = null;
    },
    removeFile (key) {
      if (this.files[key].isAutoAdded || this.files[key].id) {
        this.files[key].deleted = 1;
      } else {
        this.$delete(this.files, key);
      }
    },
    pushFileToGroup (file, index) {
      let code = 'new';
      let name = '';
      let isGallery = false;
      if (file.attachmentconfig && (file.id || file.isAutoAdded)) {
        isGallery = file.attachmentconfig?.attachment?.type === 'gallery';
        code = isGallery ? file.attachmentconfig?.attachment?.code : 'default';
        name = isGallery ? file.attachmentconfig?.attachment?.name : '';
      }
      if (!this.fileGroups[code]) {
        const group = {
          code: code,
          name: name,
          isCollapsable: isGallery,
          isCollapsed: isGallery,
          list: {},
          toArray: function () {
            const array = [];
            Object.values(this.list).forEach(file => {
              if (!file.deleted) {
                array.push(file);
              }
            })
            return array;
          }
        }
        this.$set(this.fileGroups, code, group);
      }
      this.$set(this.fileGroups[code].list, index, file);
    },
    toggleFileGroup (fileGroup) {
      fileGroup.isCollapsed = !fileGroup.isCollapsed;
    },
    updateFileData (newfiles, addMissing = 1) {
      const items = [];
      newfiles.forEach(fileEl => {
        if (!fileEl.attachmentconfig) {
          fileEl.attachmentconfig = this.modelDocumentsOptions.find(el => el.value == fileEl.model_attachment_id);
        }
        items.push(fileEl)
      });
      if (addMissing) {
        // Add autoadd files which are not linked
        this.modelDocumentsOptions.forEach(el => {
          if (el.autoadd && !items.find(item => item.model_attachment_id == el.value)) {
            const addItem = this.BasicHelper.cloneObject(defaultEl);
            addItem.attachmentconfig = el;
            addItem.model_attachment_id = el.value;
            addItem.model_attachment = el.model_attachment;
            addItem.isAutoAdded = true;
            items.push(addItem);
          }
        });
      }
      this.files = items;
    },
    showMailList () {
      this.receivers = (this.defaultReceivers ? this.defaultReceivers : '');
      this.checkedFiles = this.availableFiles.map(el => el.id);
      this.$refs.mailinglist.show();
    },
    sendEmail () {
      if (this.path && !this.sending) {
        this.sending = true;
        const reqData = {
          fileIds: this.checkedFiles,
          to: this.receivers
        }
        this.ApiRequest.request(this.path + 'sendmail', this.ApiRequest.REQUEST_POST, reqData, (response) => {
          if (response.error) {
            this.$store.commit('toastAdd', {
              context: 'danger',
              message: this.ApiRequest.getApiResponseErrorMessage(response, {})
            })
          } else {
            this.$refs.mailinglist.hide();
            this.updateFileData(response.data);
            this.$forceUpdate();
          }
          this.sending = false;
        });
      }
    },
    getTitleD365 (file) {
      let res = '';
      if (file.d365Id) {
        res = this.$t(
          'modelfiles.' + (file.hasLocalChanges ? 'fileOutdatedD365' : 'fileInD365'),
          this.$i18n.locale,
          [this.BasicHelper.formatDate(file.d365LastSyncAt, this.$t('general.dateTimeFormat'))]
        )
      }
      return res;
    },
    getDocumentparams () {
      return this.documentparams;
    }
  },
  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'ModelFileList' }) }
  },
  watch: {
    files () {
      this.fileGroups = {};
      this.files.forEach((file, index) => this.pushFileToGroup(file, index));
    },
    basefiles: {
      immediate: true,
      handler (val) {
        this.updateFileData(val, 1);
      }
    },
    baseDocumentparams: {
      immediate: true,
      handler (val) {
        if (val) {
          this.documentparams = this.BasicHelper.cloneObject(val);
        } else {
          this.documentparams = {};
        }
      }
    }
  }
}
