var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cargo-d365-line-list"},[_c('Tableview',{ref:"tableview",attrs:{"fields":_vm.tableHeaders,"items":_vm.d365lines,"tableClass":"table-striped table-sm table-hover","expandableConfig":_vm.tableExpandConfig},on:{"deleteItem":_vm.deleteLine,"addRfids":_vm.addRfids,"print":_vm.printLine},scopedSlots:_vm._u([(_vm.isIncoming)?{key:"expandrow",fn:function(ref){
var data = ref.data;
return [(data.incomingfactoryproducts != undefined && _vm.isForPurchaseInBags(data))?_c('div',{},[_c('table',{staticClass:"table align-middle table-borderless table-striped-double"},[_c('thead',[_c('tr',{staticClass:"fw-bold border-dark border-bottom"},[_c('th',[_c('LabelTooltip',{attrs:{"code":"cargo.d365lines.bagCode"}})],1),_c('th',[_c('LabelTooltip',{attrs:{"code":"cargo.d365lines.startQuantity"}})],1),_c('th')])]),_c('tbody',[_vm._l((data.incomingfactoryproducts),function(factoryProduct,productIdx){return _c('tr',{key:productIdx},[_c('td',[_c('div',{staticClass:"col-6"},[_vm._v(" "+_vm._s(factoryProduct.code)+" ")])]),_c('td',[_c('div',{staticClass:"col-6"},[_c('NumberInput',{attrs:{"precision":2,"minValue":0,"controlClass":"form-control-sm","disabled":_vm.disabledEdit365Products},model:{value:(factoryProduct.startQuantity),callback:function ($$v) {_vm.$set(factoryProduct, "startQuantity", $$v)},expression:"factoryProduct.startQuantity"}})],1)]),_c('td',[(_vm.allowPrint)?_c('span',{staticClass:"btn btn-sm btn-info",on:{"click":function($event){return _vm.printLine(data, factoryProduct.id, factoryProduct.remainingBales)}}},[_c('i',{staticClass:"bi bi-printer-fill"})]):_vm._e()])])})],2)])]):_vm._e()]}}:null,{key:"product_type",fn:function(ref){
var data = ref.data;
return [(!data.item.autoAdded && _vm.canEditLines && false)?_c('AdvancedSelect',{attrs:{"items":_vm.productTypeOptions,"searchable":true,"dbparam":("d365lines." + (data.index) + ".product_type_id"),"size":_vm.size},on:{"input":function($event){return _vm.productTypeChanged($event, data.index)}},model:{value:(_vm.d365lines[data.index].product_type_id),callback:function ($$v) {_vm.$set(_vm.d365lines[data.index], "product_type_id", $$v)},expression:"d365lines[data.index].product_type_id"}}):[_vm._v(" "+_vm._s(_vm.PetHelper.getProductConfigName(data.item.product_id, 'type_id'))+" ")]]}},{key:"exclamation",fn:function(ref){
var data = ref.data;
return [(_vm.hasUnPlannedProduct(data.item))?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"btn btn-sm",attrs:{"title":_vm.$t('purchase-order.OrderHasUnplannedCargo')}},[_c('i',{staticClass:"bi bi-exclamation-circle"})]):_vm._e(),(_vm.hasQualityMismatch(data.item))?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"btn btn-sm",attrs:{"title":_vm.$t('purchase-order.cargoLineHasQualityMismatch')}},[_c('i',{staticClass:"bi bi-exclamation-circle"})]):_vm._e()]}},{key:"product",fn:function(ref){
var data = ref.data;
return [(!_vm.d365lines[data.index].autoAdded && _vm.canEditLines && _vm.d365lines[data.index])?_c('AdvancedSelect',{attrs:{"items":_vm.getFilteredProductOptions(_vm.d365lines[data.index]),"controlClass":_vm.checkPartnerProducts && _vm.partnerProducts != null && _vm.partnerProducts.find(function (el) { return el.product_id == _vm.d365lines[data.index].product_id; }) == undefined ? "border border-danger" : "","searchable":true,"dbparam":("d365lines." + (data.index) + ".product_id"),"hasError":((_vm.validator && _vm.validator[data.index].product_id.length)  ? true : false),"size":_vm.size},on:{"select":function($event){return _vm.productChanged($event, data.index)}},model:{value:(_vm.d365lines[data.index].product_id),callback:function ($$v) {_vm.$set(_vm.d365lines[data.index], "product_id", $$v)},expression:"d365lines[data.index].product_id"}}):[_vm._v(" "+_vm._s(_vm.BasicHelper.getConfigValue('products', 'id', data.item.product_id, 'name'))+" ")],(_vm.partner !== null && _vm.validator && _vm.validator[data.index].product_id[0] != undefined && _vm.validator[data.index].product_id[0].$validator == 'checkPartner')?_c('span',[_c('LabelTooltip',{attrs:{"code":"partners.vendors.productIsMissingFromVendorProductList"}})],1):_vm._e()]}},{key:"quality",fn:function(ref){
var data = ref.data;
return [(!_vm.d365lines[data.index].autoAdded && _vm.canEditLines)?_c('b-form-select',{attrs:{"dbparam":("d365lines." + (data.index) + ".quality_id"),"options":_vm.getFilteredQualityOptions(_vm.d365lines[data.index]),"controlClass":("form-control-" + _vm.size)},model:{value:(_vm.d365lines[data.index].quality_id),callback:function ($$v) {_vm.$set(_vm.d365lines[data.index], "quality_id", $$v)},expression:"d365lines[data.index].quality_id"}}):[(data.value)?_c('span',{staticClass:"text-center badge",style:(("background-color: " + (_vm.getQualityBadgeColors(data.value).bgColor) + "; color: " + (_vm.getQualityBadgeColors(data.value).textColor)))},[_vm._v(" "+_vm._s(_vm.BasicHelper.getConfigValue('product_qualities', 'id', data.value, 'name'))+" ")]):_vm._e()]]}},{key:"pieces",fn:function(ref){
var data = ref.data;
return [(!_vm.d365lines[data.index].autoAdded && _vm.canEditLines)?_c('NumberInput',{attrs:{"precision":0,"minValue":0,"maxlength":10,"disabled":!_vm.canEditQuantity(data.item),"dbparam":("d365lines." + (data.index) + ".pieces"),"controlClass":"form-control-" + _vm.size + " " + (_vm.validator && _vm.validator[data.index].pieces.length ? 'is-invalid' : '')},model:{value:(_vm.d365lines[data.index].pieces),callback:function ($$v) {_vm.$set(_vm.d365lines[data.index], "pieces", $$v)},expression:"d365lines[data.index].pieces"}}):[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.value, 0, _vm.$i18n.locale))+" ")]]}},{key:"quantity",fn:function(ref){
var data = ref.data;
return [(!_vm.d365lines[data.index].autoAdded && _vm.canEditLines)?_c('NumberInput',{attrs:{"precision":_vm.quantityPrecision,"minValue":0,"maxlength":10,"dbparam":("d365lines." + (data.index) + ".quantity"),"controlClass":"form-control-" + _vm.size + " " + (_vm.validator && _vm.validator[data.index].quantity.length ? 'is-invalid' : '')},model:{value:(_vm.d365lines[data.index].quantity),callback:function ($$v) {_vm.$set(_vm.d365lines[data.index], "quantity", $$v)},expression:"d365lines[data.index].quantity"}}):[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.value, _vm.quantityPrecision, _vm.$i18n.locale))+" ")]]}},{key:"vendorcode",fn:function(ref){
var data = ref.data;
return [(!_vm.d365lines[data.index].autoAdded && _vm.canEditLines)?_c('BFormInput',{attrs:{"dbparam":("d365lines." + (data.index) + ".vendorcode"),"controlClass":("form-control-" + _vm.size),"maxlength":50},model:{value:(_vm.d365lines[data.index].vendorcode),callback:function ($$v) {_vm.$set(_vm.d365lines[data.index], "vendorcode", $$v)},expression:"d365lines[data.index].vendorcode"}}):[_vm._v(" "+_vm._s(_vm.d365lines[data.index].vendorcode)+" ")]]}},{key:"apus_waste_class",fn:function(ref){
var data = ref.data;
return [(_vm.canEditPurchaseApusWasteId)?_c('AdvancedSelect',{attrs:{"dbparam":("d365lines." + (data.index) + ".apus_waste_class_id"),"size":_vm.size,"items":_vm.apusWasteOptions.filter(function (el) { return (el.value == _vm.d365lines[data.index].apus_waste_class_id || (_vm.d365lines[data.index].availableApusWasteClasses && _vm.d365lines[data.index].availableApusWasteClasses.includes(el.value))); })},model:{value:(_vm.d365lines[data.index].apus_waste_class_id),callback:function ($$v) {_vm.$set(_vm.d365lines[data.index], "apus_waste_class_id", $$v)},expression:"d365lines[data.index].apus_waste_class_id"}}):[_vm._v(" "+_vm._s(_vm.apusWasteClass(_vm.d365lines[data.index].apus_waste_class_id))+" ")]]}},{key:"price",fn:function(ref){
var data = ref.data;
return [(_vm.canEditPrices)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip"}],staticClass:"position-relative",attrs:{"title":_vm.getPriceInfo(_vm.d365lines[data.index])}},[_c('NumberInput',{attrs:{"precision":2,"minValue":0,"mathfunc":true,"placeholder":_vm.d365lines[data.index].activePrice,"dbparam":("d365lines." + (data.index) + ".price"),"controlClass":("form-control-" + _vm.size + " ")},model:{value:(_vm.d365lines[data.index].price),callback:function ($$v) {_vm.$set(_vm.d365lines[data.index], "price", $$v)},expression:"d365lines[data.index].price"}}),(_vm.getPriceInfo(data.item) !== '')?_c('span',{staticClass:"text-danger position-absolute end-0 top-0"},[_c('i',{staticClass:"bi bi-exclamation-diamond"})]):_vm._e()],1):[(data.item.price !== null)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip"}],class:(_vm.getPriceInfo(data.item) == '' ? '' : 'text-danger'),attrs:{"title":_vm.getPriceInfo(data.item)}},[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.item.price, 2, _vm.$i18n.locale))+" ")]):_c('span',[_vm._v(" "+_vm._s(_vm.BasicHelper.formatDecimal(data.item.activePrice, 2, _vm.$i18n.locale))+" ")])]]}},{key:"rfids",fn:function(ref){
var data = ref.data;
return [(data.item.id && _vm.showRfidCount(data.item) && !_vm.isForPurchaseInBags(data.item))?_c('span',{class:(data.item.pieces > data.item.rfids_count ? 'fw-bold text-warning' : '')},[_vm._v(" "+_vm._s(data.item.rfids_count)+" / "+_vm._s(data.item.pieces)+" ")]):_vm._e()]}}],null,true)}),(_vm.canEditLines && !_vm.isExportOrTransferOrder)?_c('div',[_c('span',{staticClass:"btn btn-primary",on:{"click":_vm.addLine}},[_c('i',{staticClass:"bi bi-plus"})])]):_vm._e(),_c('CargoRfidList',{ref:"cargoRfidList",attrs:{"canEdit":true},on:{"rfidsChanged":_vm.rfidsChanged}}),_c('DevicePrinterCallprint',{ref:"printForm",attrs:{"customQty":_vm.customQty,"hasCustomQty":true,"allowedTemplates":_vm.allowedTemplates}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }