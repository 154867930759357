import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

const rate = {
  productType: '',
  productIds: [],
  measureTypeId: null,
  isSale: null,
  rate: null,
  startAt: null,
  endAt: null
};
export default {
  name: 'RateBulkAdd',
  data () {
    return {
      rateData: rate,
      productsWithDelta: [],
      hasValidUnitOfMeasure: false,
      loading: false,
      toggleRateChanges: false
    }
  },
  validations: {
    rateData: {
      productType: { required },
      productIds: { required },
      measureTypeId: { required },
      isSale: { required },
      rate: { required },
      startAt: { required },
      endAt: { }
    }
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    getTitle () {
      return this.$t('product.rates.bulkAddTitle');
    },
    getProductTypesOptions () {
      return this.$store.getters.getConfigOptions('product_types', this.$i18n.locale);
    },
    productOptions () {
      return this.$store.getters.getConfigOptions('products', this.$i18n.locale, 'type_id', this.rateData.productType, false, false, false, ['default_measure_type_id']);
    },
    getRateTypesOptions () {
      return [
        { value: false, text: this.$t('product.rates.buy') },
        { value: true, text: this.$t('product.rates.sale') }
      ];
    },
    getUnitOfMeasure () {
      this.hasValidUnitOfMeasure = false;
      let unitOfMeasure = this.$t('product.rates.errors.measurementOfUnit');
      const measureTypes = this.productOptions.filter(product => this.rateData.productIds.includes(product.value)).map(product => product.default_measure_type_id);
      const uniqueMeasureTypes = [...new Set(measureTypes)];
      if (uniqueMeasureTypes.length === 1) {
        this.rateData.measureTypeId = uniqueMeasureTypes[0];
        unitOfMeasure = this.BasicHelper.getConfigValue('measure_types', 'id', uniqueMeasureTypes[0], 'name');
        this.hasValidUnitOfMeasure = true;
      }
      return unitOfMeasure;
    },
    validData () {
      return (this.rateData.productIds.length > 0) && this.rateData.rate && this.rateData.startAt && (this.rateData.isSale !== null) && this.hasValidUnitOfMeasure;
    }
  },
  methods: {
    resetProducts () {
      this.rateData.productIds = [];
    },
    calculate () {
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.loading = true;
        this.ApiRequest.request('rates/calculate', this.ApiRequest.REQUEST_POST, { item: this.rateData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
            this.loading = false;
          } else {
            this.productsWithDelta = this.BasicHelper.cloneObject(response.data);
            this.toggleRateChanges = true;
            this.loading = false;
          }
        });
      }
    },
    show () {
      this.$refs['modal-window'].show();
    },
    save () {
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid) {
        this.loading = true;
        this.ApiRequest.request('rates/bulk-edit', this.ApiRequest.REQUEST_POST, { item: this.rateData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
            this.loading = false;
          } else {
            this.calculate();
            this.loading = false;
          }
        });
      }
    },
    close () {
      this.$refs['modal-window'].hide();
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
