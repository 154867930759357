import ProducedItemHistory from '@/components/pet/manufacture/produced/history/ProducedItemHistory.vue'
import RfidList from '@/components/pet/rfid/list/RfidList.vue'

export default {
  name: 'FactoryProductUsageInfo',
  data () {
    return {
      editEl: null,
      loading: true
    }
  },
  components: {
    RfidList,
    ProducedItemHistory
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    code: {
      type: String,
      default: null
    }
  },
  computed: {
    getTitle () {
      return this.$t('factory-product.usage-info');
    },
    tabOptions () {
      return [
        { code: 'feedings', text: this.$t('factory-product.tabs.feedings') },
        { code: 'rfids', text: this.$t('factory-product.tabs.rfids') },
        { code: 'assemblyproducts', text: this.$t('factory-product.tabs.assemblyproducts') },
        { code: 'salecargos', text: this.$t('factory-product.tabs.salecargos') },
        { code: 'history', text: this.$t('factory-product.tabs.history') }
      ];
    },
    hasDeletePermissions () {
      return !this.loading && this.ApiRequest.userHasPermission('delete-fed-products');
    },
    tableHeadersFeedings () {
      const headers = [
        { key: 'fed_at', label: this.$t('shift.startdate'), sortable: false, type: 'datetime' },
        { key: 'shift.shortname', label: this.$t('shift.shortName'), sortable: false },
        { key: 'shift.line_id', label: this.$t('line.name'), sortable: false, format: 'formatFromConfig', configKey: 'lines', configParam: 'name' },
        { key: 'flow_id', label: this.$t('flow.name'), sortable: false, format: 'formatFromConfig', configKey: 'line_flows', configParam: 'name' },
        { key: 'stage.name.' + this.$i18n.locale, label: this.$t('fed-products.stage_id') },
        { key: 'product_id', label: this.$t('fed-products.product_id'), sortable: false, format: 'formatFromConfig', configKey: 'products', configParam: 'name' },
        { key: 'type_id', label: this.$t('fed-products.type_id'), format: 'formatFromConfig', configKey: 'feeding_types', configParam: 'name' },
        { key: 'quantity', label: this.$t('fed-products.quantity'), sortable: false, format: 'formatDecimal', precision: 0 },
        { key: 'isBale', label: this.$t('fed-products.isBale'), sortable: false, format: 'formatYesNo' },
        { key: 'user.fullname', label: this.$t('fed-products.user'), sortable: false }
      ];
      const actions = [];
      if (this.hasDeletePermissions) {
        actions.push({ label: this.$t('general.actions.delete'), emit: 'deleteFedProduct', bicon: 'trash', class: 'btn-sm btn-danger', ifconfig: { useFunc: true, func: this.canBeDeleted } });
      }
      headers.push({ key: 'actions', actions: actions });
      return headers;
    },
    tableHeadersSales () {
      const headers = [
        { key: 'localcode', label: this.$t('cargo.localcode'), type: 'slot', slotcode: 'localcode' },
        { key: 'quantity', label: this.$t('cargo.d365lines.quantity'), format: 'formatDecimal', precision: 0 }
      ];
      return headers;
    },
    tableHeadersAssembly () {
      const headers = [
        { key: 'created_at', label: this.$t('general.item.created_at'), sortable: false, type: 'datetime' },
        { key: 'warehouse_id', label: this.$t('warehouse.name'), format: 'formatFromConfig', configKey: 'warehouses', configParam: 'name' },
        { key: 'quantity', label: this.$t('assembly.movement.quantity'), format: 'formatDecimal', precision: 0 },
        { key: 'movement.user.fullname', label: this.$t('assembly.movement.user') }
      ];
      return headers;
    }
  },
  methods: {
    canBeDeleted (el) {
      return (!el.fromAssembly && !(el.shift && el.shift.journalsCreatedAt) && !el.bale_id);
    },
    deleteFedProduct (el) {
      const path = 'fed-products/deleted';
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, { item: el }, (response) => {
        if (response.data) {
          this.$store.commit('toastAdd', {
            context: 'success',
            message: this.$t('fed-products.actions.deleteDone')
          });
          this.show(this.code);
        } else if (response.error) {
          this.$store.commit('toastAdd', {
            context: 'danger',
            message: this.ApiRequest.getApiResponseErrorMessage(response)
          })
        }
        this.loading = false;
      });
    },
    show (code) {
      this.getItem(code);
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    getItem (code) {
      this.ApiRequest.request('factory-products/view-by-code/' + code, this.ApiRequest.REQUEST_GET, { }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else {
          this.editEl = response.data;
        }
        this.loading = false;
      });
    }
  },
  mounted () {
    if (this.code) {
      this.show(this.code);
    }
  }
}
