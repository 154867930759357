<template>
  <div class="shift-recipe">
    <div v-if="warehouseShift">
      <div v-if="warehouseShift.closedAt" class="text-danger fs-3">
        {{ $t('shift.is-closed') }}
      </div>
    </div>
    <div class="fs-2 fw-bold">
      {{ $t('assembly.approval.title')}}
    </div>
    <AssemblyApprovalList v-if="warehouseShift" :shiftId="warehouseShift.id" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AssemblyApprovalList from '@/components/pet/assembly/approvallist/AssemblyApprovalList.vue';

export default {
  name: 'ShiftAssemblyApproval',
  data () {
    return {
    }
  },
  components: {
    AssemblyApprovalList
  },
  computed: {
    ...mapState({
      warehouseShift: state => state.pet.warehouseShift
    })
  },
  watch: {
    warehouseShift: {
      immediate: true,
      handler () {
      }
    }
  }
}
</script>
