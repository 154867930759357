import { mapState } from 'vuex'

export default {
  name: 'ShiftPickerAssembly',
  components: {
  },
  props: {
  },
  data () {
    return {
      loading: false,
      shiftId: (this.warehouseShift ? this.warehouseShift.id : null),
      lineId: (this.warehouseShift ? this.warehouseShift.line_id : null)
    }
  },
  computed: {
    ...mapState({
      warehouseShift: state => state.pet.warehouseShift,
      activeshifts: state => state.config.activeshifts,
      defaultSiteId: state => state.pet.defaultSiteId,
      lines: state => state.config.lines
    }),
    lineOptions () {
      return this.$store.getters.getConfigOptions('lines', this.$i18n.locale, 'id', this.visibleShiftLines, false, false, true, ['site_id']).filter(el => {
        return (el.site_id == this.defaultSiteId);
      });
    },
    visibleShiftLines () {
      return this.visibleShifts.map(el => el.line_id);
    },
    visibleShifts () {
      const currentTimestamp = Math.floor(new Date().getTime() / 1000);
      const filters = this.$store.getters.userWarehouseShiftFilters;
      const maxAgo = -60 * 60 * 12 * (1 + filters.prev);
      const maxAfter = 60 * 60 * 12 * filters.next;
      return this.BasicHelper.sortArrayByKeyValue(
        Object.values(this.activeshifts).filter(el => {
          const delta = el.startAtTimestamp - currentTimestamp;
          return (
            el.warehouse_id &&
            delta > maxAgo &&
            delta < maxAfter &&
            this.lines[el.line_id].site_id == this.defaultSiteId
          );
        }),
        'startAtTimestamp'
      );
    }
  },
  methods: {
    changeShift (shiftId) {
      if (shiftId) {
        this.loading = true;
        this.$store.dispatch('changeShift', { filters: { warehouseShift: { id: shiftId } } })
          .then((res) => {
            this.loading = false;
          }).catch(error => {
            console.log(error);
            this.loading = false;
          });
      } else {
        localStorage.setItem('warehouseFlowId', null);
        localStorage.setItem('warehouseShift', null);
        this.$store.dispatch('changeStateValues', { warehouseShift: null, warehouseFlowId: null })
      }
    },
    lineChanged (lineId) {
      const match = this.visibleShifts.find(el => el.line_id == parseInt(lineId) && el.matchesCurrentTime == false);
      if (match) {
        this.changeShift(match.id);
      }
    },
    selectShift (id) {
      this.changeShift(id);
    },
    checkUserShifts () {
      if (this.visibleShifts && this.visibleShifts.length > 0) {
        if (!this.visibleShifts.find(el => el.id == this.shiftId)) {
          this.changeShift(this.visibleShifts.find(el => el.matchesCurrentTime == false).id);
        }
      } else {
        this.changeShift(null);
      }
    }
  },
  mounted () {
    this.checkUserShifts();
  },
  watch: {
    warehouseShift: {
      immediate: true,
      handler (val) {
        if (val) {
          this.shiftId = val.id;
          this.lineId = val.line_id;
        }
      }
    },
    defaultSiteId: {
      immediate: true,
      handler (val) {
        if (val) {
          this.checkUserShifts();
          this.$forceUpdate();
        }
      }
    },
    activeshifts: {
      immediate: true,
      handler () {
        this.checkUserShifts();
        this.$forceUpdate();
      }
    }
  }
}
