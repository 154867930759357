import useVuelidate from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';
export default {
  name: 'ShiftProductMeasurementElement',
  data () {
    return {
      hasCalcResultError: false,
      hasFormulaCalcResultError: false
    }
  },
  validations: {
    testMeasurement: {
      value: {
        required: requiredIf(function (key, row) {
          return this.measurement.additionalData.isRequiredField == true && this.measurement.isOption != true &&
              this.testMeasurement.inheritShiftValue == false
        })
      }
    }
  },
  components: {
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    measurement: {
      type: Object,
      default: () => null
    },
    testMeasurement: {
      type: Object,
      default: () => {}
    },
    qualityBaseConfig: {
      type: Object,
      default: () => {}
    },
    productDataSet: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    checkboxOption () {
      return { title: 'test1', bicon: 'check' }
    },
    qualityMeasurementOptions () {
      return this.$store.getters.getConfigOptions('quality_measurement_options', this.$i18n.locale);
    },
    categoryOptionsIsOptions () {
      return this.$store.getters.getConfigOptions('quality_measurement_categories', this.$i18n.locale, 'isOption', 1);
    },
    getQualityFormulaMeasurementConstants () {
      return JSON.parse(this.$store.state.config.qualityFormulaMeasurementConstants)
    },
    calcInputMeasurementType () {
      if (this.measurement.additionalData.hasCalculationInput && 'calcInputMeasurementType' in this.measurement.additionalData &&
          this.measureTypeOptions.find(el => el.value == this.measurement.additionalData.calcInputMeasurementType) != undefined) {
        const selectedCalcMeasurement = this.measureTypeOptions.find(el => el.value == this.measurement.additionalData.calcInputMeasurementType);
        if ((this.testMeasurement.value == null || this.testMeasurement.value == '')) {
          return selectedCalcMeasurement.text;
        } else {
          if (selectedCalcMeasurement.code == this.getQualityFormulaMeasurementConstants.find(el => el.name == 'PPM').value && 'coefficient' in this.measurement.additionalData) {
            const ppm = Math.floor(parseFloat(this.testMeasurement.value) *
                parseFloat(this.measurement.additionalData.coefficient) * Math.pow(10, 6) / this.qualityBaseConfig.weight);
            if (!isNaN(ppm) && (ppm > this.measurement.additionalData.warningMaxRangeValue || ppm < this.measurement.additionalData.warningMinRangeValue)) {
              this.hasFormulaCalcResultError = true;
            } else {
              this.hasFormulaCalcResultError = false;
            }
            return ppm + ' ' + selectedCalcMeasurement.text
          }
          if (selectedCalcMeasurement.code == this.getQualityFormulaMeasurementConstants.find(el => el.name == 'PERCENT').value) {
            const percentage = (this.testMeasurement.value / this.qualityBaseConfig.weight * 100).toFixed(1);
            if (!isNaN(percentage) && (percentage > this.measurement.additionalData.warningMaxRangeValue || percentage < this.measurement.additionalData.warningMinRangeValue)) {
              this.hasFormulaCalcResultError = true;
            } else {
              this.hasFormulaCalcResultError = false;
            }
            return percentage + ' ' + selectedCalcMeasurement.text
          }
        }
      }
      return undefined
    },
    qualityCategoryOptions () {
      return this.$store.getters.getConfigOptions('quality_measurement_categories', this.$i18n.locale);
    },
    measureTypeOptions () {
      return this.$store.getters.getConfigOptions('measure_types', this.$i18n.locale);
    },
    baseTransParam () {
      return 'flow';
    },
    getTitle () {
      if (this.editEl.id) {
        return this.$t(this.baseTransParam + '.actions.edit');
      }
      return this.$t(this.baseTransParam + '.actions.new');
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission('edit-flows');
    }
  },
  methods: {
    checkMinMaxValue () {
      if (!this.measurement.isOption && (('minRangeValue' in this.measurement.additionalData && this.testMeasurement.value < parseFloat(this.measurement.additionalData.minRangeValue)) ||
          ('maxRangeValue' in this.measurement.additionalData && this.testMeasurement.value > parseFloat(this.measurement.additionalData.maxRangeValue)))) {
        this.hasCalcResultError = true;
      } else {
        this.hasCalcResultError = false;
      }
    },
    checkPreviousMeasurementData () {
      if (this.productDataSet.factory_product_with_same_shift_has_quality_test.last_quality_test != null &&
        this.productDataSet.factory_product_with_same_shift_has_quality_test.last_quality_test.quality_test_measurements.length > 0) {
        const findMeasurement = this.productDataSet.factory_product_with_same_shift_has_quality_test.last_quality_test.quality_test_measurements.find(el =>
          el.quality_measurement_id == this.testMeasurement.quality_measurement_id)
        if (findMeasurement != undefined) {
          this.testMeasurement.value = findMeasurement.value;
        }
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    },
    updateNumberInput () {
      if (this.testMeasurement.inheritShiftValue) this.testMeasurement.value = ''
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  mounted () {
    this.isMounted = true
    this.checkMinMaxValue()
  },
  watch: {
    '$route' () {
    }
  }
}
