const defaultUsed = {
  id: null,
  downtime_type_id: null,
  product_id: null,
  quantity: null,
  from_warehouse_id: null,
  measure_type_id: null,
  alwaysAdd: true,
  primary: false,
  canEditQuantity: false,
  canWriteOffAsUsed: false,
  measureAsKg: false,
  product_group_id: null,
  usedMaterialGroupType: null,
  quantityRatio: 1
};

export default {
  name: 'ProduceusesEdit',
  props: {
    value: {
      type: Object | Array,
      default () {
        return [];
      }
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasError: {
      type: Boolean,
      default: false
    },
    typeCodes: {
      default: null,
      type: Array
    },
    siteId: {
      type: Number,
      default: null
    },
    withDowntime: {
      type: Boolean,
      default: false
    },
    isFlow: {
      type: Boolean,
      default: false
    },
    basetrans: {
      type: String,
      default: 'technologicalStage.produceuses'
    }
  },
  data () {
    return {
      content: this.value
    }
  },
  computed: {
    deviceOptions () {
      return this.$store.getters.getConfigOptions(
        'devices',
        this.$i18n.locale,
        'site_id',
        this.siteId,
        false,
        false,
        false,
        ['type_id']
      );
    },
    typeOptions () {
      return this.$store.getters.getConfigOptions('technological_stage_types', this.$i18n.locale)
    },
    downtimesTypesOptions () {
      return this.$store.getters.getConfigOptions('downtime_types', this.$i18n.locale)
    },
    productOptions () {
      return this.$store.getters.getConfigOptions(
        'products',
        this.$i18n.locale,
        (this.isFlow ? null : 'isChemical'),
        (this.isFlow ? null : true),
        false,
        false,
        false,
        ['isBought', 'isSeparated', 'isFinalProduct', 'isChemical', 'inAxapta', 'default_measure_type_id', 'd365_product_id']
      );
    },
    warehouseOptions () {
      return this.$store.getters.getConfigOptions('warehouses', this.$i18n.locale, 'inAxapta', true);
    },
    materialGroupType () {
      return this.$store.getters.getConfigOptions('product_groups', this.$i18n.locale);
    },
    usedMaterialGroupTypeOptions () {
      const data = []
      JSON.parse(this.$store.state.config.usedMaterialGroupType).forEach((value, index) => {
        data[index] = {}
        data[index].value = value.value;
        data[index].text = this.$t('technologicalStage.produceuses.usedMaterialGroupTypeOptions.' + value.name);
        data[index].initName = value.name;
      });
      return data
    }
  },
  methods: {
    updateValue () {
      this.$emit('input', this.content);
    },
    useProdChanged (val, useIdx) {
      if (val) {
        const prod = this.productOptions.find(el => el.value == val);
        if (prod) {
          this.content[useIdx].measure_type_id = prod.default_measure_type_id;
        } else {
          this.content[useIdx].measure_type_id = null;
        }
      } else {
        this.content[useIdx].measure_type_id = null;
      }
      this.updateValue();
      this.$forceUpdate();
    },
    updateAlwaysAddOption (useIdx) {
      if (this.content[useIdx] != undefined && this.content[useIdx].alwaysAdd == false) {
        this.content[useIdx].primary = false
        this.content[useIdx].canEditQuantity = false
        this.content[useIdx].canWriteOffAsUsed = false
        this.content[useIdx].measureAsKg = false
        this.content[useIdx].usedMaterialGroupType = null
      }
      this.updateValue();
    },
    productGroupChanged (useIdx) {
      const currentEl = this.content[useIdx];
      const findEl = this.content.find(el => el.product_group_id == currentEl.product_group_id && currentEl.usedMaterialGroupType != el.usedMaterialGroupType)
      if (findEl != undefined && findEl != null) {
        this.content[useIdx].usedMaterialGroupType = findEl.usedMaterialGroupType
      }
      this.updateValue();
    },
    usedMaterialGroupTypeChanged (useIdx) {
      const currentEl = this.content[useIdx];
      const findEl = this.content.find(el => el.product_group_id != currentEl.product_group_id && currentEl.usedMaterialGroupType == el.usedMaterialGroupType)
      if (findEl != undefined && findEl != null) {
        this.content.product_group_id = findEl.product_group_id
      }
      this.updateValue();
    },
    removeProductUsed (usedIdx) {
      if (this.content[usedIdx].id) {
        this.content[usedIdx].deleted = true;
      } else {
        this.content.splice(usedIdx, 1);
      }
      this.updateValue();
      this.$forceUpdate();
    },
    addProductUsed () {
      this.content.push(this.BasicHelper.cloneObject(defaultUsed));
      this.updateValue();
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (val) {
        if (val != this.content) {
          this.content = (val || []);
        }
      }
    }
  }
}
