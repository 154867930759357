import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import TechnologicalStageEdit from '@/components/pet/technologicalstage/edit/TechnologicalStageEdit.vue'
import UserPicker from 'ebg-vue-common/src/components/user/picker/UserPicker.vue'

const defaultEl = {
  id: null,
  code: '',
  name: {},
  reports_page_title: {},
  order: 0,
  color: '#000',
  lineDowntimes: [],
  is_disabled: false,
  shiftwarehouses: [],
  stages: [],
  notifyusers: [],
  availableCirculationReports: [],
  configuredCirculationReports: [],
  site_id: null
};

export default {
  name: 'LineEdit',
  data () {
    return {
      editEl: defaultEl,
      loading: false
    }
  },
  validations: {
    editEl: {
      code: { required },
      name: { required },
      site_id: { required },
      reports_page_title: { required },
      order: { }
    }
  },
  components: {
    TechnologicalStageEdit,
    UserPicker
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    baseTransParam () {
      return 'line';
    },
    getTitle () {
      if (this.editEl.id) {
        return this.$t(this.baseTransParam + '.actions.edit');
      }
      return this.$t(this.baseTransParam + '.actions.new');
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission('edit-lines');
    },
    shiftTypeOptions () {
      return this.$store.getters.getConfigOptions('shift_types', this.$i18n.locale);
    },
    warehouseOptions () {
      return this.$store.getters.getConfigOptions(
        'warehouses',
        this.$i18n.locale,
        'isAssembly',
        true,
        false,
        false,
        false,
        ['site_id'],
        true
      ).filter(el => el.site_id == this.editEl.site_id)
    },
    notifyuserHeaders () {
      const headers = [
        { key: 'user_id', label: this.$t('shift.notifyusers.user_id'), type: 'slot', slotcode: 'user' },
        { key: 'successAutoclose', label: this.$t('shift.notifyusers.successAutoclose'), type: 'slot', slotcode: 'checkbox', classTd: 'w-10' },
        { key: 'failedAutoclose', label: this.$t('shift.notifyusers.failedAutoclose'), type: 'slot', slotcode: 'checkbox', classTd: 'w-10' },
        { key: 'issueReported', label: this.$t('shift.notifyusers.issueReported'), type: 'slot', slotcode: 'checkbox', classTd: 'w-10' }
      ];
      const actions = [];
      if (this.canEdit) {
        actions.push({ label: this.$t('general.actions.delete'), emit: 'removeUser', bicon: 'trash', class: 'btn-sm btn-danger' });
      }
      headers.push({ key: 'actions', actions: actions });
      return headers;
    },
    operationalSiteOptions () {
      return this.$store.getters.getConfigOptions('operational_sites', this.$i18n.locale);
    }
  },
  methods: {
    show (elData) {
      if (elData) {
        this.editEl = this.BasicHelper.cloneObject(elData);
        this.editEl.lineDowntimes = (this.editEl.availableDowntimes[0] ? this.editEl.availableDowntimes[0] : [])
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
      }
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
      if (this.$refs.techStageEdit) {
        this.$refs.techStageEdit.hideValidation();
      }
    },
    save () {
      this.v$.$touch();
      this.$refs.techStageEdit.isValid();
      if (!this.v$.$invalid && !this.loading && this.$refs.techStageEdit.isValid()) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        const self = this;
        this.ApiRequest.request('lines/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, function (response) {
          if (response.error) {
            self.ApiRequest.displayErrorDialog(response, self);
          } else {
            self.$store.dispatch('reloadConfig');
            self.$emit('saved', response.data);
            self.close();
          }
          self.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    },
    addShiftType () {
      const type = this.shiftTypeOptions.find(el => {
        return !this.editEl.shiftwarehouses.find(subel => subel.shift_type_id == el.value);
      });
      if (type) {
        this.editEl.shiftwarehouses.push({ id: null, warehouse_id: null, shift_type_id: (type ? type.value : null) })
      }
    },
    removeShiftType (index) {
      this.editEl.shiftwarehouses.splice(index, 1);
    },
    addNewUser () {
      this.editEl.notifyusers.push({
        user_id: null,
        successAutoclose: true,
        failedAutoclose: true,
        issueReported: true
      })
    },
    userChanged (el, idx) {
      if (el) {
        this.editEl.notifyusers[idx].user_id = el.id;
      } else {
        this.editEl.notifyusers[idx].user_id = null;
      }
    },
    removeUser (el, newtab, row, idx) {
      this.editEl.notifyusers.splice(idx, 1);
      this.$refs.notifyusersTableview.$forceUpdate();
    }
  },
  setup () {
    return { v$: useVuelidate() }
  }
}
