export default {
  name: 'CargoOrderMove',
  data () {
    return {
      selectedOrderId: null,
      availableOrders: [],
      loading: false
    }
  },
  props: {
    isModal: {
      type: Boolean,
      default: true
    },
    customerId: {
      type: Number,
      default: null
    },
    vendorId: {
      type: Number,
      default: null
    },
    currentOrderId: {
      type: Number,
      default: null
    },
    orderCargoTypeId: {
      type: Number,
      default: null
    },
    cargoId: {
      type: Number,
      default: null
    }
  },
  computed: {
    getTitle () {
      return this.$t('cargo.actions.move');
    },
    availableOrdersRequest () {
      return {
        resourceType: 'AVAILABLE_CARGO_ORDERS',
        customerId: this.customerId,
        vendorId: this.vendorId,
        orderCargoTypeId: this.orderCargoTypeId,
        currentOrderId: this.currentOrderId,
        disableClosedStatus: true
      }
    },
    moveOrderRequest () {
      return {
        item: {
          selectedOrderId: this.selectedOrderId,
          currentOrderId: this.currentOrderId
        }
      }
    }
  },
  methods: {
    moveConfirmed (cargoCode) {
      const currentRoute = this.$route.name;
      if (currentRoute === 'menu.warehouse.cargos.view') {
        this.$router.push({ name: currentRoute, params: { cargocode: cargoCode } });
      } else if (currentRoute === 'menu.warehouse.purchase-orders.view' || currentRoute === 'menu.warehouse.sales-orders.view') {
        this.$emit('moveCargo');
      }
      this.close();
    },
    show () {
      this.loading = true;
      this.ApiRequest.request('orders/list-simple', this.ApiRequest.REQUEST_GET, this.availableOrdersRequest, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        } else {
          this.availableOrders = response.data
          this.$refs['modal-window'].show();
          this.loading = false;
        }
      });
    },
    save () {
      this.loading = true;
      this.ApiRequest.request('cargo-orders/move-order/' + this.cargoId, this.ApiRequest.REQUEST_POST, this.moveOrderRequest, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        } else {
          this.$refs.itemConfirmMsgBox.show(response.data.cargoNewLocalCode, this.$t('cargo.messages.move-successful-title'), this.$t('cargo.messages.move-successful'), 'lg', ['confirm'])
          this.loading = false;
        }
      });
    },
    close () {
      this.$refs['modal-window'].hide();
    }
  }
}
