export default {
  name: 'SalesOrderRequirements',
  components: {
  },
  props: {
    requirements: {
      type: Array,
      default: () => []
    },
    mandatoryRequirementIds: {
      type: Array,
      default: () => []
    },
    cargoId: {
      type: Number,
      default: null
    },
    canEdit: {
      type: Boolean,
      default: false
    },
    canApprove: {
      type: Boolean,
      default: false
    },
    validator: {
      default: () => null
    },
    size: {
      type: String,
      default: ''
    },
    addMandatoryRequirements: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      items: []
    };
  },
  validations: {
  },
  computed: {
    requirementOptions () {
      return this.$store.getters.getConfigOptions(
        'sales_order_requirements_options',
        this.$i18n.locale,
        null,
        null,
        false,
        false,
        false,
        ['mandatory']
      );
    },
    mandatoryRequirements () {
      if (this.addMandatoryRequirements) {
        return this.requirementOptions.filter(el => el.mandatory || this.mandatoryRequirementIds.find(reqId => reqId == el.value));
      }
      return [];
    },
    usedRequirementIds () {
      return this.items.map(el => el.requirement_id);
    }
  },
  methods: {
    checkedByInfo (el) {
      let res = '';
      if (el.checkedby) {
        res = el.checkedby.fullname + ': ';
      }
      res += this.BasicHelper.formatDate(el.checkedAt, this.$t('general.dateTimeFormat'));
      return res;
    },
    addRequirement () {
      this.items.push({
        id: null,
        requirement_id: null,
        checkedAt: null,
        cargo_id: this.cargoId
      });
    },
    deleteRequirement (el, newtab, action, idx) {
      this.items.splice(idx, 1);
    },
    updateRequirements () {
      if (!this.canEdit) {
        return;
      }
      this.mandatoryRequirements.forEach(el => {
        const findEl = this.items.find(subEl => subEl.requirement_id == el.value);
        if (!findEl) {
          this.items.push({
            id: null,
            checkedAt: null,
            requirement_id: el.value,
            cargo_id: this.cargoId
          })
        }
      });
    },
    displayDeleteReq (el) {
      if (el.checkedAt || this.mandatoryRequirements.find(subEl => subEl.value == el.requirement_id)) {
        return false;
      }
      return true;
    },
    checkRequirement (index) {
      this.items[index].loading = true;
      this.ApiRequest.request('cargo-orders/approve-requirement/' + this.cargoId, this.ApiRequest.REQUEST_POST, { requirement_id: this.items[index].requirement_id }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.items[index].loading = false;
        } else if (response.data) {
          this.items[index].checkedby = response.data.checkedby;
          this.items[index].checked_by_user_id = response.data.checked_by_user_id;
          this.items[index].checkedAt = response.data.checkedAt;
          this.items[index].loading = false;
        }
      });
    }
  },
  mounted () {
  },
  watch: {
    requirements: {
      immediate: true,
      handler (val) {
        if (val !== null) {
          this.items = val;
          this.updateRequirements();
        }
      }
    }
  }
}
