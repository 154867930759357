import useVuelidate from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';

const checkAvailableQualityMeasurementOptions = (value, siblings, vm) => {
  if (vm.validationEnabled == false) {
    return true
  }
  if (vm.measurement.additionalData.isRequiredField == true && value.length == 0 && vm.measurement.isOption) {
    return false;
  }
  return true;
}
export default {
  name: 'QualityTestMeasurement',
  data () {
    return {
      hasCalcResultError: false,
      hasFormulaCalcResultError: true,
      checkPreviousMeasurementDataError: false
    }
  },
  validations: {
    testMeasurement: {
      value: {
        required: requiredIf(function (key, row) {
          return this.validationEnabled && this.measurement.deleted_at == null &&
              this.measurement.additionalData.isRequiredField == true && this.measurement.isOption != true &&
              this.testMeasurement.inheritShiftValue == false
        })
      },
      availableQualityMeasurementOptions: { checkAvailableQualityMeasurementOptions }
    }
  },
  components: {
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    },
    validationEnabled: {
      type: Boolean,
      default: true
    },
    categoryParentInherit: {
      type: Boolean,
      default: false
    },
    checkParentCategoryInherit: {
      type: Boolean,
      default: false
    },
    isNewColumn: {
      type: Boolean,
      default: true
    },
    testSampleWeight: {
      type: Number,
      default: null
    },
    measurement: {
      type: Object,
      default: () => null
    },
    testMeasurement: {
      type: Object,
      default: () => {}
    },
    qualityBaseConfig: {
      type: Object,
      default: () => {}
    },
    productDataSet: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    checkboxOption () {
      return { title: 'test1', bicon: 'check' }
    },
    qualityMeasurementOptions () {
      return this.$store.getters.getConfigOptions('quality_measurement_options', this.$i18n.locale);
    },
    getQualityFormulaMeasurementConstants () {
      return JSON.parse(this.$store.state.config.qualityFormulaMeasurementConstants)
    },
    categoryOptionsIsOptions () {
      return this.$store.getters.getConfigOptions('quality_measurement_categories', this.$i18n.locale, 'isOption', 1);
    },
    calcInputMeasurementType () {
      this.checkPreviousMeasurementDataError = false;
      if (this.measurement.additionalData.hasCalculationInput && 'calcInputMeasurementType' in this.measurement.additionalData &&
          this.measureTypeOptions.find(el => el.value == this.measurement.additionalData.calcInputMeasurementType) != undefined) {
        const selectedCalcMeasurement = this.measureTypeOptions.find(el => el.value == this.measurement.additionalData.calcInputMeasurementType);
        if ((this.testMeasurement.value == null || this.testMeasurement.value == '')) {
          return selectedCalcMeasurement.text;
        } else {
          if (selectedCalcMeasurement.code == this.getQualityFormulaMeasurementConstants.find(el => el.name == 'PPM').value && 'coefficient' in this.measurement.additionalData) {
            const ppm = Math.floor((parseFloat(this.testMeasurement.value) *
                parseFloat(this.measurement.additionalData.coefficient) * Math.pow(10, 6) / (this.testSampleWeight ? (this.testSampleWeight * 1000) : this.qualityBaseConfig.weight)) * 100) / 100;
            if (!isNaN(ppm) && (ppm > this.measurement.additionalData.warningMaxRangeValue || ppm < this.measurement.additionalData.warningMinRangeValue)) {
              this.hasFormulaCalcResultError = true;
            } else {
              this.hasFormulaCalcResultError = false;
            }
            return ppm + ' ' + selectedCalcMeasurement.text
          }
          if (selectedCalcMeasurement.code == this.getQualityFormulaMeasurementConstants.find(el => el.name == 'PERCENT').value) {
            const percentage = (this.testMeasurement.value / (this.testSampleWeight ? (this.testSampleWeight * 1000) : this.qualityBaseConfig.weight) * 100).toFixed(2);
            if (!isNaN(percentage) && (percentage > this.measurement.additionalData.warningMaxRangeValue || percentage < this.measurement.additionalData.warningMinRangeValue)) {
              this.hasFormulaCalcResultError = true;
            } else {
              this.hasFormulaCalcResultError = false;
            }
            return percentage + ' ' + selectedCalcMeasurement.text
          }
        }
      }
      return undefined
    },
    qualityCategoryOptions () {
      return this.$store.getters.getConfigOptions('quality_measurement_categories', this.$i18n.locale);
    },
    measureTypeOptions () {
      return this.$store.getters.getConfigOptions('measure_types', this.$i18n.locale);
    },
    baseTransParam () {
      return 'flow';
    },
    getTitle () {
      if (this.editEl.id) {
        return this.$t(this.baseTransParam + '.actions.edit');
      }
      return this.$t(this.baseTransParam + '.actions.new');
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission('edit-flows');
    }
  },
  methods: {
    checkMinMaxValue () {
      if (this.testMeasurement.inheritShiftValue == true) return true;
      if (!this.measurement.isOption && (('minRangeValue' in this.measurement.additionalData && this.testMeasurement.value < parseFloat(this.measurement.additionalData.minRangeValue)) ||
          ('maxRangeValue' in this.measurement.additionalData && this.testMeasurement.value > parseFloat(this.measurement.additionalData.maxRangeValue)))) {
        this.hasCalcResultError = true;
      } else {
        this.hasCalcResultError = false;
      }
    },
    checkPreviousMeasurementData () {
      if (this.productDataSet.factory_product_with_same_shift_has_quality_test !== null &&
          this.productDataSet.factory_product_with_same_shift_has_quality_test.last_quality_test != null &&
        this.productDataSet.factory_product_with_same_shift_has_quality_test.last_quality_test.quality_test_measurements.length > 0) {
        const findMeasurement = this.productDataSet.factory_product_with_same_shift_has_quality_test.last_quality_test.quality_test_measurements.find(el =>
          el.quality_measurement_id == this.testMeasurement.quality_measurement_id)
        if (findMeasurement != undefined) {
          this.testMeasurement.value = findMeasurement.value;
          this.checkPreviousMeasurementDataError = false;
        } else {
          this.checkPreviousMeasurementDataError = true;
        }
      } else {
        this.checkPreviousMeasurementDataError = true;
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    },
    updateNumberInput () {
      this.$store.getters.inheritMeasurementValueFromShift(
        this.testMeasurement,
        this.productDataSet.shiftQualityMeasurement
      );
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  mounted () {
    if (!this.checkParentCategoryInherit) this.categoryParentInherit = false
    this.isMounted = true
    this.updateNumberInput()
    if (!this.checkParentCategoryInherit && this.testMeasurement.id == null &&
        this.measurement.additionalData.canEditOnFactoryProductShiftMeasurement == true && this.measurement.deleted_at == null) {
      this.testMeasurement.inheritShiftValue = true
    }
    if (this.measurement && this.measurement.isOption && this.measurement.additionalData != undefined && this.measurement.additionalData.defaultSelectedOptions != undefined &&
        this.measurement.additionalData.defaultSelectedOptions != null && this.measurement.additionalData.defaultSelectedOptions.length > 0) {
      if (this.testMeasurement && this.testMeasurement.id == null) {
        this.testMeasurement.availableQualityMeasurementOptions = this.measurement.additionalData.defaultSelectedOptions
      }
    }
  },
  watch: {
    '$route' () {
    },
    categoryParentInherit: {
      immediate: true,
      handler () {
        if (this.testMeasurement.inheritShiftValue != undefined && this.checkParentCategoryInherit) {
          this.testMeasurement.inheritShiftValue = this.categoryParentInherit;
        }
        this.updateNumberInput()
      }
    },
    testMeasurement: {
      inheritShiftValue: {
        immediate: true,
        handler () {
          if (!this.checkParentCategoryInherit) {
            this.updateNumberInput()
          }
        }
      }
    }
  }
}
