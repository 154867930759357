import LinkToDifferentSystem from 'ebg-vue-common/src/components/common/LinkToDifferentSystem.vue'

const defaultFilterValues = {
  localcode: '',
  partner: '',
  productname: '',
  warehouseCode: '',
  status: ['TEMPLATE', 'PLANNED', 'LOADING', 'MARKING', 'LOADED', 'FINISHED', 'QUARANTINE'],
  isIncoming: true,
  apuswasteclassCode: '',
  transportprovider: '',
  licencePlateOrTrailerPlateOrContainerNumber: '',
  ax2009code: '',
  apuscode: '',
  orderByMulti: [
    { key: 'deliveryDate', direction: 'DESC' }
  ]
};

export default {
  name: 'ReportIncomingCargo',
  components: {
    LinkToDifferentSystem
  },
  props: {
  },
  data () {
    return {
      loading: false,
      tableItems: [],
      paging: { limit: 50, page: 1, total: 0, pages: 0 },
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      fileBase64: null
    }
  },
  computed: {
    basePathComp () {
      return 'cargo-orders/report';
    },
    tableHeaders () {
      return [
        { key: 'status_id', label: '', type: 'slot', slotcode: 'statuscolor', classTd: 'position-relative p-0 w-color' },
        { key: 'localcode', label: this.$t('cargo.localcode'), type: 'slot', slotcode: 'localcode', sortable: false, filterKey: 'localcode', classTd: 'text-nowrap' },
        { key: 'deliveryDate', label: this.$t('cargo.deliveryDate'), type: 'date', sortable: true },
        { key: 'order.vendor.name', label: this.$t('purchase-order.vendor_id'), sortable: false, filterKey: 'partner' },
        { key: 'order.warehouse_id', label: this.$t('purchase-order.warehouse_id'), format: 'formatFromConfig', configKey: 'warehouses', configParam: 'code', filterKey: 'warehouseCode' },
        { key: 'productIds', label: this.$t('cargo.d365lines.product_id'), filterKey: 'productname', type: 'slot', slotcode: 'productIds' },
        { key: 'apuswasteclassIds', label: this.$t('cargo.d365lines.apus_waste_class'), type: 'slot', slotcode: 'apuswasteclassIds', filterKey: 'apuswasteclassCode' },
        { key: 'weight', label: this.$t('purchase-order.weight'), type: 'slot', slotcode: 'weight' },
        { key: 'weightDelta', label: this.$t('cargo.weightDelta'), format: 'formatDecimal', precision: 2, hidePrecision: true },
        { key: 'weightDifferenceKg', label: this.$t('cargo.weightDifferenceKg'), type: 'slot', slotcode: 'weightDifferenceKg', sortable: false },
        { key: 'transportprovider.name', label: this.$t('cargo.transport_provider_id'), filterKey: 'transportprovider' },
        { key: 'costtype', label: this.$t('cargo.reports.costtype'), type: 'slot', slotcode: 'costtype', filterKey: 'costtype', classTd: 'align-top' },
        { key: 'plannedTransportProvider', label: this.$t('cargo.reports.plannedTransportProvider'), type: 'slot', slotcode: 'plannedTransportProvider', filterKey: 'plannedTransportProvider', classTd: 'align-top' },
        { key: 'plannedcost', label: this.$t('cargo.reports.plannedCost'), type: 'slot', slotcode: 'plannedcost', classTd: 'align-top' },
        { key: 'transportLicensePlate', label: this.$t('cargo.transportLicensePlate') + '/' + this.$t('cargo.transportTrailerPlate') + '/' + this.$t('cargo.containerNumber'), type: 'slot', slotcode: 'licencePlateOrTrailerPlateOrContainerNumber', filterKey: 'licencePlateOrTrailerPlateOrContainerNumber' },
        { key: 'documents', label: this.$t('cargo.documents'), type: 'slot', slotcode: 'documents' },
        { key: 'apuscode', label: this.$t('cargo.apuscode'), filterKey: 'apuscode' },
        { key: 'billdata', label: this.$t('cargo.reports.transportBilldata'), type: 'slot', slotcode: 'billdata', classTd: 'align-top' }
      ];
    },
    tableFilters () {
      const filters = { };
      filters.localcode = { type: 'text', label: '', colOnlyFilter: true, controlClass: 'form-control-sm' };
      filters.partner = { type: 'text', label: '', colOnlyFilter: true, controlClass: 'form-control-sm' };
      filters.productname = { type: 'text', label: '', colOnlyFilter: true, controlClass: 'form-control-sm' };
      filters.warehouseCode = { type: 'text', label: '', colOnlyFilter: true, controlClass: 'form-control-sm' };
      filters.status = { type: 'checkbox', label: this.$t('purchase-order.status_id'), options: this.statusOptions, class: 'col-md-10', controlClass: 'btn-sm', textField: 'name', textTranslate: true };
      filters.apuswasteclassCode = { type: 'text', label: '', colOnlyFilter: true, controlClass: 'form-control-sm' };
      filters.transportprovider = { type: 'text', label: '', colOnlyFilter: true, controlClass: 'form-control-sm' };
      filters.licencePlateOrTrailerPlateOrContainerNumber = { type: 'text', label: '', colOnlyFilter: true, controlClass: 'form-control-sm' };
      filters.apuscode = { type: 'text', label: '', colOnlyFilter: true, controlClass: 'form-control-sm' };
      filters.productTypes = { type: 'checkbox', label: this.$t('cargo.productType'), options: this.productTypeOptions, class: 'col-md-10' };
      filters.deliveryDate = { type: 'date-range', label: this.$t('cargo.deliveryDate'), colOnlyFilter: false, class: 'col-md-2', controlClass: 'form-control-sm' };
      filters.costtype = { type: 'text', label: '', colOnlyFilter: true, controlClass: 'form-control-sm' };
      filters.plannedTransportProvider = { type: 'text', label: '', colOnlyFilter: true, controlClass: 'form-control-sm' };
      filters.documentsStatus = { type: 'checkbox', label: this.$t('cargo.documents'), options: this.documentsStatusOptions, class: 'col-md-3 col-sm-12 col-lg-2', valueField: 'value' };
      return filters;
    },
    tableActions () {
      const actions = { download: true, reload: true, filters: true };
      return actions;
    },
    requestPath () {
      const path = this.ApiRequest.addGetParamsToPath(this.basePathComp, this.filterValues);
      return path;
    },
    statusOptions () {
      let options = Object.values(this.$store.getters.getItemsFromConfigWithFilter('cargo_statuses', null, null));
      options = this.BasicHelper.sortArrayByKeyValue(options, 'order');
      options = options.filter(el => {
        if (el.code == 'ASSEMBLY' || el.code == 'ASSEMBLED') {
          return false;
        }
        return true;
      });
      options.forEach(el => {
        el.colorCheckbox = true;
        el.labelSpanClass = 'rounded ps-1 pe-1';
      })
      return options;
    },
    quarantineStatusId () {
      return this.BasicHelper.getConfigValue('cargo_statuses', 'code', 'QUARANTINE', 'id')
    },
    productTypeOptions () {
      return this.$store.getters.getConfigOptions('product_types', this.$i18n.locale);
    },
    documentsStatusOptions () {
      return [
        { value: 'CMR_CREATED', text: this.$t('cargo.documentsStatus.cmrCreated'), bicon: 'file-earmark-pdf-fill' },
        { value: 'CMR_NOT_CREATED', text: this.$t('cargo.documentsStatus.cmrNotCreated'), bicon: 'file-earmark-pdf-fill', class: 'text-danger' },
        { value: 'BILL_CREATED', text: this.$t('cargo.documentsStatus.billCreated'), bicon: 'journal-check', class: 'text-dark' },
        { value: 'BILL_WITH_BRNUMBER', text: this.$t('cargo.documentsStatus.billWithBrnumber'), bicon: 'journal-check', colorCheckbox: true, color: '#198754' },
        { value: 'BILL_WITHOUT_BRNUMBER', text: this.$t('cargo.documentsStatus.billWithoutBrnumber'), bicon: 'journal-check', class: 'text-secondary' }
      ]
    }
  },
  methods: {
    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
      this.loading = true;
      const path = this.requestPath + '&perPage=' + this.paging.limit + '&page=' + this.paging.page;
      this.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, [], (response) => {
        if (response.data) {
          this.tableItems = response.data;
          this.paging = response.paging;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    viewFile (cargoId, fileId) {
      this.fileBase64 = null;
      this.ApiRequest.request('cargo-orders/files/' + cargoId + '/view/' + fileId, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.error) {
          this.$store.commit('toastAdd', {
            context: 'danger',
            message: this.ApiRequest.getApiResponseErrorMessage(response, {})
          })
        } else if (response.data) {
          this.fileBase64 = response.data.base64;
          this.$refs.filepreview.show();
        }
      });
    },
    getIconParam (file, key, bicon) {
      const value = file[key]
      let text = ''
      if (value) {
        text = value;
      } else {
        text = this.$t('modelfiles.link.not-added');
      }
      const title = this.$t(`modelfiles.link.${key}`, null, { number: text })
      const classData = `bi bi-${bicon} btn btn-sm p-0 m-1` + (file[key] ? ' text-white btn-success fs-7' : ' text-secondary ')
      return { title: title, classData: classData }
    },
    fileLinkParams (file) {
      return { name: 'menu.billing.record.view', path: 'view/:code/:archiveId?', code: file.d365brnumber ?? 'NEW', archiveId: file.d365Id }
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    download (html, exportCols) {
      this.$refs.tableview.downloadTableData(this.requestPath, exportCols);
    },
    getStatusColor (statusId) {
      const status = this.statusOptions.find(el => el.id == statusId);
      if (status) {
        return status.color;
      }
      return '#FFF';
    },
    getStatusName (statusId) {
      return this.BasicHelper.getConfigValue('cargo_statuses', 'id', statusId, 'name');
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.getTableData();
  }
}
