import ProductFeedingAdd from '@/components/pet/product/feeding/add/ProductFeedingAdd.vue'
import ProductFeedingEdit from '@/components/pet/product/feeding/edit/ProductFeedingEdit.vue'
import PetHelper from '@/helpers/PetHelper';
import MqttDevice from 'ebg-vue-common/src/components/common/mqttdevice.vue';

const defaultFilterValues = {
  code: '',
  viewFullInfo: false,
  fed_at: null,
  type: [],
  usageTypes: [],
  flow_id: [],
  line_id: [],
  product: ''
};

export default {
  name: 'ProductFeedingList',
  components: {
    ProductFeedingAdd,
    ProductFeedingEdit,
    MqttDevice
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      tableBoughtProducts: [],
      tableSeparatedProducts: [],
      tableFinalProducts: [],
      tableWithoutD365Products: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false,
      loadedShiftId: null
    };
  },
  props: {
    singleShift: {
      type: Boolean,
      default: false
    },
    shift: {
      type: Object,
      default: () => null
    },
    flowId: {
      type: Number,
      default: null
    },
    isChemical: {
      type: Boolean,
      default: false
    },
    rfidDeviceId: {
      type: Number | String,
      default: null
    },
    qrDeviceId: {
      type: Number | String,
      default: null
    }
  },
  computed: {
    basePathComp () {
      return 'fed-products'
    },
    basePerm () {
      return 'shift-fed-products';
    },
    baseTransParam () {
      return 'fed-products';
    },
    tableHeaders () {
      const headers = this.BasicHelper.cloneObject(this.tableHeadersDefault);
      headers.unshift({ key: 'factoryproduct.code', label: this.$t('fed-products.factoryproduct'), sortable: false, headerClass: 'w-20' });
      return headers;
    },
    tableHeadersDefault () {
      const headers = [];
      if (!this.singleShift) {
        headers.push({ key: 'fed_at', label: this.$t('shift.startdate'), sortable: false, type: 'datetime', filterKey: 'fed_at' });
        headers.push({ key: 'flow_id', label: this.$t(this.baseTransParam + '.flow_id'), format: 'formatFromConfig', configKey: 'line_flows', configParam: 'flowcode' })
        headers.push({ key: 'usageType', label: this.$t(this.baseTransParam + '.usageType'), type: 'slot', slotcode: 'usageType' })
      } else {
        headers.push({ key: 'fed_at', label: this.$t('fed-products.fed_at'), sortable: false, type: 'time', headerClass: 'w-5' })
      }
      if (this.filterValues.viewFullInfo) {
        headers.push({ key: 'stage.name.' + this.$i18n.locale, label: this.$t('fed-products.stage_id'), headerClass: 'w-20' });
      }
      headers.push({ key: 'product_id', label: this.$t('fed-products.product_id'), sortable: false, format: 'formatFromConfig', configKey: 'products', configParam: 'name', headerClass: 'w-20', filterKey: 'product' });
      headers.push({ key: 'type_id', label: this.$t('fed-products.type_id'), format: 'formatFromConfig', configKey: 'feeding_types', configParam: 'name' });
      headers.push({ key: 'quantity', label: this.$t('fed-products.quantity'), sortable: false, format: 'formatDecimal', precision: 0 });
      if (!this.singleShift) {
        headers.push({ key: 'measure_type_id', label: this.$t(this.baseTransParam + '.measure_type_id'), format: 'formatFromConfig', configKey: 'measure_types', configParam: 'name' });
        headers.push({ key: 'forfactoryproduct.code', label: this.$t('fed-products.forfactoryproduct') });
      }

      if (this.filterValues.viewFullInfo) {
        if (!this.isChemical) {
          headers.push({ key: 'isBale', label: this.$t('fed-products.isBale'), sortable: false, format: 'formatYesNo' });
        }
        headers.push({ key: 'user.fullname', label: this.$t('fed-products.user'), sortable: false });
      }
      if (this.singleShift) {
        headers.push({ key: 'flow_id_name', label: this.$t('flow.name'), sortable: false, format: 'formatFromConfig', configKey: 'line_flows', configParam: 'name' });
      }
      headers.push({ key: 'comment.comment', label: this.$t('fed-products.comment') })
      const actions = [];
      if (this.canRevert) {
        actions.push({ label: this.$t('fed-products.actions.revert'), emit: 'revertFeeding', bicon: 'backspace-fill', class: 'btn-danger btn-sm' });
      }
      if (this.canEdit) {
        actions.push({ label: this.$t('general.actions.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-primary btn-sm' });
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    tableExpandConfig () {
      return null;
    },
    tableFilterConfig () {
      const filters = { };
      filters.product = { type: 'text', label: '', colOnlyFilter: true };
      if (!this.singleShift) {
        filters.type = { type: 'checkbox', label: this.$t(this.baseTransParam + '.type_id'), options: this.typeOptions, class: 'col-12' };
        filters.usageTypes = { type: 'checkbox', label: this.$t(this.baseTransParam + '.usageTypes'), options: this.usageTypeOptions };
        filters.line_id = { type: 'slot', label: this.$t(this.baseTransParam + '.line_id'), slotcode: 'linefilter' };
        filters.flow_id = { type: 'checkbox', label: (this.flowOptions.length > 0 ? this.$t(this.baseTransParam + '.flow_id') : ''), options: this.flowOptions, textField: 'flowcode', valueField: 'value' };
        filters.fed_at = { type: 'date-range', label: '', colOnlyFilter: true };
        filters.viewFullInfo = { type: 'checkbox-single', label: this.$t('general.filters.fullview'), inlineLabel: true, class: 'mt-3 col-4' };
      } else {
        filters.viewFullInfo = { type: 'checkbox-single', label: this.$t('general.filters.fullview'), inlineLabel: true, class: 'position-absolute end-0 col-4' };
      }
      return filters;
    },
    canDelete () {
      return this.ApiRequest.userHasPermission('delete-' + this.basePerm);
    },
    canAdd () {
      return (!this.isChemical && this.shift && PetHelper.validShiftDateInterval(this.shift) && this.shift.canFeed &&
          this.ApiRequest.userHasPermission('edit-' + this.basePerm));
    },
    canEdit () {
      return (PetHelper.validShiftDateInterval(this.shift) && (this.ApiRequest.userHasPermission('edittime-shift-fed-products') ||
        (this.shift && this.shift.canFeed && this.ApiRequest.userHasPermission('edit-' + this.basePerm)))
      );
    },
    canRevert () {
      return (this.shift && PetHelper.validShiftDateInterval(this.shift) && this.shift.canFeed &&
          this.ApiRequest.userHasPermission('delete-shift-fed-products'));
    },
    yesNoOptions () {
      return this.BasicHelper.yesNoOptions();
    },
    requestPath () {
      let path = this.basePathComp + '/list';
      if (this.shift) {
        this.filterValues.shift_id = this.shift.id;
        if (this.isChemical) {
          this.filterValues.productIsChemical = '1';
        } else {
          this.filterValues.productIsChemical = '0';
        }
        this.filterValues.flow_id = this.flowId;
        path += '/1';
      }
      path = this.ApiRequest.addGetParamsToPath(path + '?perPage=' + this.paging.limit + '&page=' + this.paging.page, this.filterValues, true);
      path += '&order=fed_at,desc';
      return path;
    },
    tableActions () {
      const actions = { download: true };
      if (this.canAdd) {
        actions.add = { title: this.$t('general.actions.add') };
      }
      return actions;
    },
    typeOptions () {
      return this.$store.getters.getConfigOptions('feeding_types', this.$i18n.locale);
    },
    usageTypeOptions () {
      return this.PetHelper.getProductUsageTypeOptions(false);
    },
    lineOptions () {
      return this.$store.getters.getAvailableLineOptions(this.$i18n.locale);
    },
    flowOptions () {
      return this.$store.getters.getConfigOptions(
        'line_flows',
        this.$i18n.locale,
        'line_id',
        this.filterValues.line_id,
        false,
        false,
        true,
        ['flowcode']
      );
    },
    qrDevices () {
      return this.$store.getters.getDevicesByType('QRREADER')
    },
    rfidDevices () {
      return this.$store.getters.getDevicesByType('RFIDREADER');
    },
    qrDevice () {
      let device = null
      if (this.qrDeviceId) {
        device = Object.values(this.qrDevices).find(el => el.id == this.qrDeviceId);
      }
      return device;
    },
    rfidDevice () {
      let device = null
      if (this.rfidDeviceId) {
        device = Object.values(this.rfidDevices).find(el => el.id == this.rfidDeviceId);
      }
      return device;
    },
    tableColumnKeys () {
      return this.tableHeaders.map(header => ({ key: header.key, name: header.key }));
    }
  },
  methods: {
    getTableData () {
      if (this.basePathComp) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        this.loading = true;
        this.loadedShiftId = this.shift ? this.shift.id : null;
        this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.tableItems = response.data;
            if (this.singleShift) {
              this.tableSeparatedProducts = [];
              this.tableFinalProducts = [];
              this.tableBoughtProducts = [];
              this.tableWithoutD365Products = [];
              for (const product of response.data) {
                if (this.isSeparatedProduct(product.product_id)) {
                  this.tableSeparatedProducts.push(product);
                } else if (this.isFinalProduct(product.product_id)) {
                  this.tableFinalProducts.push(product);
                } else if (this.isBoughtProduct(product.product_id)) {
                  this.tableBoughtProducts.push(product);
                } else if (this.isGroupNameProduct(product.product_id, 'withoutD365Product')) {
                  this.tableWithoutD365Products.push(product);
                }
              }
            }
            this.paging = response.paging;
            this.loading = false;
          }
        });
      }
    },
    feedingDataChanged () {
      this.getTableData();
      this.$emit('saved');
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    editItem (el) {
      if (el) {
        this.$refs.editElFrm.show(el, (this.canAdd && el.factory_product_id == null), this.isGroupNameProduct(el.product_id, 'withoutD365Product'));
      } else {
        this.$refs.newElFrm.show(this.shift, this.flowId, this.isChemical, null)
      }
    },
    revertFeeding (el, approved = 0) {
      if (approved) {
        const path = `fed-products/revert/${el.id}`;
        this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, { }, (response) => {
          if (response.data) {
            this.$store.commit('toastAdd', {
              context: 'success',
              message: this.$t('fed-products.actions.revertDone')
            });
            this.getTableData();
          } else if (response.error) {
            this.$store.commit('toastAdd', {
              context: 'danger',
              message: this.ApiRequest.getApiResponseErrorMessage(response, {})
            })
          }
          this.loading = false;
        });
      } else {
        this.$refs.itemRevertFeedingMessageBox.show(
          el,
          this.$t('fed-products.actions.revert'),
          this.$t('fed-products.actions.revertMessage', {
            quantity: el.quantity,
            product: this.BasicHelper.getConfigValue('products', 'id', el.product_id, 'name')
          })
        );
      }
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    },
    deleteItem (el, approved) {
      if (approved) {
        this.setDeleted(el, 1);
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t(this.baseTransParam + '.actions.deleteTitle'), this.$t(this.baseTransParam + '.actions.deleteMessage', { name: this.BasicHelper.getTranslation(el.name), code: el.code }))
      }
    },
    restoreItem (el) {
      this.setDeleted(el, 0);
    },
    setDeleted (el, isDeleted) {
      this.loading = true;
      this.ApiRequest.request(this.basePathComp + '/deleted', this.ApiRequest.REQUEST_POST, { id: el.id, code: el.code, deleted: isDeleted }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        } else {
          this.getTableData();
        }
      });
    },
    isBoughtProduct (productId) {
      return this.$store.getters.isBoughtProduct(productId)
    },
    isSeparatedProduct (productId) {
      return this.$store.getters.isSeparatedProduct(productId)
    },
    isFinalProduct (productId) {
      return this.$store.getters.isFinalProduct(productId)
    },
    isGroupNameProduct (productId, groupname) {
      return this.$store.getters.isGroupNameProduct(productId, groupname)
    },
    lineFilterChanged () {
      this.filterValues.flow_id = this.filterValues.flow_id.filter(flowId => (this.flowOptions.findIndex(el => el.value == flowId) >= 0));
      this.getTableData();
    },
    qrDataRead (val) {
      this.showAddForm(val);
    },
    rfidDataRead (val) {
      if (this.canAdd && this.shift && val) {
        this.showAddForm(null, val);
      }
    },
    showAddForm (qr, rfid) {
      if (this.canAdd && this.shift) {
        this.$refs.newElFrm.show(this.shift, this.flowId, this.isChemical, null, qr, rfid, true)
      }
    },
    download () {
      this.$refs.tableview.downloadTableData(this.requestPath, this.tableColumnKeys);
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.getTableData();
    this.isMounted = true;
  },
  watch: {
    shift: {
      immediate: true,
      handler (val) {
        if (this.isMounted && val && val.id != this.loadedShiftId) {
          this.getTableData()
        }
      }
    },
    flowId: {
      immediate: true,
      handler (val) {
        if (this.isMounted && val) {
          this.getTableData();
        }
      }
    }
  }
}
