import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import TechnologicalStageEdit from '@/components/pet/technologicalstage/edit/TechnologicalStageEdit.vue'

const defaultEl = {
  id: null,
  code: '',
  name: {},
  order: 0,
  inAxapta: false,
  site_id: null,
  isAssembly: 0,
  stages: [],
  address: '',
  city: null,
  receiveOrders: false,
  rows: 0,
  floors: 0,
  placesInRow: 0,
  warehouseLayoutMap: {},
  freeSpaceAfterRows: [],
  warehouse_places: [],
  sendOrders: false,
  rowData: {}
};
const checkRowData = (value, siblings, vm) => {
  let result = true
  const existingNames = []
  if (siblings.rows > 0) {
    const arrayRange = Array.from({ length: siblings.rows }, (_, i) => i);
    arrayRange.forEach(arrayEl => {
      if (siblings.rowData[arrayEl] == undefined || siblings.rowData[arrayEl] == '') {
        result = false
      } else {
        if (existingNames.includes(siblings.rowData[arrayEl])) {
          result = false
        } else {
          existingNames.push(siblings.rowData[arrayEl])
        }
      }
    })
  }
  return result;
}
export default {
  name: 'WarehouseEdit',
  data () {
    return {
      editEl: defaultEl,
      loading: false,
      isMakingWarehouseLayoutMap: false,
      makeMapTimeoutID: null,
      inputRows: null,
      inputFloors: null,
      inputPlacesInRow: null
    }
  },
  validations: {
    editEl: {
      code: { required },
      name: { required },
      order: { },
      site_id: { required },
      rowData: { checkRowData }
    }
  },
  components: {
    TechnologicalStageEdit
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    baseTransParam () {
      return 'warehouse';
    },
    getTitle () {
      if (this.editEl.id) {
        return this.$t(this.baseTransParam + '.actions.edit');
      }
      return this.$t(this.baseTransParam + '.actions.new');
    },
    tabOptions () {
      return [
        {
          code: 'warehouse_layout',
          text: this.$t('warehouse.tabs.warehouse_layout.title'),
          addNew: true
        },
        {
          code: 'stages_and_devices',
          text: this.$t('warehouse.tabs.stages_and_devices.title'),
          addNew: true
        }
      ]
    },
    tabOptionRows () {
      const options = [];
      if (this.editEl.rows < 1) return options;
      for (const index of Array(this.editEl.rows).keys()) {
        options.push({
          code: 'WR_' + index + 1,
          text: this.editEl.rowData[index] !== undefined ? this.editEl.rowData[index] : index + 1,
          rowIndex: index + 1
        });
      }
      return options;
    },
    freeSpaceAfterRowsOptions () {
      const options = [];
      if (this.editEl.rows < 1) return options;
      for (const index of Array(this.editEl.rows).keys()) {
        options.push({
          value: index + 1,
          text: this.editEl.rowData[index] !== undefined ? this.editEl.rowData[index] : (index).toString()
        });
      }
      return options;
    },
    warehousePlaceStateOptions () {
      return this.$store.getters.getConfigOptions('warehouse_place_states', this.$i18n.locale);
    },
    defaultStateOption () {
      return this.warehousePlaceStateOptions.find((option) => option.code === 'SHELF');
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission('edit-warehouses');
    },
    canEditWarehouseLayout () {
      return [0, 1].includes(parseInt(this.editEl.isAssembly));
    },
    isAssemblyOptions () {
      const res = [];
      [0, 1, 2].forEach(type => {
        res.push({ value: type, text: this.$t('warehouse_isAssembly.' + type) });
      });
      return res;
    },
    operationalSiteOptions () {
      return this.$store.getters.getConfigOptions('operational_sites', this.$i18n.locale);
    }
  },
  methods: {
    setComponentData (code) {
      if (code && code !== 'NEW') {
        this.loadComponent = true;
        this.ApiRequest.request('warehouses/view-by-code/' + code, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else if (response.data) {
            this.editEl = this.BasicHelper.cloneObject(response.data);
            if (this.editEl.stages.length > 0) {
              const vm = this;
              this.editEl.stages.forEach(function callback (value, index) {
                if (value.workstationIds !== undefined && value.workstationIds.length > 0) {
                  vm.editEl.stages[index].workstation_ids = value.workstationIds;
                }
              });
            }

            if (!this.editEl.freeSpaceAfterRows) {
              this.editEl.freeSpaceAfterRows = [];
            }
            if (this.editEl.rowData == null) {
              this.editEl.rowData = {}
            }
            this.inputRows = (this.editEl.rows > 0) ? this.editEl.rows : null;
            this.inputFloors = (this.editEl.floors > 0) ? this.editEl.floors : null;
            this.inputPlacesInRow = (this.editEl.placesInRow > 0) ? this.editEl.placesInRow : null;
            this.makeWarehouseLayoutMap();
          } else {
            this.editEl = null;
          }
          this.loadComponent = false;
          this.v$.$reset();
          if (this.$refs.techStageEdit) {
            this.$refs.techStageEdit.hideValidation();
          }
        });
      }
    },
    show (elData) {
      if (elData) {
        this.editEl = this.BasicHelper.cloneObject(elData);
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
      }
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
      if (this.$refs.techStageEdit) {
        this.$refs.techStageEdit.hideValidation();
      }
    },
    save () {
      this.v$.$touch();
      this.$refs.techStageEdit.isValid();
      if (!this.v$.$invalid && !this.loading && this.$refs.techStageEdit.isValid()) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        this.ApiRequest.request('warehouses/edit', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$store.dispatch('reloadConfig');
            this.$emit('saved', response.data);
            this.setComponentData(response.data.code)
            this.close();
          }
          this.loading = false;
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    },
    makeCoordinateKey (row, floor, placeInRow) {
      return `${row}-${floor}-${placeInRow}`;
    },
    isNotWarehousePlaceInBounds (warehousePlace, dimensions) {
      if (warehousePlace.row > dimensions.rows || warehousePlace.row < 1) {
        return true;
      }
      if (warehousePlace.floor > dimensions.floors || warehousePlace.floor < 1) {
        return true;
      }
      if (warehousePlace.placeInRow > dimensions.placesInRow || warehousePlace.placeInRow < 1) {
        return true;
      }
      return false;
    },
    warehouseLayoutChanged () {
      clearTimeout(this.makeMapTimeoutID);
      const makeMap = () => {
        if (!this.isMakingWarehouseLayoutMap) {
          this.makeWarehouseLayoutMap();
          return;
        }
        setTimeout(() => {
          makeMap();
        }, 100);
      };
      this.makeMapTimeoutID = setTimeout(() => {
        makeMap();
      }, 500)
    },
    setEditElDimensions (dimensions) {
      this.editEl.rows = dimensions.rows;
      this.editEl.floors = dimensions.floors;
      this.editEl.placesInRow = dimensions.placesInRow;
    },
    makeWarehouseLayoutMap () {
      this.isMakingWarehouseLayoutMap = true;
      const tempWarehouseLayoutMap = {};
      const dimensions = {
        rows: (this.inputRows > 0) ? this.inputRows : 0,
        floors: (this.inputFloors > 0) ? this.inputFloors : 0,
        placesInRow: (this.inputPlacesInRow > 0) ? this.inputPlacesInRow : 0
      };
      if (
        dimensions.rows < 1 ||
        dimensions.floors < 1 ||
        dimensions.placesInRow < 1
      ) {
        this.$set(this.editEl, 'warehouseLayoutMap', tempWarehouseLayoutMap);
        this.setEditElDimensions(dimensions);
        this.isMakingWarehouseLayoutMap = false;
        return;
      }
      const defaultStateOption = this.defaultStateOption ?? null;
      if (!defaultStateOption) return;
      for (const warehousePlace of this.editEl.warehouse_places) {
        if (this.isNotWarehousePlaceInBounds(warehousePlace, dimensions)) {
          continue;
        }
        const coordinateKey = this.makeCoordinateKey(warehousePlace.row, warehousePlace.floor, warehousePlace.placeInRow);
        tempWarehouseLayoutMap[coordinateKey] = {
          warehouse_id: warehousePlace.warehouse_id,
          row: warehousePlace.row,
          floor: warehousePlace.floor,
          placeInRow: warehousePlace.placeInRow,
          warehouse_place_state_id: warehousePlace.warehouse_place_state_id
        };
      }
      for (const rowIndex of Array(dimensions.rows).keys()) {
        for (const floorIndex of Array(dimensions.floors).keys()) {
          for (const placeIndex of Array(dimensions.placesInRow).keys()) {
            const row = rowIndex + 1;
            const floor = floorIndex + 1;
            const placeInRow = placeIndex + 1;
            const coordinateKey = this.makeCoordinateKey(row, floor, placeInRow);
            if (tempWarehouseLayoutMap[coordinateKey]) {
              continue;
            }
            tempWarehouseLayoutMap[coordinateKey] = {
              warehouse_id: this.editEl.id,
              row: row,
              floor: floor,
              placeInRow: placeInRow,
              warehouse_place_state_id: defaultStateOption.value
            };
          }
        }
      }
      this.$set(this.editEl, 'warehouseLayoutMap', tempWarehouseLayoutMap);
      this.setEditElDimensions(dimensions);
      this.isMakingWarehouseLayoutMap = false;
    }
  },
  setup () {
    return { v$: useVuelidate() }
  },
  mounted () {
    this.isMounted = true;
    this.setComponentData(this.$route.params.code);
  },
  watch: {
    '$route' () {
      this.setComponentData(this.$route.params.code);
    },
    inputRows: {
      immediate: false,
      handler (val) {
        let i = 1;
        Object.keys(this.editEl.rowData).forEach(rowIndex => {
          if (i > val) {
            delete this.editEl.rowData[rowIndex]
          }
          i++;
        })
      }
    }
  }
}
