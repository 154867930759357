import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';

const defaultEl = {
  from_warehouse_id: null,
  produced_warehouse_id: null
};

export default {
  name: 'FlowStagesWarehouseEdit',
  data () {
    return {
      editEl: defaultEl,
      loading: false
    }
  },
  validations: {
    editEl: {
      from_warehouse_id: { required },
      produced_warehouse_id: { required }
    }
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    isModal: {
      type: Boolean,
      default: true
    },
    stages: {
      type: Array,
      default () {
        return [];
      }
    }
  },
  computed: {
    getTitle () {
      return this.$t('flow.update-warehouse');
    },
    warehouseOptions () {
      return this.$store.getters.getConfigOptions('warehouses', this.$i18n.locale, 'inAxapta', true);
    }
  },
  methods: {
    show () {
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    save () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        if (this.stages) {
          Object.values(this.stages).forEach(stage => {
            Object.values(stage.products).forEach(product => {
              if (product.canProduce && product.produced_warehouse_id) {
                product.produced_warehouse_id = this.editEl.produced_warehouse_id;
              }
              Object.values(product.produceuses).forEach(produce => {
                if (produce.from_warehouse_id) {
                  produce.from_warehouse_id = this.editEl.from_warehouse_id;
                }
              });
            });
          });
        }
        this.$emit('warehouseUpdated');
        this.close();
        this.loading = false;
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    }
  },
  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'FlowStagesWarehouseEdit' }) }
  }
}
