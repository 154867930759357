import D365List from '@/components/d365/list/D365List.vue'

export default {
  name: 'D365InventoryTransferLines',
  components: {
    D365List
  },
  props: {
    id: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      order: null
    };
  },
  computed: {
    basePath () {
      return `cargo-orders/d365/${this.id}`;
    },
    baseTransParam () {
      return 'cargo.d365';
    },
    tableHeaders () {
      const headers = [
        { key: 'LineNumber', label: this.$t(`${this.baseTransParam}.LineNumber`) },
        { key: 'ItemNumber', label: this.$t(`${this.baseTransParam}.ItemNumber`) },
        { key: 'InventoryUnitSymbol', label: this.$t(`${this.baseTransParam}.SalesUnitSymbol`) },
        { key: 'InventoryQuantity', label: this.$t(`${this.baseTransParam}.OrderedSalesQuantity`), format: 'formatDecimal', precision: 3, hidePrecision: true },
        { key: 'SourceItemBatchNumber', label: this.$t(`${this.baseTransParam}.ItemBatchNumber`) },
        { key: 'actions', actions: [] }
      ]
      return headers;
    }
  },
  methods: {
    getTableData () {
      this.$refs.d365list.getTableData();
    }
  },
  mounted () {
  },
  watch: {
  }
}
