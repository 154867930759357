import useVuelidate from '@vuelidate/core';
import { helpers, required, requiredIf } from '@vuelidate/validators';
import PartnerPicker from '@/components/pet/partners/picker/PartnerPicker.vue'
import LoadingAddressesEdit from '@/components/pet/partners/editloadingaddresses/LoadingAddressesEdit.vue'
import SalesStatusInfo from '@/components/pet/sales/statusinfo/SalesStatusInfo.vue'
import LogList from 'ebg-vue-common/src/components/log/list/LogList.vue'
import Comments from 'ebg-vue-common/src/components/common/comments/Comments.vue';
import ModelFileList from '@/components/modelfile/list/ModelFileList.vue'
import LinkToDifferentSystem from 'ebg-vue-common/src/components/common/LinkToDifferentSystem.vue'
import CargoList from '@/components/pet/sales/cargo/list/CargoList.vue'
import TransportCostList from '@/components/pet/sales/order/transportCost/list/TransportCostList.vue'
import OrderProductList from '@/components/pet/sales/order/productlist/OrderProductList.vue'
import UserPicker from 'ebg-vue-common/src/components/user/picker/UserPicker.vue'
import OrderCargoDocuments from '@/components/pet/sales/cargo/CargoDocuments/OrderCargoDocuments.vue';

const defaultEl = {
  id: null,
  order_cargo_type_id: null,
  vendor_id: null,
  customer_id: null,
  orderDate: null,
  clientcode: '',
  plannedDeliveryMonth: null,
  partner_address_id: null,
  warehouse_id: null,
  lines: [],
  products: [],
  cargos: [],
  transport_costs: [],
  sales_manager_id: null,
  sales_manager: null,
  signature_contact_person_id: null,
  to_warehouse_id: null,
  to_operational_site_id: null,
  local_warehouse_id: null,
  documentParameters: [],
  payment_term_id: null,
  delivery_type_id: null,
  currency_id: null,
  selectedContainers: []
};

const checkDuplicateCostType = (value, siblings, vm) => {
  const checkIds = []
  let res = true;
  if (value.length > 0) {
    value.forEach(el => {
      if (!res) return;
      if (checkIds.includes(el.chargeCode_id) && el.chargeCode_id != null) {
        res = false
      } else {
        if (el.chargeCode_id != null) checkIds.push(el.chargeCode_id)
      }
    })
  }
  return res;
}

export default {
  name: 'OrderEdit',
  data () {
    return {
      editEl: defaultEl,
      containerOptions: [],
      containerOptionsLoaded: false,
      loading: false,
      readonly: false,
      commentCount: null,
      refreshCargo: 1,
      refreshCargoStatus: 1,
      selectedCargoId: null,
      preselectedPartner: null,
      availableSalesManagerUsers: [],
      allAvailableSalesManagerUsers: [],
      allAvailableWareHouseEditorUsers: [],
      updateCargoOrdersSalesManager: false,
      initEditEl: null,
      orderCargoDocuments: [],
      availableSignatureContactOptions: [],
      cargoStatuses: []
    }
  },
  validations: {
    editEl: {
      payment_term_id: {
        required: requiredIf(function () {
          return !this.isTransferOrder && !this.isTemplate;
        })
      },
      vendor_id: {
        required: requiredIf(function () {
          return this.isIncoming && this.editEl.needVendorInfo;
        })
      },
      customer_id: {
        required: requiredIf(function () {
          return !this.isIncoming && !this.isTransferOrder;
        })
      },
      currency_id: {
        required: requiredIf(function () {
          return !this.isTransferOrder;
        })
      },
      orderDate: { required },
      plannedDeliveryMonth: { required },
      warehouse_id: { required },
      to_warehouse_id: {
        required: requiredIf(function () {
          return this.isTransferOrder
        })
      },
      sales_manager: {
        required: requiredIf(function () {
          return !this.isTransferOrder
        })
      },
      products: {
        $each: helpers.forEach({
          product_id: { required },
          apus_waste_class_id: { required },
          quality_id: {
            required: requiredIf(function () {
              return this.isIncoming;
            })
          },
          quantity: {
            required: requiredIf(function () {
              return this.isIncoming;
            })
          },
          originCountry: {
            required: requiredIf(function () {
              return this.isIncoming;
            })
          }
        })
      },
      transport_costs: {
        $each: helpers.forEach({
          chargeCode_id: { required }
        }),
        checkDuplicateCostType
      }
    }
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  },
  components: {
    OrderCargoDocuments,
    UserPicker,
    PartnerPicker,
    LoadingAddressesEdit,
    SalesStatusInfo,
    OrderProductList,
    LogList,
    Comments,
    ModelFileList,
    LinkToDifferentSystem,
    CargoList,
    TransportCostList
  },
  computed: {
    canDelete () {
      let canDelete = false;
      if (this.refreshCargo && this.editEl && this.editEl.cargos && this.editEl.id) {
        canDelete = this.editEl.cargos.filter(cargo => cargo.isDeleted !== 1).length === 0;
      }
      return canDelete
    },
    signatureContactOptions () {
      if (this.availableSignatureContactOptions != null && this.availableSignatureContactOptions.length > 0) {
        return this.availableSignatureContactOptions;
      }
      return [];
    },
    needPartnerInfo () {
      if (!this.isIncoming) {
        return true;
      }
      return (this.editEl ? this.editEl.needVendorInfo : true);
    },
    partnerPath () {
      return (this.isIncoming ? 'vendor' : 'customer');
    },
    getPartnerAddress () {
      if (this.isIncoming) {
        if (this.editEl != null && this.editEl.vendor != null && this.editEl.vendor.active_loading_addresses != undefined &&
            this.editEl.vendor.active_loading_addresses != null && this.editEl.vendor.active_loading_addresses.length > 0) {
          const findAddress = this.editEl.vendor.active_loading_addresses.find(el => el.id == this.editEl.partner_address_id)
          if (findAddress != null && findAddress != undefined) {
            return findAddress.country
          }
        }
      }
      return ''
    },
    basePerm () {
      return (this.selectedOrdertype ? this.selectedOrdertype.transbase : 'purchase');
    },
    baseTransParam () {
      return (this.selectedOrdertype ? this.selectedOrdertype.transbase : 'purchase') + '-order';
    },
    localesOptions () {
      return this.$store.getters.getConfigOptions('locales', this.$i18n.locale)
    },
    productOptions () {
      return this.$store.getters.getConfigOptions('products', this.$i18n.locale, null, null, false, false, false, ['sales_manager_id']);
    },
    d365Partners () {
      return this.$store.getters.isFeatureEnabled('d365_api', 'partners.sync')
    },
    routeParamsView () {
      return {
        company: process.env.VUE_APP_D365_AREAID,
        code: (this.editEl && this.editEl[this.partnerPath]) ? this.editEl[this.partnerPath].partycode : null
      }
    },
    editPerm () {
      return 'edit-' + this.basePerm;
    },
    getTitle () {
      if (this.editEl && this.editEl.id) {
        return this.$tc(`${this.baseTransParam}.actions.edit`, {}, this.editEl);
      }
      return this.$t(`${this.baseTransParam}.actions.new)`);
    },
    hasEditPerm () {
      return this.ApiRequest.userHasPermission(this.editPerm);
    },
    hasDeleteFilesPerm () {
      return this.ApiRequest.userHasPermission('delete-order-files');
    },
    canReopenClosedOrder () {
      return this.ApiRequest.userHasPermission('allowReopenClosedOrder');
    },
    isClosed () {
      if (this.editEl != null) {
        const orderClosedStatusId = this.BasicHelper.getConfigValue('order_statuses', 'code', 'CLOSED', 'id');
        return this.editEl.status_id === orderClosedStatusId
      }
      return false
    },
    isTemplate () {
      const orderTemplateStatusId = this.BasicHelper.getConfigValue('order_statuses', 'code', 'TEMPLATE', 'id');
      return this.editEl.status_id === orderTemplateStatusId;
    },
    canEdit () {
      if (this.isClosed) {
        return false
      }
      return !this.readonly && this.editEl && !this.loading && this.hasEditPerm && (!this.editEl.id || this.editEl.canEdit);
    },
    canEditStatus () {
      return !this.readonly && this.editEl && !this.loading && this.hasEditPerm;
    },
    canEditWarehouse () {
      return !this.readonly && this.editEl && !this.loading && this.hasEditPerm && (!this.editEl.id || (this.editEl.canEdit && this.editEl.cargos.length == 0));
    },
    canEditPaymentTermAndDeliveryType () {
      let hasInvalidStatus = false;
      if (this.refreshCargoStatus && this.editEl && this.editEl.canEdit) {
        this.cargoStatuses.forEach(statusId => {
          if (hasInvalidStatus) {
            return
          }
          hasInvalidStatus = statusId !== this.BasicHelper.getConfigValue('order_statuses', 'code', 'TEMPLATE', 'id');
        });
      }
      return !this.readonly && this.editEl && !this.loading && this.hasEditPerm && !hasInvalidStatus;
    },
    partnerPickerFilters () {
      const filters = {
        isActual: 1,
        with: 'activeLoadingAddresses;products'
      }
      if (this.isIncoming) {
        filters.isProductProvider = 1;
      } else {
        filters.with += ';productRequirements';
      }
      if (this.selectedOrdertype != null && this.selectedOrdertype.code != undefined) {
        if (['IMP', 'EXP'].includes(this.selectedOrdertype.code)) {
          filters.notPotencial = true
        }
      }
      return filters;
    },
    loadingAddresses () {
      const options = [];
      if (this.editEl && this.editEl[this.partnerPath]) {
        this.editEl[this.partnerPath].active_loading_addresses.forEach(el => {
          options.push({
            id: el.id,
            text: this.BasicHelper.getTranslation(el.name),
            address: el.address
          })
        });
        if (this.editEl.partner_address_id && this.editEl.address && !options.find(el => el.id == this.editEl.partner_address_id)) {
          options.push({
            id: this.editEl.address.id,
            text: this.BasicHelper.getTranslation(this.editEl.address.name),
            address: this.editEl.address.address
          })
        }
      }
      return options;
    },
    warehouseOptions () {
      return this.$store.getters.getConfigOptions(
        'warehouses',
        this.$i18n.locale,
        'isAssembly',
        0,
        false,
        false,
        false,
        ['receiveOrders', 'inAxapta', 'isAssembly', 'sendOrders', 'site_id']
      );
    },
    warehouseOptionsLocal () {
      return this.warehouseOptions.filter(el => {
        if (this.isTransferOrder) {
          return el.inAxapta;
        } else {
          if (!this.isIncoming) {
            return el.inAxapta && el.sendOrders;
          }
          return el.receiveOrders;
        }
      });
    },
    warehouseOptionsD365 () {
      return this.warehouseOptions.filter(el => {
        if (this.isTransferOrder) {
          return el.inAxapta;
        } else {
          if (!this.isIncoming) {
            return el.inAxapta && el.sendOrders;
          }
          return el.receiveOrders && el.inAxapta;
        }
      });
    },
    operationalSiteOptions () {
      return this.$store.getters.getConfigOptions('operational_sites', this.$i18n.locale);
    },
    warehouseOptionsTo () {
      return this.warehouseOptions.filter(el => {
        if (el.inAxapta && el.site_id == this.editEl.to_operational_site_id && el.value != this.editEl.warehouse_id) {
          return true;
        }
        return false;
      });
    },
    statusChangeButtons () {
      const options = [];
      if (this.editEl && this.editEl.id && this.canEditStatus) {
        this.editEl.toStatusCodes.forEach(status => {
          if (!['APPROVED', 'CLOSED'].includes(status) || this.ApiRequest.userHasPermission('approve-purchase')) {
            let addOption = true;
            addOption = this.checkCargos(status);
            if (addOption) {
              const color = this.$store.getters.getParamFromConfigByFilter('order_statuses', 'code', status, 'color');
              options.push({
                tostatus: status,
                title: this.$t('purchase-order.actions.toStatus.' + status),
                class: 'btn btn-outline-dark me-3 fw-bold',
                style: 'background-color:' + color + ';color:' + this.BasicHelper.getContrastColor(color),
                message: this.$t(`${this.baseTransParam}.actions.toStatusMessage.${status}`, { localcode: this.editEl.localcode }),
                recheck: !(status == 'PLANNED' || status == 'TEMPLATE'),
                callSave: !(status == 'TEMPLATE' || status == 'CANCELED')
              })
            }
          }
        });
      }
      return options;
    },
    address () {
      if (this.editEl && this.editEl.partner_address_id) {
        const addr = this.loadingAddresses.find(el => el.id == this.editEl.partner_address_id);
        if (addr) {
          return addr.address;
        }
      }
      return '';
    },
    partnerProducts () {
      if (this.isTransferOrder) {
        return null;
      }
      return (this.editEl && this.editEl[this.partnerPath] ? this.editEl[this.partnerPath].products : []);
    },
    tabOptions () {
      const options = [];
      if (this.editEl) {
        options.push({ code: 'base', text: this.$t(`${this.baseTransParam}.tabs.base`), invalid: (this.v$.editEl.$error && !this.v$.editEl.products.$error) })
      }
      if (this.editEl && this.editEl.id) {
        options.push({ code: 'products', text: this.$t(`${this.baseTransParam}.tabs.products`), invalid: this.v$.editEl.products.$error });
        if (!this.isTransferOrder) {
          options.push({ code: 'transportCost', text: this.$t('order.transportCost') });
        }
        options.push({ code: 'log', text: this.$t(`${this.baseTransParam}.tabs.log`) });
        options.push({ code: 'orderCargoDocuments', text: this.$t(`${this.baseTransParam}.tabs.cargoFiles`) });
        options.push({ code: 'files', text: this.$t(`${this.baseTransParam}.tabs.files`) });
      }
      return options;
    },
    ordertypes () {
      if (this.$route.meta.ordertype) {
        return this.$route.meta.ordertype
      }
      return '';
    },
    isIncoming () {
      if (this.editEl && this.editEl.id) {
        return this.editEl.isIncoming;
      }
      if (this.selectedOrdertype) {
        return this.selectedOrdertype.isIncoming;
      }
      return false;
    },
    isExport () {
      if (this.selectedOrdertype && this.selectedOrdertype.code == 'EXP') {
        return true
      }
      return false
    },
    selectedOrdertype () {
      if (this.editEl && this.editEl.order_cargo_type_id) {
        return this.$store.getters.getDataFromConfigWithFilter('order_cargo_types', 'id', this.editEl.order_cargo_type_id);
      }
      const codes = this.ordertypes.split(';');
      return this.$store.getters.getDataFromConfigWithFilter('order_cargo_types', 'code', codes[0]);
    },
    isTransferOrder () {
      return this.selectedOrdertype && this.selectedOrdertype.code == 'TNF';
    },
    orderTypeOptions () {
      const options = this.$store.getters.getConfigOptions('order_cargo_types', this.$i18n.locale);
      if (this.ordertypes) {
        const codes = this.ordertypes.split(';');
        return options.filter(el => {
          return codes.includes(el.code);
        });
      }
      return options;
    },
    deliveryTypeOptions () {
      return this.$store.getters.getDeliveryTypeOptions(
        this.$i18n.locale,
        this.editEl.order_cargo_type_id,
        this.editEl.delivery_type_id
      );
    },
    documentParamOptions () {
      if (this.selectedOrdertype && this.selectedOrdertype.code == 'EXP') {
        return ['order_document_last_chapter'];
      }
      return [];
    },
    paymentTermOptions () {
      return this.$store.getters.getConfigOptions('payment_terms', this.$i18n.locale, null, null, false, false, false, ['days']);
    },
    currencyOptions () {
      return this.BasicHelper.sortArrayByKeyValue(
        this.$store.getters.getConfigOptions(
          'currencies',
          this.$i18n.locale,
          null,
          null,
          false,
          false,
          false,
          ['symbol']
        ).map((row) => {
          let text = row.code + ' - ' + row.text;
          if (row.symbol) {
            text = row.code + ', ' + row.symbol + ' - ' + row.text;
          }
          return {
            ...row,
            value: row.value,
            text: text
          }
        })
      );
    },
    currencyCode () {
      let currency = null;
      if (this.editEl && this.editEl.currency_id) {
        currency = this.$store.getters.getDataFromConfigById('currencies', this.editEl.currency_id).code;
      }
      return currency;
    }
  },
  methods: {
    updateCargoStatus (cargo) {
      this.cargoStatuses[cargo.id] = this.BasicHelper.getConfigValue('order_statuses', 'code', cargo.status, 'id');
      this.refreshCargoStatus++;
    },
    deleteOrder (order, approved = 0) {
      if (approved) {
        order.deleted = 1;
        this.loading = true;
        if (order.id !== null) {
          this.ApiRequest.request('orders/deleted', this.ApiRequest.REQUEST_POST, {
            id: order.id,
            deleted: true
          }, (response) => {
            if (response.error) {
              this.ApiRequest.displayErrorDialog(response, this);
            } else {
              this.$router.push({ name: 'menu.warehouse.purchase-orders.list' });
            }
          });
        }
        this.loading = false;
      } else {
        this.$refs.itemDeleteMsgBox.show(order, this.$t('general.item.deleteTitle'), this.$t('general.item.deleteMessage', { code: order.localcode }))
      }
    },
    cargoUpdated (cargos) {
      this.refreshCargo++;
      this.editEl.cargos = cargos ?? [];
    },
    cargoDocumentsUpdated () {
      this.$refs.OrderCargoDoc.getTableData();
    },
    checkCargos (status) {
      let result = true;
      const cargoStatuses = this.$store.getters.getConfigOptions('cargo_statuses', this.$i18n.locale)
      let allowedCargoStatus = []
      let unAllowedCargoStatus = []
      switch (status) {
        case 'PLANNED': {
          allowedCargoStatus = ['PLANNED']
          break;
        }
        case 'RECEIVED': {
          allowedCargoStatus = ['LOADED']
          unAllowedCargoStatus = ['FINISHED']
          break;
        }
        case 'APPROVED': {
          allowedCargoStatus = ['FINISHED', 'CANCELED']
          break;
        }
        case 'CLOSED': {
          allowedCargoStatus = ['FINISHED', 'CANCELED', 'TEMPLATE']
          break;
        }
      }
      if (allowedCargoStatus.length > 0 && this.editEl.cargos.length > 0) {
        if (['PLANNED', 'RECEIVED', 'APPROVED'].includes(status)) {
          allowedCargoStatus = cargoStatuses.filter(el => allowedCargoStatus.includes(el.code))
          allowedCargoStatus = allowedCargoStatus.map(el => el.value)
          const findCargo = this.editEl.cargos.find(el => allowedCargoStatus.includes(el.status_id))
          if (findCargo == undefined || findCargo == null) return false
          if (unAllowedCargoStatus.length > 0) {
            this.editEl.cargos.forEach(cargo => {
              const findStatusCode = cargoStatuses.find(el => el.value == cargo.status_id)
              if (findStatusCode != undefined && unAllowedCargoStatus.includes(findStatusCode.code)) {
                return false
              }
              if (findStatusCode != undefined && !allowedCargoStatus.includes(findStatusCode.code)) {
                return false
              }
            })
          }
        } else if (['APPROVED', 'CLOSED'].includes(status)) {
          allowedCargoStatus = cargoStatuses.filter(el => allowedCargoStatus.includes(el.code)).map(el => el.value)
          const findUnAllowedCargo = this.editEl.cargos.find(el => !allowedCargoStatus.includes(el.status_id))
          if (findUnAllowedCargo != undefined || findUnAllowedCargo != null) {
            result = false
          }
        }
      }
      if (status == 'CANCELED') {
        if (this.editEl.cargos.length > 0) result = false
      }
      if (this.isClosed && status == 'TEMPLATE' && this.canReopenClosedOrder) {
        result = true;
      }
      return result
    },
    loadContainerOptions () {
      this.loading = true;
      this.containerOptionsLoaded = false;
      this.currentReq = this.ApiRequest.request('orders/containers/options?orderId=' + this.editEl.id, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.data) {
          this.containerOptions = response.data;
          this.containerOptionsLoaded = true;
          if (this.$refs.containerInput) {
            this.$refs.containerInput.$forceUpdate();
          }
        }
        this.loading = false;
      });
    },
    show (elData, readonly = false) {
      this.readonly = readonly;
      this.commentCount = null;
      if (elData && elData.localcode && elData.localcode !== 'NEW') {
        this.editEl = null;
        this.ApiRequest.request('orders/view-by-code/' + elData.localcode, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else if (response.data) {
            if (response.data.documentParameters == null || response.data.documentParameters.length == 0) {
              response.data.documentParameters = {}
            }
            this.editEl = this.BasicHelper.cloneObject(response.data);
            if (response.data.availableSalesManagerUsers != undefined && response.data.availableSalesManagerUsers.length > 0) {
              this.availableSalesManagerUsers = response.data.availableSalesManagerUsers
              this.allAvailableSalesManagerUsers = structuredClone(this.availableSalesManagerUsers);
            }
            if (response.data.availableWareHouseEditorUsers != undefined && response.data.availableWareHouseEditorUsers.length > 0) {
              this.allAvailableWareHouseEditorUsers = response.data.availableWareHouseEditorUsers
            }
            this.setDefaultOrderSalesManager();
            this.v$.$reset();
          }
          if (this.isModal) {
            this.$refs['modal-window'].show();
          }
          this.loadContainerOptions();
        });
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
        this.editEl.order_cargo_type_id = this.orderTypeOptions[0].value;
        this.editEl.orderDate = new Date();
        this.editEl.warehouse_id = (this.warehouseOptionsD365.length > 0 ? this.warehouseOptionsD365[0].value : null);
        this.editEl.to_operational_site_id = (this.operationalSiteOptions.length > 0 ? this.operationalSiteOptions[0].value : null);
        if (this.isTransferOrder) {
          this.editEl.to_warehouse_id = (this.warehouseOptionsTo.length > 0 ? this.warehouseOptionsTo[0].value : null);
        }
        this.v$.$reset();
        if (this.isModal) {
          this.$refs['modal-window'].show();
        }
      }
    },
    getUserOptionText (item) {
      return (item ? item.fullname : '');
    },
    setDefaultOrderSalesManager () {
      const partner = this.isIncoming ? this.editEl.vendor : this.editEl.customer
      if (partner && partner.sales_manager != null) {
        this.editEl.sales_manager = partner.sales_manager
        this.editEl.sales_manager_id = partner.sales_manager_id
      } else {
        const allowedPurchase = [];
        let productsToCheck = [];
        if (this.editEl.products.length == 0 || this.editEl.products == null) {
          if (partner != null && this.editEl.sales_manager_id == null && partner.products != undefined && partner.products != null) {
            productsToCheck = partner.products;
          }
        } else {
          productsToCheck = this.editEl.products;
        }
        productsToCheck.forEach(el => {
          const findProductFromVendorProduct = this.productOptions.find(prod => prod.value == el.product_id)
          if (findProductFromVendorProduct != undefined) {
            if (findProductFromVendorProduct.sales_manager_id != null && !allowedPurchase.includes(findProductFromVendorProduct.sales_manager_id)) {
              allowedPurchase.push(findProductFromVendorProduct.sales_manager_id)
            }
          }
        })
        if (allowedPurchase.length > 0) {
          this.availableSalesManagerUsers = this.availableSalesManagerUsers.filter(el => allowedPurchase.includes(el.id))
          if (allowedPurchase.length == 1 && this.editEl.sales_manager_id == null) {
            this.editEl.sales_manager = this.availableSalesManagerUsers[0]
          }
        }
      }
    },
    save (moveToStatus = null, checkManagerUpdate = false) {
      if (this.editEl.cargos != null && this.editEl.cargos.length > 0 && checkManagerUpdate && this.initEditEl != null && this.initEditEl.sales_manager_id != (this.editEl.sales_manager == null ? null : this.editEl.sales_manager.id)) {
        this.$refs.checkManagerUpdateMsgBox.show(this.editEl, this.$t('order.updateOrdersCargoSalesManager'))
        return
      }
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        this.editEl.local_warehouse_id = this.editEl.warehouse_id
        for (const key of Object.keys(defaultEl)) {
          reqData[key] = this.editEl[key];
        }
        if (this.$refs.modelfiles) {
          reqData.documentParameters = this.$refs.modelfiles.getDocumentparams();
        }
        this.ApiRequest.request('orders/edit', this.ApiRequest.REQUEST_POST, {
          item: reqData,
          updateCargoOrdersSalesManager: this.updateCargoOrdersSalesManager
        }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$emit('saved', response.data);
            if (this.isModal) {
              this.close();
            } else if (!this.editEl.id) {
              this.$router.push({ name: `menu.warehouse.${this.basePerm}-orders.view`, params: { code: response.data.localcode } });
            } else {
              this.editEl = this.BasicHelper.cloneObject(response.data);
              this.initEditEl = this.BasicHelper.cloneObject(response.data);
              this.$forceUpdate();
              this.v$.$reset();
              if (moveToStatus) {
                this.moveToStatus(moveToStatus);
              }
            }
          }
          this.loading = false;
          this.loadContainerOptions();
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    },
    partnerChanged (el) {
      if (el) {
        if (this.isIncoming) {
          this.editEl.vendor_id = el.id;
        } else {
          this.editEl.customer_id = el.id;
        }
        this.editEl.partner_address_id = (this.loadingAddresses.length > 0 ? this.loadingAddresses[0].id : null);
        if (this.canEditPaymentTermAndDeliveryType) {
          this.editEl.delivery_type_id = el.deliveryType_id;
          this.editEl.payment_term_id = el.payment_term_id;
        }
        if (el.currency_id) {
          this.editEl.currency_id = el.currency_id;
        } else {
          this.editEl.currency_id = this.BasicHelper.getConfigValue('currencies', 'code', 'EUR', 'id');
        }
      } else {
        this.editEl.vendor_id = null;
        this.editEl.customer_id = null;
        this.editEl.partner_address_id = null;
        this.editEl.currency_id = null;
      }
      this.setDefaultOrderSalesManager();
      this.$forceUpdate();
    },
    addPartnerAddress () {
      this.$refs.frmEditLoadingAddr.show(null, this.editEl.vendor_id, this.editEl.customer_id);
    },
    redirectToCard () {
      let routeData = null;
      if (this.editEl.vendor) {
        routeData = this.$router.resolve({ name: 'menu.partners.vendors.view', params: { code: this.editEl.vendor.code }, query: { scrollTo: 'addressList' } });
      } else if (this.editEl.customer) {
        routeData = this.$router.resolve({ name: 'menu.partners.customers.view', params: { code: this.editEl.customer.code }, query: { scrollTo: 'addressList' } });
      }
      if (routeData) {
        window.open(routeData.href, '_blank');
      }
    },
    changeStatus (btn, approved = 0) {
      if (approved || !btn.recheck) {
        if (btn.callSave) {
          this.save(btn.tostatus);
        } else {
          this.moveToStatus(btn.tostatus);
        }
      } else {
        this.$refs.changeStatusMsgBox.show(
          btn,
          btn.title,
          btn.message
        )
      }
    },
    moveToStatus (status) {
      this.loading = true;
      this.ApiRequest.request('orders/change-status/' + this.editEl.id, this.ApiRequest.REQUEST_POST, { tostatus: status }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        } else {
          this.loading = false;
          this.show(this.editEl);
        }
      });
    },
    checkManagerUpdate (order, approved = 0) {
      if (approved && this.initEditEl != null && this.initEditEl.sales_manager_id != (this.editEl.sales_manager == null ? null : this.editEl.sales_manager.id)) {
        this.updateCargoOrdersSalesManager = true
      } else {
        this.updateCargoOrdersSalesManager = false
      }
      this.save()
    },
    loadingAddressChanged (el) {
      if (el && el.active && this.editEl[this.partnerPath]) {
        this.editEl[this.partnerPath].active_loading_addresses.push(el);
        this.editEl.partner_address_id = el.id;
      }
    },
    commentsChanged (newComments) {
      this.commentCount = newComments;
    },
    cargoOrderUpdated (order, updateCargos = false) {
      if (order && this.editEl && this.editEl.localcode == order.localcode) {
        this.editEl.status_id = order.status_id;
        this.editEl.deliveryDate = order.deliveryDate;
        if (updateCargos && order.cargos != undefined) {
          this.editEl.cargos = order.cargos
        }
      }
    },
    async updateAvailableSignatureContactOptions (setFirstAvailable = false) {
      if (this.editEl == null || this.editEl.id == null || this.isTransferOrder) {
        return
      }
      if (this.$refs.partnerContacts) {
        this.$refs.partnerContacts.pathParams.filterByPartnerId = this.editEl.partner_address_id
        await this.$refs.partnerContacts.handleSearch();
        if (this.$refs.partnerContacts.options.length == 0) {
          this.editEl.signature_contact_person_id = null
        } else {
          if (setFirstAvailable) {
            this.editEl.signature_contact_person_id = this.$refs.partnerContacts.options[0].value
          }
        }
      }
      this.$forceUpdate();
    },
    warehouseChanged (key, value) {
      const otherKey = (key == 'warehouse_id' ? 'to_warehouse_id' : 'warehouse_id');
      this.editEl[key] = value;
      if (value == this.editEl[otherKey]) {
        const options = (otherKey == 'warehouse_id' ? this.warehouseOptionsD365 : this.warehouseOptionsTo);
        if (options.length > 0) {
          this.editEl[otherKey] = options[0].value;
        } else {
          this.editEl[otherKey] = null;
        }
      }
    },
    loadCargoStatusList (cargos) {
      cargos.forEach(el => {
        this.cargoStatuses[el.id] = el.status_id;
      });
    },
    siteChanged () {
      if (this.warehouseOptionsTo.length > 0) {
        this.editEl.to_warehouse_id = this.warehouseOptionsTo[0].value;
      } else {
        this.editEl.to_warehouse_id = null;
      }
    }
  },
  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'OrderEdit' }) }
  },
  mounted () {
    if (!this.isModal && this.$route.params.code) {
      this.show({ localcode: this.$route.params.code });
    }
    if (this.editEl != null && this.editEl.id == null && !this.isModal && (this.$route.query.vendor_id != undefined || this.$route.query.customer_id != undefined)) {
      const partnerType = this.$route.query.vendor_id != undefined ? 'vendors' : 'customers'
      const PartnerId = this.$route.query.vendor_id != undefined ? this.$route.query.vendor_id : this.$route.query.customer_id
      this.ApiRequest.request(partnerType + '/find', this.ApiRequest.REQUEST_GET, { id: PartnerId }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else if (response.data.length > 0) {
          this.preselectedPartner = response.data[0]
          if (this.canEditPaymentTermAndDeliveryType) {
            this.editEl.delivery_type_id = response.data[0].delivery_type_id
          }
        }
      });
    }
  },
  watch: {
    '$route' () {
      if (!this.isModal && this.$route.params.code) {
        this.show({ localcode: this.$route.params.code });
      }
    },
    editEl: {
      immediate: true,
      handler (val) {
        if ((this.initEditEl == null || this.initEditEl.id == null) && typeof val == 'object') {
          this.initEditEl = this.BasicHelper.cloneObject(val);
        }
        if (val != null && val.cargos != undefined) {
          if (this.$route.params.cargoId != undefined && this.$route.params.cargoId != null && this.editEl != undefined && this.editEl.cargos.length > 0) {
            const vm = this
            this.editEl.cargos.forEach(function callback (value, index) {
              if (value.id == vm.$route.params.cargoId) {
                vm.selectedCargoId = index
              }
            });
          }
        }
        if (val != null && val.cargos !== undefined && val.cargos) {
          this.loadCargoStatusList(val.cargos);
        }
      }
    }
  }
}
