import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { mapState } from 'vuex';

const defaultEl = {
  locale: null,
  device: null,
  templates: [],
  qty: null,
  url: null,
  lineId: null,
  specificFactoryProductId: null,
  printAllLineProducts: false
};

export default {
  name: 'DevicePrinterCallprint',
  data () {
    return {
      editEl: defaultEl,
      forcePrint: false,
      loading: false,
      basePath: '',
      deviceIdsOverride: this.deviceIds,
      baleCustomQty: null
    }
  },
  validations: {
    editEl: {
      locale: { },
      device: { required },
      templates: { required }
    }
  },
  props: {
    editPerm: {
      type: String,
      default: 'edit-settings'
    },
    isModal: {
      type: Boolean,
      default: true
    },
    deviceIds: {
      type: Array,
      default: () => null
    },
    hasCustomQty: {
      type: Boolean,
      default: false
    },
    customQty: {
      type: Number,
      default: null
    },
    allowedTemplates: {
      type: Array,
      default: null
    }
  },
  computed: {
    ...mapState({
      environment: state => state.config.environment
    }),
    getTitle () {
      return this.$t('general.actions.print');
    },
    canEdit () {
      return !this.loading && this.ApiRequest.userHasPermission(this.editPerm);
    },
    displayForcePrint () {
      return (this.environment != 'production' && this.ApiRequest.userHasPermission('force-print-test'));
    },
    deviceOptions () {
      const options = Object.values(this.$store.getters.getDevicesByType('PRINTZ')).filter(el => {
        if (this.deviceIdsOverride !== null) {
          return this.deviceIdsOverride.includes(el.id);
        }
        return true;
      });
      options.forEach(option => {
        option.text = this.BasicHelper.getTranslation(option.name);
      });
      return options;
    },
    printerTemplateOptions () {
      if (this.allowedTemplates) {
        return this.$store.getters.getConfigOptions('printer_templates', this.$i18n.locale, 'code', this.allowedTemplates, false, false, true);
      } else {
        return this.$store.getters.getConfigOptions('printer_templates', this.$i18n.locale);
      }
    },
    selectedDevice () {
      return this.deviceOptions.find(el => el.code == this.editEl.device);
    },
    devicePrinterTemplates () {
      const options = [];
      if (this.selectedDevice) {
        this.printerTemplateOptions.forEach(el => {
          if (this.selectedDevice.templates[el.value]) {
            this.selectedDevice.templates[el.value].text = el.text;
            this.selectedDevice.templates[el.value].code = el.code;
            options.push(this.BasicHelper.cloneObject(this.selectedDevice.templates[el.value]));
          }
        });
      }
      return options;
    }
  },
  methods: {
    show (elData, basePath, printDeviceIds, url, additionalData = []) {
      this.forcePrint = false;
      this.basePath = basePath;
      if (printDeviceIds) {
        this.deviceIdsOverride = printDeviceIds;
      }
      if (elData) {
        this.editEl = this.BasicHelper.cloneObject(elData);
      } else {
        this.editEl = this.BasicHelper.cloneObject(defaultEl);
        if (url) {
          this.editEl.url = url;
        }
        if (additionalData.id != undefined) {
          this.editEl.lineId = additionalData.id
        }
        if (additionalData.printAllLineProducts != undefined) {
          this.editEl.printAllLineProducts = additionalData.printAllLineProducts
        }
        if (additionalData.preselectedQty != undefined && additionalData.preselectedQty != null) {
          this.editEl.qty = additionalData.preselectedQty
        }
        if (additionalData.specificFactoryProductId != undefined) {
          this.editEl.specificFactoryProductId = additionalData.specificFactoryProductId
        }
        if (additionalData.product_id != undefined) {
          this.editEl.product_id = additionalData.product_id
        }
      }
      this.v$.$reset();
      if (this.isModal) {
        this.$refs['modal-window'].show();
      }
    },
    print () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const reqData = { };
        for (const key of Object.keys(defaultEl)) {
          if (this.editEl[key] != undefined) {
            reqData[key] = this.editEl[key]
          }
        }
        if (this.customQty && !this.editEl.qty) {
          reqData.qty = this.customQty;
        }
        let path = this.basePath;
        if (this.forcePrint) {
          path += '/1';
        }
        this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, reqData, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.$emit('saved', response.data);
            this.close();
          }
          this.loading = false;
        });
      }
    },
    printerChanged () {
      this.editEl.templates.splice(0, this.editEl.templates.length)
      if (this.devicePrinterTemplates.length > 0) {
        const matches = this.devicePrinterTemplates.filter(el => el.isDefault == 1);
        if (matches.length == 0) {
          matches.push(this.devicePrinterTemplates[0]);
        }
        matches.forEach(match => {
          this.editEl.templates.push(match.code);
        });
      }
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    }
  },
  setup () {
    return { v$: useVuelidate({ $stopPropagation: true, $scope: 'DevicePrinterCallprint' }) }
  },
  watch: {
    deviceIds: {
      immediate: true,
      handler (parameter) {
      }
    }
  }
}
