import TargetProductBonusEdit from '@/components/pet/flow/targetProductBonuses/edit/TargetProductBonusEdit.vue'

export default {
  name: 'TargetProductBonusList',
  components: {
    TargetProductBonusEdit
  },
  props: {
    editPerm: {
      type: String,
      default: 'edit-flows'
    },
    deletePerm: {
      type: String,
      default: 'delete-flows'
    },
    flowId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      paging: { limit: 5, page: 1, total: 0, pages: 0 },
      isMounted: false
    };
  },
  computed: {
    editPermComp () {
      return this.editPerm;
    },
    deletePermComp () {
      return this.deletePerm;
    },
    basePathComp () {
      return 'flows/target-product-bonuses';
    },
    baseTransParam () {
      return 'flow.targetProductBonus';
    },
    tableHeaders () {
      const headers = [
        { key: 'product_id', label: this.$t(this.baseTransParam + '.product_id'), format: 'formatFromConfig', configKey: 'products', configParam: 'name' },
        { key: 'dateFrom', label: this.$t(this.baseTransParam + '.dateFrom'), type: 'date' },
        { key: 'dateTo', label: this.$t(this.baseTransParam + '.dateTo'), type: 'date' },
        { key: 'targetProductPercentage', label: this.$t(this.baseTransParam + '.targetProductPercentage') }
      ];
      const actions = [];
      if (this.canEdit) {
        actions.push({ label: this.$t('general.actions.edit'), emit: 'editItem', bicon: 'pencil-fill', class: 'btn-sm btn-primary' });
      }
      if (this.canDelete) {
        actions.push({ label: this.$t('general.actions.delete'), emit: 'deleteItem', bicon: 'trash', class: 'btn-sm btn-danger', ifconfig: { param: 'deleted_at', value: null } });
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    tableExpandConfig () {
      return null;
    },
    tableFilterConfig () {
      return null;
    },
    canDelete () {
      return this.ApiRequest.userHasPermission(this.deletePermComp);
    },
    canEdit () {
      return this.ApiRequest.userHasPermission(this.editPermComp);
    },
    requestPath () {
      const path = this.ApiRequest.addGetParamsToPath(this.basePathComp + `/list/${this.flowId}` + '?perPage=' + this.paging.limit + '&page=' + this.paging.page);
      return path;
    },
    tableActions () {
      const actions = { download: false };
      if (this.canEdit) {
        actions.add = { title: this.$t('general.actions.add') };
      }
      return actions;
    }
  },
  methods: {
    getTableData () {
      if (this.basePathComp) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        if (this.flowId) {
          this.loading = true;
          this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, (response) => {
            if (response.data) {
              this.tableItems = response.data;
              this.paging = response.paging;
              this.loading = false;
            }
          });
        }
      }
    },
    editItem (el) {
      this.$refs.editElFrm.show(el);
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    deleteItem (el, approved) {
      if (approved) {
        this.setDeleted(el, 1);
      } else {
        this.$refs.itemDeleteMsgBox.show(el, this.$t('general.item.deleteTitle'), this.$t('general.item.deleteMessage', { code: '' }))
      }
    },
    setDeleted (el, deleted) {
      this.loading = true;
      this.ApiRequest.request(this.basePathComp + '/deleted', this.ApiRequest.REQUEST_POST, { id: el.id, deleted: deleted }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        } else {
          this.getTableData();
        }
      });
    }
  },
  mounted () {
    this.getTableData();
    this.isMounted = true;
  },
  watch: {
    basePathComp: {
      immediate: true,
      handler (val) {
        if (val) {
          this.isMounted = false;
          this.getTableData();
          this.isMounted = true;
        }
      }
    }
  }
}
