import RecipeEdit from '@/components/pet/shift/recipeedit/RecipeEdit.vue'
import AssemblyMoveTo from '@/components/pet/assembly/moveto/AssemblyMoveTo.vue'
import AssemblyReturn from '@/components/pet/assembly/return/AssemblyReturn.vue'
import ProductFeedingAdd from '@/components/pet/product/feeding/add/ProductFeedingAdd.vue'
import PetHelper from '@/helpers/PetHelper';
import ShiftFlowProduced from '@/components/pet/shift/recipeedit/shiftFlowProduced/ShiftFlowProduced.vue';
import useVuelidate from '@vuelidate/core';

const defaultFilterValues = {
  viewFullInfo: false
};

export default {
  name: 'ShiftRecipeView',
  data () {
    return {
      recipe: null,
      loading: true,
      isMounted: false,
      currentReq: null,
      filterValues: this.BasicHelper.fillFilterValues(this.$route.name, this.BasicHelper.cloneObject(defaultFilterValues), this.$session),
      shift_flow_produced_config: []
    }
  },
  props: {
    flowId: {
      type: Number,
      default: null
    },
    shiftId: {
      type: Number,
      default: null
    },
    viewtype: {
      type: String,
      default: 'shift'
    },
    shift: {
      type: Object,
      default: null
    }
  },
  components: {
    ShiftFlowProduced,
    RecipeEdit,
    AssemblyMoveTo,
    AssemblyReturn,
    ProductFeedingAdd
  },
  computed: {
    hasRecipe () {
      return this.$store.getters.getParamFromConfigByFilter('line_flows', 'id', this.flowId, 'hasRecipe');
    },
    canEdit () {
      return (this.recipe && !this.recipe.disabled && this.ApiRequest.userHasPermission(['edit-shift-recipe', 'edit-all-recipes']));
    },
    canEditShiftFlowProduced () {
      return (this.ApiRequest.userHasPermission(['edit-shift-flow-produced']));
    },
    canMove () {
      return (this.recipe && this.recipe.shift.warehouseApprovedAt && !this.recipe.disabled && this.ApiRequest.userHasPermission(['edit-assembly-products']));
    },
    tableHeaders () {
      const headers = [
        { key: 'product_type', label: this.$t('product.type_id'), type: 'slot', slotcode: 'producttype' },
        {
          key: 'product_id',
          label: this.$t('product.name'),
          format: 'formatFromConfig',
          configKey: 'products',
          configParam: 'name'
        },
        {
          key: 'quantity',
          label: this.$t('recipe.quantity'),
          format: 'formatDecimal',
          precision: 0
        }
      ];
      if (!this.isAssemblyView) {
        headers.push(
          {
            key: 'inWarehouse',
            label: this.$t('recipe.inAssemblyZone'),
            format: 'formatDecimal',
            precision: 0
          })
      } else {
        headers.push({
          key: 'assembledQuantity',
          label: this.$t('recipe.assembledQuantity'),
          type: 'slot',
          slotcode: 'assembledQuantity'
        });
        headers.push({ key: 'assembledBales', label: this.$t('recipe.assembledBales') });
        headers.push({ key: 'returnedQuantity', label: this.$t('recipe.returnedQuantity') });
      }
      headers.push({ key: 'fedQuantity', label: this.$t('recipe.fedQuantity') });
      if (this.isAssemblyView) {
        headers.push({
          key: 'fedQuantityByFeedingTypes.writeoff.quantity',
          label: this.$t('recipe.fedQuantityByFeedingTypes.writeoff.quantity'),
          format: 'formatDecimal',
          precision: 0
        })
        headers.push({
          key: 'inWarehouse',
          label: this.$t('recipe.inWarehouse'),
          format: 'formatDecimal',
          precision: 0
        });
        headers.push({
          key: 'inWarehouseBaleCount',
          label: this.$t('recipe.inWarehouseBaleCount'),
          format: 'formatDecimal',
          precision: 0
        });
        headers.push({
          key: 'missingAmount',
          label: this.$t('recipe.feedRemaining'),
          format: 'formatDecimal',
          precision: 0
        })
      } else {
        headers.push({ key: 'feedRemaining', label: this.$t('recipe.feedRemaining') })
      }
      const actions = [];
      if (this.isAssemblyView) {
        if (this.canMove) {
          actions.push({
            label: this.$t('assembly.movement.return'),
            emit: 'returnAssemblyProducts',
            bicon: 'dash-lg',
            class: 'btn-warning',
            ifconfig: { useFunc: true, func: this.displayMovementReturn }
          })
          actions.push({
            label: this.$t('assembly.movement.add'),
            emit: 'addAssemblyProducts',
            bicon: 'plus-lg',
            class: 'btn-primary',
            ifconfig: { useFunc: true, func: this.displayMovementAdd }
          });
        }
      } else if (PetHelper.validShiftDateInterval(this.recipe.shift) && this.recipe && this.recipe.shift && this.recipe.shift.canFeed &&
                this.ApiRequest.userHasPermission('add-shift-fed-products')
      ) {
        actions.push({
          label: this.$t('recipe.actions.feed'),
          emit: 'feedProducts',
          bicon: 'box-arrow-right',
          class: 'btn-primary',
          ifconfig: { useFunc: true, func: this.displayFeed }
        });
      }
      headers.push({ key: 'actions', label: '', actions: actions });
      return headers;
    },
    filterActions () {
      const actions = { extra: [], download: false };
      if (this.recipe) {
        if (this.canEdit) {
          actions.extra.push({
            class: 'btn-primary',
            event: 'editRecipe',
            icon: '',
            title: this.$t('recipe.actions.edit')
          })
        }
      }
      if (this.ApiRequest.userHasPermission(['download-recipes'])) {
        actions.download = true;
      }

      return actions;
    },
    tableExpandConfig () {
      return null;
    },
    tableFilterConfig () {
      const filters = {};
      if (this.isAssemblyView && this.ApiRequest.userHasPermission('edit-all-recipes')) {
        filters.viewFullInfo = {
          type: 'checkbox-single',
          label: this.$t('general.filters.fullview'),
          inlineLabel: true,
          class: 'position-absolute end-0 col-4'
        }
      }
      return filters;
    },
    isAssemblyView () {
      return (this.viewtype === 'assembly');
    },
    typeOrder () {
      const res = [];
      if (this.recipe && this.recipe.items) {
        Object.values(this.recipe.items).forEach(el => {
          if (el.product && res.indexOf(el.product.type_id) == -1) {
            res.push(el.product.type_id);
          }
        });
      }
      return res;
    }
  },
  methods: {
    getTableData () {
      if (this.shiftId && this.flowId) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        this.loading = true;
        const path = 'shift/view/' + this.shiftId + '/' + this.flowId + '/recipe';
        this.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, { withWarehouseProducts: this.isAssemblyView }, (response) => {
          if (response.data) {
            if (response.data.assemblyItems) {
              response.data.assemblyItems.forEach(el => {
                response.data.items.push(el);
              });
            }
            this.recipe = response.data;
            if (this.recipe.id == undefined) {
              this.checkShiftFlowData();
            }
          } else {
            this.recipe = null;
          }
          this.loading = false;
        });
      }
    },
    checkShiftFlowData () {
      if (this.shiftId && this.flowId) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        this.loading = true;
        const path = 'shift/view/' + this.shiftId + '/' + this.flowId + '/checkShiftFlowProduced';
        this.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.shift_flow_produced_config = response.data.shift_flow_produced_config;
          } else {
            this.recipe = null;
          }
          this.loading = false;
        });
      }
    },
    editRecipe () {
      this.$refs.editElFrm.show(null, this.recipe.id);
    },
    downloadRecipe () {
      this.$refs.tableview.setDownloading(true);
      const path = `recipe/generate-recipe-pdf/${this.recipe.id}`;
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_DOWNLOAD, {}, () => {
        this.$refs.tableview.setDownloading(false);
      });
    },
    addAssemblyProducts (el) {
      this.$refs.assemblyMoveToFrm.show({
        product_id: el.product_id,
        to_warehouse_id: this.recipe.shift.warehouse_id,
        shift_id: this.shiftId,
        missingAmount: el.missingAmount
      });
    },
    returnAssemblyProducts (el) {
      this.$refs.assemblyReturnFrm.show({
        product_id: el.product_id,
        warehouse_id: this.recipe.shift.warehouse_id,
        shift: this.recipe.shift,
        missingAmount: (el.missingAmount == null ? el.inWarehouse : el.missingAmount)
      });
    },
    displayMovementAdd (el) {
      return !!(el && el.quantity >= 0 && this.$store.getters.isBoughtProduct(el.product_id) &&
          this.recipe.shift.warehouseInvalidProductIds && !this.recipe.shift.warehouseInvalidProductIds.includes(el.product_id));
    },
    displayMovementReturn (el) {
      return !!(el && el.inWarehouse > 0 && this.recipe && this.recipe.shift.warehouseInvalidProductIds &&
          !this.recipe.shift.warehouseInvalidProductIds.includes(el.product_id));
    },
    displayMovementError (el) {
      return !!(el && this.recipe && this.recipe.shift.warehouseInvalidProductIds && this.recipe.shift.warehouseInvalidProductIds.includes(el.product_id));
    },
    displayFeed (el) {
      return !!(el && el.missingAmount != null &&
          this.recipe.shift.warehouseInvalidProductIds && !this.recipe.shift.warehouseInvalidProductIds.includes(el.product_id));
    },
    rowClassText (el) {
      if (!el || el.quantity === null || typeof (el.quantity) === 'undefined') {
        return 'bg-danger bg-opacity-25';
      } else if (el && el.quantity === 0) {
        return 'bg-dangerinfo bg-opacity-25';
      }
      return '';
    },
    feedProducts (el) {
      this.$refs.feedingAddFrm.show(this.shift, null, false, el.product_id);
    },
    saveShiftFlowProduced () {
      this.v$.$touch();
      if (!this.v$.$invalid && !this.loading) {
        this.loading = true;
        const data = {
          shift_flow_produced_config: this.shift_flow_produced_config,
          shiftId: this.shiftId,
          flowId: this.flowId
        }
        this.ApiRequest.request('shift/saveShiftFlowProduced', this.ApiRequest.REQUEST_POST,
          { item: data }, (response) => {
            if (response.error) {
              this.ApiRequest.displayErrorDialog(response, this);
            } else {
              this.shift_flow_produced_config = response.data.shift_flow_produced_config;
            }
            this.loading = false;
          });
      }
    }
  },
  mounted () {
    this.isMounted = true;
    this.getTableData();
  },
  setup () {
    return { v$: useVuelidate() }
  },
  watch: {
    shiftId: {
      immediate: true,
      handler (val) {
        if (this.isMounted) {
          this.getTableData();
        }
      }
    },
    flowId: {
      immediate: true,
      handler (val) {
        if (this.isMounted) {
          this.getTableData();
        }
      }
    }
  }
}
