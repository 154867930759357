export default {
  name: 'ShiftProductionJournalLines',
  components: {
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      journal: null,
      isMounted: false,
      productionTypesFed: null
    };
  },
  props: {
    shiftId: Number,
    journalId: Number,
    readonly: {
      type: Boolean,
      default: true
    },
    validator: {
      default: () => null
    }
  },
  computed: {
    basePathComp () {
      if (this.shiftId && this.journalId) {
        return 'shift/view/' + this.shiftId + '/production-journal/' + this.journalId;
      }
      return null;
    },
    baseTransParam () {
      return 'shift.production-journal';
    },
    tableHeaders () {
      const actions = [];
      if (this.journal && !this.journal.bookedAt && !this.readonly) {
        actions.push({
          label: this.$t('shift.production-journal.actions.defaultcosts'),
          emit: 'setCostByRate',
          bicon: 'percent',
          class: 'btn-sm btn-tertiary'
        });
      }
      const headers = [
        { key: 'warningIndicator', label: this.$t('product.hasQuantity'), type: 'slot', slotcode: 'warningIndicator' },
        { key: 'product_id', label: this.$t('product.name'), format: 'formatFromConfig', configKey: 'products', configParam: 'name' },
        { key: 'd365_product_id', label: this.$t('product.d365_product_id'), format: 'formatFromConfig', configKey: 'd365_products', configParam: 'code' },
        { key: 'batch.code', label: this.$t(this.baseTransParam + '.batch') },
        { key: 'mode', label: this.$t(this.baseTransParam + '.line.mode'), type: 'slot', slotcode: 'mode' },
        { key: 'quantityAfterShift', label: this.$t(this.baseTransParam + '.line.quantityAfterShift'), format: 'formatDecimal', precision: 0 },
        { key: 'writeoffQuantity', label: this.$t('product.writeOff'), format: 'formatDecimal', precision: 0 },
        { key: 'fed', label: this.$t('product.fed'), format: 'formatDecimal', precision: 0 },
        { key: 'quantity', label: this.$t('general.total'), format: 'formatDecimal', precision: 0 },
        { key: 'targetProductPercentage', label: this.$t(this.baseTransParam + '.line.targetProductPercentage'), type: 'slot', slotcode: 'targetProductPercentage' },
        { key: 'costPercentage', label: this.$t(this.baseTransParam + '.line.costPercentage'), type: 'slot', slotcode: 'costPercentage' },
        { key: 'calculatedCostPercentage', label: this.$t(this.baseTransParam + '.line.calculatedCostPercentage'), format: 'formatDecimal', precision: 4 },
        { key: 'marketcost', label: this.$t(this.baseTransParam + '.line.marketcost'), type: 'slot', slotcode: 'marketcost' },
        { key: 'calculatedCostValue', label: this.$t(this.baseTransParam + '.line.costValue'), type: 'slot', slotcode: 'costValue' },
        { key: 'costPostedValue', label: this.$t(this.baseTransParam + '.line.costPostedValue'), format: 'formatDecimal', precision: 2 },
        { key: 'lineId', label: this.$t(this.baseTransParam + '.line.lineId') },
        { key: 'actions', label: '', actions: actions }
      ];
      return headers;
    },
    tableActions () {
      const actions = { download: false };
      return actions;
    },
    tableItems () {
      return (this.journal ? this.journal.lines : []);
    },
    productioncost () {
      if (this.journal) {
        return parseFloat(this.journal.usedTotalCost) + parseFloat(this.trashcost);
      }
      return null;
    },
    trashcost () {
      let total = 0;
      if (this.journal && this.journal.lines) {
        this.journal.lines.forEach(el => {
          if (el.mode == 2 && parseFloat(el.costValue)) {
            total += parseFloat(el.costValue);
          }
        })
      }
      return total;
    }
  },
  methods: {
    getTableData () {
      if (this.basePathComp) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.loading = true;
        this.currentReq = this.ApiRequest.request(this.basePathComp + '/view/1', this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.error) {
            this.$store.commit('toastAdd', {
              context: 'danger',
              message: this.ApiRequest.getApiResponseErrorMessage(response, {})
            })
            this.loading = false;
          } else if (response.data) {
            this.journal = response.data;
            this.$emit('updateJournal', this.journal);
          }
          this.loading = false;
        });
      }
    },
    getRowClassTxt (el) {
      let text = 'bg-info'
      if (el.isFinalProduct) {
        text = 'bg-success';
      } else if (el.mode == 2) {
        text = 'bg-danger';
      } else if (el.mode == 1) {
        text = 'bg-warning';
      } else if (el.mode == 101) {
        // chemical
        text = 'bg-dangerinfo';
      }
      return text + ' bg-opacity-25';
    },
    setCostByRateLineClick (el, newtab, action, index) {
      this.setCostByRate(el, index);
    },
    setCostByRate (el, index, setFinalPercentage = true) {
      if (!this.readonly) {
        if (el.mode == 2) {
          // trash: set exact match
          let cost = -parseFloat(el.marketcost);
          if (isNaN(cost)) {
            cost = 0;
          }
          this.journal.lines[index].costValue = cost;
        } else if (el.mode == 1 && typeof (el.marketcost) != typeof (undefined)) {
          if (!el.isFinalProduct || setFinalPercentage) {
            const percent = Math.round(100 * 10000 * parseFloat(el.marketcost) / -parseFloat(this.productioncost)) / 10000;
            this.journal.lines[index].costPercentage = percent;
          }
        }
      }
    },
    setLineDefaultCosts () {
      let finalProdSum = 0;
      let usedPercent = 0;
      Object.keys(this.journal.lines).forEach(key => {
        this.setCostByRate(this.journal.lines[key], key, false);
        if (this.journal.lines[key].isFinalProduct) {
          finalProdSum += parseFloat(this.journal.lines[key].quantity);
        } else if (this.journal.lines[key].mode == 1) {
          usedPercent += parseFloat(this.journal.lines[key].costPercentage);
        }
      });
      usedPercent = 100 - usedPercent;
      if (usedPercent < 0) {
        usedPercent = 0;
      }
      Object.keys(this.journal.lines).forEach(key => {
        if (this.journal.lines[key].isFinalProduct) {
          const percent = Math.round(usedPercent * 10000 * parseFloat(this.journal.lines[key].quantity) / finalProdSum) / 10000;
          this.journal.lines[key].costPercentage = percent;
        }
      });
    }
  },
  mounted () {
    this.getTableData();
    this.isMounted = true;
    this.productionTypesFed = this.$store.getters.getConfigOptions('production_types_fed').map(item => item.value);
  },
  watch: {
    shiftId: {
      immediate: true,
      handler (val) {
        if (this.isMounted) {
          this.getTableData();
        }
      }
    },
    journalId: {
      immediate: true,
      handler (val) {
        if (this.isMounted) {
          this.getTableData();
        }
      }
    }
  }
}
