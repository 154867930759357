import RatesList from '@/components/pet/partners/rates/list/rates/RatesList.vue'
import RateEdit from '@/components/pet/partners/rates/edit/RateEdit.vue';
import RateBulkAdd from '@/components/pet/partners/rates/add/RateBulkAdd.vue';
import ManagersBulkAdd from '@/components/pet/partners/rates/add/ManagersBulkAdd.vue';
import PetHelper from '@/helpers/PetHelper';

const defaultFilterValues = {
  code: '',
  name: '',
  deleted: ['0'],
  usageTypes: []
};

export default {
  name: 'ProductRateList',
  components: {
    ManagersBulkAdd,
    RateBulkAdd,
    RatesList,
    RateEdit
  },
  data () {
    return {
      currentReq: null,
      tableItems: [],
      loading: true,
      tableSorting: { item: 'code', direction: 'asc' },
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      paging: { limit: 20, page: 1, total: 0, pages: 0 }
    };
  },
  props: {
    customerId: {
      type: Number,
      default: null
    },
    vendorId: {
      type: Number,
      default: null
    },
    currencyCode: {
      type: String,
      default: null
    }
  },
  computed: {
    basePathComp () {
      return 'rates'
    },
    basePerm () {
      return 'rates';
    },
    requestPath () {
      let path = this.ApiRequest.addGetParamsToPath(this.basePathComp + '/products?perPage=' + this.paging.limit + '&page=' + this.paging.page, this.filterValues, true);
      if (this.vendorId) {
        path += '&vendor_id=' + this.vendorId;
      }
      if (this.customerId) {
        path += '&customer_id=' + this.customerId;
      }
      if (this.tableSorting.item) {
        path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
      }
      return path;
    },
    tableHeaders () {
      const headers = [
        { key: 'type_id', label: this.$t('product.type_id'), sortable: false, format: 'formatFromConfig', configKey: 'product_types', configParam: 'name' },
        { key: 'variant_id', label: this.$t('product.variant_id'), sortable: false, format: 'formatFromConfig', configKey: 'product_variants', configParam: 'name' },
        { key: 'name.' + this.$i18n.locale, label: this.$t('product.name'), sortable: false, filterKey: 'name' },
        { key: 'sales_manager.fullname', label: this.$t('product.rates.salesManager'), sortable: false },
        { key: 'warehouse_editor.fullname', label: this.$t('product.rates.warehouseEditor'), sortable: false },
        { key: 'code', label: this.$t('general.item.code'), sortable: true, filterKey: 'code', classTd: 'w-10' },
        { key: 'd365_product_id', label: this.$t('product.d365_product_id'), sortable: false, filterKey: 'd365_product_id', classTd: 'w-10', format: 'formatFromConfig', configKey: 'd365_products', configParam: 'code' },
        { key: 'default_measure_type_id', label: this.$t('product.default_measure_type_id'), sortable: false, format: 'formatFromConfig', configKey: 'measure_types', configParam: 'name' }
      ];
      if (!this.customerId) {
        headers.push({ key: 'defaultBuyRate', label: this.$t('product.defaultBuyRate', { currency: PetHelper.getCurrencyNameFromCode() }), sortable: false, format: 'formatDecimal' });
      }
      if (this.vendorId) {
        headers.push({ key: 'vendorBuyRate', label: this.$t('product.vendorBuyRate', { currency: PetHelper.getCurrencyNameFromCode(this.currencyCode) }), sortable: false, format: 'formatDecimal' });
      }
      if (!this.vendorId) {
        headers.push({ key: 'defaultSaleRate', label: this.$t('product.defaultSaleRate', { currency: PetHelper.getCurrencyNameFromCode() }), sortable: false, format: 'formatDecimal' });
      }
      if (this.customerId) {
        headers.push({ key: 'customerSaleRate', label: this.$t('product.customerSaleRate', { currency: PetHelper.getCurrencyNameFromCode(this.currencyCode) }), sortable: false, format: 'formatDecimal' });
      }
      const actions = [];
      if (this.canEdit) {
        actions.push({ label: this.$t('product.actions.addRate'), emit: 'addRate', bicon: 'plus', class: 'btn-sm btn-primary' });
      }
      headers.push({ key: 'actions', label: '', actions: actions })
      return headers;
    },
    tableFilterConfig () {
      const filters = { };
      filters.code = { type: 'text', label: '', colOnlyFilter: true };
      filters.name = { type: 'text', label: '', colOnlyFilter: true };
      if (!(this.vendorId || this.customerId)) {
        filters.usageTypes = { type: 'checkbox', label: this.$t('product.usageTypes'), class: 'flex-grow-1 pb-3', options: this.usageTypeOptions };
        filters.type = { type: 'checkbox', label: this.$t('product.type_id'), class: 'col-12 pb-3', options: this.typeOptions };
      }
      return filters;
    },
    filterActions () {
      const actions = { extra: [], download: false };
      if (this.canEdit) {
        actions.extra.push({
          class: 'btn-warning',
          event: 'bulkAdd',
          icon: '',
          title: this.$t('product.rates.bulkAddTitle')
        })
        actions.extra.push({
          class: 'btn-info',
          event: 'bulkAddManagers',
          icon: '',
          title: this.$t('product.rates.bulkAddManagers')
        })
      }
      return actions;
    },
    tableActions () {
      return null;
    },
    tableExpandConfig () {
      return { component: 'slot' }
    },
    usageTypeOptions () {
      return this.PetHelper.getProductUsageTypeOptions();
    },
    typeOptions () {
      let productTypes = this.$store.getters.getConfigOptions('product_types', this.$i18n.locale);
      if (this.filterValues.usageTypes && this.filterValues.usageTypes.length > 0) {
        productTypes = this.$store.getters.getConfigOptions('product_types', this.$i18n.locale, null, null, false, false, false, ['inAxapta', 'isBought', 'isChemical', 'isFinalProduct', 'isSeparated']);
        productTypes = productTypes.filter(productType => {
          let keep = false;
          this.filterValues.usageTypes.forEach(usageType => {
            if (productType[usageType]) {
              keep = true;
            }
          });
          return keep;
        });
      }
      return productTypes;
    },
    canDelete () {
      return this.ApiRequest.userHasPermission('delete-' + this.basePerm);
    },
    canEdit () {
      return this.ApiRequest.userHasPermission('edit-' + this.basePerm);
    }
  },
  methods: {
    bulkAdd () {
      this.$refs.bulkAdd.show();
    },
    bulkAddManagers () {
      this.$refs.bulkAddManagers.show();
    },
    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
      this.loading = true;
      this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
          this.loading = false;
        } else if (response.data) {
          this.tableItems = Object.values(response.data.data != undefined ? response.data.data : response.data);
          this.paging = response.paging;
          this.loading = false;
        }
      });
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    refresh (el) {
      if (el.product) {
        const matchIdx = this.tableItems.findIndex(subEl => subEl.id == el.product.id);
        if (matchIdx > -1) {
          const overwriteParams = [
            'defaultBuyRate', 'defaultBuyRates', 'defaultSaleRate', 'defaultSaleRates',
            'customerSaleRate', 'customerSaleRates',
            'vendorBuyRate', 'vendorBuyRates'
          ];
          overwriteParams.forEach(param => {
            this.tableItems[matchIdx][param] = el.product[param];
          })
        }
      }
      if (this.$refs['rateList' + el.product?.id]) {
        this.$refs['rateList' + el.product.id].getTableData();
      }
    },
    editItem (el, productId) {
      this.$refs.editElFrm.show(el, productId, this.vendorId, this.customerId);
    },
    addRate (el) {
      this.editItem(null, el.id, this.vendorId, this.customerId);
    }
  },
  mounted () {
    this.isMounted = true;
    this.getTableData();
  }
}
