import AssemblyApprovalEdit from '@/components/pet/assembly/approvaledit/AssemblyApprovalEdit.vue'
import AssemblyApprovalBalesList from '@/components/pet/assembly/approvalbaleslist/AssemblyApprovalBalesList.vue'

export default {
  name: 'AssemblyApprovalList',
  data () {
    return {
      shift: null,
      loading: true,
      currentReq: null,
      filterValues: {}
    }
  },
  props: {
    shiftId: {
      type: Number,
      default: null
    }
  },
  components: {
    AssemblyApprovalEdit,
    AssemblyApprovalBalesList
  },
  computed: {
    canEdit () {
      return (this.shift && this.shift.warehouseApprovalsActive && this.ApiRequest.userHasPermission(['approve-assembly-warehouse']));
    },
    canCancelApproval () {
      return (this.shift && !this.shift.closedAt && this.ApiRequest.userHasPermission(['cancelapproval-assembly-warehouse']));
    },
    tableHeaders () {
      const headers = [
        { key: 'product_id', label: this.$t('product.name'), format: 'formatFromConfig', configKey: 'products', configParam: 'name' },
        {
          key: 'quantity',
          label: this.$t('assembly.approval.fromshift.quantity', {}, { shortname: this.prevShiftName }),
          format: 'formatDecimal',
          precision: 0
        },
        {
          key: 'fedQuantity',
          label: this.$t('assembly.approval.fromshift.fed', {}, { shortname: this.prevShiftName }),
          format: 'formatDecimal',
          precision: 0
        },
        { key: 'approval.quantity', label: this.$t('assembly.approval.quantity'), type: 'slot', slotcode: 'approvalq' },
        { key: 'approval.bales', label: this.$t('assembly.approval.bales'), type: 'slot', slotcode: 'approvalq' },
        { key: 'approval.approved_at', label: this.$t('assembly.approval.user_id'), type: 'slot', slotcode: 'approved' }
      ];
      if (this.ApiRequest.userHasPermission('view-assembly-warehouse-logic')) {
        headers.push({ key: 'approval.approvalLogic', label: this.$t('assembly.approval.approvalLogic'), type: 'slot', slotcode: 'approvalLogic' });
      }
      const actions = []
      if (this.canEdit) {
        actions.push({ label: this.$t('assembly.actions.approve'), emit: 'approveQuantity', bicon: 'check2-square', class: 'btn btn-success', ifconfig: { func: this.displayApprovalApprove, useFunc: true } });
        actions.push({ label: this.$t('assembly.actions.edit'), emit: 'editQuantity', bicon: 'pencil-fill', class: 'btn btn-warning', ifconfig: { func: this.displayApprovalEdit, useFunc: true } });
      }
      if (this.canCancelApproval) {
        actions.push({ label: this.$t('assembly.actions.cancelApproval'), emit: 'cancelApproval', bicon: 'backspace-fill', class: 'btn btn-danger', ifconfig: { func: this.displayCancelApproval, useFunc: true } });
      }
      headers.push({ key: 'actions', label: '', actions: actions });

      return headers;
    },
    prevShiftName () {
      if (this.shift && this.shift.approvalRecipeShift) {
        return this.shift.approvalRecipeShift.shortname
      }
      return '';
    },
    filterActions () {
      const actions = { extra: [] };
      if (this.canEdit && !this.shift.warehouseApprovedAt) {
        actions.extra.push({ class: 'btn-primary', event: 'approveAllQuantity', icon: '', title: this.$t('assembly.actions.approveall') })
      }
      return actions;
    },
    tableExpandConfig () {
      return { component: 'slot', ifconfig: { param: 'approval_id', value: null, notCheck: true } }
    },
    tableFilterConfig () {
      return { };
    },
    invalidApprovalCount () {
      if (this.shift) {
        return this.shift.warehouseProductApprovals.filter(el => (el.approval && el.approval.invalidData)).length;
      }
      return null;
    }
  },
  methods: {
    getTableData () {
      if (this.shiftId) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.loading = true;
        const path = 'assembly/approvals/' + this.shiftId + '/list';
        this.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, [], (response) => {
          if (response.data) {
            this.shift = response.data;
          }
          this.loading = false;
        });
      }
    },
    approveRequest (reqData, multi = false, el = null) {
      if (multi) {
        this.loading = true;
      } else if (el) {
        el.loading = true;
      }
      const path = 'assembly/approvals/' + this.shiftId + '/approve';
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, { products: reqData }, (response) => {
        if (response.data) {
          this.$store.commit('toastAdd', {
            context: 'success',
            message: this.$t('assembly.actions.approvalDone' + (multi ? 'Multi' : ''))
          })
          this.shift = response.data;
        } else if (response.error) {
          this.$store.commit('toastAdd', {
            context: 'danger',
            message: this.ApiRequest.getApiResponseErrorMessage(response, {})
          })
        }
        this.loading = false;
      });
    },
    approveAllQuantity () {
      const reqData = [];
      this.shift.warehouseProductApprovals.forEach(el => {
        if (el.approval.id == null) {
          reqData.push({
            product_id: el.product_id,
            quantity: el.approval.quantity,
            bales: el.approval.bales
          })
        }
      });
      this.approveRequest(reqData, true);
    },
    approveQuantity (el, approved = 0) {
      if (approved) {
        const reqData = [{
          product_id: el.product_id,
          quantity: el.approval.quantity,
          bales: el.approval.bales
        }];
        this.approveRequest(reqData, false, el);
      } else {
        this.$refs.itemApproveMessageBox.show(
          el,
          this.$t('assembly.actions.approve'),
          this.$t('assembly.actions.approveMessage', {
            bales: el.approval.bales,
            quantity: el.approval.quantity,
            name: this.BasicHelper.getConfigValue('products', 'id', el.product_id, 'name')
          })
        );
      }
    },
    cancelApproval (el, approved = 0) {
      if (approved) {
        const path = `assembly/approvals/cancel/${el.approval.id}`;
        this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, { }, (response) => {
          if (response.data) {
            this.shift = response.data;
            this.$store.commit('toastAdd', {
              context: 'success',
              message: this.$t('assembly.actions.approvalCanceled')
            })
          } else if (response.error) {
            this.$store.commit('toastAdd', {
              context: 'danger',
              message: this.ApiRequest.getApiResponseErrorMessage(response, {})
            })
          }
          this.loading = false;
        });
      } else {
        this.$refs.itemCancelApprovalMessageBox.show(
          el,
          this.$t('assembly.actions.cancelApproval'),
          this.$t('assembly.actions.cancelApprovalMessage', {
            bales: el.approval.bales,
            quantity: el.approval.quantity,
            name: this.BasicHelper.getConfigValue('products', 'id', el.product_id, 'name')
          })
        );
      }
    },
    editQuantity (el) {
      this.$refs.approvalEditFrm.show(el, this.shiftId, this.shift.line_id);
    },
    displayApprovalEdit (el) {
      return (!el.approval || !el.approval.id || el.approval.invalidData);
    },
    displayApprovalApprove (el) {
      return (el.approval && !el.approval.created_at);
    },
    displayCancelApproval (el) {
      return (el.approval && el.approval.id && !el.approval.invalidData);
    }
  },
  mounted () {
    this.getTableData();
  },
  watch: {
    shiftId: {
      immediate: true,
      handler (val) {
        this.getTableData();
      }
    }
  }
}
