import FactoryProductPicker from '@/components/pet/factoryproduct/picker/FactoryProductPicker.vue';
import { mapState } from 'vuex';
import WarehouseBinPicker from '@/components/pet/warehouse/sku/edit/WarehouseBinPicker.vue';

export default {
  name: 'MoveSKU',
  data () {
    return {
      loading: false,
      isMovement: false,
      selectedBin: null,
      selectedFactoryProduct: null,
      binPicker: null,
      warehouseId: null,
      factoryProductPicker: null,
      showSchema: false,
      bagFits: true,
      dataIsIncorrect: false,
      bagIsLost: false,
      bagWidth: 1
    }
  },
  validations: {
  },
  components: {
    WarehouseBinPicker,
    FactoryProductPicker
  },
  props: {
    binFitmentMap: {
      type: Object,
      default: {}
    }
  },
  computed: {
    ...mapState({
      qrReaderDeviceIds: state => [state.pet.currentQRDeviceId]
    }),
    getTitle () {
      return this.isMovement ? this.$t('menu.warehouse.actions.moveBag') : this.$t('menu.warehouse.actions.insertBag');
    },
    factoryPickerFilters () {
      return {
        status: 'WAREHOUSE',
        withRemainingQuantity: 1,
        warehouse_id: this.warehouseId,
        withWareHousePlacesCount: true
      };
    },
    binPickerFilters () {
      return {
        warehouse_id: this.warehouseId
      };
    },
    bagWidthOptions () {
      return [
        { value: 1, text: 1 },
        { value: 2, text: 2 },
        { value: 3, text: 3 }
      ]
    },
    dataIsIncorrectOption () {
      return {
        value: '1',
        title: '',
        bicon: 'check'
      };
    },
    bagIsLostOption () {
      return {
        value: '1',
        title: '',
        bicon: 'check'
      };
    }
  },
  methods: {
    checkIfBagFits (id) {
      let fits = false;
      if (this.binFitmentMap[id] !== undefined) {
        fits = this.binFitmentMap[id] >= this.bagWidth;
      }
      return fits;
    },
    factoryProductChanged (factoryProduct) {
      if (factoryProduct) {
        if (this.showSchema) {
          this.$refs.warehouseLayout.selectPageByFactoryProduct(factoryProduct);
        }
        this.selectedFactoryProduct.id = factoryProduct.id;
      }
    },
    binPickerInputChanged (bin) {
      if (bin) {
        this.selectedBin.id = bin.id;
        if (bin.factoryProduct) {
          this.selectedFactoryProduct.id = bin.factoryProduct.id;
        }
        this.bagFits = this.checkIfBagFits(bin.id);
      }
    },
    close () {
      this.$refs['modal-window'].hide();
    },
    bagWidthChanged () {
      this.bagFits = this.checkIfBagFits(this.selectedBin.id);
    },
    selectBin (bin) {
      if (bin.factoryProduct) {
        this.selectedFactoryProduct = this.BasicHelper.cloneObject(bin.factoryProduct);
      }
      this.selectedBin = this.BasicHelper.cloneObject(bin);
      this.selectedBin.factoryProduct = null;
      this.bagFits = this.checkIfBagFits(bin.id);
    },
    toggleSchema () {
      this.showSchema = !this.showSchema;
    },
    show (isMovement, warehouseId, selectedBin) {
      this.warehouseId = warehouseId;
      this.isMovement = isMovement;
      if (selectedBin.factoryProduct) {
        this.selectedFactoryProduct = this.BasicHelper.cloneObject(selectedBin.factoryProduct);
      }
      this.selectedBin = this.BasicHelper.cloneObject(selectedBin);
      this.selectedBin.factoryProduct = null;
      this.$refs['modal-window'].show();
    },
    save () {
      if (!this.loading) {
        this.loading = true;
        const reqData = {};
        reqData.factoryProductId = this.selectedFactoryProduct.id;
        reqData.binId = this.selectedBin.id;
        reqData.bagWidth = this.bagWidth;
        reqData.bagIsLost = this.bagIsLost;
        reqData.dataIsIncorrect = this.dataIsIncorrect;
        reqData.isMovement = this.isMovement;
        this.ApiRequest.request('warehouses/setWarehouseRawData', this.ApiRequest.REQUEST_POST, { item: reqData }, (response) => {
          if (response.error) {
            this.ApiRequest.displayErrorDialog(response, this);
          } else {
            this.close();
            this.$emit('saved', this.selectedFactoryProduct);
          }
          this.loading = false;
        });
      }
    }
  }
}
