const defaultEl = {
  id: null
};

export default {
  name: 'WarehouseSnapshotView',
  data () {
    return {
      editEl: defaultEl,
      loading: false,
      generating: false,
      downloading: false
    }
  },
  props: {
    isModal: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    baseTransParam () {
      return 'warehouse.snapshot';
    },
    getTitle () {
      if (this.isModal) {
        return this.$t(this.baseTransParam + '.actions.edit');
      }
    },
    tableHeaders () {
      const headers = [
        { key: 'productname', label: this.$t(`${this.baseTransParam}.item.productname`), localFilter: true },
        { key: 'pruductax2009', label: this.$t(`${this.baseTransParam}.item.pruductax2009`), localFilter: true },
        { key: 'd365code', label: this.$t(`${this.baseTransParam}.item.d365code`), localFilter: true },
        { key: 'batchcode', label: this.$t(`${this.baseTransParam}.item.batchcode`), localFilter: true },
        { key: 'warehousecode', label: this.$t(`${this.baseTransParam}.item.warehousecode`), localFilter: true },
        { key: 'measuretypecode', label: this.$t(`${this.baseTransParam}.item.measuretypecode`) },
        { key: 'countrycode', label: this.$t(`${this.baseTransParam}.item.countrycode`), localFilter: true },
        { key: 'manufactureQ', label: this.$t(`${this.baseTransParam}.item.manufactureQ`), format: 'formatDecimal', precision: 0 },
        { key: 'warehouseQ', label: this.$t(`${this.baseTransParam}.item.warehouseQ`), format: 'formatDecimal', precision: 0 },
        { key: 'assemblyQ', label: this.$t(`${this.baseTransParam}.item.assemblyQ`), format: 'formatDecimal', precision: 0 },
        { key: 'fedQ', label: this.$t(`${this.baseTransParam}.item.fedQ`), format: 'formatDecimal', precision: 0 },
        { key: 'incomingQ', label: this.$t(`${this.baseTransParam}.item.incomingQ`), format: 'formatDecimal', precision: 0 },
        { key: 'outgoingQ', label: this.$t(`${this.baseTransParam}.item.outgoingQ`), format: 'formatDecimal', precision: 0 },
        { key: 'matchQ', label: this.$t(`${this.baseTransParam}.item.matchQ`), format: 'formatDecimal', precision: 0 },
        { key: 'availPhysical', label: this.$t(`${this.baseTransParam}.item.availPhysical`), format: 'formatDecimal', precision: 0, classCondition: this.availPhysicalClassCondition },
        { key: 'delta', label: this.$t(`${this.baseTransParam}.item.delta`), format: 'formatDecimal', precision: 2, localFilter: true }
      ];
      return headers;
    }
  },
  methods: {
    show (id) {
      this.loading = true;
      this.editEl = null;

      let path = 'warehouses/snapshots/view/' + id;
      let mode = this.ApiRequest.REQUEST_GET;

      if (id == 'NEW') {
        path = 'warehouses/snapshots/generate';
        mode = this.ApiRequest.REQUEST_POST;
        this.generating = true;
      }

      this.ApiRequest.request(path, mode, { }, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else if (response.data) {
          this.editEl = response.data;
        }
        this.loading = false;
        this.generating = false;
      });
    },
    close () {
      if (this.isModal) {
        this.$refs['modal-window'].hide();
      }
    },
    availPhysicalClassCondition (el) {
      if (el.availPhysicalMatch != undefined && el.availPhysicalMatch == false) {
        return 'bg-warning';
      }
      return '';
    },
    downloadItem () {
      this.downloading = true;
      this.ApiRequest.request('warehouses/snapshots/view/' + this.editEl.id + '?download=1', this.ApiRequest.REQUEST_DOWNLOAD, {}, () => {
        this.downloading = false;
      });
    }
  },
  mounted () {
    if (this.$route.params.id) {
      this.show(this.$route.params.id);
    }
  },
  watch: {
    '$route' () {
      this.show(this.$route.params.id);
    }
  }
}
