const defaultFilterValues = {
}
export default {
  name: 'ReportImportedProducts',
  components: {
  },
  props: {
    vendorId: {
      type: Number,
      default: null
    },
    productId: {
      type: Number,
      default: null
    },
    apusWasteClassId: {
      type: Number,
      default: null
    },
    countrycode: {
      type: String,
      default: null
    },
    skipfilters: {
      type: Array,
      default: () => []
    },
    date: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      loading: false,
      tableItems: [],
      paging: { limit: 50, page: 1, total: 0, pages: 0 },
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues)
    }
  },
  computed: {
    basePathComp () {
      return 'factory-products/report-imported-lines';
    },
    baseTransParam () {
      return 'factory-product.reportImported.';
    },
    tableHeaders () {
      return [
        { key: 'cargo_id', label: this.$t('cargo.localcode'), type: 'slot', slotcode: 'cargo' },
        { key: 'startQuantity', label: this.$t('purchase-order.product.actualQuantity'), format: 'formatDecimal', precision: 3 },
        { key: 'deliveryDate', label: this.$t('purchase-order.deliveryDate'), type: 'date', precision: 3 }
      ];
    },
    tableFilters () {
      return {};
    }
  },
  methods: {
    getTableData () {
      if (this.currentReq) {
        this.currentReq.cancel('changed search query');
      }
      this.loading = true;
      if (this.date) {
        this.filterValues.date = this.date;
      }
      let path = this.basePathComp + '?perPage=' + this.paging.limit + '&page=' + this.paging.page;
      path = this.ApiRequest.addGetParamsToPath(path, this.filterValues);
      const params = ['vendorId', 'productId', 'apusWasteClassId', 'countrycode'];
      params.forEach(param => {
        if (!this.skipfilters.includes(param)) {
          const val = (this[param] === null ? '' : this[param])
          path += `&${param}=${val}`
        }
      });
      this.currentReq = this.ApiRequest.request(path, this.ApiRequest.REQUEST_GET, [], (response) => {
        if (response.data) {
          this.tableItems = response.data;
          this.paging = response.paging;
          this.loading = false;
        } else {
          this.loading = false;
        }
      });
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
    }
  },
  mounted () {
    this.getTableData();
  }
}
