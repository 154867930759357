import { mapState } from 'vuex';

export default {
  name: 'TesterSubmenu',
  props: {
  },
  data () {
    return {
      selectedSubmenu: ''
    }
  },
  computed: {
    testerProfileId () {
      const splitPath = this.$route.fullPath.split('/');
      if (this.$route.name.includes('productQualityTestEdit') || this.$route.name.includes('productQualityTestOverView')) {
        return parseInt(splitPath[(splitPath.length - 3)]);
      } else {
        return parseInt(splitPath[(splitPath.length - 2)]);
      }
    },
    isForImportedCargoCategory () {
      return this.availableTesterCategory !== undefined && this.availableTesterCategory.isForImportedCargos === true;
    },
    availableTesterCategory () {
      const availableTesterCategory = JSON.parse(localStorage.getItem('availableTesterCategories'));
      return Array.isArray(availableTesterCategory) ? availableTesterCategory.find(el => el.id === this.testerProfileId) : undefined
    },
    partyTypeOption () {
      const data = []
      data.push({ value: 1, text: this.$t('menu.quality.productQualityTestList'), code: 'productQualityTestList', disabled: false })
      if (!this.isForImportedCargoCategory) {
        data.push({ value: 2, text: this.$t('menu.quality.shift-product-measurements.title'), code: 'shiftProductMeasurements', disabled: false })
      } else if (this.ApiRequest.userHasPermission('view-cargo-screenings')) {
        data.push({ value: 3, text: this.$t('menu.quality.cargo-screening.title'), code: 'cargoScreening', disabled: false })
      }
      return data
    },
    ...mapState({
      testerSelectedSubmenu: state => state.pet.testerSelectedSubmenu
    })
  },
  methods: {
    changeSelectedSubmenu () {
      //
    }
  },
  mounted () {
    this.selectedSubmenu = ['productQualityTestList', 'shiftProductMeasurements', 'cargoScreening'].includes(this.testerSelectedSubmenu) && !this.isForImportedCargoCategory ? this.testerSelectedSubmenu : 'productQualityTestList'
  },
  watch: {
    selectedSubmenu: {
      immediate: false,
      handler (val) {
        this.$store.dispatch('changeStateValues', { testerSelectedSubmenu: val })
      }
    },
    testerProfileId: {
      immediate: true,
      handler (val) {
        if (this.isForImportedCargoCategory) {
          this.selectedSubmenu = 'productQualityTestList';
        }
      }
    }
  }
}
