const defaultFilterValues = {
  canProduce: [],
  canFeed: []
};

export default {
  props: {
    productId: {
      type: Number,
      default: null
    }
  },
  name: 'RatesList',
  components: {},
  data () {
    return {
      loading: true,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      paging: { limit: 20, page: 1, total: 0, pages: 0 }
    }
  },
  computed: {
    requestPath () {
      return this.ApiRequest.addGetParamsToPath(`products/${this.productId}/technological-stage-products-list?perPage=${this.paging.limit}&page=${this.paging.page}`, this.filterValues);
    },
    tableHeaders () {
      const headers = [
        { key: 'stage.line_id', label: this.$t('line.name'), sortable: false, format: 'formatFromConfig', configKey: 'lines', configParam: 'name' },
        { key: 'stage.flow_id', label: this.$t('flow.name'), sortable: false, format: 'formatFromConfig', configKey: 'line_flows', configParam: 'name' },
        { key: `stage.name.${this.$i18n.locale}`, label: this.$t('technologicalStageProduct.stage'), sortable: false },
        { key: 'canProduce', label: this.$t('technologicalStageProduct.canProduce'), sortable: false, format: 'formatYesNo', filterKey: 'canProduce' },
        { key: 'canFeed', label: this.$t('technologicalStageProduct.canFeed'), sortable: false, format: 'formatYesNo', filterKey: 'canFeed' },
        { key: 'feedNotAssembly', label: this.$t('technologicalStageProduct.feedNotAssembly'), sortable: false, format: 'formatYesNo' }
      ];

      return headers;
    },
    tableExpandConfig () {
      return null;
    },
    tableActions () {
      const actions = { download: false };
      return actions;
    },
    yesNoOptions () {
      return this.BasicHelper.yesNoOptions();
    },
    tableFilterConfig () {
      const filters = { };
      filters.canProduce = { type: 'checkbox', label: '', colOnlyFilter: true, options: this.yesNoOptions };
      filters.canFeed = { type: 'checkbox', label: '', colOnlyFilter: true, options: this.yesNoOptions };
      return filters;
    }
  },
  methods: {
    getTableData () {
      if (this.productId) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        this.loading = true;
        this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.tableItems = response.data;
            this.paging = response.paging;
            this.loading = false;
          }
        });
      }
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    }
  },
  mounted () {
    this.getTableData();
  },
  watch: {
    productId: {
      immediate: true,
      handler (value) {
        this.getTableData();
      }
    }
  }
}
