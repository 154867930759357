import RoundedBlockList from 'ebg-vue-common/src/components/common/roundedblocklist/RoundedBlockList.vue';
import ShiftProductionJournalLines from '@/components/pet/shift/productionjournal/lines/ShiftProductionJournalLines.vue';
import ShiftProductionJournalEdit from '@/components/pet/shift/productionjournal/edit/ShiftProductionJournalEdit.vue';

export default {
  name: 'ShiftProductionJournalList',
  components: {
    RoundedBlockList,
    ShiftProductionJournalLines,
    ShiftProductionJournalEdit
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      isMounted: false
    };
  },
  props: {
    shiftId: Number,
    items: {
      type: Array,
      default: () => null
    },
    canD365Send: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    basePathComp () {
      return 'shift/view/' + this.shiftId + '/production-journals';
    },
    baseTransParam () {
      return 'shift.production-journal';
    },
    tableHeaders () {
      const headers = [
        { key: 'code', label: this.$t(this.baseTransParam + '.code') },
        { key: 'producedPieces', label: this.$t(this.baseTransParam + '.producedPieces'), format: 'formatDecimal', precision: 0 },
        { key: 'producedQuantity', label: this.$t(this.baseTransParam + '.producedQuantity'), format: 'formatDecimal', precision: 0 },
        { key: 'producedExtra', label: this.$t(this.baseTransParam + '.producedExtra'), format: 'formatDecimal', precision: 0 },
        { key: 'siloProduced', label: this.$t(this.baseTransParam + '.siloProduced'), format: 'formatDecimal', precision: 0 },
        { key: 'productivity', label: this.$t(this.baseTransParam + '.productivity'), type: 'slot', slotcode: 'productivity' },
        { key: 'hasinvalid', label: this.$t(this.baseTransParam + '.invalid-products'), format: 'formatYesNo', reverseMode: true },
        { key: 'd365LastUpdatedAt', label: this.$t(this.baseTransParam + '.d365LastUpdatedAt'), type: 'datetime' },
        { key: 'finishedAt', label: this.$t(this.baseTransParam + '.finishedAt'), type: 'datetime' },
        { key: 'bookedAt', label: this.$t(this.baseTransParam + '.bookedAt'), type: 'datetime' }
      ];
      const actions = [];
      if (this.ApiRequest.userHasPermission('book-shift-journals') && this.$store.getters.isFeatureEnabled('d365_api', 'production.book')) {
        actions.push({ label: this.$t('shift.actions.bookJournal'), emit: 'bookJournal', bicon: 'journal-bookmark-fill', class: 'btn-sm btn-warning', ifconfig: { useFunc: true, func: this.canBookJournal } });
      }
      if (this.canD365Send && this.ApiRequest.userHasPermission('generate-shift-journals') && this.$store.getters.isFeatureEnabled('d365_api', 'production.edit')) {
        actions.push({ label: this.$t('shift.actions.finishJournal'), emit: 'finishJournal', bicon: 'journal-check', class: 'btn-sm btn-dangerinfo', ifconfig: { useFunc: true, func: this.canFinishJournal } });
        actions.push({ label: this.$t('shift.actions.sendJournal'), emit: 'sendJournal', bicon: 'journal-arrow-up', class: 'btn-sm btn-success', ifconfig: { useFunc: true, func: this.canSendJournal } });
        actions.push({ label: this.$t('shift.actions.deleteSingleJournal'), emit: 'deleteSingleJournal', bicon: 'journal-x', class: 'btn-sm btn-danger', ifconfig: { useFunc: true, func: this.canDeleteJournal } });
      }
      actions.push({ label: this.$t('shift.actions.downloadJournal'), emit: 'download', bicon: 'journal-arrow-down', class: 'btn-sm btn-info' });
      if (this.canD365Send && this.ApiRequest.userHasPermission('generate-shift-journals') && this.$store.getters.isFeatureEnabled('d365_api', 'production.edit')) {
        actions.push({ label: this.$t('shift.production-journal.actions.editcosts'), emit: 'editCosts', bicon: 'pencil-fill', class: 'btn-sm btn-info', ifconfig: { useFunc: true, func: this.canEditJournalCosts } });
      }
      headers.push({ key: 'actions', label: '', actions, classTd: 'w-10' });
      return headers;
    },
    tableHeadersLines () {
      const headers = [
        { key: 'product_id', label: this.$t('product.name'), format: 'formatFromConfig', configKey: 'products', configParam: 'name' },
        { key: 'd365_product_id', label: this.$t('product.d365_product_id'), format: 'formatFromConfig', configKey: 'd365_products', configParam: 'code' },
        { key: 'batch.code', label: this.$t(this.baseTransParam + '.batch') },
        { key: 'mode', label: this.$t(this.baseTransParam + '.line.mode'), type: 'slot', slotcode: 'mode' },
        { key: 'quantityAfterShift', label: this.$t(this.baseTransParam + '.line.quantityAfterShift'), format: 'formatDecimal', precision: 0 },
        { key: 'quantity', label: this.$t(this.baseTransParam + '.line.quantity'), format: 'formatDecimal', precision: 0 },
        { key: 'costValue', label: this.$t(this.baseTransParam + '.line.costValue'), format: 'formatDecimal', precision: 2 }
      ];
      return headers;
    },
    tableExpandConfig () {
      return { component: 'slot' }
    },
    tableActions () {
      const actions = { download: false };
      actions.extra = [{
        class: 'btn btn-sm btn-danger',
        icon: 'trash-fill',
        event: 'deleteJournals',
        title: this.$t('shift.actions.deleteJournals')
      }];
      return actions;
    }
  },
  methods: {
    setTableItems (res) {
      const mappingCheck = {
        produced_product_ids: 'producedlist',
        used_product_ids: 'usedlist'
      }
      Object.keys(res).forEach(journalId => {
        if (res[journalId].invalidinfo) {
          const invalidata = {
            producedlist: [],
            usedlist: []
          };
          Object.keys(mappingCheck).forEach(key => {
            Object.values(res[journalId].invalidinfo[key]).forEach(id => {
              const product = this.$store.getters.getDataFromConfigWithFilter('products', 'id', id);
              const invalidMode = (product.d365_product_id ? 2 : 1);
              if (product) {
                const item = {
                  text: this.BasicHelper.getTranslation(product.name),
                  tooltip: invalidMode,
                  class: (invalidMode == 1 ? 'bg-danger text-white' : 'bg-warning')
                };
                invalidata[mappingCheck[key]].push(item)
              }
            });
          });
          res[journalId].custom_invalid = invalidata;
          res[journalId].hasinvalid = true;
        }
      });
      this.tableItems = res;
    },
    getTableData () {
      if (this.basePathComp) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.loading = true;
        this.currentReq = this.ApiRequest.request(this.basePathComp, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.setTableItems(response.data);
            this.loading = false;
          }
        });
      }
    },
    sendJournal (el) {
      this.journalActions('shift/send-journals/' + el.shift_id + '/' + el.id, {}, el)
    },
    editCosts (el) {
      this.$refs.journaledit.show(el.shift_id, el.id);
    },
    finishJournal (el) {
      this.journalActions('shift/finish-journals/' + el.shift_id + '/' + el.id, {}, el)
    },
    bookJournal (el) {
      this.journalActions('shift/book-journals/' + el.shift_id + '/' + el.id, {}, el)
    },
    deleteJournals () {
      this.journalActions('shift/delete-journals/' + this.shiftId, {}, null)
    },
    deleteSingleJournal (el) {
      this.journalActions('shift/delete-journals/' + el.shift_id + '/' + el.id, {}, el)
    },
    downloadJournal (el) {
      el.downloading = true;
      this.ApiRequest.request('shift/view/' + this.shiftId + '/production-journal/' + el.id + '/view?download=1', this.ApiRequest.REQUEST_DOWNLOAD, {}, (e) => {
        el.downloading = false;
      });
    },
    journalActions (path, reqData, el) {
      if (el) {
        el.loading = true;
      } else {
        this.loading = true;
      }
      this.$refs.tableview.$forceUpdate();
      this.ApiRequest.request(path, this.ApiRequest.REQUEST_POST, reqData, (response) => {
        if (response.error) {
          this.ApiRequest.displayErrorDialog(response, this);
        } else {
          this.setTableItems(response.data.productionjournals);
        }
        if (el) {
          el.loading = false;
        } else {
          this.loading = false;
        }
        this.$refs.tableview.$forceUpdate();
      });
    },
    canBookJournal (el) {
      return (el.bookedAt == null && el.finishedAt != null);
    },
    canEditJournalCosts (el) {
      return (el.bookedAt == null);
    },
    canFinishJournal (el) {
      return (el.d365LastUpdatedAt && el.bookedAt == null && el.finishedAt == null);
    },
    canSendJournal (el) {
      return (el.bookedAt == null && el.finishedAt == null);
    },
    canDeleteJournal (el) {
      return (el.bookedAt == null && el.finishedAt == null);
    },
    getInvalidinfoClass (baseItem, el) {
      return el.class;
    },
    getRowClassTxt (el) {
      let text = 'bg-info'
      if (el.isFinalProduct) {
        text = 'bg-success';
      } else if (el.mode == 2) {
        text = 'bg-danger';
      } else if (el.mode == 1) {
        text = 'bg-warning';
      } else if (el.mode == 101) {
        // chemical
        text = 'bg-dangerinfo';
      }
      return text + ' bg-opacity-25';
    }
  },
  mounted () {
    if (this.items) {
      this.tableItems = this.BasicHelper.cloneObject(this.items);
      this.loading = false;
    } else {
      this.getTableData();
    }
    this.isMounted = true;
  },
  watch: {
    items: {
      immediate: true,
      handler (val) {
        if (val) {
          this.tableItems = this.BasicHelper.cloneObject(val);
        }
      }
    }
  }
}
