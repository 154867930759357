import AssemblyMovementInfo from '@/components/pet/assembly/movementinfo/AssemblyMovementInfo.vue'
const defaultFilterValues = {
  created: null,
  shiftWasActive: [],
  isReturnMovement: [],
  producttype: '',
  product: '',
  warehouse: []
};

export default {
  name: 'AssemblyMovementList',
  components: {
    AssemblyMovementInfo
  },
  data () {
    return {
      loading: true,
      currentReq: null,
      tableItems: [],
      filterValues: this.BasicHelper.cloneObject(defaultFilterValues),
      tableSorting: { item: 'created_at', direction: 'desc' },
      paging: { limit: 20, page: 1, total: 0, pages: 0 },
      isMounted: false
    };
  },
  props: {
    warehouseIsAssembly: {
      type: Array,
      default: () => [1]
    },
    shiftId: {
      type: Number,
      default: null
    },
    warehouseFilter: {
      type: Boolean,
      default: true
    },
    shiftChemoView: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    basePathComp () {
      return 'assembly/movements'
    },
    baseTransParam () {
      return 'assembly.movement';
    },
    tableHeaders () {
      const headers = [
        { key: 'created_at', label: this.$t(this.baseTransParam + '.created_at'), type: 'datetime', filterKey: 'created' },
        { key: 'product_type', label: this.$t('product.type_id'), type: 'slot', slotcode: 'producttype', filterKey: 'producttype' },
        { key: 'product_id', label: this.$t('product.name'), format: 'formatFromConfig', configKey: 'products', configParam: 'name', filterKey: 'product' }
      ];
      if (this.shiftChemoView) {
        headers.push({ key: 'quantity', label: this.$t(this.baseTransParam + '.quantity'), format: 'formatDecimal', precision: 0 });
        headers.push({ key: 'user.fullname', label: this.$t(this.baseTransParam + '.user'), filterKey: 'user' });
        headers.push({ key: 'warehouse', label: this.$t(this.baseTransParam + '.warehouseinfo'), type: 'slot', slotcode: 'movement' });
      } else {
        headers.push({ key: 'pieces', label: this.$t(this.baseTransParam + '.pieces'), format: 'formatDecimal', precision: 0 });
        headers.push({ key: 'quantity', label: this.$t(this.baseTransParam + '.quantity'), format: 'formatDecimal', precision: 0 });
        headers.push({ key: 'user.fullname', label: this.$t(this.baseTransParam + '.user'), filterKey: 'user' });
        headers.push({ key: 'shiftWasActive', label: this.$t(this.baseTransParam + '.shiftWasActive'), format: 'formatYesNo', filterKey: 'shiftWasActive' });
        headers.push({ key: 'warehouse', label: this.$t(this.baseTransParam + '.warehouseinfo'), type: 'slot', slotcode: 'movement' });
        headers.push({ key: 'isReturnMovement', label: this.$t(this.baseTransParam + '.isReturnMovement'), format: 'formatYesNo', filterKey: 'isReturnMovement' });
      }
      headers.push({ key: 'actions', label: '', actions: [], classTd: 'w-10' });
      return headers;
    },
    tableExpandConfig () {
      if (this.shiftChemoView) {
        return null;
      }
      return { component: 'slot' }
    },
    tableFilterConfig () {
      const filters = { };
      filters.created = { type: 'date-range', label: '', colOnlyFilter: true };
      filters.shiftWasActive = { type: 'checkbox', label: '', options: this.yesNoOptions, colOnlyFilter: true };
      filters.isReturnMovement = { type: 'checkbox', label: '', options: this.yesNoOptions, colOnlyFilter: true };
      filters.user = { type: 'text', label: '', colOnlyFilter: true };
      filters.producttype = { type: 'text', label: '', colOnlyFilter: true };
      filters.product = { type: 'text', label: '', colOnlyFilter: true };
      if (this.warehouseFilter) {
        filters.warehouse = { type: 'checkbox', label: this.$t('warehouse.name'), options: this.warehouseOptions, class: 'col-12' };
      }
      return filters;
    },
    requestPath () {
      let path = this.ApiRequest.addGetParamsToPath(this.basePathComp + '/list?perPage=' + this.paging.limit + '&page=' + this.paging.page, this.filterValues, true);
      if (this.tableSorting.item) {
        path += '&order=' + this.tableSorting.item + ',' + this.tableSorting.direction;
      }
      path = this.ApiRequest.addGetParamsToPath(path,
        {
          shift_id: this.shiftId,
          warehouse_isAssembly: this.warehouseIsAssembly
        },
        true
      );
      return path;
    },
    tableActions () {
      const actions = { download: false };
      return actions;
    },
    yesNoOptions () {
      return this.BasicHelper.yesNoOptions();
    },
    warehouseOptions () {
      return this.$store.getters.getConfigOptions('warehouses', this.$i18n.locale, 'isAssembly', this.warehouseIsAssembly, false, false, true);
    }
  },
  methods: {
    getTableData () {
      if (this.basePathComp) {
        if (this.currentReq) {
          this.currentReq.cancel('changed search query');
        }
        this.BasicHelper.updateFilterValues(this.$route.name, this.filterValues);
        this.loading = true;
        this.currentReq = this.ApiRequest.request(this.requestPath, this.ApiRequest.REQUEST_GET, {}, (response) => {
          if (response.data) {
            this.tableItems = response.data;
            this.paging = response.paging;
            this.loading = false;
          }
        });
      }
    },
    clearFilters () {
      this.filterValues = this.BasicHelper.cloneObject(defaultFilterValues);
      this.getTableData();
    },
    changePage (pageNr) {
      this.paging.page = pageNr;
      this.getTableData();
    },
    updateFilter (key, value) {
      if (this.filterValues[key] !== value) {
        this.filterValues[key] = this.BasicHelper.cloneObject(value);
        this.paging.page = 1;
        this.getTableData();
      }
    }
  },
  mounted () {
    this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
    this.getTableData();
    this.isMounted = true;
  },
  watch: {
    basePathComp: {
      immediate: true,
      handler (val) {
        if (val) {
          this.isMounted = false;
          this.filterValues = this.BasicHelper.fillFilterValues(this.$route.name, this.filterValues, this.$session);
          this.getTableData();
          this.isMounted = true;
        }
      }
    }
  }
}
